import { addColorClass, removeColorClass } from "../../cb/colorClasses"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { Component } from "../defui/component"
import { getTextWidth, hoverEvent } from "../DOMutils"
import { EventRouter } from "../events"
import { dom } from "../tsxrender/dom"

export const hideAllTheaterControlsTooltips = new EventRouter<void>("hideAllTheaterControlsTooltips")

export interface ITooltipTrigger {
    getTooltipText(): string | undefined
    getTooltipTextOverride(): string | undefined
    isTooltipDisabled(): boolean
    isHovering(): boolean
    isDisabled(): boolean
}

interface ITheaterControlsIconButtonProps {
    iconPath: string
    tooltipText?: string
    noTooltip?: boolean
    hideCallback?: () => void
    disableCallback?: () => void
}

interface ITheaterControlsIconTextButtonProps extends ITheaterControlsIconButtonProps {labelText: string}

interface ITheaterControlsButtonTooltipProps {triggerElement: Component & ITooltipTrigger}

export class TheaterControlsIconButton extends Component<HTMLDivElement> implements ITooltipTrigger {
    public icon: HTMLImageElement
    public tooltip: TheaterControlsButtonTooltip | undefined
    // tooltipText should be used as a general tooltip label for the button
    // tooltipTextOverride should be used for temporary status messages to display in tooltip
    protected tooltipText: string | undefined
    protected tooltipTextOverride: string | undefined
    protected disabled = false
    protected tooltipDisabled = false
    protected animationClass?: string
    protected hideCallback?: () => void
    protected disableCallback?: () => void

    constructor(props: ITheaterControlsIconButtonProps) {
        super("div", props)
    }

    initUI(props: ITheaterControlsIconButtonProps): void {
        const divStyles: CSSX.Properties = {
            display: "inline-flex",
            position: "relative",
            alignItems: "center",
            justifyContent: "center",
            padding: "",
            minWidth: "32px",
            userSelect: "none",
            pointerEvents: "auto",
        }

        this.element = 
            <div style={divStyles} className="hover-btn drop-shadow-container" aria-label={props.tooltipText}>
                <img ref={(el: HTMLImageElement) => this.icon = el} src={props.iconPath} draggable={false} alt={props.tooltipText}/>
            </div>
        
        this.initHoverListeners()
        // this stores this.originalDisplayStyle
        this.hideElement()
        this.showElement()

        if (!(props.noTooltip ?? false)) {
            this.addTooltip()
        }
    }

    initData(props: ITheaterControlsIconButtonProps): void {
        this.hideCallback = props.hideCallback
        this.disableCallback = props.disableCallback
        this.tooltipText = props.tooltipText
    }

    protected initHoverListeners(): void {
        hoverEvent(this.element, { ignoreTouch: true }).listen((hovering) => {
            if (hovering) {
                addColorClass(this.element, "hovering")
            } else {
                removeColorClass(this.element, "hovering")
            }
        })
    }

    public isHovering(): boolean {
        return this.element.classList.contains("hovering")
    }

    public getTooltipText(): string | undefined {
        return this.tooltipText
    }

    public setTooltipText(tooltipText: string): void {
        if (this.tooltip === undefined) {
            this.addTooltip()
        }
        this.tooltipText = tooltipText
        this.tooltip?.refreshTooltip.fire()
        this.updateAriaLabelAndAlt(tooltipText)
    }

    public clearTooltipText(): void {
        this.tooltipText = undefined
        this.tooltip?.refreshTooltip.fire()
    }

    public getTooltipTextOverride(): string | undefined {
        return this.tooltipTextOverride
    }

    public setTooltipTextOverride(tooltipText: string): void {
        if (this.tooltip === undefined) {
            this.addTooltip()
        }
        this.tooltipTextOverride = tooltipText
        this.tooltip?.refreshTooltip.fire()
    }

    public clearTooltipTextOverride(): void {
        this.tooltipTextOverride = undefined
        this.tooltip?.refreshTooltip.fire()
    }

    public updateIcon(iconPath: string): void {
        this.icon.src = iconPath
    }

    public changeIconAnimation(className: string): void {
        if (this.animationClass !== undefined) {
            this.icon.classList.remove(this.animationClass)
        }
        this.animationClass = className
        this.icon.classList.add("animated-icon")
        this.icon.classList.add(className)
    }

    protected addTooltip(): void {
        if (this.tooltip !== undefined) {
            return
        }
        this.tooltip = new TheaterControlsButtonTooltip({ triggerElement: this })
        this.addChild(this.tooltip)
    }

    public removeTooltip(): void {
        if (this.tooltip === undefined) {
            return
        }
        this.removeChild(this.tooltip)
        this.tooltip = undefined
        this.clearTooltipTextOverride()
    }

    public disable(): void {
        this.disabled = true
        addColorClass(this.element, "disabled")
        this.element.ariaDisabled = "true"
        this.disableCallback?.()
    }

    public enable(): void {
        this.disabled = false
        removeColorClass(this.element, "disabled")
        this.element.removeAttribute("aria-disabled")
    }

    public isDisabled(): boolean {
        return this.disabled
    }

    public disableTooltip(): void {
        this.tooltipDisabled = true
    }

    public enableTooltip(): void {
        this.tooltipDisabled = false
    }

    public isTooltipDisabled(): boolean {
        return this.tooltipDisabled
    }

    public hideElement(): void {
        super.hideElement()
        this.hideCallback?.()
    }

    public hideVisibility(): void {
        this.element.style.visibility = "hidden"
        this.hideCallback?.()
    }

    public showVisibility(): void {
        this.element.style.visibility = ""
    }

    protected updateAriaLabelAndAlt(label: string): void {
        this.element.ariaLabel = label
        this.icon.alt = label
    }

}

export class TheaterControlsIconTextButton extends TheaterControlsIconButton {
    private textSpan: HTMLSpanElement
    private textP: HTMLParagraphElement

    constructor(props: ITheaterControlsIconTextButtonProps) {
        super(props)
    }

    initUI(props: ITheaterControlsIconTextButtonProps): void {
        const divStyles: CSSX.Properties = {
            display: "inline-flex",
            position: "relative",
            alignItems: "center",
            userSelect: "none",
            justifyContent: "center",
            pointerEvents: "auto",
        }

        const spanStyles: CSSX.Properties = {
            color: "#ffffff",
            fontSize: "13px",
            padding: "5px",
            display: "inline-block",
            textAlign: "right",
        }

        this.element = 
            <div style={divStyles} className="hover-btn drop-shadow-container" aria-label={props.labelText}>
                <img ref={(el: HTMLImageElement) => this.icon = el} src={props.iconPath} draggable={false} alt={props.labelText}/>
                <span ref={(el: HTMLSpanElement) => this.textSpan = el} style={spanStyles}>
                    <p ref={(el: HTMLParagraphElement) => this.textP = el} style={{ display: "inline" }}>{props.labelText}</p>
                </span>
            </div>
        
        this.initHoverListeners()
        // this stores this.originalDisplayStyle
        this.hideElement()
        this.showElement()

        if (!(props.noTooltip ?? false)) {
            this.addTooltip()
        }
    }

    public getLabelText(): string {
        return this.textP.textContent ?? ""
    }

    public setTooltipTextFromLabel(): void {
        this.setTooltipText(this.getLabelText())
    }

    public updateTextLabel(text: string): void {
        this.textP.textContent = text
        this.repositionChildren()
        this.updateAriaLabelAndAlt(text)
    }

    public hideTextLabel(): void {
        this.element.style.width = "32px"
        this.textSpan.style.display = "none"
    }

    public showTextLabel(): void {
        this.element.style.width = ""
        this.textSpan.style.display = "inline-block"
    }

    protected repositionChildren(): void {
        // shrink span if the text is wrapped, prevents white space after wrapping text
        if (this.textP.textContent === null) {
            return
        }
        // in calculations, the 10 is the padding on the span
        this.textSpan.style.textAlign = "left"
        this.textSpan.style.width = ""
        const maxSpanWidth = this.textSpan.offsetWidth - 10 + 1
        const textWidth = getTextWidth(this.textP.textContent, this.textP)
        // set span width so inner p can wrap to the correct amount, only allow max 2 line wraps
        if (textWidth > maxSpanWidth * 2 ) {
            this.textSpan.style.width = `${Math.ceil(textWidth) / 2 + 10 + 1}px`
        } else if (textWidth > maxSpanWidth ) {
            this.textSpan.style.width = `${maxSpanWidth}px`
        } else {
            this.textSpan.style.width = ""
        }
        // shrink span width to just fit inner p, this removes whitespace
        this.textSpan.style.width = `${this.textP.offsetWidth + 1}px`
    }
}

export class TheaterControlsButtonTooltip extends Component<HTMLDivElement> {
    public refreshTooltip: EventRouter<void>
    private triggerElement: Component & ITooltipTrigger
    private textP: HTMLParagraphElement
    private triggeredByTouch = false

    constructor(props: ITheaterControlsButtonTooltipProps) {
        super("div", props)
    }

    protected initData(props: ITheaterControlsButtonTooltipProps): void {
        super.initData(props)
        this.triggerElement = props.triggerElement
        this.refreshTooltip = new EventRouter<void>("refreshTooltip")
    }

    protected initUI(props: ITheaterControlsButtonTooltipProps): void {
        const divStyles: CSSX.Properties = {
            position: "absolute",
            display: "block",
            visibility: "hidden",
            opacity: "0",
            bottom: "calc(100% + 5px)",
            left: "50%",
            transform: "translateX(-50%)",
            borderRadius: "4px",
            backgroundColor: "rgba(0, 0, 0, .92)",
            padding: "8px 16px",
            textAlign: "center",
            fontSize: "13px",
            color: "#ffffff",
            width: "max-content",
            maxWidth: "150px",
            transition: "inherit",
            pointerEvents: "none",
        }

        addEventListenerPoly("pointerenter", props.triggerElement.element, (evt) => {
            // When button is enabled, only show tooltip on mouse hover
            // When button is disabled, show tooltip on touch to display error message
            if (props.triggerElement.isTooltipDisabled()) {
                return
            }
            if (!props.triggerElement.isDisabled() && evt.pointerType !== "mouse") {
                return
            }

            this.triggeredByTouch = evt.pointerType !== "mouse"

            const tooltipText = this.getTooltipText()
            if (tooltipText === undefined) {
                return
            }
            this.showTooltip(tooltipText)
        })

        addEventListenerPoly("pointerleave", props.triggerElement.element, (evt) => {
            if (evt.pointerType === "mouse") {
                this.hideTooltip()
            }
        })

        addEventListenerPoly("touchstart", document, (evt) => {
            if (!props.triggerElement.element.contains(evt.target as Node)) {
                this.hideTooltip()
            }
        })
        addEventListenerPoly("touchmove", document, (evt) => {
            if (!props.triggerElement.element.contains(evt.target as Node)) {
                this.hideTooltip()
            }
        })
        addEventListenerPoly("touchend", document, (evt) => {
            if (!props.triggerElement.element.contains(evt.target as Node)) {
                this.hideTooltip()
            }
        })

        hideAllTheaterControlsTooltips.listen(() => {
            this.hideTooltip()
        }, false)

        this.refreshTooltip.listen(() => this.refreshTooltipFn(), false)

        this.element = 
            <div style={divStyles} className="no-drop-shadow video-controls-tooltip">
                <p ref={(el: HTMLParagraphElement) => this.textP = el} style={{ display: "inline" }}></p>
            </div>
        
        this.recalculateWidth()
    }

    private getTooltipText(): string | undefined {
        let tooltipText: string | undefined
        if ("tooltipTextOverride" in this.triggerElement) {
            tooltipText = this.triggerElement.getTooltipTextOverride()
        }
        if (tooltipText === undefined) {
            tooltipText = this.triggerElement.getTooltipText()
        }
        return tooltipText
    }

    private showTooltip(text: string): void {
        this.textP.textContent = text
        this.recalculateWidth()
        this.repositionTooltipX()
        this.element.style.visibility = ""
        this.element.style.opacity = "1"
    }

    public hideTooltip(): void {
        this.element.style.opacity = "0"
        this.element.style.visibility = "hidden"
    }

    private refreshTooltipFn(): void {
        // only update tooltipText when it is or should be showing
        if (this.element.style.visibility === "hidden" && !this.triggerElement.isHovering()) {
            return
        }
        const shouldHideTouchTooltip = this.triggeredByTouch && !this.triggerElement.isDisabled()
        const tooltipText = this.getTooltipText()
        if (tooltipText === undefined || shouldHideTouchTooltip) {
            this.hideTooltip()
            return
        }
        this.showTooltip(tooltipText)
    }

    private repositionTooltipX(): void {
        this.element.style.left = "50%"
        this.element.style.transform = "translateX(-50%)"

        const rect = this.element.getBoundingClientRect()
        const playerRect= document.getElementById("TheaterModePlayer")?.getBoundingClientRect()

        if (playerRect !== undefined && playerRect.right < rect.right + 5) {
            this.element.style.left = `${this.element.offsetLeft - (rect.right - playerRect.right + 5)}px`
        } else if (playerRect !== undefined && playerRect.left > rect.left - 5) {
            this.element.style.left = `${this.element.offsetLeft + (playerRect.left - rect.left + 5)}px`
        }
    }

    public showElement(defaultDisplay = "block"): void{
        super.showElement(defaultDisplay)
        this.repositionTooltipX()
    }

    private recalculateWidth(): void {
        // shrink tooltip if the text is wrapped
        const maxWidth = 150
        if (this.textP.textContent !== null && getTextWidth(this.textP.textContent, this.textP) > maxWidth - 1) {
            this.element.style.width = `${maxWidth}px`
            this.element.style.width = `${this.textP.offsetWidth + 1}px`
        } else {
            this.element.style.width = "max-content"
        }
    }
}
