import { SentimentSurveyOption } from "../../../common/sentimentSurvey"
import { dom } from "../../../common/tsxrender/dom"

export function smileyEmoticon(option: SentimentSurveyOption): SVGElement {
    switch (option) {
        case SentimentSurveyOption.InTrouble:
            return smileyInTrouble()
        case SentimentSurveyOption.Unhappy:
            return smileyUnhappy()
        case SentimentSurveyOption.Indifferent:
            return smileyIndifferent()
        case SentimentSurveyOption.Smirk:
            return smileySmirk()
        case SentimentSurveyOption.InLove:
            return smileyInLove()
    }
}

function smileyInTrouble(): SVGElement {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4" clip-path="url(#clip0)">
            <path d="M22.948 10.5C22.8167 10.5054 22.6878 10.5366 22.5686 10.5919C22.4494 10.6471 22.3423 10.7253 22.2533 10.822C22.1643 10.9187 22.0953 11.0319 22.0501 11.1553C22.0049 11.2787 21.9845 11.4097 21.99 11.541C21.996 11.692 22 11.841 22 11.999C21.9983 14.1333 21.3133 16.2109 20.0452 17.9277C18.7772 19.6444 16.9927 20.91 14.9533 21.5391C12.9138 22.1682 10.7265 22.1278 8.71174 21.4237C6.69694 20.7196 4.96052 19.3889 3.7568 17.6265C2.55309 15.864 1.94533 13.7625 2.02255 11.6296C2.09976 9.4967 2.85789 7.44458 4.1859 5.77379C5.5139 4.103 7.342 2.90132 9.40244 2.34478C11.4629 1.78823 13.6474 1.90606 15.636 2.68099C15.8831 2.77753 16.1583 2.77197 16.4013 2.66555C16.5216 2.61285 16.6303 2.53697 16.7213 2.44225C16.8123 2.34753 16.8837 2.23582 16.9315 2.11349C16.9793 1.99116 17.0025 1.86062 16.9999 1.72931C16.9972 1.59801 16.9688 1.46851 16.9161 1.34821C16.8634 1.22791 16.7875 1.11917 16.6928 1.02819C16.598 0.937219 16.4863 0.865792 16.364 0.817991C13.9758 -0.114361 11.3518 -0.257336 8.87638 0.410013C6.40101 1.07736 4.20443 2.51995 2.60851 4.52641C1.01259 6.53286 0.101223 8.9977 0.00794807 11.5598C-0.0853267 14.1218 0.644395 16.6464 2.09019 18.7636C3.53599 20.8808 5.62185 22.4793 8.04214 23.3249C10.4624 24.1704 13.0899 24.2187 15.5396 23.4624C17.9893 22.7062 20.1324 21.1853 21.6549 19.1226C23.1774 17.0599 23.9992 14.5637 24 12C24 11.818 24 11.639 23.988 11.459C23.9711 11.1961 23.8536 10.9499 23.66 10.7713C23.4663 10.5928 23.2114 10.4956 22.948 10.5Z" fill="#49494F"/>
            <path d="M20.5 9.49994C21.4283 9.49994 22.3185 9.13119 22.9749 8.47482C23.6313 7.81844 24 6.9282 24 5.99994C24 4.42994 21.951 1.34094 21.323 0.431943C21.2261 0.305815 21.1014 0.203655 20.9587 0.133354C20.816 0.0630519 20.6591 0.0264893 20.5 0.0264893C20.3409 0.0264893 20.184 0.0630519 20.0413 0.133354C19.8986 0.203655 19.7739 0.305815 19.677 0.431943C19.049 1.34094 17 4.42994 17 5.99994C17 6.9282 17.3687 7.81844 18.0251 8.47482C18.6815 9.13119 19.5717 9.49994 20.5 9.49994ZM20.285 3.19094C20.3071 3.15364 20.3386 3.12273 20.3763 3.10127C20.414 3.0798 20.4566 3.06852 20.5 3.06852C20.5434 3.06852 20.586 3.0798 20.6237 3.10127C20.6614 3.12273 20.6929 3.15364 20.715 3.19094C21.3011 4.04677 21.7357 4.99692 22 5.99994C22 6.39777 21.842 6.7793 21.5607 7.0606C21.2794 7.34191 20.8978 7.49994 20.5 7.49994C20.1022 7.49994 19.7206 7.34191 19.4393 7.0606C19.158 6.7793 19 6.39777 19 5.99994C19.2643 4.99692 19.6989 4.04677 20.285 3.19094Z" fill="#49494F"/>
            <path d="M7.50827 18.37C7.73904 18.5003 8.01212 18.5338 8.2675 18.4629C8.52289 18.392 8.73969 18.2226 8.87027 17.992C9.16681 17.4109 9.61344 16.9197 10.1638 16.5695C10.7142 16.2192 11.3483 16.0226 12.0003 16C12.6523 16.0226 13.2863 16.2192 13.8367 16.5695C14.3871 16.9197 14.8337 17.4109 15.1303 17.992C15.2653 18.2138 15.4813 18.3743 15.7325 18.4397C15.9838 18.5051 16.2507 18.4702 16.4767 18.3424C16.7027 18.2146 16.8701 18.0039 16.9436 17.7549C17.017 17.5058 16.9907 17.238 16.8703 17.008C16.4021 16.1166 15.7032 15.3672 14.8466 14.8381C13.9899 14.309 13.007 14.0195 12.0003 14C10.9936 14.0195 10.0106 14.309 9.15396 14.8381C8.29732 15.3672 7.59843 16.1166 7.13027 17.008C6.99993 17.2388 6.96652 17.5118 7.0374 17.7672C7.10828 18.0226 7.27764 18.2394 7.50827 18.37Z" fill="#49494F"/>
            <path d="M8.5 11.5C9.60457 11.5 10.5 10.6046 10.5 9.5C10.5 8.39543 9.60457 7.5 8.5 7.5C7.39543 7.5 6.5 8.39543 6.5 9.5C6.5 10.6046 7.39543 11.5 8.5 11.5Z" fill="#49494F"/>
            <path d="M15.5 11.5C16.6046 11.5 17.5 10.6046 17.5 9.5C17.5 8.39543 16.6046 7.5 15.5 7.5C14.3954 7.5 13.5 8.39543 13.5 9.5C13.5 10.6046 14.3954 11.5 15.5 11.5Z" fill="#49494F"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
}

function smileyUnhappy(): SVGElement {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4" clip-path="url(#clip0)">
            <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z" fill="#49494F"/>
            <path d="M8 11C9.10457 11 10 10.1046 10 9C10 7.89543 9.10457 7 8 7C6.89543 7 6 7.89543 6 9C6 10.1046 6.89543 11 8 11Z" fill="#49494F"/>
            <path d="M16 11C17.1046 11 18 10.1046 18 9C18 7.89543 17.1046 7 16 7C14.8954 7 14 7.89543 14 9C14 10.1046 14.8954 11 16 11Z" fill="#49494F"/>
            <path d="M17.2149 14.186C15.7885 13.873 14.3036 13.9565 12.9212 14.4273C11.5389 14.8982 10.3118 15.7384 9.37291 16.857C9.27893 16.9558 9.20617 17.0728 9.1591 17.2009C9.11203 17.3289 9.09164 17.4651 9.09919 17.6013C9.10674 17.7375 9.14206 17.8707 9.20299 17.9927C9.26392 18.1147 9.34917 18.223 9.45349 18.3109C9.55781 18.3987 9.679 18.4643 9.80961 18.5036C9.94022 18.5429 10.0775 18.555 10.213 18.5393C10.3484 18.5236 10.4793 18.4803 10.5974 18.4122C10.7155 18.344 10.8185 18.2524 10.8999 18.143C11.6044 17.3047 12.5248 16.6752 13.5614 16.3227C14.5981 15.9701 15.7115 15.908 16.7809 16.143C16.9099 16.1734 17.0437 16.1779 17.1745 16.1561C17.3053 16.1344 17.4305 16.0868 17.5427 16.0163C17.6549 15.9457 17.752 15.8536 17.8283 15.7451C17.9046 15.6367 17.9586 15.5142 17.9871 15.3847C18.0156 15.2552 18.0181 15.1214 17.9944 14.9909C17.9707 14.8605 17.9213 14.7361 17.8491 14.6249C17.7768 14.5137 17.6832 14.418 17.5737 14.3433C17.4641 14.2687 17.3408 14.2165 17.2109 14.19L17.2149 14.186Z" fill="#49494F"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
}

function smileyIndifferent(): SVGElement {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4" clip-path="url(#clip0)">
            <path d="M17 15H7C6.73478 15 6.48043 15.1054 6.29289 15.2929C6.10536 15.4804 6 15.7348 6 16C6 16.2652 6.10536 16.5196 6.29289 16.7071C6.48043 16.8946 6.73478 17 7 17H17C17.2652 17 17.5196 16.8946 17.7071 16.7071C17.8946 16.5196 18 16.2652 18 16C18 15.7348 17.8946 15.4804 17.7071 15.2929C17.5196 15.1054 17.2652 15 17 15Z" fill="#49494F"/>
            <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z" fill="#49494F"/>
            <path d="M8 11C9.10457 11 10 10.1046 10 9C10 7.89543 9.10457 7 8 7C6.89543 7 6 7.89543 6 9C6 10.1046 6.89543 11 8 11Z" fill="#49494F"/>
            <path d="M16 11C17.1046 11 18 10.1046 18 9C18 7.89543 17.1046 7 16 7C14.8954 7 14 7.89543 14 9C14 10.1046 14.8954 11 16 11Z" fill="#49494F"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
}

function smileySmirk(): SVGElement {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4" clip-path="url(#clip0)">
            <path d="M17.8871 13.077C17.7659 13.0264 17.6359 13.0002 17.5046 12.9999C17.3732 12.9996 17.2431 13.0251 17.1216 13.0751C17.0002 13.125 16.8897 13.1984 16.7967 13.291C16.7036 13.3837 16.6296 13.4938 16.5791 13.615C16.0168 14.9609 14.9436 16.0289 13.595 16.5845C12.2463 17.1402 10.7323 17.1382 9.38508 16.579C9.14028 16.4769 8.86495 16.4762 8.61965 16.5771C8.37436 16.678 8.17918 16.8722 8.07708 17.117C7.97497 17.3618 7.97429 17.6371 8.07518 17.8824C8.17608 18.1277 8.37028 18.3229 8.61508 18.425C10.4519 19.1885 12.5166 19.1918 14.3558 18.4344C16.1951 17.6769 17.6586 16.2205 18.4251 14.385C18.4756 14.2638 18.5018 14.1338 18.5022 14.0025C18.5025 13.8711 18.477 13.741 18.427 13.6195C18.377 13.4981 18.3036 13.3876 18.211 13.2946C18.1184 13.2015 18.0083 13.1275 17.8871 13.077Z" fill="#49494F"/>
            <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 22C10.0222 22 8.08879 21.4135 6.4443 20.3147C4.79981 19.2159 3.51809 17.6541 2.76121 15.8268C2.00433 13.9996 1.8063 11.9889 2.19215 10.0491C2.578 8.10929 3.53041 6.32746 4.92894 4.92893C6.32746 3.53041 8.10929 2.578 10.0491 2.19215C11.9889 1.8063 13.9996 2.00433 15.8268 2.7612C17.6541 3.51808 19.2159 4.79981 20.3147 6.4443C21.4135 8.08879 22 10.0222 22 12C21.9971 14.6513 20.9426 17.1931 19.0679 19.0679C17.1931 20.9426 14.6513 21.9971 12 22Z" fill="#49494F"/>
            <path d="M8 11C9.10457 11 10 10.1046 10 9C10 7.89543 9.10457 7 8 7C6.89543 7 6 7.89543 6 9C6 10.1046 6.89543 11 8 11Z" fill="#49494F"/>
            <path d="M16 11C17.1046 11 18 10.1046 18 9C18 7.89543 17.1046 7 16 7C14.8954 7 14 7.89543 14 9C14 10.1046 14.8954 11 16 11Z" fill="#49494F"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
}

function smileyInLove(): SVGElement {
    return <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.4" clip-path="url(#clip0)">
            <path d="M15.7724 14.452C15.3637 15.0802 14.8047 15.5963 14.146 15.9536C13.4873 16.3109 12.7498 16.498 12.0004 16.498C11.251 16.498 10.5135 16.3109 9.85479 15.9536C9.19608 15.5963 8.63703 15.0802 8.22838 14.452C8.08331 14.2298 7.85588 14.0743 7.59615 14.0197C7.33641 13.9651 7.06563 14.0159 6.84338 14.161C6.62113 14.3061 6.46561 14.5335 6.41104 14.7933C6.35646 15.053 6.40731 15.3238 6.55238 15.546C7.14252 16.454 7.95016 17.2001 8.90194 17.7167C9.85372 18.2332 10.9195 18.5038 12.0024 18.5038C13.0853 18.5038 14.151 18.2332 15.1028 17.7167C16.0546 17.2001 16.8622 16.454 17.4524 15.546C17.5975 15.3238 17.6483 15.053 17.5937 14.7933C17.5391 14.5335 17.3836 14.3061 17.1614 14.161C16.9391 14.0159 16.6683 13.9651 16.4086 14.0197C16.1489 14.0743 15.9215 14.2298 15.7764 14.452H15.7724Z" fill="#49494F"/>
            <path d="M5.13896 11.345C5.18563 11.3937 5.24168 11.4325 5.30372 11.459C5.36576 11.4854 5.43251 11.4991 5.49996 11.4991C5.56742 11.4991 5.63417 11.4854 5.69621 11.459C5.75825 11.4325 5.81429 11.3937 5.86096 11.345L9.26296 7.80002C9.63565 7.42708 9.88166 6.94641 9.96618 6.42599C10.0507 5.90557 9.96947 5.37174 9.73396 4.90002C9.55667 4.54205 9.29672 4.23141 8.97559 3.9938C8.65447 3.75619 8.28141 3.59842 7.88724 3.53354C7.49307 3.46866 7.08912 3.49853 6.70878 3.62068C6.32844 3.74283 5.98264 3.95376 5.69996 4.23602L5.67396 4.26302C5.6268 4.30956 5.56322 4.33565 5.49696 4.33565C5.43071 4.33565 5.36712 4.30956 5.31996 4.26302L5.29996 4.23602C4.82894 3.76248 4.18909 3.49544 3.52118 3.49366C2.85327 3.49188 2.21201 3.7555 1.73846 4.22652C1.26492 4.69755 0.997884 5.33739 0.996103 6.0053C0.994321 6.67322 1.25794 7.31448 1.72896 7.78802L5.13896 11.345Z" fill="#49494F"/>
            <path d="M14.2663 4.89103C14.0292 5.36181 13.9463 5.89529 14.0294 6.41581C14.1125 6.93634 14.3574 7.41749 14.7293 7.79103L18.1393 11.348C18.186 11.3967 18.242 11.4355 18.3041 11.462C18.3661 11.4884 18.4329 11.5021 18.5003 11.5021C18.5678 11.5021 18.6345 11.4884 18.6966 11.462C18.7586 11.4355 18.8147 11.3967 18.8613 11.348L22.2613 7.79903C22.634 7.42609 22.88 6.94542 22.9645 6.425C23.0491 5.90458 22.9678 5.37075 22.7323 4.89903C22.5548 4.5415 22.2949 4.23129 21.9739 3.99403C21.6529 3.75677 21.2801 3.59926 20.8862 3.53449C20.4923 3.46972 20.0887 3.49956 19.7086 3.62154C19.3285 3.74352 18.9829 3.95415 18.7003 4.23603L18.6743 4.26303C18.6272 4.30957 18.5636 4.33566 18.4973 4.33566C18.4311 4.33566 18.3675 4.30957 18.3203 4.26303L18.3003 4.23603C18.0183 3.95407 17.6732 3.74317 17.2936 3.62071C16.9141 3.49826 16.5108 3.46776 16.1172 3.53173C15.7235 3.5957 15.3507 3.7523 15.0294 3.98864C14.7081 4.22497 14.4476 4.53426 14.2693 4.89103H14.2663Z" fill="#49494F"/>
            <path d="M23 11C22.7348 11 22.4804 11.1054 22.2929 11.2929C22.1054 11.4804 22 11.7348 22 12C22 14.6522 20.9464 17.1957 19.0711 19.0711C17.1957 20.9464 14.6522 22 12 22C9.34784 22 6.8043 20.9464 4.92893 19.0711C3.05357 17.1957 2 14.6522 2 12C2 11.7348 1.89464 11.4804 1.70711 11.2929C1.51957 11.1054 1.26522 11 1 11C0.734784 11 0.48043 11.1054 0.292893 11.2929C0.105357 11.4804 0 11.7348 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 11.7348 23.8946 11.4804 23.7071 11.2929C23.5196 11.1054 23.2652 11 23 11Z" fill="#49494F"/>
            <path d="M9.72711 2.25897C11.2228 1.91397 12.7774 1.91397 14.2731 2.25897C14.348 2.27557 14.4244 2.28396 14.5011 2.28397C14.7458 2.28281 14.9816 2.19197 15.1638 2.02865C15.346 1.86534 15.462 1.64088 15.4899 1.39778C15.5177 1.15468 15.4554 0.909807 15.3149 0.70952C15.1743 0.509232 14.9652 0.367434 14.7271 0.31097C12.9326 -0.102029 11.0677 -0.102029 9.27311 0.31097C9.01478 0.371174 8.79096 0.53153 8.65087 0.756761C8.51078 0.981992 8.4659 1.25365 8.52611 1.51197C8.58631 1.77029 8.74667 1.99412 8.9719 2.1342C9.19713 2.27429 9.46879 2.31917 9.72711 2.25897V2.25897Z" fill="#49494F"/>
        </g>
        <defs>
            <clipPath id="clip0">
                <rect width="24" height="24" fill="white"/>
            </clipPath>
        </defs>
    </svg>
}
