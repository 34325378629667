import { isRoomRoomlistSpaActive } from "@multimediallc/cb-roomlist-prefetch"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { getRequestAnimationFrameFunction, isPuffin } from "@multimediallc/web-utils/modernizr"
import { pageContext, roomDossierContext } from "../../cb/interfaces/context"
import { currentSiteSettings } from "../../cb/siteSettings"
import { postCb } from "../api"
import { SubSystemType } from "../debug"
import { Component } from "../defui/component"
import { EventRouter, ListenerGroup } from "../events"
import { requestFullscreen } from "../fullscreen"
import { ignoreCatch } from "../promiseUtils"
import { parseRoomStatus, RoomStatus } from "../roomStatus"
import { VideoMode, videoModeHandler } from "../videoModeHandler"
import { VideoMetrics } from "./videoMetrics"
import type { IPlayer, IVideoControls } from "./interfaces"
import type { IQualityLevel } from "./playerSettings"
import type { IRoomContext } from "../context"
import type { RoomStatusNotifier } from "../roomStatusNotifier"

export class JpegPushPlayer extends Component implements IPlayer {
    public readonly requestControlVisibility = new EventRouter<boolean>("requestControlVisibility")

    readonly supportsAutoplayWithAudio = false
    public possibleQualityLevelsChanged = new EventRouter<IQualityLevel[]>("possibleQualityLevelsChanged") // Needed for interface
    public updateLLHLSButton = new EventRouter<{ allowed: boolean, enabled: boolean }>("updateLLHLSButton") // Needed for interface
    public videoOfflineChange = new EventRouter<boolean>("videoOfflineChange")
    public playbackStart = new EventRouter<undefined>("playbackStart")
    private roomName: string
    private lastFrameTime = 0
    private requestAnimationFrame: (callback: FrameRequestCallback) => number
    private playerImage: HTMLImageElement
    private loading = false
    private muted = true
    private volume: number
    private stopped = true
    private url = ""
    private lastTime = 0
    private listenerGroup = new ListenerGroup()
    public videoControls: IVideoControls | undefined
    private videoOffline: boolean
    public isDisabled = false
    private containerElement: HTMLElement | undefined

    private videoMetrics: VideoMetrics

    constructor(private roomStatusNotifier: RoomStatusNotifier, public isHlsPlaceholder = false) {
        super()

        this.videoMetrics = new VideoMetrics(this.isHlsPlaceholder ? "JpegToHlsPlayer" : "JpegPushPlayer")
        this.videoMetrics.bindAll()

        this.element.style.background = `#333333 url(${STATIC_URL}cam_notice_background.jpg) center center / cover`
        this.element.className = "videoPlayerDiv"

        this.element.style.position = "static"
        this.element.dataset.testid = "video-container"

        this.playerImage = document.createElement("img")
        this.playerImage.style.width = "100%"
        this.playerImage.style.height = "100%"
        this.playerImage.style.objectFit = "contain"
        this.playerImage.style.backgroundColor = "black"
        this.playerImage.dataset.testid = "video"
        this.playerImage.id = "chat-player"

        this.playerImage.onload = () => {
            this.playerImage.style.display = "inline"
            this.loading = false
        }
        this.playerImage.onerror = () => {
            this.playerImage.style.display = "none"
            this.lastTime -= 100  // Force a reload
            this.loading = false
        }
        this.element.appendChild(this.playerImage)

        if (!isPuffin()) {
            this.requestAnimationFrame = getRequestAnimationFrameFunction()
            this.requestAnimationCallback(0)
        }
    }

    private updateAjaxUrl(): Promise<void> | undefined {
        this.lastTime = Date.now()
        if (this.roomName === undefined) {
            return
        }
        return postCb("get_edge_hls_url_ajax/", {
            "room_slug": this.roomName,
            "jpeg": "1",
        }).then((xhr) => {
            const p = new ArgJSONMap(xhr.responseText)
            const success = p.getBoolean("success")
            if (parseRoomStatus(p) === RoomStatus.Offline) {
                this.videoOfflineChange.fire(true)
                this.stop()
                return
            } else {
                this.videoOfflineChange.fire(false)
            }
            p.ignore("url")
            p.ignore("hidden_message")

            if (!success) {
                error("Unable to refresh JPEG stream", { "error": xhr.responseText }, SubSystemType.Video)
                return
            }
            this.url = p.getString("cbjpeg_url", false)
            p.logUnusedDebugging("parseJpegStream")
        }).catch((err) => {
            error("Unable to refresh JPEG stream", { "error": err }, SubSystemType.Video)
        })
    }

    private requestAnimationCallback(timestamp: number): void {
        if (this.isHlsPlaceholder) {
            return
        }
        const now = Date.now()
        if (now - this.lastTime > 5000) {
            void this.updateAjaxUrl()
        }
        if (this.stopped || this.url === "" || isPuffin()) {
            this.playerImage.style.visibility = "hidden"
        } else if ((this.lastFrameTime === 0 || timestamp - this.lastFrameTime > 140) && !this.loading) {
            this.loading = true
            this.lastFrameTime = timestamp
            this.playerImage.src = `${this.url}&f=${Math.random()}`
            if (this.playerImage.style.visibility !== "visible") {
                this.playbackStart.fire(undefined)
                this.playerImage.style.visibility = "visible"
            }
        }
        this.requestAnimationFrame.call(window, (timestamp: number) => {
            this.requestAnimationCallback(timestamp)
        })
    }

    handleRoomLoaded(context: IRoomContext): void {
        this.stopped = false
        this.listenerGroup.removeAll()
        context.chatConnection.event.statusChange.listen((roomStatusChangeNotification) => {
            if (this.roomStatusNotifier.displaysForStatus(roomStatusChangeNotification.currentStatus) || this.videoOffline) {
                this.stop()
            } else {
                switch (roomStatusChangeNotification.currentStatus) {
                    default:
                        this.stopped = false
                }
            }
        }).addTo(this.listenerGroup)
        this.roomName = context.dossier.room
        this.url = pageContext.current.isTestbed ? "" : `${currentSiteSettings.jpegStreamUrl}stream?room=${this.roomName}`
        if (roomDossierContext.getState().activePassword) {
            // we need an authorized url to access the jpeg stream in passworded rooms
            this.updateAjaxUrl()?.then(() => {
                this.updateImage()
            }).catch(ignoreCatch)
        } else {
            this.updateImage()
        }
    }

    updateImage(): void {
        this.requestControlVisibility.fire(true)

        if (this.isHlsPlaceholder) {
            this.playerImage.src = `${this.url}&f=${Math.random()}`
            this.showPlayerImage()
        }
    }

    // Needed for playing sounds
    setVolume(volume: number): void {
        this.volume = volume
    }

    // Needed for playing sounds
    getVolume(): number {
        return this.muted ? 0 : this.volume
    }

    // Needed for playing sounds
    setMuted(muted: boolean): void {
        this.muted = muted
    }

    // Needed for interface
    stop(): void {
        this.playerImage.style.visibility = "hidden"
        this.stopped = true
    }

    showPlayerImage(): void {
        this.playerImage.style.visibility = "visible"
    }

    // Needed for interface
    setQualityLevel(): void {}

    public showControls(): void {
        this.requestControlVisibility.fire(true)
    }

    public hideControls(): void {
        this.requestControlVisibility.fire(false)
    }

    // Needed for interface
    public getControlBarHeight(): number {return 0}

    // Needed for interface
    public enterFullScreenMode(): void {
        // consider adding enter full window mode when switching to fullscreen from IFS
        videoModeHandler.setFireVideoMode(VideoMode.Fullscreen)
        // Try to make parent element fullscreen if player has a parent element; otherwise, just fullscreen jpegPlayer
        let fullscreenElem = this.element
        if (this.containerElement !== undefined) {
            fullscreenElem = this.containerElement
        }
        requestFullscreen(fullscreenElem)
    }

    // Needed for interface
    public setVolumeMuted(volume: number, muted: boolean): void {}

    afterRemovedFromParent(): void {
        this.stop()
        this.listenerGroup.removeAll()
    }

    public getVideoElement(): HTMLVideoElement | undefined {
        return undefined
    }

    public getIsHlsPlaceholder(): boolean {
        return this.isHlsPlaceholder
    }

    public onForceRemoved(): void {
        this.videoMetrics.playerForceRemoved()
    }

    public disable(disable: boolean): void {
        this.isDisabled = disable
    }

    public forceHlsFallback(): void {
        return
    }

    public forceStream(): void {
        return
    }

    public getSource(): string {
        return this.url
    }

    public setSource(source: string): void {
        return
    }

    public setContainerElement(container: HTMLElement): void {
        this.containerElement = container
    }

    public stopVideoAndMetrics(): void {
        if (!isRoomRoomlistSpaActive()) {
            return
        }
        this.stop()
    }

    public shouldDisallowLLHLS(): boolean {
        return true
    }
}
