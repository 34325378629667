interface IEmoticon {
    width: number
    height: number
    imgUrl: string
    name: string
    abuseUrl: string
    thumbUrl?: string
}

export const emptyEmoticon: IEmoticon = {
    height: 0,
    name: "",
    imgUrl: "",
    width: 0,
    abuseUrl: "",
}

interface IParseResult {
    stringParts: string[]
    emoticons: IEmoticon[]
}

export class ParsedEmoticonMessage {
    _parseResult: IParseResult

    constructor(message: string) {
        this._parseResult = parseEmoticons(message)
    }

    stringParts(): string[] {
        return this._parseResult.stringParts
    }

    emoticons(): IEmoticon[] {
        return this._parseResult.emoticons
    }
}

function parseEmoticon(emoticon: string): IEmoticon|undefined {
    emoticon = emoticon.replace("%%%[emoticon ", "").replace("]%%%", "")
    const emoticonParts = emoticon.split(/\|/)
    if (emoticonParts.length === 6) {
        const emoticon: IEmoticon = {
            name: emoticonParts[0],
            thumbUrl: emoticonParts[1],
            width: validDimension(emoticonParts[2]) ? Number(emoticonParts[2]) : 80,
            height: validDimension(emoticonParts[3]) ? Number(emoticonParts[3]) : 80,
            imgUrl: emoticonParts[4],
            abuseUrl: emoticonParts[5],
        }
        if (emoticon.thumbUrl !== undefined && validUrl(emoticon.thumbUrl)) {
            return emoticon
        } else {
            return undefined
        }
    } else if (emoticonParts.length === 5) {
        const emoticon: IEmoticon = {
            name: emoticonParts[0],
            imgUrl: emoticonParts[1],
            width: validDimension(emoticonParts[2]) ? Number(emoticonParts[2]) : 80,
            height: validDimension(emoticonParts[3]) ? Number(emoticonParts[3]) : 80,
            abuseUrl: emoticonParts[4],
        }
        if (validUrl(emoticon.imgUrl)) {
            return emoticon
        } else {
            return undefined
        }
    } else {
        return undefined
    }
}

function parseEmoticons(msg: string): IParseResult {
    const startToken = "%%%[emoticon "
    const endToken = "]%%%"
    let buffer: string[] = []
    const emoticonParts: IEmoticon[] = []
    const stringParts: string[] = []
    const hasNoEmoticons: boolean = msg.indexOf(startToken) < 0

    if (hasNoEmoticons) {
        return {
            stringParts: [msg],
            emoticons: [],
        }
    }
    for (let char = 0; char < msg.length; char += 1) {
        if (msg[char] === "%") {
            if (msg.slice(char, char + startToken.length) === startToken) {
                const emoticonEndIndex = char + msg.slice(char, msg.length).indexOf(endToken)
                if (msg.slice(emoticonEndIndex, emoticonEndIndex + endToken.length) === endToken) {
                    stringParts.push(buffer.join(""))
                    buffer = []
                    const emoticon = parseEmoticon(msg.slice(char, emoticonEndIndex + endToken.length))
                    if (emoticon !== undefined) {
                        emoticonParts.push(emoticon)
                    } else {
                        error(`Cannot parse emoticon: ${emoticon}`)
                        emoticonParts.push(emptyEmoticon)
                    }
                    char = emoticonEndIndex + endToken.length - 1
                }
            } else {
                buffer.push(msg[char])
            }
        } else {
            buffer.push(msg[char])
        }
    }
    stringParts.push(buffer.join(""))
    return {
        stringParts: stringParts,
        emoticons: emoticonParts,
    }
}

function validUrl(str: string): boolean {
    const res = str.match(/(http|https):\/\/(\w+:{0,1}\w*@)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%@!\-\/]))?/g)
    if (res === null) {
        error("Invalid emoticon URL")
        return false
    } else {
        return true
    }
}

function validDimension(str: string): boolean {
    if (isNaN(parseInt(str, 10))) {
        error("Invalid dimension argument")
        return false
    } else {
        return true
    }
}
