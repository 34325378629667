import { addColorClass } from "../../cb/colorClasses"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { applyStyles } from "../DOMutils"
import { addPageAction } from "../newrelic"
import { getGenderIconUrl, getUsernameColorClass } from "../roomUtil"
import { userPanelRequest } from "./userActionEvents"
import type { IRoomMessage, IUserInfo } from "../messageInterfaces"

// region Helpers
export function createUsernameLabel(u: IUserInfo, message?: IRoomMessage, isUserList = false, isMention = false, forPureChat = false): HTMLSpanElement {
    const span = document.createElement("span")
    addColorClass(span, getUsernameColorClass(u))
    span.style.fontWeight = "bold"
    // adding this class for an onclick listener for the mobile UCM in portraitContents.ts
    span.classList.add("username")
    span.innerText = isMention ? `@${u.username}` : u.username
    span.style.maxWidth = "100%"
    span.style.whiteSpace = "nowrap"
    span.style.overflowX = "hidden"
    span.style.textOverflow = "ellipsis"
    span.style.display = "inline-block"
    span.style.verticalAlign = "bottom"
    span.style.margin = "0"

    if (isUserList) {
        const genderImg = document.createElement("img")
        genderImg.src = getGenderIconUrl((u.gender as string))
        applyStyles(genderImg, {
            height: "16px",
            verticalAlign: "middle",
            margin: "0 10px 0 0",
        })
        applyStyles(span, { fontWeight: "normal" })
        span.insertBefore(genderImg, span.firstChild)
    }

    // Do not add pointer event listeners for mobile purechat
    // as we do not want to show the user panel in fullscreen mode
    if (!forPureChat) {
        span.onmouseenter = () => {
            span.style.textDecoration = "underline"
            span.style.cursor = "pointer"
        }
        span.onmouseleave = () => {
            span.style.textDecoration = "none"
            span.style.cursor = "default"
        }
        addEventListenerPoly("click", span, (e: MouseEvent) => {
            let parent: HTMLElement | null = span.parentElement

            // for handling username mentions
            while (parent !== null && parent.tagName !== "DIV") {
                parent = parent.parentElement
            }
            if (parent === null) {
                error("Username span has no parent")
            } else {
                addPageAction("OpenUserPanel", { "username": u.username })
                e.stopPropagation()
                userPanelRequest.fire({
                    username: u.username,
                    message: message,
                    attachTo: parent,
                })
            }
        })
    }
    return span
}
