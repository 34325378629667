import { addEventListenerPoly } from "../../../../common/addEventListenerPolyfill"
import { normalizeResource } from "../../../../common/api"
import { applyStyles } from "../../../../common/DOMutils"
import { DropDownComponent, PushmenuDropDownComponent } from "../../../../common/dropDownComponent"
import { i18n } from "../../../../common/translation"
import { addColorClass } from "../../../colorClasses"
import { defaultSiteSettings } from "../../../siteSettings"
import { BaseTabsContainer } from "../../../ui/tabs"
import { Events } from "../events"
import { openFeedbackForm } from "./userFeedbackFormEvents"
import { UpdatesTab } from "./userUpdates/updatesTab"
import type { ToggleEvent } from "../../../../common/dropDownComponentBase"
import type { EventRouter } from "../../../../common/events"


export class UserUpdatesPanel extends BaseTabsContainer {
    updatesTabs: UpdatesTab[]
    isDropDownVisible = false
    dropDownComponent: DropDownComponent
    private tabsLoaded = 0

    // user-updates-panel
    constructor(triggerElement: HTMLElement, isMobile = false) {
        super()

        this.element.id = "UserUpdatesPanel"
        this.element.dataset.testid = "user-updates-panel"
        addColorClass(this.element, "userUpdatesPanel")
        this.tabHandlesRow.element.style.borderBottom = "none"
        this.tabHandlesRow.element.style.padding = "4px 0 0 6px"
        this.tabHandlesRow.element.style.marginBottom = "4px"
        this.tabHandlesRow.element.style.boxSizing = "border-box"
        this.window.style.position = "static"

        this.tabHandleStyle = { ...this.tabHandleStyle, fontSize: "12px", padding: "5px 10px" }

        this.updatesTabs = [new UpdatesTab(isMobile)]

        this.updatesTabs.forEach((updatesTab) => {
            this.addTab(updatesTab)
        })

        Events.dropDownToggle.listen(isShowing => {
            if (isShowing) {
                this.tabsLoaded = 0
                this.updatesTabs.forEach(updatesTab => updatesTab.loadFeed())
                if (this.tabsLoaded === this.updatesTabs.length) {
                    this.switchToMostRecentUnreadTab()
                }
            } else {
                this.currentTab = undefined! // eslint-disable-line @typescript-eslint/no-non-null-assertion
            }
        })

        Events.tabItemsLoaded.listen(() => {
            this.tabsLoaded += 1
        })

        this.addFeedbackLink()
        this.dropDownComponent = this.createDropDownComponent(triggerElement)
    }

    protected createDropDownComponent(triggerElement: HTMLElement): DropDownComponent {
        return new UserUpdatesDropDown(this, triggerElement)
    }

    initAdditionalToggleElement(additionalToggleElement: HTMLDivElement): void {
        Events.updatesAreUnread.listen(() => {
            if (this.anyUpdatesTabUnread()) {
                additionalToggleElement?.classList.add("has_unseen")
            }
        })

        Events.updatesAreRead.listen(() => {
            if (!this.anyUpdatesTabUnread()) {
                const hasUnreadPms = document.querySelector("#dmListIconRoot")?.classList.contains("has_unread") ?? false

                if (!hasUnreadPms) {
                    additionalToggleElement.className = additionalToggleElement.className.replace("has_unseen", "")
                }
            }
        })
    }

    /** Switch to tab with newest notification, default to prev tab if there's a tie. */
    private switchToMostRecentUnreadTab() {
        this.changeToTab(this.updatesTabs.reduce((prev, curr) => {
            return curr.highestUnreadTs > prev.highestUnreadTs ? curr : prev
        }))
    }

    public anyUpdatesTabUnread(): boolean {
        return this.updatesTabs.some(updatesTab => updatesTab.hasUnread())
    }

    protected addFeedbackLink(): void {
        this.addFooter(constructFeedbackLink(false))
    }

    repositionChildren(): void {
    }

    protected initUI(): void {
        this.element.style.overflow = ""
        this.element.style.position = "static"
        this.element.style.borderWidth = "1px"
        this.element.style.borderStyle = "solid"
        this.element.style.borderTop = "none"
        this.element.style.borderRadius = "0 0 4px 4px"
    }
}

export class MobileUserUpdatesPanel extends UserUpdatesPanel {
    dropDownComponent: MobileUserUpdatesDropDown

    constructor(triggerElement: HTMLElement) {
        super(triggerElement, true)
        const heightAdjust = 60

        for (let i = 1; i < this.element.children.length - 1; i += 1) {
            const child = this.element.children[i] as HTMLElement
            child.style.height = `calc(100% - ${heightAdjust}px)`
        }
    }

    protected createDropDownComponent(triggerElement: HTMLElement): DropDownComponent {
        return new MobileUserUpdatesDropDown(this, triggerElement)
    }

    protected addFeedbackLink(): void {
        this.addFooter(constructFeedbackLink(true))
    }

    protected initUI(): void {
        super.initUI()
        this.element.style.borderRight = "0"
    }
}

function constructFeedbackLink(mobile: boolean): HTMLAnchorElement {
    const feedbackLink = document.createElement("A") as HTMLAnchorElement
    addColorClass(feedbackLink, "feedbackLink")
    feedbackLink.innerText = i18n.howCanWeImproveText
    feedbackLink.style.fontSize = "11px"
    feedbackLink.style.textAlign = "center"
    feedbackLink.style.width = "100%"
    feedbackLink.style.display = "block"
    feedbackLink.style.padding = "5px 0"
    feedbackLink.style.marginBottom = "7px"
    feedbackLink.dataset.testid = "how-can-we-improve"

    if (mobile) {
        feedbackLink.href = normalizeResource(defaultSiteSettings.userUpdatesFeedbackLink)
        feedbackLink.target = "_blank"
    } else {
        feedbackLink.href = "#"
        feedbackLink.onclick = () => {
            openFeedbackForm.fire({ source: "site_updates" })
        }
    }
    return feedbackLink
}

function registerUpdateHandlers(panel: UserUpdatesPanel, toggleElement: HTMLElement,
    toggleEvent: EventRouter<ToggleEvent>): void {
    toggleEvent.listen(evt => {
        // onToggle fires on any DOM element clicked so watch for state change
        if (panel.isDropDownVisible !== evt.isShowing) {
            panel.isDropDownVisible = evt.isShowing
            Events.dropDownToggle.fire(evt.isShowing)
            toggleElement.classList.toggle("active", evt.isShowing)
        }
    })

    Events.updatesAreUnread.listen(() => {
        if (panel.anyUpdatesTabUnread()) {
            toggleElement.classList.add("has_unseen")
        }
    })

    Events.updatesAreRead.listen(() => {
        panel.refreshTabs()
        if (!panel.anyUpdatesTabUnread()) {
            toggleElement.className = toggleElement.className.replace("has_unseen", "")
        }
    })

    panel.updatesTabs.forEach((updatesTab) => {
        // load initial feed and subscribe to push service topics
        updatesTab.loadFeed()
    })
}

class UserUpdatesDropDown extends DropDownComponent {
    constructor(panel: UserUpdatesPanel, toggleElement: HTMLElement) {
        super(toggleElement)

        this.element.appendChild(panel.element)
        registerUpdateHandlers(panel, toggleElement, this.toggleEvent)
    }

    protected initUI(): void {
        super.initUI()

        const userUpdatesDropdownStyles: CSSX.Properties = {
            maxHeight: "368px",
            height: "",
            width: "100%",
            zIndex: 1001, // less than over 18 overlay more than others
            backgroundColor: "transparent",
            fontFamily: "UbuntuBold, Helvetica, Arial, sans-serif",
            left: "-1px",
            top: "24px",
            right: "auto",
        }

        applyStyles(this.element, userUpdatesDropdownStyles)
    }
}

class MobileUserUpdatesDropDown extends PushmenuDropDownComponent {
    private pushmenu: HTMLElement | null

    constructor(panel: UserUpdatesPanel, toggleElement: HTMLElement) {
        super(toggleElement)

        this.element.appendChild(panel.element)
        registerUpdateHandlers(panel, toggleElement, this.toggleEvent)
    }

    protected initUI(): void {
        super.initUI()

        const headerHeight = 46
        this.element.style.maxHeight = ""
        this.element.style.width = "100%"
        this.element.style.zIndex = "1001" // less than over 18 overlay more than others
        this.element.style.backgroundColor = "#FFFFFF"
        this.element.style.fontFamily = "UbuntuBold, Helvetica, Arial, sans-serif"
        this.element.style.left = "-1px"
        this.element.style.top = "0"
        this.element.style.height = `calc(100% - ${headerHeight}px)`
        this.element.style.top = `${headerHeight}px`

        this.pushmenu = document.querySelector<HTMLElement>(".pushmenu.main-menu")

        if (this.pushmenu !== null) {
            addEventListenerPoly("touchmove", this.pushmenu, () => {
                if (this.isShown()) {
                    this.hideElement()
                }
            })
        }
    }

    public showElement(): boolean {
        const shown = super.showElement()
        this.scrollTopPushmenu()

        return shown
    }

    private scrollTopPushmenu(): void {
        if (this.pushmenu !== null) {
            this.pushmenu.scrollTop = 0
        }
    }
}
