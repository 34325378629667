import { addIgnoreUser, isIgnored, removeIgnoreUser } from "../../cb/api/ignore"
import { dmsEnabled } from "../../cb/api/pm"
import { addColorClass, colorClass } from "../../cb/colorClasses"
import { createDmWindowRequest } from "../../cb/components/pm/dmWindowsManager"
import { pageContext } from "../../cb/interfaces/context"
import { modalAlert, modalConfirm } from "../alerts"
import { postCb } from "../api"
import { isNotLoggedIn } from "../auth"
import { roomLoaded } from "../context"
import { fetchUserMenuData } from "../fetchUserMenuData"
import { ModalComponent } from "../modalComponent"
import { addPageAction } from "../newrelic"
import { OptimisticUpdate } from "../optimisticUpdate"
import { OverlayComponent } from "../overlayComponent"
import { getGenderIconUrl } from "../roomUtil"
import { i18n } from "../translation"
import { mentionUser, userInitiatedPm } from "../userActionEvents"
import { MobileChatReport } from "./MobileChatReport"
import { loadRoomRequest, toggleDms } from "./userActionEvents"
import type { IChatConnection } from "../context"
import type { IMenuData } from "../fetchUserMenuData"
import type { IRoomMessage } from "../messageInterfaces"

export interface IUserPanelRequest {
    username: string
    message?: IRoomMessage
    attachTo: HTMLElement
}

let roomName: string
let currentUsername: string | undefined
let chatConn: IChatConnection
// eslint-disable-next-line @multimediallc/no-global-listener
roomLoaded.listen((context) => {
    roomName = context.dossier.room
    currentUsername = context.dossier.userName
    chatConn = context.chatConnection
})

const linkColorDisabled = "#6c757d"
const linkColor = "#156b95"

export class UserPanel extends OverlayComponent {
    protected username: string
    protected roomImage: HTMLImageElement
    protected displayAge: HTMLSpanElement
    protected genderIcon: HTMLImageElement
    protected profileLink: HTMLSpanElement
    protected ignoreLink: HTMLSpanElement
    private silenceLink: HTMLSpanElement | undefined
    protected sendPMLink: HTMLSpanElement
    protected sendDMLink?: HTMLSpanElement
    protected mentionLink: HTMLSpanElement
    protected needsSupporterToPm: boolean
    protected reportLink: HTMLSpanElement
    private isChatReportShowing = false
    private userPanelContent: HTMLDivElement
    protected chatReport: MobileChatReport | undefined
    protected reportModal = new ModalComponent({
        onShow: () => {
            this.onReportModalShown()
        },
        onHide: () => {
            this.onReportModalHidden()
        },
        easyExit: true,
    })
    protected isIgnored = false
    protected ignoreUpdate: OptimisticUpdate<boolean>

    constructor(viewProfile = true) {
        super()

        currentUsername = pageContext.current.loggedInUser?.username
        this.userPanelContent = document.createElement("div")
        this.element.appendChild(this.userPanelContent)

        this.element.style.position = "relative"
        this.element.style.width = "auto"
        this.element.style.height = "auto"
        this.element.style.minHeight = "40px"
        this.element.style.padding = "4px"
        this.element.style.border = "1px solid #cccccc"
        this.element.style.borderRadius = "6px"
        this.element.style.marginBottom = "16px"
        this.element.style.fontWeight = "normal"
        addColorClass(this.element, "userPanel")
        addColorClass(this.element, colorClass.bgColor)

        this.roomImage = document.createElement("img")
        this.roomImage.style.width = "47px"
        this.roomImage.style.height = "40px"
        this.roomImage.style.marginRight = "16px"
        this.roomImage.style.verticalAlign = "middle"
        this.roomImage.style.borderRadius = "4px"
        this.roomImage.style.display = "none"
        this.userPanelContent.appendChild(this.roomImage)

        this.genderIcon = document.createElement("img")
        this.genderIcon.style.position = "relative"
        this.genderIcon.style.top = "3px"
        this.genderIcon.style.height = "16px"
        this.genderIcon.style.padding = "6px 16px 0 0"
        this.genderIcon.style.display = "none"
        this.userPanelContent.appendChild(this.genderIcon)

        this.displayAge = document.createElement("span")
        this.displayAge.innerText = "--"
        this.displayAge.style.verticalAlign = "middle"
        this.displayAge.style.marginRight = "16px"
        this.displayAge.style.display = "none"
        this.userPanelContent.appendChild(this.displayAge)

        this.profileLink = createLink(i18n.viewProfile)
        this.profileLink.onclick = () => {
            loadRoomRequest.fire(this.username)
        }
        if (viewProfile) {
            this.userPanelContent.appendChild(this.profileLink)
        }

        this.sendPMLink = createLink(i18n.pmMenuTitle)
        this.sendPMLink.onclick = () => {
            if (isNotLoggedIn(i18n.loggedInToPm)) {
                return
            }
            userInitiatedPm.fire({ username: this.username, focus: true, showSupporterAlert: this.needsSupporterToPm })
        }
        this.userPanelContent.appendChild(this.sendPMLink)

        if (dmsEnabled()) {
            this.sendDMLink = createLink(i18n.dmMenuTitle)
            this.sendDMLink.onclick = () => {
                if (isNotLoggedIn(i18n.loggedInToDm)) {
                    return
                }

                toggleDms.fire(true)
                createDmWindowRequest.fire(this.username)
            }
            this.userPanelContent.appendChild(this.sendDMLink)
        }

        this.mentionLink = createLink(i18n.mention)
        this.mentionLink.style.display = "inline-block"
        this.mentionLink.onclick = () => {
            mentionUser.fire(this.username)
        }
        this.userPanelContent.appendChild(this.mentionLink)

        this.ignoreLink = createLink(i18n.ignore)
        this.ignoreUpdate = new OptimisticUpdate<boolean>(
            false,
            (value: boolean, isPending: boolean) => this.setIsIgnored(value, isPending),
            () => {
                const ignoreError = this.isIgnored ? i18n.errorUnignoringUser(this.username) : i18n.errorIgnoringUser(this.username)
                modalAlert(ignoreError)
            },
        )
        this.ignoreLink.onclick = () => {this.onIgnoreUnignoreClick()}
        this.userPanelContent.appendChild(this.ignoreLink)

        this.reportModal.element.style.backgroundColor = "rgba(0, 0, 0, 0.3)"
        this.reportModal.element.style.position = "fixed"
        this.reportLink = createLink(i18n.report)
        this.reportLink.onclick = () => {
            if (this.isChatReportShowing) {
                this.reportModal.hide()
            } else {
                addPageAction("ReportChatUser", { "username": this.username })
                this.reportModal.show()
            }
        }
        this.reportModal.overlayClick.listen(() => {
            this.reportModal.hide()
        })
        this.userPanelContent.appendChild(this.reportLink)
    }

    protected onIgnoreUnignoreClick(): void {
        if (isNotLoggedIn(`${i18n.loginForIgnoreClickOkToLogin}`)) {
            return
        }

        const ignoreAction = (username: string) => this.isIgnored ? chatConn.unignore(username) : chatConn.ignore(username)
        this.ignoreUpdate.dispatch(!this.isIgnored, ignoreAction(this.username))
    }

    private setIsIgnored(isIgnored: boolean, isPending: boolean): void {
        this.isIgnored = isIgnored
        if (isIgnored) {
            this.ignoreLink.innerText = i18n.unignore
        } else {
            this.ignoreLink.innerText = i18n.ignore
        }
        if (isPending) {
            this.ignoreLink.style.pointerEvents = "none"
            this.ignoreLink.style.color = linkColorDisabled
        } else {
            this.ignoreLink.style.pointerEvents = ""
            this.ignoreLink.style.color = linkColor
        }
        if (this.chatReport !== undefined) {
            this.chatReport.updateIgnoreText(this.isIgnored)
        }
    }

    // eslint-disable-next-line complexity
    public updateContents(username: string, message?: IRoomMessage): void {
        this.username = username
        if (username === roomName) {
            this.profileLink.style.display = "none"
        } else {
            this.profileLink.style.display = "inline-block"
        }
        if (chatConn.username() === roomName) {
            this.ignoreLink.style.display = "none"
        } else {
            this.ignoreLink.style.display = "inline-block"
            this.sendPMLink.style.display = "none"
        }
        if (currentUsername === this.username) {
            this.ignoreLink.style.display = "none"
            this.reportLink.style.display = "none"
            this.mentionLink.style.display = "none"
            this.sendPMLink.style.display = "none"

            if (this.sendDMLink !== undefined) {
                this.sendDMLink.style.display = "none"
            }
        } else {
            if (message === undefined) {
                this.reportLink.style.display = "none"
            } else {
                this.reportLink.style.display = "inline-block"
                this.createChatReport(message)
            }
            this.mentionLink.style.display = "inline-block"

            if (roomName === currentUsername) {
                this.sendPMLink.style.display = "inline-block"
            }
            if (this.sendDMLink !== undefined) {
                this.sendDMLink.style.display = "inline-block"
            }
        }
        this.roomImage.style.display = "none"
        this.genderIcon.style.display = "none"
        this.displayAge.style.display = "none"

        // remove silence link here so that the wrong link doesn't get shown before fetchUserMenuData returns
        if (this.silenceLink !== undefined) {
            this.userPanelContent.removeChild(this.silenceLink)
            this.silenceLink = undefined
        }
        fetchUserMenuData(this.username).then((data) => {
            this.needsSupporterToPm = data.needsSupporterToPm
            if (data.canAccess) {
                this.genderIcon.src = getGenderIconUrl(data.gender)
                this.displayAge.innerText = !isNaN(data.displayAge) ? `${data.displayAge}` : ""
                this.roomImage.src = data.imageUrl
                if (data.online) {
                    this.roomImage.style.display = "inline"
                }
                this.genderIcon.style.display = "inline"
                this.displayAge.style.display = "inline"
            }
            this.ignoreUpdate.dispatch(chatConn.isIgnored(this.username), Promise.resolve(true))
            this.setSilenceLink(data)
        }).catch((err) => {
            error("Could not process user menu request", err)
        })
    }

    protected createChatReport(message: IRoomMessage): void {
        if (this.chatReport !== undefined) {
            this.reportModal.removeChild(this.chatReport)
        }
        this.chatReport = new MobileChatReport(this.username, message, chatConn)
        this.reportModal.addChild(this.chatReport)
        this.chatReport.element.style.display = "none"
        this.chatReport.closeChatReportRequest.listen((wasFormSubmitted: boolean) => {
            this.reportModal.hide()
            if (wasFormSubmitted) {
                this.overlayClick.fire(undefined)
                if (this.chatReport !== undefined) {
                    this.chatReport.tearDown()
                }
            }
        })
        this.chatReport.ignoreReportedUser.listen(() => {
            this.ignoreUpdate.dispatch(true, chatConn.ignore(this.username))
        })
    }

    private setSilenceLink(userMenuData: IMenuData): void {
        if (!userMenuData.canSilence) {
            return
        }
        if (userMenuData.silenceId !== undefined) {
            this.silenceLink = createLink(i18n.removeSilenceMessage)
            this.silenceLink.onclick = () => {
                postCb(
                    "edit_room_ban/",
                    { "banid": String(userMenuData.silenceId), "action": "remove_silence", "room_username": roomName },
                ).catch((xhr) => {
                    error(xhr)
                    modalAlert(`Error removing silence from user ${userMenuData.username}`)
                })
                this.hide()
            }
        } else {
            this.silenceLink = createLink(i18n.silenceText)
            this.silenceLink.onclick = () => {
                modalConfirm(`Silence ${userMenuData.username}?`, () => {
                    addPageAction("SilenceUser", { "username": userMenuData.username })
                    postCb(`roomsilence/${userMenuData.username}/${roomName}/`, {}).then(() => {
                    }).catch((err) => {
                        error(
                            `Unable to silence user (${err})`,
                            { "room": roomName, "username": userMenuData.username },
                        )
                        modalAlert(`Error silencing user ${userMenuData.username}`)
                    })
                })
                this.hide()
            }
        }
        this.silenceLink.style.display = "inline-block"
        this.userPanelContent.appendChild(this.silenceLink)
    }

    protected onReportModalShown(): void {
        this.reportLink.style.textDecoration = "underline"
        this.reportModal.element.style.top = "0"
        if (this.chatReport !== undefined) {
            this.chatReport.element.style.display = ""
            this.chatReport.repositionChildren()
        }
        this.isChatReportShowing = true
    }

    protected onReportModalHidden(): void {
        this.isChatReportShowing = false
        this.reportLink.style.textDecoration = "none"
        this.reportModal.element.style.top = ""
        if (this.chatReport !== undefined) {
            this.chatReport.element.style.display = "none"
            this.chatReport.resetForm()
        }
    }

    public hide(): void {
        this.hideOverlay()
        this.element.remove()
    }
}

export class DmUserPanel extends UserPanel {
    constructor() {
        super(true)
        this.profileLink.onclick = () => {
            if (chatConn !== undefined) {  // in room
                loadRoomRequest.fire(this.username)
            } else {
                window.location.pathname = `/${this.username}/`
            }

            toggleDms.fire(false)
        }
    }

    protected onIgnoreUnignoreClick(): void {
        if (isNotLoggedIn(`${i18n.loginForIgnoreClickOkToLogin}`)) {
            return
        }

        const ignoreAction = () => {
            if (this.isIgnored) {
                return removeIgnoreUser(this.username).then(() => {
                    return true
                })
            } else {
                return addIgnoreUser(this.username).then(() => {
                    return true
                })
            }
        }

        this.ignoreUpdate.dispatch(!this.isIgnored, ignoreAction())
    }

    public updateContents(username: string, message?: IRoomMessage): void {
        this.username = username
        this.element.style.top = ""
        this.profileLink.style.display = "inline-block"
        this.mentionLink.style.display = "none"

        if (this.sendPMLink !== undefined) {
            this.sendPMLink.style.display = "none"
        }
        if (this.sendDMLink !== undefined) {
            this.sendDMLink.style.display = "none"
        }
        if (currentUsername === this.username) {
            this.ignoreLink.style.display = "none"
            this.reportLink.style.display = "none"
        } else {
            this.ignoreLink.style.display = "inline-block"

            if (message === undefined) {
                this.reportLink.style.display = "none"
            } else {
                this.reportLink.style.display = "inline-block"
                this.createChatReport(message)
            }
        }

        this.roomImage.style.display = "none"
        this.genderIcon.style.display = "none"
        this.displayAge.style.display = "none"

        fetchUserMenuData(this.username).then((data: IMenuData) => {
            this.needsSupporterToPm = data.needsSupporterToPm

            if (data.canAccess) {
                this.genderIcon.src = getGenderIconUrl(data.gender)
                this.displayAge.innerText = !isNaN(data.displayAge) ? `${data.displayAge}` : ""
                this.roomImage.src = data.imageUrl

                if (data.online) {
                    this.roomImage.style.display = "inline"
                }

                this.genderIcon.style.display = "inline"
                this.displayAge.style.display = "inline"
            }

            this.ignoreUpdate.dispatch(isIgnored(this.username), Promise.resolve(true))
        }).catch((err) => {
            error("Could not process user menu request", err)
        })
    }

    protected createChatReport(message: IRoomMessage): void {
        if (this.chatReport !== undefined) {
            this.reportModal.removeChild(this.chatReport)
        }

        this.chatReport = new MobileChatReport(this.username, message, undefined)
        this.reportModal.addChild(this.chatReport)
        this.chatReport.element.style.display = "none"

        this.chatReport.closeChatReportRequest.listen((wasFormSubmitted: boolean) => {
            this.reportModal.hide()

            if (wasFormSubmitted) {
                this.overlayClick.fire(undefined)

                if (this.chatReport !== undefined) {
                    this.chatReport.tearDown()
                }
            }
        })
    }
}

function createLink(text: string): HTMLSpanElement {
    const link = document.createElement("span")
    link.innerText = text
    link.style.verticalAlign = "middle"
    link.style.textDecoration = "none"
    link.style.cursor = "pointer"
    link.style.color = linkColor
    link.style.padding = "0 16px 6px 0"
    return link
}
