export class FormItem {
    label: HTMLLabelElement
    input: HTMLInputElement | HTMLTextAreaElement
    error: HTMLElement
    element: HTMLDivElement

    constructor(inputType: string, name: string, label: string, required = true) {
        this.element = document.createElement("div")

        this.label = document.createElement("label")
        this.label.htmlFor = name
        this.label.innerText = label

        if (inputType === "textarea") {
            this.input = document.createElement("textarea")
        } else {
            this.input = document.createElement("input")
            this.input.type = inputType
        }
        this.input.name = name
        if (required) {
            this.input.required = required
        }

        this.error = document.createElement("p")
        this.error.style.display = "none"

        this.element.appendChild(this.label)
        this.element.appendChild(this.input)
        this.element.appendChild(this.error)
    }

    public setError(errorStr: string): void {
        this.error.textContent = errorStr
        this.error.style.display = ""
    }

    public clearError(): void {
        this.error.textContent = ""
        this.error.style.display = "none"
    }
}
