import { ArgJSONMap } from "@multimediallc/web-utils"
import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { getCb } from "../../../common/api"
import { EventRouter } from "../../../common/events"

export const newFollowedOnlineEvent = new EventRouter<undefined>("newFollowedOnline")

export const onlineFollowedStorageEvent = new EventRouter<IOnlineFollowedStorage>("onlineFollowedStorage")
export const followedDropdownClickedEvent = new EventRouter<IOnlineFollowedStorage>("onlineFollowedStorage")

const onlineFollowedKey = "onlineFollowedTab"
const dropdownClickedKey = "followedDropdownClicked"

export function setOnlineFollowedStorage(username: string, onlineFollowed: IOnlineFollowedList, seen: Record<string, boolean>, flash: boolean, unseenRooms: IFollowedRoom[]): void {
    if (!isLocalStorageSupported()) {
        return
    }
    const data = {
        "username": username,
        "onlineFollowedList": onlineFollowed,
        "seenRooms": seen,
        "unseenRooms": unseenRooms,
        "flash": flash,
        "timestamp": Date.now(),
    }
    window.localStorage.setItem(onlineFollowedKey, JSON.stringify(data))
}

export function clearLocalStorageUnseen(): void {
    if (!isLocalStorageSupported()) {
        return
    }
    const oft = window.localStorage.getItem(onlineFollowedKey)
    if (oft === null) {
        return
    }
    const oftParsed = JSON.parse(oft)
    for (const room of oftParsed["unseenRooms"]) {
        oftParsed["seenRooms"][room["room"]] = true
    }
    oftParsed["timestamp"] = Date.now()
    oftParsed["flash"] = false
    oftParsed["unseenRooms"] = []
    window.localStorage.setItem(onlineFollowedKey, JSON.stringify(oftParsed))
}

export function setDropdownClickedStorage(): void {
    if (!isLocalStorageSupported()) {
        return
    }
    window.localStorage.setItem(dropdownClickedKey, JSON.stringify({ "timestamp": Date.now() }))
}

export function updateStorageTimestamp(): void {
    if (!isLocalStorageSupported()) {
        return
    }
    const oft = window.localStorage.getItem(onlineFollowedKey)
    if (oft === null) {
        return
    }
    const oftParsed = JSON.parse(oft)
    oftParsed["timestamp"] = Date.now()
    window.localStorage.setItem(onlineFollowedKey, JSON.stringify(oftParsed))
}

export function getOnlineFollowedStorage(): IOnlineFollowedStorage | undefined {
    if (!isLocalStorageSupported()) {
        return
    }
    const oft = window.localStorage.getItem(onlineFollowedKey)
    if (oft === null) {
        return
    }
    return JSON.parse(oft)
}

export function watchOnlineFollowedStorage(): void {
    if (!isLocalStorageSupported()) {
        return
    }
    addEventListenerPoly("storage", window, (e: StorageEvent) => {
        if (e.key === onlineFollowedKey) {
            const newFS = getOnlineFollowedStorage()
            if (newFS !== undefined) {
                onlineFollowedStorageEvent.fire(newFS)
            }
        }
        if (e.key === dropdownClickedKey && e.newValue !== null) {
            const newFS = getOnlineFollowedStorage()
            if (newFS !== undefined) {
                newFS.timestamp = JSON.parse(e.newValue)["timestamp"]
                newFS.flash = false
                newFS.unseenRooms = []
                followedDropdownClickedEvent.fire(newFS)
            }
        }
    })
    return
}

export interface IFollowedRoom {
    room: string
    image: string
}

export interface IOnlineFollowed {
    online: number
    total: number
}

export interface IOnlineFollowedList {
    onlineFollowed: IOnlineFollowed,
    roomList: IFollowedRoom[]
}

export interface IOnlineFollowedStorage {
    username: string,
    onlineFollowedList: IOnlineFollowedList,
    seenRooms: Record<string, boolean>,
    unseenRooms: IFollowedRoom[]
    flash: boolean,
    timestamp: number,
}

export function parseFollowedText(str: string): IOnlineFollowed {
    const parts = str.split(/[()/]+/)
    if (parts.length !== 4) {
        return {
            online: 0,
            total: 0,
        }
    }
    return {
        online: parseInt(parts[1]),
        total: parseInt(parts[2]),
    }
}

export function getOnlineFollowed(): Promise<IOnlineFollowedList> {
    const parseRoomList = (ss: ArgJSONMap[] | undefined) => {
        if (ss === undefined) {
            return []
        }
        return ss.map((value) => {
            return {
                room: value.getString("room"),
                image: value.getString("image"),
            }
        })
    }
    return getCb("/follow/api/online_followed_rooms/").then((xhr) => {
        if (xhr.status !== 200) {
            return Promise.reject()
        }
        const data = new ArgJSONMap(xhr.responseText)
        if (!data.valid) {
            // If XHR status was 200 but data is invalid, this probably means the user is banned
            // and the API request redirected. Return empty results so the dropdown clears.
            return {
                onlineFollowed: {
                    online: 0,
                    total: 0,
                },
                roomList: [],
            }
        }

        return {
            onlineFollowed: {
                online: data.getNumber("online"),
                total: data.getNumber("total"),
            },
            roomList: parseRoomList(data.getList("online_rooms")),
        }
    })
}
