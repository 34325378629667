import { postCb } from "../api"

type QualityAttributes = Record<string, string | number | boolean | undefined>

export class QualityNotifier {
    private pendingMessages: QualityAttributes[] = []
    private readonly intervalId: number
    private readonly qualityUpdatesKey = "qualityUpdates"
    private readonly saveTimeSecondsKey = "saveTimeSeconds"
    private readonly messagesKey = "messages"


    constructor() {
        if (typeof sessionStorage !== "undefined") {
            this.loadFromSessionStorage()
        }
        this.intervalId = window.setInterval(() => this.sendMessages(), 5000)
    }

    public addMessage(formattedAttributes: QualityAttributes): void {
        this.pendingMessages.push(formattedAttributes)
    }

    public stop(): void {
        clearInterval(this.intervalId)
        if (typeof sessionStorage == "undefined") {
            this.sendMessages()
        } else {
            this.saveToSessionStorage()
        }
    }

    private sendMessages(): void {
        if (this.pendingMessages.length > 0) {
            const update = { [this.qualityUpdatesKey]: JSON.stringify(this.pendingMessages) }
            this.pendingMessages = []
            void postCb("api/ts/chat/send-player-quality/", update)
        }
    }

    private saveToSessionStorage(): void {
        if (this.pendingMessages.length > 0) {
            sessionStorage.setItem(this.qualityUpdatesKey, JSON.stringify({
                [this.saveTimeSecondsKey]: new Date().getTime() / 1000,
                [this.messagesKey]: this.pendingMessages,
            }))
            this.pendingMessages = []
        }
    }

    private loadFromSessionStorage(): void {
        const serializedQualityUpdates = sessionStorage.getItem(this.qualityUpdatesKey)
        sessionStorage.removeItem(this.qualityUpdatesKey)
        if (serializedQualityUpdates == null) {
            this.pendingMessages = []
        } else {
            const timestampWithMessage = JSON.parse(serializedQualityUpdates)
            const timeNowSeconds = new Date().getTime() / 1000
            if (timeNowSeconds - timestampWithMessage[this.saveTimeSecondsKey] <= 60) {
                this.pendingMessages = timestampWithMessage[this.messagesKey]
            }
        }
    }

    public sendImmediate(formattedAttributes: QualityAttributes): void {
        this.pendingMessages.push(formattedAttributes)
        this.sendMessages()
    }
}
