import { t } from "@lingui/macro"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { getLocalStorageWithExpiration, setLocalStorageWithExpiration } from "@multimediallc/web-utils/storage"
import { addIgnoreUser, getIgnoredSet, IGNORE_USER_LIMIT, isIgnored, loadIgnoreList, removeIgnoreUser } from "../../cb/api/ignore"
import { tokenBalanceUpdate } from "../../cb/api/tipping"
import { getSmcSharedWith } from "../../cb/components/showMyCam/smcViewer"
import { pageContext, roomDossierContext } from "../../cb/interfaces/context"
import { Shortcode, shortcodeHelpMsg } from "../../cb/interfaces/shortcode"
import { PushService } from "../../cb/pushservicelib/pushService"
import { UserIgnoreTopic } from "../../cb/pushservicelib/topics/user"
import { RoomListSource } from "../../cb/roomList"
import { modalAlert, modalConfirm } from "../alerts"
import { getCb, postCb } from "../api"
import { isAnonymous } from "../auth"
import { roomCleanup } from "../context"
import { Debouncer, DebounceTypes } from "../debouncer"
import { SubSystemType } from "../debug"
import { EventRouter } from "../events"
import { featureFlagIsActive } from "../featureFlag"
import { RoomNoticeType } from "../messageInterfaces"
import { getUserType } from "../messageToDOM"
import { addPageAction } from "../newrelic"
import { getPrivateShowInfo, storePrivateShowInfo } from "../player/playerSettings"
import { declinePrivateShow, ShowRequestErrorType } from "../privateShow"
import { ignoreCatch } from "../promiseUtils"
import { EnterLeaveSettings } from "../roomDossier"
import { RoomStatus } from "../roomStatus"
import { i18n } from "../translation"
import { buildQueryString } from "../urlUtil"
import { appDebuggingToggled } from "../userActionEvents"
import { PushServiceHandler } from "./pushServiceMessageHandler"
import { hashtagPart, stringPart, userPart } from "./roomnoticeparts"
import { WowzaHandler } from "./wowzaHandler"
import type { IRefreshPanel } from "../../cb/interfaces/asp"
import type { IShortcode, IShortcodeForm } from "../../cb/interfaces/shortcode"
import type { AudioHolderSound } from "../audioHolder"
import type { IChatConnection } from "../context"
import type {
    IBanSilenceInfo, IIgnoreTopic, IPrivateShowRequestNotification, IRemoveMessagesNotification,
    IRoomMessage, IRoomNotice, IRoomStatusChangeNotification, ISettingsUpdateNotification,
    IShortcodeMessage,
    ITipAlert, IUserInfo,
} from "../messageInterfaces"
import type { ShowRequestError } from "../privateShow"
import type { IAppInfo, IRoomDossier } from "../roomDossier"

export class ChatConnection implements IChatConnection {
    // Used for push-service verification
    public previousStatus: RoomStatus = RoomStatus.Offline
    public status: RoomStatus = RoomStatus.Offline
    public statusAfterConnected: RoomStatus
    public wowzaHandler: WowzaHandler
    private pushServiceHandler: PushServiceHandler | undefined
    public isModerator = false
    private appDebuggingEnabled = false
    private roomEntryFor: EnterLeaveSettings
    private roomLeaveFor: EnterLeaveSettings
    public privatePrice: number
    public privateMinEnd = 0
    public privateShowRequestingUser = ""
    public spyPrice: number
    public fanClubSpyPrice: number | undefined
    public premiumShowActive = false
    public premiumPrivatePrice: number | undefined
    public premiumRequested = false
    public isBroadcasting = false
    public isNewConnection = false
    private exploringHashTag = ""
    private sourceName = RoomListSource.Unknown
    private static readonly privateViewStatuses: RoomStatus[] = [
        RoomStatus.PrivateWatching,
        RoomStatus.PrivateNotWatching,
        RoomStatus.PrivateSpying,
    ]
    static noFollowMessageInStatus: Set<RoomStatus> = new Set<RoomStatus>(ChatConnection.privateViewStatuses)
    private roomCountPresenceThrottle = new Debouncer(() => { this.roomCountPresence().catch(ignoreCatch) }, { debounceType: DebounceTypes.trailThrottle, bounceLimitMS: 90 * 1000 })

    public readonly event = {
        messageSent: new EventRouter<undefined>("messageSent"),
        roomMessage: new EventRouter<IRoomMessage>("roomMessage"),
        roomNotice: new EventRouter<IRoomNotice>("roomNotice"),
        roomShortcode: new EventRouter<IShortcodeMessage>("roomShortcode"),

        // do not fire statusChange directly.  Instead, call `conn.changeStatus`
        statusChange: new EventRouter<IRoomStatusChangeNotification>("statusChange", { listenersWarningThreshold: 20 }),

        hiddenMessageChange: new EventRouter<string>("hiddenMessageChange"),
        refreshPanel: new EventRouter<IRefreshPanel | undefined>("refreshPanel"),
        titleChange: new EventRouter<string>("titleChange"),
        clearApp: new EventRouter<undefined>("clearApp"),
        removeMessages: new EventRouter<IRemoveMessagesNotification>("removeMessages"),
        settingsUpdate: new EventRouter<ISettingsUpdateNotification>("settingsUpdate"),

        roomCountUpdate: new EventRouter<number>("roomCountUpdate"),
        modStatusChange: new EventRouter<boolean>("modStatusChange"),
        tokenBalanceUpdate: tokenBalanceUpdate,
        privateShowRequest: new EventRouter<IPrivateShowRequestNotification>("privateShowRequest"),
        playSound: new EventRouter<AudioHolderSound>("playSound"),
        appTabRefresh: new EventRouter<undefined>("appTabRefresh"),
        appDebugLog: new EventRouter<string>("appDebugLog"),

        tipAlert: new EventRouter<ITipAlert>("tipAlert"),

        leftRoom: new EventRouter<IUserInfo>("leftRoom"),
        connectionLost: new EventRouter<undefined>("connectionLost"),
        onBanSilence: new EventRouter<IBanSilenceInfo>("onBanSilence"),
    }

    constructor(private readonly d: IRoomDossier, enablePushService = false) {
        this.isNewConnection = false
        this.isBroadcasting = this.username() === this.room()
        if (!isAnonymous()) {
            loadIgnoreList()
        }

        this.isModerator = d.isModerator
        this.updateEnterLeaveSettings(d.userChatSettings.roomEntryFor, d.userChatSettings.roomLeaveFor)
        if (this.isBroadcasting) {
            this.statusAfterConnected = RoomStatus.Public
        } else {
            this.statusAfterConnected = d.roomStatus
        }
        this.privatePrice = d.privatePrice
        this.spyPrice = d.spyPrice
        if (featureFlagIsActive("FanClubSpying")) {
            this.fanClubSpyPrice = d.fanClubSpyPrice
        }
        this.premiumShowActive = d.premiumShowActive
        this.premiumPrivatePrice = d.premiumPrivatePrice
        this.exploringHashTag = this.d.exploringHashTag
        this.sourceName = this.d.sourceName
        this.wowzaHandler = new WowzaHandler(this.d, this)
        if (enablePushService) {
            this.pushServiceHandler = new PushServiceHandler(this.d, this)
        }
        if (this.statusAfterConnected === RoomStatus.PrivateWatching) {
            this.loadLastPrivateInfo()
        }
        this.changeStatus(RoomStatus.NotConnected)
        this.listenForIgnores()
    }

    public appsRunning(): IAppInfo[] {
        return this.d.appsRunning
    }

    disconnect(): void {
        this.wowzaHandler.disconnect()
        this.pushServiceHandler?.cleanup()
    }

    // changeStatus should be called instead of firing the statusChange event directly
    public changeStatus(s: RoomStatus): void {
        if (s === this.status) {
            return
        }
        debug(`Status changing from ${this.status} to ${s}`)
        this.previousStatus = this.status
        this.status = s
        if (this.isBroadcasting && this.previousStatus === RoomStatus.NotConnected && this.status === RoomStatus.Public) {
            declinePrivateShow()
        }

        this.event.statusChange.fire({ previousStatus: this.previousStatus, currentStatus: this.status })
    }

    public updateEnterLeaveSettings(entrySetting: EnterLeaveSettings, leaveSetting: EnterLeaveSettings): void {
        if (this.isModerator || this.isBroadcasting) {
            this.roomEntryFor = entrySetting
            this.roomLeaveFor = leaveSetting
        } else {
            this.roomEntryFor = EnterLeaveSettings.ModsAndFans
            this.roomLeaveFor = EnterLeaveSettings.ModsAndFans
        }
    }

    public isAppDebuggingEnabled(): boolean {
        return this.appDebuggingEnabled
    }

    public toggleAppDebugging(): void {
        this.appDebuggingEnabled = !this.appDebuggingEnabled
        appDebuggingToggled.fire(this.appDebuggingEnabled)
    }

    // eslint-disable-next-line complexity
    private viewerInPrivateChat(): boolean {
        switch (this.status) {
            case RoomStatus.PrivateWatching:
                return true
            case RoomStatus.PrivateRequesting:
            case RoomStatus.PrivateNotWatching:
            case RoomStatus.PasswordProtected:
            case RoomStatus.Public:
            case RoomStatus.Away:
            case RoomStatus.Hidden:
            case RoomStatus.HiddenWatching:
            case RoomStatus.Offline:
            case RoomStatus.PrivateSpying:
            case RoomStatus.NotConnected:
                return false
            default:
                warn(`unexpected status: ${this.status}`)
                return false
        }
    }

    public sendShortcode(form: IShortcodeForm): void {
        // If one of the shortcodes is `help`, only show help message and don't do anything else
        if (form.shortcodes.map((sc) => sc.code.toLowerCase()).includes(Shortcode.Help)) {
            this.event.roomNotice.fire({
                messages: [[stringPart(shortcodeHelpMsg(this.room(), this.isBroadcasting))]],
                showInPrivateMessage: true,
            })
            return
        }

        // Shortcodes sending a chat message could add users to presence/userlist, disable for internal staff
        if (pageContext.current.isNoninteractiveUser) {
            modalAlert(i18n.internalStaffMessage)
            return
        }

        const scs = form.shortcodes.map((sc) => {
            const body: IShortcode  = { "code": sc.code }
            if (sc.msg !== undefined) {
                body["msg"] = sc.msg
            }
            if (sc.amt !== undefined) {
                body["amt"] = sc.amt
            }
            return body
        })
        const body: Record<string, string> = {
            "room": this.room(),
            "shortcodes": JSON.stringify(scs),
            "message": JSON.stringify({ "m": form.message }),
        }

        postCb("push_service/publish_chat_message_live/", body).then((xhr) => {
            let errorMessage = xhr.getResponseHeader("x-banned") ?? xhr.getResponseHeader("x-denied")
            if (errorMessage !== null) {
                errorMessage = decodeURIComponent(errorMessage)
                if (PushService.isEnabledForUI()) {
                    this.event.roomNotice.fire({ messages: [[stringPart(errorMessage)]], showInPrivateMessage: true })
                }
            }
        }).catch((_) => {
            this.event.roomNotice.fire({ messages: [[stringPart(i18n.shortcodeGeneralError)]], showInPrivateMessage: true })
        })
    }

    public sendRoomMessage(message: string): void {
        if (pageContext.current.isNoninteractiveUser) {
            modalAlert(i18n.internalStaffMessage)
            return
        }
        this.event.messageSent.fire(undefined)
        addPageAction("SendRoomMessage")
        const wowzaFallbackSend = (m: string) => {
            this.wowzaHandler.sendMessage(
                this.viewerInPrivateChat() ? "messagePrivateRoom" : "messageRoom",
                {
                    "room": this.d.room,
                    "msg": JSON.stringify({
                        "m": m,
                        "f": "",
                        "c": "",
                        "tid": "",
                    }),
                },
            )
        }

        if (this.pushServiceHandler === undefined) {
            warn("push service send handler not active", {}, SubSystemType.PushService)
            wowzaFallbackSend(message)
            return
        }

        const inPrivateRoom = this.viewerInPrivateChat()
        this.pushServiceHandler.sendMessage(this.d.room, message).then((json) => {
            this.wowzaHandler.sendMessage(
                inPrivateRoom ? "messagePrivateRoom" : "messageRoom",
                {
                    "room": this.d.room,
                    "msg": JSON.stringify(json),
                },
            )
        }).catch(err => {
            error("Publishing live message", err, SubSystemType.PushService)
            wowzaFallbackSend(message)
        })
    }

    public updateRoomCount(ignoreThrottle = false): void {
        if (!PushService.isEnabledForUserList()) {
            this.wowzaHandler.sendMessage(
                "updateRoomCount",
                {
                    "room_uid": this.d.roomUid,
                    "model_name": this.room(),
                    "private_room": this.inPrivateRoom(),
                },
            )
        }
        if (ignoreThrottle) {
            // roomCountPresence cannot throw - caught inside function
            // eslint-disable-next-line @typescript-eslint/no-floating-promises
            this.roomCountPresence()
        } else {
            this.roomCountPresenceThrottle.callFunc()
        }
    }

    private roomCountPresence(): Promise<void> {
        if (pageContext.current.isNoninteractiveUser) {
            return Promise.resolve()
        }
        const privateShowId = roomDossierContext.getState().privateShowId
        const query: Record<string, string> = { "presence_id": PushService.presenceId }
        if (privateShowId !== "" && this.inPrivateRoom()) {
            query["private_show_id"] = privateShowId
        }
        const url = `push_service/room_user_count/${this.room()}/?${buildQueryString(query)}`
        return getCb(url).then(xhr => {
            if (PushService.isEnabledForUserList()) {
                const p = new ArgJSONMap(xhr.responseText)
                const count = p.getNumber("count")
                this.event.roomCountUpdate.fire(count)
                PushService.setVerifierRoomCount(count)
            }
        }).catch((err: Error) => {
            warn("roomUserCount", err, SubSystemType.PushService)
        })
    }

    public kickUser(username: string): void {
        this.wowzaHandler.sendMessage(
            "kickUser",
            {
                "user": username,
                "room": this.room(),
            },
        )
    }

    public requestPrivateShow(price: number, minimumMinutes: string, recordingsAllowed: boolean, premiumChosen = false, delayFiringEvent = false): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            if (this.status !== RoomStatus.Public) {
                reject({
                    errorType: ShowRequestErrorType.statusNotAllowed,
                    message: t`The room must be Public to request a private show.`,
                } as ShowRequestError)
                return
            }
            this.premiumRequested = premiumChosen
            // in case open in other tab ensure this window is connected
            this.wowzaHandler.ensureConnected()
            postCb(`tipping/private_show_request/${this.room()}/`, {
                "chat_username": this.username(),
                "price": `${price}`,
                "private_show_minimum_minutes": `${minimumMinutes}`,
                "recordings_allowed": `${recordingsAllowed}`,
                "premium_show": `${premiumChosen}`,
                "delay_firing_event": `${delayFiringEvent}`,
            }).then((xhr) => {
                const p = new ArgJSONMap(xhr.responseText)
                const success = p.getBoolean("success")
                if (!success) {
                    reject(this.parseShowRequestError(p))
                    return
                }
                this.changeStatus(RoomStatus.PrivateRequesting)
                roomDossierContext.setState({ roomStatus: RoomStatus.PrivateRequesting })
                const msg = premiumChosen ? i18n.premiumPrivateShowRequestMessage : i18n.privateShowRequestMessage
                this.event.roomNotice.fire({
                    messages: [[stringPart(msg)]],
                    foreground: "#222",
                    background: "#ff8b45",
                    weight: "bold",
                    showInPrivateMessage: true,
                    toUser: this.room(),
                })

                resolve()
            }).catch((err) => {
                reject(err)
            })
        })
    }

    public requestSpyShow(price: number, fanclubPrice?: number): Promise<void> {
        return new Promise((resolve, reject) => {
            if (this.status !== RoomStatus.PrivateNotWatching) {
                const status = this.status
                let message: string
                switch (status) {
                    case RoomStatus.Away:
                        message = t`You cannot request a spy show when the broadcaster is away.`
                        break
                    default:
                        message = t`The room must currently be in a private show to request a spy show.`
                }
                reject({ errorType: ShowRequestErrorType.statusNotAllowed, message: message } as ShowRequestError)
                return
            }
            // in case open in other tab ensure this window is connected
            this.wowzaHandler.ensureConnected()
            postCb(`tipping/spy_on_private_show_request/${this.room()}/`, {
                "chat_username": this.username(),
                "price": `${price}`,
                "fan_club_price": `${fanclubPrice}`,
            }).then((xhr) => {
                const p = new ArgJSONMap(xhr.responseText)
                const success = p.getBoolean("success")
                if (!success) {
                    reject(this.parseShowRequestError(p))
                    return
                }
                this.changeStatus(RoomStatus.PrivateSpying)
                roomDossierContext.setState({ roomStatus: RoomStatus.PrivateSpying })
                this.event.roomNotice.fire({
                    messages: [[stringPart("You are spying on the private show")]],
                    foreground: "#222",
                    background: "#ff8b45",
                    weight: "bold",
                    showInPrivateMessage: true,
                })
                resolve()
            }).catch((err) => {
                reject(err)
            })
        })
    }

    private parseShowRequestError(p: ArgJSONMap, isSpyShow = false): ShowRequestError {
        const errorType = p.getBooleanOrUndefined("low_tokens") === true ? ShowRequestErrorType.lowTokens : ShowRequestErrorType.default
        let message = p.getString("message", false)
        if (message === "") {
            message = isSpyShow ? t`There was an error joining the spy show` : t`There was an error requesting your private show`
        }
        return { errorType, message }
    }

    public setPrivateShowInfo(price: number, minimumMinutes: number): void {
        this.privatePrice = price
        this.privateMinEnd = Date.now() + minimumMinutes * 60 * 1000
        storePrivateShowInfo(this.room(), price, this.privateMinEnd)
    }

    public setPremiumShow(isPremium: boolean): void {
        this.premiumShowActive = isPremium
    }

    public leavePrivateOrSpyShow(allowLeaveEarly = false): Promise<void> {
        return new Promise((resolve, reject) => {
            const url = `tipping/private_show_cancel/${this.room()}/`
            let data = {}
            if (allowLeaveEarly) {
                data = { "understands_minimum_charge": true }
            }
            const prevStatus = this.status
            // eslint-disable-next-line complexity
            postCb(url, data).then((xhr) => {
                const p = new ArgJSONMap(xhr.responseText)
                const success = p.getBoolean("success")
                const secondsRemaining = p.getNumber("remaining_seconds")
                const minimumMinutes = p.getStringWithNumbers("private_show_minimum_minutes")
                const tokensPerMinute = p.getNumberOrUndefined("tokens_per_minute")
                const minimumTokens = Math.ceil(tokensPerMinute ?? this.privatePrice * ((this.privateMinEnd - Date.now()) / (60 * 1000)))
                p.logUnusedDebugging("parseCancelResult")

                switch (prevStatus) {
                    case RoomStatus.PrivateWatching:
                        if (!success) {
                            if (secondsRemaining === 0) {
                                reject({
                                    allowCancel: false,
                                    message: i18n.privateShowRequestCancelErrorMessage,
                                })
                            } else {
                                reject({
                                    allowCancel: true,
                                    message: "privateCancelEarly",
                                    earlyDetails: {
                                        minimumMinutes: minimumMinutes,
                                        tokensPerMinute: tokensPerMinute ?? this.privatePrice,
                                        secondsRemaining: secondsRemaining,
                                        tokensRemaining: minimumTokens,
                                    },
                                })
                            }
                            return
                        }
                        break

                    case RoomStatus.PrivateSpying:
                        if (!success) {
                            reject({
                                allowCancel: false,
                                message: i18n.unableToCancelSpyShow,
                            })
                            return
                        }
                        this.changeStatus(RoomStatus.PrivateNotWatching)
                        roomDossierContext.setState({ roomStatus: RoomStatus.PrivateNotWatching })
                        break

                    case RoomStatus.PrivateRequesting:
                        if (!success) {
                            reject({
                                allowCancel: false,
                                message: i18n.unableToCancelPrivateRequest,
                            })
                            return
                        }
                        break

                    default:
                        warn(`unable to leave private show from status: ${prevStatus}`)
                        if (!success) {
                            reject({
                                allowCancel: false,
                                message: i18n.unableToLeavePrivateShow(prevStatus),
                            })
                            return
                        }
                }
                resolve()
            }).catch(reject)
        })
    }

    public joinRoom(): void {
        this.wowzaHandler.sendMessage(
            "joinRoom",
            {
                "room": this.room(),
                "exploringHashTag": this.exploringHashTag,
                "source_name": this.sourceName,
            },
        )
        // Prevent sending hashtag and source multiple times in case of re-connection.
        this.exploringHashTag = ""
        this.sourceName = RoomListSource.Default
    }

    public leaveRoom(): void {
        this.wowzaHandler.sendMessage(
            "leaveRoom",
            { "room": this.room() },
        )
    }

    public joinPrivateRoom(): void {
        // Account for replication delay
        window.setTimeout(() => {
            this.wowzaHandler.sendMessage(
                "joinPrivateRoom",
                { "room": this.room() },
            )
        }, 500)
    }

    public leavePrivateRoom(): void {
        this.wowzaHandler.sendMessage(
            "leavePrivateRoom",
            { "room": this.room() },
        )
    }

    public inPrivateRoom(): boolean {
        return this.status === RoomStatus.PrivateWatching
    }

    public inPrivateOrSpy(): boolean {
        return [RoomStatus.PrivateWatching, RoomStatus.PrivateSpying].includes(this.status)
    }

    public setPrivateShowRequestingUser(username: string): void {
        this.privateShowRequestingUser = username
    }

    public getPrivateShowUser(): string {
        if (this.inPrivateRoom()) {
            return this.privateShowRequestingUser
        }
        return ""
    }

    public room(): string {
        return this.d.room
    }

    public age(): number | undefined {
        return this.d.age
    }

    public username(): string {
        return this.d.userName
    }

    public ignore(username: string): Promise<boolean> {
        if (isAnonymous()) {
            this.event.roomNotice.fire({
                messages: [[stringPart(i18n.loginToUseFeature)]],
                showInPrivateMessage: true,
            })
            return new Promise<boolean>(() => false)
        }

        if (this.d.room === this.username()) {
            error("Error: cannot ignore users in this room")
            return new Promise<boolean>(() => false)
        }

        const ignoreCacheKey = `${this.username()}-hitMaxIgnore`
        const atIgnoreLimit = getIgnoredSet().size >= IGNORE_USER_LIMIT
        return new Promise<boolean>((resolve) => {
            const sendIgnore = () => {
                if (atIgnoreLimit) {
                    setLocalStorageWithExpiration(ignoreCacheKey, "1", { days: 30 })
                }

                const originalIgnoreSet = getIgnoredSet()
                addIgnoreUser(username).then((success) => {
                    if (success) {
                        const newIgnoreSet = getIgnoredSet()
                        for (const ignoredUser of originalIgnoreSet) {
                            if (!newIgnoreSet.has(ignoredUser)) {
                                this.event.roomNotice.fire({
                                    messages: [[stringPart(i18n.removedOldestIgnore(ignoredUser))]],
                                    showInPrivateMessage: true,
                                })
                            }
                        }
                    } else {
                        // Success roomnotice is handled in this.listenForIgnores()
                        this.event.roomNotice.fire({
                            messages: [[stringPart(i18n.errorIgnoringUser(username))]],
                            showInPrivateMessage: true,
                        })
                    }
                    resolve(success)
                }).catch(ignoreCatch)
            }

            const previouslyHitIgnoreLimit = getLocalStorageWithExpiration(ignoreCacheKey)
            if (previouslyHitIgnoreLimit === undefined && atIgnoreLimit) {
                modalConfirm(i18n.reachedMaxIgnore, sendIgnore)
            } else {
                sendIgnore()
            }
        })
    }

    public unignore(username: string): Promise<boolean> {
        return removeIgnoreUser(username).then((success) => {
            if (!success) {
                // Success roomnotice is handled in this.listenForIgnores()
                this.event.roomNotice.fire({
                    messages: [[stringPart(i18n.errorUnignoringUser(username))]],
                    showInPrivateMessage: true,
                })
            }
            return success
        })
    }

    public isIgnored(username: string): boolean {
        return isIgnored(username)
    }

    private listenForIgnores(): void {
        const userUid = pageContext.current.loggedInUser?.userUid
        if (userUid !== undefined) {
            const listener = new UserIgnoreTopic(userUid).onMessage.listen((update: IIgnoreTopic) => {
                if (update.isIgnored) {
                    const message = i18n.ignoringUser(update.username)
                    this.event.roomNotice.fire({
                        messages: [[stringPart(message)]],
                        showInPrivateMessage: true,
                    })
                } else {
                    const message = i18n.noLongerIgnoring(update.username)
                    this.event.roomNotice.fire({
                        messages: [[stringPart(message)]],
                        showInPrivateMessage: true,
                    })
                }
            })
            roomCleanup.once(() => listener.removeListener(), false)
        }
    }

    /**
     * HACK: Remove this once wowza chat JARs have been upgraded with support for sourceName
     */
    private static updateUserInfoFromHashtag(userInfo: IUserInfo): void {
        switch (userInfo.exploringHashTag) {
            case "promotion":
                userInfo.exploringHashTag = ""
                userInfo.sourceName = RoomListSource.Promoted
                break
            case "onlineannouncement":
                userInfo.exploringHashTag = ""
                userInfo.sourceName = RoomListSource.FollowedNotification
                break
            default:
                break
        }
    }

    // eslint-disable-next-line complexity
    public roomEntry(userInfo: IUserInfo): void {
        // HACK: Remove this once wowza chat JARs have been upgraded with support for sourceName
        ChatConnection.updateUserInfoFromHashtag(userInfo)
        if (this.shouldShowJoinLeaveMessage(this.roomEntryFor, userInfo)) {
            // Populate user exploringHashTag only for broadcaster.
            const tag = this.isBroadcasting ? userInfo.exploringHashTag : ""

            const messages = [
                stringPart(getUserType(this.room(), userInfo)),
                userPart(userInfo),
                stringPart(` ${i18n.roomJoinedMessage}`),
            ]
            if (tag !== "") {
                // Add "(from #hashTag)" part.
                messages.push(stringPart(" (from "))
                messages.push(hashtagPart(tag, [tag]))
                messages.push(stringPart(")"))
            } else if (this.isBroadcasting && userInfo.sourceName === RoomListSource.Promoted) {
                messages.push(stringPart(" via promotion"))
            } else if (this.isBroadcasting && userInfo.sourceName === RoomListSource.FollowedNotification) {
                messages.push(stringPart(" via online announcement"))
            }
            messages.push(stringPart("."))
            this.event.roomNotice.fire({
                messages: [messages],
                showInPrivateMessage: userInfo.username === this.username(),
                dataNick: userInfo.username,
            })
        }
    }

    public roomLeave(userInfo: IUserInfo): void {
        if (this.shouldShowJoinLeaveMessage(this.roomLeaveFor, userInfo)) {
            let msg = ` ${i18n.roomLeftMessage}`
            if (userInfo.isBroadcaster && !ChatConnection.noFollowMessageInStatus.has(this.status)) {
                msg += ` ${i18n.roomFollowToGetNotified}`
            }
            this.event.roomNotice.fire({
                messages: [[
                    stringPart(getUserType(this.room(), userInfo)),
                    userPart(userInfo),
                    stringPart(msg),
                ]],
                showInPrivateMessage: userInfo.username === this.username(),
                dataNick: userInfo.username,
                noticeType: RoomNoticeType.RoomLeave,
            })
        }
    }

    private shouldShowJoinLeaveMessage(setting: EnterLeaveSettings, userInfo: IUserInfo): boolean { // eslint-disable-line complexity
        if (userInfo.username === this.username()) {
            return false
        }
        if (userInfo.username === getSmcSharedWith()) {
            return true
        }
        if (this.inPrivateRoom()) {
            return false
        }
        if (isIgnored(userInfo.username)) {
            return false
        }
        switch (setting) {
            case EnterLeaveSettings.None:
                return false
                break
            case EnterLeaveSettings.ModsAndFans:
                if (userInfo.username === this.room() || userInfo.isMod || userInfo.inFanclub) {
                    return true
                }
                break
            case EnterLeaveSettings.ModsFansAndTokens:
                if (userInfo.username === this.room() || userInfo.isMod || userInfo.inFanclub ||
                    userInfo.hasTokens && (this.isBroadcasting || this.isModerator)) {
                    return true
                }
                break
            case EnterLeaveSettings.AllUsers:
                if (userInfo.username === this.room() || userInfo.isMod || userInfo.inFanclub ||
                    (this.isBroadcasting || this.isModerator)) {
                    return true
                }
                break
            default:
                error("Invalid EnterLeaveSetting")
                return false
        }
        return false
    }

    private loadLastPrivateInfo(): void {
        const savedPrivateInfo = getPrivateShowInfo(this.room())
        if (savedPrivateInfo !== "") {
            const parsed = new ArgJSONMap(savedPrivateInfo)
            this.privatePrice = parsed.getNumber("price")
            this.privateMinEnd = parsed.getNumber("end")
        }
    }

    public hasAnyMessageOrNoticeBeenAdded(): boolean {
        return this.event.roomNotice.historyLength() > 0 || this.event.roomMessage.historyLength() > 0
    }
}
