import { EventRouter, eventsPmSessionsCount } from "../events"
import type { IUserChatSettings } from "../roomDossier"
import type { ITipRequest } from "../specialoutgoingmessages"
import type { IPrivateShowRequest } from "./privateShowRequestModal"

export const switchedToHLS = new EventRouter<undefined>("switchedToHLS")

export const openDefaultTipCalloutRequest = new EventRouter<ITipRequest>("openDefaultTipCalloutRequest")

export const loginOverlayRequest = new EventRouter<boolean>("loginOverlayRequest")

// Base limit + 2 per pm session
export const userChatSettingsUpdate = new EventRouter<IUserChatSettings>("userChatSettingsUpdate", { listenersWarningThreshold: () => 50 + 2 * eventsPmSessionsCount })

export const openPhotoVideoTabRequest = new EventRouter<number | undefined>("openPhotoVideoTabRequest")

export const privateShowSplitModeRequest = new EventRouter<IPrivateShowRequest|undefined>("privateShowSplitModeRequest")

export const privateShowRequestOverlayDismiss = new EventRouter<undefined>("privateShowRequestOverlayDismiss")

export const resetPrivateShowLink = new EventRouter<undefined>("resetPrivateShowLink")
