import { normalizeResource, postCb } from "../api"
import { roomLoaded } from "../context"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { EventRouter } from "../events"
import { i18n } from "../translation"
import { MenuLinkDrawerComponent } from "./menuDrawerComponent"
import { scrollFix } from "./scrollFix"
import type { IRoomContext } from "../context"

const CATEGORIES = {
    "underage": i18n.reportAbuseCatUnderage,
    "advertising": i18n.reportAbuseCatAdvertising,
    "abusive": i18n.reportAbuseCatAbusive,
    "intoxicated": i18n.reportAbuseCatIntoxicated,
    "large toy": i18n.reportAbuseCatLarge,
    "offline payments": i18n.reportAbuseCatOffline,
    "public broadcast": i18n.reportAbuseCatPublic,
    "service uniform": i18n.reportAbuseCatUniform,
    "sleeping": i18n.reportAbuseCatSleeping,
    "gender": i18n.reportAbuseCatGender,
    "other": i18n.reportAbuseCatOther,
}

export class ReportAbuseMenuItem extends MenuLinkDrawerComponent {
    private reportAbuse: ReportAbuse | undefined

    constructor(a: HTMLAnchorElement) {
        super(a)
        this.element.style.height = ""
    }

    protected initializeDrawer(): void {
        if (this.reportAbuse === undefined) {
            this.reportAbuse = new ReportAbuse()
            this.reportAbuse.closeEvent.listen((reset: boolean) => {
                this.toggleDrawer(false, reset)
            })
        }
        this.drawer = this.reportAbuse.element
    }

    protected addOrShowDrawer(): void {
        super.addOrShowDrawer()
        this.menuLink.style.fontWeight = "bold"
    }

    protected disposeDrawer(): void {
        super.disposeDrawer()
        this.reportAbuse = undefined
    }

    public hideDrawer(): void {
        super.hideDrawer()
        this.menuLink.style.fontWeight = "normal"
    }
}

export class ReportAbuse extends Component<HTMLDivElement> {
    public closeEvent = new EventRouter<boolean>("reset")

    private hideTimeout: number
    private formContainer = document.createElement("div")
    private blankOption = document.createElement("option")
    private submittedMsg = document.createElement("div")
    private categoryRequiredMsg = document.createElement("div")
    private commentsRequiredMsg = document.createElement("div")
    private categorySelect = document.createElement("select")
    private textBox = document.createElement("textarea")
    private reportAbuseForm = document.createElement("form")
    private room: string

    constructor() {
        super()
        this.hideTimeout = 0
        applyStyles(this.element, {
            display: "block",
            color: "#6C6C78",
            fontSize: "14px",
            height: "calc(100% - 45px)",
            boxSizing: "border-box",
            position: "absolute",
            padding: "19px",
        })
        scrollFix(this.element)
        this.element.appendChild(this.formContainer)

        this.reportAbuseForm.method = "post"
        this.reportAbuseForm.dataset.testid = "report-abuse-form"
        this.formContainer.appendChild(this.reportAbuseForm)

        const reportAbuseInnerDiv = document.createElement("div")
        reportAbuseInnerDiv.style.paddingBottom = "10px"
        reportAbuseInnerDiv.style.width = "100%"
        reportAbuseInnerDiv.style.display = "inline-block"
        reportAbuseInnerDiv.style.maxWidth = "100%"
        this.reportAbuseForm.appendChild(reportAbuseInnerDiv)

        this.categorySelect.style.border = "1px solid #A4A4A4"
        this.categorySelect.style.fontSize = "16px"
        this.categorySelect.style.width = "99%"
        this.categorySelect.style.fontFamily = "UbuntuRegular, Arial, Helvetica, sans-serif"
        this.categorySelect.dataset.testid = "report-abuse-category"
        reportAbuseInnerDiv.appendChild(this.categorySelect)

        this.blankOption.value = ""
        this.blankOption.disabled = true
        this.blankOption.selected = true
        this.blankOption.textContent = i18n.chooseCategoryText
        this.categorySelect.appendChild(this.blankOption)
        for (const category in CATEGORIES) {
            const categoryOption = document.createElement("option")
            categoryOption.value = category
            categoryOption.innerText = CATEGORIES[category as keyof typeof CATEGORIES]
            this.categorySelect.appendChild(categoryOption)
        }

        this.categoryRequiredMsg.style.display = "none"
        this.categoryRequiredMsg.style.color = "#EA3323"
        const span = document.createElement("span")
        span.textContent = i18n.reportAbuseCategoryRequired
        span.style.fontSize = "14px"
        this.categoryRequiredMsg.appendChild(span)
        reportAbuseInnerDiv.appendChild(this.categoryRequiredMsg)

        const label = document.createElement("p")
        label.style.fontWeight = "bold"
        label.style.margin = "0.5em 0px 0.2em"
        label.innerText = i18n.reportAbuseAdditionalComments
        reportAbuseInnerDiv.appendChild(label)

        this.textBox.draggable = false
        this.textBox.maxLength = 2048
        this.textBox.style.boxSizing = "border-box"
        this.textBox.style.width = "100%"
        this.textBox.style.fontSize = "16px"
        this.textBox.style.padding = "3px"
        this.textBox.style.border = "1px solid #A4A4A4"
        this.textBox.style.height = "55px"
        this.textBox.style.fontFamily = "UbuntuRegular, Arial, Helvetica, sans-serif"
        this.textBox.dataset.testid = "report-abuse-comments"
        scrollFix(this.textBox)
        reportAbuseInnerDiv.appendChild(this.textBox)

        this.commentsRequiredMsg.style.color = "#EA3323"
        this.commentsRequiredMsg.style.display = "none"
        const span1 = document.createElement("span")
        span1.textContent = i18n.reportAbuseDescriptionRequired
        span1.style.fontSize = "14px"
        this.commentsRequiredMsg.appendChild(span1)
        reportAbuseInnerDiv.appendChild(this.commentsRequiredMsg)

        const reportAbuseButtonBar = document.createElement("div")
        reportAbuseButtonBar.style.paddingTop = "10px"

        const submitBtn = document.createElement("a")
        submitBtn.textContent = i18n.reportCAPS
        submitBtn.style.cssFloat = "right"
        submitBtn.style.display = "block"
        submitBtn.style.width = "auto"
        submitBtn.style.padding = "6px 16px"
        submitBtn.style.fontSize = "16px"
        submitBtn.style.color = "#FFF"
        submitBtn.style.backgroundColor = "#EA3323"
        submitBtn.dataset.testid = "report-abuse-submit"
        reportAbuseButtonBar.appendChild(submitBtn)
        submitBtn.onclick = (event: MouseEvent) => {
            this.sendReport()
        }

        const cancelBtn = document.createElement("a")
        cancelBtn.textContent = i18n.cancelText
        cancelBtn.style.cssFloat = "right"
        cancelBtn.style.display = "block"
        cancelBtn.style.width = "auto"
        cancelBtn.style.padding = "6px 16px"
        cancelBtn.style.fontSize = "16px"
        cancelBtn.style.color = "#1C6B92"
        cancelBtn.style.marginRight = "8px"
        cancelBtn.dataset.testid = "report-abuse-cancel"
        reportAbuseButtonBar.appendChild(cancelBtn)
        cancelBtn.onclick = (event: MouseEvent) => {
            this.closeEvent.fire(true)
        }

        reportAbuseInnerDiv.appendChild(reportAbuseButtonBar)

        const clearDiv = document.createElement("div")
        clearDiv.style.clear = "both"
        reportAbuseInnerDiv.appendChild(clearDiv)

        const termsDiv = document.createElement("div")
        termsDiv.style.fontSize = "12px"
        termsDiv.style.marginTop = "20px"
        termsDiv.innerText = `${i18n.reportAbuseTextTerms} `

        const termsLink = document.createElement("a")
        termsLink.href = normalizeResource("/terms/#room-reports")
        termsLink.innerText = "Terms"
        termsLink.dataset.testid = "report-abuse-terms-link"
        termsDiv.appendChild(termsLink)
        reportAbuseInnerDiv.appendChild(termsDiv)

        this.submittedMsg.style.display = "none"
        this.submittedMsg.style.height = "80px"
        this.submittedMsg.style.lineHeight = "80px"
        this.submittedMsg.style.textAlign = "center"
        this.submittedMsg.style.fontWeight = "bold"
        this.submittedMsg.innerText = i18n.commentSubmittedMessage
        this.element.appendChild(this.submittedMsg)

        roomLoaded.listen((context: IRoomContext) => {
            this.room = context.dossier.room
        })

        this.categorySelect.onchange = () => {
            this.categoryRequiredMsg.style.display = "none"
            this.commentsRequiredMsg.style.display = "none"
        }

        this.textBox.onchange = () => {
            if (this.textBox.value !== "") {
                this.commentsRequiredMsg.style.display = "none"
            }
        }

        this.element.onclick = (event: MouseEvent) => {
            event.stopPropagation()
        }
    }

    private sendReport(): void {
        const isNotEmptyRx = /[a-z]{2}/i
        if (this.categorySelect.value === "") {
            this.categoryRequiredMsg.style.display = "block"
            return
        } else if (this.categorySelect.value === "other" && isNotEmptyRx.test(this.textBox.value) === false) {
            this.commentsRequiredMsg.style.display = "block"
            return
        }
        postCb(`abuse/report/${this.room}/`, {
            "category": this.categorySelect.value,
            "additional_comments": this.textBox.value,
        }).then((xhr) => {
            this.formContainer.style.display = "none"
            this.commentsRequiredMsg.style.display = "none"
            this.showSubmitted(i18n.commentSubmittedMessage)
            this.reportAbuseForm.reset()
            this.delayClose(10000)
        }).catch((err) => {
            this.formContainer.style.display = "none"
            this.showSubmitted(err.xhr.responseText)
            this.delayClose(10000)
        })
    }

    public reset(): void {
        this.formContainer.style.display = "block"
        this.commentsRequiredMsg.style.display = "none"
        this.submittedMsg.style.display = "none"
        this.categorySelect.value = ""
        this.blankOption.setAttribute("selected", "selected") // eslint-disable-line @multimediallc/no-set-attribute
        this.reportAbuseForm.reset()
    }

    private showSubmitted(text: string): void {
        this.submittedMsg.innerText = text
        this.submittedMsg.style.display = "block"
    }

    private delayClose(timeout: number): void {
        clearTimeout(this.hideTimeout)
        this.hideTimeout = window.setTimeout(() => {
            clearTimeout(this.hideTimeout)
            this.closeEvent.fire(true)
        }, timeout)
    }
}
