import { dom } from "../../../../common/tsxrender/dom"
import { RoomListSource } from "../../../roomList"
import { RoomList } from "../roomList"
import type { IRoomInfo } from "../IRoomInfo"
import type { RoomCard } from "../roomCard"

export interface IMoreRoomsState {roomInfoList: IRoomInfo[]}

export abstract class MoreRoomsList<TRoomCard extends RoomCard> extends RoomList<TRoomCard, IMoreRoomsState> {
    protected roomIndex = 0

    static ulStyle: CSSX.Properties = {
        overflowY: "visible",
        fontSize: "12px",
        height: "auto",
        listStyle: "none",
        margin: 0,
        padding: 0,
    }

    constructor() {
        super({
            roomListSource: RoomListSource.MoreRooms,
            animate: true,
            // If user has disabled locations, this will be handled from the API results in resetRooms()
            showLocation: true,
            rooms: () => this.state.roomInfoList,
        })
    }

    protected createElement(): HTMLUListElement {
        return <ul className="list" style={MoreRoomsList.ulStyle}></ul>
    }

    updateState(): void {
        super.updateState()
        this.roomIndex = this.rooms.length
    }

    getRoomInfoList(): IRoomInfo[] {
        return this.state.roomInfoList
    }

    appendRooms(rooms: IRoomInfo[]): void {
        this.state.roomInfoList.push(...rooms)
        for (const roomInfo of rooms) {
            super.appendRoomCard(this.roomIndex+=1, roomInfo)
        }
    }

    spliceRooms(start: number, deleteCount: number): void {
        this.state.roomInfoList.splice(start, deleteCount)
        const removedCards = this.rooms.splice(start, deleteCount)
        for (const card of removedCards) {
            this.removeChild(card)
        }
    }
}