// window.innerHeight corresponds to the visual viewport
// document.documentElement.clientHeight corresponds to the layout viewport

import { isPortrait } from "@multimediallc/web-utils"
import { isiOS } from "@multimediallc/web-utils/modernizr"

export const getViewportHeight = (): number => {
    // window.visualViewport.height does not account for the address bar with the following flow in Android + Chrome:
    // portrait mode -> fullscreen -> exit fullscreen -> rotate to landscape
    if (document.documentElement.clientHeight === window.outerHeight && !isiOS()) {
        // Some browsers incorrectly report the clientHeight to be as large as the entire window, which does not account for the address/menu bar.
        // The window.innerHeight is more likely to be closer to the true visual viewport in this case.
        return window.innerHeight
    }
    // In most cases, the clientHeight should be exactly the same as the window.innerHeight
    // However, with the keyboard open, iOS reports a smaller window.innerHeight, but maintains the document's clientHeight.
    // So we want the larger of these two numbers
    return Math.max(window.innerHeight, document.documentElement.clientHeight)
}

export const getViewportWidth = (): number => {
    // window.outerWidth is not used because iPad on iOS<13 will return 0
    // window.innerWidth is not used because iPad + Chrome will not immediately update its value on rotation to landscape
    return document.documentElement.clientWidth
}

const getDimensions = (isPortrait: boolean): { width: number, height: number } => {
    const viewportWidth = getViewportWidth()
    const viewportHeight = getViewportHeight()

    return isPortrait
        ? { width: viewportWidth, height: viewportHeight }
        : { width: viewportHeight, height: viewportWidth }
}

/**
 * Get a device's portrait dimensions independent of screen orientation.
 * Useful for when a user loads a page in landscape mode but you want to initialize
 * element widths within the context of portrait mode.
 * @return An object containing the portrait dimensions' width and height
 */
export const getPortraitDimensions = (): { width: number, height: number } => getDimensions(isPortrait())

/**
 * Get a device's landscape dimensions independent of screen orientation.
 * Useful for when a user loads a page in portrait mode but you want to initialize
 * element widths within the context of landscape mode.
 * @return An object containing the landscape dimensions' width and height
 */
export const getLandscapeDimensions = (): { width: number, height: number } => getDimensions(!isPortrait())

export const viewportOffsetTop = (): number => {
    return window.pageYOffset
}

const initialScale = window.visualViewport?.scale

export const isViewportZoomed = (): boolean => {
    if (!isiOS() && initialScale != null && window.visualViewport) {
        return Math.round(initialScale * 10) !== Math.round(window.visualViewport.scale * 10)
    } else {
        // This will sometimes give a false positive (such as immediately after rotation on iPad + Chrome),
        // but it should never give false negative.
        return document.documentElement.clientWidth !== window.innerWidth
    }
}
