import { tokenBalanceUpdate } from "../../cb/api/tipping"
import { addColorClass } from "../../cb/colorClasses"
import { pageContext } from "../../cb/interfaces/context"
import { popUpPurchasePage } from "../../cb/ui/tipping"
import { AppPanel } from "../../common/appPanel"
import { roomLoaded } from "../context"
import { applyStyles } from "../DOMutils"
import { RoomStatus } from "../roomStatus"
import { RoomType } from "../roomUtil"
import { i18n } from "../translation"
import { dom, Fragment } from "../tsxrender/dom"
import { FanclubButton, PrivateShowButton, SendTipButton, SupporterButton } from "./actionButtons"
import { BaseRoomTab } from "./baseRoomTab"
import { TabName } from "./tabList"

export class TokensTab extends BaseRoomTab {
    private appPanel: AppPanel
    private tokenBalanceText: HTMLSpanElement
    private tipButtonText: HTMLSpanElement
    private privateShowButton: PrivateShowButton
    private fanclubButton: FanclubButton
    private supporterButton: SupporterButton
    constructor() {
        super({ tabName: TabName.Tokens, tabLabel: i18n.tokensCC, pageActionName: "TipTabOpened" })
    }

    protected initUI(): void {
        super.initUI()
        addColorClass(this.element, "TokensTab")
        applyStyles(this.element, {
            position: "relative",
            display: "flex",
            flexDirection: "column",
        })

        this.appPanel = new AppPanel()
        this.privateShowButton = new PrivateShowButton()
        this.fanclubButton = new FanclubButton()
        this.supporterButton = new SupporterButton()
        this.tokenBalanceText = <span colorClass="tokenBalance" />
        this.tipButtonText = <span>{i18n.tipUser("")}</span>

        this.element.appendChild(
            <Fragment>
                <MainContent
                    appPanel={this.appPanel}
                    privateShowButton={this.privateShowButton}
                    fanclubButton={this.fanclubButton}
                    supporterButton={this.supporterButton}
                />
                <TokenBar tokenBalanceText={this.tokenBalanceText} tipButtonText={this.tipButtonText} />
            </Fragment>,
        )

        roomLoaded.listen((context) => {
            this.updateTokenBalance(context.dossier.tokenBalance)
            this.tipButtonText.innerText = i18n.tipUser(context.dossier.room)
            context.chatConnection.event.statusChange.listen(roomStatusChangeNotification => {
                const purchaseSource = pageContext.current.PurchaseEventSources["TOKEN_SOURCE_MOBILE_TOKENS_TAB_TOKEN_BAR"]
                const purchaseLink = this.element.querySelector("#tokenPurchaseLink") as HTMLLinkElement
                purchaseLink.onclick = () => popUpPurchasePage({
                    "source": purchaseSource,
                    "roomType": roomStatusChangeNotification.currentStatus === RoomStatus.PrivateWatching ? RoomType.Private : RoomType.Public,
                })
            })
            this.repositionChildren()
        })

        this.appPanel.panelUpdated.listen(() => {
            if (this.appPanel.hasContent) {
                this.appPanel.showElement()
            } else {
                this.appPanel.hideElement()
            }
        })

        tokenBalanceUpdate.listen((event) => {
            this.updateTokenBalance(event.tokens)
        })
    }

    private updateTokenBalance(tokenBalance: number): void {
        this.tokenBalanceText.innerText = `${tokenBalance.toLocaleString()} ${i18n.tokenOrTokensText(tokenBalance, false)}`
    }

    protected repositionChildren(): void {
        applyStyles(this.fanclubButton.element, { marginRight: this.supporterButton.isShown() ? "10px" : "" })
    }
}

const MainContent = (props: {
    appPanel: AppPanel,
    privateShowButton: PrivateShowButton,
    fanclubButton: FanclubButton,
    supporterButton: SupporterButton
}): HTMLDivElement => {
    const containerStyle: CSSX.Properties = {
        flex: 1,
        overflowY: "scroll",
    }
    const appPanelContainerStyle: CSSX.Properties = {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        position: "relative",
    }
    applyStyles(props.appPanel.element, { padding: "16px 0 0" })

    const elem: HTMLDivElement = 
        <div style={containerStyle}>
            <div style={appPanelContainerStyle}>
                {props.appPanel.element}
            </div>
            <ActionButtons
                privateShowButton={props.privateShowButton}
                fanclubButton={props.fanclubButton}
                supporterButton={props.supporterButton}
            />
        </div>
    

    return elem
}

const ActionButtons = (props: {
    privateShowButton: PrivateShowButton,
    fanclubButton: FanclubButton,
    supporterButton: SupporterButton
}): HTMLDivElement => {
    const containerStyle: CSSX.Properties = {
        padding: "16px",
        fontSize: "14px",
        width: "100%",
        boxSizing: "border-box",
    }

    return (
        <div style={containerStyle}>
            {props.privateShowButton.element}
            <div style={{ display: "flex" }}>
                {props.fanclubButton.element}
                {props.supporterButton.element}
            </div>
        </div>
    )
}

const TokenBar = (props: {
    tokenBalanceText: HTMLSpanElement,
    tipButtonText: HTMLSpanElement,
}): HTMLDivElement => {
    const tokenBarStyle: CSSX.Properties = {
        width: "100%",
        borderTop: "1px solid #EFEFEF",
        padding: "16px 18px 12px 18px",
        display: "flex",
        flexDirection: "column",
        boxSizing: "border-box",
        fontSize: "14px",
    }
    const purchaseSource = pageContext.current.PurchaseEventSources["TOKEN_SOURCE_MOBILE_TOKENS_TAB_TOKEN_BAR"]

    return (
        <div style={tokenBarStyle}>
            <div style={{ display: "flex", justifyContent: "space-between", marginBottom: "12px" }}>
                <div style={{ fontFamily: "UbuntuBold, Helvetica, Arial, sans-serif" }}>
                    <span colorClass="youHave">{`${i18n.tokensLabel} `}</span>
                    {props.tokenBalanceText}
                </div>
                <a id="tokenPurchaseLink" onClick={() => () => popUpPurchasePage({ "source": purchaseSource, "roomType": RoomType.Public })}>{i18n.buyMoreTokens}</a>
            </div>
            {new SendTipButton({ tipButtonText: props.tipButtonText }).element}
        </div>
    )
}
