import { i18n } from "../translation"
import { BaseRoomTab } from "./baseRoomTab"
import { ChatWindow } from "./chatWindow"
import { TabName } from "./tabList"
import type { ChatContents } from "./chatContents"

export class ChatTab extends BaseRoomTab {
    private chatWindow: ChatWindow

    constructor() {
        super({ tabName: TabName.Chat, tabLabel: i18n.chatCapitalized, pageActionName: "ChatOpened" })
    }

    protected initUI(): void {
        super.initUI()

        this.chatWindow = new ChatWindow()
        this.addChild(this.chatWindow)
    }

    public getChatContents(): ChatContents {
        return this.chatWindow.chatContents
    }

    public getChatWindow(): ChatWindow {
        return this.chatWindow
    }
}
