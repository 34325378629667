import { addColorClass } from "../../cb/colorClasses"
import { pageContext, roomDossierContext } from "../../cb/interfaces/context"
import { normalizeResource } from "../api"
import { isNotLoggedIn } from "../auth"
import { type IChatConnection, type IRoomContext, roomCleanup, roomLoaded } from "../context"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { ListenerGroup } from "../events"
import { addPageAction } from "../newrelic"
import { enterPrivateShowAlertChain, enterSpyShowAlertChain, leavePrivateOrSpyShowAlertChain } from "../privateShow"
import { ignoreCatch } from "../promiseUtils"
import { areRoomsInSync, syncRooms } from "../roomDossier"
import { RoomStatus } from "../roomStatus"
import { i18n } from "../translation"
import { dom, Fragment } from "../tsxrender/dom"
import { TabName } from "./tabList"
import { openTipCalloutRequest, userSwitchedTab } from "./userActionEvents"

interface IButtonProps {
    style?: CSSX.Properties
    colorClass?: string
    body?: string | HTMLElement
    onClick?: EventListener
}

interface IAnchorProps extends IButtonProps {
    href?: string
    target?: string
}

const actionElementBaseStyle: CSSX.Properties = {
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "4px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginBottom: "10px",
    padding: "0 12px",
    textAlign: "center",
    minHeight: "48px",
    boxSizing: "border-box",
    font: "inherit",
    width: "inherit",
}

const Button = (props: IButtonProps): HTMLButtonElement => {
    return (
        <button style={{ ...actionElementBaseStyle, ...props.style }}
            colorClass={props.colorClass !== undefined ? ["buttonBase", props.colorClass] : "buttonBase"}
            onClick={props.onClick}
        >
            {props.body ?? ""}
        </button>
    )
}

export const Anchor = (props: IAnchorProps): HTMLAnchorElement => {
    const elem: HTMLAnchorElement = 
        <a style={{ ...actionElementBaseStyle, ...props.style }}
            colorClass={props.colorClass !== undefined ? ["buttonBase", props.colorClass] : "buttonBase"}
            onClick={props.onClick}
        >
            {props.body ?? ""}
        </a>
    

    // set here instead of jsx because href="undefined" gets rendered to dom if prop is undefined
    if (props.href !== undefined) {
        elem.href = normalizeResource(props.href)
    }
    if (props.target !== undefined) {
        elem.target = props.target
    }
    return elem
}

/**
 * @styles: scss/theme/shared/actionButtons.scss
 */
abstract class BaseActionButton extends Component<HTMLElement, IButtonProps> {
    constructor(tagOrElement: string, props?: IButtonProps) {
        super(tagOrElement, props)
    }

    protected setButtonText(text: string): void {
        this.element.innerText = text
    }

    protected setButtonBody(body: HTMLElement | string): void {
        this.removeAllDOMChildren()
        this.element.appendChild(
            <Fragment>
                {body}
            </Fragment>,
        )
    }

    protected setOnClick(onClick: EventListener): void {
        this.element.onclick = onClick
    }

    public showElement(): void {
        super.showElement("flex")
    }
}

abstract class ActionButton extends BaseActionButton {
    public element: HTMLButtonElement

    constructor(props?: IButtonProps) {
        super("button", props)
    }

    protected initUI(props?: IButtonProps): void {
        this.element =
            <Button
                style={props?.style}
                colorClass={props?.colorClass}
                body={props?.body}
                onClick={props?.onClick}
            />
    }

    protected enable(): void {
        this.element.disabled = false
    }

    protected disable(): void {
        this.element.disabled = true
    }
}

// For any ActionButton that should lead to a new URL
abstract class ActionAnchorButton extends BaseActionButton {
    public element: HTMLAnchorElement

    constructor(props?: IButtonProps) {
        super("a", props)
    }

    protected initUI(props?: IAnchorProps): void {
        this.element =
            <Anchor
                style={props?.style}
                colorClass={props?.colorClass}
                body={props?.body}
                onClick={props?.onClick}
                href={props?.href}
                target={props?.target}
            />
    }
}

class ActionButtonsManager {
    private privateShowButtons: PrivateShowButton[]
    private fanclubButtons: FanclubButton[]
    private supporterButtons: SupporterButton[]
    private context?: IRoomContext

    constructor() {
        this.privateShowButtons = []
        this.fanclubButtons = []
        this.supporterButtons = []
        const perRoomListeners = new ListenerGroup()

        roomLoaded.listen((context) => {
            context.chatConnection.event.statusChange.listen(() => {
                this.updateContext(context)
            }).addTo(perRoomListeners)

            context.chatConnection.event.settingsUpdate.listen(() => {
                this.updateContext(context)
            }).addTo(perRoomListeners)
        })

        roomCleanup.listen(() => {perRoomListeners.removeAll()})
    }

    public addButton(button: BaseActionButton): void {
        if (button instanceof PrivateShowButton) {
            this.privateShowButtons.push(button)
        } else if (button instanceof FanclubButton) {
            this.fanclubButtons.push(button)
        } else if (button instanceof SupporterButton) {
            this.supporterButtons.push(button)
        }

        if (this.context !== undefined) {
            this.updateContext(this.context)
        }
    }

    private updateContext(context: IRoomContext): void {
        this.context = context
        this.fanclubButtons.forEach((button) => {button.updateState(context)})
        this.supporterButtons.forEach((button) => {button.updateState(context)})
        this.updatePrivateButtonsState()
    }

    private updatePrivateButtonsState(): void {
        this.privateShowButtons.forEach((button) => {button.hideElement()})

        if (this.context === undefined || !this.canShowPrivateButton()) {
            return
        }

        const applyUpdates = () => {
            this.privateShowButtons.forEach((button) => {
                if (this.context === undefined) {
                    return
                }

                button.updateState(this.context.chatConnection)
            })
        }

        const roomStatusChange = {
            previousStatus: this.context.chatConnection.previousStatus,
            currentStatus: this.context.chatConnection.status,
        }

        if (areRoomsInSync(this.context, roomStatusChange)) {
            applyUpdates()
        } else {
            syncRooms(this.context, roomStatusChange)
                .then(applyUpdates)
                .catch(ignoreCatch)
        }
    }

    private canShowPrivateButton(): boolean {
        if (this.context === undefined) {
            return false
        }

        const { allowPrivateShow, isAgeVerified } = roomDossierContext.getState()
        const inPrivateOrSpy = [RoomStatus.PrivateWatching, RoomStatus.PrivateSpying].includes(this.context.chatConnection.status)
        return (allowPrivateShow || inPrivateOrSpy)
            && isAgeVerified
    }
}

const actionButtonsManager = new ActionButtonsManager()

export class FanclubButton extends ActionAnchorButton {
    private hasFanclub: boolean

    constructor() {
        super()
        actionButtonsManager.addButton(this)
    }

    protected initData(): void {
        this.hasFanclub = false
    }

    protected initUI(): void {
        super.initUI()
        addColorClass(this.element, "fanclubButton")
        applyStyles(this.element, { flex: 1 })
        this.hideElement()
    }

    public updateState(context: IRoomContext): void {
        // don't show fanclub button for non-interactive users
        if (pageContext.current.isNoninteractiveUser) {
            return
        }

        const { hasFanClub, isInFanClub } = roomDossierContext.getState()
        this.hasFanclub = hasFanClub

        if (this.hasFanclub) {
            this.element.href = normalizeResource(`/fanclub/join/${context.chatConnection.room()}/?source=${pageContext.current?.PurchaseEventSources["SUPPORTER_SOURCE_JOIN_FAN_CLUB_BUTTON"]}`)
        }

        this.hasFanclub ? this.showElement() : this.hideElement()

        if (isInFanClub) {
            this.setButtonText(i18n.fanClubMember)
        } else {
            this.setButtonText(i18n.joinFanClub)
        }
    }

    public getHasFanclub(): boolean {
        return this.hasFanclub
    }
}

interface IPrivateShowButtonProps extends IButtonProps {useConciseText?: boolean}

export class PrivateShowButton extends ActionButton {
    private useConciseText: boolean

    constructor(props?: IPrivateShowButtonProps) {
        super(props)
        actionButtonsManager.addButton(this)
    }

    protected initData(props?: IPrivateShowButtonProps): void {
        this.useConciseText = props?.useConciseText === true
    }

    protected initUI(props?: IPrivateShowButtonProps): void {
        super.initUI(props)
        this.hideElement()
        addColorClass(this.element, "privateShowButton")
    }

    // eslint-disable-next-line complexity
    public updateState(chatConnection: IChatConnection): void {
        this.showElement()
        const status = chatConnection.status

        switch (status) {
            case RoomStatus.PrivateRequesting:
                this.setCancelText()
                this.setOnClick(() => {
                    this.leavePrivateOrSpy(chatConnection, status)
                })
                break
            case RoomStatus.PrivateNotWatching:
                const spyPrice = roomDossierContext.getState().spyPrice
                const spyDisabled = chatConnection.premiumShowActive || spyPrice <= 0

                if (spyDisabled) {
                    this.hideElement()
                    break
                }

                this.setSpyRequestText()
                this.setOnClick(() => {
                    addPageAction("SpyClicked")
                    this.requestSpy(chatConnection, status)
                })
                break
            case RoomStatus.PrivateWatching:
            case RoomStatus.PrivateSpying:
                this.setLeaveText()
                this.setOnClick((event) => {
                    event.preventDefault()
                    this.leavePrivateOrSpy(chatConnection, status)
                })
                break
            case RoomStatus.Offline:
            case RoomStatus.Away:
            case RoomStatus.Hidden:
            case RoomStatus.NotConnected:
            case RoomStatus.HiddenWatching:
                this.hideElement()
                break
            case RoomStatus.Public:
                this.setPrivateRequestText()
                this.setOnClick(() => {
                    addPageAction("PrivateClicked")
                    this.requestPrivate(chatConnection)
                })
                break
            default:
                warn(`Unexpected status for updatePrivateState: ${status}`)
        }
    }

    protected requestPrivate(chatConnection: IChatConnection): void {
        enterPrivateShowAlertChain(chatConnection, true, { onResolve: () => {userSwitchedTab.fire(TabName.Private)} })
    }

    protected requestSpy(chatConnection: IChatConnection, status: RoomStatus): void {
        enterSpyShowAlertChain(chatConnection, true, { onResolve: () => {userSwitchedTab.fire(TabName.Private)} })
    }

    protected leavePrivateOrSpy(chatConnection: IChatConnection, status: RoomStatus): void {
        addPageAction("LeavePrivateOrSpyShow")
        const confirm = chatConnection.privateMinEnd < Date.now()
        leavePrivateOrSpyShowAlertChain(chatConnection, confirm, {}, () => {this.updateState(chatConnection)})
    }

    protected setCancelText(): void {
        this.setButtonText(this.useConciseText ? i18n.cancelRequest : i18n.privateShowCancelRequestLabel)
    }

    protected setSpyRequestText(): void {
        this.setButtonText(this.useConciseText ? i18n.spyOnPrivate : i18n.privateShowSpyLabel)
    }

    protected setLeaveText(): void {
        this.setButtonText(this.useConciseText ? i18n.leavePrivate : i18n.privateShowLeaveLabel)
    }

    protected setPrivateRequestText(): void {
        this.setButtonText(this.useConciseText ? i18n.requestPrivate : i18n.privateShowRequestLabel)
    }
}

export class PrivateTabButton extends PrivateShowButton {
    constructor(props?: IPrivateShowButtonProps) {
        super({
            ...props,
            style: {
                width: "120px",
                minWidth: "120px",
                height: "40px",
                marginBottom: "",
                minHeight: "",
            },
        })
    }

    protected initData(props?: IPrivateShowButtonProps): void {
        super.initData(props)
    }

    public updateState(chatConnection: IChatConnection): void {
        this.enable()
        super.updateState(chatConnection)
    }

    protected requestPrivate(chatConnection: IChatConnection): void {
        enterPrivateShowAlertChain(chatConnection, false, undefined, true)
    }

    protected requestSpy(chatConnection: IChatConnection): void {
        this.disable()
        this.setConnectingText()
        enterSpyShowAlertChain(chatConnection, false, {
            onError: () => {this.updateState(chatConnection)},
            onResolve: () => {userSwitchedTab.fire(TabName.Private)},
            onFinally: () => {this.enable()},
        })
    }

    protected leavePrivateOrSpy(chatConnection: IChatConnection): void {
        this.disable()
        this.setEndingText()
        const confirm = chatConnection.inPrivateOrSpy() && chatConnection.privateMinEnd < Date.now()
        leavePrivateOrSpyShowAlertChain(
            chatConnection,
            confirm,
            {
                onError: () => {this.updateState(chatConnection)},
                onFinally: () => {this.enable()},
            },
            () => {
                this.updateState(chatConnection)
            })
    }

    protected setCancelText(): void {
        this.setButtonText(i18n.cancelText)
    }

    protected setSpyRequestText(): void {
        this.setButtonText(i18n.spyNow)
    }

    protected setLeaveText(): void {
        this.setButtonText(i18n.leave)
    }

    protected setPrivateRequestText(): void {
        this.setButtonText(i18n.request)
    }

    private setConnectingText(): void {
        this.setButtonText(`${i18n.connecting}...`)
    }

    private setEndingText(): void {
        this.setButtonText(`${i18n.ending}...`)
    }
}

export class SupporterButton extends ActionAnchorButton {
    constructor() {
        super()
        actionButtonsManager.addButton(this)
    }

    protected initUI(): void {
        this.element = 
            <Anchor
                style={{ flex: 1 }}
                colorClass="upgradeButton"
                href={`/supporter/upgrade/?source=${pageContext.current.PurchaseEventSources["SUPPORTER_SOURCE_MOBILE_TOKENS_TAB"]}`}
                target="_blank"
                body={i18n.upgradeToSupporterLabel}
            />
    }

    public updateState(context: IRoomContext): void {
        if (context.dossier.isSupporter) {
            this.hideElement()
        } else {
            this.showElement()
        }
    }
}

export interface ISendTipButton extends IButtonProps {
    tipButtonText: HTMLSpanElement | string
    hideIcon?: boolean
}

export class SendTipButton extends ActionButton {
    constructor(props: ISendTipButton) {
        super(props)
    }

    protected initUI(props: ISendTipButton): void {
        super.initUI()
        const iconStyle: CSSX.Properties  = {
            display: "inline-block",
            width: "20px",
            height: "20px",
            marginRight: "8px",
        }

        const buttonBody = 
            <span style={{ display: "flex", alignItems: "center" }} data-testid="send-tip-button">
                {props.hideIcon !== true && <span style={iconStyle} colorClass="tipIcon" />}
                {props.tipButtonText}
            </span>
        

        const handleTipButtonClick = () => {
            addPageAction("TipWindowOpened")
            if (isNotLoggedIn(i18n.loginToTip)) {
                return
            }
            openTipCalloutRequest.fire({})
        }

        this.element = 
            <Button
                style={{ ...props.style, marginBottom: 0 }}
                colorClass="sendTipButton"
                body={buttonBody}
                onClick={handleTipButtonClick}
            />
    }
}

export class SendMessageButton extends ActionButton {
    constructor(props: IButtonProps) {
        super(props)
    }

    protected initUI(props: IButtonProps): void {
        const buttonStyle: CSSX.Properties = {
            marginBottom: 0,
            padding: "12px",
            minHeight: "",
            fontSize: "14px",
            margin: "0 8px 0 0",
        }
        const buttonBodyStyle: CSSX.Properties = {
            display: "flex",
            alignItems: "center",
        }
        const iconStyle: CSSX.Properties  = {
            display: "inline-block",
            width: "20px",
            height: "20px",
            marginLeft: "8px",
        }

        const buttonBody = 
            <span style={buttonBodyStyle}>
                {i18n.sendAMessage}
                <span style={iconStyle} colorClass="sendIcon" />
            </span>
        

        this.element = 
            <Button
                style={buttonStyle}
                colorClass="sendMessageButton"
                body={buttonBody}
                onClick={props.onClick}
            />
    }
}
