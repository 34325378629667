import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb, postCb } from "../../common/api"
import { AppPermissionsForm } from "../components/asp/permissionsForm"
import type { XhrError } from "../../common/api"
import type {
    IApp,
    IAppData,
    IAppListApp, IAppListResponseData,
    IBroadcasterApp,
    IBroadcasterAppListResponseData, IPanel, IPanelApp,
    IPermission,
    IPermissions,
    IPermissionsValues, ISetting, ISettings,
} from "../interfaces/asp"

export function getNewAppPage(appId: string): Promise<IApp> {
    return getCb(`api/public/asp/control/app/${appId}/`).then(xhr => parseApp(xhr.responseText))
}

function parseApp(str: string): IApp {
    const parser = new ArgJSONMap(str)
    let settings: Record<string, string | undefined> | undefined
    if (parser.getAny("settingsValues") !== undefined) {
        const settingsParser = parser.getMap("settingsValues")
        settings = {}
        settingsParser.keys().map((k: string) => {
            // @ts-ignore settings can be string number boolean
            settings[k] = settingsParser.getAny(k)
        })
    }
    return {
        id: parser.getString("id"),
        name: parser.getString("name"),
        isPublic: parser.getBoolean("isPublic", false, false),
        isInstalled: parser.getBoolean("isInstalled", false, false),
        isActive: parser.getBoolean("isActive", false, false),
        hasUpdate: parser.getBoolean("hasUpdate", false, false),
        installed: parser.getAny("installed") !== undefined
            ? parseAppData(parser.getMap("installed"))
            : undefined,
        latest: parseAppData(parser.getMap("latest")),
        settingsValues: settings,
        permissionsValues: parser.getAny("permissionsValues") !== undefined
            ? parsePermissionsValues(parser.getMap("permissionsValues"))
            : undefined,
        reason: parser.getStringOrUndefined("reason"),
        slot: parser.getNumberOrUndefined("slot"),
    }
}

function parseBroadcasterApp(map: ArgJSONMap): IBroadcasterApp {
    return {
        id: map.getString("id"),
        name: map.getString("name"),
        slot: map.getNumber("slot"),
    }
}

function parseAppListApp(map: ArgJSONMap): IAppListApp {
    return {
        id: map.getString("id"),
        name: map.getString("name"),
        isPublic: map.getBoolean("isPublic", false, false),
        isInstalled: map.getBoolean("isInstalled", false, false),
        isActive: map.getBoolean("isActive", false, false),
        hasUpdate: map.getBoolean("hasUpdate", false, false),
        author: map.getString("author"),
        summary: map.getString("summary"),
        latestVersion: map.getStringOrUndefined("latestVersion"),
        installedVersion: map.getStringOrUndefined("installedVersion"),
        permissionsValues: map.getAny("permissionsValues") !== undefined
            ? parsePermissionsValues(map.getMap("permissionsValues"))
            : undefined,
        reason: map.getStringOrUndefined("reason"),
        slot: map.getNumberOrUndefined("slot"),
    }
}

function parsePermissionsValues(map: ArgJSONMap): IPermissionsValues {
    return {
        videoPanel: map.getBoolean("videoPanel"),
        rewriteMessages: map.getBoolean("rewriteMessages"),
        tipOptions: map.getBoolean("tipOptions"),
    }
}

function parseAppData(map: ArgJSONMap): IAppData {
    return {
        version: map.getString("version"),
        id: map.getString("id"),
        summary: map.getString("summary"),
        description: map.getString("description"),
        author: map.getString("author"),
        settings: parseSettings(map.getMap("settings")),
        permissions: parsePermissions(map.getMap("permissions")),
    }
}

function parseSetting(settings: ArgJSONMap): ISetting {
    return {
        type: settings.getString("type"),
        title: settings.getStringOrUndefined("title", false),
        description: settings.getStringOrUndefined("description", false),
        minimum: settings.getNumberOrUndefined("minimum", false),
        maximum: settings.getNumberOrUndefined("maximum", false),
        minLength: settings.getNumberOrUndefined("minLength", false),
        maxLength: settings.getNumberOrUndefined("maxLength", false),
        pattern: settings.getStringOrUndefined("pattern", false),
        default: settings.getAny("default"),
        required: settings.getBoolean("required", false, false),
        order: settings.getNumberOrUndefined("order"),
        enum: settings.getStringListOrUndefined("enum"),
    }
}

function parseSettings(map: ArgJSONMap): ISettings {
    const settings: Record<string, ISetting> = {}
    map.keys().map(k => {
        settings[k] = parseSetting(map.getMap(k))
    })
    return settings
}

function parsePermissions(permissions: ArgJSONMap): IPermissions {
    function parsePermission(map: ArgJSONMap): IPermission {
        return { settings: map.getString("settings") as IPermission["settings"] }
    }

    return {
        videoPanel: parsePermission(permissions.getMap("videoPanel")),
        rewriteMessages: parsePermission(permissions.getMap("rewriteMessages")),
        tipOptions: parsePermission(permissions.getMap("tipOptions")),
    }
}

function parseAppListResponseData(str: string): IAppListResponseData {
    const parser = new ArgJSONMap(str)
    return {
        items: parser.getList("items")?.map((app) => {
            return parseAppListApp(app)
        }) ?? [],
        limit: parser.getNumber("limit"),
        offset: parser.getNumber("offset"),
        total: parser.getNumber("total"),
    }
}


function parseBroadcasterAppList(str: string): IBroadcasterAppListResponseData {
    const parser = new ArgJSONMap(str)
    return {
        items: parser.getList("items")?.map((app) => {
            return parseBroadcasterApp(app)
        }) ?? [],
    }
}

export function parsePanel(data: ArgJSONMap): IPanel {
    return {
        appList: data.getStringList("order").map(app => parsePanelApp(app)),
        appName: data.getString("app_name", false),
        template: data.getString("template", false),
        layers: data.getList("layers"),
        url: data.getStringOrUndefined("url", false),
        webcOrigin: data.getStringOrUndefined("webc_origin", false),
        webcAuthToken: data.getStringOrUndefined("webc_auth_token"),
        error: data.getStringOrUndefined("error", false),
    }
}

function parsePanelApp(str: string): IPanelApp {
    const parser = new ArgJSONMap(str)
    return {
        id: parser.getString("id"),
        name: parser.getString("name"),
        panelType: parser.getString("system"),
    }
}

export function getBroadcasterAppList(room_uid: string): Promise<IBroadcasterAppListResponseData> {
    return getCb(`api/public/asp/broadcast/applist/${room_uid}/`)
        .then(xhr => parseBroadcasterAppList(xhr.responseText))
}

export function getAppList(args: Record<string, string | string[]>): Promise<IAppListResponseData> {
    /**
     * Args as attribute in object
     * - offset,
     * - limit
     * - isInstalled ~ 0 if featured
     * - isInstalled ~ 1 if myapps
     * - orderedBy
     * - permissions
     */

    const formData = new FormData()
    for (const [key, value] of Object.entries(args)) {
        if (Array.isArray(value)) {
            value.forEach(item => {
                formData.append(key, item)
            })
        } else {
            formData.set(key, value)
        }
    }
    return postCb("api/public/asp/control/applist/", formData)
        .then(xhr => parseAppListResponseData(xhr.responseText))
}

export function getSlottedAppList(): Promise<IAppListResponseData> {
    const args = {
        "offset": "0",
        "limit": "10",
        "hasSlot": "1",
    }
    return getAppList(args)
}

let broadcasterAppListCache: Promise<IBroadcasterAppListResponseData> | undefined

export function getBroadcasterAppListCached(broadcasterUid: string): Promise<IBroadcasterAppListResponseData> {
    if (broadcasterAppListCache === undefined) {
        broadcasterAppListCache = getBroadcasterAppList(broadcasterUid)
    }
    window.setTimeout(() => {
        broadcasterAppListCache = undefined
    }, 1000)
    return broadcasterAppListCache
}

export function getExclusiveApps(): Promise<IAppListResponseData> {
    return getAppList({
        "isInstalled": "1",
        "permissions": Object.values(AppPermissionsForm.EXCLUSIVE_PERMISSIONS),
    })
}

export function addApp(
    appId: string,
    settings: object,
    permissions: object,
    slot: number,
    replaceSlot: boolean,
    callback: () => void,
    errCallback: (err: XhrError) => void,
): void {
    postCb(`api/public/asp/control/app/install/${appId}/`, {
        "settings": JSON.stringify(settings),
        "permissions": JSON.stringify(permissions),
        "slot": slot.toString(),
        "replace_slot": replaceSlot.toString(),
    })
        .then((data) => {
            callback()
        })
        .catch((error: XhrError) => {
            errCallback(error)
        })
}

export function updateApp(
    appId: string,
    settings: object,
    permissions: object,
    originalSettings: object,
    originalPermissions: object,
    callback: () => void,
    errCallback: (err: XhrError) => void,
): void {
    postCb(
        `api/public/asp/control/app/update/${appId}/`,
        {
            "settings": JSON.stringify(settings),
            "permissions": JSON.stringify(permissions),
            "original_settings": JSON.stringify(originalSettings),
            "original_permissions": JSON.stringify(originalPermissions),
        },
    ).then((data) => {
        callback()
    })
        .catch((error: XhrError) => {
            errCallback(error)
        })
}

export function upgradeApp(
    appId: string,
    versionId: string,
    settings: object,
    permissions: object,
    callback: () => void,
    errCallback: (err: XhrError) => void,
): void {
    postCb(
        `api/public/asp/control/app/upgrade/${appId}/${versionId}/`,
        {
            "settings": JSON.stringify(settings),
            "permissions": JSON.stringify(permissions),
        },
    )
        .then((data) => { callback() })
        .catch((error: XhrError) => {
            errCallback(error)
        })
}

export async function stopApp(
    appId: string,
    callback: () => void,
): Promise<void> {
    await postCb(`api/public/asp/control/app/stop/${appId}/`, {})
        .then((data) => { callback() })
        .catch((xhr) => {
            error("Error stopping app", xhr)
        })
}

export async function removeApp(
    appId: string,
    deleteSlot: boolean,
    callback: () => void,
): Promise<void> {
    await postCb(`api/public/asp/control/app/uninstall/${appId}/`, { "delete_slot": deleteSlot.toString() })
        .then((data) => { callback() })
        .catch((xhr) => {
            error("Error removing app", xhr)
        })
}

export function getPanel(room_uid: string, bcp_fallback: boolean, app_id?: string, system?: string): Promise<ArgJSONMap> {
    let url = `api/public/asp/panel/${room_uid}/`
    const params = new URLSearchParams()
    if (app_id !== undefined) {
        params.append("app_id", app_id)
    }
    if (system !== undefined) {
        params.append("system", system)
    }
    if (bcp_fallback) {
        params.append("bcp_fallback", "1")
    }
    if (params.toString() !== "") {
        url += `?${params.toString()}`
    }
    return getCb(url).then(xhr => new ArgJSONMap(xhr.responseText))
}
