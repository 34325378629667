import { getCb } from "../../../common/api"
import { EventRouter } from "../../../common/events"

interface UnreadDMData { unread: number }

export class DMUnreadData {
    public static unreadDataUpdated = new EventRouter<UnreadDMData>("unreadDataUpdated")
    private static instance?: DMUnreadData
    private unreadUsernames: Set<string> = new Set()

    private constructor() {
        void this.fetchUnreadData()
    }

    static getInstance(): DMUnreadData {
        if (DMUnreadData.instance === undefined) {
            DMUnreadData.instance = new DMUnreadData()
        }
        return DMUnreadData.instance
    }

    private async fetchUnreadData(): Promise<void> {
        try {
            const response = await getCb("/api/messaging/unread/")
            const data = JSON.parse(response?.responseText)
            this.unreadUsernames = new Set(data.unread_usernames)
            this.fireUpdate()
        } catch (err) {
            error("Error fetching DM unread status", err)
        }
    }

    private fireUpdate(): void {
        DMUnreadData.unreadDataUpdated.fire({ unread: this.unreadUsernames.size })
    }

    getUnreadCount(): number {
        return this.unreadUsernames.size
    }

    addUnreadRecipient(username: string): void {
        if (!this.unreadUsernames.has(username)) {
            this.unreadUsernames.add(username)
            this.fireUpdate()
        }
    }

    removeUnreadRecipient(username: string): void {
        if (this.unreadUsernames.has(username)) {
            this.unreadUsernames.delete(username)
            this.fireUpdate()
        }
    }
}