import { bound01, pad2 } from "./util"
import type { ICIELAB, IHSL, IHSV, IRGB, IYPbPr, Numberify } from "./interfaces"

// `rgbToHsl`, `rgbToHsv`, `hslToRgb`, `hsvToRgb` modified from:
// <http://mjijackson.com/2008/02/rgb-to-hsl-and-rgb-to-hsv-color-model-conversion-algorithms-in-javascript>

/**
 * Handle bounds / percentage checking to conform to CSS color spec
 * <http://www.w3.org/TR/css3-color/>
 * *Assumes:* r, g, b in [0, 255] or [0, 1]
 * *Returns:* { r, g, b } in [0, 255]
 */
export function rgbToRgb(
    r: number | string,
    g: number | string,
    b: number | string,
): Numberify<IRGB> {
    return {
        r: bound01(r, 255) * 255,
        g: bound01(g, 255) * 255,
        b: bound01(b, 255) * 255,
    }
}

/**
 * Converts an RGB color value to HSL.
 * *Assumes:* r, g, and b are contained in [0, 255] or [0, 1]
 * *Returns:* { h, s, l } in [0,1]
 */
export function rgbToHsl(r: number, g: number, b: number): Numberify<IHSL> {
    r = bound01(r, 255)
    g = bound01(g, 255)
    b = bound01(b, 255)

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h = 0
    let s = 0
    const l = (max + min) / 2

    if (max === min) {
        s = 0
        h = 0 // achromatic
    } else {
        const d = max - min
        s = l > 0.5 ? d / (2 - max - min) : d / (max + min)
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0)
                break
            case g:
                h = (b - r) / d + 2
                break
            case b:
                h = (r - g) / d + 4
                break
            default:
                break
        }

        h /= 6
    }

    return { h, s, l }
}

function hue2rgb(p: number, q: number, t: number): number {
    if (t < 0) {
        t += 1
    }

    if (t > 1) {
        t -= 1
    }

    if (t < 1 / 6) {
        return p + (q - p) * (6 * t)
    }

    if (t < 1 / 2) {
        return q
    }

    if (t < 2 / 3) {
        return p + (q - p) * (2 / 3 - t) * 6
    }

    return p
}

/**
 * Converts an HSL color value to RGB.
 *
 * *Assumes:* h is contained in [0, 1] or [0, 360] and s and l are contained [0, 1] or [0, 100]
 * *Returns:* { r, g, b } in the set [0, 255]
 */
export function hslToRgb(
    h: number | string,
    s: number | string,
    l: number | string,
): Numberify<IRGB> {
    let r: number
    let g: number
    let b: number

    h = bound01(h, 360)
    s = bound01(s, 100)
    l = bound01(l, 100)

    if (s === 0) {
        // achromatic
        g = l
        b = l
        r = l
    } else {
        const q = l < 0.5 ? l * (1 + s) : l + s - l * s
        const p = 2 * l - q
        r = hue2rgb(p, q, h + 1 / 3)
        g = hue2rgb(p, q, h)
        b = hue2rgb(p, q, h - 1 / 3)
    }

    return { r: r * 255, g: g * 255, b: b * 255 }
}

/**
 * Converts an RGB color value to HSV
 *
 * *Assumes:* r, g, and b are contained in the set [0, 255] or [0, 1]
 * *Returns:* { h, s, v } in [0,1]
 */
export function rgbToHsv(r: number, g: number, b: number): Numberify<IHSV> {
    r = bound01(r, 255)
    g = bound01(g, 255)
    b = bound01(b, 255)

    const max = Math.max(r, g, b)
    const min = Math.min(r, g, b)
    let h = 0
    const v = max
    const d = max - min
    const s = max === 0 ? 0 : d / max

    if (max === min) {
        h = 0 // achromatic
    } else {
        switch (max) {
            case r:
                h = (g - b) / d + (g < b ? 6 : 0)
                break
            case g:
                h = (b - r) / d + 2
                break
            case b:
                h = (r - g) / d + 4
                break
            default:
                break
        }

        h /= 6
    }

    return { h, s, v }
}

/**
 * Converts an HSV color value to RGB.
 *
 * *Assumes:* h is contained in [0, 1] or [0, 360] and s and v are contained in [0, 1] or [0, 100]
 * *Returns:* { r, g, b } in the set [0, 255]
 */
export function hsvToRgb(
    h: number | string,
    s: number | string,
    v: number | string,
): Numberify<IRGB> {
    h = bound01(h, 360) * 6
    s = bound01(s, 100)
    v = bound01(v, 100)

    const i = Math.floor(h)
    const f = h - i
    const p = v * (1 - s)
    const q = v * (1 - f * s)
    const t = v * (1 - (1 - f) * s)
    const mod = i % 6
    const r = [v, q, p, p, t, v][mod]
    const g = [t, v, v, q, p, p][mod]
    const b = [p, p, t, v, v, q][mod]

    return { r: r * 255, g: g * 255, b: b * 255 }
}

/**
 * Converts an RGB color to hex
 *
 * Assumes r, g, and b are contained in the set [0, 255]
 * Returns a 3 or 6 character hex
 */
export function rgbToHex(r: number, g: number, b: number, allow3Char: boolean): string {
    const hex = [
        pad2(Math.round(r).toString(16)),
        pad2(Math.round(g).toString(16)),
        pad2(Math.round(b).toString(16)),
    ]

    // Return a 3 character hex if possible
    if (
        allow3Char &&
        hex[0].startsWith(hex[0].charAt(1)) &&
        hex[1].startsWith(hex[1].charAt(1)) &&
        hex[2].startsWith(hex[2].charAt(1))
    ) {
        return hex[0].charAt(0) + hex[1].charAt(0) + hex[2].charAt(0)
    }

    return hex.join("")
}

/**
 * Converts an RGBA color plus alpha transparency to hex
 *
 * Assumes r, g, b are contained in the set [0, 255] and
 * a in [0, 1]. Returns a 4 or 8 character rgba hex
 */
// eslint-disable-next-line max-params
export function rgbaToHex(r: number, g: number, b: number, a: number, allow4Char: boolean): string {
    const hex = [
        pad2(Math.round(r).toString(16)),
        pad2(Math.round(g).toString(16)),
        pad2(Math.round(b).toString(16)),
        pad2(convertDecimalToHex(a)),
    ]

    // Return a 4 character hex if possible
    if (
        allow4Char &&
        hex[0].startsWith(hex[0].charAt(1)) &&
        hex[1].startsWith(hex[1].charAt(1)) &&
        hex[2].startsWith(hex[2].charAt(1)) &&
        hex[3].startsWith(hex[3].charAt(1))
    ) {
        return hex[0].charAt(0) + hex[1].charAt(0) + hex[2].charAt(0) + hex[3].charAt(0)
    }

    return hex.join("")
}

/**
 * Converts an RGBA color to an ARGB Hex8 string
 * Rarely used, but required for "toFilter()"
 */
export function rgbaToArgbHex(r: number, g: number, b: number, a: number): string {
    const hex = [
        pad2(convertDecimalToHex(a)),
        pad2(Math.round(r).toString(16)),
        pad2(Math.round(g).toString(16)),
        pad2(Math.round(b).toString(16)),
    ]

    return hex.join("")
}

/** Converts a decimal to a hex value */
export function convertDecimalToHex(d: string | number): string {
    return Math.round(parseFloat((d as string)) * 255).toString(16)
}

/** Converts a hex value to a decimal */
export function convertHexToDecimal(h: string): number {
    return parseIntFromHex(h) / 255
}

/** Parse a base-16 hex value into a base-10 integer */
export function parseIntFromHex(val: string): number {
    return parseInt(val, 16)
}

export function numberInputToObject(color: number): IRGB {
    return {
        r: color >> 16,
        g: (color & 0xff00) >> 8,
        b: color & 0xff,
    }
}

// the following functions are based off of the pseudocode
// found on www.easyrgb.com

export function labToRgb(lV: number, aV: number, bV: number): Numberify<IRGB> {
    let y = (lV + 16) / 116,
        x = aV / 500 + y,
        z = y - bV / 200,
        r, g, b

    x = 0.95047 * (x * x * x > 0.008856 ? x * x * x : (x - 16 / 116) / 7.787)
    y = 1.00000 * (y * y * y > 0.008856 ? y * y * y : (y - 16 / 116) / 7.787)
    z = 1.08883 * (z * z * z > 0.008856 ? z * z * z : (z - 16 / 116) / 7.787)

    r = x *  3.2406 + y * -1.5372 + z * -0.4986
    g = x * -0.9689 + y *  1.8758 + z *  0.0415
    b = x *  0.0557 + y * -0.2040 + z *  1.0570

    r = r > 0.0031308 ? 1.055 * Math.pow(r, 1 / 2.4) - 0.055 : 12.92 * r
    g = g > 0.0031308 ? 1.055 * Math.pow(g, 1 / 2.4) - 0.055 : 12.92 * g
    b = b > 0.0031308 ? 1.055 * Math.pow(b, 1 / 2.4) - 0.055 : 12.92 * b

    return {
        r: Math.max(0, Math.min(1, r)) * 255,
        g: Math.max(0, Math.min(1, g)) * 255,
        b: Math.max(0, Math.min(1, b)) * 255, 
    }
}

export function rgbToLab(r: number, g: number, b: number): Numberify<ICIELAB> {
    r = r / 255
    g = g / 255
    b = b / 255

    let x, y, z

    r = r > 0.04045 ? Math.pow((r + 0.055) / 1.055, 2.4) : r / 12.92
    g = g > 0.04045 ? Math.pow((g + 0.055) / 1.055, 2.4) : g / 12.92
    b = b > 0.04045 ? Math.pow((b + 0.055) / 1.055, 2.4) : b / 12.92

    x = (r * 0.4124 + g * 0.3576 + b * 0.1805) / 0.95047
    y = (r * 0.2126 + g * 0.7152 + b * 0.0722) / 1.00000
    z = (r * 0.0193 + g * 0.1192 + b * 0.9505) / 1.08883

    x = x > 0.008856 ? Math.pow(x, 1 / 3) : 7.787 * x + 16 / 116
    y = y > 0.008856 ? Math.pow(y, 1 / 3) : 7.787 * y + 16 / 116
    z = z > 0.008856 ? Math.pow(z, 1 / 3) : 7.787 * z + 16 / 116

    return {
        l: 116 * y - 16,
        a: 500 * (x - y),
        b: 200 * (y - z),
    }
}

export function rgbToYPbPr(r: number, g: number, b: number): Numberify<IYPbPr> {
    return {
        y: 0.299 * r + 0.587 * g + 0.114 * b,
        pb: -0.169 * r - 0.331 * g + 0.500 * b,
        pr: 0.500 * r - 0.419 * g - 0.081 * b,
    }
}

export function yPbPrToRgb(y: number, pb: number, pr: number): Numberify<IRGB> {
    return {
        r: Math.round(y + 1.402 * pr),
        g: Math.round(y - 0.344 * pb - 0.714 * pr),
        b: Math.round(y + 1.772 * pb),
    }
}
