import { homepageFiltersCurrentlyApplied, PageType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { addEventListenerPoly } from "../../common/addEventListenerPolyfill"
import { Component } from "../../common/defui/component"
import { ignoreMetaClick } from "../../common/DOMutils"
import { getCurrentGender } from "../../common/genders"
import { updateFormRedirectUrl } from "../../common/redirectParamUtils"
import { makeResponsive } from "../ui/responsiveUtil"
import { GenderedLink } from "./genderedLink"
import { Pagination } from "./pagination/pagination"
import { isRoomRoomlistSpaEligiblePage } from "./roomlist/spaHelpers"
import type { IURLState } from "@multimediallc/cb-roomlist-prefetch"

export class Footer extends Component {
    private genderedLinks: GenderedLink[] = []

    constructor(footerEl: HTMLElement) {
        super(footerEl)

        const footerNav = footerEl.querySelector(".nav")
        if (footerNav !== null && footerNav instanceof HTMLElement) {
            makeResponsive(footerNav, 500, 800, [
                { name: "padding-left", min: 10, max: 65 },
                { name: "padding-right", min: 10, max: 65 },
            ])
        }

        const office = document.querySelector(".footer-holder .footer-cb-address")
        if (office !== null && office instanceof HTMLElement) {
            makeResponsive(office, 500, 589, [{ name: "margin-left", min: -55, max: 0 }],
                (styleVal: number) => {
                    if (styleVal === 0) {
                        office.style.marginLeft = ""
                    }
                })
        }
        // Tag category links to intercept TS roomlist navigation
        footerEl.querySelectorAll<HTMLAnchorElement>(".footercon a").forEach((el: HTMLAnchorElement) => {
            addEventListenerPoly("click", el, (event: MouseEvent) => {
                if (UrlState.current.state.pageType === PageType.HOME ||
                    isRoomRoomlistSpaEligiblePage() && UrlState.current.state.pageType === PageType.ROOM) {
                    const href = el.getAttribute("href")
                    if (href !== null && href.startsWith("/")) {
                        ignoreMetaClick(event, () => {
                            event.preventDefault()
                            // For footer links that correspond to a filter panel option we skip caching the filter
                            // selections, as their saved filters won't be restored and would otherwise be overwritten
                            UrlState.current.navigateTo(new URL(el.href), this.isFilterPanelCategoryLink(href))
                        })
                    }
                }
            })
        })

        // Ensure footer links update correctly with gender tab/checkbox changes
        footerEl.querySelectorAll<HTMLAnchorElement>("div[gendered-category] a").forEach((anchorEl) => {
            this.genderedLinks.push(new GenderedLink({ anchorElement: anchorEl, gender: getCurrentGender() }))
        })
        UrlState.current.listen(["genders"], (state: IURLState) => {
            const gender = (state.genders ?? [])[0] ?? ""
            this.genderedLinks.forEach((anchor) => {
                anchor.setState({ gender })
            })
            updateLanguageLinks()
        }, this.element)

        Pagination.pageNumberChanged.listen(updateLanguageLinks)
    }

    private isFilterPanelCategoryLink(href: string) {
        const linkState = UrlState.current.readStateFromURL(new URL(href, window.location.origin))
        return homepageFiltersCurrentlyApplied(linkState)
    }
}

/** Ensures language links redirect to the selected gender tab and page number. */
function updateLanguageLinks(): void {
    document.querySelectorAll("#footer-holder form").forEach((form: HTMLFormElement) => {
        updateFormRedirectUrl(form)
    })
}
