function isPortraitFromScreenAPI() {
    // Since it's experimental, this API doesn't reliably return screen["orientation"]["angle"], i.e., it will sometimes
    // return zero for every angle. So, we must rely on screen["orientation"]["type"]
    const orientation = screen["orientation"] !== undefined
        ? screen["orientation"]["type"]
        : undefined;
    if (orientation !== undefined) {
        if (orientation.startsWith("portrait")) {
            return true;
        }
        else if (orientation.startsWith("landscape")) {
            return false;
        }
    }
    return undefined;
}
export function isPortrait() {
    if (!isOrientationChangeSupported()) {
        return true;
    }
    // Try the new API first, unfortunately it's not supported everywhere
    const portrait = isPortraitFromScreenAPI();
    if (portrait !== undefined) {
        return portrait;
    }
    // Now try the deprecated API which should still be supported on some browsers like iOS, IE and Edge
    switch (window.orientation) {
        case 0:
        case 180:
        case -180: // just in case
            return true;
        case 90:
        case -90:
        case 270: // just in case
            return false;
    }
    // Finally try the least reliable way
    // This is for devices where the height changes when the keyboard is open,
    // potentially making the height smaller than the width, which would trigger landscape mode.
    if (window.innerWidth === Math.min(screen.width, screen.height)) {
        return true;
    }
    return window.innerWidth <= window.innerHeight;
}
const windowHasOrientationChangeProperty = Object.prototype.hasOwnProperty.call(window, "onorientationchange"); // IE doesn't have window.hasOwnProperty
export function isOrientationChangeSupported() {
    return windowHasOrientationChangeProperty;
}
function isScreenOrientationChangeSupported() {
    // eslint-disable-next-line compat/compat
    return "orientation" in screen && "onchange" in screen.orientation;
}
export function isAnyOrientationChangeSupported() {
    return (isOrientationChangeSupported() || isScreenOrientationChangeSupported());
}
export function addOrientationChangeListener(listener) {
    if (isScreenOrientationChangeSupported()) {
        return screen.orientation.addEventListener("change", listener);
    }
    else if (isOrientationChangeSupported()) {
        window.addEventListener("orientationchange", listener);
    }
}
export function removeOrientationChangeListener(listener) {
    if (isScreenOrientationChangeSupported()) {
        return screen.orientation.removeEventListener("change", listener);
    }
    else if (isOrientationChangeSupported()) {
        window.removeEventListener("orientationchange", listener);
    }
}
