import { t } from "@lingui/macro"
import { postCb } from "../../common/api"
import { pageContext } from "../interfaces/context"
import type { XhrError } from "../../common/api"

export interface IBanIDQuery {
    createdBy: string
    roomUser: string,
    bannedUser: string,
    isSilence: boolean
}

export async function getBanID(query: IBanIDQuery): Promise<number> {
    const obj = {
        "room_user": query.roomUser,
        "created_by": query.createdBy,
        "banned_user": query.bannedUser,
        "is_silence": query.isSilence.toString(),
    }
    return postCb("api/ts/chat/ban-id/", obj).then((xhr) => {
        const data: { banid: number } = JSON.parse(xhr.responseText)
        return data["banid"]
    })
}

export function editRoomBan(ID: number, action: string, roomName: string, successFunc: () => void, failFunc: () => void): void {
    postCb("edit_room_ban/", { "banid": String(ID), "action": action, "room_username": roomName }).then((xhr) => {
        const responseText = xhr.responseText
        if (responseText === null) {
            failFunc()
            return
        }
        let response
        try {
            response = JSON.parse(responseText)
        } catch (a) {
            failFunc()
            return
        }
        if (response["error"] !== undefined) {
            failFunc()
            return
        } else {
            successFunc()
        }
    }).catch(() => {
        failFunc()
    })
}

export function banUser(username: string, room: string): Promise<void | XMLHttpRequest> {
    if (pageContext.current.loggedInUser?.username !== room) {
        error("banUser called by nonbroadcaster", { username, room })
        return Promise.reject(Error(t`You do not have permission to ban users in this room.`))
    }
    if (username === room) {
        error("banUser called on self", { username })
        return Promise.reject(Error(t`You cannot ban yourself from your own room.`))
    }

    return postCb(`roomban/${username}/${room}/`, {}).catch((err: XhrError) => {
        error("broadcast_ban_error", err)

        let errorString = t`There was an error banning this user.`
        if (err.xhr.status === 404) {
            errorString = t`Error: User not found.`
        } else if (err.xhr.responseText === "already_banned") {
            errorString = t`Error: User is already banned from your room.`
        }

        throw Error(errorString)
    })
}
