import { SortOption } from "@multimediallc/cb-roomlist-prefetch"
import { getCookieOrUndefined } from "@multimediallc/web-utils/storage"

export const ROOMLIST_SORT_COOKIE_NAME = "rlsrt"

export function getRoomlistSortCookieValue(): SortOption {
    const sort = getCookieOrUndefined(ROOMLIST_SORT_COOKIE_NAME) as SortOption
    if (Object.values(SortOption).includes(sort)) {
        return sort
    }
    return SortOption.Popular
}
