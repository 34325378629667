import { t } from "@lingui/macro"
import { isPortrait } from "@multimediallc/web-utils"
import { isAndroid, isiOS, isiPad, isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { ConversationType, PrivateMessageSource, sendPrivateMessage } from "../../cb/api/pm"
import { sendTip, tipsInPast24HoursUpdate, TipType, tokenBalanceUpdate } from "../../cb/api/tipping"
import { addColorClass } from "../../cb/colorClasses"
import { ReactWrapper } from "../../cb/components/ReactWrapper"
import { Toggle } from "../../cb/components/toggle"
import { hideRulesModal, maybeShowRulesModal } from "../../cb/components/userMenus/ui/rulesModal"
import { pageContext } from "../../cb/interfaces/context"
import { cleanTipAmountInput, createTipAmountInput, isValidTipInput, popUpPurchasePage, popUpTokenPurchaseModal } from "../../cb/ui/tipping"
import { SubSystemType } from "../../common/debug"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { modalAlert, modalAlertRotateDismiss, modalConfirm } from "../alerts"
import { isNotLoggedIn } from "../auth"
import { stringPart } from "../chatconnection/roomnoticeparts"
import { onTipSent, roomCleanup, roomLoaded } from "../context"
import { CustomInput } from "../customInput"
import { Component } from "../defui/component"
import { applyStyles, isElementInViewport } from "../DOMutils"
import { EventRouter } from "../events"
import { exitFullscreen, fullscreenChange, isFullscreen } from "../fullscreen"
import { addPageAction, NRSetPlayerVolume } from "../newrelic"
import { JpegPushPlayer } from "../player/jpegPlayer"
import { sdRatio, wsRatio } from "../player/playerSettings"
import { LLHLSSupported, mobileLLHLS } from "../player/utils"
import { privateJoinInitiated } from "../privateShow"
import { RoomStatus, roomStatusIsWatching } from "../roomStatus"
import { RoomStatusNotifier } from "../roomStatusNotifier"
import { RoomType } from "../roomUtil"
import { styleTransition } from "../safeStyle"
import { OutgoingMessageType, parseOutgoingMessage, ShortcodeParser } from "../specialoutgoingmessages"
import { maxInputChat } from "../theatermodelib/chatTabContents"
import { i18n } from "../translation"
import { parseQueryString } from "../urlUtil"
import { playerForceMuted, roomListRequest } from "../userActionEvents"
import { isVirtualKeyboardLikelyShowing } from "../virtualKeyboardUtil"
import { createAdvancedOptions } from "./advancedOptions"
import { LayoutConstraints, LayoutConstraintsHandler } from "./layoutConstraints"
import {
    mobileConversationLinkFullscreenToggle, mobileFullscreenSendChat, mobilePureChatDrag, mobilePureChatTap,
    mobileVideoControlsTap, playerChangeSize, PlayerSizeChangeType,
} from "./mobileControlsEvents"
import { needsSafariInputFix } from "./mobileRoot"
import { closeAdvancedOptions } from "./portraitContents"
import { shouldUseFullscreenAPI } from "./shouldUseFullscreenAPI"
import { TouchState } from "./touchUtil"
import { siteHeaderMenuOpened, switchedToHLS } from "./userActionEvents"
import { VideoPlayerTouchControls } from "./videoPlayerTouchControls"
import { getViewportHeight, viewportOffsetTop } from "./viewportDimension"
import { screenOrientationChanged } from "./windowOrientation"
import type { AdvancedOptions } from "./advancedOptions"

import type { MobileDismissibleMessages } from "./mobileDismissibleMessages"
import type { MobilePlayer } from "./mobilePlayer"
import type { MobilePureChat } from "./mobilePureChat"
import type { MobileRoot } from "./mobileRoot"
import type { IPMError, ISendPrivateMessage } from "../../cb/api/pm"
import type { IChatConnection, IRoomContext } from "../context"
import type { IShortcodeMessage, ITipRequest, ITipRequestMessage } from "../specialoutgoingmessages"

const defaultVolume = 100
const ICON_SIZE = 42     // The size of all of the controls is based off of the icon size
const ICON_MARGIN = 6    // Margin between icons and the edges of the screen
const ICON_PADDING = 8   // The padding the icons would have if their backgrounds were not baked into the SVG
const CENTER_MARGIN = 10 // Horizontal margin between center div buttons
const FONT_SIZE = 16     // If set to less than 16, IOS zooms into inputs
const TIP_MESSAGE_LANDSCAPE_PLACEHOLDER = i18n.mobileTipMessageLabelLandscape
const TIP_MESSAGE_PORTRAIT_PLACEHOLDER = i18n.mobileTipMessageLabelPortrait
const FADE_DURATION = 250
const AUTOFILL_KEYBOARD_ACCESSORY_VIEW_HEIGHT = 50
const HITBOX_EXPANSION_PX = 6 // increase in clickable area beyond the icon for unmute and fullscreen icons

const storageKey = "videoControls"

/**
 * @styles: scss/theme/mobile/room/mobileVideoControls.scss
 */
export class MobileVideoControls extends Component {
    public readonly setPlayerVisibilityEvent = new EventRouter<boolean>("setPlayerVisibilityEvent")
    public readonly centerPlayerEvent = new EventRouter<undefined>("centerPlayerEvent")
    public readonly requestFullscreenEvent = new EventRouter<undefined>("requestFullscreenEvent")
    public readonly setPlayerComponentVolumeMutedEvents = new EventRouter<{
        volume: number,
        isMuted: boolean,
    }>("setPlayerComponentVolumeMutedEvents")
    public readonly forceHlsPlayerEvent = new EventRouter<{
        roomContext: IRoomContext,
        unmute?: boolean,
    }>("forceHlsPlayerEvent")
    public readonly playerComponentReadjustForceHlsOverlayOrderEvent = new EventRouter<HTMLDivElement>("playerComponentReadjustForceHlsOverlayOrderEvent")
    public readonly showJpegPlayerComponentImage = new EventRouter<undefined>("showJpegPlayerComponentImage")
    public readonly toggleFullscreen = new EventRouter<boolean>("toggleFullscreen")
    public readonly requestHlsPlayerPlayIfPaused = new EventRouter<undefined>("requestHlsPlayerPlayIfPaused")

    private playerOverlay: HTMLDivElement
    private volumeImgWrapper: HTMLDivElement
    private volumeImg: HTMLImageElement
    private state = { volume: defaultVolume, isMuted: true }
    private fullscreenImgWrapper: HTMLDivElement
    private fullscreenImg: HTMLImageElement
    private resizeDragLabel: HTMLDivElement
    private chatForm: HTMLFormElement
    public chatInput: CustomInput
    private tipForm: HTMLFormElement
    private tipMessageInput: HTMLInputElement
    private tipAmountInput: HTMLInputElement
    private tipAmountContainer: HTMLDivElement
    private sendButton: HTMLButtonElement
    public centerControlsDiv: HTMLDivElement
    private chatExitButton: HTMLButtonElement
    private chatExitImg: HTMLImageElement
    private tokenBalanceLink: HTMLAnchorElement
    private tokenBalanceWrapper: HTMLDivElement
    private tokenBalanceAmount: HTMLSpanElement
    private tokenBalance = 0
    public airPlayImg: HTMLImageElement
    public chromecastImg: HTMLImageElement
    private opacityTimer: number
    private isVisible = true
    private chatConnection: IChatConnection | undefined
    private isTipFormActive = false
    private isClickingAButton = false
    private roomType = RoomType.Public
    private roomName: string
    private currentRoomContext: IRoomContext | undefined
    private layoutHandler = new LayoutConstraintsHandler()
    private layoutConstraints = new LayoutConstraints(this.constructor.name)
    public mobilePureChat: undefined | MobilePureChat
    private playerScroll: number
    private playerHeight: number
    private airPlayIconConstraint = 0
    private autofillAccessoryConstraint = 0
    private forceHlsOverlay: HTMLDivElement | undefined
    private forceHlsTriggered = false
    private playButtonContainer: HTMLDivElement | undefined
    private forceHlsPlayButtonCreated = false
    private hlsWaitingForInteraction = false
    private userUnmuted = false
    private wasInPortrait = isPortrait()
    private playerIsFullscreen: boolean
    private playerUsingAirPlay: boolean
    private playerUsingChromecast: boolean
    private playerIsJPEG: boolean
    private playerIsHlsPlaceholder: boolean
    private playerElement: HTMLElement
    public readonly touchControls: VideoPlayerTouchControls
    private headerHeight = 0
    private initialFullscreen = false
    private readonly mobileRoot: MobileRoot
    private topBar: HTMLDivElement
    private bottomBar: HTMLDivElement
    private smallestPortraitHeight?: number
    private smallestLandscapeWidth?: number
    private optionsButton: HTMLDivElement | undefined
    private optionImg: HTMLImageElement
    private LLHLSAllowed = false
    private advancedOptionsOpen = false
    private advancedOptions: AdvancedOptions | undefined
    private optionsDivWrapper: HTMLDivElement | undefined
    private LLHLSEnabled = false
    private LLHLSWarningShown = false

    constructor(private player: MobilePlayer, mobileRoot: MobileRoot, private mobileDismissibleMessages: MobileDismissibleMessages) {
        super()
        this.mobileRoot = mobileRoot

        this.playerIsFullscreen = player.isFullscreen
        this.playerUsingAirPlay = player.usingAirPlay
        this.playerUsingChromecast = player.usingChromecast
        if (player.playerComponent instanceof JpegPushPlayer) {
            this.playerIsJPEG = true
            this.playerIsHlsPlaceholder = player.playerComponent.getIsHlsPlaceholder()
        } else {
            this.playerIsJPEG = false
            this.playerIsHlsPlaceholder = false
        }

        this.playerElement = player.element // TODO Attempt to remove this as well.

        const parsedQueryString = parseQueryString(window.location.search)
        const disableSound = parsedQueryString["disable_sound"]
        if (disableSound !== undefined && disableSound.toLowerCase() === "true" || disableSound === "1") {
            this.state.isMuted = true
        }

        const headerMenu = document.querySelector<HTMLDivElement>("#static-header")
        if (headerMenu !== null) {
            this.headerHeight = headerMenu.offsetHeight
        }

        // region DOM Creation
        addColorClass(this.element, "MobileVideoControls")
        this.element.style.position = "fixed"
        this.element.style.width = "inherit"
        this.element.style.top = "0px"
        this.element.style.display = "flex"
        this.element.style.flexDirection = "column"
        this.element.style.alignItems = "center"
        this.element.style.justifyContent = "space-between"
        this.element.style.zIndex = `${RoomStatusNotifier.Z_INDEX - 1}`

        this.playerOverlay = document.createElement("div")
        this.playerOverlay.style.position = "absolute"
        this.playerOverlay.style.width = "100%"
        this.playerOverlay.style.height = "100%"
        this.playerOverlay.dataset.testid = "player-overlay"
        this.element.appendChild(this.playerOverlay)

        this.initControls()
        this.constructUI()
        // endregion

        this.touchControls = new VideoPlayerTouchControls(this.playerOverlay, this.playerElement)
        this.bindTouchControlsEvent()

        roomLoaded.listen((context) => {
            this.currentRoomContext = context
            this.chatConnection = context.chatConnection
            this.roomName = context.chatConnection.room()
            context.chatConnection.event.statusChange.listen(roomStatusChangeNotification => {
                this.updatePlayerHiddenStatus(roomStatusChangeNotification.currentStatus)
                this.showHideHlsPlayButton(roomStatusChangeNotification.currentStatus)
                this.roomType = roomStatusChangeNotification.currentStatus === RoomStatus.PrivateWatching ? RoomType.Private : RoomType.Public
                this.tokenBalanceLink.onclick = () => {
                    const purchaseSource = pageContext.current.PurchaseEventSources["TOKEN_SOURCE_TIP_CALLOUT"]
                    popUpPurchasePage({ source: purchaseSource, "roomType": this.roomType })
                }
            })

            if (this.playerIsJPEG && this.playerIsHlsPlaceholder
                && !this.forceHlsPlayButtonCreated) {
                this.forceHlsPlayButtonCreated = true
                this.createForceHlsPlayButton()
                this.showHideHlsPlayButton(context.dossier.roomStatus)
            }
            this.updatePlayerHiddenStatus(context.dossier.roomStatus)
            if (!this.userUnmuted && !this.state.isMuted) {
                this.userUnmuted = true
            }
            this.updateVolumeImage()
            if (!this.playerIsJPEG) {
                this.updatePlayerVolume()
            }
            this.updateTokenBalance(isNaN(context.dossier.tokenBalance) ? 0 : context.dossier.tokenBalance)
            this.LLHLSWarningShown = false
        })

        roomListRequest.listen(() => {
            this.setFullscreen(false)
        })

        roomCleanup.listen(() => {
            hideRulesModal()
        })

        playerForceMuted.listen(() => {
            this.mute()
        })

        tokenBalanceUpdate.listen(delta => {
            if (isNaN(delta.tokens)) {
                return
            }
            this.updateTokenBalance(delta.tokens)
        })

        mobilePureChatTap.listen((isResizeIndicatorVisible: boolean) => {
            if (this.isAnyInputFocused()) {
                this.closeActiveInput()
                this.showControls()
                if (this.isTipFormActive) {
                    this.hideTipForm()
                }
            } else if (isResizeIndicatorVisible === this.isVisible) {
                this.showControls()
                return
            } else if (this.isVisible) {
                if (this.isTipFormActive) {
                    this.hideTipForm()
                } else {
                    this.hideControls()
                }
            } else {
                this.showControls()
            }
        })
        mobilePureChatDrag.listen(() => {
            if (this.isVisible) {
                this.showControls()
            }
        })

        mobileConversationLinkFullscreenToggle.listen((conversationType: ConversationType) => {
            this.setFullscreen(false)
            if (!isPortrait()) {
                modalAlertRotateDismiss(conversationType === ConversationType.PM ? i18n.rotateForPMs : i18n.rotateForDMs)
            }
        })

        privateJoinInitiated.listen(() => {
            this.forceHlsOverlayFunc()
        }, false)

        siteHeaderMenuOpened.listen((wasOpened) => {
            if (wasOpened) {
                this.hideControls()
            } else {
                this.repositionChildren()
            }
        })

        addEventListenerPoly("scroll", window, () => {
            this.updateTokenBalancePosition()
        })

        screenOrientationChanged.listen(() => {
            if (this.wasInPortrait === isPortrait()) {
                // These two values SHOULD be different. If they are the same, it means that
                // the browser fired the orientationchange event before it actually updated the window.
                // So we give the window time to update.

                window.setTimeout(() => {
                    this.onOrientationChange()
                }, 0)
            } else {
                // The browser has already updated the window, so immediately apply the changes.
                this.onOrientationChange()
            }

            window.setTimeout(() => {
                // These values should be cleared after an orientation change so
                // that they don't retain inaccurate numbers collected from the resize listener.
                // Timeout to let the resize listener resolve since this listener fires first.
                this.smallestPortraitHeight = undefined
                this.smallestLandscapeWidth = undefined
            }, 10)
        })

        addEventListenerPoly("resize", window, () => {
            if (this.mobilePureChat !== undefined) {
                this.mobilePureChat.scrollToBottom()
            }
        })

        window.visualViewport?.addEventListener("resize", () => {
            if (window.visualViewport && isAndroid() && isVirtualKeyboardLikelyShowing()) {
                // These evaluate to inaccurate values from the previous orientation whenever
                // an orientation change happens. `isPortrait()` does not help since it is not always accurate
                // for how many times this event fires mid orientation change.
                // We avoid this by clearing the values in the `screenOrientationChanged` listener.
                this.smallestPortraitHeight = Math.min(
                    this.smallestPortraitHeight ?? window.visualViewport.height,
                    window.visualViewport.height,
                )
                this.smallestLandscapeWidth = Math.min(
                    this.smallestLandscapeWidth ?? window.visualViewport.width,
                    window.visualViewport.width,
                )
            }
        })

        fullscreenChange.listen(() => {
            // All changes to fullscreen will eventually come to this function.
            if (isFullscreen() === this.playerIsFullscreen) {
                // A user-initiated change. The side-effects have already been set.
                return
            }
            // In the case of hardware changes to fullscreen,
            // we force the player to go back to the fullscreen state that we want,
            // but we cannot call the fullscreen API since it is not user-initiated.
            const disableFullscreenAPI = true
            this.setFullscreen(this.playerIsFullscreen, disableFullscreenAPI)

            if ((this.playerUsingAirPlay || this.playerUsingChromecast) &&
                this.canResizePlayerInFullscreen() && this.mobilePureChat !== undefined) {
                this.mobilePureChat.setPortraitHeight(getViewportHeight() - 100)
            }
        })

        this.showControls()
        this.repositionChildren()

        const style = document.createElement("style")

        // eslint-disable-next-line @multimediallc/no-inner-html
        style.innerHTML = `
            .mobileFullscreenInput::-webkit-input-placeholder {
                color: black;
                opacity: 0.65;
            }
            .mobileFullscreenInput:-ms-input-placeholder {
                color: black;
                opacity: 0.65;
            }
            .mobileFullscreenInput:-moz-placeholder {
                color: black;
                opacity: 0.65;
            }
            .mobileFullscreenInput::-moz-placeholder {
                color: black;
                opacity: 0.65;
            }
        `
        document.head.appendChild(style)

        this.layoutHandler.addListener(player.roomStatusNotifier.layoutConstraints)
        this.layoutHandler.addListener(this.layoutConstraints)
        if (isLocalStorageSupported()) {
            const savedSettings = window.localStorage.getItem(storageKey)
            if (savedSettings !== null) {
                const importedState = JSON.parse(savedSettings)
                this.state.isMuted = player.playerComponent.supportsAutoplayWithAudio ? importedState["isMuted"] : true
                this.initialFullscreen = importedState["isFullscreen"]
            } else {
                this.state.isMuted = !player.playerComponent.supportsAutoplayWithAudio
            }
        }
    }

    private initControls(): void {
        this.createVolumeImg()
        this.createCenterControlsDiv()
        this.createChatForm()
        this.createTipForm()
        this.createSendButton()
        this.createTokenBalance()
        this.createFullscreenImg()
        this.createResizeDragLabel()
        if (LLHLSSupported() && !this.player.playerComponent.shouldDisallowLLHLS()) {
            this.createOptionsButton()
        }
        this.airPlayImg = this.createCastingImage("airplay.svg")
        this.chromecastImg = this.createCastingImage("chromecast.svg")

        this.createTopBar()
        this.createBottomBar()
    }

    private constructUI(): void {
        this.element.appendChild(this.topBar)
        if (LLHLSSupported() && !this.player.playerComponent.shouldDisallowLLHLS()) {
            this.createAdvancedOptions()
        }
        this.element.appendChild(this.bottomBar)

        this.centerControlsDiv.appendChild(this.chatForm)
        this.centerControlsDiv.appendChild(this.tipForm)
        this.centerControlsDiv.appendChild(this.sendButton)
    }

    private createTopBar(): void {
        this.topBar = document.createElement("div")
        this.topBar.style.width = "100%"
        this.topBar.style.display = "flex"
        this.topBar.style.alignItems = "center"
        this.topBar.style.padding = "6px"
        this.topBar.style.boxSizing = "border-box"

        this.topBar.appendChild(this.airPlayImg)
        this.topBar.appendChild(this.chromecastImg)
        this.topBar.appendChild(this.tokenBalanceWrapper)
    }

    private createBottomBar(): void {
        this.bottomBar = document.createElement("div")
        this.bottomBar.classList.add("bottomBar")
        this.bottomBar.style.width = "100%"
        this.bottomBar.style.display = "flex"
        this.bottomBar.style.alignItems = "center"
        this.bottomBar.style.justifyContent = "center"
        this.bottomBar.style.boxSizing = "border-box"

        this.bottomBar.appendChild(this.volumeImgWrapper)
        if (this.optionsButton !== undefined) {
            this.bottomBar.appendChild(this.optionsButton)
        }
        this.bottomBar.appendChild(this.resizeDragLabel)
        this.bottomBar.appendChild(this.centerControlsDiv)
        this.bottomBar.appendChild(this.fullscreenImgWrapper)
    }

    private onOrientationChange(): void {
        this.show()
        if (isPortrait()) {
            this.setPlaceholder(this.tipMessageInput, TIP_MESSAGE_PORTRAIT_PLACEHOLDER)
            if (this.advancedOptions !== undefined) {
                this.advancedOptions.optionsDiv.style.width = "95%"
                this.advancedOptions.optionsDiv.style.bottom = "54px"
                this.advancedOptions.HLSToggleTooltip.style.bottom = "80px"
            }
        } else {
            this.setPlaceholder(this.tipMessageInput, TIP_MESSAGE_LANDSCAPE_PLACEHOLDER)
            if (this.advancedOptions !== undefined) {
                this.advancedOptions.optionsDiv.style.width = "50%"
                this.advancedOptions.optionsDiv.style.bottom = "13px"
                this.advancedOptions.HLSToggleTooltip.style.bottom = "40px"
            }
        }
        if (!this.playerIsFullscreen && this.isTipFormActive) {
            this.hideTipForm()
        }

        if (LLHLSSupported() && mobileLLHLS()) {
            this.closeAdvancedOptions()
        }

        hideRulesModal()

        // Some browsers do not keep inputs in view on orientation change, so close them
        this.closeActiveInput()

        if (this.mobilePureChat !== undefined) {
            this.mobilePureChat.setVisible(this.playerIsFullscreen || !isPortrait())
            this.mobilePureChat.scrollToBottom()
        }
        this.wasInPortrait = isPortrait()
    }

    private updatePlayerHiddenStatus(roomStatus: RoomStatus): void {
        switch (roomStatus) {
            // case RoomStatus.Unknown:
            case RoomStatus.Offline:
            case RoomStatus.NotConnected:
            case RoomStatus.Away:
            // case RoomStatus.PrivateRequesting:
            case RoomStatus.PrivateNotWatching:
            // case RoomStatus.PrivateWatching:
            // case RoomStatus.PrivateSpying:
            // case RoomStatus.Public:
            case RoomStatus.Hidden:
            case RoomStatus.PasswordProtected:
                this.setPlayerVisibilityEvent.fire(true)
                break
            default:
                this.setPlayerVisibilityEvent.fire(false)
        }
    }

    private createVolumeImg(): void {
        this.volumeImgWrapper = document.createElement("div")
        this.volumeImgWrapper.style.position = "relative"
        this.volumeImgWrapper.style.display = "inline-block"

        this.volumeImg = document.createElement("img")
        this.volumeImg.style.height = `${ICON_SIZE}px`
        this.volumeImg.style.width = `${ICON_SIZE}px`
        this.volumeImg.style.boxSizing = "border-box"
        this.volumeImg.style.padding = "0"
        this.volumeImg.style.cursor = "pointer"
        this.volumeImg.style.border = "none"
        this.volumeImg.style.background = "none"
        this.volumeImg.style.zIndex = "1"
        this.volumeImg.style.marginRight = `${CENTER_MARGIN}px`
        this.volumeImgWrapper.appendChild(this.volumeImg)

        const clickElement = this.volumeImgWrapper
        const expandedHitbox = document.createElement("div")
        applyStyles(expandedHitbox, {
            position: "absolute",
            top: `-${HITBOX_EXPANSION_PX}px`,
            bottom: `-${HITBOX_EXPANSION_PX}px`,
            left: `-${HITBOX_EXPANSION_PX}px`,
            right: "0", // volumeImgWrapper includes the volumeImg margin, so its right edge goes right up to the next controls component
        })
        this.volumeImgWrapper.appendChild(expandedHitbox)

        addEventListenerPoly("click", clickElement, () => {
            if (!this.isVisible) {
                this.showControls()
                return
            }
            if (LLHLSSupported() && mobileLLHLS()) {
                this.closeAdvancedOptions()
            }
            this.showControls()
            if (!this.playerIsJPEG) {
                addPageAction("ToggleMute", { "newState": !this.state.isMuted })
                if (this.state.isMuted) {
                    this.unmute()
                } else {
                    this.mute()
                }
            } else {
                if (this.hlsWaitingForInteraction) {
                    this.forceHlsOverlayFunc(true)
                } else {
                    this.forceHLS()
                }
            }
        })
    }

    private createCenterControlsDiv(): void {
        this.centerControlsDiv = document.createElement("div")
        this.centerControlsDiv.style.opacity = "0"
        this.centerControlsDiv.style.display = "none"
        this.centerControlsDiv.style.zIndex = "1"
        this.centerControlsDiv.style.height = `${ICON_SIZE}px`
        this.centerControlsDiv.style.gridTemplateColumns = "1fr auto"
        this.centerControlsDiv.style.columnGap = "10px"
        this.centerControlsDiv.style.flex = "1"

        this.centerControlsDiv.onclick = () => {
            if (LLHLSSupported() && mobileLLHLS()) {
                this.closeAdvancedOptions()
            }
        }

        styleTransition(this.centerControlsDiv, `opacity ${FADE_DURATION}ms`)
    }

    private createChatForm(): void {
        this.chatForm = document.createElement("form")
        this.chatInput = new CustomInput(() => {
            return this.safeSubmit()
        }, maxInputChat)
        this.chatExitButton = document.createElement("button")
        this.chatExitImg = document.createElement("img")
        this.chatExitButton.appendChild(this.chatExitImg)

        this.styleForm(this.chatForm)

        this.chatInput.element.classList.add("mobileFullscreenInput")
        this.chatInput.element.style.textOverflow = ""
        this.chatInput.element.style.verticalAlign = "bottom"
        this.chatInput.element.style.webkitUserSelect = "text"
        this.chatInput.setPlaceholder(i18n.mobileChatLabel, "placeholder")
        this.chatInput.element.dataset["testid"] = "chat-input"
        this.styleInput(this.chatForm)
        this.chatForm.style.overflow = "hidden"
        this.chatForm.style.alignItems = "center"
        this.showChatForm()

        this.chatInput.element.style.flex = "1"
        this.chatInput.element.style.height = ""
        this.chatInput.element.style.textOverflow = "ellipsis"
        this.chatInput.element.style.overflow = "hidden"
        this.chatInput.element.style.lineHeight = `${ICON_SIZE - ICON_PADDING * 2}px`
        this.chatInput.element.style.fontSize = `${FONT_SIZE}px`
        this.chatInput.element.style.fontWeight = "bold"
        this.chatInput.element.style.fontFamily = "UbuntuRegular, Arial, Helvetica, sans-serif"

        this.chatForm.appendChild(this.chatInput.element)
        this.chatForm.appendChild(this.chatExitButton)

        const BUTTON_SIZE = ICON_SIZE - 6
        this.chatExitButton.type = "button"
        this.chatExitButton.style.width = `${BUTTON_SIZE}px`
        this.chatExitButton.style.height = `${BUTTON_SIZE}px`
        this.chatExitButton.style.padding = "0"
        this.chatExitButton.style.margin = "0"
        this.chatExitButton.style.border = "none"
        this.chatExitButton.style.borderRadius = `${BUTTON_SIZE / 2}px`
        this.chatExitButton.style.outline = "none"
        this.chatExitButton.style.backgroundColor = "transparent"
        this.chatExitButton.style.opacity = "1"
        this.chatExitButton.style.display = "none"

        this.chatExitImg.src = `${STATIC_URL_MOBILE}exit-chat.svg`
        this.chatExitImg.style.width = "50%"
        this.chatExitImg.style.height = `${100 * 2 / 7}%`
        this.chatExitImg.style.opacity = "1"
        this.chatExitImg.style.display = "none"

        this.chatExitButton.onmousedown = (ev: Event) => {
            ev.preventDefault()
            this.isClickingAButton = true
        }
        this.chatExitButton.onmouseup = (ev: Event) => {
            ev.preventDefault()
            this.isClickingAButton = false
        }
        this.chatExitButton.onclick = (ev: Event) => {
            ev.preventDefault()
            this.chatInput.blur()
            if (this.hlsWaitingForInteraction) {
                this.forceHlsOverlayFunc(false)
            }
        }

        addEventListenerPoly("mousedown", this.chatInput.element, () => {
            if (maybeShowRulesModal(this)) {
                return
            }
            if (this.isVisible) {
                // iOS will not scroll an input into view while it is executing an opacity transition
                // so force the input to be completely opaque
                styleTransition(this.centerControlsDiv, "none")
                this.centerControlsDiv.style.opacity = "1"
            }
        })
        addEventListenerPoly("focus", this.chatInput.element, () => {
            this.showControls()
            this.inputScrollIntoViewFix()
            this.inputFix(true)
            this.repositionChildren()
        })
        addEventListenerPoly("blur", this.chatInput.element, () => {
            styleTransition(this.centerControlsDiv, `opacity ${FADE_DURATION}ms`)
            if (this.isClickingAButton) {
                return
            }
            this.showControls()
            this.closeKeyboardFix()
            this.inputFix(false)
            this.repositionChildren()
        })

        this.chatForm.onsubmit = (ev: Event) => {
            ev.preventDefault()
            this.chatInput.submit()
        }
    }

    private createOptionsButton(): void {
        this.optionsButton = document.createElement("div")
        this.optionsButton.style.position = "relative"
        this.optionsButton.style.display = "inline"
        this.optionsButton.style.overflow = "visible"

        this.optionImg = document.createElement("img")
        applyStyles(this.optionImg, {
            height: `${ICON_SIZE}px`,
            width: `${ICON_SIZE}px`,
            boxSizing: "border-box",
            padding: "0",
            cursor: "pointer",
            border: "none",
            background: "none",
            zIndex: 1,
            marginRight: `${CENTER_MARGIN}px`,
        })
        this.optionImg.src = `${STATIC_URL_MOBILE}advanced-settings.svg`
        this.optionsButton.appendChild(this.optionImg)

        const expandedHitbox = document.createElement("div")
        applyStyles(expandedHitbox, {
            position: "absolute",
            top: `-${HITBOX_EXPANSION_PX}px`,
            bottom: `-${HITBOX_EXPANSION_PX}px`,
            left: "0",
            right: "0",
        })
        this.optionsButton.appendChild(expandedHitbox)

        this.optionsButton.onclick = () => {
            if (!this.isVisible) {
                this.showControls()
                return
            }
            this.advancedOptionsOpen = !this.advancedOptionsOpen
            if (this.advancedOptionsOpen) {
                this.optionImg.src = `${STATIC_URL_MOBILE}advanced-settings-selected.svg`
                clearTimeout(this.opacityTimer)
            } else {
                this.optionImg.src = `${STATIC_URL_MOBILE}advanced-settings.svg`
                this.opacityTimer = window.setTimeout(() => {
                    this.hideControls()
                }, 3500)
            }
            if (this.optionsDivWrapper !== undefined && (this.playerIsFullscreen || !isPortrait())) {
                this.optionsDivWrapper.style.display = this.advancedOptionsOpen ? "flex" : "none"
                this.optionsDivWrapper.style.opacity = this.advancedOptionsOpen ? "1" : "0"
            } else {
                this.mobileRoot.toggleAdvancedOptions(this.advancedOptionsOpen)
            }
        }

        const LLHLSWarning = new ReactWrapper({
            component: "DirectiveTooltip",
            componentProps: {
                text: t`Stream issues? Try turning "Minimize Delay" on/off.`,
                position: "left",
                closeCallback: () => {
                    LLHLSWarning.element.style.display = "none"
                    LLHLSWarning.element.style.opacity = "0"
                    this.opacityTimer = window.setTimeout(() => {
                        this.hideControls()
                    }, 3500)
                },
            },
        })

        applyStyles(LLHLSWarning, {
            display: "none",
            opacity: "0",
            position: "absolute",
            width: "222px",
            bottom: "100%",
            right: "-240%",
            zIndex: 1000,
        })

        this.optionsButton.appendChild(LLHLSWarning.element)

        this.player.playerComponent.requestLLHLSWarning?.listen(() => {
            if (this.LLHLSWarningShown) {
                return
            }
            this.LLHLSWarningShown = true
            LLHLSWarning.element.style.display = "block"
            LLHLSWarning.element.style.opacity = "1"
            this.showControls()
            clearTimeout(this.opacityTimer)
        })

        this.player.playerComponent.updateLLHLSButton.listen(({ allowed, enabled }) => {
            this.LLHLSAllowed = allowed
            if (this.optionsButton !== undefined) {
                this.optionsButton.style.display = allowed && !this.player.playerComponent.shouldDisallowLLHLS() && !this.shouldHideIcons() ? "inline" : "none"
            }
        })

        closeAdvancedOptions.listen(() => {
            this.closeAdvancedOptions()
        })
    }

    private createAdvancedOptions(): void {
        this.advancedOptions = createAdvancedOptions()

        this.optionsDivWrapper = document.createElement("div")
        this.optionsDivWrapper.style.justifyContent = "center"
        this.optionsDivWrapper.style.display = "none"

        const optionsDiv = this.advancedOptions.optionsDiv
        optionsDiv.style.bottom = isPortrait() ? "54px" : "13px"
        optionsDiv.style.display = "flex"
        optionsDiv.style.width = isPortrait() ? "95%" : "50%"
        optionsDiv.style.zIndex = "5"

        const toggle = new Toggle(false, () => {
            this.player.playerComponent.forceStream(toggle.isChecked())
        }, { height: 24, width: 48 })
        toggle.element.style.marginRight = "16px"
        optionsDiv.appendChild(toggle.element)

        this.advancedOptions.optionsDiv.onclick = () => {
            toggle.setChecked(!toggle.isChecked())
            this.LLHLSEnabled = toggle.isChecked()
        }

        this.advancedOptions.HLSToggleTooltip.style.bottom = isPortrait() ? "80px" : "40px"

        this.optionsDivWrapper.appendChild(optionsDiv)
        this.optionsDivWrapper.appendChild(this.advancedOptions.HLSToggleTooltip)
        this.element.appendChild(this.optionsDivWrapper)

        this.player.playerComponent.updateLLHLSButton.listen(({ allowed, enabled }) => {
            if (this.LLHLSEnabled !== enabled) {
                this.LLHLSEnabled = enabled
                toggle.setCheckedDirectly(enabled)
            }
        })
    }

    private inputFix(isFocused: boolean): void {
        if (!needsSafariInputFix() || !isPortrait()) {
            return
        }

        if (isFocused) {
            applyStyles(this.centerControlsDiv, { bottom: "50px" })
            if (this.mobilePureChat !== undefined) {
                this.mobilePureChat.inputFocusOffset = 50
                applyStyles(this.mobilePureChat.element, { bottom: `${65 + this.centerControlsDiv.offsetHeight}px` })
            }
        } else {
            applyStyles(this.centerControlsDiv, { bottom: "0" })
            if (this.mobilePureChat !== undefined) {
                this.mobilePureChat.inputFocusOffset = 0
            }
            this.repositionChildrenRecursive()
        }
    }

    private inputScrollIntoViewFix(): void {
        // Some later versions of iPhone do not properly scroll an input into view while in landscape mode.
        // The root cause requires more research, but for now just force the input into view.
        if (isiOS() && !isPortrait()) {
            // Naively scrolls to bottom of the page. Note that element.scrollIntoView() does not work for inputs on iOS
            document.documentElement.scrollTop = getViewportHeight()
        }
        if (needsAutofillAccessoryFix()) {
            // Add extra spacing for the bar above the keyboard
            this.autofillAccessoryConstraint = AUTOFILL_KEYBOARD_ACCESSORY_VIEW_HEIGHT
            this.updateConstraints()
        }
    }

    private closeKeyboardFix(): void {
        if (needsAutofillAccessoryFix()) {
            // Undo the extra spacing
            this.autofillAccessoryConstraint = 0
            this.updateConstraints()
        }
    }

    // Don't call this directly, go through this.chatInput.submit() instead
    private safeSubmit(): boolean {
        if (isNotLoggedIn(i18n.loggedInToSendAMessage)) {
            return false
        }
        return this.cleanAndSendMessage(this.chatInput.getText())
    }

    // eslint-disable-next-line complexity
    private cleanAndSendMessage(msg: string): boolean {
        if (msg.trim() === "") {
            return false
        }
        const outgoingMessage = parseOutgoingMessage(msg)
        switch (outgoingMessage.messageType) {
            case OutgoingMessageType.TipRequest:
                // `clearText` gets called in CustomInput.submit() but clear it early
                // here so the one in CustomInput doesn't mess up tip callout input focus
                this.chatInput.clearText()
                const tipMessage = outgoingMessage as ITipRequestMessage
                this.showTipForm(tipMessage.messageData)
                break
            case OutgoingMessageType.ToggleDebugMode:
                if (this.chatConnection === undefined) {
                    modalAlert("Unable to enable debug mode")
                    return false
                }
                this.chatConnection.toggleAppDebugging()
                break
            case OutgoingMessageType.Shortcode:
                if (this.chatConnection === undefined) {
                    modalAlert("Unable to send shortcode")
                    return false
                }

                const shortcode = outgoingMessage as IShortcodeMessage
                const errorMsg = this.shortcodeErrorMsg(shortcode, msg)

                if (errorMsg !== undefined) {
                    this.chatConnection.event.roomNotice.fire({
                        messages: [[stringPart(errorMsg)]],
                        showInPrivateMessage: true,
                    })

                    return false
                } else {
                    this.chatConnection.sendShortcode(shortcode)
                }
                break
            default:
                if (this.chatConnection === undefined) {
                    modalAlert("Unable to send message")
                    return false
                }
                if (this.chatConnection.inPrivateRoom()) {
                    const pm: ISendPrivateMessage = {
                        message: msg,
                        username: this.chatConnection.isBroadcasting
                            ? this.chatConnection.getPrivateShowUser()
                            : this.chatConnection.room(),
                        source: PrivateMessageSource.MobilePM,
                        roomName: this.chatConnection.room(),
                    }

                    sendPrivateMessage(pm).catch((error: IPMError) => {
                        modalAlert(error.errorMessage)
                    })
                } else {
                    this.chatConnection.sendRoomMessage(msg)
                }

                mobileFullscreenSendChat.fire(undefined)
                break
        }
        return true
    }

    private shortcodeErrorMsg(shortcode: IShortcodeMessage, raw: string): string | undefined {
        if (this.chatConnection?.inPrivateRoom() === true) {
            return i18n.shortcodeNotSupportedInPrivates
        } else if (shortcode.shortcodes.length === 0) {
            // Recognized as shortcode syntax, but not valid shortcode message
            return ShortcodeParser.errorBehindShortcode(raw)
        }
    }

    private createSendButton(): void {
        this.sendButton = document.createElement("button")
        this.sendButton.classList.add("sendButton")
        this.sendButton.innerText = i18n.sendTipButtonCAPS
        this.sendButton.dataset.testid = "send-tip-button"
        this.sendButton.style.outline = "none"
        this.sendButton.style.border = "none"
        this.sendButton.style.height = `${ICON_SIZE}px`
        this.sendButton.style.fontSize = `${FONT_SIZE}px`
        this.sendButton.style.fontFamily = "UbuntuBold, Arial, Helvetica, sans-serif"
        this.sendButton.style.color = "rgb(240,240,240)"
        this.sendButton.style.background = "rgba(21, 107, 149, 0.75)"
        this.sendButton.style.padding = "5px 8px"
        this.sendButton.style.borderRadius = "6px"
        this.sendButton.style.boxSizing = "border-box"
        this.sendButton.style.cursor = "pointer"
        this.sendButton.style.display = "inline-block"
        this.sendButton.style.textAlign = "center"

        this.sendButton.onmousedown = (ev: Event) => {
            ev.preventDefault()
            this.isClickingAButton = true
        }
        this.sendButton.onmouseup = (ev: Event) => {
            ev.preventDefault()
            this.isClickingAButton = false
        }
        this.sendButton.onclick = () => {
            const action = this.isChatInputFocused() ? "message" : "tip"
            if (isNotLoggedIn(`You must be logged in to send a ${action}. Click "OK" to login.`)) {
                this.closeActiveInput()
                return
            }
            if (this.isTipFormActive) {
                this.submitTipForm()
            } else if (this.isChatInputFocused()) {
                this.chatInput.submit()
            } else {
                this.showTipForm()
            }

            if (this.hlsWaitingForInteraction) {
                this.forceHlsOverlayFunc(false)
            }
        }
    }

    private createTipForm(): void {
        this.tipForm = document.createElement("form")
        this.tipMessageInput = document.createElement("input")
        this.tipMessageInput.dataset.testid = "tip-message-textarea"
        this.tipAmountContainer = document.createElement("div")
        const tipAmountLabel = document.createElement("label")
        const tipAmountInputContainer = document.createElement("div")
        this.tipAmountInput = createTipAmountInput("")
        const hiddenSubmit = document.createElement("button")

        this.tipForm.appendChild(this.tipMessageInput)
        this.tipForm.appendChild(this.tipAmountContainer)
        this.tipForm.appendChild(hiddenSubmit)
        this.tipAmountContainer.appendChild(tipAmountLabel)
        this.tipAmountContainer.appendChild(tipAmountInputContainer)
        tipAmountInputContainer.appendChild(this.tipAmountInput)

        this.styleForm(this.tipForm)
        this.styleInput(this.tipMessageInput)
        this.styleInput(this.tipAmountContainer)
        this.styleInput(this.tipAmountInput)

        if (isPortrait()) {
            this.setPlaceholder(this.tipMessageInput, TIP_MESSAGE_PORTRAIT_PLACEHOLDER)
        } else {
            this.setPlaceholder(this.tipMessageInput, TIP_MESSAGE_LANDSCAPE_PLACEHOLDER)
        }
        this.tipForm.classList.add("tipForm")
        this.tipForm.style.columnGap = "10px"
        this.tipForm.style.display = "none"
        this.tipMessageInput.classList.add("mobileFullscreenInput")
        this.tipMessageInput.style.minWidth = "0"
        this.tipAmountContainer.style.display = "flex"
        this.tipAmountContainer.style.padding = `${ICON_PADDING / 2}px ${CENTER_MARGIN / 2}px`

        tipAmountLabel.innerText = `${i18n.amountText}\u00a0`
        tipAmountLabel.style.opacity = "0.7"
        tipAmountLabel.style.lineHeight = `${ICON_SIZE - ICON_PADDING}px`

        tipAmountInputContainer.style.overflow = "hidden"
        tipAmountInputContainer.style.padding = "0"
        tipAmountInputContainer.style.flex = "1"

        this.tipAmountInput.style.background = "white"
        this.tipAmountInput.style.height = `${ICON_SIZE - ICON_PADDING}px`
        this.tipAmountInput.style.lineHeight = `${ICON_SIZE - ICON_PADDING}px`
        this.tipAmountInput.style.textAlign = "center"
        this.tipAmountInput.style.margin = "0"
        this.tipAmountInput.style.padding = "0 6px"
        this.tipAmountInput.style.opacity = "0.9"
        this.tipAmountInput.style.width = "100%"
        this.tipAmountInput.style.color = "black"

        addEventListenerPoly("input", this.tipAmountInput, () => {
            cleanTipAmountInput(this.tipAmountInput)

            if (this.hlsWaitingForInteraction) {
                this.forceHlsOverlayFunc(false)
            }
        })

        hiddenSubmit.style.visibility = "hidden"
        hiddenSubmit.style.width = "0"
        hiddenSubmit.style.height = "0"
        hiddenSubmit.style.position = "absolute"
        hiddenSubmit.type = "submit"


        this.tipMessageInput.onfocus = () => {
            this.inputScrollIntoViewFix()
            this.inputFix(true)
        }
        this.tipAmountInput.onfocus = () => {
            this.inputScrollIntoViewFix()
            this.inputFix(true)
            if (isiOS()) {
                if (needsSafariInputFix()) {
                    // add timeout because tip amount does not get selected while input is being repositioned
                    // when applying iOS15 input fix
                    window.setTimeout(() => {
                        this.tipAmountInput.setSelectionRange(0, this.tipAmountInput.value.length)
                    }, 0)
                } else {
                    this.tipAmountInput.setSelectionRange(0, this.tipAmountInput.value.length)
                }
            } else {
                this.tipAmountInput.select()
            }
        }
        this.tipAmountInput.onblur = () => {
            if (this.tipAmountInput.value === "") {
                this.tipAmountInput.value = "0"
            }
            this.closeKeyboardFix()
            this.inputFix(false)
        }
        this.tipAmountInput.onkeydown = (event: KeyboardEvent) => {
            if (event.metaKey || event.key === "Unidentified" || event.key === "Backspace" || event.key === "Enter") {
                return
            } else if ("0123456789".includes(event.key)) {
                return
            }

            event.preventDefault()
        }
        this.tipMessageInput.onblur = () => {
            this.closeKeyboardFix()
            this.inputFix(false)
        }
        this.tipForm.onsubmit = (event: Event) => {
            event.preventDefault()
            this.submitTipForm()
        }
    }

    private notifyPlayerChangeSize(): void {
        const xDelta = Math.abs(this.playerScroll - this.playerElement.scrollLeft)
        const yDelta = Math.abs(this.playerHeight - this.playerElement.offsetHeight)

        if (xDelta < 10 && yDelta < 10) {
            // consider as a missclick
            return
        }
        let ratio = sdRatio
        if (this.currentRoomContext !== undefined && this.currentRoomContext.dossier.isWidescreen === true) {
            ratio = wsRatio
        }

        if (xDelta * ratio > yDelta) {
            playerChangeSize.fire({ changeType: PlayerSizeChangeType.Reposition })
        } else {
            playerChangeSize.fire({ changeType: PlayerSizeChangeType.Resize })
        }
    }

    private submitTipForm(): void {
        this.closeActiveInput()
        if (!isValidTipInput(this.tipAmountInput.value)) {
            modalAlert(i18n.tipAmountInvalid)
            return
        }

        const amount = parseInt(this.tipAmountInput.value)

        if (amount > this.tokenBalance) {
            popUpTokenPurchaseModal(i18n.notEnoughTokensMessage, pageContext.current.PurchaseEventSources["TOKEN_SOURCE_LOW_TOKEN_BALANCE"], this.roomType)
            return
        }

        addPageAction("SendTipClicked", { "amount": amount, "source": "mobile_fullscreen" })
        if (amount > 100) {
            modalConfirm(
                i18n.tipConfirmationMessage(amount),
                () => this.mySendTip(amount),
            )
        } else {
            this.mySendTip(amount)
        }
    }

    private mySendTip(amount: number): void {
        this.sendButton.disabled = true
        sendTip({
            roomName: this.roomName,
            tipAmount: this.tipAmountInput.value,
            message: `${this.tipMessageInput.value}`,
            source: "mobile",
            tipRoomType: this.roomType,
            tipType: TipType.public,
            videoMode: "mobile",
        }).then((sendTipResponse) => {
            this.sendButton.disabled = false
            if (sendTipResponse.success) {
                addPageAction("SendTipSuccess", { "amount": amount, "source": "mobile_fullscreen" })
            } else {
                if (sendTipResponse.error !== undefined) {
                    modalAlert(sendTipResponse.error)
                } else {
                    error("unknown send tip error")
                }
            }
            this.tipMessageInput.value = ""
            this.hideTipForm()
            onTipSent.fire({ amount, success: sendTipResponse.success })
            if (sendTipResponse.tipsInPast24Hours !== undefined) {
                tipsInPast24HoursUpdate.fire({ tokens: sendTipResponse.tipsInPast24Hours, roomName: this.roomName })
            }
        }).catch((err) => {
            onTipSent.fire({ amount, success: false })
            this.sendButton.disabled = false
            modalAlert("Unable to send tip")
            error(`Error sending tip (${err})`)
        })
    }

    // Default styling for forms
    private styleForm(form: HTMLFormElement): void {
        form.style.height = "100%"
        form.style.boxSizing = "border-box"
    }

    // Default styling for an element to look like an input
    private styleInput(element: HTMLElement): void {
        element.style.textOverflow = "ellipsis"
        element.style.whiteSpace = "nowrap"
        element.style.overflow = "hidden"
        element.style.height = `${ICON_SIZE}px`
        element.style.lineHeight = `${ICON_SIZE - ICON_PADDING * 2}px`
        element.style.fontSize = `${FONT_SIZE}px`
        element.style.fontWeight = "bold"
        element.style.fontFamily = "UbuntuRegular, Arial, Helvetica, sans-serif"
        element.style.border = "none"
        element.style.outline = "none"
        if (element instanceof HTMLInputElement) {
            element.autocomplete = "off"
        }
        element.style.color = "rgb(15,15,15)"
        element.style.background = "rgba(255, 255, 255, 0.5)"
        element.style.padding = `${ICON_PADDING}px ${CENTER_MARGIN}px`
        element.style.borderRadius = "6px"
        element.style.boxSizing = "border-box"
        element.style.cursor = "pointer"
        element.style.display = "inline-block"
        element.style.textAlign = "left"
    }

    private createTokenBalance(): void {
        this.tokenBalanceWrapper = document.createElement("div")
        this.tokenBalanceLink = document.createElement("a")
        const tokenBalancePrefix = document.createElement("span")
        this.tokenBalanceAmount = document.createElement("span")

        this.tokenBalanceWrapper.appendChild(this.tokenBalanceLink)
        this.tokenBalanceLink.appendChild(tokenBalancePrefix)
        this.tokenBalanceLink.appendChild(this.tokenBalanceAmount)

        this.tokenBalanceWrapper.style.zIndex = "2"

        this.tokenBalanceLink.target = "_blank"
        this.tokenBalanceLink.style.display = "none"
        this.tokenBalanceLink.style.textDecoration = "none"
        this.tokenBalanceLink.style.fontWeight = "bold"
        this.tokenBalanceLink.style.borderRadius = "8px"
        this.tokenBalanceLink.style.padding = "6px 12px"
        this.tokenBalanceLink.style.background = "rgba(255, 255, 255, 0.6)"
        this.tokenBalanceLink.onclick = () => {
            const source = pageContext.current.PurchaseEventSources["TOKEN_SOURCE_MOBILE_VIDEO_CONTROLS"]
            popUpPurchasePage({ source, roomType: this.roomType })
        }

        tokenBalancePrefix.textContent = `${i18n.balanceText} `
        tokenBalancePrefix.style.color = "rgb(50, 50, 50)"

        this.tokenBalanceAmount.textContent = "0 Tokens"
        this.tokenBalanceAmount.style.color = "rgb(15, 15, 15)"
        this.tokenBalanceAmount.dataset.testid = "mobile-token-balance"
    }

    private createFullscreenImg(): void {
        this.fullscreenImgWrapper= document.createElement("div")
        this.fullscreenImgWrapper.dataset.testid = "mobile-fullscreen-button"
        this.fullscreenImgWrapper.style.position = "relative"
        this.fullscreenImgWrapper.style.display = "inline-block"

        this.fullscreenImg = document.createElement("img")
        this.fullscreenImg.src = `${STATIC_URL_MOBILE}expand-1.svg`
        this.fullscreenImg.style.height = `${ICON_SIZE}px`
        this.fullscreenImg.style.width = `${ICON_SIZE}px`
        this.fullscreenImg.style.boxSizing = "border-box"
        this.fullscreenImg.style.padding = "0"
        this.fullscreenImg.style.cursor = "pointer"
        this.fullscreenImg.style.border = "none"
        this.fullscreenImg.style.background = "none"
        this.fullscreenImg.style.zIndex = "1"
        this.fullscreenImg.style.borderRadius = `${ICON_SIZE / 2}px`
        this.fullscreenImg.style.marginLeft = `${CENTER_MARGIN}px`
        this.fullscreenImgWrapper.appendChild(this.fullscreenImg)

        const clickElement = this.fullscreenImgWrapper
        const expandedHitbox = document.createElement("div")
        applyStyles(expandedHitbox, {
            position: "absolute",
            top: `-${HITBOX_EXPANSION_PX}px`,
            bottom: `-${HITBOX_EXPANSION_PX}px`,
            left: "0", // fullscreenImgWrapper includes the fullscreenImg margin, so its left edge goes right up to the next controls component
            right: `-${HITBOX_EXPANSION_PX}px`,
        })
        this.fullscreenImgWrapper.appendChild(expandedHitbox)

        addEventListenerPoly("click", clickElement, () => {
            if (this.hlsWaitingForInteraction) {
                this.forceHlsOverlayFunc(false)
            }

            if (!this.isVisible) {
                this.showControls()
                return
            }
            this.showControls()
            this.setFullscreen(!this.playerIsFullscreen)
        })
    }

    private createResizeDragLabel(): void {
        this.resizeDragLabel = document.createElement("div")
        this.resizeDragLabel.innerText = i18n.dragToResize
        this.resizeDragLabel.style.backgroundColor = "rgba(0,0,0,0.3)"
        this.resizeDragLabel.style.color = "rgba(255,255,255,0.6)"
        this.resizeDragLabel.style.borderRadius = "6px"
        this.resizeDragLabel.style.padding = "5px"
        this.resizeDragLabel.style.textAlign = "center"
        this.resizeDragLabel.style.pointerEvents = "none"
        this.resizeDragLabel.style.textOverflow = "ellipsis"
        this.resizeDragLabel.style.whiteSpace = "nowrap"
        this.resizeDragLabel.style.overflow = "hidden"
        this.resizeDragLabel.style.flex = "1"
    }

    protected repositionChildren(): void {
        if (isPortrait() && !this.playerIsFullscreen && !this.playerUsingAirPlay && !this.playerUsingChromecast) {
            this.resizeDragLabel.style.display = ""
        } else {
            this.resizeDragLabel.style.display = "none"
        }
        this.updateIcons()
        this.updateCenterControls()
        this.updateSendButton()
        this.updateChatExitButton()
        // Since repositionChildren is called inside the blur/focus listeners of the chatInput, this method gets a stale state
        // of activeElement which results in the re-focus of chatInput because of setCaretToEnd()
        // Adding an async timeout here fixes the issue.
        window.setTimeout(() => {
            this.updateChatInput()
        }, 0)
        this.updateTokenBalancePosition()
        this.adjustDimensions()
    }

    private adjustDimensions(): void {
        const playerRect = this.playerElement.getBoundingClientRect()
        this.element.style.width = "inherit"

        if (this.isFullVideo()) {
            if (isFullscreen()) {
                this.element.style.height = "calc(var(--vh, 1vh) * 100)"
                if (isAndroid() && window.visualViewport) {
                    // The Android bottom navigation bar on newer devices causes issues in portrait mode where it covers the chat
                    // input and in landscape mode where the page _renders_ as if the bar weren't present but the actual _DOM_ is
                    // laid out like it is present, so where you think you're clicking isn't where you're actually clicking.
                    if (isPortrait()) {
                        // Check if soft keyboard is up. Window is resized twice in this case, first where only the soft keyboard
                        // opens up and second when the bottom navbar is opened below the keyboard. However till the time we
                        // reposition elements, the viewport considers both keyboard and navbar heights.
                        // This fix explicitly sets the smallest viewport height with keyboard & navbar present on screen.
                        if (isVirtualKeyboardLikelyShowing()) {
                            this.element.style.height = `${this.smallestPortraitHeight ?? window.visualViewport.height}px`
                        }
                    } else {
                        if (
                            isVirtualKeyboardLikelyShowing()
                            && this.smallestLandscapeWidth !== undefined
                        ) {
                            // When soft keyboard is up, restrict width to the known max allowed
                            this.element.style.width = `${this.smallestLandscapeWidth}px`
                        } else {
                            // Otherwise we can trust visualViewport.width
                            this.element.style.width = `${window.visualViewport.width}px`
                        }
                    }
                }
            } else {
                this.element.style.height = "100%"
            }

            this.element.style.top = "0"
        } else {
            this.element.style.height = `${playerRect.height}px`

            const dismissibleMessagesHeight = this.mobileDismissibleMessages.element.offsetHeight
            this.element.style.top = `${this.headerHeight + dismissibleMessagesHeight}px`
        }
    }

    private updateIcons(): void {
        if (this.shouldHideIcons()) {
            this.fullscreenImgWrapper.style.display = "none"
            this.volumeImgWrapper.style.display = "none"
            if (this.optionsButton !== undefined) {
                this.optionsButton.style.display = "none"
            }
        } else {
            this.fullscreenImgWrapper.style.display = "inline"
            this.volumeImgWrapper.style.display = "inline"
            if (this.optionsButton !== undefined) {
                this.optionsButton.style.display = this.LLHLSAllowed ? "inline" : "none"
            }
        }

        if (!shouldUseFullscreenAPI() && !isPortrait()) {
            this.fullscreenImgWrapper.style.display = "none"
        }
    }

    private updateCenterControls(): void {
        if (this.isFullVideo()) {
            this.showCenterControls()
        } else {
            this.hideCenterControls()
        }
    }

    private showCenterControls(): void {
        if (this.isVisible) {
            this.centerControlsDiv.style.opacity = "1"
            this.centerControlsDiv.style.pointerEvents = "auto"
            this.centerControlsDiv.style.display = "grid"
        }
    }

    private hideCenterControls(): void {
        this.centerControlsDiv.style.opacity = "0"
        this.centerControlsDiv.style.pointerEvents = "none"
        if (!this.isFullVideo()) {
            // Immediately hide - prevent any css transition
            this.centerControlsDiv.style.display = "none"
        }
    }

    private updateChatExitButton(): void {
        if (this.isChatInputFocused()) {
            this.chatExitImg.style.display = "inline-block"
            this.chatExitButton.style.display = "block"
        } else {
            this.chatExitImg.style.display = "none"
            this.chatExitButton.style.display = "none"
        }
    }

    private updateChatInput(): void {
        if (this.isChatInputFocused()) {
            this.chatInput.element.style.textOverflow = ""
            this.chatInput.element.scrollLeft = this.chatInput.element.scrollWidth
            this.chatInput.setCaretToEnd()
        } else {
            this.chatInput.element.style.textOverflow = "ellipsis"
            this.chatInput.element.scrollLeft = 0
        }
    }

    public isAnyInputFocused(): boolean {
        return this.isChatInputFocused() || this.isTipInputFocused()
    }

    private closeActiveInput(): void {
        if (this.chatInput.element === document.activeElement) {
            this.chatInput.blur()
        } else if (this.tipAmountInput === document.activeElement) {
            this.tipAmountInput.blur()
        } else if (this.tipMessageInput === document.activeElement) {
            this.tipMessageInput.blur()
        }
    }

    private isChatInputFocused(): boolean {
        return this.chatInput.element === document.activeElement
    }

    private isTipInputFocused(): boolean {
        return (
            this.tipAmountInput === document.activeElement
            || this.tipMessageInput === document.activeElement
        )
    }

    private shouldHideIcons(): boolean {
        return (
            this.isChatInputFocused() ||
            this.isTipFormActive
        )
    }

    public showTipForm(tip?: ITipRequest): void {
        if (!this.isVisible) {
            // amount input focus doesn't work after clicking tip sc
            // if controls are in transition so remove it
            styleTransition(this.centerControlsDiv, "none")
            this.showControls()
        }
        this.isTipFormActive = true
        this.tokenBalanceLink.style.display = "block"
        this.chatForm.style.display = "none"
        this.tipForm.style.display = "grid"
        if (tip !== undefined) {
            if (tip.amount !== undefined) {
                this.tipAmountInput.value = tip.amount.toString()
                cleanTipAmountInput(this.tipAmountInput)
            }
            if (tip.message !== undefined) {
                this.tipMessageInput.value = tip.message
            }
        }

        this.tipAmountInput.focus()
        this.repositionChildren() // must be after tip amount focus
    }

    private hideTipForm(): void {
        this.isTipFormActive = false
        this.tokenBalanceLink.style.display = "none"
        this.tipForm.style.display = "none"
        this.showChatForm()
        this.repositionChildren()
    }

    private showChatForm(): void {
        this.chatForm.style.display = "flex"
    }

    private setFullscreen(isFullscreen: boolean, disableFullscreenAPI = false): void {
        if (this.playerIsFullscreen === isFullscreen) {
            return
        }
        if (LLHLSSupported() && mobileLLHLS()) {
            this.closeAdvancedOptions()
        }
        this.playerIsFullscreen = isFullscreen
        this.toggleFullscreen.fire(isFullscreen)
        if (isFullscreen) {
            this.fullscreenImg.src = `${STATIC_URL_MOBILE}contract-1.svg`
        } else {
            this.fullscreenImg.src = `${STATIC_URL_MOBILE}expand-1.svg`
            if (this.isTipFormActive) {
                this.hideTipForm()
            }
        }
        hideRulesModal()

        if (this.mobilePureChat !== undefined) {
            if (isPortrait()) {
                this.mobilePureChat.setVisible(isFullscreen)
            } else {
                this.mobilePureChat.setVisible(true)
            }
        }
        this.centerPlayerEvent.fire(undefined)
        this.updateCenterControls()
        this.repositionChildren()
        this.saveSettings()

        if (!disableFullscreenAPI) {
            this.maybeUseFullscreenAPI(isFullscreen)
        }
    }

    private maybeUseFullscreenAPI(isFullscreen: boolean): void {
        // Requesting fullscreen tends to freeze the device for a split second,
        // so do it last after everything else has updated
        if (shouldUseFullscreenAPI()) {
            if (isFullscreen) {
                this.requestFullscreenEvent.fire(undefined)
            } else {
                exitFullscreen()
            }
        }
    }

    private updateSendButton(): void {
        if (this.isChatInputFocused()) {
            this.sendButton.innerText = i18n.sendCAPS
            this.sendButton.dataset.testid = "send-button"
        } else {
            this.sendButton.innerText = i18n.sendTipButtonCAPS
            this.sendButton.dataset.testid = "send-tip-button"
        }
    }

    private updateVolumeImage(): void {
        if (this.state.isMuted || this.state.volume === 0) {
            this.volumeImg.src = `${STATIC_URL_MOBILE}muted.svg`
        } else {
            this.volumeImg.src = `${STATIC_URL_MOBILE}unmuted.svg`
        }
        NRSetPlayerVolume(100, this.state.isMuted)
    }

    private updatePlayerVolume(): void {
        if (!this.userUnmuted && !this.state.isMuted) {
            this.userUnmuted = true
            addPageAction("UserUnmuted", { "chatMode": "mobile" })
        }
        this.setPlayerComponentVolumeMutedEvents.fire({ ...this.state })
    }

    private saveSettings(): void {
        if (isLocalStorageSupported()) {
            const exportedState = {
                "isMuted": this.state.isMuted,
                "isFullscreen": this.playerIsFullscreen,
                "volume": this.state.volume,
            }
            window.localStorage.setItem(storageKey, JSON.stringify(exportedState))
        }
    }

    private update(): void {
        this.updatePlayerVolume()
    }

    private mute(): void {
        this.updateIsMuted(true)
        this.update()
    }

    private unmute(): void {
        this.updateIsMuted(false)
        this.update()
    }

    private updateTokenBalance(balance: number): void {
        this.tokenBalance = balance
        this.tokenBalanceAmount.innerText = `${balance} Tokens`
    }

    private updateTokenBalancePosition(): void {
        const isCastButtonInViewport = isElementInViewport(this.airPlayImg) || isElementInViewport(this.chromecastImg)
        const castButtonWidth = this.airPlayImg.offsetWidth || this.chromecastImg.offsetWidth
        const marginForCast = castButtonWidth && isCastButtonInViewport ? castButtonWidth : 0

        this.tokenBalanceWrapper.style.left = `${15 + marginForCast}px`

        if (this.isTipInputFocused()) {
            this.tokenBalanceWrapper.style.position = "absolute"
            this.updateTokenBalanceTopProperty()
        } else {
            this.tokenBalanceWrapper.style.position = "static"
        }
    }

    private updateTokenBalanceTopProperty(): void {
        const idealTopValue = 15
        const centerControlsDivHeight = this.centerControlsDiv.offsetHeight
        const tokenBalanceLinkHeight = this.tokenBalanceLink.offsetHeight
        const maxTopValue = getViewportHeight() - centerControlsDivHeight - tokenBalanceLinkHeight - idealTopValue

        // Have a limit for the top value so it doesn't overlap with the input
        this.tokenBalanceWrapper.style.top = `${Math.min(viewportOffsetTop() + idealTopValue, maxTopValue)}px`
    }

    private setPlaceholder(input: HTMLInputElement, placeholder: string): void {
        input.setAttribute("placeholder", placeholder) // eslint-disable-line @multimediallc/no-set-attribute
    }

    private isFullVideo(): boolean {
        return this.playerIsFullscreen || !isPortrait()
    }

    public updateCastingStyling(castingType: string): void {
        if (castingType === "AirPlay") {
            this.airPlayImg.src = `${STATIC_URL_MOBILE}airplay-active.svg`
        } else if (castingType === "Chromecast") {
            this.chromecastImg.src = `${STATIC_URL_MOBILE}chromecast-active.svg`
        } else {
            this.airPlayImg.src = `${STATIC_URL_MOBILE}airplay.svg`
            this.chromecastImg.src = `${STATIC_URL_MOBILE}chromecast.svg`
        }
        this.showControls()
    }

    private createCastingImage(src: string): HTMLImageElement {
        const target = document.createElement("img")
        target.style.display = "none"
        target.src = `${STATIC_URL_MOBILE + src}`
        target.style.height = `${ICON_SIZE}px`
        target.style.width = `${ICON_SIZE}px`
        target.style.cursor = "pointer"
        target.style.marginRight = `${ICON_MARGIN}px`
        target.style.zIndex = "1"
        addEventListenerPoly("click", target, () => {this.showControls()})

        return target
    }

    public showAirPlay(): void {
        this.airPlayImg.style.display = ""
        this.airPlayIconConstraint = ICON_SIZE + ICON_MARGIN
        this.updateConstraints()
    }

    public hideAirPlay(): void {
        this.airPlayImg.style.display = "none"
        this.airPlayIconConstraint = 0
        this.updateConstraints()
    }

    public showChromecast(): void {
        this.chromecastImg.style.display = ""
        this.airPlayIconConstraint = ICON_SIZE + ICON_MARGIN
        this.updateConstraints()
    }

    public hideChromecast(): void {
        this.chromecastImg.style.display = "none"
        this.airPlayIconConstraint = 0
        this.updateConstraints()
    }

    private updateConstraints(): void {
        this.layoutConstraints.setConstraints({
            top: this.airPlayIconConstraint,
            bottom: this.autofillAccessoryConstraint,
            left: this.airPlayIconConstraint,
            right: 0,
            transitionTime: 0,
        })
    }

    public showElement(): void {
        super.showElement("flex")
    }

    public show(): void {
        this.showElement()
        this.showControls()
    }

    public hide(): void {
        this.hideElement()
    }

    public showControls(): void {
        if (!this.isVisible) {
            if (this.isFullVideo() || this.playerUsingAirPlay || this.playerUsingChromecast) {
                this.resizeDragLabel.style.display = "none"
            } else {
                this.resizeDragLabel.style.display = ""
            }
            styleTransition(this.airPlayImg, "opacity none")
            styleTransition(this.chromecastImg, "opacity none")
            styleTransition(this.volumeImgWrapper, "opacity none")
            styleTransition(this.fullscreenImgWrapper, "opacity none")
            styleTransition(this.resizeDragLabel, "opacity none")
            this.airPlayImg.style.opacity = "1"
            this.chromecastImg.style.opacity = "1"
            this.volumeImgWrapper.style.opacity = "1"
            this.fullscreenImgWrapper.style.opacity = "1"
            this.resizeDragLabel.style.opacity = "1"
            if (this.optionsButton !== undefined) {
                styleTransition(this.optionsButton, "opacity none")
                this.optionsButton.style.opacity = this.LLHLSAllowed ? "1" : "0"
            }
            this.isVisible = true
        }
        this.updateCenterControls()
        clearTimeout(this.opacityTimer)
        this.opacityTimer = window.setTimeout(() => {
            this.hideControls()
        }, 3500)
    }

    public hideControls(): void {
        if (this.isVisible && !this.isChatInputFocused() && !this.isTipFormActive && !this.playerUsingAirPlay && !this.playerUsingChromecast) {
            styleTransition(this.airPlayImg, `opacity ${FADE_DURATION}ms`)
            styleTransition(this.chromecastImg, `opacity ${FADE_DURATION}ms`)
            styleTransition(this.volumeImgWrapper, `opacity ${FADE_DURATION}ms`)
            styleTransition(this.fullscreenImgWrapper, `opacity ${FADE_DURATION}ms`)
            styleTransition(this.resizeDragLabel, `opacity ${FADE_DURATION}ms`)
            this.airPlayImg.style.opacity = "0"
            this.chromecastImg.style.opacity = "0"
            this.volumeImgWrapper.style.opacity = "0"
            this.fullscreenImgWrapper.style.opacity = "0"
            this.resizeDragLabel.style.opacity = "0"
            if (this.optionsButton !== undefined) {
                styleTransition(this.optionsButton, `opacity ${FADE_DURATION}ms`)
                this.optionsButton.style.opacity = "0"
                if (this.advancedOptionsOpen) {
                    this.closeAdvancedOptions()
                }
            }
            this.hideCenterControls()
            this.isVisible = false
        }
    }

    public updateVolume(volume: number): void {
        this.state.volume = volume
        this.updateVolumeImage()
    }

    public updateIsMuted(muted: boolean): void {
        this.state.isMuted = muted
        this.updateVolumeImage()
    }

    public updateAndSaveVolume(volume: number): void {
        this.updateVolume(volume)
        this.saveSettings()
    }

    public updateAndSaveIsMuted(muted: boolean): void {
        this.updateIsMuted(muted)
        this.saveSettings()
    }

    public getControlBarHeight(): number {
        return 0
    }

    public setPureChat(mobilePureChat: MobilePureChat): void {
        if (mobilePureChat === this.mobilePureChat) {
            // Skip if no change. Prevents a bug where chat scroll is set to the top after refreshing a room in mobile fullscreen
            return
        }

        if (this.mobilePureChat !== undefined) {
            this.layoutHandler.removeListener(this.mobilePureChat.layoutConstraints)
            this.removeChild(this.mobilePureChat)
        }

        this.mobilePureChat = mobilePureChat
        this.addChild(this.mobilePureChat)
        this.mobilePureChat.setVisible(this.playerIsFullscreen || !isPortrait())
        this.layoutHandler.addListener(this.mobilePureChat.layoutConstraints)
    }

    public forceHLS(): void {
        if (this.currentRoomContext === undefined) {
            error("unexpected switch to hls", {}, SubSystemType.Video)
            return
        }
        addPageAction("ForceHLS")
        this.forceHlsPlayerEvent.fire({ roomContext: this.currentRoomContext })
        this.unmute()
        switchedToHLS.fire(undefined)
    }

    private showHideHlsPlayButton(status: RoomStatus): void {
        if (this.playButtonContainer !== undefined && this.forceHlsOverlay !== undefined) {
            if (roomStatusIsWatching(status)) {
                this.playButtonContainer.style.display = "flex"
                this.forceHlsOverlay.style.display = ""
            } else {
                this.playButtonContainer.style.display = "none"
                this.forceHlsOverlay.style.display = "none"
            }
        }
    }

    private forceHlsOverlayFunc(unmute = false): void {
        if (this.hlsWaitingForInteraction && this.currentRoomContext !== undefined && !this.forceHlsTriggered) {
            if (this.forceHlsOverlay !== undefined && this.forceHlsOverlay.parentElement !== null) {
                this.forceHlsOverlay.parentElement.removeChild(this.forceHlsOverlay)
                this.forceHlsOverlay = undefined
            }

            if (this.playButtonContainer !== undefined && this.playButtonContainer.parentElement !== null) {
                this.playButtonContainer.parentElement.removeChild(this.playButtonContainer)
                this.playButtonContainer = undefined
            }
            this.forceHlsTriggered = true
            this.hlsWaitingForInteraction = false
            addPageAction("ForceHLS")
            this.forceHlsPlayerEvent.fire({
                roomContext: this.currentRoomContext,
                unmute,
            })
            // Return zIndex back to normal
            this.element.style.zIndex = `${RoomStatusNotifier.Z_INDEX - 1}`
        }
    }

    private createHlsPlayOverlay(): void {
        this.forceHlsOverlay = document.createElement("div")
        // Have video controls be clickable (unmute, ect)
        this.element.style.zIndex = "100"
        this.forceHlsOverlay.style.width = "100%"
        this.forceHlsOverlay.style.height = "100%"
        this.forceHlsOverlay.style.position = "absolute"
        this.forceHlsOverlay.style.top = "0"
        this.forceHlsOverlay.style.left = "0"
        this.forceHlsOverlay.style.zIndex = "99"
        addEventListenerPoly("click", this.forceHlsOverlay, () => {
            this.forceHlsOverlayFunc()
        })
        addEventListenerPoly("click", this.element, () => {
            this.forceHlsOverlayFunc()
        })
        this.playerComponentReadjustForceHlsOverlayOrderEvent.fire(this.forceHlsOverlay)
    }

    private createForceHlsPlayButton(): void {
        if (this.playerIsJPEG) {
            this.hlsWaitingForInteraction = true
            this.playButtonContainer = document.createElement("div")
            const playButton = document.createElement("img")
            playButton.src = `${STATIC_URL}play-inactive.svg`
            playButton.style.width = "30px"
            playButton.style.height = "30px"
            playButton.style.cursor = "pointer"

            this.playButtonContainer.style.cursor = "pointer"
            this.playButtonContainer.style.width = "70px"
            this.playButtonContainer.style.height = "70px"
            this.playButtonContainer.style.borderRadius = "50%"
            this.playButtonContainer.style.backgroundColor = "rgba(0,0,0,0.25)"
            this.playButtonContainer.style.display = "flex"
            this.playButtonContainer.style.alignItems = "center"
            this.playButtonContainer.style.justifyContent = "center"
            this.playButtonContainer.appendChild(playButton)

            this.element.insertBefore(this.playButtonContainer, this.bottomBar)

            this.showJpegPlayerComponentImage.fire(undefined)
            this.createHlsPlayOverlay()
            this.repositionChildren()
        }
    }

    public notifyPlayerIsJPEG(isJPEG: boolean): void {
        this.playerIsJPEG = isJPEG
    }

    public notifyPlayerIsHlsPlaceholder(isHlsPlaceholder: boolean): void {
        this.playerIsHlsPlaceholder = isHlsPlaceholder
    }

    public getPlayerIsHlsPlaceholder(): boolean {
        return this.playerIsHlsPlaceholder
    }

    public notifyUsingAirPlayChange(usingAirPlay: boolean): void {
        this.playerUsingAirPlay = usingAirPlay
    }

    public notifyUsingChromecastChange(usingChromecast: boolean): void {
        this.playerUsingChromecast = usingChromecast
    }

    private canResizePlayerInFullscreen(): boolean {
        return !this.isAnyInputFocused() && isPortrait() && this.isFullVideo()
    }

    private bindTouchControlsEvent(): void {
        this.touchControls.touchMoveEvent.listen(touchInfo => {
            switch (touchInfo.state) {
                case TouchState.Start:
                    this.playerScroll = this.playerElement.scrollLeft
                    this.playerHeight = this.playerElement.offsetHeight
                    break
                case TouchState.InProgress:
                    this.showControls()
                    break
                case TouchState.Tap:
                    const controlsVisible = this.isVisible
                    if (!controlsVisible) {
                        // Don't allow buttons to be clicked when they cannot be seen
                        touchInfo.event.preventDefault()
                    }

                    if (controlsVisible) {
                        if (this.isTipFormActive) {
                            this.hideTipForm()
                        } else {
                            this.hideControls()
                        }
                    } else {
                        this.showControls()
                    }

                    mobileVideoControlsTap.fire(undefined)
                    this.closeActiveInput()
                    this.requestHlsPlayerPlayIfPaused.fire(undefined)

                    break
                case TouchState.End:
                    this.notifyPlayerChangeSize()
                    break
            }
        })
    }

    public executeInitialFullscreenState(): void {
        if (this.initialFullscreen) {
            this.setFullscreen(true, true)
        }
    }

    public getMobileRoot(): MobileRoot {
        return this.mobileRoot
    }

    private closeAdvancedOptions(): void {
        this.advancedOptionsOpen = false
        this.mobileRoot.toggleAdvancedOptions(false)
        if(this.optionsDivWrapper !== undefined) {
            this.optionImg.src = `${STATIC_URL_MOBILE}advanced-settings.svg`
            this.optionsDivWrapper.style.display = "none"
            this.optionsDivWrapper.style.opacity = "0"
        }
        if (this.advancedOptions !== undefined) {
            this.advancedOptions.HLSToggleTooltip.style.display = "none"
            this.advancedOptions.HLSToggleTooltip.style.opacity = "0"
        }
    }
}

const needsAutofillAccessoryFix = (): boolean => {
    // Chrome.v75+ on iPad currently overlays an accessory bar on the screen whenever
    // the keyboard is open.  It is likely to be fixed in the future as many people are complaining about it.
    // See: https://apple.stackexchange.com/questions/305089/how-to-disable-top-bar-of-the-ios-keyboard
    // See: https://discussions.apple.com/thread/250116693
    // See: https://support.google.com/chrome/thread/14298791?hl=en
    // See: https://support.google.com/chrome/thread/21001996?hl=en
    if (isiPad() && /CriOS\//.test(navigator.userAgent)) {
        const chromeVersionStr = navigator.userAgent.split("CriOS/")[1]
        const majorVersion = chromeVersionStr.split(".")[0]
        // TODO: update Chrome versions affected when iOS Chrome fixes the autofill accessory
        if (parseInt(majorVersion) >= 75) {
            return true
        }
    }
    return false
}
