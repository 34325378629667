import { addColorClass, colorClass } from "../../../cb/colorClasses"
import { dismissDismissibleNotice } from "../../../cb/dismissibleNotice"
import { DjangoFormParser } from "../../../cb/ui/djangoForm"
import { addEventListenerPoly } from "../../addEventListenerPolyfill"
import { modalConfirm } from "../../alerts"
import { getCb, normalizeResource } from "../../api"
import { parseBioApiResponse } from "../../bioContent"
import { BioContentTab } from "../../bioContentTab"
import { underlineOnHover } from "../../DOMutils"
import { JoinOverlayAnchor } from "../../theatermodelib/joinOverlay"
import { i18n } from "../../translation"
import { parseQueryString } from "../../urlUtil"
import { largePopupLinkFeatures, safeWindowOpen } from "../../windowUtils"
import { genderSettingsSave } from "../userActionEvents"
import { EditBroadcastBioForm } from "./editBroadcastBioForm"
import type { IDjangoFormData } from "../../../cb/ui/djangoForm"
import type { IBioParsed, IBioSocialMedia } from "../../bioContent"

export const refreshBroadcastBioTabSocialMedia = "RefreshBroadcastBioTabSocialMedia"

export enum BIO_TAB_STATE {
    VIEW_BIO,
    EDIT_BIO,
}

export class BroadcastBioTab extends BioContentTab<IDjangoFormData> {
    private state = BIO_TAB_STATE.VIEW_BIO
    private editQueryChecked = false
    public editBroadcastForm: EditBroadcastBioForm | undefined

    constructor(roomName: string, private isAgeVerified: boolean, private canUpload: boolean) {
        super()

        window[refreshBroadcastBioTabSocialMedia] = () => {
            this.load(false)
        }
        this.element.id = "tabs_content_container" // for Lovense

        // NOTE: This won't work on iOS Safari. There is no easy solution to stop navigation
        // and show a confirmation modal using either beforeunload/pagehide/popstate/blur/visibilitychange events.
        addEventListenerPoly("beforeunload", window, (ev: Event) => {
            if (this.editBroadcastForm?.formHasChanged() === true) {
                ev.preventDefault()
                return i18n.warnOnClosingDirty
            }
            return
        })
    }

    public checkBeforeSwitch(switchCallback: () => void): void {
        if (this.editBroadcastForm?.formHasChanged() === true) {
            modalConfirm(i18n.warnOnClosingDirty, () => {
                this.setState(BIO_TAB_STATE.VIEW_BIO)
                switchCallback()
            })
        } else {
            this.setState(BIO_TAB_STATE.VIEW_BIO)
            switchCallback()
        }
    }

    protected load(showLoading = true, callback?: () => void): void {
        super.load(showLoading, () => {
            if (callback !== undefined) {
                callback()
            }

            if (!this.editQueryChecked) {
                this.editQueryChecked = true
                const editQuery = parseQueryString(window.location.search)["edit"]
                if (editQuery === "bio") {
                    this.setState(BIO_TAB_STATE.EDIT_BIO)
                }
            }
        })
    }

    public show(): void {
        this.element.style.display = ""
        this.element.style.margin = "0 0 14px"
        if (this.showingError) {
            // Don't reload the contents of this tab on show, unless an error is showing
            this.load()
        }
        this.repositionChildren()
    }

    public hide(): void {
        this.element.style.display = "none"
    }

    protected parseData(rawData: string): IBioParsed | IDjangoFormData {
        if (this.state === BIO_TAB_STATE.VIEW_BIO) {
            return parseBioApiResponse(rawData, this.room)
        } else {
            return DjangoFormParser.parseData(rawData)
        }
    }

    protected createContent(data: IBioParsed | IDjangoFormData): void {
        if (this.state === BIO_TAB_STATE.VIEW_BIO) {
            this.createBioContents((data as IBioParsed))
        } else if (this.state === BIO_TAB_STATE.EDIT_BIO) {
            this.createEditBio((data as IDjangoFormData))
        }
    }

    protected createEditBio(data: IDjangoFormData): void {
        this.createPreHeaderContent()
        this.editBroadcastForm = new EditBroadcastBioForm(data, "api/ts/accounts/editbio/", {
            onSubmitSuccess: (formData: FormData) => {
                genderSettingsSave.fire({
                    gender: String(formData.get("gender")),
                    subgender: String(formData.get("subgender")),
                })
                if (formData.get("spoken_languages_strict") !== null) {
                    const dismissSpokenLanguagesLink =
                        document.querySelector<HTMLAnchorElement>("a#edit-spoken-language + .dismiss_notice")
                    if (dismissSpokenLanguagesLink !== null) {
                        dismissDismissibleNotice(dismissSpokenLanguagesLink)
                    }
                }
                this.setState(BIO_TAB_STATE.VIEW_BIO)
            },
        }, this.room, this.canUpload)
        this.element.appendChild(this.editBroadcastForm.element)
        this.editBroadcastForm.focusFirstField()
    }

    private createSeparator(): HTMLSpanElement {
        const separator = document.createElement("span")
        addColorClass(separator, "separator")
        separator.style.fontSize = "14px"
        separator.innerText = " | "
        return separator
    }

    protected generateSocialMediaSection(socialMedias: IBioSocialMedia[], username: string): HTMLDivElement {
        const section = super.generateSocialMediaSection(socialMedias, username)
        if (!this.isAgeVerified) {
            section.querySelectorAll<HTMLAnchorElement>(".socialMediaUpload").forEach(upload => {
                upload.style.opacity = "0.6"
            })
        }
        return section
    }

    protected generateSocialMediaLinks(username: string, needsShowMore: boolean): HTMLDivElement {
        const linksContainer = super.generateSocialMediaLinks(username, needsShowMore)
        if (needsShowMore) {
            linksContainer.appendChild(this.createSeparator())
        }
        if (this.isAgeVerified) {
            const addMore = document.createElement("a")
            addColorClass(addMore, colorClass.hrefColor)
            addMore.href = normalizeResource("/socials/social_media/create/")
            addMore.onclick = (event: Event) => {
                event.preventDefault()
                safeWindowOpen(addMore.href, "_blank", largePopupLinkFeatures)
            }
            addMore.innerText = i18n.addNewSocialMedia
            addMore.style.fontSize = "14px"
            addMore.dataset.testid="add-more"
            linksContainer.appendChild(addMore)
        } else {
            const mustBeVerified = document.createElement("span")
            mustBeVerified.style.fontSize = "14px"
            mustBeVerified.innerHTML = i18n.mustBeAgeVerifiedToAddSocialMedia() // eslint-disable-line @multimediallc/no-inner-html
            linksContainer.appendChild(mustBeVerified)
        }
        return linksContainer
    }

    protected generatePicsLinks(username: string, needsShowMore: boolean, type = "photo"): HTMLDivElement {
        // needed to force "display:block" without width + click handler side effects
        const picsLinksContainer = document.createElement("div")
        if (needsShowMore) {
            const showMoreLink = new JoinOverlayAnchor().anchor
            const showMoreHref = `/photo_videos/photoset/list_popup/${username}/`
            addColorClass(showMoreLink, colorClass.hrefColor)
            showMoreLink.href = normalizeResource(showMoreHref)
            showMoreLink.innerText = i18n.showMoreText
            showMoreLink.style.fontSize = "14px"
            showMoreLink.style.cursor = "pointer"
            underlineOnHover(showMoreLink)
            showMoreLink.onclick = (event: Event) => {
                event.preventDefault()
                safeWindowOpen(showMoreLink.href, "_blank", largePopupLinkFeatures)
            }
            picsLinksContainer.appendChild(showMoreLink)
            picsLinksContainer.appendChild(this.createSeparator())
        }

        const createLink = (text: string, href: string) => {
            const link = document.createElement("a")
            addColorClass(link, colorClass.hrefColor)
            link.href = normalizeResource(href)
            link.onclick = (event: Event) => {
                event.preventDefault()
                safeWindowOpen(link.href, "_blank", largePopupLinkFeatures)
            }
            link.innerText = text
            link.style.fontSize = "14px"
            link.dataset.testid = `upload-new-${type}`
            return link
        }
        if (this.canUpload) {
            if (type === "photo") {
                picsLinksContainer.appendChild(createLink(i18n.uploadNewPics, "/photo_videos/photoset/create/"))
            } else {
                picsLinksContainer.appendChild(createLink(i18n.uploadNewVideos, "/photo_videos/photoset/create/video/"))
            }
        } else {
            const mustBeVerifiedorTokens = document.createElement("span")
            mustBeVerifiedorTokens.style.fontSize = "14px"
            mustBeVerifiedorTokens.innerHTML = i18n.mustBeAgeVerifiedToUploadContent() // eslint-disable-line @multimediallc/no-inner-html
            picsLinksContainer.appendChild(mustBeVerifiedorTokens)
        }
        return picsLinksContainer
    }

    protected handlePictureClick(ev: Event, id: number, href: string): void {
        ev.preventDefault()
        safeWindowOpen(href, "_blank", largePopupLinkFeatures)
    }

    protected createFollowersSection(followerCount: number): HTMLDivElement {
        const link = `<a href="${normalizeResource("/accounts/followers/")}" target="_blank">${!isNaN(followerCount) ? followerCount.toString() : "0"}</a>`
        return this.createSection(
            i18n.followersText,
            link,
            true,
        )
    }

    protected shouldShowSocialMediaSection(socialMediaLength: number): boolean {
        return true
    }

    protected shouldShowPicsSection(picsLength: number): boolean {
        return true
    }

    protected createBioContents(data: IBioParsed): void {
        this.createPreHeaderContent()
        super.createBioContents(data, this.canUpload)
    }

    protected createPreHeaderContent(): void {
        if (this.state === BIO_TAB_STATE.VIEW_BIO) {
            const editBioLink = document.createElement("a")
            this.element.appendChild(editBioLink)
            editBioLink.style.cursor = "pointer"
            editBioLink.style.display = "inline-block"
            editBioLink.style.margin = "14px 14px 0"
            editBioLink.innerText = i18n.editYourBio
            editBioLink.className = "editbio" // for Lovense
            editBioLink.href = "#"
            editBioLink.onclick = (event: Event) => {
                this.setState(BIO_TAB_STATE.EDIT_BIO)
            }
        } else if (this.state === BIO_TAB_STATE.EDIT_BIO) {
            const cancelLink = document.createElement("a")
            this.element.appendChild(cancelLink)
            cancelLink.style.cursor = "pointer"
            cancelLink.style.display = "inline-block"
            cancelLink.style.margin = "14px 14px 0"
            cancelLink.innerText = i18n.cancelText
            cancelLink.href = "#"
            cancelLink.onclick = (event: Event) => {
                this.setState(BIO_TAB_STATE.VIEW_BIO)
            }
        }
    }

    public setState(state: BIO_TAB_STATE, loadCallback?: () => void): void {
        if (state === this.state) { // Skip load call if unneeded (not changing state).
            return loadCallback?.call(undefined)
        }
        if (state === BIO_TAB_STATE.VIEW_BIO) {
            this.state = BIO_TAB_STATE.VIEW_BIO
            this.editBroadcastForm = undefined // reset the form to avoid stale state of any previously loaded forms
            this.setResourceUrl(`api/biocontext/${this.room}/`)
            this.load(false, loadCallback)
        } else if (state === BIO_TAB_STATE.EDIT_BIO) {
            this.state = BIO_TAB_STATE.EDIT_BIO
            this.setResourceUrl("api/ts/accounts/editbio/")
            this.load(false, loadCallback)
        }
    }

    protected fetchData(path: string): Promise<XMLHttpRequest> {
        if (this.state === BIO_TAB_STATE.VIEW_BIO) {
            return getCb(path)
        } else if (this.state === BIO_TAB_STATE.EDIT_BIO) {
            return getCb(path)
        } else {
            // Should be handled by an above case
            return super.fetchData(path)
        }
    }
}
