import { pageContext } from "../cb/interfaces/context"
import { addEventListenerPoly, onceEventListenerPoly, removeEventListenerPoly } from "./addEventListenerPolyfill"
import Key = JQuery.Key

export function isArrowUpKey(e: KeyboardEvent): boolean {
    return e.key === "ArrowUp" || e.keyCode === Key.ArrowUp
}

export function isArrowDownKey(e: KeyboardEvent): boolean {
    return e.key === "ArrowDown" || e.keyCode === Key.ArrowDown
}

export function isArrowKey(e: KeyboardEvent): boolean {
    return isArrowUpKey(e) || isArrowDownKey(e)
}

export function isEnterKey(e: KeyboardEvent): boolean {
    return e.key === "Enter" || e.keyCode === Key.Enter
}

export function isCharacterOrBackspaceKey(e: KeyboardEvent): boolean {
    if (typeof e.which == "undefined") {
        // This is IE, which only fires keypress events for printable keys
        return true
    } else if (typeof e.which == "number" && e.which > 0) {
        // In other browsers except old versions of WebKit, evt.which is
        // only greater than zero if the keypress is a printable key.
        // We need to filter out backspace and ctrl/alt/meta key combinations
        return !e.ctrlKey && !e.metaKey && !e.altKey
    }
    return false
}

// Expects a single alphanumeric character
export function isAlphaNumericKey(e: KeyboardEvent): boolean {
    const alphaNumericRegex = /^[a-zA-Z0-9]$/
    return e.key.match(alphaNumericRegex)?.length === 1
}

function _clickNoop(e?: Event) {
    e?.preventDefault()
    e?.stopImmediatePropagation()
}

/**
 * Prevents duplicate click events on a given element by binding a noop click handler in the capture phase.
 * After the first click, the element will still accept other pointer events (hover, etc.) but additional
 * clicks will have no effect, unless you specifically bind other capture-phase listeners before this one.
 *
 * @param el The element to apply the click debouncing to
 * @param ignoreMeta (optional) If true, ignores modifier-key clicks (e.g. ctrl+click, shift+click) for debouncing
 *     purposes, allowing them to be handled as normal without triggering the click-prevention. Defaults to true
 * @param timeout (optional) Defaults to 6000 (6s) or 3000 (3s). If GREATER than 0, the debounce will be reset after
 *     `timeout` milliseconds. Otherwise it is indefinite (with the assumption that the first click causes a navigation)
 *     and only resets on pageshow events, to account for the page being restored from bfcache on history navigation.
 *     You should think carefully when passing a timeout of 0, as navigation can always be interrupted by the user.
 * @returns the `el` argument with click handlers attached, to allow using this function like a higher-order component
 */

export function preventMultiClicks(el: HTMLElement, ignoreMeta = true, timeout?: number): HTMLElement {
    const preventMultiClickTimeout = pageContext.current.isMobile ? 6000 : 3000
    timeout = timeout ?? preventMultiClickTimeout
    // Inner fxn restores el to its state before the click-prevention listener was activated
    // Flag prevents restoreEl from running more than once per invocation of preventMultiClicks since we bind two listeners
    let restored = false
    const restoreEl = () => {
        if (restored) {
            return
        }
        removeEventListenerPoly("click", el, _clickNoop, true)
        removeEventListenerPoly("pageshow", window, restoreEl)
        preventMultiClicks(el, ignoreMeta, timeout)  // Listener only fires once so it must be re-bound
        restored = true
    }

    onceEventListenerPoly("click", el, (evt: MouseEvent) => {
        if (ignoreMeta && (evt.ctrlKey || evt.metaKey || evt.shiftKey)) {
            // ignoreMeta = don't intercept modifier-clicks, early-exit (but reset the listener for the next click)
            preventMultiClicks(el, ignoreMeta, timeout)
            return
        }
        // Add click-prevention listener with useCapture=true, so the click intercept occurs before any other
        // non-capture-phase listeners that may be registered on the same element
        addEventListenerPoly("click", el, _clickNoop, true)
        // If a postive timeout is specified, automatically restore click listeners after that period
        if (timeout > 0) {
            window.setTimeout(restoreEl, timeout)
        }
        // Also restore click events on `pageshow` since e.g. Safari may be serving a cached document due to
        // history navigation, and we want the element to act like it would on load rather than stay disabled
        addEventListenerPoly("pageshow", window, restoreEl)
    })

    return el
}
