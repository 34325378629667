import { EventRouter } from "../../../common/events"

export enum ResultType {
    Online = "online",
    Offline = "offline",
    Hashtag = "hashtags",
    History = "history",
    Keywords = "keywords",
}

// NOTE: Focusable elements in SearchInput should call SearchBlur.fire(e) in their blur event.
export const SearchBlur = new EventRouter<FocusEvent>("SearchBlur")
