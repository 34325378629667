import { normalizeResource } from "../../../../../common/api"
import { Component } from "../../../../../common/defui/component"
import { addPageAction } from "../../../../../common/newrelic"
import { i18n } from "../../../../../common/translation"
import { addColorClass, removeColorClass } from "../../../../colorClasses"
import { pageContext } from "../../../../interfaces/context"
import { NotificationType } from "../../api"
import type { IUserUpdateItem } from "../../../../../common/messageInterfaces"

export class UserUpdateRow extends Component {
    constructor(data: IUserUpdateItem) {
        super()
        addColorClass(this.element, "twitterUpdateRow")
        this.element.style.fontFamily = "UbuntuRegular, Helvetica, Arial, sans-serif"
        this.element.style.fontStyle = "normal"
        this.element.style.fontWeight = "normal"
        this.element.style.position = "relative"
        this.element.style.height = ""
        this.element.style.width = ""
        this.element.style.margin = "7px"
        this.element.style.padding = "6px"
        this.setSeenColors(data.seen)
        this.element.style.borderWidth = "1px"
        this.element.style.borderStyle = "solid"
        this.element.style.borderRadius = "4px"
        this.element.dataset.testid = "notification-item"

        this.element.appendChild(this.createContentElement(data.text))

        const skipLinkforMobileOfflineTip = data.notificationType === NotificationType.OFFLINE_TIP && pageContext.current.isMobile
        
        if (data.timeSince !== "") {
            const timeSinceEl = createTimeSinceFooter(data.timeSince)
            this.element.appendChild(timeSinceEl)
        }

        if (data.url !== "" && !skipLinkforMobileOfflineTip) {
            let notificationType = "twitter_feed"
            if (data.notificationType !== undefined) {
                notificationType = data.notificationType
            }
            const linkEl = createLinkElement(data.url, notificationType)
            this.element.appendChild(linkEl)
        }
    }

    private setSeenColors(seen: boolean): void {
        if (seen) {
            addColorClass(this.element, "seen")
        } else {
            removeColorClass(this.element, "seen")
        }
    }

    private createContentElement(text: string): HTMLElement {
        const contentEl = this.buildContentFromText(text)
        addColorClass(contentEl, "contentSection")
        contentEl.style.fontSize = "12px"
        contentEl.style.lineHeight = "14px"
        contentEl.style.whiteSpace = "pre-wrap"
        contentEl.style.marginBottom = "6px"
        contentEl.style.wordWrap = contentEl.style.wordBreak = "break-word"
        contentEl.style.overflow = "hidden"
        contentEl.dataset.testid = "notification-content"
        return contentEl
    }

    private buildContentFromText(html: string): HTMLElement {
        const root = document.createElement("div")
        // eslint-disable-next-line @multimediallc/no-inner-html
        root.innerHTML = html
        this.makeLinksShorterAndAddStyle(root)
        return root
    }

    private makeLinksShorterAndAddStyle(doc: HTMLElement): void {
        Array.from(doc.querySelectorAll("a")).forEach(e => {
            addColorClass(e, "tweetLink")
            e.target = "_blank"
            e.style.outline = "none"
            e.style.fontSize = "12px"
            e.style.lineHeight = "14px"
            if (e.firstChild !== null && e.firstChild instanceof Text && e.firstChild.textContent !== null) {
                e.firstChild.textContent = e.firstChild.textContent
                    .replace(/^http(s)?:\/\//, "")
                    .replace(/^www\./, "")
            }
            e.onclick = () => {
                addPageAction("NewsfeedItemLinkClick")
            }
        })
    }
}

function createTimeSinceFooter(time_since: string): HTMLElement {
    const footerEl = document.createElement("div")
    addColorClass(footerEl, "timeSince")
    footerEl.appendChild(document.createTextNode(time_since))
    footerEl.style.fontSize = "9px"
    footerEl.style.lineHeight = "13px"
    footerEl.style.cssFloat = "left"
    footerEl.dataset.testid = "notification-time-since"
    return footerEl
}

function getNotificationLinkText(notificationType: string): string {
    switch (notificationType) {
        case NotificationType.TWITTER_FEED:
            return i18n.viewOnTwitterText
        case NotificationType.OFFLINE_TIP:
            return i18n.showTipsAndMessages
    }
    return i18n.seeMore
}

function createLinkElement(url: string, notificationType: string): HTMLAnchorElement {
    const linkEl = document.createElement("a")
    linkEl.href = normalizeResource(url)
    linkEl.target = "_blank"
    const isTwitterFeed = notificationType === NotificationType.TWITTER_FEED
    const isOfflineTipFeed = notificationType === NotificationType.OFFLINE_TIP
    const text = getNotificationLinkText(notificationType)
    linkEl.appendChild(document.createTextNode(text))
    addColorClass(linkEl, "twitterLink")
    linkEl.style.fontSize = "9px"
    linkEl.style.lineHeight = "13px"
    linkEl.style.textAlign = "right"
    linkEl.style.cssFloat = "right"
    linkEl.dataset.testid = "notification-link"
    if (isTwitterFeed) {
        linkEl.onclick = () => {
            addPageAction("NewsfeedItemViewOnTwitter")
        }
    } else if (isOfflineTipFeed) {
        linkEl.onclick = () => {
            addPageAction("OfflineTipItemLinkClick")
        }
    }

    return linkEl
}
