import { PageType, saveStickyFilters, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { addEventListenerMultiPoly, addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { normalizeResource } from "../../../common/api"
import { isAnonymous } from "../../../common/auth"
import { applyStyles, getCoords, ignoreMetaClick, numberFromStyle } from "../../../common/DOMutils"
import { isRecommendedFollowRoomsActive } from "../../../common/featureFlagUtil"
import { loadRoomRequest } from "../../../common/fullvideolib/userActionEvents"
import { Gender, GendersSymbolToNameMap } from "../../../common/genders"
import { getViewportWidth } from "../../../common/mobilelib/viewportDimension"
import { addPageAction } from "../../../common/newrelic"
import { LOGIN_BASE_PATH, SIGN_UP_BASE_PATH } from "../../../common/redirectParamUtils"
import { RoomImageStreamer } from "../../../common/roomImage"
import { i18n } from "../../../common/translation"
import { dom, Fragment } from "../../../common/tsxrender/dom"
import { addColorClass, colorClass, removeColorClass } from "../../colorClasses"
import { isRoomAnimationEnabled, RoomListSource, saveRoomListSourceCookie } from "../../roomList"
import { showLoginOverlay } from "../../ui/loginOverlay"
import { WaterfallDropDown } from "../../ui/waterfallDropDown"
import { isRoomRoomlistSpaEligiblePage } from "../roomlist/spaHelpers"
import { FollowedData } from "./followedData"
import {
    FollowedRoomTable,
    FollowRecommendedHeader,
} from "./followRecommendedUtil"
import type { IFollowedRoom, IOnlineFollowed } from "./follow"
import type { TsxChild } from "../../../common/tsxrender/dom"


export interface INewRooms {
    flash: boolean
    rooms: IFollowedRoom[]
}

const dropdownStyle: CSSX.Properties = {
    fontSize: "13px",
    fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
    width: "auto",
    height: "auto",
    position: "absolute",
    display: "none",
    overflow: "",
    cursor: "default",
    top: "0px",
    left: "0px",
    zIndex: 5,
}

const followedCountInlineStyle: CSSX.Properties = { cssFloat: "right" }

const followedCountNextLineStyle: CSSX.Properties = { margin: "5px 0" }

export class FollowedDropdown extends WaterfallDropDown {
    private followedContainer: HTMLDivElement
    private followedData = FollowedData.getInstance()
    private followedCount: HTMLDivElement | undefined
    protected topMargin = 5
    private moreAnchor: HTMLAnchorElement

    constructor(toggleElement: HTMLElement, private showFollowedCount: boolean) {
        super(toggleElement, false)
    }

    /** Follow dropdown styles are defined in css, only need to assign the border radius here based on alignment. */
    protected setDropDownStyleFromToggle(alignRight: boolean): void {
        const radius = getComputedStyle(this.element).borderBottomRightRadius
        this.setBorderRadius(alignRight, radius)
    }

    protected createBaseElement(): HTMLDivElement {
        const containerClasses = isRecommendedFollowRoomsActive()
            ? "followRecommendedContainer" : "followedContainer"
        return <div
            className="followedDropdown" data-href="skip"
        >
            <div className={containerClasses}
                colorClass={[colorClass.tabActiveBgColor, "tabBorderNoHover"]}
                ref={(el: HTMLDivElement) => {
                    this.followedContainer = el
                }}/>
        </div>
    }

    protected initData(props?: object): void {
        super.initData(props ?? {})

        UrlState.current.listen(["genders"], (state) => {
            const gender = (state.genders ?? [])[0] ?? ""
            if (this.moreAnchor !== undefined) {
                this.moreAnchor.href = normalizeResource(followedUrl(gender, this.followedData.getOnlineFollowed().online > 0))
            }
        }, this.element)
    }

    protected initUI(props?: object): void {
        super.initUI(props)
        applyStyles(this.element, dropdownStyle)
    }

    public updateFollowedCount(): void {
        if (this.followedCount === undefined) {
            return
        }
        const onlineFollowed = this.followedData.getOnlineFollowed()
        this.followedCount.textContent = i18n.followCountLong(onlineFollowed.online, onlineFollowed.total)
    }

    private navigateToFollowedPage(event: MouseEvent): void {
        addPageAction("FollowedDropdownSeeAll")
        if (UrlState.current.state.pageType === PageType.HOME ||
            isRoomRoomlistSpaEligiblePage() && UrlState.current.state.pageType === PageType.ROOM) {
            event.preventDefault()
            UrlState.current.navigateTo(followedUrl(UrlState.current.state.genders?.[0] ?? Gender.All, this.followedData.getOnlineFollowed().online > 0))
        }
    }

    public replaceRooms(): void {
        if (this.isShown()) {
            return
        }
        while (this.followedContainer.firstChild !== null) {
            this.followedContainer.removeChild(this.followedContainer.firstChild)
        }
        this.appendHeaders()
        this.followedContainer.style.width = "auto"
        this.appendRoomTables()

        if (this.followedData.getCurrentShownRooms().length === 0) {
            this.appendNoneDiv()
            if (!isRecommendedFollowRoomsActive() || FollowedData.getInstance().getCurrentRecommendations().length < 2) {
                this.followedContainer.style.width = "265px"
            }
        }
    }

    private appendHeaders(): void {
        if (!isAnonymous()) {
            const onlineFollowed = this.followedData.getOnlineFollowed()
            const useWideContainer = this.followedData.getCurrentShownRooms().length > 1
            const gender = (UrlState.current.state.genders ?? [])[0] ?? ""
            this.followedContainer.appendChild(this.getRoomElement(gender, onlineFollowed, useWideContainer))
            if (isRecommendedFollowRoomsActive()) {
                const recommendationsHeader = new FollowRecommendedHeader({ "rooms": FollowedData.getInstance().getCurrentRecommendations() })
                this.followedContainer.appendChild(recommendationsHeader.element)
            }
        }
    }

    private appendRoomTables(): void {
        if (isRecommendedFollowRoomsActive()) {
            const followTable = new FollowedRoomTable({ rooms: this.followedData.getCurrentShownRooms() })
            this.followedContainer.firstElementChild?.insertAdjacentElement("afterend", followTable.element)
            const recommendationsTable = new FollowedRoomTable({ rooms: this.followedData.getCurrentRecommendations() })
            this.followedContainer.appendChild(recommendationsTable.element)
        } else {
            const table = <div style={{ display: "table" }} />
            let tr = <div />
            let count = 0
            this.followedData.getCurrentShownRooms().forEach((roomEl) => {
                if (count % 2 === 0) {
                    tr = <div style={{ display: "table-row" }} />
                    table.appendChild(tr)
                }
                const tc = <div style={{ display: "table-cell" }} />
                tc.appendChild(roomEl.card)
                tr.appendChild(tc)
                count += 1
            })
            this.followedContainer.appendChild(table)
        }
    }

    private appendNoneDiv() {
        const noneDiv = <div
            colorClass={colorClass.textColor}
            style={{
                margin: "5px 3px 5px 3px",
                fontSize: "12px",
                fontFamily: "UbuntuRegular",
            }} />
        if (isAnonymous()) {
            const AnonAnchor = (props: { href: string, onClick?: (ev: MouseEvent) => void, children?: TsxChild[], testid?: string }) => {
                const anchorStyle: CSSX.Properties = {
                    height: "auto",
                    width: "auto",
                    border: "none",
                    borderRadius: "0px",
                    textDecoration: "none",
                    cursor: "pointer",
                    cssFloat: "none",
                    padding: "0px",
                }
                const anonAnchor = <a
                    href={props.href}
                    style={anchorStyle}
                    colorClass={[colorClass.tabActiveBgColor, colorClass.hrefColor]}
                    onMouseEnter={() => {
                        anonAnchor.style.textDecoration = "underline"
                    }}
                    onMouseLeave={() => {
                        anonAnchor.style.textDecoration = "none"
                    }}
                    onClick={props.onClick !== undefined ? props.onClick : undefined}
                    account-redirect
                    data-testid={props.testid}
                >{props.children}</a>
                return anonAnchor
            }

            noneDiv.appendChild(<Fragment>
                <AnonAnchor
                    href={normalizeResource(`${SIGN_UP_BASE_PATH}?src=followed_tab`)}
                    testid="following-sign-up-link">
                    {i18n.signUpText}
                </AnonAnchor>
                <span>{` ${i18n.orLower} `}</span>
                <AnonAnchor href={normalizeResource(LOGIN_BASE_PATH)}
                    onClick={(ev: MouseEvent) => {
                        if (!(ev.metaKey || ev.ctrlKey)) {
                            ev.preventDefault()
                            this.hideElement()
                            showLoginOverlay({})
                        }
                    }}
                    testid="following-login-link"
                >
                    {i18n.loginVerbText}
                </AnonAnchor>
            </Fragment>)
            noneDiv.appendChild(<span>{i18n.anonFollowMore}</span>)
        } else {
            noneDiv.appendChild(<span>{i18n.userFollowMore}</span>)
        }
        if (isRecommendedFollowRoomsActive()) {
            this.followedContainer.firstElementChild?.insertAdjacentElement("afterend", noneDiv)
        } else {
            this.followedContainer.appendChild(noneDiv)
        }
    }

    private getRoomElement(gender: Gender, onlineFollowed: IOnlineFollowed, useWideContainer: boolean): HTMLDivElement {
        return <div style={{ margin: "5px 3px" }}>
            {isRecommendedFollowRoomsActive() && <span className="onlineRoomsTitle">{i18n.onlineRooms}</span>}
            <a href={followedUrl(gender, onlineFollowed.online > 0)}
                style={{
                    height: "auto",
                    width: "auto",
                    border: "none",
                    borderRadius: "0px",
                    textDecoration: "none",
                    cursor: "pointer",
                    cssFloat: isRecommendedFollowRoomsActive() ? "right" : "none",
                    padding: "0px",
                }}
                data-testid="following-show-all"
                colorClass={[colorClass.tabActiveBgColor, colorClass.hrefColor]}
                ref={(el: HTMLAnchorElement) => {
                    this.moreAnchor = el
                }}
                onMouseEnter={() => {
                    this.moreAnchor.style.textDecoration = "underline"
                    addColorClass(this.moreAnchor, colorClass.tabActiveColor)
                    removeColorClass(this.moreAnchor, colorClass.hrefColor)
                }}
                onMouseLeave={() => {
                    this.moreAnchor.style.textDecoration = "none"
                    addColorClass(this.moreAnchor, colorClass.hrefColor)
                    removeColorClass(this.moreAnchor, colorClass.tabActiveColor)
                }}
                onClick={(e: MouseEvent) => this.navigateToFollowedPage(e)}
            >{i18n.showAllText}</a>
            {this.showFollowedCount &&
                <div style={useWideContainer ? followedCountInlineStyle : followedCountNextLineStyle}
                    className="followedCount"
                    ref={(el: HTMLDivElement) => {
                        this.followedCount = el
                    }}
                >{i18n.followCountLong(onlineFollowed.online, onlineFollowed.total)}</div>
            }
        </div>
    }

    updateThumbnails(): void {
        for (const roomEl of this.followedData.getCurrentShownRooms()) {
            roomEl.updateThumbnail()
        }
        if (isRecommendedFollowRoomsActive()) {
            for (const roomEl of this.followedData.getCurrentRecommendations()) {
                roomEl.updateThumbnail()
            }
        }
    }

    showElement(): boolean {
        if (!super.showElement()) {
            return false
        }
        this.updateThumbnails()
        this.followedContainer.scrollTop = 0
        this.followedData.resetLastUnseen()

        const coords = getCoords(this.element)
        const rightEdge = Math.max(getViewportWidth(), numberFromStyle(document.body.style.minWidth))
        if (coords.right > rightEdge) {
            this.element.style.width = `${this.element.offsetWidth - (coords.right - rightEdge) - 4}px`
            this.followedContainer.style.overflowX = "auto"
        }

        return true
    }

    hideElement(event?: MouseEvent): boolean {
        if (event !== undefined && (event.metaKey || event.ctrlKey)) {
            return false
        }

        if (!super.hideElement()) {
            return false
        }
        for (const roomEl of this.followedData.getCurrentShownRooms()) {
            roomEl.unhighlight()
        }
        this.element.style.width = "auto"
        this.followedContainer.style.overflowX = "hidden"
        this.replaceRooms()
        return true
    }

    reposition(): void {
        super.reposition()
        this.connectedOverlay.element.style.display = "none"
        this.setCollapsed()
    }

    protected setCollapsed(): void {
        // overrides inline styling for followed dropdown inset
        const navbar = document.querySelector(".nav-bar")
        if (navbar !== null && this.toggleElement.getBoundingClientRect().top > navbar.getBoundingClientRect().bottom) {
            this.element.classList.add("collapsed")
            this.element.style.left = ""
            this.element.style.right = ""
            this.element.style.width = "auto"
        } else {
            this.element.classList.remove("collapsed")
        }
    }
}

export function followedUrl(gender: Gender, hasOnline: boolean): string {
    if (!hasOnline) {
        return "/followed-cams/offline/"
    }
    const baseUrl = "/followed-cams/online/"
    return `${baseUrl}${gender !== Gender.All ? `${GendersSymbolToNameMap.get(gender)}/` : ""}`
}

export class RoomElement {
    public card: HTMLDivElement
    public username = ""
    private a: HTMLAnchorElement
    private img: HTMLImageElement
    private imageStreamer?: RoomImageStreamer

    constructor(private room: IFollowedRoom, private highlighted: boolean, roomIndex: number) {
        this.username = room.room

        const cardStyle: CSSX.Properties = {
            display: "inline-block",
            width: "180px",
            height: "126px",
            borderRadius: "4px",
            margin: "3px",
            cursor: "pointer",
        }
        const anchorStyle: CSSX.Properties = {
            width: "180px",
            height: "126px",
            borderRadius: "4px",
            border: "none",
            padding: "0px",
            margin: "0px",
            display: "block",
            cursor: "pointer",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textDecoration: "none",
        }

        const roomImage = this.room.image !== "" ? this.room.image : `${STATIC_URL_ROOT}images/no_thumbnail_1_wide.jpg`

        this.a = <a
            className="roomElementAnchor"
            colorClass={[colorClass.tabInactiveColor, this.highlighted ? "isHighlighted" : "notHighlighted"]}
            style={anchorStyle}
            data-room={this.username}
            href={normalizeResource(`/${this.username}/`)}
            onClick={(evt: MouseEvent) => {
                addPageAction("FollowedDropdownVisit")
                // When we navigate to a room, we should save the sticky filters in case they are from
                // a footer link and haven't been saved yet.
                saveStickyFilters(UrlState.current.state)
                if (isRoomRoomlistSpaEligiblePage()) {
                    ignoreMetaClick(evt, () => {
                        loadRoomRequest.fire(this.username)
                    })
                }
            }}
        >
            <img alt="" src={roomImage} width={180} height={101} style={{ borderRadius: "3px 3px 0px 0px" }}
                className="room_thumbnail"
                data-testid="room-card-image"
                ref={(el: HTMLImageElement) => {
                    this.img = el
                }} />
            <span style={{ padding: "5px" }} data-testid="room-card-username">{this.username}</span>
        </a>

        this.card = <div className="roomElement" style={cardStyle} data-testid="room-card">{this.a}</div>

        addEventListenerMultiPoly(["click", "mousedown", "touchstart"], this.a, () => {
            saveRoomListSourceCookie(this.username, RoomListSource.FollowedTab, roomIndex)
        })
    }

    unhighlight(): void {
        this.highlighted = false
        addColorClass(this.a, "notHighlighted")
        removeColorClass(this.a, "isHighlighted")
    }

    updateThumbnail(): void {
        this.setImageStreamer()
        if (this.room.image !== "") {
            this.imageStreamer?.loadImage(0).catch(err => {
                warn("Error loading image", { imageSrc: err })
            })
        }
    }

    private setImageStreamer(): void {
        if (this.room.image === "" || this.imageStreamer !== undefined) {
            return
        }
        this.imageStreamer = new RoomImageStreamer(this.username, this.img)
        addEventListenerPoly("mouseenter", this.card, () => {
            if (isRoomAnimationEnabled()) {
                this.imageStreamer?.startStreaming()
            }
        })
        addEventListenerPoly("mouseleave", this.card, () => {
            if (isRoomAnimationEnabled()) {
                this.imageStreamer?.stopStreaming()
            }
        })
    }
}
