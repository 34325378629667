import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { MultipleCheckbox } from "../../ui/fields"
import { AdvancedOptionsCheckboxStyles } from "./AdvancedOptionsStyles"
import type { IChoices } from "../../ui/fields"


const searchRegionChoices: IChoices[] = [
    { label: i18n.northAmericaTitle, value: "NA" },
    { label: i18n.southAmericaTitle, value: "SA" },
    { label: i18n.asiaTitle, value: "AS" },
    { label: i18n.europeRussiaTitle, value: "ER" },
    { label: i18n.otherTitle, value: "O" },
]

interface ISearchRegionProp {initial: string,}

export class SearchRegionsForm extends HTMLComponent<HTMLDivElement, ISearchRegionProp> {
    checkboxes: MultipleCheckbox

    protected createElement(props: ISearchRegionProp): HTMLDivElement {
        const options = {
            choices: searchRegionChoices,
            name: "visible_regions",
            defaultValue: props.initial !== "" ? props.initial : "NA,SA,AS,ER,O",
            styles: new AdvancedOptionsCheckboxStyles(),
        }
        this.checkboxes = new MultipleCheckbox(options)
        return <div className="filter_location checkboxes">
            <label>{i18n.visibleRegionsTag}:</label>
            <form id="filter_location_form" method="post" action="/ax/update_location_filters/">
                {this.checkboxes.getField()}
            </form>
        </div>
    }

    protected initUI(props?: ISearchRegionProp): void {
        super.initUI(props)
        this.initCheckboxInputElements()
    }

    private initCheckboxInputElements(): void {
        let id = 0
        this.element.querySelectorAll<HTMLInputElement>("input[type=checkbox]").forEach((inputEl) => {
            inputEl.id = `id_visible_regions_${id}`
            const label = inputEl.closest("label[class=checkboxFieldLabel]") as HTMLLabelElement
            label.htmlFor = inputEl.id
            id = id + 1

            // disables autocomplete to prevent checkboxes from being falsely checked after non-spa back/forward
            inputEl.autocomplete = "off"
        })
    }
}
