import { getCb, postCb } from "../../common/api"
import { Debouncer, DebounceTypes } from "../../common/debouncer"
import { EventRouter, eventsPmSessionsCount } from "../../common/events"
import { libraryMediaDockItemLimit } from "../components/pm/mediaDock"
import type { IChatMedia } from "../../common/messageInterfaces"


// 1 per PM session
export const pmMediaDrop = new EventRouter<{ toUser: string, files: File[] }>("pmMediaDrop", { listenersWarningThreshold: () => eventsPmSessionsCount })
export const pmMediaUpload = new EventRouter<{ toUser: string, promise: Promise<IChatMedia> }>("pmMediaUpload", { listenersWarningThreshold: () => eventsPmSessionsCount })

// 2 singleton listens + 2 per pm session
export const pmMediaDeleted = new EventRouter<number>("pmMediaDeleted", { listenersWarningThreshold: () => 2 + 2 * eventsPmSessionsCount })
export const pmMediaRejected = new EventRouter<number>("pmMediaRejected", { listenersWarningThreshold: () => 2 + 2 * eventsPmSessionsCount })

const MAX_MEDIA_SIZE = 50 * 1024 * 1024  // This limit is enforced in live's nginx config too
export const enum ChatMediaUploadError {
    InvalidFile = "invalid_media",
    FileTooLarge = "file_too_large",
    LegalBlock = "legal_block",
}

export function uploadChatMedia(mediaFile: File, toUser: string): Promise<IChatMedia> {
    let promise
    if (mediaFile.size > MAX_MEDIA_SIZE) {
        promise = Promise.reject(ChatMediaUploadError.FileTooLarge)
    } else {
        const formData = new FormData()
        formData.append("media", mediaFile)
        promise = postCb("api/ts/chatmessages/media/upload/", formData).then(xhr => {
            const response = JSON.parse(xhr.responseText)["payload"]
            return {
                mediaId: response["media_id"],
                url: response["media_url"],
                thumbnailUrl: response["media_thumbnail_url"],
                opened: false,
                fromUserUID: response["user_uid"],
            }
        })
    }
    pmMediaUpload.fire({ toUser, promise })
    return promise
}

export function getLatestPhotos(limit = libraryMediaDockItemLimit, offset = 0): Promise<IChatMedia[]> {
    return getCb(`api/ts/chatmessages/media/?media_type=I&limit=${limit}&offset=${offset}`).then(xhr => {
        const output: IChatMedia[] = []
        const media = JSON.parse(xhr.responseText)["media"]
        for (const img of media) {
            output.push({
                mediaId: img["media_id"],
                url: img["media_url"],
                thumbnailUrl: img["media_thumbnail_url"],
                opened: false,
                fromUserUID: img["user_uid"],
            })
        }
        output.sort((a, b) => Number(b.mediaId) - Number(a.mediaId))
        return output
    })
}

let recentlyFetchedPhotos: Promise<IChatMedia[]>
const getLatestPhotosDebouncer = new Debouncer(() => {recentlyFetchedPhotos = getLatestPhotos()}, { bounceLimitMS: 10, debounceType: DebounceTypes.throttle })
export function throttledGetLatestPhotos(): Promise<IChatMedia[]> {
    getLatestPhotosDebouncer.callFunc()
    return recentlyFetchedPhotos
}

export function deletePhoto(mediaId: number): Promise<void> {
    return postCb("api/ts/chatmessages/media/delete/", { "media_id": mediaId.toString() }).then(xhr => {
        if (xhr.status === 200) {
            pmMediaDeleted.fire(mediaId)
            return Promise.resolve()
        } else {
            return Promise.reject()
        }
    }).catch(xhrError => {
        return Promise.reject()
    })
}

export function setMediaOpenedStatus(fromUserUID: string, messageID: string, mediaID: number): Promise<boolean> {
    const formData = new FormData()
    formData.append("from_user_uid", fromUserUID)
    formData.append("message_id", messageID)
    formData.append("media_id", mediaID.toString())
    return postCb("api/ts/chatmessages/media/opened/", formData).then((xhr) => {
        return xhr.status === 204
    }).catch(() => {
        return false
    })
}
