import { modalAlert } from "../../../common/alerts"
import { Component } from "../../../common/defui/component"
import { getTextWidth } from "../../../common/DOMutils"
import { ListenerGroup } from "../../../common/events"
import { OutgoingMessageType, parseOutgoingMessage } from "../../../common/specialoutgoingmessages"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { standardEmojiRequest } from "../../../common/userActionEvents"
import { PrivateMessageSource, sendPrivateMessage } from "../../api/pm"
import { removeColorClass } from "../../colorClasses"
import { pageContext } from "../../interfaces/context"
import { handleDmInputFocus } from "./dmUtil"
import { maxDmInput } from "./dmWindow"
import { removeDmWindowRequest } from "./dmWindowsManager"

import Key = JQuery.Key
import type {
    IOutgoingMessage,
    ITipRequestMessage,
} from "../../../common/specialoutgoingmessages"
import type { IPMError } from "../../api/pm"

export type DmWindowInputProps = {
    toUsername: string
    onInputExpand: () => void
    onInputCollapse: () => void
    showTipping: (amount?: number, message?: string, delayFocus?: boolean) => void
    sendDmFailedCallback: (error: IPMError) => void
    onFocus: () => void
}

export class DmWindowInput extends Component<HTMLDivElement, DmWindowInputProps> {
    public static readonly height = "38px"
    public static readonly expandedHeight = "76px"
    protected inputBar: HTMLTextAreaElement
    protected buttonsContainer: HTMLDivElement
    protected sendDmButton: HTMLSpanElement
    protected sendTipButton: HTMLSpanElement
    private emojiButton: HTMLImageElement
    // private emojiModal: EmojiSelectionModal | undefined

    public isInputExpanded = false
    private disableSend = true
    protected disableTip = true
    private listenerGroup: ListenerGroup

    constructor(protected props: DmWindowInputProps) {
        super("div", props)
    }

    protected initData(props: DmWindowInputProps): void {
        this.listenerGroup = new ListenerGroup()
        standardEmojiRequest.listen((clickedElement: HTMLElement) => {
            if (clickedElement === this.emojiButton) {
                // TODO fix emojis
                // if (this.emojiModal === undefined) {
                //     this.emojiModal = new EmojiSelectionModal(this.inputBar)
                //     this.addChild(this.emojiModal)
                // }
                // this.raiseWindowZIndexToTop(this.username)
                // this.emojiModal.show(clickedElement)
            }
        }).addTo(this.listenerGroup)
    }

    protected initUI(props: DmWindowInputProps): void {
        const inputDivStyle: CSSX.Properties = {
            position: "absolute",
            bottom: "0",
            width: "calc(100% - 4px)", // border-box sizing doesn't include margins
            height: this.trueHeight(false),
            margin: "0px 2px 3px",
            boxSizing: "border-box",
            borderWidth: "1px",
            borderStyle: "solid",
            borderRadius: "2px",
        }
        const inputBarStyle: CSSX.Properties = {
            position: "absolute",
            width: "100%",
            resize: "none",
            boxSizing: "border-box",
            backgroundColor: "transparent",
            border: "none",
            outline: "none",
            fontFamily: "Tahoma, Helvetica, Arial, sans-serif",
            fontSize: "12px",
            padding: "8px 0 0 8px",
        }
        const sendButtonStyle: CSSX.Properties = {
            height: "23px",
            width: "47px",
            borderRadius: "4px",
            fontSize: "12px",
            lineHeight: "14px",
            cursor: "pointer",
            padding: "4px",
        }
        const tipButtonStyle: CSSX.Properties = {
            height: "23px",
            width: "47px",
            borderRadius: "4px",
            fontSize: "12px",
            lineHeight: "14px",
            padding: "4px",
            cursor: "pointer",
            marginLeft: "4px",
            display: "none",
        }
        // const emojiButtonStyle: CSSX.Properties = {
        //    position: "relative",
        //    top: "4px",
        //    marginRight: "5px",
        //    cursor: "pointer",
        // }

        const inputButtonsDivStyle: CSSX.Properties = {
            position: "absolute",
            bottom: "4px",
            right: "0px",
            padding: "2px 6px 0px",
            margin: "4px 0px",
        }

        this.element =
            <div style={inputDivStyle} colorClass="dmWindowInput">
                <textarea
                    style={inputBarStyle}
                    className="noScrollbar"
                    placeholder={i18n.sendAMessageDesktop}
                    maxLength={maxDmInput}
                    ref={(el: HTMLTextAreaElement) => this.inputBar = el}
                    onKeyDown={(e) => this.keyDownHandler(e)}
                    onInput={() => this.expandOrCollapseInputBar()}
                    onFocus={props.onFocus}
                    onPointerDown={(e) => this.focus(e)}
                    data-testid="dm-input"
                />
                <div
                    style={inputButtonsDivStyle}
                    ref={(el: HTMLDivElement) => this.buttonsContainer = el}
                >
                    {/* <img src={`${STATIC_URL}emoji.svg`} style={emojiButtonStyle} onClick={emojiButtonClickHandler} ref={(el: HTMLImageElement) => this.emojiButton = el}/>  TODO put back */}
                    <span
                        style={sendButtonStyle}
                        colorClass={["sendButton", "disabled"]}
                        onClick={() => this.submit()}
                        ref={(el: HTMLSpanElement) => this.sendDmButton = el}
                        data-testid="dm-send-button"
                    >
                        {i18n.sendCAPS}
                    </span>
                    <span
                        style={tipButtonStyle}
                        colorClass={["tipButton"]}
                        onClick={() => this.props.showTipping()}
                        ref={(el: HTMLSpanElement) => this.sendTipButton = el}
                        data-testid="dm-tip-button"
                    >
                        {i18n.tipCAPS}
                    </span>
                </div>
            </div>
    }

    public enableSend(): void {
        this.disableSend = false
        removeColorClass(this.sendDmButton, "disabled")
    }

    public enableTip(): void {
        this.disableTip = false
        this.sendTipButton.style.display = ""
    }

    public focus(event?: PointerEvent): void {
        handleDmInputFocus(event ?? this.inputBar)
    }

    public blur(): void {
        this.inputBar.blur()
    }

    public isFocused(): boolean {
        return document.activeElement === this.inputBar
    }

    public dispose(): void {
        this.listenerGroup.removeAll()
    }

    protected submit(focusInput = true): void {
        if (this.disableSend) {
            return
        }
        if (pageContext.current.isNoninteractiveUser) {
            modalAlert(i18n.internalStaffMessage)
            return
        }
        const value = this.inputBar.value.trim()
        this.inputBar.value = ""
        this.expandOrCollapseInputBar()

        if (focusInput) {
            this.focus()
        }
        if (value === "") {
            return
        }
        const outgoingMessage: IOutgoingMessage = parseOutgoingMessage(value, PrivateMessageSource.DM)
        switch (outgoingMessage.messageType) {
            case OutgoingMessageType.TipRequest:
                const tipRequestMessage = outgoingMessage as ITipRequestMessage
                this.handleTipRequestMessage(tipRequestMessage)
                break
            default:
                sendPrivateMessage({
                    message: value,
                    username: this.props.toUsername,
                    source: PrivateMessageSource.DM,
                }).catch(err => {
                    this.props.sendDmFailedCallback(err)
                })
        }
    }

    protected handleTipRequestMessage(message: ITipRequestMessage): void {
        if (this.disableTip) {
            // catch /tip messages when tip button is hidden
            return
        }
        this.props.showTipping(message.messageData.amount, message.messageData.message)
    }

    protected keyDownHandler(e: KeyboardEvent): void {
        switch (e.keyCode) {
            case Key.L:
                if (e.ctrlKey || e.metaKey) {
                    e.preventDefault()
                    e.stopPropagation()
                    removeDmWindowRequest.fire({ username: this.props.toUsername })
                }
                break
            case Key.Enter:
                this.handleEnterKey(e)
                break
        }
    }

    protected handleEnterKey(e: KeyboardEvent): void {
        if (!e.shiftKey) {
            e.preventDefault()
            this.submit()
        }
    }

    protected expandOrCollapseInputBar(): void {
        const expandInputBar = () => {
            if (!this.isInputExpanded) {
                this.element.style.height = this.trueHeight(true)
                this.isInputExpanded = true
                this.props.onInputExpand()
            }
        }

        const collapseInputBar = () => {
            if (this.isInputExpanded) {
                this.element.style.height = this.trueHeight(false)
                this.isInputExpanded = false
                this.props.onInputCollapse()
            }
        }

        const availableWidth = this.buttonsContainer.getBoundingClientRect().x - this.element.getBoundingClientRect().x
        if (getTextWidth(this.inputBar.value, this.inputBar) >= availableWidth - 20 || this.inputBar.value.includes("\n")) {
            expandInputBar()
        } else {
            collapseInputBar()
        }
    }

    public render(): HTMLDivElement {
        return this.element
    }

    protected trueHeight(expanded: boolean): string {
        const baseHeight = expanded ? DmWindowInput.expandedHeight : DmWindowInput.height
        return `calc(${baseHeight} - 3px)` // border-box sizing doesn't include margins
    }
}
