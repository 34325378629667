import { VideoJsPlayer } from "../player/videoJsPlayer"
import type { RoomStatusNotifier } from "../roomStatusNotifier"

export class MobileVideoJsPlayer extends VideoJsPlayer {
    // Raw strings are to prevent obfuscation.
    protected createPlayerOption(): videojs.PlayerOptions {
        const options = super.createPlayerOption()

        options["techOrder"] = ["chromecast", "html5"]
        options["chromecast"] = {
            "requestTitleFn": (source: object) => {
                return document.title
            },
            "requestSubtitleFn": (source: object) => {
                return ""
            },
        }
        options["plugins"] = { "chromecast": { "receiverAppID": "96BDEA07" } }

        return options
    }

    constructor(roomStatusNotifier: RoomStatusNotifier) {
        super(roomStatusNotifier, false)
    }
}
