import { isiOS, isSafari, isUsingPwa } from "@multimediallc/web-utils/modernizr"
import { ReactComponentRegistry } from "../cb/components/ReactRegistry"
import { pageContext } from "../cb/interfaces/context"
import { currentSiteSettings } from "../cb/siteSettings"
import { applyStyles } from "./DOMutils"
import { isPwaNotificationActive } from "./featureFlagUtil"
import { dom } from "./tsxrender/dom"
import { anyTypingInputBlurred, anyTypingInputFocused } from "./virtualKeyboardUtil"
import type { ReactComponent } from "../cb/components/ReactRegistry"
import type { Duration } from "@multimediallc/web-utils"

let pwaNotification: ReactComponent | undefined
const reactRoot = <div />

export enum PwaNotificationSource {
    homepage = "Homepage",
    follow = "Follow",
}

function initInputFocusHiding(): void {
    // Notifications can appear from the bottom of the screen which can
    // disrupt a user's ability to see their messages while typing,
    // so toggle the root display for these cases
    anyTypingInputFocused.listen(() => {
        applyStyles(reactRoot, { display: "none" })
    })

    anyTypingInputBlurred.listen(() => {
        applyStyles(reactRoot, { display: "" })
    })
}

initInputFocusHiding()

export function schedulePwaNotification(source: PwaNotificationSource, delay?: Duration): void {
    const forceParam = "force_pwa_notif"
    const force = new URLSearchParams(window.location.search).get(forceParam) === "1"
    const trueDelay = force ? undefined : delay
    const canShow = isPwaNotificationActive()
        && pageContext.current.isMobile
        && isiOS()
        && isSafari()
        && !currentSiteSettings.isWhiteLabel
        && !isUsingPwa()

    if (!force && !canShow) {
        return
    }
    if (pwaNotification !== undefined) {
        pwaNotification.update({ delay: trueDelay, source })
        return
    }

    document.body.appendChild(reactRoot)
    const PwaNotification = ReactComponentRegistry.get("PwaNotification")
    pwaNotification = new PwaNotification({ delay: trueDelay, source }, reactRoot)
}
