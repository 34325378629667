import { UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb } from "../../../common/api"
import { isAnonymous } from "../../../common/auth"
import { handlePrivateMessage } from "../../../common/chatconnection/messagehandler"
import { type IChatConnection, type IRoomContext, roomCleanup, roomLoaded } from "../../../common/context"
import { EventRouter, ListenerGroup } from "../../../common/events"
import { featureFlagIsActive } from "../../../common/featureFlag"
import { isReactDMActive } from "../../../common/featureFlagUtil"
import { ignoreCatch } from "../../../common/promiseUtils"
import { buildQueryString } from "../../../common/urlUtil"
import { userInitiatedPm, userModeratorStatusChanged } from "../../../common/userActionEvents"
import { sendConversationRead } from "../../api/conversationReadSender"
import {
    dmsEnabled,
    getUserInfo,
    parseConversationListItemResponse,
} from "../../api/pm"
import { pmMediaDeleted, pmMediaRejected } from "../../api/pmMedia"
import { pageContext } from "../../interfaces/context"
import { RoomFanClubJoinedTopic, RoomKickTopic } from "../../pushservicelib/topics/room"
import { UserChatMediaOpenedTopic, UserChatMediaRemovedTopic, UserMessageTopic, UserPmReadTopic } from "../../pushservicelib/topics/user"
import { isRoomRoomlistSpaEligiblePage } from "../roomlist/spaHelpers"
import { DMUnreadData } from "./dmUnreadData"
import { createDmWindowRequest, DmWindowsManager, removeDmWindowRequest } from "./dmWindowsManager"
import { MediasetThumbnails } from "./mediasetThumbnails"
import { allDmsRead, allPmsRead, closePmSession, directMessage, privateMessage } from "./userActionEvents"
import type { IDmsReadInfo } from "./userActionEvents"

import type { IChatMediaOpened, IChatMediaRemoved, IPmReadTopic, IPrivateMessage, IPushPrivateMessage } from "../../../common/messageInterfaces"
import type { IUserModeratorStatus } from "../../../common/userActionEvents"
import type { IConversationListItem, IUserInfoAndUnread } from "../../api/pm"

export class AdjustedUserList {
    private showList = new Set<string>()
    private hideList = new Set<string>()

    show(username: string): void {
        this.showList.add(username)
        this.hideList.delete(username)
    }

    hide(username: string): void {
        this.hideList.add(username)
        this.showList.delete(username)
    }

    remove(username: string): void {
        this.showList.delete(username)
        this.hideList.delete(username)
    }

    isShowing(username: string): boolean {
        return this.showList.has(username)
    }

    isHiding(username: string): boolean {
        return this.hideList.has(username)
    }

    have(username: string): boolean {
        return this.showList.has(username) || this.hideList.has(username)
    }

    clear(): void {
        this.showList.clear()
        this.hideList.clear()
    }
}

export const adjustedUserList = new AdjustedUserList()

export class ConversationListData {
    public static conversationDataChanged = new EventRouter<IRoomContext | undefined>("conversationDataChanged")
    public static conversationItemAdded = new EventRouter<IConversationListItem>("conversationItemAdded")
    public static unreadConversationsCountUpdate = new EventRouter<{ dmsCount: number, pmsCount: number }>("unreadConversationsCountUpdate")
    public static conversationRead = new EventRouter<{ username: string, isDm: boolean }>("conversationRead")
    public static conversationLoaded = new EventRouter<IConversationListItem>("conversationLoaded")

    private static instance?: ConversationListData
    private roomListeners: ListenerGroup
    private room: string
    private chatConnection?: IChatConnection
    private dmUnreadData?: DMUnreadData
    private isRefactorDMFlagActive: boolean = featureFlagIsActive("RefactorDm")

    // Conversations in this.pms/dms are stored in reverse order because Map.set
    // for new conversations adds them to the end of this.dms/pms
    private pms = new Map<string, IConversationListItem>()
    private dms = new Map<string, IConversationListItem>()

    public static initialDMFetchCompleteEvent = new EventRouter<void>("initialDMFetchComplete")
    private initialDMFetchComplete = false

    private constructor() {
        this.room = ""
        this.roomListeners = new ListenerGroup()

        this.listenToMessagePushTopics()
        closePmSession.listen(username => {
            allPmsRead.fire(username)
        })
        allPmsRead.listen((username: string) => {
            this.markAsRead(username, false)
            if (pageContext.current.mergePmDm) {
                this.markAsRead(username, true)
            }
        })
        allDmsRead.listen((info: IDmsReadInfo) => {
            this.markAsRead(info.username, true)
            if (pageContext.current.mergePmDm) {
                this.markAsRead(info.username, false)
            }
        })
        directMessage.listen((dm) => {
            this.handleIncomingMessage(dm, true).catch(ignoreCatch)
        })
        privateMessage.listen((pm) => {
            this.handleIncomingMessage(pm, false).catch(ignoreCatch)
        })
        roomLoaded.listen((context: IRoomContext) => {
            this.room = context.dossier.room
            this.chatConnection = context.chatConnection
            this.pms.clear()
            this.removeEmptyDms()
            for (const dm of this.dms.values()) {
                dm.otherUser.isBroadcaster = dm.otherUser.username === this.room
            }
            if (ConversationListData.instance !== undefined) {
                ConversationListData.conversationDataChanged.fire(context)
            }
            if (pageContext.current.loggedInUser?.username !== this.room) {
                // prefetch broadcaster info
                getUserInfo(this.room, this.room).then((otherUser: IUserInfoAndUnread) => {
                    if (otherUser.user.username === this.room) {
                        this.addConversation({
                            message: "",
                            numUnread: 0,
                            time: Date.now(),
                            fromUsername: this.room,
                            otherUser: otherUser.user,
                            hasMedia: false,
                            room: this.room,
                        })
                    }
                }).catch(ignoreCatch)
            }
            this.listenToRoomPushTopics(context.dossier.roomUid)
        })
        roomCleanup.listen(() => {
            this.roomListeners.removeAll()
        })
        userInitiatedPm.listen((notification) => {
            if (!notification.focus) {
                return
            }

            if (this.pms.has(notification.username)) {
                ConversationListData.conversationDataChanged.fire(undefined)
            } else {
                getUserInfo(notification.username, this.room).then((otherUser: IUserInfoAndUnread) => {
                    this.addConversation({
                        message: "",
                        numUnread: 0,
                        time: Date.now(),
                        fromUsername: this.room,
                        otherUser: otherUser.user,
                        hasMedia: false,
                        room: this.room,
                    })
                }).catch(ignoreCatch)
            }
        })
        userModeratorStatusChanged.listen((userModeratorStatus: IUserModeratorStatus) => {
            let dataChanged = false
            const pm = this.pms.get(userModeratorStatus.username)
            if (pm !== undefined) {
                pm.otherUser.isMod = userModeratorStatus.isMod
                dataChanged = true
            }
            if (pageContext.current.loggedInUser?.username === this.room) {
                const dm = this.dms.get(userModeratorStatus.username)
                if (dm !== undefined) {
                    dm.otherUser.isMod = userModeratorStatus.isMod
                    dataChanged = true
                }
            }
            if (dataChanged) {
                ConversationListData.conversationDataChanged.fire(undefined)
            }
        })
        ConversationListData.conversationLoaded.listen((newConversation) => {
            const existingConversation = this.getConversation(
                newConversation.otherUser.username,
                newConversation.room === undefined || newConversation.room === "",
            )
            // add message list item if it doesn't exist, update existing message list item if it's just a placeholder
            const existingConversationIsEmpty = (existingConversation?.message ?? "") === "" && !(existingConversation?.hasMedia ?? false)
            const newConversationIsEmpty = newConversation.message === "" && !newConversation.hasMedia
            if (existingConversationIsEmpty && !newConversationIsEmpty) {
                this.addConversation(newConversation)
            }
        })

        if (isRoomRoomlistSpaEligiblePage()) {
            UrlState.current.listenGlobal(["pageType"], () => {
                this.room = ""
            })
        }

        if (this.isRefactorDMFlagActive) {
            this.dmUnreadData = DMUnreadData.getInstance()
        } else {
            this.getMessageThreads()
        }
    }

    /**
     * Returns an instance of the class.
     */
    static getInstance(): ConversationListData {
        if (ConversationListData.instance === undefined) {
            ConversationListData.instance = new ConversationListData()
        }
        return ConversationListData.instance
    }

    public getMessageThreads(): void {
        this.fetchInitialDms().finally(() => {
            // put listener here so the opened conversation's preview placeholder gets added to the top of DM list
            createDmWindowRequest.listen((username: string) => {
                const existingDm = this.getConversation(username, true)
                if (existingDm === undefined) {
                    getUserInfo(username).then((otherUser: IUserInfoAndUnread) => {
                        this.addConversation({
                            message: "",
                            numUnread: 0,
                            time: Date.now(),
                            fromUsername: username,
                            otherUser: otherUser.user,
                            hasMedia: false,
                            room: "",
                        })
                    }).catch(ignoreCatch)
                }
            })
        }).catch(ignoreCatch)
    }

    private setInitialDMFetchComplete(): void {
        this.initialDMFetchComplete = true
        ConversationListData.initialDMFetchCompleteEvent.fire()
    }

    public isInitialDMFetchComplete(): boolean {
        return this.initialDMFetchComplete
    }

    private fetchInitialDms(): Promise<void> {
        if (!dmsEnabled() || isAnonymous()) {
            return Promise.resolve()
        }

        const queryString = buildQueryString({
            "offset": "0",
            ...this.isRefactorDMFlagActive && isReactDMActive() ? { "limit": "20" } : {},
        })
        const url = this.isRefactorDMFlagActive ? `api/messaging/threads/?${queryString}` : `api/ts/chatmessages/pm_users/?${queryString}`
        
        return getCb(url).then((xhr: XMLHttpRequest) => {
            this.setInitialDMFetchComplete()
            
            const response = JSON.parse(xhr.responseText)
            const threads = this.isRefactorDMFlagActive && isReactDMActive() ? response.threads : response
            
            threads.reverse().forEach((item: object) => {
                const parsedItem = parseConversationListItemResponse(new ArgJSONMap(item))
                this.addConversation({ ...parsedItem, room: "" })
            })
            
            ConversationListData.conversationDataChanged.fire(undefined)
        }).catch((err) => {
            error("Error fetching dm conversations", err)
        })
    }

    public getDms(): IConversationListItem[] {
        // Reverse to sort from most recent to oldest
        return Array.from(this.dms.values()).reverse()
    }

    public getPms(): IConversationListItem[] {
        // Reverse to sort from most recent to oldest
        return Array.from(this.pms.values()).reverse()
    }

    private async handleIncomingMessage(message: IPrivateMessage, isDm: boolean): Promise<void> {
        const oldConversation = this.removeConversation(message.otherUsername, isDm)
        let otherUser, numUnread
        if (oldConversation === undefined) {
            const userInfoAndUnread = await getUserInfo(message.otherUsername, isDm ? undefined : this.room)
            otherUser = userInfoAndUnread.user
            // pms start counting unread from 0, even if there are more unreads, unless mergePmDm flag is on
            numUnread = isDm || pageContext.current.mergePmDm ? userInfoAndUnread.numUnread : 1
        } else {
            otherUser = oldConversation.otherUser
            numUnread = oldConversation.numUnread + 1
        }

        if (message.fromUser.username !== message.otherUsername) {
            numUnread = 0
        }

        this.incrUnreadDMCount(isDm, numUnread, oldConversation, message.otherUsername)

        this.addConversation({
            message: message.message,
            time: Date.now(),
            numUnread: numUnread,
            fromUsername: message.fromUser.username,
            otherUser: otherUser,
            hasMedia: message.mediaList.length > 0,
            room: isDm ? "" : this.room,
            origMessage: message,
        })
    }

    private addConversation(conversation: IConversationListItem): void {
        const isDm = conversation.room === ""
        const conversations = isDm ? this.dms : this.pms
        // JS Maps are ordered by initial `set`. Delete before setting so that the conversation's position in the map updates
        conversations.delete(conversation.otherUser.username)
        conversations.set(conversation.otherUser.username, conversation)

        ConversationListData.conversationItemAdded.fire(conversation)
        if (conversation.fromUsername === pageContext.current.loggedInUser?.username) {
            ConversationListData.conversationRead.fire({ username: conversation.otherUser.username, isDm: isDm })
        }
        if (this.isRefactorDMFlagActive && isDm) {
            // Do not update unread count for DMs
            return
        }
        this.updateUnreadCount()
    }

    public getConversation(username: string, useDms = false): IConversationListItem | undefined {
        const conversations = useDms ? this.dms : this.pms
        return conversations.get(username)
    }

    private removeConversation(username: string, useDms: boolean): IConversationListItem | undefined {
        const conversations = useDms ? this.dms : this.pms
        const removedConversation = conversations.get(username)
        conversations.delete(username)
        return removedConversation
    }

    private removeEmptyDms(): void {
        const currentDmWindowUsername = DmWindowsManager.getInstance()?.shownWindowUsername()
        this.dms.forEach((dm, username) => {
            if (dm.message === "" && !dm.hasMedia && username !== currentDmWindowUsername) {
                this.dms.delete(username)
            }
        })
    }

    private markAsRead(username: string, isDm: boolean, noUpdate = false): void {
        const conversations = isDm ? this.dms : this.pms
        const conversation = conversations.get(username)

        if (conversation === undefined || conversation.numUnread === 0) {
            return
        }

        conversation.numUnread = 0
        if (!this.isRefactorDMFlagActive || !isDm) {
            this.updateUnreadCount()
        }
        ConversationListData.conversationRead.fire({ username, isDm })
        if (this.isRefactorDMFlagActive && isDm) {
            this.dmUnreadData?.removeUnreadRecipient(username)
        }

        if (!noUpdate) {
            sendConversationRead(username, isDm)
        }
    }

    private updateUnreadCount(): void {
        ConversationListData.unreadConversationsCountUpdate.fire({
            dmsCount: [...this.dms.values()].filter(dm => dm.numUnread > 0).length,
            pmsCount: [...this.pms.values()].filter(pm => pm.numUnread > 0).length,
        })
    }

    private incrUnreadDMCount(isDm: boolean, numUnread: number, oldConversation: IConversationListItem | undefined, otherUsername: string): void {
        if (this.isRefactorDMFlagActive && isDm && numUnread > 0 && (!oldConversation || oldConversation.numUnread === 0)) {
            this.dmUnreadData?.addUnreadRecipient(otherUsername)
        }
    }

    private isPrivateShowMessage(m: IPushPrivateMessage): boolean {
        const privateShowUser = this.chatConnection?.getPrivateShowUser() ?? ""

        return this.chatConnection?.inPrivateRoom() === true
            && (m.otherUsername === privateShowUser || m.otherUsername === this.room)
            && m.room !== ""
    }

    private listenToMessagePushTopics(): void {
        const userUid = pageContext.current.loggedInUser?.userUid
        if (userUid !== undefined) {
            new UserMessageTopic(userUid).onMessage.listen((m: IPushPrivateMessage) => {
                if (!this.isPrivateShowMessage(m) || this.chatConnection === undefined) {
                    handlePrivateMessage(m, this.room, m.room)
                    return
                }

                // inPrivateRoom() excludes spyers so we are either the bcaster or the private show user at this point
                if (m.otherUsername === this.chatConnection.getPrivateShowUser() || m.otherUsername === this.room) {
                    const privateShowMessage = { ...m, isPrivateShowMessage: true }
                    handlePrivateMessage(privateShowMessage, this.room, m.room)
                }
            })

            new UserChatMediaOpenedTopic(userUid).onMessage.listen((data: IChatMediaOpened) => {
                MediasetThumbnails.markOpened(data.messageId, data.mediaId)
                this.pms.forEach((pm) => {
                    if (pm.hasMedia) {
                        pm.origMessage?.mediaList.forEach((media) => {
                            if (media.mediaId === data.mediaId) {
                                media.opened = true
                            }
                        })
                    }
                })
            })

            new UserChatMediaRemovedTopic(userUid).onMessage.listen((data: IChatMediaRemoved) => {
                if (data.isCompliance) {
                    pmMediaRejected.fire(data.mediaId)
                } else {
                    pmMediaDeleted.fire(data.mediaId)
                }
            })

            new UserPmReadTopic(userUid).onMessage.listen((data: IPmReadTopic) => {
                if (pageContext.current.mergePmDm) {
                    this.markAsRead(data.otherUsername, true, true)
                    this.markAsRead(data.otherUsername, false, true)
                } else if (data.room === "" || data.room === undefined) {
                    this.markAsRead(data.otherUsername, true, true)
                } else if (data.room === this.room) {
                    this.markAsRead(data.otherUsername, false, true)
                }
            })
        }
    }

    private listenToRoomPushTopics(roomUid: string): void {
        new RoomFanClubJoinedTopic(roomUid).onMessage.listen((msg) => {
            let dataChanged = false
            const pm = this.pms.get(msg.fromUser.username)
            if (pm !== undefined) {
                pm.otherUser.inFanclub = true
                dataChanged = true
            }
            if (pageContext.current.loggedInUser?.username === this.room) {
                const dm = this.dms.get(msg.fromUser.username)
                if (dm !== undefined) {
                    dm.otherUser.inFanclub = true
                    dataChanged = true
                }
            }
            if (dataChanged) {
                ConversationListData.conversationDataChanged.fire(undefined)
            }
        }).addTo(this.roomListeners)

        new RoomKickTopic(roomUid).onMessage.listen(msg => {
            const currentUsername = pageContext.current.loggedInUser?.username
            if (currentUsername === this.room) {
                removeDmWindowRequest.fire({ username: msg.username })
            } else if (currentUsername === msg.username) {
                removeDmWindowRequest.fire({ username: this.room })
            }
        }).addTo(this.roomListeners)
    }
}
