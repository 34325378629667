import { isiOS, isiOSPwa, isSafari } from "@multimediallc/web-utils/modernizr"
import { loadIgnoreList } from "../../cb/api/ignore"
import { MobileDmWindow } from "../../cb/components/pm/dmWindow"
import {
    createDmWindowRequest,
    removeDmWindowRequest,
} from "../../cb/components/pm/dmWindowsManager"
import { bindDmWindowsPushEvents } from "../../cb/components/pm/dmWindowUtils"
import { directMessage } from "../../cb/components/pm/userActionEvents"
import { cssVhVarUpdate } from "../../cb/util/cssVhVarUtil"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { Debouncer, DebounceTypes } from "../debouncer"
import { DefDeck } from "../defDeck"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { isPwaNotificationActive } from "../featureFlagUtil"
import { styleTransition } from "../safeStyle"
import { dom } from "../tsxrender/dom"
import { BackButton } from "./mobileDmsMenu"
import { iosPwaBottomMargin } from "./portraitContents"
import { siteHeaderMenuOpened } from "./userActionEvents"
import type { IPushPrivateMessage } from "../messageInterfaces"

interface IMobileDmWindowManagerProps {
    myUsername: string
    onShow: () => void
    onHide: () => void
}

export class MobileDmWindowManager extends Component<HTMLDivElement, IMobileDmWindowManagerProps> {
    private myUsername: string
    private allDmWindowsMap: Map<string, MobileDmWindow>
    private orderedDmSessionKeys: DefDeck<string>
    private isTouchingScreen: boolean
    private onShow: () => void
    private onHide: () => void

    constructor(props: IMobileDmWindowManagerProps) {
        super("div", props)
        loadIgnoreList()
    }

    protected initData(props: IMobileDmWindowManagerProps): void {
        this.myUsername = props.myUsername
        this.allDmWindowsMap = new Map()
        this.orderedDmSessionKeys = new DefDeck<string>()
        this.isTouchingScreen = false
        this.onShow = props.onShow
        this.onHide = props.onHide
    }

    protected initUI(props: IMobileDmWindowManagerProps): void {
        const mobileDmWindowManagerStyle: CSSX.Properties = {
            width: "320px",
            height: isPwaNotificationActive() && isiOSPwa()
                ? `calc(var(--vh, 1vh) * 100 - ${iosPwaBottomMargin}px)`
                : "calc(var(--vh, 1vh) * 100)",
            position: "absolute",
            top: 0,
            bottom: 0,
            left: 0,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
        }

        this.element = 
            <div className="MobileDmWindowManager" style={mobileDmWindowManagerStyle}>
                <BackButton onClick={() => {this.handleBackButtonClick()}} />
            </div>
        

        styleTransition(this.element, "transform 150ms ease")
        this.bindMenuChangeEvents()
        this.bindListeners()
        this.bindWindowListeners()
        this.initVirtualKeyboardHandling()
        this.hideElement()
    }

    public showElement(): void {
        applyStyles(this.element, { transform: "translateX(0)" })
        this.onShow()
    }

    public hideElement(): void {
        applyStyles(this.element, { transform: "translateX(-100%)" })
        this.onHide()
        const currentWindowUser = this.orderedDmSessionKeys.currentElem

        if (currentWindowUser !== undefined) {
            this.hideWindow(currentWindowUser)
        }
    }

    private bindListeners(): void {
        createDmWindowRequest.listen((username: string) => {
            this.showElement()
            const exisitingWindow = this.allDmWindowsMap.get(username)

            if (exisitingWindow === undefined) {
                this.createWindow(username)
            }

            this.showWindow(username)
        })

        removeDmWindowRequest.listen(({ username, deleteWindow }) => {
            const currentOpenWindow = this.getCurrentOpenWindow()
            if (currentOpenWindow?.username === username) {
                this.removeShownWindow()
            }
            if (deleteWindow === true) {
                this.allDmWindowsMap.delete(username)
            }
        })

        directMessage.listen((dmData: IPushPrivateMessage) => {
            const receivingWindow = this.allDmWindowsMap.get(dmData.otherUsername)

            if (receivingWindow === undefined) {
                return
            }

            receivingWindow.handleNewMessage(dmData)
        })

        bindDmWindowsPushEvents(username => this.allDmWindowsMap.get(username))
    }

    private createWindow(username: string): void {
        const inMap = this.allDmWindowsMap.has(username)

        if (!inMap) {
            const newWindow = new MobileDmWindow({
                username: username,
                myUsername: this.myUsername,
                open: true,
                markAsRead: true,
                raiseWindowZIndexToTop: () => {},
            })
            this.allDmWindowsMap.set(username, newWindow)
            this.orderedDmSessionKeys.addToTop(username)
            this.addChild(newWindow)
        }
    }

    private removeShownWindow(): void {
        const currentOpenWindow = this.getCurrentOpenWindow()

        if (currentOpenWindow === undefined) {
            return
        }

        currentOpenWindow.removeFromDOM()
        this.orderedDmSessionKeys.remove(currentOpenWindow.username)
        this.handleBackButtonClick()
    }

    // Only shows already existing windows.
    // Use `createWindow` first if necessary.
    private showWindow(username: string) {
        const currentOpenWindow = this.getCurrentOpenWindow()

        if (currentOpenWindow !== undefined) {
            currentOpenWindow.openOrCollapseWindow(false)
        }

        const existingWindow = this.allDmWindowsMap.get(username)

        if (existingWindow !== undefined) {
            this.orderedDmSessionKeys.makeCurrent(username)
            existingWindow.openOrCollapseWindow(true)
        }
    }

    private getCurrentOpenWindow(): MobileDmWindow | undefined {
        const currentWindow = this.allDmWindowsMap.get(this.orderedDmSessionKeys.currentElem ?? "")
        return currentWindow?.isWindowOpen() === true ? currentWindow : undefined
    }

    private hideWindow(username: string) {
        const window = this.allDmWindowsMap.get(username)

        if (window !== undefined) {
            window.openOrCollapseWindow(false)
        }
    }

    private handleBackButtonClick(): void {
        this.hideElement()
    }

    private bindMenuChangeEvents(): void {
        siteHeaderMenuOpened.listen((isOpen: boolean) => {
            if (!isOpen) {
                this.hideElement()
            }
        })
    }

    private blurInputIfFocused(): void {
        const currentOpenDmWindow = this.getCurrentOpenWindow()

        if (currentOpenDmWindow?.isInputFocused() === true && document.activeElement instanceof HTMLElement) {
            document.activeElement.blur()
        }
    }

    private bindWindowListeners(): void {
        let windowWidth = window.innerWidth

        const scrollToBottomOnOrientationChange = () => {
            const currentOpenDmWindow = this.getCurrentOpenWindow()
            const orientationChanged = window.innerWidth !== windowWidth

            if (orientationChanged) {
                currentOpenDmWindow?.scrollToBottom()
                windowWidth = window.innerWidth

                // iOS doesn't render messages immediately if user starts scroll, lets go,
                // and immediately changes orientation
                window.setTimeout(() => {
                    currentOpenDmWindow?.scrollToBottom()
                }, 300)

                this.blurInputIfFocused()
            }
        }

        const scrollToBottomDelay = () => {
            // iOS Chrome doesn't immediately set `window.innerWidth` on resize/orientation change
            window.setTimeout(scrollToBottomOnOrientationChange, 125)
        }

        addEventListenerPoly("resize", window, () => {
            if (isiOS()) {
                if (isSafari()) {
                    scrollToBottomOnOrientationChange()
                } else {
                    scrollToBottomDelay()
                }
            } else {
                const currentOpenDmWindow = this.getCurrentOpenWindow()
                currentOpenDmWindow?.resizeTipCallout()
            }
        })

        addEventListenerPoly("touchstart", window, () => {
            this.isTouchingScreen = true
        })

        addEventListenerPoly("touchend", window, () => {
            this.isTouchingScreen = false
        })

        // Some browsers show weird behavior (like scrolling underneath the viewport) when scrolling around
        // the page while the keyboard is open. Blur the input when the viewport scrolls from user interaction.
        window.visualViewport?.addEventListener("scroll", () => {
            if (this.isTouchingScreen) {
                this.blurInputIfFocused()
            }
        })
    }

    private initVirtualKeyboardHandling(): void {
        // Prevent the virtual keyboard from pushing this element off screen
        const topSpamPeriodMS = 1000
        const resetTop = () => {
            this.element.style.top = `${window.visualViewport?.offsetTop}px`
            // An indeterminate delay can be needed before top can be set correctly, so spam setting top for a bit
            const setTopInterval = window.setInterval(() => {
                this.element.style.top = `${window.visualViewport?.offsetTop}px`
            }, 100)
            window.setTimeout(() => {
                window.clearInterval(setTopInterval)
            }, topSpamPeriodMS)
        }
        const resetTopDebouncer = new Debouncer(resetTop, { bounceLimitMS: topSpamPeriodMS, debounceType: DebounceTypes.trailThrottle })
        cssVhVarUpdate.listen(() => { resetTopDebouncer.callFunc() })
        addEventListenerPoly("scroll", document, () => { resetTopDebouncer.callFunc() })
    }
}
