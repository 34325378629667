import { t } from "@lingui/macro"
import { isMobileDevice } from "@multimediallc/web-utils/modernizr"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { EventRouter } from "../../../common/events"
import { isEnterKey } from "../../../common/eventsUtil"
import { addPageAction } from "../../../common/newrelic"
import { dom } from "../../../common/tsxrender/dom"
import { pageContext } from "../../interfaces/context"
import { ResultType, SearchBlur } from "./constants"
import { saveSearchHistory } from "./searchHistory"

export const SuggestionClicked = new EventRouter<string>("SuggestionClicked")

const suggestionIconStyle: CSSX.Properties = {
    display: "inline-block",
    position: "relative",
    top: "6px",
    height: "18px",
    padding: "5px 10px",
    width: "18px",
}

const suggestionLabelStyle: CSSX.Properties = {
    display: "inline-block",
    margin: "0px",
    position: "relative",
}

const suggestionRowStyle: CSSX.Properties = {
    borderBottomStyle: "solid",
    borderBottomWidth: "1px",
    cursor: "pointer",
    height: "42px",
    position: "relative",
}

const linkStyle: CSSX.Properties = { textDecoration: "none" }

const labelContainerStyle: CSSX.Properties = {
    display: "inline-block",
    position: "absolute",
    top: "6px",
    height: "35px",
    width: isMobileDevice() ? "calc(100% - 38px)" : "calc(100% - 27px)",
}

const boldTextStyle: CSSX.Properties = {
    font: "12px UbuntuBold, Arial, Helvetica, sans-serif",
    marginRight: "0px",
}

const regularTextStyle: CSSX.Properties = {
    font: "12px UbuntuRegular, Arial, Helvetica, sans-serif",
    marginRight: "0px",
}

export interface ISuggestionProps {
    labelText: string
    input: string
    count: number
    href: string
    resultType: ResultType
}

function translateSearchKeywords(search: string): string {
    return t`Search "${search}"`
}

const KEYWORDS_BOLD_START = translateSearchKeywords("").length - 1

export class BaseSuggestedItem extends HTMLComponent<HTMLAnchorElement, ISuggestionProps> {
    protected getIconClassName(): string {
        return ""
    }

    protected getStatusText(): string {
        return ""
    }

    protected getRowClassName(): string {
        return ""
    }

    protected createLabelElements(props: ISuggestionProps): HTMLElement[] {
        let labelText = props.labelText
        let text = props.input
        if (props.resultType === ResultType.Keywords) {
            labelText = translateSearchKeywords(text)
        } else if (labelText.startsWith("#")) {
            text = text.replace(/%23/g, "#")
        } else {
            text = text.replace(/#/g, "")
        }

        const boldIndex = props.resultType === ResultType.Keywords
            ? KEYWORDS_BOLD_START
            : labelText.toLowerCase().indexOf(text.toLowerCase())
        const nonBoldText = boldIndex >= 0 ? labelText.slice(boldIndex, boldIndex + text.length) : labelText
        const preBoldText = boldIndex > 0 ? labelText.slice(0, boldIndex) : ""
        const postBoldText = boldIndex + text.length < labelText.length && boldIndex >= 0 ? labelText.slice(boldIndex + text.length) : ""
        return [
            <span style={boldTextStyle}>{preBoldText}</span>,
            <span style={regularTextStyle}>{nonBoldText}</span>,
            <span style={boldTextStyle}>{postBoldText}</span>,
            <div className="statusText">{this.getStatusText()}</div>,
        ]
    }

    protected createElement(props: ISuggestionProps): HTMLAnchorElement {
        return <a
            style={linkStyle}
            href={props.href}
            tabIndex={-1}
            onKeyDown = {(evt: KeyboardEvent) => {
                if (isEnterKey(evt)) {
                    // Tab, select, then enter is treated as a Search Result click.
                    this.onClickHandler(props)
                }
            }}
            onContextMenu={(event: MouseEvent) => {
                if (isMobileDevice() || pageContext.current.isMobile) {
                    event.preventDefault()
                    return false
                }
                return true
            }}
            onBlur={(evt: FocusEvent) => {SearchBlur.fire(evt)}}
            onClick={(evt: MouseEvent) => {
                if (props.href === "") {
                    evt.preventDefault()
                }
                this.onClickHandler(props, evt)
            }}>
            <div className={this.getRowClassName()} style={suggestionRowStyle}>
                <div className={this.getIconClassName()} style={suggestionIconStyle}></div>
                <div style={labelContainerStyle}><p className={this.getRowClassName()} style={suggestionLabelStyle}>
                    {this.createLabelElements(props)}
                </p></div>
            </div>
        </a>
    }

    protected onClickHandler(props: ISuggestionProps, evt?: MouseEvent): void {
        saveSearchHistory(props.labelText, ResultType.Online)
        const attributes = { "resultType": "broadcaster", "resultString": props.labelText }
        addPageAction("SearchBarResultsClick", attributes)
    }
}
