import { SubSystemType } from "../../../common/debug"
import { PushService } from "../pushService"
import type { ISubscribeChange, ITopicMessage, Topic } from "./base"
import type { ArgJSONMap, NewrelicAttributes } from "@multimediallc/web-utils"

export interface IPushPresencePayload {
    topic: Topic<ITopicMessage>
    changed: boolean
    data: object
}

export interface ITopicAuthData {
    key: string,
    payload: object,
    subscribeOnlyOnPrimaryBackend: boolean,
}

export class TopicManager {
    private static topics = new Map<string, Topic<ITopicMessage>[]>()
    private static presence = new Map<string, IPushPresencePayload>()
    private static pending = new Map<string, Topic<ITopicMessage>>()
    private static missingTopics = new Set<string>()
    private static unsubscribedTracker = new Map<string, Date>()
    private static unsubscribedLimit = 100

    public static fireMessage(topicKey: string, data: ArgJSONMap): void {
        const topics = TopicManager.topics.get(topicKey)
        if (topics === undefined) {
            if (TopicManager.missingTopics.has(topicKey)) {
                return
            }
            TopicManager.missingTopics.add(topicKey)
            const attributes: NewrelicAttributes = { "topicKey": topicKey, "client": PushService.getClientName() }
            if (topicKey !== undefined && topicKey.includes(":")){
                attributes["topic"] = topicKey.split(":")[0]
                attributes["userId"] = topicKey.split(":")[1]
                attributes["subscribedTopics"] = TopicManager.getTopicKeys().toSorted().toString()
            }
            const lastListened = TopicManager.unsubscribedTracker.get(topicKey)
            if (lastListened !== undefined) {
                attributes["timeSinceListen"] = new Date().getTime() - lastListened.getTime()
            }
            warn("calling fireMessage with no topics in TopicManager", attributes, SubSystemType.PushService)
            return
        }
        topics.forEach((topic) => {
            topic.onMessage.fire(topic.parseData(data))
        })
        data.logUnusedDebugging(topicKey)
    }

    public static fireMessageDirect(topic: Topic<ITopicMessage>, data: ITopicMessage): void {
        const topics = TopicManager.topics.get(topic.getKey())
        if (topics === undefined) {
            warn("calling fireMessageDirect with no topics in TopicManager", {
                topicKey: topic.getKey(),
                client: PushService.getClientName(),
            }, SubSystemType.PushService)
            return
        }
        topics.forEach((topic) => {
            topic.onMessage.fire(data)
        })
    }

    public static fireSubscribeChange(topicKey: string, event: ISubscribeChange): void {
        TopicManager.topics.get(topicKey)?.forEach((topic) => {
            topic.onSubscribeChange.fire(event)
        })
    }

    public static fireAuthFail(topicKey: string): void {
        TopicManager.topics.get(topicKey)?.forEach((topic) => {
            topic.onAuthFail.fire(undefined)
        })
    }

    public static registerTopic(topic: Topic<ITopicMessage>): void {
        let topics = TopicManager.topics.get(topic.getKey())
        if (topics === undefined) {
            topics = [topic]
        } else {
            if (topics.length > topic.maxListeners) {
                warn("Too many instances of topic created", {
                    topic: topic.getKey(),
                    num_listeners: topics.length,
                }, SubSystemType.PushService)
            }
            topics.push(topic)
        }
        TopicManager.topics.set(topic.getKey(), topics)
        PushService.updateAuthorization()
    }

    public static removeTopic(topic: Topic<ITopicMessage>): void {
        const topics = TopicManager.topics.get(topic.getKey())
        if (topics !== undefined) {
            const index = topics.indexOf(topic)
            topics.splice(index, 1)
            if (topics.length < 1) {
                TopicManager.topics.delete(topic.getKey())
                TopicManager.pending.set(topic.getKey(), topic)
                if (TopicManager.unsubscribedTracker.size > TopicManager.unsubscribedLimit) {
                    const entries = Array.from(TopicManager.unsubscribedTracker.entries())
                    TopicManager.unsubscribedTracker = new Map(entries.splice(0, TopicManager.unsubscribedLimit / 2))
                }
                TopicManager.unsubscribedTracker.set(topic.getKey(), new Date())
                PushService.stopListeningFor(topic.getKey()).then(() => {
                    TopicManager.pending.delete(topic.getKey())
                }).catch(() => {})
            } else {
                TopicManager.topics.set(topic.getKey(), topics)
            }
        }
    }

    public static getTopicKeys(): string[] {
        const topicsWithListeners: string[] = Array.from(TopicManager.presence.keys())
        const hasListeners = (topic: Topic<ITopicMessage>) => {
            return topic.onMessage.listenerCount() > 0 || topic.onSubscribeChange.listenerCount() > 0
        }
        TopicManager.topics.forEach((topics, topicKey) => {
            if (topics.some(hasListeners)) {
                topicsWithListeners.push(topicKey)
            }
        })
        return topicsWithListeners
    }

    public static getPresenceKeys(): string[] {
        return Array.from(TopicManager.presence.keys())
    }

    public static getPendingTopics(): string[] {
        return Array.from(TopicManager.pending.keys())
    }

    public static hasPresence(topicKey: string): boolean {
        return TopicManager.presence.has(topicKey)
    }

    public static getPresence(topicKey: string): IPushPresencePayload | undefined {
        return TopicManager.presence.get(topicKey)
    }

    public static getTopic(topicKey: string): Topic<ITopicMessage> | undefined {
        const presenceTopic = TopicManager.presence.get(topicKey)
        if (presenceTopic !== undefined) {
            return presenceTopic.topic
        }
        const pending = TopicManager.pending.get(topicKey)
        if (pending !== undefined) {
            return pending
        }
        const topics = TopicManager.topics.get(topicKey)
        if (topics === undefined || topics.length === 0) {
            return undefined
        }
        return topics[0]
    }

    public static getTopicAuthData(topicKey: string): ITopicAuthData | undefined {
        const topic = TopicManager.getTopic(topicKey)
        if (topic === undefined) {
            return undefined
        }
        return {
            key: topic.getAuthKey(),
            payload: topic.getAuthData(),
            subscribeOnlyOnPrimaryBackend: topic.subscribeOnlyOnPrimaryBackend,
        }
    }

    public static enterPresence(topic: Topic<ITopicMessage>, payload: object = {}): void {
        TopicManager.presence.set(topic.getKey(), {
            changed: true,
            data: payload,
            topic: topic,
        })
        PushService.updateAuthorization()
    }

    public static leavePresence(topic: Topic<ITopicMessage>): void {
        const topicKey = topic.getKey()
        if (!TopicManager.presence.has(topicKey)) {
            return
        }
        TopicManager.presence.delete(topicKey)
        TopicManager.pending.set(topicKey, topic)
        PushService.stopListeningFor(topic.getKey()).then(() => {
            TopicManager.pending.delete(topicKey)
        }).catch(() => {})
    }
}
