import { EventRouter } from "../../../common/events"
import type { IPrivateMessage, IPushPrivateMessage } from "../../../common/messageInterfaces"

export const privateMessage = new EventRouter<IPrivateMessage>("privateMessage")
export const directMessage = new EventRouter<IPushPrivateMessage>("directMessage")
export const showPmSession = new EventRouter<string>("showPmSession") // Used via { openConversationEvent: showPmSession }
export const closePmSession = new EventRouter<string>("closePmSession")
export const allPmsRead = new EventRouter<string>("allPmsRead")
export const allDmsRead = new EventRouter<IDmsReadInfo>("allDmsRead")

export interface IUpdateSearchBarInfo {
    isFullVideo: boolean,
    isValid: boolean,
    prefix: string,
}
export interface INavigateSuggestionInfo {event: KeyboardEvent,}
export interface ISuggestionActiveInfo {slug: string,}
export interface IDmsReadInfo {username: string}
