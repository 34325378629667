import { updateWindowIsOpenEvent } from "../cb/components/pm/dmWindowsManager"
import { roomCleanup } from "./context"
import { PmNotifier } from "./pmNotifier"
import type { IDmWindow } from "../cb/components/pm/dmWindowsManager"

export class DmNotifier extends PmNotifier {
    constructor() {
        super()
    }

    protected initListeners(): void {
        updateWindowIsOpenEvent.listen((info: IDmWindow) => {
            if (info.isOpen) {
                this.dontNotify.add(info.username)
            }
        })

        roomCleanup.listen(() => {
            this.dontNotify.clear()
        })
    }
}
