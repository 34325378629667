import { startStreaming, stopStreaming } from "../../../roomList"
import { RoomCard } from "../roomCard"
import type { IRoomCardProps } from "../roomCard"

export const roomImageHeightRatio = 270 / 480
export const roomPadding = 4

export abstract class MoreRoomsCard extends RoomCard {
    protected roomTitle: HTMLDivElement
    protected roomLink: HTMLAnchorElement
    protected roomName: string
    constructor(props: IRoomCardProps) {
        super(props)
    }

    protected initData(props: IRoomCardProps): void {
        super.initData(props)
        this.roomName = props.roomInfo.room
    }

    protected afterRemovedFromParent(): void {
        this.stopStreaming()
        super.afterRemovedFromParent()
    }

    stopStreaming(): void {
        stopStreaming(this.roomName, true)
    }

    startStreaming(): void {
        startStreaming(this.roomName, this.img, true, true)
    }
}
