import { ArgJSONMap } from "@multimediallc/web-utils"
import { modalAlert } from "../../common/alerts"
import { postCb } from "../../common/api"
import { Component } from "../../common/defui/component"
import { i18n } from "../../common/translation"
import { addColorClass } from "../colorClasses"
import { MultiSelectDropdown } from "../components/profile/multiSelectDropdown"
import {
    CheckboxInput, DateSelect,
    Input,
    MultipleCheckbox,
    NoReCaptchaField,
    Select,
    TextArea,
} from "./fields"
import {
    CheckboxInputStyles,
    FieldStyles,
    InputStyles,
    MultipleCheckboxStyles,
    MultipleSelectStyles,
    NoRecaptchaStyles,
    SelectStyles,
    TextAreaStyles,
} from "./fieldStyles"
import type { Field, IChoices, IFieldOptions } from "./fields"
import type { XhrError } from "../../common/api"

export interface IDjangoFormData {
    extra: object,
    initial: object,
    schema: IFormSchema,
}

interface IFormSchema {
    fields: Record<string, IDjangoField>,
    prefix?: string,
    fieldsets?: IFieldset[],
    label_suffix?: string,
    field_order: string[],
}

export interface IDjangoField extends IFieldOptions {
    fieldType: string,
    helpText?: string,
    emptyValue?: string | number,
    choices?: IChoices[],
}

interface IFieldset {
    fields: string[],
    label: string,
}

export class DjangoFormParser {
    public static parseData(rawData: string): IDjangoFormData {
        const data = new ArgJSONMap(rawData)
        const intial = data.getObject("initial")
        const schemaMap = data.getMap("schema")

        const fieldsetsMap = schemaMap.getList("fieldsets")
        let parsedFieldset: IFieldset[]
        if (fieldsetsMap === undefined) {
            parsedFieldset = []
        } else {
            parsedFieldset = fieldsetsMap.map((fieldset) => {
                return {
                    fields: fieldset.getStringList("fields"),
                    label: fieldset.getString("label"),
                }
            })
        }

        const fieldsMap = JSON.parse(rawData)["schema"]["fields"]
        const parsedFields: Record<string, IDjangoField> = {}
        for (const fieldName of Object.keys(fieldsMap)) {
            const field = new ArgJSONMap(JSON.stringify(fieldsMap[fieldName]))
            // @ts-ignore not type checking django forms
            const defaultValue = intial[fieldName] === null ? undefined : intial[fieldName]
            parsedFields[fieldName] = DjangoFormParser.parseField(fieldName, field, defaultValue)
            field.logUnusedDebugging("parse DjangoForm field")
        }

        return {
            extra: data.getObject("extra"),
            initial: intial,
            schema: {
                prefix: schemaMap.getStringOrUndefined("prefix"),
                fields: parsedFields,
                fieldsets: parsedFieldset,
                label_suffix: schemaMap.getStringOrUndefined("label_suffix"),
                field_order: schemaMap.getStringList("field_order"),
            },
        }
    }

    private static parseField(fieldName: string, field: ArgJSONMap, defaultValue: number | boolean | string): IDjangoField {
        return {
            defaultValue: defaultValue,
            name: fieldName,
            minLength: field.getNumberOrUndefined("min_length"),
            maxLength: field.getNumberOrUndefined("max_length"),
            emptyValue: field.getStringOrUndefined("empty_value"),
            choices: DjangoFormParser.parseSelectOptions(field.getList("choices")),
            required: field.getBoolean("required"),
            disabled: field.getBoolean("disabled"),
            helpText: field.getString("help_text"),
            labelText: field.getString("label"),
            fieldType: field.getString("type"),
            siteKey: field.getStringOrUndefined("site_key"),
        }
    }

    private static parseSelectOptions(data: ArgJSONMap[] | undefined): IChoices[] {
        if (data === undefined) {
            return []
        }
        const options: IChoices[] = []
        for (const option of data) {
            options.push({
                label: option.getStringWithNumbers("label"),
                value: option.getStringWithNumbersOrBoolean("value"),
            })
        }
        return options
    }
}

interface IDjangoFormOptions {
    onSubmitSuccess: (formData: FormData) => void,
    onSubmitError: (err: XhrError) => void,
    styles: {
        field?: FieldStyles,
        checkbox?: FieldStyles,
        input?: FieldStyles,
        select?: FieldStyles,
        textarea?: FieldStyles,
        multipleCheckboxes?: FieldStyles,
        multipleSelect?: FieldStyles,
        noReCaptcha?: FieldStyles,
    },
}

export type IPartialDjangoFormOptions = Partial<IDjangoFormOptions> // makes every field optional

export class DjangoForm extends Component<HTMLFormElement> {
    private fields: Record<string, Field|undefined> = {}
    private fieldsets: Record<string, HTMLElement> = {}
    private tbody: HTMLTableSectionElement
    private submitButton: HTMLInputElement
    private options: IDjangoFormOptions
    private loading = false
    private endpoint: string
    private isAutoSaveForm: boolean
    private hasChanged: boolean

    constructor(protected data: IDjangoFormData, endpoint: string, partialOptions: IPartialDjangoFormOptions = {}, isAutoSaveForm = false) {
        super("form")

        this.initializeOptions(partialOptions)
        this.element.style.position = "static"

        this.isAutoSaveForm = isAutoSaveForm

        const table = document.createElement("table")
        this.element.appendChild(table)
        this.tbody = document.createElement("tbody")
        table.appendChild(this.tbody)

        this.submitButton = this.createSubmitButton()
        this.element.appendChild(this.submitButton)

        this.endpoint = endpoint
        this.generateFields()

        // Only enable autosave for settings tab form where this parameter is passed
        if (this.isAutoSaveForm) {
            this.handleAutoSaveAndUnsavedLabels()
        } else {
            this.element.onsubmit = (event: Event) => {
                event.preventDefault()
                this.onSubmit()
            }
        }
    }

    // To be overriden by settingsTabForm where isAutoSaveForm is true
    protected handleAutoSaveAndUnsavedLabels(): void {}

    // Used to call submit api whenever user switches tab or closes browser tab
    public triggerExternalSubmit(): void {
        if (this.isAutoSaveForm && this.formHasChanged()) {
            this.onSubmit()
        }
    }

    private onSubmit(): void {
        if (this.loading) {
            return
        }
        this.loading = true
        const formData = new FormData(this.element)
        let throttleError = false
        this.hasChanged = false
        postCb(this.endpoint, formData).then(() => {
            this.loading = false
            this.options.onSubmitSuccess(formData)
            if (this.isAutoSaveForm) {
                // Clear unsaved labels for all fields after save is successful
                this.clearUnsavedLabels()
            }
        }).catch((err: XhrError) => {
            this.loading = false
            try {
                const errorResponseText = JSON.parse(err.xhr.responseText)
                // Explicitly check if the error was due to rate limiting and show the error string in modal
                if (errorResponseText["throttle_error"] !== undefined) {
                    throttleError = true
                    modalAlert(errorResponseText["throttle_error"])
                    return
                }
                const errors = errorResponseText["errors"]
                if (errors === null || typeof errors !== "object") {
                    return
                }
                this.handleErrors(errors)
            } catch(e) {
                // JSON parse error for string response. Do nothing
            } finally {
                if (!throttleError) {
                    this.options.onSubmitError(err)
                }
            }
        }).finally(() => {
            // This avoids flickering of errors if an error is present and is not resolved in the next api calls as well
            this.hideErrorMessages()
        })
    }

    // Every option of the Django form is optional, but we also need to set the form's options with sane defaults
    private initializeOptions(partialOptions: IPartialDjangoFormOptions): void {
        const defaultStyles = {
            field: new FieldStyles(),
            checkbox: new CheckboxInputStyles(),
            input: new InputStyles(),
            select: new SelectStyles(),
            textarea: new TextAreaStyles(),
            multipleCheckboxes: new MultipleCheckboxStyles(),
            multipleSelect: new MultipleSelectStyles(),
            noReCaptcha: new NoRecaptchaStyles(),
        }
        this.options = {
            onSubmitSuccess: partialOptions.onSubmitSuccess === undefined ? () => {} : partialOptions.onSubmitSuccess,
            onSubmitError: partialOptions.onSubmitError === undefined ? () => {} : partialOptions.onSubmitError,
            styles: partialOptions.styles === undefined ? defaultStyles : {
                ...defaultStyles,
                ...partialOptions.styles, // overwrites the default styles with any user-supplied styles
            },
        }
    }

    private generateFields(): void {
        if (this.data.schema.field_order.length > 0) {
            for (const fieldName of this.data.schema.field_order) {
                const field = this.data.schema.fields[fieldName]
                this.addField(this.createField(field))
            }
        } else if (this.data.schema.fieldsets !== undefined) {
            for (const fieldset of this.data.schema.fieldsets) {
                this.addFieldset(fieldset.label)
                for (const fieldName of fieldset.fields) {
                    const field = this.data.schema.fields[fieldName]
                    this.addFieldToFieldset(this.createField(field), fieldset.label)
                }
            }
        } else {
            for (const fieldName of Object.keys(this.data.schema.fields)) {
                const field = this.data.schema.fields[fieldName]
                this.addField(this.createField(field))
            }
        }
        this.listenFormChanges()
    }

    // eslint-disable-next-line complexity
    protected createField(field: IDjangoField): Field {
        switch (field.fieldType) {
            case "CharField":
                if (field.maxLength === undefined) {
                    return new TextArea({
                        ...field,
                        styles: this.options.styles.textarea,
                    })
                } else {
                    return new Input({
                        ...field,
                        styles: this.options.styles.input,
                    })
                }
            case "EmailField":
                return new Input({
                    ...field,
                    styles: this.options.styles.input,
                })
            case "BooleanField":
                return new CheckboxInput({
                    ...field,
                    styles: this.options.styles.checkbox,
                })
            case "ChoiceField":
                return new Select({
                    ...field,
                    styles: this.options.styles.select,
                })
            case "TypedChoiceField":
                return new Select({
                    ...field,
                    styles: this.options.styles.select,
                })
            case "TypedMultipleChoiceField":
                return new MultiSelectDropdown({ ...field })
            case "MultiSelectFormField":
                return new MultipleCheckbox({
                    ...field,
                    styles: this.options.styles.multipleSelect,
                })
            case "DateField":
                return new DateSelect({
                    ...field,
                    // @ts-ignore not type checking django forms
                    day: this.data.initial[`${field.name}__day`],
                    // @ts-ignore not type checking django forms
                    month: this.data.initial[`${field.name}__month`],
                    // @ts-ignore not type checking django forms
                    year: this.data.initial[`${field.name}__year`],
                    styles: this.options.styles.select,
                })
            case "NoReCaptchaField":
                const siteKey = field.siteKey ?? ""
                if (siteKey === "") {
                    error("siteKey must be provided for NoReCaptchaField")
                }
                return new NoReCaptchaField({
                    ...field,
                    siteKey: siteKey,
                    styles: this.options.styles.noReCaptcha,
                })
            default:
                error(`DjangoForm received an unknown field type: ${field.fieldType}`)
                return new Input({ name: "" })
        }
    }

    public addField(field: Field): void {
        if (this.fields[field.getName()] !== undefined) {
            error(`Field ${field.getName()} already exists`)
            return
        }
        this.fields[field.getName()] = field
        this.tbody.appendChild(field.getField())
    }

    private clearUnsavedLabels(): void {
        for (const key of Object.keys(this.fields)) {
            const field = this.fields[key]
            if (field !== undefined) {
                field.setUnsaved(false)
                field.clearError()
            }
        }
    }

    public addFieldToFieldset(field: Field, fieldsetName: string): void {
        const fieldset = this.getFieldset(fieldsetName)
        if (fieldset === undefined) {
            error(`Fieldset ${fieldsetName} does not exist`)
            return
        }
        if (this.fields[field.getName()] !== undefined) {
            error(`Field ${field.getName()} already exists`)
            return
        }
        this.fields[field.getName()] = field
        fieldset.appendChild(field.getField())
    }

    public removeField(fieldName: string): void {
        const field = this.fields[fieldName]
        if (field !== undefined) {
            field.getField().remove()
            delete this.fields[fieldName]
        }
    }

    private createFieldset(name: string): HTMLElement {
        const fieldset = document.createElement("fieldset")
        const table = document.createElement("table")
        const tbody = document.createElement("tbody")

        fieldset.appendChild(this.createLegend(name))
        fieldset.appendChild(table)
        table.appendChild(tbody)
        this.fieldsets[name] = tbody

        return fieldset
    }

    private createLegend(text: string): HTMLLegendElement {
        const legend = document.createElement("legend")
        addColorClass(legend, "label")
        legend.innerText = text
        legend.style.fontSize = "1.266em"
        legend.style.fontFamily = "'UbuntuMedium', Arial, Helvetica, sans-serif"
        legend.style.fontWeight = "normal"
        legend.style.padding = "10px 0px 10px 0px"

        return legend
    }

    private handleErrors(errors: Record<string, string>): void {
        for (const key of Object.keys(this.fields)) {
            this.fields[key]?.onFormError()
            // Clear any errors for fields not in the api error response
            if (!(key in errors)) {
                const field = this.fields[key]
                if (field !== undefined) {
                    field.clearError()
                }
            }
        }
        // Set errors based on the API response
        for (const key in errors) {
            const errorString = errors[key][0]
            if (typeof errorString !== "string") {
                break
            }
            const field = this.getField(key)
            if (field === undefined) {
                error(`Form error on unknown field ${key}`)
            } else {
                field.setError(errorString)
            }
        }
    }

    protected hideErrorMessages(): void {
        for (const key of Object.keys(this.fields)) {
            const field = this.fields[key]
            if (field !== undefined && !field.hasError()) {
                field.clearError()
            }
        }
    }

    public formHasChanged(): boolean {
        return this.hasChanged
    }

    private listenFormChanges(): void {
        for (const key of Object.keys(this.getFields())) {
            const field = this.getField(key)
            if (field !== undefined) {
                field.fieldChanged.listen(() => {
                    this.hasChanged = true
                })
            }
        }
    }

    protected createSubmitButton(): HTMLInputElement {
        const button = document.createElement("input")
        button.type = "submit"
        button.value = i18n.submitCAPS
        button.className = "button" // for Lovense
        button.style.display = "block"
        button.style.cursor = "pointer"
        button.style.padding = "0px 30px 2px 15px"
        button.style.margin = "15px 0px 0px 137px"
        button.style.width = "auto"
        button.style.height = "28px"
        button.style.borderRadius = "4px"
        button.style.font = "1.16em/1.0em 'UbuntuMedium', Arial, Helvetica, sans-serif"
        if (this.isAutoSaveForm) {
            button.style.display = "none"
            button.disabled = true
        }
        return button
    }

    public focusFirstField(): void {
        const firstFieldName = Object.keys(this.fields)[0]
        if (firstFieldName !== undefined) {
            const field = this.fields[firstFieldName]
            if (field !== undefined) {
                field.getWidget().focus()
            }
        }
    }

    public focusField(name: string): void {
        const field = this.getField(name)
        if (field !== undefined) {
            field.getWidget().focus()
        }
    }

    protected getFields(): Record<string, Field | undefined> {
        return this.fields
    }

    public getField(name: string): Field | undefined {
        return this.fields[name]
    }

    public getFieldset(name: string): HTMLElement | undefined {
        return this.fieldsets[name]
    }

    public getSubmitButton(): HTMLInputElement {
        return this.submitButton
    }

    public prependFieldset(name: string): void {
        this.tbody.insertBefore(this.createFieldset(name), this.tbody.firstChild)
    }

    public addFieldset(name: string): void {
        this.tbody.appendChild(this.createFieldset(name))
    }

    public setSubmitText(text: string): void {
        this.submitButton.value = text
    }
}
