import type { ErrorCode, IErrorInfo } from "../states"

export function normalizeRejection(code: ErrorCode, rejection: Ably.ErrorInfo | IErrorInfo | string | undefined): IErrorInfo {
    let result: IErrorInfo
    if (rejection === undefined) {
        result = { code: code, message: "Unknown", providerCode: "" }
    } else if (typeof rejection === "string") {
        result = { code: code, message: rejection, providerCode: "" }
    } else if ("statusCode" in rejection) {
        result = resolveErrorInfo(code, rejection)
    } else {
        result = rejection
    }
    return result
}

export function resolveErrorInfo(code: ErrorCode, errorInfo: Ably.ErrorInfo | undefined): IErrorInfo {
    const result: IErrorInfo = { code: code, message: "Unknown failure", providerCode: "" }
    if (errorInfo !== undefined) {
        result.message = errorInfo.message
        result.providerCode = errorInfo.code.toString()
        // @ts-ignore ably type is not correct
        if (errorInfo["cause"] !== undefined && "code" in errorInfo["cause"]) {
            // @ts-ignore ably type is not correct
            result.cause = errorInfo["cause"]["code"]
        }
    }
    return result
}
