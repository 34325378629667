import { createDmWindowRequest, updateWindowIsOpenEvent } from "../cb/components/pm/dmWindowsManager"
import { pageContext } from "../cb/interfaces/context"
import { type IChatConnection } from "./context"
import { setPureChatColorData } from "./fullvideolib/pureChatUtil"
import { brighten } from "./messageToDOM"
import { TabName } from "./mobilelib/tabList"
import { toggleDms, userSwitchedTab } from "./mobilelib/userActionEvents"
import { PmNotifier } from "./pmNotifier"
import { createPMChatLinkMessage } from "./theatermodelib/messageToDOM"
import { i18n } from "./translation"
import { dom } from "./tsxrender/dom"
import { userInitiatedPm } from "./userActionEvents"
import type { IDmWindow } from "../cb/components/pm/dmWindowsManager"

// Combined PmNotifier/DmNotifer plus notification building utility.
// The various notifier classes should be cleaned up when merge_pm_dm feature is cleaned up
export class ConversationNotifier extends PmNotifier {
    constructor(private chatConn: IChatConnection) {
        super()
    }

    protected initListeners(): void {
        super.initListeners()
        updateWindowIsOpenEvent.listen((info: IDmWindow) => {
            if (info.isOpen) {
                this.dontNotify.add(info.username)
            }
        }).addTo(this.listenerGroup)
    }

    public shouldNotify(username: string): boolean {
        const isPrivateShowMessage = this.chatConn.inPrivateRoom() &&
            (username === this.chatConn.getPrivateShowUser() || username === this.chatConn.room())

        return super.shouldNotify(username) && !isPrivateShowMessage
    }

    public createChatLinkMessage(username: string): HTMLDivElement {
        const linkContainerStyle: CSSX.Properties = { marginLeft: ".5em" }

        const dmLink = this.createDmChatLink(username)
        const pmLink = this.createPmChatLink(username)

        const skipPmLink = pageContext.current.isMobile && username !== this.chatConn.room()

        const combinedChatLinks = <span>
            <span style={linkContainerStyle}>({dmLink})</span>
            {!skipPmLink && <span style={linkContainerStyle}>({pmLink})</span>}
        </span>
        setPureChatColorData(combinedChatLinks, brighten("#aaaaaa"))

        return createPMChatLinkMessage(i18n.newConversationMessageNotice(username), combinedChatLinks)
    }

    private createDmChatLink(username: string): HTMLElement {
        const onDmClick = () => {
            createDmWindowRequest.fire(username)
            if (pageContext.current.isMobile) {
                toggleDms.fire(true)
            }
        }
        return this.createChatLink(i18n.newConversationDmClick, onDmClick)
    }

    private createPmChatLink(username: string): HTMLElement {
        if (pageContext.current.isMobile) { 
            const onClick = () => {
                userSwitchedTab.fire(TabName.Private)
            }
            return this.createChatLink(i18n.newConversationPmClickMobile, onClick)
        } else {
            const onClick = (e: Event) => {
                if (e.target instanceof HTMLAnchorElement) {
                    userInitiatedPm.fire({ username: username, focus: true, showSupporterAlert: false })
                }
            }
            return <span onClick={onClick} dangerouslySetInnerHTML={{ __html: i18n.newConversationPmClickHTML }}/>
        }
    }

    // TODO merge_pm_dm cleanup - change this to a button for accessibility and correct semantics.
    //      For now we just want to get this to product fast, so using the same <a> as used on live with PmNotifier/DmNotifier 
    private createChatLink(text: string, onClick: () =>  void): HTMLAnchorElement {
        const style: CSSX.Properties = { cursor: "pointer" }
        return <a style={style} colorClass={["msg-link", "underline-link"]} onClick={onClick}>{text}</a>
    }
}
