import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { applyStyles } from "../../../common/DOMutils"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { Select } from "../../ui/fields"
import type { IChoices } from "../../ui/fields"


const refreshChoices: IChoices[] = [
    { label: i18n.refresh30Seconds, value: "30" },
    { label: i18n.refresh45Seconds, value: "45" },
    { label: i18n.refresh60Seconds, value: "60" },
    { label: i18n.refresh90Seconds, value: "90" },
    { label: i18n.refreshDisable, value: "0" },
]

interface IRefreshThumbnailsFormProps {initial?: number}

export class RefreshThumbnailsForm extends HTMLComponent<HTMLDivElement, IRefreshThumbnailsFormProps> {
    private refreshThumbnailsSelect: Select

    protected createElement(props: IRefreshThumbnailsFormProps): HTMLDivElement {
        this.refreshThumbnailsSelect = new Select({
            choices: refreshChoices,
            name: "refresh_frequency",
            defaultValue: (props.initial ?? 60).toString(),
        })
        return <div className="refresh">
            <form id="refresh_cams_form" action="/ax/update_refresh_frequency/" method="post">
                <label>{i18n.refreshThumbnailsEvery}:</label>
                {this.refreshThumbnailsSelect.getField()}
            </form>
        </div>
    }

    protected initUI(props?: IRefreshThumbnailsFormProps): void {
        super.initUI(props)
        const selectEl = this.element.querySelector("select")
        if (selectEl !== null) {
            selectEl.id = "id_refresh_frequency"
            selectEl.dataset.testid = "refresh-frequency-select"
            applyStyles(selectEl, { marginTop: "4px" })
        }
    }
}
