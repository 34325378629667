import { privateMessage } from "../../cb/components/pm/userActionEvents"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { isAnonymous } from "../auth"
import { roomCleanup, roomLoaded } from "../context"
import { Debouncer, DebounceTypes } from "../debouncer"
import { addPageAction, setCurrentMode } from "../newrelic"
import { videoModeHandler } from "../videoModeHandler"
import type { ITipSent, TipCallout as TheaterTipCallout } from "./tipCallout"
import type { IChatConnection } from "../context"
import type { TipCallout as FullVideoTipCallout } from "../fullvideolib/tipCallout"
import type { IPrivateMessage, IRoomMessage } from "../messageInterfaces"
import type { MobileMetricsVideoMode } from "../mobilelib/sessionMetrics"
import type { TipCallout as MobileTipCallout } from "../mobilelib/tipCallout"
import type { VideoMode } from "../videoModeHandler"
import type { NewrelicAttributes } from "@multimediallc/web-utils"

const enum NavigationType {
    // Navigation Timing Level 2 / Navigation Timing Level 1
    Navigate, // "navigate" / TYPE_NAVIGATE = 0
    Reload, // "reload" / TYPE_RELOAD = 1
    BackForward, // "back_forward" / TYPE_BACK_FORWARD = 2
    Prerender, // "prerender" / N/A
    Reserved = 255, // N/A / TYPE_RESERVED = 255
}

type RefreshMeta = {
    href: string
    unloadTimestamp: number
}

type AllVideoModes = VideoMode | MobileMetricsVideoMode

export class SessionMetrics {
    private messageCount = 0
    private messageSuccessCount = 0
    private tipSuccessCount = 0
    private tipSuccessTotal = 0
    private tipFailCount = 0
    private tipFailTotal = 0
    private pageRefreshed: boolean | undefined
    protected currentMode: AllVideoModes
    private newMode?: AllVideoModes

    private readonly loadTimestamp: number
    private chatConnection?: IChatConnection = undefined
    private tipCallout?: TheaterTipCallout | FullVideoTipCallout | MobileTipCallout = undefined
    protected actionName = "SessionMetrics"
    private modeChangeDebounce: Debouncer

    constructor() {
        this.resetMetrics()

        this.loadTimestamp = new Date().getTime()
        this.pageRefreshed = this.wasRefresh()
        this.currentMode = videoModeHandler.getVideoMode()
        setCurrentMode(this.currentMode)
        this.modeChangeDebounce = new Debouncer(() => {
            const newMode = this.getMode()
            if (this.currentMode === newMode) {
                return
            }
            this.newMode = newMode
            this.sendMetrics()
            this.currentMode = newMode
        }, { bounceLimitMS: 100, debounceType: DebounceTypes.debounce })

        roomLoaded.once(() => {
            this.setupModeChangeListener()
        })
        roomCleanup.listen(this.sendMetrics)
        addEventListenerPoly("beforeunload", window, this.sendMetrics)
        addEventListenerPoly("beforeunload", window, this.saveRefreshMeta)
    }

    protected setupModeChangeListener(): void {
        videoModeHandler.changeVideoMode.listen(this.onModeChange)
    }

    public bindChatConnection (chatConnection: IChatConnection): void {
        if (chatConnection === this.chatConnection) {
            return
        }

        if (this.chatConnection !== undefined) {
            this.chatConnection.event.messageSent.removeListener(this.onMessageSent)
            this.chatConnection.event.roomMessage.removeListener(this.onMessageReceived)
            // TODO: decouple from chatconnection
            privateMessage.removeListener(this.onMessageReceived)
        }

        this.chatConnection = chatConnection
        this.chatConnection.event.messageSent.listen(this.onMessageSent)
        this.chatConnection.event.roomMessage.listen(this.onMessageReceived)
        privateMessage.listen(this.onMessageReceived)
    }

    public bindTipCallout(tipCallout: TheaterTipCallout | FullVideoTipCallout | MobileTipCallout): boolean {
        if (tipCallout !== this.tipCallout) {
            if (this.tipCallout !== undefined) {
                this.tipCallout.tipSent.removeListener(this.onTipSent)
            }
            this.tipCallout = tipCallout
            this.tipCallout.tipSent.listen(this.onTipSent, false)
            return true
        } else {
            return false
        }
    }

    private careAboutMetrics(): boolean {
        return this.chatConnection !== undefined && !isAnonymous()
    }

    protected resetMetrics(): void {
        this.messageCount = 0
        this.messageSuccessCount = 0
        this.tipSuccessCount = 0
        this.tipSuccessTotal = 0
        this.tipFailCount = 0
        this.tipFailTotal = 0
        this.newMode = undefined
        this.pageRefreshed = false
    }

    private sendMetrics = (): void => {
        if (this.careAboutMetrics()) {
            addPageAction(this.actionName, this.getPageActionAttributes())
            this.resetMetrics()
        }
    }

    protected getPageActionAttributes(): NewrelicAttributes {
        return {
            "messageSuccessCount": this.messageSuccessCount,
            "messageFailCount": this.messageCount - this.messageSuccessCount,
            "tipSuccessCount": this.tipSuccessCount,
            "tipSuccessTotal": this.tipSuccessTotal,
            "tipFailCount": this.tipFailCount,
            "tipFailTotal": this.tipFailTotal,
            "refreshCount": this.pageRefreshed === undefined ? undefined : Number(this.pageRefreshed),
            "modeChange": Boolean(this.newMode),
            "newMode": this.newMode,
            "currentMode": this.currentMode,
        }
    }

    protected getMode(): AllVideoModes {
        return videoModeHandler.getVideoMode()
    }

    protected onModeChange = (): void  => {
        setCurrentMode(this.getMode())
        addPageAction("ChangeVideoMode", { "videoMode": this.getMode() })
        if (this.careAboutMetrics()) {
            this.modeChangeDebounce.callFunc()
        }
    }

    private onTipSent = (event: ITipSent): void => {
        if (event.amount !== undefined) {
            if (event.success === true) {
                this.tipSuccessCount += 1
                this.tipSuccessTotal += event.amount
            } else {
                this.tipFailCount += 1
                if (event.amount > 0) { this.tipFailTotal += event.amount }
            }
        }
    }

    private onMessageSent = (): void => {
        this.messageCount += 1
    }

    private onMessageReceived = (event: IRoomMessage | IPrivateMessage): void => {
        if (
            this.chatConnection !== undefined
            && event.fromUser.username === this.chatConnection.username()
            && this.messageSuccessCount < this.messageCount
        ) {
            this.messageSuccessCount += 1
        }
    }

    private getNavigationType(): NavigationType | undefined {
        // Navigation Timing Supported
        if (window.performance !== undefined) {
            let navigationTimingEntries: PerformanceNavigationTiming[] = []
            if (window.performance.getEntriesByType !== undefined) {
                navigationTimingEntries = window.performance.getEntriesByType("navigation") as PerformanceNavigationTiming[]
            }

            // Navigation Timing Level 2 supported
            if (navigationTimingEntries.length > 0) {
                switch (navigationTimingEntries[0].type) {
                    case "navigate": return NavigationType.Navigate
                    case "reload": return NavigationType.Reload
                    case "back_forward": return NavigationType.BackForward
                    case "prerender": return NavigationType.Prerender
                    default: return NavigationType.Reserved
                }
            } else {
                return window.performance.navigation.type as NavigationType
            }
        } else {
            return undefined
        }
    }

    private getRefreshMeta(): RefreshMeta | undefined {
        try {
            const data = window.sessionStorage?.getItem("refreshMeta")
            if (data !== null) {
                return JSON.parse(data)
            }
            return undefined
        } catch {
            // in cases where sessionStorage is blocked, reading will throw DOMException
            return undefined
        }
    }

    private wasRefresh(): boolean | undefined {
        const navigationType = this.getNavigationType()
        if (navigationType === NavigationType.Reload) {
            return true
        } else if (navigationType === NavigationType.Navigate || navigationType === undefined) {
            // This may have been a manual reload (e.g., hitting enter in the address bar), or we just don't have any
            // navigation data to work with.
            const refreshMeta = this.getRefreshMeta()
            if (refreshMeta === undefined) {
                return undefined
            }
            return (
                refreshMeta.href === window.location.href
                // Give 10s of leeway, which is double our average load time.
                && refreshMeta.unloadTimestamp > this.loadTimestamp - 10000
            )
        }
        return false
    }

    private saveRefreshMeta = (): void => {
        if (this.careAboutMetrics()) {
            const refreshMeta: RefreshMeta = {
                "href": window.location.href,
                "unloadTimestamp": new Date().getTime(),
            }
            try {
                window.sessionStorage.setItem("refreshMeta", JSON.stringify(refreshMeta))
            } catch {
                // same as with reading, writing to sessionStorage can throw DOMException
                return
            }
        }
    }
}
