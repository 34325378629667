/* eslint-disable @multimediallc/no-xhr */
import { getCookieOrUndefined } from "@multimediallc/web-utils/storage"
import { pageContext } from "../cb/interfaces/context"

export interface IXhrConfig {
    timeout?: number
    withCredentials?: boolean
    noRequestedWith?: boolean
}

/**
 * Normalizes resource url by adding prefix if necessary.
 * @param resource Url string.
 */
export function normalizeResource(resource: string): string {
    const skippedInitialChars = [".", "?", "#"]
    if (skippedInitialChars.some(char => resource.startsWith(char))) {
        return resource
    }

    let urlPrefix = ""
    if (resource.match(/^[a-zA-Z]+:\/\//) === null) {
        urlPrefix = resource.startsWith("/") ? "" : "/"
    }
    return `${urlPrefix}${resource}`

    // TODO uncomment after backend changes merge
    // const langPrefix = pageContext.current.languageCode == "en" ? "/" : `/${pageContext.current.languageCode}/`
    // if (resource.startsWith(langPrefix)) {
    //     return resource
    // }

    // if (resource.match(/^[a-zA-Z]+:\/\//) !== null) {
    //     for (const externalCbPrefix of [`https://${currentSiteSettings.CBAlias}`, "https://m.chaturbate.com"]) {
    //         if (resource.startsWith(externalCbPrefix)) {
    //             return resource.replace(`${externalCbPrefix}/`, `${externalCbPrefix}${langPrefix}`)
    //         }
    //     }
    //     return resource
    // }

    // if (resource.startsWith("/")) {
    //     resource = resource.slice(1)
    // }
    // return `${langPrefix}${resource}`
}

function toFormData(data: Record<string, string> | FormData): FormData {
    if (data instanceof FormData) {
        return data
    }
    const formDataNew = new FormData()
    for (const key of Object.keys(data)) {
        formDataNew.append(key, data[key])
    }
    return formDataNew
}

function xhrToString(xhr: XMLHttpRequest): string {
    switch (xhr.readyState) {
        case 0:
            return "request not sent"
        case 4:
            switch (xhr.status) {
                case 0:
                    return "network error"
                case 400:
                    return "bad request"
                case 401:
                    return "unauthorized"
                case 403:
                    return "access denied"
                case 404:
                    return "not found"
                case 429:
                    return "request throttled"
                case 500:
                    return "server error"
                default:
                    return `unknown status ${xhr.status}`
            }
        default:
            return `unknown ready state ${xhr.readyState}`
    }
}

export class XhrError extends Error {
    public xhrErrorAttributes: object
    constructor(public xhr: XMLHttpRequest) {
        super(xhrToString(xhr))
        this.xhrErrorAttributes = {
            status: xhr.status,
            reason: xhr.statusText,
            readyState: xhr.readyState,
        }
    }

    public toString = (): string => { // normal function syntax doesn't seem to work
        return `XhrError: ${this.message}`
    }
}

export function isGoodStatus(status: number): boolean {
    // https://stackoverflow.com/questions/10046972/msie-returns-status-code-of-1223-for-ajax-request
    return status >= 200 && status <= 299 || status === 1223
}

function setupXhr(xhr: XMLHttpRequest, resolve: (xhr: XMLHttpRequest) => void, reject: (xhr: XhrError) => void, config?: IXhrConfig): void {
    xhr.withCredentials = config?.withCredentials ?? true
    xhr.timeout = config?.timeout ?? 60000
    xhr.onerror = () => {
        reject(new XhrError(xhr))
    }
    xhr.onload = () => {
        if (isGoodStatus(xhr.status)) {
            resolve(xhr)
        } else {
            reject(new XhrError(xhr))
        }
    }
    if (config?.noRequestedWith !== true) {
        xhr.setRequestHeader("X-Requested-With", "XMLHttpRequest")
    }
}

function buildFullResource(resource: string, data: Record<string, string>): string {
    const buildQueryOpt = (key: string, value: string) => `${key}=${encodeURIComponent(value)}`
    const opts = Object.keys(data).map((key) => buildQueryOpt(key, data[key])).join("&")
    const fullResource = opts === "" ? resource : `${resource}?${opts}`
    return normalizeResource(fullResource)
}

export function getCb(resource: string, config?: IXhrConfig): Promise<XMLHttpRequest> {
    return getCbAndXhr(resource, config)[1]
}

function fetchCbAndXhr(resource: string, method: string, config?: IXhrConfig): [XMLHttpRequest, Promise<XMLHttpRequest>] {
    const xhr = new XMLHttpRequest()
    return [xhr, new Promise((resolve, reject) => {
        xhr.open(method, normalizeResource(resource))
        setupXhr(xhr, resolve, reject, config)
        xhr.send()
    })]
}

export function getCbAndXhr(resource: string, config?: IXhrConfig): [XMLHttpRequest, Promise<XMLHttpRequest>] {
    return fetchCbAndXhr(resource, "GET", config)
}

export function postCb(resource: string, data: Record<string, string> | FormData, config?: IXhrConfig): Promise<XMLHttpRequest> {
    // convert plain objects to FormData
    const formData = toFormData(data)
    const csrfToken = getPreferredCSRFToken(formData)
    if (csrfToken === undefined) {
        error("CSRF token is undefined")
    } else {
        formData.set("csrfmiddlewaretoken", csrfToken)
    }

    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open("POST", normalizeResource(resource), true)
        setupXhr(xhr, resolve, reject, config)
        xhr.send(formData)
    })
}

export function putCb(resource: string, data: Record<string, string> = {}, config?: IXhrConfig): Promise<XMLHttpRequest> {
    const csrfToken = getPreferredCSRFToken()
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open("PUT", buildFullResource(resource, data), true)
        setupXhr(xhr, resolve, reject, config)
        if (csrfToken === undefined) {
            error("CSRF token is undefined")
            reject(new XhrError(xhr))
            return
        }
        xhr.setRequestHeader("X-CSRFToken", csrfToken)
        xhr.send()
    })
}

export function deleteCb(resource: string, data: Record<string, string> = {}, config?: IXhrConfig): Promise<XMLHttpRequest> {
    const csrfToken = getPreferredCSRFToken()
    return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest()
        xhr.open("DELETE", buildFullResource(resource, data), true)
        setupXhr(xhr, resolve, reject, config)
        if (csrfToken === undefined) {
            error("CSRF token is undefined")
            reject(new XhrError(xhr))
            return
        }
        xhr.setRequestHeader("X-CSRFToken", csrfToken)
        xhr.send()
    })
}

function getPreferredCSRFToken(data?: FormData): string | undefined {
    const cookieToken = getCookieOrUndefined("csrftoken")
    if (cookieToken !== undefined) {
        return cookieToken
    } else if (pageContext.current.csrftoken !== undefined) {
        return pageContext.current.csrftoken
    }

    let csrfForm
    if (data !== undefined) {
        if (data.has("csrfmiddlewaretoken")) {
            csrfForm = data.get("csrfmiddlewaretoken") as string
        }
    }
    return csrfForm
}
