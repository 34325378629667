import { pageContext, roomDossierContext } from "../cb/interfaces/context"
import { RoomPrivilegedEnterTopic } from "../cb/pushservicelib/topics/room"
import { UserFollowerTopic } from "../cb/pushservicelib/topics/user"
import { RoomUsers } from "../cb/roomUsers"
import { createFollowerStar } from "../cb/ui/svg/followerStar"
import { buildTooltip } from "../cb/ui/tooltip"
import { BadgeType, ChatBadgeManager } from "./chatBadgeManager"
import { roomLoaded } from "./context"
import { DivotPosition } from "./divot"
import { applyStyles, getFixedOffset, hoverEvent } from "./DOMutils"
import { i18n } from "./translation"
import type { IFollowerTopic } from "./messageInterfaces"

export class RoomFollowersManager {
    private static singletonInstance: RoomFollowersManager
    private followers: Set<string>

    private constructor() {
        this.followers = new Set<string>()

        // At least for now, only track followers and add badges if the user is on their own broadcast page
        if (pageContext.current.isBroadcast) {
            const userUid = pageContext.current.loggedInUser?.userUid ?? ""
            new UserFollowerTopic(userUid).onMessage.listen((data: IFollowerTopic) => {
                if (data.followerUsername === pageContext.current.loggedInUser?.username) {
                    return
                }

                if (data.isFollowing) {
                    this.addFollower(data.followerUsername)
                } else {
                    this.removeFollower(data.followerUsername)
                }
            })

            roomLoaded.listen(context => {
                // The userlist comes back empty if loaded immediately, so timeout a few seconds first. Not critical if
                // it doesn't work tho
                window.setTimeout(() => {
                    RoomUsers.getInstance().fetchRoomUsers().then(roomUsersInfo => {
                        roomUsersInfo.roomUsers.forEach(user => {
                            if (user.isFollowing === true) {
                                this.addFollower(user.username)
                            }
                        })
                    }).catch(undefined)
                }, 5000)

                context.chatConnection.event.roomMessage.listen(message => {
                    if (message.fromUser.isFollowing === true) {
                        this.addFollower(message.fromUser.username)
                    }
                })
                context.chatConnection.event.tipAlert.listen(tip => {
                    if (tip.fromUser.isFollowing=== true) {
                        this.addFollower(tip.fromUser.username)
                    }
                })

                new RoomPrivilegedEnterTopic(context.dossier.roomUid).onMessage.listen(data => {
                    if (data.user.isFollowing === true) {
                        this.addFollower(data.user.username)
                    }
                })
            })

            ChatBadgeManager.registerGenerator(BadgeType.Follower, username => this.createChatBadge(username))
        }
    }

    public static getOrCreateInstance(): RoomFollowersManager {
        if (RoomFollowersManager.singletonInstance === undefined) {
            RoomFollowersManager.singletonInstance = new RoomFollowersManager()
        }
        return RoomFollowersManager.singletonInstance
    }

    public hasFollower(username: string): boolean {
        return this.followers.has(username)
    }

    public addFollower(username: string): void {
        if (this.followers.has(username)) {
            return
        }
        this.followers.add(username)
        this.updateChatBadges(username)
    }

    public removeFollower(username: string): void {
        this.followers.delete(username)
        this.updateChatBadges(username)
    }

    private shouldShowBadge(username: string): boolean {
        return this.followers.has(username) && username !== roomDossierContext.getState().room
    }

    private createChatBadge(username: string): HTMLSpanElement {
        const badgeContainer = document.createElement("span")
        applyStyles(badgeContainer, {
            position: "relative",
            display: this.shouldShowBadge(username) ? "" : "none",
            marginRight: ".1em",
        })

        const badge = createFollowerStar()

        const tooltip = buildTooltip({
            content: i18n.followsYou,
            hasHTML: false,
            divotPosition: DivotPosition.Bottom,
            divotLeftOrTop: "8px",
        })
        tooltip.style.padding = "6px"
        tooltip.style.fontFamily = "UbuntuLight, Helvetica, Arial, sans-serif"
        tooltip.style.lineHeight = "normal"
        tooltip.style.position = "fixed"
        tooltip.style.display = "block"
        hoverEvent(badge).listen(isHover => {
            if (isHover) {
                document.body.appendChild(tooltip)
            } else {
                tooltip.parentElement?.removeChild(tooltip)
            }
            const badgeBoundingRect = badge.getBoundingClientRect()
            const fixedOffset = getFixedOffset()
            tooltip.style.top = `${-fixedOffset.top + badgeBoundingRect.top - tooltip.offsetHeight - badgeBoundingRect.height / 5 - 5}px`
            tooltip.style.left = `${-fixedOffset.left + badgeBoundingRect.left + badgeBoundingRect.width / 2 - 18}px`
        })

        badgeContainer.appendChild(badge)

        return badgeContainer
    }

    private updateChatBadges(username: string): void {
        const display = this.shouldShowBadge(username)
        const badges = ChatBadgeManager.getBadgeElements(BadgeType.Follower, username) as HTMLSpanElement[]
        badges.forEach((el: HTMLSpanElement) => {
            el.style.display = display ? "" : "none"
        })
    }
}
