import { ArgJSONMap } from "@multimediallc/web-utils"
import { EventRouter } from "../../../common/events"
import { AUTH_API_URL } from "../auth"
import { PushServiceClient, resolveConnectionState } from "../baseClient"
import { ConnectionState, ErrorCode, SubscriptionState } from "../states"
import { PusherAuthProvider } from "./index"
import type { IPusherContextSettings } from "./index"
import type { IClientCallbacks, IPushContextSettings } from "../baseClient"
import type { IConnectionStateChange, IErrorInfo } from "../states"

export const PUSHER_CLIENT_NAME = "p"

function createRejection(code: ErrorCode, errorMessage?: string): IErrorInfo {
    return {
        providerCode: "",
        code: code,
        message: errorMessage ?? "",
    }
}

function resolveChannelState(channel?: Pusher.Channel): SubscriptionState {
    if (channel === undefined) {
        return SubscriptionState.uninitialized
    }
    if (channel.subscriptionPending) {
        return SubscriptionState.subscribing
    }
    if (channel.subscribed) {
        return SubscriptionState.subscribed
    }
    if (channel.subscriptionCancelled) {
        return SubscriptionState.failed
    }
    return SubscriptionState.initialized
}

export class PusherClient extends PushServiceClient {
    public readonly clientName = PUSHER_CLIENT_NAME
    public readonly auth: PusherAuthProvider
    private client: Pusher | undefined
    private settings: IPusherContextSettings
    // only used internally for checking reconnecting, otherwise use client.connection
    private socketId: string | undefined

    constructor(callbacks: IClientCallbacks, settings: IPushContextSettings) {
        super(callbacks)
        this.connectionChange = new EventRouter<IConnectionStateChange>("PusherClientConnection")
        this.settings = settings as IPusherContextSettings
        this.auth = new PusherAuthProvider(this)
    }


    public close(): void {
        if (this.client !== undefined) {
            this.client.disconnect()
        }
        this.auth.reset()
        this.client = undefined
    }

    protected _connect(): void {
        const pusherOptions = {
            cluster: this.settings.cluster,
            channelAuthorization: {
                endpoint: AUTH_API_URL,
                transport: "ajax",
                customHandler: (params: Pusher.ChannelAuthorizationRequestParams, callback: Pusher.ChannelAuthorizationCallback) => {
                    const token = this.auth.getAuthToken(params.channelName)
                    if (token === undefined) {
                        // eslint-disable-next-line @multimediallc/no-null-usage
                        callback(new Error("Pusher js no auth token"), null)
                        return
                    }
                    const authData: Pusher.ChannelAuthorizationData = {
                        auth: token,
                        channel_data: this.auth.channelDataString,
                    }
                    // eslint-disable-next-line @multimediallc/no-null-usage
                    callback(null, authData)
                },
            } as Pusher.ChannelAuthorizationOptions,
        } as Pusher.Options
        if (this.settings.host !== undefined && this.settings.host !== "") {
            pusherOptions.wsHost = `ws.${this.settings.host}`
            pusherOptions.httpHost = `sockjs.${this.settings.host}`
        }
        this.client = new Pusher(this.settings.key, pusherOptions)
        this.client.connection.bind("state_change", (stateChange: Pusher.ConnectionStateChange) => {
            if (stateChange.current === "connected" && this.client !== undefined) {
                // make sure this doesn't hit on first connect
                if (this.socketId !== undefined && this.socketId !== this.client.connection.socket_id) {
                    this.auth.reset()
                    this.activeSubscriptions.clear()
                }
                this.socketId = this.client.connection.socket_id
            }

            const changeEvent: IConnectionStateChange = {
                current: resolveConnectionState(stateChange.current),
                previous: resolveConnectionState(stateChange.previous),
                client: "p",
            }
            this.connectionChange.fire(changeEvent)
        })

        Pusher.log = msg => debug(msg)
        // no catch - base client already calls connectionChange on error
        this.connect().catch(() => {})
    }

    public getConnectionState(): ConnectionState {
        if (this.client === undefined) {
            return ConnectionState.disconnected
        }
        return resolveConnectionState(this.client.connection.state)
    }

    protected getChannelState(channelName: string): SubscriptionState {
        if (this.client === undefined) {
            return SubscriptionState.unknown
        }
        return resolveChannelState(this.client.channel(channelName))
    }

    public getReconnectCount(): number {
        return -1
    }

    protected _subscribe(topicKey: string): Promise<void> {
        const channelName = this.getChannelName(topicKey)
        if (channelName === undefined) {
            return Promise.reject(createRejection(ErrorCode.topic_error, "Invalid topic key reached subscribe"))
        }
        if (this.client === undefined) {
            return Promise.reject(createRejection(ErrorCode.connect, "Client not initialized at subscribe"))
        }
        const channel = this.client.subscribe(channelName)
        const topicPromise = new Promise<void>((resolve, reject) => {
            this.ensureConnectedAndAuthed(topicKey).then(() => {
                // unbind all before making a new bind to prevent duplicates
                channel.unbind_all()
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                channel.bind("notify", (data: any) => {
                    data["providerData"] = {
                        "id": "pusher:id:1",
                        "ts": new Date().getTime(),
                    }
                    const messageData = new ArgJSONMap(data as string)
                    this.checkForReauth(messageData)
                    const topicIdToKeyMap = this.getChannelTopicMap(channelName)
                    let topicKeyToFire
                    if (topicIdToKeyMap !== undefined) {
                        topicKeyToFire = topicIdToKeyMap.get(messageData.getString("_topic"))
                    }
                    if (topicKeyToFire !== undefined) {
                        if (this.handleMessageDuplicate(channelName, messageData, messageData.getString("tid"))) {
                            return
                        }
                        this.callbacks.onMessage(this.clientName, topicKeyToFire, messageData)
                    } else if (messageData.getStringOrUndefined("_sm") !== "o") {
                        warn("Received message for unknown topic", {
                            "topic": messageData.getString("_topic"),
                            "client": this.clientName,
                        })
                    }
                })
                resolve()
            }).catch((err: string | undefined) => {
                channel.unbind_all()
                channel.unsubscribe()
                reject(createRejection(ErrorCode.subscribe, err))
            })
        })
        this.topicPromises.set(topicKey, topicPromise)
        return topicPromise
    }

    protected _unsubscribe(topicKey: string): Promise<void> {
        const channelName = this.getChannelName(topicKey)
        if (channelName === undefined) {
            return Promise.reject(createRejection(ErrorCode.topic_error, "Invalid topic key reached unsubscribe"))
        }
        if (this.client === undefined) {
            return Promise.reject(createRejection(ErrorCode.connect, "Client not initialized at unsubscribe"))
        }
        const channel = this.client.channel(channelName)
        if (channel === undefined) {
            // pusher not connected to channel
            return Promise.resolve()
        }
        const topicPromise = new Promise<void>((resolve, reject) => {
            channel.unbind_all()
            channel.unsubscribe()
            this.auth.removeTopicAuth(channelName)
            resolve()
        })
        this.topicPromises.set(topicKey, topicPromise)
        return topicPromise
    }

    public enterPresence(topicKey: string, payload?: object): Promise<void> {
        return this.subscribe(topicKey).then(() => {
            const channelName = this.getChannelName(topicKey)
            if (channelName === undefined) {
                return Promise.reject(createRejection(ErrorCode.topic_error, "Invalid topic key reached enterPresence"))
            }
            if (this.client === undefined) {
                return Promise.reject(createRejection(ErrorCode.connect, "Client not initialized at enterPresence"))
            }
            const channel = this.client.channel(channelName) as Pusher.PresenceChannel
            if (channel === undefined) {
                return Promise.reject(createRejection(ErrorCode.topic_error, "Invalid channel state after subscribe in enterPresence"))
            }
            channel.members.me.info = payload
            return Promise.resolve()
        })
    }

    public leavePresence(topicKey: string): Promise<void> {
        return this.unsubscribe(topicKey)
    }

    public getConnectionType(): string {
        return ""
    }

    public getConnectionId(): string {
        let connectionId = ""
        if (this.client !== undefined) {
            connectionId = this.client.connection.socket_id
        }
        return connectionId
    }

    public getClientId(): string {
        let clientId = ""
        if (this.client !== undefined) {
            clientId = this.client.sessionID.toString()
        }
        return clientId
    }

    public getConnectionHost(): string {
        return this.settings.key
    }

    public getConnectionServer(): string {
        return this.settings.cluster
    }
}
