import { performToggleIsActive } from "./featureFlag"

type CustomToastTypes =
    | "low-balance-common"
    | "low-balance-private"
    | "success-autorefill"

type GenericToastTypes = "success" | "error" | "info" | "warning"

type ToastTypes = GenericToastTypes | CustomToastTypes
type ContainerIdType =
    | "global-toast"
    | "video-player-toast-container"
    | "purchase-success-toast"

declare global {
    interface Window {
        showToast: ({
            message,
            type,
            containerId,
        }: {
            message: string
            type: ToastTypes
            containerId?: ContainerIdType
        }) => void
    }
}

export const showToast = (args: {
    message: string
    type?: ToastTypes
    containerId?: ContainerIdType
    [key: string]: unknown
}): void => {
    if (
        !performToggleIsActive("TknRflTopUp") ||
        window.showToast === undefined
    ) {
        return
    }
    window.showToast({ ...args, type: args.type ?? "success" })
}
