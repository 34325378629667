import { getBroadcasterAppListCached } from "../cb/api/asp"
import { addColorClass } from "../cb/colorClasses"
import { getAppPath } from "../cb/components/asp/aspPanel"
import { normalizeResource } from "./api"
import { parseColorToRGB } from "./colorUtils"
import { setPureChatColorData } from "./fullvideolib/pureChatUtil"
import { Luminance } from "./luminance"
import { maxMessageHistory } from "./theatermodelib/chatTabContents"
import { i18n } from "./translation"
import type { IPrivateMessage, IRoomMessage, IUserInfo } from "./messageInterfaces"
import type { IAppInfo } from "./roomDossier"
import type { IBroadcasterAppListResponseData } from "../cb/interfaces/asp"

export const enum ChatMode {
    Theater = "theater",
    FullVideo = "fullvideo",
    Mobile = "mobile",
}

export function createMessageContentDiv(): HTMLDivElement {
    const bar = document.createElement("div")
    bar.style.width = "100%"
    bar.style.display = "inline-block"
    bar.style.padding = "0px"
    bar.style.position = "relative"
    bar.style.boxSizing = "border-box"
    bar.style.borderRadius = "4px"
    bar.style.marginRight = "4px"
    return bar
}

export function createBaseMessageDiv(dataNick = ""): HTMLDivElement {
    const div = document.createElement("div")
    div.dataset.testid = "chat-message"
    div.style.fontFamily = "Tahoma,Arial,Helvetica,sans-serif"
    div.style.boxSizing = "border-box"
    div.style.width = "100%"
    div.style.paddingTop = "3px"
    div.style.paddingBottom = "3px"
    div.style.paddingLeft = "5px"
    div.style.paddingRight = "5px"
    div.style.overflowX = "hidden"
    if (dataNick.length > 0) {
        div.setAttribute("data-nick", dataNick) // eslint-disable-line @multimediallc/no-set-attribute
    }
    return div
}

export function getUserType(room: string, userInfo: IUserInfo): string {
    if (userInfo.username === room) {
        return `${i18n.broadcaster} `
    } else if (userInfo.isMod) {
        return `${i18n.moderator} `
    } else if (userInfo.inFanclub) {
        return `${i18n.fanclubMember} `
    } else {
        return `${i18n.user} `
    }
}

export function brighten(color: string): string {
    const parsedRGB = parseColorToRGB(color)
    const messageLum = new Luminance(parsedRGB.red, parsedRGB.green, parsedRGB.blue)
    const oldBrightness = messageLum.brightness()
    messageLum.raiseToMin(255 - oldBrightness / 3)
    return `rgb(${messageLum.red}, ${messageLum.green}, ${messageLum.blue})`
}

function createSeparator(): HTMLSpanElement {
    const s = document.createElement("span")
    s.innerText = ", "
    return s
}

function createAppsRunningMessageAspPromise(broadcasterUid: string, source: ChatMode): Promise<HTMLSpanElement> {
    return new Promise((resolve, reject) => {
        getBroadcasterAppListCached(broadcasterUid).then((result: IBroadcasterAppListResponseData) => {
            const base = document.createElement("span")
            if (result.items.length === 0) {
                resolve(base)
                return
            }
            const span = document.createElement("span")
            base.appendChild(span)
            let i = 0
            for (const app of result.items) {
                i += 1
                let container: HTMLDivElement | HTMLAnchorElement
                if (source === ChatMode.Mobile) {
                    container = document.createElement("div")
                    container.style.display = "inline"
                    container.style.position = "relative"
                    container.innerText = app.name
                } else {
                    container = document.createElement("a")
                    container.href = normalizeResource(`/v2apps?slot=${app.slot}&app_path=${getAppPath(app)}`)
                    container.target = "_blank"
                    container.innerText = app.name
                    addColorClass(container, "msg-link")
                    container.onmouseenter = () => {
                        container.style.textDecoration = "underline"
                    }
                    container.onmouseleave = () => {
                        container.style.textDecoration = "none"
                    }
                }
                base.appendChild(container)
                if (i < result.items.length) {
                    base.appendChild(createSeparator())
                }
            }
            resolve(base)
        }).catch((err: string) => {
            reject(err)
        })
    })
}

export async function createAppsRunningMessage(
    broadcaster: string,
    broadcasterUid: string,
    apps: IAppInfo[],
    source: ChatMode,
): Promise<HTMLDivElement> {
    let div = createBaseMessageDiv()
    setPureChatColorData(div, brighten("#aaaaaa"))

    const span = document.createElement("span")
    span.innerText = i18n.appsRunningMessage(broadcaster)
    div.appendChild(span)
    for (let i = 0; i < apps.length; i += 1) {
        let container: HTMLDivElement | HTMLAnchorElement
        if (source === ChatMode.Mobile) {
            container = document.createElement("div")
            container.style.display = "inline"
            container.style.position = "relative"
            container.innerText = apps[i].app_name
        } else {
            container = document.createElement("a")
            container.dataset.testid = "app-link"
            container.href = normalizeResource(apps[i].app_location)
            container.target = "_blank"
            container.innerText = apps[i].app_name
            addColorClass(container, "msg-link")
            container.onmouseenter = () => {
                container.style.textDecoration = "underline"
            }
            container.onmouseleave = () => {
                container.style.textDecoration = "none"
            }
        }
        div.appendChild(container)
        if (i < apps.length - 1) {
            div.appendChild(createSeparator())
        }
    }

    let aspMessage
    try {
        aspMessage = await createAppsRunningMessageAspPromise(broadcasterUid, source)
        if (aspMessage.innerText === "" && apps.length === 0) {
            return Promise.resolve(document.createElement("div"))
        }
        if (aspMessage.innerText === "") {
            return Promise.resolve(div)
        }
        if (apps.length > 0) {
            div.appendChild(createSeparator())
        }
        div.appendChild(aspMessage)
    } catch(e) {
        if (apps.length === 0) {
            div = document.createElement("div")
        }
    }
    return Promise.resolve(div)
}

export function setTimestamp(message: IRoomMessage | IPrivateMessage, div: HTMLDivElement): void {
    const pm = message as IPrivateMessage

    if (message.ts !== undefined) {
        div.dataset["ts"] = message.ts.toString()
    } else if (pm.createdAt !== undefined) { // for history pms which don't have a push service timestamp
        div.dataset["ts"] = pm.createdAt.getTime().toString()
    }
}

export function insertByTimestamp(c: HTMLDivElement, messageList: HTMLDivElement, isPMs = false): void {
    const ts = c.dataset["ts"]
    if (ts === undefined || ts === "") {
        messageList.appendChild(c)
        return
    }
    const children = messageList.children
    let insertIndex = messageList.children.length

    // limit will always be 0, otherwise messages will be inserted incorrectly if enough history is loaded,
    // but keeping the limit just in case some bug causes some messageList to possibly grow unbounded
    const limit = children.length < maxMessageHistory(isPMs) ? 0 : children.length - maxMessageHistory(isPMs)
    for (let i = children.length - 1; i >= limit; i -= 1) {
        const childTs = children[i].getAttribute("data-ts")
        if (childTs !== null && childTs !== "") {
            if (Number.parseInt(childTs) <= Number.parseInt(ts)) {
                break
            } else {
                insertIndex = i
            }
        }
    }
    if (insertIndex >= messageList.children.length) {
        messageList.appendChild(c)
    } else {
        messageList.insertBefore(c, children[insertIndex])
    }
}
