import { isIE9OrBelow } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly, removeEventListenerPoly } from "./addEventListenerPolyfill"

type ListenerBindingObject = HTMLElement | Document | Window

interface IDragListener {
    enabled: boolean
    move: (x: number, y: number) => void
    end: () => void
}

interface ICoords {
    valid: boolean
    x: number
    y: number
}

export const DISABLED_DRAG_LISTENER: IDragListener = {
    enabled: false,
    move: (x: number, y: number) => {
    },
    end: () => {
    },
}

export type DragListenerStartCB = (ev: Event, x: number, y: number) => IDragListener

export function addDragListener(elem: ListenerBindingObject, start: DragListenerStartCB): void {
    const moveCoords = (ev: Event) => {
        const mev = ev as MouseEvent
        return { valid: true, x: mev.clientX, y: mev.clientY }
    }
    setupListeners(elem, start, moveCoords, "mousedown", "mousemove", "mouseup")

    const touchCoords = (ev: Event) => {
        const tev = ev as TouchEvent
        if (tev.touches.length === 1) {
            const touch = tev.touches.item(0)
            if (touch !== null) {
                return { valid: true, x: touch.clientX, y: touch.clientY }
            }
        }
        return { valid: false, x: 0, y: 0 }
    }
    setupListeners(elem, start, touchCoords, "touchstart", "touchmove", "touchend")
}

function setupListeners(elem: ListenerBindingObject,
    startListener: (ev: Event, x: number, y: number) => IDragListener,
    coordsExtractor: (ev: Event) => ICoords, startEvent: string,
    moveEvent: string, endEvent: string): void {
    addEventListenerPoly(startEvent, elem, (ev: Event) => {
        const initialCoords = coordsExtractor(ev)
        if (!initialCoords.valid) {
            return
        }
        const listenerConfig = startListener(ev, initialCoords.x, initialCoords.y)
        if (!listenerConfig.enabled) {
            return
        }
        listenerConfig.move(initialCoords.x, initialCoords.y)
        ev.preventDefault()

        const handleMove = (ev: Event) => {
            const coords = coordsExtractor(ev)
            if (!coords.valid) {
                return
            }
            listenerConfig.move(coords.x, coords.y)
        }
        const moveListener = (ev: Event) => {
            ev.preventDefault()
            const isOneMouseButtonActive = () => {
                const checkButtons = (ev: MouseEvent): boolean => {
                    if (isIE9OrBelow()) {
                        // browser is IE <= 9 and always returns buttons === 0 on mousemove and mouseup
                        return ev.buttons === 0
                    } else {
                        return ev.buttons === 1
                    }
                }
                return (ev as MouseEvent).buttons !== undefined && checkButtons((ev as MouseEvent))
            }
            const isOneTouchActive = () => {
                return (ev as TouchEvent).touches !== undefined && (ev as TouchEvent).touches.length === 1
            }
            if (!isOneMouseButtonActive() && !isOneTouchActive()) {
                upHandler(ev, false)
            } else {
                handleMove(ev)
            }
        }
        addEventListenerPoly(moveEvent, document, moveListener, true)

        const upHandler = (ev: Event, shouldHandleMove = true) => {
            if (shouldHandleMove) {
                handleMove(ev)
            }
            removeEventListenerPoly(moveEvent, document, moveListener, true)
            removeEventListenerPoly(endEvent, document, upHandler, true)
            listenerConfig.end()
        }
        addEventListenerPoly(endEvent, document, upHandler, true)
    })
}
