import type { Point, TouchState } from "./touchUtil"

export interface IPlayerScaled {
    height: number
    width: number
}

export interface IScrollInfo {distance: number}

export interface IScaleInfo {
    factor: number,
    pointX: number,
}

export enum SwipeUniqueState {
    Cancelled = 4,
}

export type SwipeState = SwipeUniqueState | TouchState

export interface ISwipeInfo {
    state: SwipeState,
    totalDistance: number,
    elapsedTime: number,
}

export interface IVideoHeightChangeInfo {
    distance: Point,
    startingPoint: Point,
}
