import { ArgJSONMap } from "@multimediallc/web-utils"
import { isIgnored } from "../cb/api/ignore"
import { addColorClass, removeColorClass } from "../cb/colorClasses"
import { handleDmInputFocus } from "../cb/components/pm/dmUtil"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { modalAlert } from "./alerts"
import { postCb } from "./api"
import { isNotLoggedIn } from "./auth"
import { Component } from "./defui/component"
import { EventRouter } from "./events"
import { isPrivateMessage } from "./messageInterfaces"
import { i18n } from "./translation"
import type { XhrError } from "./api"
import type { IChatConnection } from "./context"
import type { IPrivateMessage, IRoomMessage } from "./messageInterfaces"

export interface ISendReportResponse {
    success: boolean
    html?: string
}

export type ICategories = Record<string, string>

export class ChatReport extends Component {
    private activeRadioInput: HTMLInputElement | undefined
    private formFocusableElements: HTMLElement[] = []
    private endScreenFocusableElements: HTMLElement[] = []
    private isOnEndScreen = false
    protected form: HTMLFormElement
    protected commentInput: HTMLTextAreaElement
    private readonly buttonContainer: HTMLDivElement
    private ignoreUserLink: HTMLButtonElement
    private finishReportScreenDiv: HTMLDivElement
    private finishReportContainer: HTMLDivElement
    private userReportedMessage: HTMLDivElement

    // closeChatReportRequest should be called via the onChatReportClosed method to invoke possible overrides in subclasses.
    public closeChatReportRequest = new EventRouter<boolean>("closeChatReportRequest")
    // reportSent should be called via the onSendReport method to invoke possible overrides in subclasses.
    public reportSent = new EventRouter<undefined>("reportSent")
    public ignoreReportedUser = new EventRouter<undefined>("ignoreReportedUser")

    constructor(protected username: string, private message?: IRoomMessage, protected chatConnection?: IChatConnection) {
        super()
        addColorClass(this.element, "chatReport")
        this.element.style.position = "static"
        this.element.style.fontFamily = "UbuntuBold, Arial, Helvetica, sans-serif"
        this.element.style.fontSize = "11px"
        this.form = document.createElement("form")
        this.element.dataset.testid = "report-modal"
        const headerDiv = document.createElement("div")
        const commentInputContainer = document.createElement("div")
        this.commentInput = document.createElement("textarea")

        this.form.appendChild(headerDiv)
        this.form.style.width = "100%"
        addColorClass(headerDiv, "headerDiv")
        headerDiv.style.margin = "6px"
        headerDiv.style.fontSize = "13px"
        headerDiv.style.overflow = "hidden"
        headerDiv.style.textOverflow = "ellipsis"
        headerDiv.innerText = `${i18n.report} ${this.username}`
        const categories = this.categories()
        for (const category in categories) {
            const radioInputLabel = document.createElement("label")
            const radioInput = document.createElement("input")
            const radioInputText = document.createElement("span")
            radioInputLabel.style.display = "flex"
            radioInputLabel.style.margin = "6px 8px"
            radioInputLabel.style.alignItems = "center"
            radioInput.type = "radio"
            radioInput.style.margin = "0 4px 0 2px"
            radioInput.name = "chatReport"
            radioInput.value = category
            radioInputText.innerText = categories[category]
            radioInputText.style.flex = "1"
            radioInputLabel.appendChild(radioInput)
            radioInputLabel.appendChild(radioInputText)
            this.form.appendChild(radioInputLabel)
            radioInputLabel.onclick = () => {
                this.activeRadioInput = radioInput
            }
            this.formFocusableElements.push(radioInput)
        }
        commentInputContainer.style.margin = "6px 8px"
        this.commentInput.draggable = false
        this.commentInput.style.resize = "none"
        this.commentInput.style.boxSizing = "border-box"
        this.commentInput.style.height = "40px"
        this.commentInput.style.width = "100%"
        this.commentInput.style.borderWidth = "1px"
        this.commentInput.style.borderStyle = "solid"
        this.commentInput.maxLength = 255
        this.commentInput.oninput = () => {
            this.commentInput.value = this.commentInput.value.slice(0, 255) // polyfill maxLength for IE9
        }
        commentInputContainer.appendChild(this.commentInput)
        this.form.appendChild(commentInputContainer)
        this.formFocusableElements.push(this.commentInput)

        this.buttonContainer = document.createElement("div")
        this.buttonContainer.style.margin = "0 0 8px"
        this.buttonContainer.style.cssFloat = "right"
        this.buttonContainer.style.fontFamily = "UbuntuMedium, Arial, Helvetica, sans-serif"

        const submitButton = document.createElement("button")
        addColorClass(submitButton, "submitButton")
        submitButton.type = "submit"
        submitButton.innerText = i18n.reportCAPS
        submitButton.style.fontSize = "11px"
        submitButton.style.padding = "4px 6px 4px 6px"
        submitButton.style.marginRight = "8px"
        submitButton.style.borderRadius = "4px"
        submitButton.style.boxSizing = "border-box"
        submitButton.style.cursor = "pointer"
        submitButton.style.display = "inline-block"
        submitButton.dataset.testid = "submit-report-button"

        const cancelSpan = document.createElement("span")
        addColorClass(cancelSpan, "cancelSpan")
        cancelSpan.innerText = i18n.cancelCAPS
        cancelSpan.style.fontSize = "11px"
        cancelSpan.style.padding = "4px 6px 4px 6px"
        cancelSpan.style.marginRight = "8px"
        cancelSpan.style.borderRadius = "4px"
        cancelSpan.style.boxSizing = "border-box"
        cancelSpan.style.cursor = "pointer"
        cancelSpan.style.display = "inline-block"
        cancelSpan.dataset.testid = "cancel-report-button"

        this.buttonContainer.appendChild(cancelSpan)
        this.buttonContainer.appendChild(submitButton)
        this.form.appendChild(this.buttonContainer)
        this.formFocusableElements.push(cancelSpan)
        this.formFocusableElements.push(submitButton)

        this.createFinishReportScreen()

        addEventListenerPoly("submit", this.form, (event: Event) => {
            event.preventDefault()
            this.commentInput.blur()

            if (this.activeRadioInput === undefined) {
                modalAlert(i18n.selectReason, () => this.focusForm())
                return
            } else if (this.activeRadioInput.value === "other" && this.commentInput.value.trim() === "") {
                modalAlert(i18n.reportAbuseDescriptionRequired, () => this.focusCommentBox())
                return
            }

            this.sendReport().then((response) => {
                if (response.success) {
                    this.setEndScreenText(i18n.userHasBeenReported(this.username))
                } else {
                    this.setEndScreenText(i18n.unableToReport)
                }
            }).catch((err: XhrError) => {
                if (err.xhr.status === 403) {
                    this.setEndScreenText(i18n.tooManyReports)
                } else {
                    this.setEndScreenText(i18n.unableToReport)
                }
            })
            this.onSendReport()
            this.showEndReportScreen()
        })

        addEventListenerPoly("click", cancelSpan, (event: Event) => {
            event.preventDefault()
            const wasFormSubmitted = false
            this.onChatReportClosed(wasFormSubmitted)
        })

        this.element.appendChild(this.form)
        this.element.appendChild(this.finishReportScreenDiv)

        addEventListenerPoly("keydown", this.element, this.trapFocus)
    }

    protected categories(): ICategories {
        if (this.message === undefined) {
            error("ChatReport error: expected categories override")
            return {}
        }
        if (isPrivateMessage(this.message) && this.message.mediaList.length > 0) {
            return {
                "inappropriate": i18n.reportMessageInappropriate,
                "rude": i18n.reportMessageRudeToBcaster,
                "spam": i18n.reportMessageSpam,
                "photo": i18n.reportMessageOffensiveMedia,
                "other": i18n.reportMessageOther,
            }
        }
        return {
            "inappropriate": i18n.reportMessageInappropriate,
            "rude": i18n.reportMessageRudeToBcaster,
            "spam": i18n.reportMessageSpam,
            "other": i18n.reportMessageOther,
        }
    }

    protected additionalFinishReportLinks(): HTMLButtonElement[] {
        return []
    }

    private showEndReportScreen(): void {
        const width = this.element.offsetWidth
        const height = this.element.offsetHeight
        this.form.style.display = "none"
        this.finishReportScreenDiv.style.display = "block"
        this.finishReportScreenDiv.style.width = `${width}px`
        this.finishReportScreenDiv.style.height = `${height}px`
        const textHeight = this.finishReportContainer.offsetHeight
        this.finishReportContainer.style.padding = `${height / 2 - textHeight / 2}px 0 0` // center text vertically
        this.isOnEndScreen = true
    }

    private static parseAbuseReportResult(response: string): ISendReportResponse {
        const p = new ArgJSONMap(response)
        return {
            success: p.getStringOrUndefined("result") === "success",
            html: p.getStringOrUndefined("html"),
        } as ISendReportResponse
    }

    protected reportEndpoint(): string {
        if (this.message === undefined) {
            error("ChatReport error: expected reportEndpoint override")
            return ""
        }
        return `chatmessages/report/${this.message.messageID}/`
    }

    private sendReport(): Promise<ISendReportResponse> {
        return new Promise((resolve, reject) => {
            const category = this.activeRadioInput === undefined ? "" : this.activeRadioInput.value
            const additionalComments = this.commentInput.value
            postCb(this.reportEndpoint(), {
                "spammer": this.username,
                "category": category,
                "additional_comments": additionalComments,
            }).then((xhr) => {
                resolve(ChatReport.parseAbuseReportResult(xhr.responseText))
            }).catch((err: XhrError) => {
                reject(err)
            })
        })
    }

    public resetForm(): void {
        this.form.reset()
    }

    private createFinishReportScreen(): void {
        this.finishReportScreenDiv = document.createElement("div")
        this.finishReportContainer = document.createElement("div")
        this.userReportedMessage = document.createElement("div")
        this.ignoreUserLink = document.createElement("button")
        const additionalLinks = this.additionalFinishReportLinks()
        const closeReportLink = document.createElement("button")

        this.endScreenFocusableElements.push(this.ignoreUserLink)
        for (const link of additionalLinks) {
            this.endScreenFocusableElements.push(link)
        }
        this.endScreenFocusableElements.push(closeReportLink)

        this.userReportedMessage.innerText = i18n.sending
        this.userReportedMessage.style.margin = "0 0 8px 0"
        this.userReportedMessage.style.textAlign = "center"

        addColorClass(this.ignoreUserLink, "ignoreUserLink")
        addColorClass(closeReportLink, "closeReportLink")
        this.ignoreUserLink.style.textAlign = "center"
        this.ignoreUserLink.style.fontSize = "13px"
        this.ignoreUserLink.style.display = "block"
        this.ignoreUserLink.style.margin = "0 auto 8px"
        this.ignoreUserLink.style.background = "none"
        this.ignoreUserLink.style.border = "none"
        this.ignoreUserLink.style.cursor = "pointer"
        this.ignoreUserLink.dataset.testid = "ignore-user-link"
        this.updateIgnoreText(isIgnored(this.username))

        for (const link of additionalLinks) {
            link.style.textAlign = "center"
            link.style.fontSize = "13px"
            link.style.display = "block"
            link.style.margin = "0 auto 8px"
            link.style.background = "none"
            link.style.border = "none"
            link.style.cursor = "pointer"
        }

        closeReportLink.innerText = i18n.close
        closeReportLink.style.textAlign = "center"
        closeReportLink.style.fontSize = "13px"
        closeReportLink.style.display = "block"
        closeReportLink.style.margin = "0 auto 8px"
        closeReportLink.style.background = "none"
        closeReportLink.style.border = "none"
        closeReportLink.style.cursor = "pointer"
        closeReportLink.dataset.testid = "close-report-link"
        this.underlineOnHover(closeReportLink)

        this.finishReportScreenDiv.style.display = "none"
        this.finishReportContainer.style.fontSize = "13px"
        this.finishReportContainer.style.fontFamily = "Arial, Helvetica, sans-serif"
        this.finishReportContainer.appendChild(this.userReportedMessage)
        if (this.shouldShowIgnore()) {
            this.finishReportContainer.appendChild(this.ignoreUserLink)
        }
        for (const link of additionalLinks) {
            this.finishReportContainer.appendChild(link)
        }
        this.finishReportContainer.appendChild(closeReportLink)
        this.finishReportScreenDiv.appendChild(this.finishReportContainer)

        addEventListenerPoly("click", closeReportLink, (event: Event) => {
            event.preventDefault()
            const wasFormSubmitted = true
            this.onChatReportClosed(wasFormSubmitted)
        })
    }

    private setEndScreenText(msg: string): void {
        this.userReportedMessage.innerText = msg
    }

    private disableIgnoreTextInteraction(): void {
        this.ignoreUserLink.disabled = true
        this.ignoreUserLink.style.cursor = "default"
        this.ignoreUserLink.style.textDecoration = "none"
        this.ignoreUserLink.onmouseenter = () => {}
        this.ignoreUserLink.onmouseleave = () => {}
        this.ignoreUserLink.onclick = () => {}
        addColorClass(this.ignoreUserLink, "disabled")
        const index = this.endScreenFocusableElements.indexOf(this.ignoreUserLink)
        if (index !== -1) {
            this.endScreenFocusableElements.splice(index, 1)
        }
        this.ignoreUserLink.blur()
    }

    private focusNextElement(elements: HTMLElement[], isShift: boolean): void {
        if (!(document.activeElement instanceof HTMLElement)) {
            this.focusForm()
            return
        }
        const index = elements.indexOf(document.activeElement)
        if (index === -1) {
            this.focusForm()
            return
        }
        let nextIndex: number
        if (isShift) { // shift + tab
            nextIndex = index - 1
            if (nextIndex < 0) {
                nextIndex += elements.length
            }
        } else { // tab
            nextIndex = (index + 1) % elements.length
        }
        elements[nextIndex].focus()
    }

    public updateIgnoreText(isIgnored: boolean): void {
        if (isIgnored) {
            this.ignoreUserLink.innerText = i18n.userIsIgnored(this.username)
            this.disableIgnoreTextInteraction()
        } else {
            this.ignoreUserLink.innerText = i18n.ignoreUser(this.username)
            removeColorClass(this.ignoreUserLink, "disabled")
            this.underlineOnHover(this.ignoreUserLink)
            this.ignoreUserLink.disabled = false
            this.ignoreUserLink.onclick = () => {
                if (isNotLoggedIn()) {
                    this.ignoreUserLink.innerText = i18n.loginForIgnore
                } else {
                    this.onIgnoreUser()
                    this.ignoreUserLink.innerText = i18n.userIsIgnored(this.username)
                    this.ignoreReportedUser.fire(undefined)
                }
                this.disableIgnoreTextInteraction()
            }
        }
    }

    private focusCommentBox(): void {
        this.commentInput.focus()
    }

    public focusForm(): void {
        if (this.isOnEndScreen) {
            this.endScreenFocusableElements[0].focus()
        } else {
            this.formFocusableElements[0].focus()
        }
    }

    public tearDown(): void {
    }

    private trapFocus = (event: KeyboardEvent) => {
        // All keystrokes are intercepted in FVM (see fullvideolib/eventListeners.ts).
        // So in order to tab/type/enter, all keystrokes must stop propagation.
        event.stopPropagation()
        if (event.keyCode === 27) { // ESC
            this.onChatReportClosed(false)
            return
        } else if (!(event.keyCode === 9)) { // not tab
            return
        }
        event.preventDefault()
        if (this.isOnEndScreen) {
            this.focusNextElement(this.endScreenFocusableElements, event.shiftKey)
        } else {
            this.focusNextElement(this.formFocusableElements, event.shiftKey)
        }
    }

    private underlineOnHover(element: HTMLElement): void {
        element.onmouseenter = () => {
            element.style.textDecoration = "underline"
        }
        element.onmouseleave = () => {
            element.style.textDecoration = "none"
        }
    }

    protected shouldShowIgnore(): boolean {
        return this.chatConnection !== undefined && this.chatConnection.username() !== this.chatConnection.room()
    }

    // Overridable to allow sub-implementations to handle ignoring on their own
    protected onIgnoreUser(): void {
        if (this.chatConnection !== undefined) {
            this.chatConnection.ignore(this.username)  // eslint-disable-line @typescript-eslint/no-floating-promises
        }
    }

    protected onSendReport(): void {
        this.reportSent.fire(undefined)
    }

    protected onChatReportClosed(wasFormSubmitted: boolean): void {
        this.closeChatReportRequest.fire(wasFormSubmitted)
    }
}

export class DmsReport extends ChatReport {
    constructor(username: string, message: IPrivateMessage, private ignoreFunc: () => void) {
        super(username, message)
        this.element.style.minWidth = "188px"
        this.element.style.maxWidth = "268px"
        addEventListenerPoly("pointerdown", this.commentInput, (e: PointerEvent) =>  handleDmInputFocus(e))
    }

    protected categories(): ICategories {
        return {
            "inappropriate": i18n.inappropriateMessage,
            "rude": i18n.rudeMessage,
            "spam": i18n.spamMessage,
            "other": i18n.reportMessageOther,
        }
    }

    protected shouldShowIgnore(): boolean {
        return true
    }
    protected onIgnoreUser(): void {
        this.ignoreFunc()
    }
}
