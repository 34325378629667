export class OrderedSet<T> {
    private backingArray: T[] = []

    constructor() {
    }

    addToTop(elem: T): void {
        this.remove(elem)
        this.backingArray.unshift(elem)
    }

    addToBottom(elem: T): void {
        this.remove(elem)
        this.backingArray.push(elem)
    }

    toArray(): T[] {
        return ([] as T[]).concat(this.backingArray)
    }

    remove(elem: T): void {
        const index = this.backingArray.indexOf(elem)
        if (index !== -1) {
            this.backingArray.splice(index, 1)
        }
    }

    popFromTop(): T | undefined {
        return this.backingArray.shift()
    }

    popFromBottom(): T | undefined {
        return this.backingArray.pop()
    }
}