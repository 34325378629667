import { isPortrait } from "@multimediallc/web-utils"
import { isiOS } from "@multimediallc/web-utils/modernizr"
import { applyStyles } from "../DOMutils"
import { isFullscreen } from "../fullscreen"
import { RoomStatusNotifier } from "../roomStatusNotifier"
import { styleTransition } from "../safeStyle"
import { isVirtualKeyboardLikelyShowing } from "../virtualKeyboardUtil"
import { inputDivHeight } from "./chatContents"
import { LayoutConstraints } from "./layoutConstraints"
import { getViewportHeight, getViewportWidth } from "./viewportDimension"

import type { IChatConnection } from "../context"
import type { RoomStatus } from "../roomStatus"

const MIN_LANDSCAPE_WIDTH = 200

export class MobileRoomStatusNotifier extends RoomStatusNotifier {
    public layoutConstraints = new LayoutConstraints(this.constructor.name)
    private playerIsBottomPositioned: boolean
    private androidKeyboardViewportHeight = 0 // potentially a good candidate for factoring out into a util

    constructor() {
        super()
        applyStyles(this.element, {
            WebkitTextSizeAdjust: "none",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
        })

        this.layoutConstraints.setOnUpdate(() => {
            const transitionTime = this.layoutConstraints.transitionTime()
            styleTransition(this.element, `top ${transitionTime}ms, width ${transitionTime}ms`)
            this.repositionChildren()
        })
    }

    // eslint-disable-next-line complexity
    protected repositionChildren(): void {
        super.repositionChildren()

        if (this.parent === undefined) {
            return
        }

        const parentRect = this.parent.element.getBoundingClientRect()

        const usableWidth = parentRect.width - (isPortrait() ? 0 : this.layoutConstraints.right())
        this.element.style.width = `${usableWidth}px`

        this.element.style.top = ""
        this.element.style.bottom = ""
        // The three major ways we can be positioned, corresponding to each `if` case below, are:
        //   1. Virtual keyboard is showing in non purechat portrait, ie when the player gets moved to be positioned directly above the input
        //   2. Virtual keyboard is showing in purechat portrait
        //   3. Virtual keyboard is not showing, or we are in landscape
        // Cases 1 and 3 want this.element centered vertically in the player, minus whatever portion may be covered by purechat.
        // In case 2, centering this.element vertically will generally put it off the top of the screen, so instead it is placed directly above purechat
        if (this.playerIsBottomPositioned) {
            this.element.style.bottom = `${inputDivHeight() + .5 * (parentRect.height - this.element.offsetHeight)}px`
        } else if (isVirtualKeyboardLikelyShowing() && isPortrait()) {
            // Bottom positioning gets busted on android fullscreen with the keyboard up
            if (isiOS() || !isFullscreen()) {
                this.element.style.bottom = `${this.layoutConstraints.bottom()}px`
            } else {
                // Android's bottom bar creates complications for bottom positioning and for visualViewport.height, so stabilize androidKeyboardViewportHeight
                // to a fixed value each time the keyboard is opened, and use top positioning with extra space to avoid bottom positioning issues
                if (this.androidKeyboardViewportHeight === 0) {
                    this.androidKeyboardViewportHeight = visualViewport ? visualViewport.height : screen.height - 350
                }
                const bufferPx = 44
                this.element.style.top = `${this.androidKeyboardViewportHeight - this.layoutConstraints.bottom() - this.element.offsetHeight - bufferPx}px`
            }
        } else {
            // Since the player can be resized, the relevant part of the bottom constraint is the amount that purechat actually overlaps the video
            const relevantBottomConstraint = Math.max(0, this.layoutConstraints.bottom() - (getViewportHeight() - parentRect.height))
            const usableHeight = parentRect.height - (isPortrait() ? relevantBottomConstraint : 0)
            this.element.style.top = `${parentRect.top + .5 * (usableHeight - this.element.offsetHeight)}px`
        }

        if (!isVirtualKeyboardLikelyShowing()) {
            this.androidKeyboardViewportHeight = 0
        }

        // Set the left-right padding a value between 50 and 0. The smaller the player, the less the padding
        let padding = Math.min(50, (parentRect.width - getViewportWidth() + 100) / 2)
        padding = Math.max(0, padding)
        this.element.style.padding = `4px ${padding}px 12px`
    }

    public setPlayerIsBottomPositioned(isBottomPositioned: boolean): void {
        this.playerIsBottomPositioned = isBottomPositioned
        this.repositionChildren()
    }

    public showElement(): void {
        super.showElement("flex")
    }

    protected resizeRoomStatusText(parentWidth: number): void {
        const reset = () => {
            this.header.style.fontSize = "20px"
            this.header.style.marginBottom = "20px"
            this.body.style.fontSize = "12px"
        }

        if (isPortrait() && parentWidth < getViewportWidth()) {
            const widthRatio = parentWidth/getViewportWidth()*100

            if (widthRatio <= 47) {
                this.header.style.fontSize = "15px"
                this.header.style.marginBottom = "0px"
                this.body.style.fontSize = "8px"
            } else if (widthRatio <= 50) {
                this.header.style.fontSize = "15px"
                this.header.style.marginBottom = "0px"
                this.body.style.fontSize = "10px"
            } else if (widthRatio <= 75) {
                this.header.style.fontSize = "15px"
                this.header.style.marginBottom = "12px"
                this.body.style.fontSize = "10px"
            } else {
                reset()
            }
        } else {
            reset()
        }
    }

    protected handleStatusChange(chatConnection: IChatConnection, status: RoomStatus): void {
        super.handleStatusChange(chatConnection, status)
        const newConstraints = {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            transitionTime: 0,
        }
        if (this.element.style.display !== "none") {
            newConstraints.top = this.element.offsetHeight
            newConstraints.left = MIN_LANDSCAPE_WIDTH
        }
        this.layoutConstraints.setConstraints(newConstraints)
    }

    public hideConnectingStatus(): void {
        super.hideConnectingStatus()
        if (this.element.style.display === "none") {
            this.layoutConstraints.setConstraints({
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                transitionTime: 0,
            })
        }
    }
}
