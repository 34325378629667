// addEventListenerPoly and removeEventListenerPoly are polyfills for attaching listeners to the DOM. They're similar to the
// base functions, except we pass in the element we want to add the listener to as an argument (elem).

import { isPassiveListenerSupported } from "@multimediallc/web-utils/modernizr"
import type { WebkitEventMap } from "@multimediallc/web-utils/modernizr"

type ListenerBindingObject = HTMLElement | Document | Window

const maxListeners = 42
const windowListeners: Record<string, number> = {}
const documentListeners: Record<string, number> = {}

// IE8 and other weird browsers polyfill
// this does the best job it can to actually get the event and avoid any errors that stop script execution on IE8

function numberOrZero(n: number|string|null): number {
    if (Number.isNaN(Number(n))) {
        return 0
    }
    return Number(n)
}

type AllEventsMap = ElementEventMap & DocumentEventMap & WindowEventMap & WebkitEventMap

export function addEventListenerPoly<K extends keyof AllEventsMap>(event: K | string, elem: ListenerBindingObject, fn: (ev: AllEventsMap[K]) => void, useCapture = false, usePassive = false): void {
    let listenerCount: number
    if (elem === window) {
        listenerCount = windowListeners[event] = numberOrZero(windowListeners[event]) + 1
    } else if (elem === document) {
        listenerCount = documentListeners[event] = numberOrZero(documentListeners[event]) + 1
    } else {
        if (elem instanceof HTMLElement) {
            const key = `data-listener-count-${event}`
            listenerCount = numberOrZero(elem.getAttribute(key)) + 1
            elem.setAttribute(key, `${listenerCount}`) // eslint-disable-line @multimediallc/no-set-attribute
        } else {
            listenerCount = 1
            debug(`EventListener attached to non-HTMLElement object (possibly frame document): ${elem}`)
        }
    }

    if (listenerCount > maxListeners) {
        let elementInfo: string
        if (elem === document) {
            elementInfo = "document"
        } else if (elem === window) {
            elementInfo = "window"
        } else {
            elementInfo = `${(elem as HTMLElement).outerHTML.split(">")[0]}>`
        }
        warn("Too many event listeners on element", {
            event: event,
            element: elementInfo,
            listeners: listenerCount,
            maxListeners: maxListeners,
        })
    }

    if (window.addEventListener !== undefined) {
        const opts = isPassiveListenerSupported() ? { capture: useCapture, passive: usePassive } : useCapture
        elem.addEventListener(event, fn, opts)
    } else if (window.attachEvent !== undefined) {
        elem.attachEvent(`on${event}`, fn)
    } else {
        error(`Could not resolve addEventListenerPoly( ${event} ) to ${elem}`)
    }
}

export function addEventListenerMultiPoly(events: string[], elem: ListenerBindingObject, fn: EventListener, useCapture = false, usePassive = false): void {
    for (const event of events) {
        addEventListenerPoly(event as keyof AllEventsMap, elem, fn, useCapture, usePassive)
    }
}

export function removeEventListenerPoly<K extends keyof AllEventsMap>(event: K | string, elem: ListenerBindingObject, fn: (ev: AllEventsMap[K]) => void, useCapture = false, usePassive = false): void {
    let listenerCount: number
    if (elem === window) {
        listenerCount =  windowListeners[event] = numberOrZero(windowListeners[event]) - 1
    } else if (elem === document) {
        listenerCount = documentListeners[event] = numberOrZero(documentListeners[event]) - 1
    } else {
        if (elem instanceof HTMLElement) {
            const key = `data-listener-count-${event}`
            listenerCount = numberOrZero(elem.getAttribute(key)) - 1
            elem.setAttribute(key, `${listenerCount}`) // eslint-disable-line @multimediallc/no-set-attribute
        } else {
            listenerCount = 1
            debug(`EventListener removed from non-HTMLElement object (possibly frame document): ${elem}`)
        }
    }

    if (listenerCount < 0) {
        warn(`event listener "${event}" removed without being added`, { "listeners": listenerCount })
    }

    if (window.removeEventListener !== undefined) {
        const opts = isPassiveListenerSupported() ? { capture: useCapture, passive: usePassive } : useCapture
        elem.removeEventListener(event, fn, opts)
    } else if (window.detachEvent !== undefined) {
        elem.detachEvent(`on${event}`, fn)
    } else {
        error(`Could not resolve removeEventListenerPoly( ${event} ) from ${elem}`)
    }
}

export function onceEventListenerPoly<K extends keyof AllEventsMap>(event: K, elem: ListenerBindingObject, fn: (ev: AllEventsMap[K]) => void, useCapture = false, usePassive = false): void {
    // needs to be any for any type of event to be accepted in AllEventsMap
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const selfCleaningFn = (e: any) => {
        fn(e)
        removeEventListenerPoly(event, elem, selfCleaningFn, useCapture, usePassive)
    }
    addEventListenerPoly(event, elem, selfCleaningFn, useCapture, usePassive)
}

// A helper that wraps an addEventListenerPoly call with a onceEventListenerPoly call, causing the passed listener
// to only get bound after the first time the given event is triggered on elem, such that it only fires for the
// second and subsequent triggers of the event. It's kind of like the complement of onceEventListenerPoly().
export function addDeferedEventListenerPoly<K extends keyof AllEventsMap>(event: K, elem: ListenerBindingObject, fn: (ev: AllEventsMap[K]) => void, useCapture = false, usePassive = false): void {
    onceEventListenerPoly(event, elem, () => {
        addEventListenerPoly(event, elem, fn, useCapture, usePassive)
    }, useCapture, usePassive)
}
