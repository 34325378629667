import { addColorClass } from "../../cb/colorClasses"
import { dismissibleSettingsLinkClass, setupDismissibleSettingsLink } from "../chatSettingsUtil"
import { applyStyles } from "../DOMutils"
import { closeBtnSvg } from "../svgElement"
import { DismissibleMessages } from "../theatermodelib/dismissibleMessages"
import { dom } from "../tsxrender/dom"
import type { IDismissibleMessage } from "../theatermodelib/dismissibleMessages"

export class MobileDismissibleMessages extends DismissibleMessages {
    constructor() {
        super()
        addColorClass(this.element, "MobileDismissibleMessages")
        applyStyles(this.element, {
            height: "",
            position: "fixed",
            top: "40px",
        })
    }

    protected render(message: IDismissibleMessage): void {
        const messageContainer: HTMLDivElement =
            <MessageContainer
                onCloseButtonClick={() => this.dismissMessage(messageContainer, message)}
                message={message}
            />
        this.element.appendChild(messageContainer)
    }

    protected dismissMessage(messageContainer: HTMLDivElement, message: IDismissibleMessage): void {
        super.dismissMessage(messageContainer, message)
    }
}

const CloseButton = (props: { onClick: () => void }): HTMLAnchorElement => {
    const button = closeBtnSvg("12px")
    addColorClass(button, "closeButton")
    applyStyles(button, { marginRight: "15px" })
    button.dataset.testid = "dismissible-message-dismiss"
    button.onclick = props.onClick

    return button
}

const MessageContainer = (props: {
    onCloseButtonClick: () => void,
    message: IDismissibleMessage,
}): HTMLDivElement => {
    const messageContainerStyle: CSSX.Properties = {
        alignItems: "center",
        padding: "8px 15px",
        fontFamily: "UbuntuMedium, Helvetica, Arial, sans-serif",
        fontSize: "14px",
        lineHeight: "16px",
    }

    return (
        <div colorClass="messageContainer" style={messageContainerStyle}>
            <CloseButton onClick={props.onCloseButtonClick} />
            <Message message={props.message} />
        </div>
    )
}

const Message = (props: { message: IDismissibleMessage }): HTMLDivElement => {
    const containerStyle: CSSX.Properties = {
        flex: 1,
        maxHeight: "15vh",
        overflowY: "scroll",
    }

    const container: HTMLDivElement = 
        <div
            colorClass="message"
            data-testid="dismissible-message-text"
            style={containerStyle}
            dangerouslySetInnerHTML={{ __html: props.message.messageHtml }}>
        </div>
    

    for (const link of container.getElementsByTagName("a")) {
        addColorClass(link, "link")

        if (link.href !== "javascript:;") {
            link.target = "_blank"
            link.rel = "noopener noreferrer"
        }

        if (link.classList.contains(dismissibleSettingsLinkClass)) {
            setupDismissibleSettingsLink(link)
        }
    }

    for (const image of container.getElementsByTagName("img")) {
        image.style.maxWidth = "100%"
    }
    return container
}
