import countryJson from "flag-icons/country.json"
import { ReactWrapper } from "../ReactWrapper"

type CountryData = {
    capital?: string,
    code: string,
    continent?: string,
    flag_1x1: string,
    flag_4x3: string,
    iso: boolean,
    name: string,
}

const INVALID_COUNTRY_DATA = ""

const UNKNOWN_COUNTRY_CODE = "xx"

export function getCountryData(countryCode: string | undefined): { flagCode: string, flagName: string } {
    const validCountryData = countryJson.find((country: CountryData) =>
        country.code === countryCode,
    )

    return {
        flagCode: validCountryData?.code ?? INVALID_COUNTRY_DATA,
        flagName: validCountryData?.name ?? INVALID_COUNTRY_DATA,
    }
}

export function getValidCountryData(countryCode: string | undefined): { flagCode: string, flagName: string } {
    let validCountryData = getCountryData(countryCode)
    if (validCountryData.flagCode === UNKNOWN_COUNTRY_CODE) {
        validCountryData = {
            flagCode: INVALID_COUNTRY_DATA,
            flagName: INVALID_COUNTRY_DATA,
        }
    }

    return validCountryData
}

export function addCountryFlags(): void {
    const flagSpans: NodeListOf<HTMLSpanElement> = document.querySelectorAll(".room_list_room span[data-country]")
    flagSpans.forEach(flagSpan => {
        const { flagCode, flagName } = getValidCountryData(flagSpan.dataset.country)
        if (flagCode && flagName) {
            flagSpan.appendChild(new ReactWrapper({
                component: "FlagIcon",
                componentProps: {
                    countryCode: flagCode,
                    countryName: flagName,
                },

            }).render())
        } else {
            flagSpan.remove()
        }
    })
}
export function isBlockedCountry(countryCode: string): boolean {
    return ["ru", UNKNOWN_COUNTRY_CODE, INVALID_COUNTRY_DATA].includes(countryCode)
}
