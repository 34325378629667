import { ArgJSONMap } from "@multimediallc/web-utils"
import { pageContext } from "../cb/interfaces/context"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { getCb } from "./api"
import { isAnonymous } from "./auth"
import { BadgeType, ChatBadgeManager } from "./chatBadgeManager"
import { noteIconSvg } from "./svgElement"
import { i18n } from "./translation"

export class UserNotesBadgeManager {
    private static singletonInstance: UserNotesBadgeManager | undefined
    private usersWithNotes = new Set<string>()

    private constructor() {
        // Don't load notes on mobile since there's access to the notes there.
        if (isAnonymous() || pageContext.current.isMobile) {
            return
        }

        this.getUsernames(false)

        addEventListenerPoly("focus", window, () => {
            this.getUsernames()
        })

        ChatBadgeManager.registerGenerator(BadgeType.Notes, (username) =>
            this.createChatBadge(username),
        )
    }

    private getUsernames(updateExistingBadges = true) {
        getCb("api/notes/usernames/")
            .then((xhr) => {
                const data = new ArgJSONMap(xhr.responseText)
                const usernames: string[] = JSON.parse(
                    data.getObjectString("usernames"),
                )
                if (updateExistingBadges) {
                    this.usersWithNotes.forEach((username) => usernames.includes(username) || this.removeUser(username))
                    usernames.forEach((username) => this.usersWithNotes.has(username) || this.addUser(username))
                } else {
                    this.usersWithNotes.clear()
                    usernames.forEach((username) => {
                        this.usersWithNotes.add(username)
                    })
                }
            })
            .catch((err) => {
                error(
                    "Could not retrieve list of users for which currentUser has notes on",
                )
            })
    }

    public static getOrCreateInstance(): UserNotesBadgeManager {
        if (UserNotesBadgeManager.singletonInstance === undefined) {
            UserNotesBadgeManager.singletonInstance = new UserNotesBadgeManager()
        }
        return UserNotesBadgeManager.singletonInstance
    }

    public addUser(username: string): void {
        this.usersWithNotes.add(username)
        this.updateChatBadges(username)
    }

    public removeUser(username: string): void {
        if (this.usersWithNotes.has(username)) {
            this.usersWithNotes.delete(username)
            this.updateChatBadges(username)
        }
    }

    private createChatBadge(username: string): HTMLSpanElement {
        const notesIconContainer = document.createElement("span")
        notesIconContainer.style.display = this.usersWithNotes.has(username) ? "" : "none"
        notesIconContainer.style.marginRight = ".1em"
        notesIconContainer.title = i18n.notes

        const notesIcon = noteIconSvg()
        notesIconContainer.appendChild(notesIcon)

        return notesIconContainer
    }

    private updateChatBadges(username: string): void {
        const display = this.usersWithNotes.has(username)
        const badges = ChatBadgeManager.getBadgeElements(BadgeType.Notes, username) as HTMLSpanElement[]
        badges.forEach((el: HTMLSpanElement) => {
            el.style.display = display ? "inline-block" : "none"
        })
    }
}
