import { Component } from "../../../common/defui/component"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import type { ConversationType } from "../../api/pm"

export interface IPMAnnouncement {
    username: string
    PMChatLink: ChatLinkBase
}

interface IChatLinkConfig {onClick: () => void}

class ChatLinkBase extends Component {
    public openConversationElement: HTMLElement

    constructor(protected config: IChatLinkConfig) {
        super()
    }

    protected clickCallback = (event: Event): void => {
        event.stopPropagation()
        this.config.onClick()
    }
}

interface IMobileChatLinkConfig extends IChatLinkConfig {conversationType: ConversationType}

export class MobileChatLink extends ChatLinkBase {
    constructor(protected config: IMobileChatLinkConfig) {
        super(config)

        this.openConversationElement = <a
            className="mobileChatLink msg-link underline-link"
            data-conversation-type={this.config.conversationType}
            style={{
                textDecoration: "none",
                cursor: "pointer",
            }}
        >{` (${i18n.openConversationLower})`}</a>
        this.openConversationElement.onclick = this.clickCallback
    }
}

export class DesktopDMChatLink extends ChatLinkBase {
    constructor(config: IChatLinkConfig) {
        super(config)

        const openConversationSpan = <a
            className="msg-link underline-link"
            onClick={this.clickCallback}
            style={{ cursor: "pointer" }}
        >
            {i18n.openConversationLower}
        </a>
        this.openConversationElement = <span style={{
            fontWeight: "bold",
            textDecoration: "",
        }}> ({openConversationSpan})</span>
    }
}

export class DesktopPMChatLink extends ChatLinkBase {
    constructor(config: IChatLinkConfig) {
        super(config)

        this.openConversationElement = <span
            dangerouslySetInnerHTML={{ __html: i18n.newPrivateMessageClickPrompt }}
            onClick={this.clickCallback}
            style={{
                fontWeight: "bold",
                textDecoration: "",
            }}
        />
    }
}
