import { isMobileSpaActive, PageType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { isMobileDevice } from "@multimediallc/web-utils/modernizr"
import { EventRouter } from "../../../common/events"
import { addPageAction } from "../../../common/newrelic"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { pageContext } from "../../interfaces/context"
import { BaseSuggestedItem, SuggestionClicked } from "./baseSuggestedItem"
import { ResultType } from "./constants"
import { deleteFromSearchHistory, saveSearchHistory } from "./searchHistory"
import type { ISuggestionProps } from "./baseSuggestedItem"


export const historyDeleteClicked = new EventRouter<string>("historyDeleteClicked")

const mobileDeleteStyle: CSSX.Properties = {
    width: "23px",
    height: "23px",
    display: "block",
    position: "absolute",
    top: "20%",
    left: "unset",
    right: "2px",
    backgroundPosition: "50% top",
    backgroundSize: "14px 14px",
}

export class HistorySuggestedItem extends BaseSuggestedItem {
    protected getIconClassName(): string {
        return "icon-history"
    }

    protected getStatusText(): string {
        return i18n.previouslySearched
    }

    protected getRowClassName(): string {
        return "history_suggestion"
    }

    protected onClickHandler(props: ISuggestionProps, event?: MouseEvent): void {
        const attributes = { "resultType": "history", "resultString": props.labelText }
        addPageAction("SearchBarResultsClick", attributes)
        if (props.href.includes("/tag/")) {
            saveSearchHistory(props.labelText, ResultType.Hashtag)
            if (event !== undefined) {
                if (!pageContext.current.isMobile || isMobileSpaActive()) {
                    // SPA handling only needed on roomlist pages, otherwise default behavior is as desired
                    if (UrlState.current.state.pageType === PageType.HOME) {
                        event.preventDefault()
                        UrlState.current.navigateTo(props.href)
                    }
                }
            }
        } else if (props.href !== "") {
            saveSearchHistory(props.labelText, ResultType.Online)
        } else {
            SuggestionClicked.fire(props.labelText)
        }
    }

    protected createElement(props: ISuggestionProps): HTMLAnchorElement {
        const main = super.createElement(props)
        // Add History Delete icon to label container
        const mobileStyle = isMobileDevice() ? mobileDeleteStyle : {}
        const historyDelete = <div style={mobileStyle}
            className="historyDelete"
            onClick={(e: MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
            }}
            onMouseDown={(evt: MouseEvent) => {
                if (evt.ctrlKey || evt.metaKey || evt.button === 2) {
                    return
                }
                deleteFromSearchHistory(props.labelText)
                historyDeleteClicked.fire(props.labelText)
                evt.preventDefault()
                evt.stopPropagation()
            }}>
        </div>
        const row = main.firstElementChild
        if (row !== null) {
            const labelContainer = row.lastElementChild
            if (labelContainer !== null) {
                labelContainer.appendChild(historyDelete)
            }
        }
        return main
    }
}
