import { createDivot } from "../../../common/divot"
import { addColorClass } from "../../colorClasses"
import type { DivotPosition } from "../../../common/divot"

export interface ITooltip {
    content: string
    hasHTML: boolean
    width?: string | number
    divotPosition: DivotPosition
    divotLeftOrTop?: string // Don't forget units (eg px)
    borderWidth?: number
    onAfterDismiss?: () => void
}


export function buildTooltip(tooltipParams: ITooltip): HTMLDivElement {
    const tooltip = document.createElement("div")
    addColorClass(tooltip, "tooltip")
    if (tooltipParams.hasHTML) {
        // eslint-disable-next-line @multimediallc/no-inner-html
        tooltip.innerHTML = tooltipParams.content
    } else {
        tooltip.textContent = tooltipParams.content
    }

    if (tooltipParams.width !== undefined) {
        tooltip.style.width = typeof tooltipParams.width == "number" ? `${tooltipParams.width}px` : tooltipParams.width
    }
    tooltip.style.borderWidth = "1px"
    tooltip.style.position = "absolute"
    tooltip.style.borderStyle = "solid"
    tooltip.style.borderRadius = "4px"
    tooltip.style.fontSize = "14px"
    tooltip.style.padding = "8px"
    tooltip.style.display = "none"
    tooltip.style.zIndex = "5"

    const divot = createDivot(tooltipParams.divotPosition, tooltipParams.divotLeftOrTop, tooltipParams.borderWidth)
    if (divot !== undefined) {
        tooltip.appendChild(divot)
    }

    return tooltip
}
