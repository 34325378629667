import { range } from "../../../common/arrayUtils"
import { Debouncer, DebounceTypes } from "../../../common/debouncer"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { EventRouter } from "../../../common/events"
import { dom } from "../../../common/tsxrender/dom"
import { DirectionalNavigationButton } from "./directionalButton"
import { PageNumber } from "./pageNumber"

// Handles page numbers shown and how to handle ellipses
interface IPageNumberSequenceInfo {
    pageNumbers: number[]
    showPreviousEllipses: boolean
    showNextEllipses: boolean
}

interface IPaginationState {
    totalItems: number
    currentPage: number
}

interface IPaginationProps {
    itemsPerPage: number
    baseUrl?: string
    onPageChange?: (pageNumber: number) => void
    reloadOnPageChange: boolean  // Indicates if Pagination is used for ajax requests or page reloads
    addLastPageAction?: boolean  // Default false, adds a "LastPage" page action to the final number button on render
    pageParam?: string  // What URL param should correspond with the page number, defaults to "page"
    makeResponsive?: boolean
}

class EllipsisSpacer extends HTMLComponent<HTMLSpanElement> {
    protected createElement(): HTMLSpanElement {
        return <span className="endless_separator" data-testid="endless-separator">...</span>
    }

    setShowHide(show: boolean) {
        if (show) {
            this.showElement()
        } else {
            this.hideElement()
        }
    }
}

export class Pagination extends HTMLComponent<HTMLUListElement, IPaginationProps, IPaginationState> {
    private maxPages: number
    private prevNavigationLink: DirectionalNavigationButton
    private nextNavigationLink: DirectionalNavigationButton
    private previousSpacer: EllipsisSpacer
    private nextSpacer: EllipsisSpacer
    private props: IPaginationProps
    protected readonly pageParam

    private static readonly MAX_PAGE_NUMBERS_PER_SIDE = 3

    public static readonly pageNumberChanged = new EventRouter<void>("pageNumberChanged")

    constructor(props: IPaginationProps) {
        super(props)
        this.pageParam = props.pageParam ?? "page"
        this.setState({
            currentPage: 1,
            totalItems: 0,
        })

        if (props.makeResponsive === true) {
            const resizeDebouncer = new Debouncer(() => {
                this.updateState()
            }, {
                bounceLimitMS: 250,
                debounceType: DebounceTypes.trailThrottle,
            })
            window.setTimeout(() => {
                if (this.element.parentElement !== null && window["ResizeObserver"] !== undefined) {
                    new ResizeObserver(() => {
                        resizeDebouncer.callFunc()
                    }).observe(this.element.parentElement)
                }
            })
        }
    }

    get currentPage(): number {
        return this.state.currentPage
    }

    set currentPage(page: number) {
        this.setState({ ...this.state, currentPage: page })
    }

    protected createElement(props: IPaginationProps): HTMLUListElement {
        this.props = props
        this.prevNavigationLink = this.createDirectionalButton("prev", this.state.currentPage - 1)
        this.nextNavigationLink = this.createDirectionalButton("next", this.state.currentPage + 1)
        return <ul className="paging" data-paction="PageNumber" bind={{ display: () => this.getDisplay() }}>
            {this.prevNavigationLink.element}
            <EllipsisSpacer classRef={(el: EllipsisSpacer) => { this.previousSpacer = el }}></EllipsisSpacer>
            <EllipsisSpacer classRef={(el: EllipsisSpacer) => { this.nextSpacer = el }}></EllipsisSpacer>
            {this.nextNavigationLink.element}
        </ul>
    }

    public static calculateMaxPages(totalItems: number, itemsPerPage: number): number {
        return Math.max(Math.ceil(totalItems / itemsPerPage), 1)
    }

    private generatePaginationNumberSequence(): IPageNumberSequenceInfo {
        this.maxPages = Pagination.calculateMaxPages(this.state.totalItems, this.props.itemsPerPage)

        if (this.state.currentPage > this.maxPages || this.state.currentPage < 1) {
            this.state.currentPage = 1
        }

        // For small screens or when the homepage filter is open there is not enough space to show
        // MAX_PAGE_NUMBERS_PER_SIDE page nums on each side. In this case we try to calculate how many page numbers we
        // can fit within the parent width and use that instead
        const maxPageNumsFitWithinParentWidth = this.getMaxPageNumsFitWithinParentWidth()
        const maxPagesPerSide = Math.min(Pagination.MAX_PAGE_NUMBERS_PER_SIDE, Math.floor(maxPageNumsFitWithinParentWidth / 2))
        const all_pages = new Set([
            1,
            ...range(this.currentPage - maxPagesPerSide, maxPagesPerSide),
            this.currentPage,
            ...range(this.currentPage + 1, maxPagesPerSide),
            this.maxPages,
        ])
        const pages = Array.from(all_pages).filter(p => p >= 1 && p <= this.maxPages)

        return {
            pageNumbers: pages,
            showPreviousEllipses: pages.length > 2 && pages[0] + 1 !== pages[1],
            showNextEllipses: pages.length > 2 && pages[pages.length - 2] + 1 !== pages[pages.length - 1],
        }
    }

    private getMaxPageNumsFitWithinParentWidth() {
        // 50px for each directional button or ellipsis, 40px for each number button
        const directionalAndEllipsisWidth = 50 * 4
        const beginningAndEndPageNumsWidth = 40 * 2
        const pageNumWidth = 40
        const spaceLeftForPageNums = (this.element.parentElement?.offsetWidth ?? 1000) - (directionalAndEllipsisWidth + beginningAndEndPageNumsWidth)
        return Math.max(0, Math.floor(spaceLeftForPageNums / pageNumWidth))
    }

    private createDirectionalButton(direction: "next"|"prev", pageNumber: number): DirectionalNavigationButton {
        let directionalButton  = this.nextNavigationLink
        let pageLimit = this.maxPages

        if (direction === "prev") {
            directionalButton = this.prevNavigationLink
            pageLimit = 1
        }

        const onPageNavigation = (e: MouseEvent | KeyboardEvent) => {
            if (e instanceof MouseEvent && (e.ctrlKey || e.metaKey || e.shiftKey)) {
                return
            }
            if (!this.props.reloadOnPageChange) {
                e.preventDefault()
            }

            if (!directionalButton.isDisabled()) {
                this.onUserInitiatedPageChange(pageNumber)
            }
        }

        const button = new DirectionalNavigationButton({
            direction: direction,
            baseUrl: this.props.baseUrl,
            onClick: onPageNavigation,
            page: pageNumber,
            pageParam: this.props.pageParam,
        })

        button.toggleDisable(this.state.currentPage === pageLimit)
        return button
    }

    private createPageNumber(pageNumber: number, setActive: boolean): PageNumber {
        const onPageNavigation = (e: MouseEvent | KeyboardEvent, pageNumberButton: PageNumber) => {
            if (e instanceof MouseEvent && (e.ctrlKey || e.metaKey || e.shiftKey)) {
                return
            }
            if (!this.props.reloadOnPageChange) {
                e.preventDefault()
            }

            if (!pageNumberButton.isSelected()) {
                this.onUserInitiatedPageChange(pageNumber)
            }
        }

        const pageNumberButton = new PageNumber({
            baseUrl: this.props.baseUrl,
            onClick: (e: MouseEvent) => {
                onPageNavigation(e, pageNumberButton)
            },
            page: pageNumber,
            pageParam: this.props.pageParam,
        })

        pageNumberButton.toggleSelected(setActive)

        return pageNumberButton
    }

    private rearrangePaginationButtons(pageNumbers: number[]): void {
        this.removeAllDOMChildren()

        this.addChild(this.createDirectionalButton("prev", this.state.currentPage - 1))
        for (let i = 0; i < pageNumbers.length; i++) {
            if (i === pageNumbers.length - 1) {
                this.addChild(this.nextSpacer)
            }

            const pageNumberButton = this.createPageNumber(pageNumbers[i], this.state.currentPage === pageNumbers[i])
            if (i === pageNumbers.length - 1 && this.props.addLastPageAction === true) {
                // Conditionally track when a user clicks directly to the end of a pagination sequence
                pageNumberButton.element.dataset["pactionName"] = "LastPage"
            }
            this.addChild(pageNumberButton)

            if (i === 0) {
                this.addChild(this.previousSpacer)
            }
        }
        this.addChild(this.createDirectionalButton("next", this.state.currentPage + 1))
    }

    updateState(): void {
        const pageNumberSequenceInfo = this.generatePaginationNumberSequence()
        const pageNumbers = pageNumberSequenceInfo.pageNumbers

        this.rearrangePaginationButtons(pageNumbers)

        this.nextSpacer.setShowHide(pageNumberSequenceInfo.showNextEllipses)
        this.previousSpacer.setShowHide(pageNumberSequenceInfo.showPreviousEllipses)
        super.updateState()
    }

    private onUserInitiatedPageChange(pageNumber: number): void {
        this.setState({ ...this.state, currentPage: pageNumber })
        if (this.props.onPageChange !== undefined) {
            this.props.onPageChange(pageNumber)
        }
        Pagination.pageNumberChanged.fire()
    }

    isLastPage(): boolean {
        return this.maxPages === this.state.currentPage
    }

    private getDisplay() {
        return this.maxPages > 1 ? "flex" : "none"
    }
}
