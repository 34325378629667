import { Component } from "../../common/defui/component"
import { applyStyles, numberFromStyle } from "../../common/DOMutils"
import { EventRouter, ListenerGroup } from "../../common/events"
import { addColorClass, colorClass } from "../colorClasses"
import { resizeDebounceEvent } from "./responsiveUtil"
import { WaterfallDropDown } from "./waterfallDropDown"
import { Wrapper } from "./wrapper"
import type { ToggleEvent } from "../../common/dropDownComponentBase"

export class ExpandableDropDownMenu<T extends HTMLElement = HTMLElement> extends Component<T> {
    public static DOTDOTDOT_TEXT = ". . ."
    public dropDown: WaterfallDropDown
    public defaultDisplay = "inline-block"
    public collapseEvent = new EventRouter<boolean>("collapse", { reportIfNoListeners: false })
    protected overflownSibling: CollapsibleComponent[] = []
    protected shownSibling: CollapsibleComponent[] = []
    protected spacer = new Wrapper()
    private collapsedTabs: [CollapsibleComponent, Component | undefined][] = []
    private listeners = new ListenerGroup()

    constructor(elementOrTag?: string | T, alignRight?: boolean) {
        super(elementOrTag)
        if (elementOrTag === undefined || typeof elementOrTag === "string") {
            this.styleElement()
        }
        this.dropDown = this.createDropDown(alignRight === true)
        applyStyles(this, { userSelect: "none" })
        this.hideElement()
        applyStyles(this.spacer, {
            cssFloat: "right",
            height: "100%",
            visibility: "hidden",
        })

        this.listeners.add(this.dropDown.toggleEvent.listen(evt => {
            this.onToggle(evt)
        }))
        this.listeners.add(resizeDebounceEvent.listen(inProgress => {
            if (this.dropDown.isShown()) {
                this.dropDown.hideElement()
            }
            this.collapseIfNeeded(!inProgress)
        }))
        window.setTimeout(() => {
            this.attachSpacer()
            this.collapseIfNeeded()
        })
    }

    public collapseIfNeeded(repositionEnded = true): void {
        const dropDownShown = this.dropDown.isShown()
        if (dropDownShown) {
            this.restoreTabsFromDropDown()
        }
        this.separateOverflownSiblings()
        this.showOrHideBasedOnOverflownSiblings()
        if (repositionEnded && this.isShown()) {
            this.fixLeftPosition()
        }
        if (dropDownShown) {
            if (this.overflownSibling.length === 0) {
                this.dropDown.hideElement()
            } else {
                this.addTabsToDropDown()
                this.dropDown.reposition()
            }
        }
    }

    private showOrHideBasedOnOverflownSiblings(): void {
        if (this.overflownSibling.length === 0) {
            if (this.isShown()) {
                this.hideElement()
                this.spacer.element.style.paddingLeft = "0px"
                this.collapseEvent.fire(false)
            }
        } else if (this.overflownSibling.length === 1 && this.isShown() &&
            this.lastOverflownElementHasEnoughSpace()) {
            this.tryToHideThisAndShowLastOverflown()
        } else if (!this.isShown()) {
            this.showElement(this.defaultDisplay)
            this.reposition()
            this.collapseEvent.fire(true)
            this.separateOverflownSiblings()
        }
    }

    private tryToHideThisAndShowLastOverflown(): void {
        // if the last overflown element width is less than this element then we can just hide this
        // and show that last element
        this.hideElement()
        const origPadding = this.spacer.element.style.paddingLeft
        this.spacer.element.style.paddingLeft = "0px"
        this.separateOverflownSiblings()
        if (this.overflownSibling.length > 0) {
            // our calculation was wrong and even without ellipses we still have overflown
            // elements so revert what we did here
            this.showElement(this.defaultDisplay)
            this.spacer.element.style.paddingLeft = origPadding
        } else {
            this.collapseEvent.fire(false)
        }
    }

    private lastOverflownElementHasEnoughSpace(): boolean {
        const overflownEl = this.overflownSibling[0]
        const styles = getComputedStyle(overflownEl.element)
        const lastChildOverflownWidth = overflownEl.element.offsetWidth +
            numberFromStyle(styles.marginLeft) + numberFromStyle(styles.marginRight) + 5
        const spaceLeft = this.element.offsetWidth + this.spacer.element.offsetLeft - this.element.offsetLeft
        return lastChildOverflownWidth <= spaceLeft
    }

    public setSpacerWidth(width: number): void {
        this.spacer.element.style.width = `${width}px`
    }

    public isTargetOverflownAnchor(target: EventTarget): boolean {
        return this.overflownSibling.some((collapsed) => {
            return collapsed.element.children[0] === target
        })
    }

    public lastShownSibling(): CollapsibleComponent | undefined {
        return this.shownSibling[this.shownSibling.length - 1]
    }

    public firstTab(): Component | undefined {
        if (this.parent === undefined) {
            return undefined
        }

        for (const child of this.parent.children()) {
            if (isStaticAndNotFloatRight(child.element)) {
                return child
            }
        }
        return undefined
    }

    protected repositionChildren(): void {
        super.repositionChildren()
        this.collapseIfNeeded()
    }

    protected onToggle(evt: ToggleEvent): void {
        if (evt.isShowing) {
            this.separateOverflownSiblings()
            this.addTabsToDropDown()
        } else {
            this.restoreTabsFromDropDown()
        }
    }

    protected addTabsToDropDown(): void {
        this.overflownSibling.forEach(tab => {
            this.collapsedTabs.unshift([tab, tab.nextSibling()])
            this.dropDown.addChild(tab)
            tab.collapse(true)
        })
    }

    protected restoreTabsFromDropDown(): void {
        const parent = this.parent as Component
        this.collapsedTabs.forEach(tabNext => {
            const tab = tabNext[0]
            parent.addChildBefore(tab, tabNext[1])
            tab.collapse(false)
        })
        this.collapsedTabs = []
    }

    protected collapsibleSiblings(): CollapsibleComponent[] {
        return this.parent === undefined ? [] :
            this.parent.children().filter(child => {
                // noinspection SuspiciousTypeOfGuard
                return child instanceof CollapsibleComponent && child.isShown()
            }) as CollapsibleComponent[]
    }

    protected createDropDown(alignRight: boolean): WaterfallDropDown {
        return new WaterfallDropDown(this.element, alignRight)
    }

    protected separateOverflownSiblings(): void {
        if (this.element.parentElement === null || this.element.parentElement.offsetParent === null) {
            return
        }
        const collapsibleSiblings = this.collapsibleSiblings()
        this.overflownSibling = []
        this.shownSibling = []
        collapsibleSiblings.forEach(child => {
            if (isOverflown(child)) {
                this.overflownSibling.push(child)
            } else {
                this.shownSibling.push(child)
            }
        })
    }

    protected reposition(): void {
        if (this.element.parentElement !== null) {
            applyStyles(this.element, {
                position: "absolute",
                boxSizing: "border-box",
                textAlign: "center",
            })
            this.spacer.element.style.paddingLeft = `${this.element.offsetWidth}px`
            if (this.element.style.width === "") {
                // need to set the width to int value to fix being one pixel off randomly on drop down overlay
                this.element.style.width = `${this.element.offsetWidth + 1}px`
            }
        }
    }

    private attachSpacer(): void {
        if (this.parent !== undefined || this.element.parentElement !== null) {
            const parent = this.parent === undefined ? this.element.parentElement as HTMLElement : this.parent.element
            const firstTab = this.firstTab()
            if (firstTab !== undefined) {
                parent.insertBefore(this.spacer.element, firstTab.element.nextSibling)
            }
        }
    }

    private styleElement(): T {
        this.element.appendChild(document.createTextNode(ExpandableDropDownMenu.DOTDOTDOT_TEXT))
        addColorClass(this.element, colorClass.chatAreaTabColor)
        applyStyles(this.element, {
            cursor: "pointer",
            width: "",
            height: "",
            padding: "0 10px",
            textAlign: "center",
        })

        return this.element
    }

    protected fixLeftPosition(): void {
        // occasionally specially on Safari or iOS the ... is shown out of position on first load
        // or repositioning. The css seems to be correct and it seems to be a browser bug because if you hover over
        // or change any style back and forth on it it will fix itself in correct position. Here we explicitly
        // set the left style for the element for a brief moment so it would be moved to the correct position.
        if (this.parent !== undefined) {
            let left = 0
            for (const c of this.parent.children()) {
                if (isStaticAndNotFloatRight(c.element) && c !== this &&
                    !(c instanceof CollapsibleComponent && this.shownSibling.indexOf(c) === -1)) {
                    left = Math.max(c.element.offsetLeft +
                        c.element.offsetWidth + numberFromStyle(getComputedStyle(c.element).marginRight), left)
                }
            }
            if (this.element.offsetLeft !== left) {
                this.element.style.left = `${left}px`
                window.setTimeout(() => {
                    this.element.style.left = ""
                })
            }
        }
    }

    dispose(): void {
        this.dropDown.dispose()
        this.listeners.removeAll()
    }
}

export function isOverflown(child: CollapsibleComponent): boolean {
    if (child.element.parentElement === null) {
        return false
    } else if (child.element.parentElement.offsetHeight <= child.element.offsetHeight) {
        // consider overflowed if half or more of child is outside of parent viewable area
        return child.element.offsetHeight * 0.5 + child.element.offsetTop >= child.element.parentElement.offsetHeight
    }

    const parentHasOverflow = child.element.parentElement.scrollHeight > child.element.parentElement.offsetHeight
    const childIsOverflown = child.element.parentElement.offsetHeight <= child.element.offsetHeight * 0.5 + child.element.offsetTop

    return parentHasOverflow && childIsOverflown
}

function isStaticAndNotFloatRight(element: HTMLElement): boolean {
    return element.style.display !== "none" &&
        element.style.cssFloat !== "right" &&
        element.style.position !== "absolute" &&
        element.style.position !== "fixed"
}

export class CollapsibleComponent<T extends HTMLElement = HTMLElement> extends Component<T> {
    public onCollapseEvent = new EventRouter<boolean>("collapse", { reportIfNoListeners: false })
    public collapsed = false

    constructor(tagOrElement: string | T = "div") {
        super(tagOrElement)
        window.setTimeout(() => {
            this.onCollapseEvent.fire(this.collapsed)
        })
    }

    public collapse(collapsed: boolean): void {
        this.collapsed = collapsed
        if (collapsed) {
            this.element.classList.add("collapsed")
        } else {
            this.element.classList.remove("collapsed")
        }
        this.onCollapseEvent.fire(collapsed)
    }
}
