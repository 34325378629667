import { hasWellSupportedEmojis, isIE } from "@multimediallc/web-utils/modernizr"
import twemoji from "@twemoji/api"
import { addColorClass } from "../cb/colorClasses"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { Debouncer, DebounceTypes } from "./debouncer"
import { Component } from "./defui/component"
import { contentWidth, getTextWidth, isElementInViewport } from "./DOMutils"
import { findEmojiInString } from "./emojis"
import { EventRouter, eventsPmSessionsCount, ListenerGroup } from "./events"
import { isCharacterKey } from "./ischaracterkey"
import { addPageAction } from "./newrelic"
import { styleUserSelect } from "./safeStyle"

export interface ICustomInput extends Component {
    getAvailableLength(): number
    insertText(text: string): void
    setCaretToEnd(): void
    setCaretToEndOfSelection(): void
    deleteSelection(): void
    focus(): void
    blur(): void
    caretXPos(getWord?: boolean): number
    getCurrentNodePreCaretText(): string
    replaceCurrentNodePreCaretText(pattern: RegExp, newText: string, highlightLength: number): void
}

interface ICaretPosition {
    nodeIdx: number
    nodeOffset: number
}

interface IUndoData {
    inputContent: string
    inputNodes: Node[],
    savedCaret: ICaretPosition
}

// Base limit + 1 per PM session
export const focusingInputFromKeypress = new EventRouter<KeyboardEvent>("focusingInputFromKeypress", { listenersWarningThreshold: () => 10 + eventsPmSessionsCount })
const MAX_UNDO = 20
const UNDO_DEBOUNCE_INTERVAL = 300

// NOTE: Always use CustomInput.submit() to submit. It will call the provided submitInput callback.

// Input that supports twemoji images and internal DOM manipulation in general
export class CustomInput extends Component implements ICustomInput {
    private savedCaret: ICaretPosition = { nodeIdx: -1, nodeOffset: -1 }
    private placeholder: HTMLSpanElement | undefined
    private recordUndoTimeout = -1
    private undoStack: IUndoData[] = [{ inputContent: "", inputNodes: [], savedCaret: this.savedCaret }]  // initialized for empty input
    private undoPointer = 0
    private ctrlMetaKeydownsIE = new Set<string>()
    private disableRequestUndo = false
    private restoringBrowserUndo = false
    private elementIsFocused = false
    private listeners = new ListenerGroup()

    constructor(private submitInput: () => boolean, private readonly maxLength: number) {
        super()
        this.maxLength = maxLength
        this.element.classList.add("customInput")
        this.element.classList.add("noScrollbar")
        this.element.style.position = "relative"
        this.element.style.outline = "none"
        this.element.style.border = "none"
        this.element.style.boxSizing = "border-box"
        this.element.style.fontSize = "12px"
        this.element.style.whiteSpace = "nowrap"
        this.element.style.overflowY = "hidden"
        this.element.style.overflowX = "scroll"
        this.element.contentEditable = "true"
        styleUserSelect(this.element, "text")

        // Debounce browser undo/redo because safari double fires the event (0ms doesn't work, 1ms does, using 10ms for safety)
        const browserUndoDebouncer = new Debouncer(() => {this.doUndo(true)}, { bounceLimitMS: 10, debounceType: DebounceTypes.throttle })
        const browserRedoDebouncer = new Debouncer(() => {this.doRedo(true)}, { bounceLimitMS: 10, debounceType: DebounceTypes.throttle })
        // Window's emoji picker (win+.) double fires the input event on chrome
        const onPossibleEmojiDebouncer =  new Debouncer(() => { this.onPossibleEmoji() }, { bounceLimitMS: 10, debounceType: DebounceTypes.debounce })

        let processingInput = false
        let lastKeydownIdentified = false

        addEventListenerPoly("input", this.element, (e: InputEvent) => {
            // Android doesn't always give `event.keyCode` or `event.key` for soft keyboard events (marks `Unidentified`), but it does add a \n to `e.data` when enter is pressed.
            // We can infer the user's intent to submit by detecting a \n at the end of `e.data`.
            // See: https://bugs.chromium.org/p/chromium/issues/detail?id=809107
            const isLastCharNewline = e.data?.charAt(e.data.length - 1) === "\n"

            // TODO: Add a property to the class that indicates when newlines should/should not submit the input by default and check that property here.
            // i.e. if implementing CustomInput into DMs, we don't want to enter this block since we want newlines to actually add a line break.
            if (!lastKeydownIdentified && isLastCharNewline) {
                e.preventDefault()
                this.submit()
                return
            }

            if (processingInput || this.restoringBrowserUndo) {
                return
            }
            processingInput = true

            // Note IE does not fire input events for contenteditable divs, so we don't handle browser undo/redo on IE.
            if (e.inputType === "historyUndo") {
                browserUndoDebouncer.callFunc()
            } else if (e.inputType === "historyRedo") {
                browserRedoDebouncer.callFunc()
            } else {
                // Handles input by system emoji pickers (eg mobile keyboards or ctrl-cmd-space on mac)
                if (!hasWellSupportedEmojis()) {
                    onPossibleEmojiDebouncer.callFunc()
                }
                this.requestRecordUndo(true)
            }

            this.updatePlaceholderVisibility()

            // No onInputChanged call here because we don't request undo if we're a historyUndo/Redo event, so we call
            // saveCaretPos and requestRecordUndo directly.
            // Since IE doesn't get the input event, IE's onInputChanged call is on keyup instead. Not doing keyup for
            // others browsers because mac doesn't fire keyup if meta is down, which makes adapting the
            // ctrlMetaKeydownsIE logic infeasible
            this.saveCaretPos()

            processingInput = false
        })

        if (isIE()) {
            // Poll to handle input from win+. emoji picker since the input event doesn't work on IE
            window.setInterval(() => {
                if (document.activeElement === this.element) {
                    this.onPossibleEmoji()
                }
            }, 200)
        }

        addEventListenerPoly("paste", this.element, (e: ClipboardEvent) => {
            // Always paste clipboard data as unformatted text
            e.preventDefault()
            if (e.clipboardData !== null) {
                let pastedText
                if (window.clipboardData !== null && window.clipboardData !== undefined) {  // IE
                    pastedText = window.clipboardData.getData("Text")
                } else {
                    pastedText = e.clipboardData.getData("text/plain")
                }
                this.insertText(pastedText)
            }
        })

        addEventListenerPoly("click", this.element, (e: MouseEvent) => {
            // Let clicking on emojis set cursor position
            if (e.target !== null && e.target !== this.element) {
                const sel = window.getSelection()
                if (sel !== null) {
                    const range = document.createRange()
                    const targetNode = e.target as HTMLElement
                    if (e.offsetX < targetNode.offsetWidth / 2) {
                        range.setStartBefore(targetNode)
                    } else {
                        range.setStartAfter(targetNode)
                    }
                    range.collapse(true)
                    sel.removeAllRanges()
                    sel.addRange(range)
                }
            }

            this.saveCaretPos()
        })

        addEventListenerPoly("keydown", this.element, (e: KeyboardEvent) => {  // eslint-disable-line complexity
            lastKeydownIdentified = e.key !== "Unidentified"

            if (e.key === "Enter") {
                // If adding support for multiline CustomInput (eg ucm notes) make sure not to use <br> for newlines.
                // Firefox adds a dummy <br> at the end of the input as soon as there is trailing whitespace. Our input
                // manipulations often end up making it a real <br>, so we supress <br>s in customInput.scss
                e.preventDefault()
                this.submit()
            } else if (this.willKeyEventViolateMaxLength(e)) {
                e.preventDefault()
            } else if (e.ctrlKey || e.metaKey) {
                if (["u", "b", "i"].indexOf(e.key) !== -1) {
                    e.preventDefault()
                } else if (e.key === "z" && !e.shiftKey) {
                    this.doUndo()
                    e.preventDefault()
                } else if (e.key === "z" && e.shiftKey || e.key === "y") {
                    this.doRedo()
                    e.preventDefault()
                }
                this.ctrlMetaKeydownsIE.add(e.key)
            }

            this.updatePlaceholderVisibility()
        })

        addEventListenerPoly("keyup", this.element, (e: KeyboardEvent) => {
            // Using ctrlMetaKeydownsIE to skip onInputChanged in case of the user hitting eg ctrl-z and releasing ctrl before z
            if (isIE() && isCharacterKey(e.which) && (!this.ctrlMetaKeydownsIE.has(e.key) || e.repeat)) {
                this.onInputChanged()
            } else if (["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"].indexOf(e.key) !== -1 || ["MetaLeft", "MetaRight"].indexOf(e.code) !== -1) {
                this.saveCaretPos()
            }
            this.ctrlMetaKeydownsIE.delete(e.key)
        })

        addEventListenerPoly("focus", this.element, () => {
            this.elementIsFocused = true
            if (this.placeholder !== undefined) {
                this.placeholder.parentElement?.removeChild(this.placeholder)
            }
        })

        addEventListenerPoly("blur", this.element, () => {
            this.elementIsFocused = false
            if (this.placeholder !== undefined && this.getText() === "") {
                this.element.appendChild(this.placeholder)
            }

            this.updatePlaceholderVisibility()

            // In case of clicking off the input before releasing eg ctrl-z
            this.ctrlMetaKeydownsIE.clear()
        })

        focusingInputFromKeypress.listen((e: KeyboardEvent) => {
            // Optionally prevent eventListeners.ts keypress listener focusing input
            if (this.willKeyEventViolateMaxLength(e)) {
                e.preventDefault()
            }
        }).addTo(this.listeners)
    }

    public setText(text: string): void {
        this.disableRequestUndo = true
        this.clearText()
        this.insertText(text)
        this.element.scrollLeft = this.element.scrollWidth
        this.disableRequestUndo = false
    }

    public insertText(text: string, atEnd = false): void {
        if (text === "") {
            return
        }

        if (atEnd) {
            this.setCaretToEnd()
        }
        this.focus(true)

        this.forceRequestedRecordUndo()
        if (this.areAnyContentsSelected()) {
            const sel = window.getSelection()
            if (sel !== null && sel.rangeCount > 0) {
                sel.getRangeAt(0).deleteContents()
            }
        }

        text = text.replace(/\r?\n|\r/g, " ") // Delete all newlines
        text = text.replace(/ /g, "\u00a0") // Browsers may ignore final space if not a \u00a0 (&nbsp)
        text = CustomInput.truncateString(text, this.getAvailableLength())
        if (text === "") {
            return
        }
        const div = this.createDivForText(text)
        const sel = window.getSelection()
        if (sel !== null && sel.rangeCount > 0) {
            const range = sel.getRangeAt(0)
            const nodes = [...div.childNodes].reverse()
            const lastNode = nodes[0]
            for (const node of nodes) {
                range.insertNode(node)
            }
            range.setStartAfter(lastNode)
            range.collapse(true)
            sel.removeAllRanges()
            sel.addRange(range)
        }
        this.onInputChanged()
        this.updatePlaceholderVisibility()
        this.scrollToSavedCaret()
    }

    private createDivForText(text: string): HTMLDivElement {
        const div = document.createElement("div")
        div.textContent = text
        if (!hasWellSupportedEmojis()){
            const emojiAttributesIE = { unselectable: "on" }
            twemoji.parse(div, { attributes: (icon) => emojiAttributesIE })
        }
        return div
    }

    public appendText(text: string): void {
        this.insertText(text, true)
    }

    public clearText(): void {
        if (this.getText() === "") {
            return
        }
        this.forceRequestedRecordUndo()
        const sel = window.getSelection()
        if (sel !== null) {
            const range = document.createRange()
            range.selectNodeContents(this.element)
            sel.removeAllRanges()
            sel.addRange(range)
            range.deleteContents()
            range.collapse(true)
        }
        this.onInputChanged()
    }

    public getText(): string {
        let value = ""
        let prevNodeWasEmoji = false
        for (const node of this.element.childNodes) {
            if (node instanceof HTMLImageElement) {
                value += node.alt
                prevNodeWasEmoji = true
            } else if (node === this.placeholder) {
                continue
            } else if (node.textContent !== null) {
                if (prevNodeWasEmoji && (node.textContent[0] === ":" || node.textContent[0] === "@")) {
                    // Not the cleanest but needed to guarantee that emoticons or user mentions after emojis will work
                    value += " "
                }
                value += node.textContent
                prevNodeWasEmoji = false
            }
        }
        // Replace whitespace with spaces since the input can wind up with e.g. nonbreaking spaces (\u00a0) in place of
        // spaces, which ironically break backend emoticon parsing
        return value.replace(/\s/g, " ")
    }

    public getAvailableLength(): number {
        return this.maxLength - this.getText().length
    }

    public setCaretToEnd(): void {
        const sel = window.getSelection()
        if (sel !== null) {
            const range = document.createRange()
            range.selectNodeContents(this.element)
            range.collapse(false)
            sel.removeAllRanges()
            sel.addRange(range)
            this.saveCaretPos()
        }
    }

    public setCaretToEndOfSelection(): void {
        const sel = window.getSelection()
        if (sel !== null && sel.rangeCount > 0) {
            const range = sel.getRangeAt(sel.rangeCount - 1)
            range.collapse(false)
            sel.removeAllRanges()
            sel.addRange(range)
        } else {
            this.focus(true)
        }
    }

    public setCurrentNodeText(text: string): void {
        if (document.activeElement !== this.element) {
            this.restoreCaretPos()
        }
        const currentNode = this.getNodeAtCaret()
        const sel = window.getSelection()
        if (sel !== null) {
            currentNode.textContent = text
            const range = document.createRange()
            range.selectNodeContents(currentNode)
            range.collapse(false)
            sel.removeAllRanges()
            sel.addRange(range)
        }
    }

    public getCurrentNodeText(): string {
        const node = this.getNodeAtCaret()
        if (node.textContent !== null) {
            return node.textContent.replace(/\s/g, " ")
        }
        return ""
    }

    public selectCurrentNodeTail(start: number): void {
        const node = this.getNodeAtCaret()
        const sel = window.getSelection()
        if (sel !== null && sel.rangeCount > 0) {
            const range = document.createRange()
            range.selectNodeContents(node)
            range.setStart(node, start)
            this.scrollToSavedCaret()
            sel.removeAllRanges()
            sel.addRange(range)
        }
    }

    public deleteSelection(): void {
        const sel = window.getSelection()
        if (sel !== null && sel.rangeCount > 0) {
            const range = sel.getRangeAt(0)
            if (range.startContainer !== this.element && this.element.contains(range.startContainer)) {
                this.requestRecordUndo(false)
                range.deleteContents()
                this.onInputChanged()
            }
        }
    }

    public focus(restoreCaret = false, scrollTo = true): void {
        if (document.activeElement !== this.element && document.body.contains(this.element)) {
            if (scrollTo && !isElementInViewport(this.element)) {
                this.element.scrollIntoView(false)
                window.scrollBy(0, 12)
            }
            this.element.focus()
            if (restoreCaret) {
                this.restoreCaretPos()
            } else {
                this.setCaretToEnd()
            }
            this.scrollToSavedCaret()
        }
    }

    public blur(): void {
        this.element.blur()
    }

    public disable(): void {
        this.blur()
        this.element.contentEditable = "false"
    }

    public enable(): void {
        this.element.contentEditable = "true"
    }

    public setPlaceholder(text: string, colorClass?: string): void {
        if (this.placeholder !== undefined) {
            this.placeholder.textContent = text
            return
        }

        this.placeholder = document.createElement("span")
        this.placeholder.textContent = text
        this.placeholder.style.pointerEvents = "none"
        this.placeholder.contentEditable = "false"

        if (colorClass !== undefined) {
            addColorClass(this.placeholder, colorClass)
        }

        this.element.appendChild(this.placeholder)
    }

    public submit(): void {
        const sendSuccess = this.submitInput()
        if (!sendSuccess) {
            return
        }

        if (!hasWellSupportedEmojis()) {
            // eslint-disable-next-line @multimediallc/no-inner-html
            const inputHtml = this.element.innerHTML
            const emojiRegex = /<img[^>]+alt="?([^"\s]+)"?\s*/g // Find all the "alt=<emoji string>"s
            let emojiSent = emojiRegex.exec(inputHtml)
            while (emojiSent !== null) {
                addPageAction("emojiSent", { value: emojiSent[1] })
                emojiSent = emojiRegex.exec(inputHtml)
            }
        }

        this.clearText()
        this.blur()
    }

    private onPossibleEmoji(): void {
        // Convert unicode emojis in the node under the caret to twemoji. In practice there's only ever one at a time
        const sel = window.getSelection()
        if (sel !== null && sel.rangeCount > 0) {
            const caretNode = this.getNodeAtCaret()
            if (caretNode.textContent !== null && twemoji.test(caretNode.textContent)) {
                const text = caretNode.textContent
                const range = document.createRange()
                range.selectNodeContents(caretNode)
                sel.removeAllRanges()
                sel.addRange(range)
                range.deleteContents()
                this.insertText(text)

                // Caret is now at the end of the inserted text. Bring it back to the emoji within the text
                const caretNodeAfterInsert = this.getNodeAtCaret()
                if (caretNodeAfterInsert.textContent !== "") {
                    range.setStartBefore(caretNodeAfterInsert)
                    range.collapse(true)
                    sel.removeAllRanges()
                    sel.addRange(range)
                    this.saveCaretPos()
                }
            }
        }
    }

    private areAnyContentsSelected(): boolean {
        const sel = window.getSelection()
        if (sel !== null && sel.rangeCount > 0 && document.activeElement === this.element) {
            const range = sel.getRangeAt(0)
            if (range.startContainer !== range.endContainer || range.startOffset !== range.endOffset) {
                return true
            }
        }
        return false
    }

    private willKeyEventViolateMaxLength(e: KeyboardEvent): boolean {
        if (this.getAvailableLength() > 0) {
            return false
        }
        const isForbiddenKey = !e.ctrlKey && !e.metaKey && isCharacterKey(e.which) && ["Backspace", "Delete"].indexOf(e.key) === -1
        return isForbiddenKey && !this.areAnyContentsSelected()
    }

    private onInputChanged(): void {
        this.saveCaretPos()
        this.requestRecordUndo(true)
    }

    private requestRecordUndo(debounced: boolean): void {
        window.clearTimeout(this.recordUndoTimeout)
        this.recordUndoTimeout = -1
        if (this.disableRequestUndo) {
            return
        }
        if (debounced) {
            this.recordUndoTimeout = window.setTimeout(() => {
                this.recordUndo()
                this.recordUndoTimeout = -1
            }, UNDO_DEBOUNCE_INTERVAL)
        } else {
            this.recordUndo()
        }
    }

    private forceRequestedRecordUndo(): void {
        if (this.recordUndoTimeout !== -1) {
            window.clearTimeout(this.recordUndoTimeout)
            this.recordUndoTimeout = -1
            this.recordUndo()
        }
    }

    // Avoid calling this.recordUndo directly, use this.requestRecordUndo or this.forceRequestedRecordUndo
    private recordUndo(): void {
        this.saveCaretPos()
        const currentContent = this.getText()
        this.undoStack.splice(this.undoPointer + 1, this.undoStack.length)
        if (this.undoStack[this.undoStack.length - 1].inputContent === currentContent) {
            this.undoStack.pop()
        }
        // Save childNodes and not innerHTML because innerHTML merges adjacent text nodes and makes this.savedCaret wrong.
        // cloneNode here and in this.applyUndoData to prevent nodes in the undo stack from being affected by changes in the input.
        const nodes = [...this.element.childNodes].map((node: ChildNode) => node.cloneNode(true))
        this.undoStack.push({ inputContent: currentContent, inputNodes: nodes, savedCaret: this.savedCaret })
        if (this.undoStack.length > MAX_UNDO) {
            this.undoStack.shift()
        }
        this.undoPointer = this.undoStack.length - 1
        this.keepBrowserUndoAvailable(false)
    }

    private doUndo(fromBrowser = false): void {
        addPageAction(fromBrowser ? "BrowserTriggeredUndo" : "HotkeyTriggeredUndo")
        if (this.undoPointer === this.undoStack.length - 1 && !fromBrowser) {
            this.requestRecordUndo(false)
        }
        if (this.undoPointer > 0) {
            this.undoPointer -= 1
            this.applyUndoData(this.undoStack[this.undoPointer])
            this.keepBrowserUndoAvailable(true)
        }
    }

    private doRedo(fromBrowser = false): void {
        addPageAction(fromBrowser ? "BrowserTriggeredRedo" : "HotkeyTriggeredRedo")
        if (this.undoPointer < this.undoStack.length - 1) {
            this.undoPointer += 1
            this.applyUndoData(this.undoStack[this.undoPointer])
            this.keepBrowserUndoAvailable(this.undoPointer < this.undoStack.length - 1)
        }
    }

    private applyUndoData(undoData: IUndoData): void {
        this.element.innerHTML = ""  // eslint-disable-line @multimediallc/no-inner-html
        undoData.inputNodes.forEach((node) => { this.element.appendChild(node.cloneNode())})
        this.savedCaret = undoData.savedCaret
        this.restoreCaretPos()
        this.scrollToSavedCaret()
    }

    private keepBrowserUndoAvailable(withRedo: boolean): void {
        // Make sure browser undo still fires the historyUndo event. If execCommand ever actually hits EOL then we'll
        // need another way to do this. Ideally by then there will be some equivalent method.
        // This doesn't even work super well. Attempts to make it work better were bugged in a more critical way, so
        // we're leaving it like this and seeing if we care to make it work better (prob very few ppl using this)
        if (!this.elementIsFocused || document.queryCommandSupported("insertText")) {
            return
        }

        this.restoringBrowserUndo = true
        const restoreBrowserUndo = () => {
            document.execCommand("insertText", false, "~")
            document.execCommand("delete", false)
        }
        const sel = window.getSelection()
        if (sel !== null && sel.rangeCount > 0) {
            // Avoid deleting the selection if a user selects text within the UNDO_DEBOUNCE_INTERVAL timeout
            const range = sel.getRangeAt(0)
            sel.removeAllRanges()
            restoreBrowserUndo()
            sel.addRange(range)
        } else {
            restoreBrowserUndo()
        }
        this.restoringBrowserUndo = false
    }

    private getNodeAtCaret(): Node {
        this.saveCaretPos()
        if (this.element.childNodes.length === 0) {
            return this.element
        } else if (this.savedCaret.nodeIdx === -1) {
            return this.element.childNodes[0]
        } else {
            return this.element.childNodes[this.savedCaret.nodeIdx]
        }
    }

    private caretLeftOffset(getWord = false): number {
        return this.getCaretRange(getWord).getBoundingClientRect().left
    }

    public caretXPos(getWord = false): number {
        return this.caretLeftOffset(getWord) - this.element.getBoundingClientRect().left
    }

    public caretAtEndOfInput(): boolean {
        const currentNodeLength = this.getCurrentNodeText().length
        if (currentNodeLength > 0 && this.savedCaret.nodeOffset !== currentNodeLength) {
            return false
        }

        // Some browsers may include an empty text node at the end of the input. ignore it
        const nodeHasContent = (node: Node) => node.nodeName === "IMG" || (node.textContent?.length ?? 0) > 0
        return ![...this.element.childNodes].slice(this.savedCaret.nodeIdx + 1).some(nodeHasContent)
    }

    // In practice, only used to get the X coordinates of the start position (via getBoundingClientRect)
    // of the word under the caret, but could be used for more in future implementations
    private getCaretWordStartRange(range: Range): Range {
        const node = range.startContainer
        const length = node?.textContent?.length ?? 0
        if (length === 0) {
            return range
        }

        const sel = window.getSelection()
        if (sel !== null) {
            while (range.startOffset > 0) {
                const text = range.toString()
                if (/^\s/.test(text)) {
                    range.setStart(node, range.startOffset + 1)
                    break
                }
                range.setStart(node, range.startOffset - 1)
            }

            // TODO set the end of the range properly (not needed for getting the start position)

            return range
        }
        return document.createRange()
    }

    private getCaretRange(getWord = false): Range {
        const range = document.createRange()

        if (this.element.childNodes.length === 0) {
            range.setStart(this.element, 0)
            return range
        }

        const node = this.element.childNodes[this.savedCaret.nodeIdx]
        if (this.savedCaret.nodeIdx === -1) {
            range.setStartBefore(this.element.childNodes[0])
        } else if (node.textContent === "") {
            range.setStartAfter(node)
        } else {
            range.setStart(node, this.savedCaret.nodeOffset)
        }

        return getWord ? this.getCaretWordStartRange(range) : range
    }

    private saveCaretPos(): void {
        const sel = window.getSelection()
        if (sel !== null && sel.rangeCount > 0 && document.activeElement === this.element) {
            const range = sel.getRangeAt(0).cloneRange()
            range.collapse(false)
            let nodeIdx
            if (range.startContainer === this.element) {
                nodeIdx = range.startOffset - 1
            } else {
                const childNodes = Array.from(this.element.childNodes) as Node[]
                nodeIdx = childNodes.indexOf(range.startContainer)
            }

            if (nodeIdx === -1) {
                this.savedCaret = { nodeIdx: -1, nodeOffset: -1 }
            } else if (this.element.childNodes[nodeIdx].textContent === "") {
                this.savedCaret = { nodeIdx: nodeIdx, nodeOffset: -1 }
            } else {
                const textContent = this.element.childNodes[nodeIdx].textContent as string // Will never be null
                const offset = range.startContainer === this.element ? textContent.length : range.startOffset
                this.savedCaret = { nodeIdx: nodeIdx, nodeOffset: offset }
            }
        }
    }

    private restoreCaretPos(): void {
        if (this.element.childNodes.length === 0 || this.savedCaret.nodeIdx >= this.element.childNodes.length) {
            return
        }

        const sel = window.getSelection()
        if (sel !== null) {
            const range = this.getCaretRange()
            range.collapse(true)
            sel.removeAllRanges()
            sel.addRange(range)
        }
    }

    private scrollToSavedCaret(): void {
        let caretOffset = 0
        if (this.savedCaret.nodeIdx !== -1) {
            const caretNode = this.element.childNodes[this.savedCaret.nodeIdx]
            const range = document.createRange()
            range.setStart(this.element, 0)
            range.setEndAfter(caretNode)
            caretOffset = range.getBoundingClientRect().width
        }
        caretOffset += 3  // Leave a little extra space
        this.element.scrollLeft = Math.max(this.element.scrollLeft, caretOffset - this.element.offsetWidth)
    }

    private static truncateString(text: string, maxLength: number): string {
        // Backend length limit is based on codeunits, not codepoints. That means we can truncate to
        // text.substr(0, maxLength) as long as we're careful of surrogate pairs and emojis that overlap the
        // text[maxLength-1] text[maxLength] boundary. Not handling grapheme clusters like "한" that overlap the
        // boundary though (would truncate to "하" or "ᄒ").
        if (text.length <= maxLength) {
            return text
        }

        let lastEmojiIdx = -1
        let charIdx = 0
        for (const char of text) {
            if (charIdx + char.length > maxLength) {
                break
            }
            if (twemoji.test(char)) {
                lastEmojiIdx = charIdx
            }
            charIdx += char.length
        }

        if (lastEmojiIdx !== -1) {
            const emoji = findEmojiInString(text.substr(lastEmojiIdx, text.length))
            if (emoji !== undefined) {
                if (lastEmojiIdx + emoji.emojiChars.length > maxLength) {
                    return text.substr(0, lastEmojiIdx)
                }
            }
        }

        return text.substr(0, charIdx)
    }

    private updatePlaceholderVisibility(): void {
        /**
         * Updates the pseudo-element based placeholder's visibility. No
         * effect on the placeholder generated by `setPlaceholder`.
         */
        if (this.getText() !== "") {
            this.element.classList.remove("inputFieldChatPlaceholder")
        } else {
            this.element.classList.add("inputFieldChatPlaceholder")
        }
    }

    public dispose(): void {
        this.listeners.removeAll()
    }

    public getCurrentNodePreCaretText(): string {
        return this.getCurrentNodeText().substring(0, this.savedCaret.nodeOffset)
    }

    private getCurrentNodePostCaretText(): string {
        return this.getCurrentNodeText().substring(this.savedCaret.nodeOffset)
    }

    public replaceCurrentNodePreCaretText(pattern: RegExp, newText: string, highlightLength: number): void {
        const newPreCaratText = this.getCurrentNodePreCaretText().replace(pattern, newText)
        if (highlightLength < 0) {
            error("CustomInput replaceCurrentNodePreCaretText with highlightLength < 0")
            highlightLength = 0
        }
        this.setCurrentNodeText(newPreCaratText + this.getCurrentNodePostCaretText())
        this.setCurrentNodeSelection(newPreCaratText.length - highlightLength, newPreCaratText.length)
    }

    private setCurrentNodeSelection(start: number, end: number): void {
        const node = this.getNodeAtCaret()
        const sel = window.getSelection()
        if (sel !== null && sel.rangeCount > 0) {
            const range = document.createRange()
            range.selectNodeContents(node)
            try {
                range.setStart(node, start)
                range.setEnd(node, end)
                sel.removeAllRanges()
                sel.addRange(range)
                this.saveCaretPos()
                this.scrollToSavedCaret()
            } catch(e) {
                error("setCurrentNodeSelectionError", { error: e, nodeContent: node.textContent, inputContent: this.getText(), nodeLen: node.textContent?.length, start, end })
                throw e
            }
        }
    }
}

export class InputAsCustomInput extends Component implements ICustomInput {
    private pendingSelection: { start: number, end: number } | undefined

    constructor(private inputElement: HTMLInputElement) {
        super()
        this.element = inputElement
    }

    private caretLeftOffset(getWord = false): number {
        let text = this.inputElement.value.substring(0, this.getCurrentSelection()?.start ?? 0)
        if (getWord) {
            while (text.length > 0) {
                if (/^\s/.test(text)) {
                    break
                }
                text = text.slice(0, -1)
            }
            text = this.inputElement.value.substring(0, text.length + 1)
        }
        return getTextWidth(text, this.inputElement)
    }

    // usually is slightly to the left of the true position & and need to provide a constant offset elsewhere
    public caretXPos(getWord = false): number {
        return this.caretLeftOffset(getWord) + this.element.getBoundingClientRect().left
    }

    public getAvailableLength(): number {
        return this.inputElement.maxLength - this.inputElement.value.length
    }

    public appendText(text: string): void {
        this.inputElement.value = `${this.inputElement.value}${text}`
    }

    public setCaretToEnd(): void {
        this.setCaretPosition(this.inputElement.value.length)
    }

    public setCaretToEndOfSelection(): void {
        const currentSelection = this.getCurrentSelection()
        if (currentSelection !== undefined) {
            this.setCaretPosition(currentSelection.end)
        }
    }

    private getCaretPosition(): number | undefined {
        return this.getCurrentSelection()?.end
    }

    private setCaretPosition(offset: number): void {
        this.selectRange(offset, offset)
    }

    private getCurrentSelection(): { start: number, end: number } | undefined {
        if (this.pendingSelection !== undefined) {
            return this.pendingSelection
        } else if (this.inputElement.selectionStart === null || this.inputElement.selectionEnd === null) {
            return undefined
        } else {
            return {
                start: this.inputElement.selectionStart,
                end: this.inputElement.selectionEnd,
            }
        }
    }

    public getCurrentNodePreCaretText(): string {
        return this.inputElement.value.substring(0, this.getCaretPosition() ?? 0).replace(/\s/g, " ")
    }

    private scrollToEndOfSelection(): void {
        const textUpToEndOfSelection = this.inputElement.value.substring(0, this.getCurrentSelection()?.end ?? 0)
        const textWidth = getTextWidth(textUpToEndOfSelection, this.inputElement)
        const extraBufferPx = 4
        this.element.scrollLeft = Math.max(this.element.scrollLeft, textWidth - contentWidth(this.element) + extraBufferPx)
    }

    public getCurrentNodeText(): string {
        return this.inputElement.value.replace(/\s/g, " ")
    }

    private getCurrentNodePostCaretText(): string {
        return this.inputElement.value.substring(this.getCaretPosition() ?? this.inputElement.value.length).replace(/\s/g, " ")
    }

    public replaceCurrentNodePreCaretText(pattern: RegExp, newText: string, highlightLength: number): void {
        const newPreCaratText = this.getCurrentNodePreCaretText().replace(pattern, newText)
        if (highlightLength < 0) {
            error("InputAsCustomInput replaceCurrentNodePreCaretText with highlightLength < 0")
            highlightLength = 0
        }
        this.inputElement.value = newPreCaratText + this.getCurrentNodePostCaretText()
        this.selectRange(newPreCaratText.length - highlightLength, newPreCaratText.length)
    }

    public insertText(text: string): void {
        this.replaceCurrentNodePreCaretText(/$/, text, 0)
    }

    private selectRange(start: number, end: number): void {
        // setTimeout and focus() on input are necessary for setSelectionRange to work properly across different browsers
        this.inputElement.focus()
        this.pendingSelection = { start, end }
        window.setTimeout(() => {
            this.inputElement.setSelectionRange(start, end)
            this.scrollToEndOfSelection()
            this.pendingSelection = undefined
        }, 0)
    }

    public deleteSelection(): void {
        const currentSelection = this.getCurrentSelection()
        if (currentSelection !== undefined) {
            const preSelectValue = this.inputElement.value.substring(0, currentSelection.start)
            const postSelectValue = this.inputElement.value.substring(currentSelection.end)
            this.inputElement.value = preSelectValue + postSelectValue
            this.setCaretPosition(preSelectValue.length)
        }
    }

    public focus(): void {
        this.inputElement.focus()
    }

    public blur(): void {
        this.inputElement.blur()
    }
}
