import { isiOS } from "@multimediallc/web-utils/modernizr"
import { addColorClass } from "../../cb/colorClasses"
import { ConversationListData } from "../../cb/components/pm/conversationListData"
import { DMUnreadData } from "../../cb/components/pm/dmUnreadData"
import { createDmWindowRequest } from "../../cb/components/pm/dmWindowsManager"
import { SearchBar } from "../../cb/components/pm/searchBar"
import { pageContext } from "../../cb/interfaces/context"
import { showLoginOverlay } from "../../cb/ui/loginOverlay"
import { SpinnerIcon } from "../../cb/ui/spinnerIcon"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { isAnonymous } from "../auth"
import { Component } from "../defui/component"
import { applyStyles } from "../DOMutils"
import { featureFlagIsActive } from "../featureFlag"
import { isReactDMActive } from "../featureFlagUtil"
import { addPageAction } from "../newrelic"
import { LOGIN_BASE_PATH, SIGN_UP_BASE_PATH } from "../redirectParamUtils"
import { styleTransition } from "../safeStyle"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"
import { MobileConversationList } from "./mobileConversationList"
import { MobileDmWindowManager } from "./mobileDmWindowManager"
import { siteHeaderMenuOpened, toggleDms } from "./userActionEvents"
import { getViewportHeight } from "./viewportDimension"

interface IMobileDmsMenuProps {
    dmListIconRoot: HTMLDivElement
    userUpdatesBellRoot: HTMLDivElement | null
    hamburgerIcon: HTMLDivElement | null | undefined
}

export class MobileDmsMenu extends Component<HTMLDivElement, IMobileDmsMenuProps> {
    private dmListIconRoot: HTMLDivElement
    private userUpdatesBellRoot: HTMLDivElement | null
    private hamburgerIcon?: HTMLDivElement | null
    private dmList?: MobileConversationList
    private contentMask: HTMLDivElement
    private isRefactorDMFlagActive: boolean
    private loadingSpinner: HTMLDivElement
    private searchBar: SearchBar
    private conversationListData: ConversationListData | undefined
    constructor(props: IMobileDmsMenuProps) {
        super("div", props)
    }

    protected initData(props: IMobileDmsMenuProps): void {
        this.dmListIconRoot = props.dmListIconRoot
        this.userUpdatesBellRoot = props.userUpdatesBellRoot
        this.hamburgerIcon = props.hamburgerIcon
        this.isRefactorDMFlagActive = featureFlagIsActive("RefactorDm")

        if (this.isRefactorDMFlagActive && !isAnonymous()) {
            this.conversationListData = ConversationListData.getInstance()
        }
    }

    protected initUI(props: IMobileDmsMenuProps): void {
        const mobileDmsMenuStyle: CSSX.Properties = {
            width: "320px",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
            boxSizing: "border-box",
            display: "flex",
            flexDirection: "column",
        }
        const contentMaskStyle: CSSX.Properties = {
            width: "320px",
            display: "none",
            position: "fixed",
            top: 0,
            bottom: 0,
            left: 0,
        }
        const myUsername = pageContext.current.loggedInUser?.username

        if (myUsername !== undefined) {
            this.searchBar = new SearchBar({ isFullVideoMode: false, room: "" })
            this.dmList = new MobileConversationList({
                onItemRemoved: () => {},
                openConversationEvent: createDmWindowRequest,
                isDropdown: true,
                searchBar: this.searchBar,
            })
            this.contentMask = <div className="MobileDmsMenu__contentMask" style={contentMaskStyle} />
            const dmWindowManager = new MobileDmWindowManager({
                myUsername,
                onShow: () => this.toggleMaskingContent(true),
                onHide: () => this.toggleMaskingContent(false),
            })

            this.loadingSpinner = this.createLoadingSpinner()

            this.element = 
                <div style={mobileDmsMenuStyle}>
                    <BackButton onClick={() => {this.handleBackButtonClick()}} />
                    {this.loadingSpinner}
                    {this.searchBar.element}
                    {this.dmList.element}
                    {this.contentMask}
                    {dmWindowManager.element}
                </div>
            

            if (this.isRefactorDMFlagActive) {
                this.showLoadingSpinner()

                ConversationListData.initialDMFetchCompleteEvent.listen(() => {
                    this.hideLoadingSpinner()
                })
            }
        } else {
            const titleStlye: CSSX.Properties = {
                height: "45px",
                display: "flex",
                alignItems: "center",
                paddingLeft: "10px",
            }
            const contentContainerStyle: CSSX.Properties = {
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                flex: 1,
            }
            const linkStyle: CSSX.Properties = { textDecoration: "none" }

            const onLoginClick = () => {
                const path = `${window.location.pathname}${window.location.search}`
                const isOnLoginPage = path.indexOf(LOGIN_BASE_PATH) !== -1

                if (!isOnLoginPage) {
                    showLoginOverlay({ fromFeature: true })
                }

                const pushMenuOverlay = document.querySelector(".push-overlay")
                this.hideElement()

                if (pushMenuOverlay instanceof HTMLElement) {
                    pushMenuOverlay.click()
                }
            }

            this.element = 
                <div style={mobileDmsMenuStyle}>
                    <div className="MobileDmsMenu__title" style={titleStlye}>{i18n.directMessageLabel}</div>
                    <div style={contentContainerStyle}>
                        <img style={{ display: "inline-block", width: "50px", height: "50px" }} src={`${STATIC_URL}pms/empty-chat-state.svg`} />
                        <span style={{ margin: "25px 0", display: "inline-block" }}>{i18n.sendDirectMessage}</span>
                        <span className="MobileDmsMenu__link" style={{ ...linkStyle, marginBottom: "50px" }} onClick={onLoginClick}>{i18n.loginVerbText}</span>
                        <span>{i18n.dontHaveAccount} <a className="MobileDmsMenu__link" href={SIGN_UP_BASE_PATH} style={linkStyle} account-redirect>{i18n.signUpText}</a></span>
                    </div>
                </div>
        }

        addColorClass(this.element, "MobileDmsMenu")
        styleTransition(this.element, "transform 150ms ease")
        this.bindIconClickEvent()
        this.bindMenuChangeEvents()
        this.bindNumUnreadEvents()
        this.bindResizeEvents()
        this.hideElement()
    }

    private handleBackButtonClick(): void {
        this.hideElement()
    }

    private createLoadingSpinner(): HTMLDivElement {
        return SpinnerIcon({
            extraStyle: {
                position: "absolute",
                top: "90px",
                left: "140px",
                display: "none",
            }, 
        })
    }

    private showLoadingSpinner(): void {
        if (this.dmList) {
            this.dmList.element.style.display = "none"
        }
        this.searchBar.element.style.display = "none"
        this.loadingSpinner.style.display = "block"
    }

    private hideLoadingSpinner(): void {
        this.loadingSpinner.style.display = "none"
        if (this.dmList) {
            this.dmList.element.style.display = "block"
        }
        this.searchBar.element.style.display = "block"
    }

    public showElement(): void {
        if (this.isRefactorDMFlagActive && !isReactDMActive() && this.conversationListData && !this.conversationListData.isInitialDMFetchComplete()) {
            this.conversationListData?.getMessageThreads()
        }
        addPageAction("DMListOpened", { source: "mobile" })
        applyStyles(this.element, { transform: "translateX(0)" })
        this.dmListIconRoot.classList.add("active")
    }

    public hideElement(): void {
        applyStyles(this.element, { transform: "translateX(-100%)" })
        this.dmListIconRoot.classList.remove("active")
    }

    private toggleMaskingContent(mask: boolean): void {
        this.contentMask.style.display = mask ? "block" : "none"
    }

    public isShown(): boolean {
        return this.element.style.transform !== "translateX(-100%)"
    }

    private bindIconClickEvent(): void {
        this.dmListIconRoot.onclick = () => {
            if (!this.isShown()) {
                this.showElement()
            }
        }
    }

    private bindMenuChangeEvents(): void {
        toggleDms.listen((shouldOpen: boolean) => {
            if (shouldOpen && !this.isShown()) {
                this.showElement()
            } else if (!shouldOpen && this.isShown()) {
                this.hideElement()
            }
        })

        siteHeaderMenuOpened.listen((isOpen: boolean) => {
            if (!isOpen) {
                this.hideElement()
            }
        })
    }

    private handleUnreadCountUpdate(unread: number): void {
        if (unread > 0) {
            this.dmListIconRoot.classList.add("has_unread")
            this.hamburgerIcon?.classList.add("has_unseen")
        } else {
            this.dmListIconRoot.classList.remove("has_unread")
            const hasUserUpdates = this.userUpdatesBellRoot?.classList.contains("has_unseen") ?? false

            if (!hasUserUpdates) {
                this.hamburgerIcon?.classList.remove("has_unseen")
            }
        }
    }

    private bindNumUnreadEvents(): void {
        if (this.isRefactorDMFlagActive) {
            DMUnreadData.unreadDataUpdated.listen(({ unread }) => {
                this.handleUnreadCountUpdate(unread)
            })
        } else {
            ConversationListData.unreadConversationsCountUpdate.listen(() => {
                if (this.dmList === undefined) {
                    return
                }

                this.handleUnreadCountUpdate(this.dmList?.getNumUnread())
            })
        }
    }

    private bindResizeEvents(): void {
        if (!isiOS()) {
            // Prevent Android landscape keyboard from squeezing dm UI
            addEventListenerPoly("resize", window, () => {
                const viewportHeight = getViewportHeight()

                if (viewportHeight < 250) {
                    this.element.style.top = ""
                } else {
                    this.element.style.top = "0"
                }
            })
        }
    }
}

export const BackButton = (props: { onClick: () => void }): HTMLDivElement => {
    const backButtonStyle: CSSX.Properties = {
        height: "45px",
        display: "flex",
        alignItems: "center",
        paddingLeft: "10px",
    }

    return (
        <span className="BackButton" style={backButtonStyle} onClick={props.onClick} data-testid="back-button">{i18n.back}</span>
    )
}
