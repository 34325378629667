import { i18n } from "../translation"
import { BaseRoomTab } from "./baseRoomTab"
import { MoreRooms } from "./moreRooms"
import { TabName } from "./tabList"
import { userSwitchedTab } from "./userActionEvents"
import type { IBaseRoomTabProps } from "./baseRoomTab"
import type { BasePlayer } from "../player/basePlayer"

interface IMoreRoomsTabProps extends IBaseRoomTabProps {player: BasePlayer}

export class MoreRoomsTab extends BaseRoomTab {
    private player: BasePlayer
    protected moreRooms: MoreRooms

    constructor(player: BasePlayer) {
        const props: IMoreRoomsTabProps = {
            tabName: TabName.MoreRooms,
            tabLabel: i18n.moreRoomsText,
            pageActionName: "MoreRoomsOpened",
            player,
        }
        super(props)
    }

    protected initData(props: IMoreRoomsTabProps): void {
        super.initData(props)
        this.player = props.player
    }

    protected initUI(): void {
        super.initUI()

        this.moreRooms = new MoreRooms(this.player)
        this.addChild(this.moreRooms)

        userSwitchedTab.listen((tabName) => {
            if (tabName === this.getTabName()) {
                this.moreRooms.show()
            } else {
                this.moreRooms.hide()
            }
        })
    }
}
