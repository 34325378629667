// This module declares the expected interface for any potential client implementations and provides
// an abstract base for PushServiceClient to eliminate code duplication.
//
// For concrete implementations of IPushServiceClient, see `cbpush.ts` and `ablyio.ts`
//
import { SubSystemType } from "../../common/debug"
import { addPageAction, newRelicEnabled } from "../../common/newrelic"
import { PushService } from "./pushService"
import { ConnectionState, ErrorCode, SubscriptionState } from "./states"
import { TopicManager } from "./topics/topicManager"
import type { IAuthProvider } from "./auth"
import type { IConnectionStateChange, IErrorInfo } from "./states"
import type { IPushPresencePayload } from "./topics/topicManager"
import type { EventRouter } from "../../common/events"
import type { ArgJSONMap, NewrelicAttributes } from "@multimediallc/web-utils"

export function resolveConnectionState(state: string): ConnectionState {
    // Appears to be the only way to fool the compiler
    switch (state) {
        case ConnectionState.initialized:
            return ConnectionState.initialized
        case ConnectionState.connecting:
            return ConnectionState.connecting
        case ConnectionState.connected:
            return ConnectionState.connected
        case ConnectionState.closing:
            return ConnectionState.closing
        case ConnectionState.closed:
            return ConnectionState.closed
        case ConnectionState.disconnected:
        case "unavailable": // Pusher has a different name for this state
            return ConnectionState.disconnected
        case ConnectionState.failed:
            return ConnectionState.failed
        case ConnectionState.suspended:
            return ConnectionState.suspended
        default:
            return ConnectionState.unknown
    }
}

export function resolveSubscriptionState(state: string): SubscriptionState {
    // Appears to be the only way to fool the compiler
    switch (state) {
        case SubscriptionState.initialized:
            return SubscriptionState.initialized
        case "attaching":
            return SubscriptionState.subscribing
        case "attached":
            return SubscriptionState.subscribed
        case "detaching":
            return SubscriptionState.unsubscribing
        case "detached":
            return SubscriptionState.unsubscribed
        case SubscriptionState.failed:
            return SubscriptionState.failed
        case SubscriptionState.suspended:
            return SubscriptionState.suspended
        default:
            return SubscriptionState.unknown
    }
}


export function populateMap<V>(destination: Map<string, V>, source?: object): void{
    if (source === undefined) {
        return
    }
    const sourceCast = source as Record<string, V>
    const sourceKeys = Object.keys(source)
    sourceKeys.forEach(key => {
        const sourceValue = sourceCast[key]
        destination.set(key, sourceValue)
    })
}

export interface IDuplicateMessageMeta {
    providerId: string
    hash: string
    timestamp: number,
    server: string,
    host: string,
    connectionSerial: string,
}

export const enum ReportedActions {
    auth_failure = "auth_failure",
    unsubscribe_failure = "unsubscribe_failure",
    subscribe_failure = "subscribe_failure",
    subscribe_success = "subscribe_success",
    presence_failure = "presence_failure",
    leave_presence_failure = "leave_presence_failure",
    token_request_failed_topics = "token_request_failed_topics", // a TokenRequest can succeed overall but fail for individual topics
}

export interface IPushContextSettings {
    backend: string,
    flags: Map<string, boolean>,
    verifyEnabled: boolean,
}

export interface IClientCallbacks {
    onMessage: (clientName: string, topicKey: string, data: ArgJSONMap) => void
    onSubscribe: (clientName: string, topicKey: string, siuccess: boolean) => void
}

export abstract class PushServiceContext<T extends IPushContextSettings> {
    settings: T
    private topicKeyToChannelName: Map<string, string>
    private channelNameToTopicKeys: Map<string, string[]>
    private channelNameToTopicsMap: Map<string, Map<string, string>>
    private failures: Map<string, string>

    constructor(context: ArgJSONMap) {
        // we dont need client id anymore, ignore to suppress warnings
        context.ignore("client_id")
        this.topicKeyToChannelName = new Map<string, string>()
        this.channelNameToTopicKeys = new Map<string, string[]>()
        this.channelNameToTopicsMap = new Map<string, Map<string, string>>()
        this.failures = new Map<string, string>()

        if (!context.keys().includes("settings")) {
            warn("PushServiceContext: settings not found in context", {
                "contextKeys": context.keys().join(","),
                "context": context.stringMessage,
            })
        }

        const settings = context.getParsedSubMap("settings")
        this.settings = this.parseSettings(settings)

        populateMap<boolean>(this.settings.flags, settings.getObjectOrUndefined("flags"))
        this.populateTopics(context.getObjectOrUndefined("channels") as Record<string, string>)
        populateMap(this.failures, context.getObjectOrUndefined("failures"))
    }

    abstract isValid(): boolean

    abstract parseSettings(context: ArgJSONMap): T

    protected populateTopics(source: Record<string, string> | undefined): void {
        if (source === undefined) {
            return
        }
        const sourceKeys = Object.keys(source)
        for (const sourceKey of sourceKeys) {
            // We do this because backend sends back the map as:
            /*
                {
                    TopicClassName#topicKey: channelName,
                }
             */
            const topicId = sourceKey.split("#")[0]
            const topicKey = sourceKey.split("#")[1]
            const channelName = source[sourceKey]
            this.topicKeyToChannelName.set(topicKey, channelName)
            const currentTopics = this.channelNameToTopicKeys.get(channelName)
            if (currentTopics === undefined) {
                this.channelNameToTopicKeys.set(channelName, [topicKey])
            } else {
                currentTopics.push(topicKey)
                this.channelNameToTopicKeys.set(channelName, currentTopics)
            }
            let topics = this.channelNameToTopicsMap.get(channelName)
            if (topics === undefined){
                topics = new Map<string, string>()
            } else if (topics.has(topicId)) {
                warn("A topic with different topic keys is being registered on the same channel. This should never happen!", {
                    "channelName": channelName,
                    "topicId": topicId,
                }, SubSystemType.PushService)
                return
            }
            topics.set(topicId, topicKey)
            this.channelNameToTopicsMap.set(channelName, topics)
        }
    }

    public getChannelName(topicKey: string): string | undefined {
        return this.topicKeyToChannelName.get(topicKey)
    }

    public getTopicKeys(channelName: string): string[] | undefined {
        return this.channelNameToTopicKeys.get(channelName)
    }

    public deleteChannel(channelName: string): void {
        this.channelNameToTopicKeys.delete(channelName)
    }

    public deleteTopic(topicKey: string): void {
        this.topicKeyToChannelName.delete(topicKey)
    }

    public getChannelTopicMap(channelName: string): Map<string, string> | undefined {
        return this.channelNameToTopicsMap.get(channelName)
    }

    public getFailedTopics(): string[] {
        return Array.from(this.failures.keys(), channel => channel.split("#")[1])
    }
}

export interface IPushServiceClient {
    context?: PushServiceContext<IPushContextSettings>
    auth: IAuthProvider
    connectionChange: EventRouter<IConnectionStateChange>
    clientName: string
    creationTime: number

    addPageAction(action: ReportedActions, attributes: object): void

    connect(): Promise<void>
    close(): void
    getConnectionState(): ConnectionState
    isConnected(): boolean
    getReconnectCount(): number
    ensureConnected(): Promise<void>
    ensureConnectedAndAuthed(topicKey: string): Promise<void>
    getAuthPromise(topicsToAuth: string[]): Promise<void>

    isSubscribedTo(topicKey: string): boolean
    subscribe(topicKey: string): Promise<void>
    unsubscribe(topicKey: string): Promise<void>
    enterPresence(topicKey: string, payload: IPushPresencePayload): Promise<void>
    leavePresence(topicKey: string): Promise<void>

    addExternalMessage(topicKey: string, tid: string): boolean
    clearMessagesForHistory(): void

    getCriticallyFailedTopics(): string[]
    shouldRetrySubscribe(): boolean

    getConnectionType(): string
    getConnectionId(): string
    getClientId(): string
    hasBusyConnection(): boolean
}

export abstract class PushServiceClient implements IPushServiceClient {
    // Client implementation
    public readonly auth: IAuthProvider
    public context?: PushServiceContext<IPushContextSettings>
    public connectionChange: EventRouter<IConnectionStateChange>

    // Topic keys that the client is currently subscribed to.
    protected readonly activeSubscriptions = new Set<string>()
    protected readonly topicsToSubscribe = new Set<string>()
    protected readonly topicPromises = new Map<string, Promise<void>>()

    // Extra data for NR page actions
    public readonly creationTime: number = new Date().getTime()
    public abstract readonly clientName: string

    // Handling duplicate messages
    private seenMessages = new Map<string, IDuplicateMessageMeta[]>()
    private seenMessagesLimit = 5000

    // Handling sent messages to only display once
    private messagesInChat = new Map<string, IDuplicateMessageMeta[]>()

    // Topics that failed to subscribe/unsubscribe
    private failedTopics = new Map<string, number>()
    private readonly criticalFailLimit = 3

    // base connection management
    private connectPromise?: Promise<void>

    protected subscribedTopicKeysByChannel = new Map<string, string[]>()


    // Constructor
    protected constructor(protected callbacks: IClientCallbacks) {
    }

    // Diagnostics
    public addPageAction(action: ReportedActions, attributes: NewrelicAttributes): void {
        if (newRelicEnabled()) {
            attributes["action"] = action
            attributes["created"] = this.creationTime
            attributes["client"] = this.clientName
            addPageAction("PushServiceClient", attributes)
        }
    }

    // Connection Handling

    public abstract close(): void

    public connect(): Promise<void> {
        if (this.isConnected()) {
            return Promise.resolve()
        } else if (this.hasBusyConnection() && this.connectPromise === undefined) {
            return Promise.reject("Connection is currently busy!")
        }

        if (this.connectPromise === undefined) {
            this.connectPromise = new Promise((resolve, reject) => {
                const connectListener = (stateChange: IConnectionStateChange) => {
                    if (stateChange.current === ConnectionState.connected) {
                        if (this.isConnected()){
                            this.connectPromise = undefined
                            this.connectionChange.removeListener(connectListener)
                            resolve()
                        }
                    } else if (stateChange.previous === ConnectionState.connecting) {
                        // State change from 'connecting' when 'current' is not 'connected'
                        this.connectPromise = undefined
                        this.connectionChange.removeListener(connectListener)
                        reject(stateChange.reason)
                    }
                }

                this.connectionChange.listen(connectListener)
                this._connect()
            })
        }
        return this.connectPromise
    }

    protected abstract _connect(): void

    public abstract getConnectionState(): ConnectionState

    public isConnected(): boolean {
        return this.getConnectionState() === ConnectionState.connected
    }

    public hasBusyConnection(): boolean {
        const busyStates = new Set([ConnectionState.connecting, ConnectionState.closing])
        return busyStates.has(this.getConnectionState())
    }

    public abstract getReconnectCount(): number

    public ensureConnected(): Promise<void> {
        if (this.isConnected()) {
            return Promise.resolve()
        } else {
            return this.connect()
        }
    }

    public ensureConnectedAndAuthed(topicKey: string): Promise<void> {
        return this.ensureConnected().then(() => {
            return this.auth.canAccessTopic(topicKey) ?
                Promise.resolve() : Promise.reject(`Auth context has no access: ${this.auth.serialize()}`)
        })
    }

    public getAuthPromise(topicsToAuth: string[]): Promise<void> {
        const authTopics: string[] = []
        const subscribeTopics: string[] = []

        topicsToAuth.forEach(t => {
            if (this.auth.getTopicKeys().indexOf(t) === -1) {
                authTopics.push(t)
            }
            if (!this.isSubscribedTo(t) || TopicManager.hasPresence(t)) {
                subscribeTopics.push(t)
            }
        })
        return authTopics.length < 1 ? Promise.resolve() : this.auth.updateAuthToken()
    }

    // Subscription Handling

    public isSubscribedTo(topicKey: string): boolean {
        return this.auth.canAccessTopic(topicKey) &&
            this.activeSubscriptions.has(topicKey)
    }

    public subscribe(topicKey: string): Promise<void> {
        this.topicsToSubscribe.add(topicKey)
        // client implementation of subscribe must throw with IErrorInfo
        return this.updateChannelSubscription(topicKey).then(() => {
            this.topicPromises.delete(topicKey)
            return Promise.resolve()
        }).catch((err: IErrorInfo) => {
            this.handleTopicFailure(topicKey)
            this.topicPromises.delete(topicKey)
            const closingStates = [ConnectionState.closed, ConnectionState.closing, ConnectionState.disconnected]
            if (closingStates.includes(this.getConnectionState())) {
                // If we manually close the PushService while a topic is setting up, don't treat as an error
                return Promise.resolve()
            }
            return Promise.reject(err)
        })
    }

    public unsubscribe(topicKey: string): Promise<void> {
        this.topicsToSubscribe.delete(topicKey)
        return this.updateChannelSubscription(topicKey).then(() => {
            this.topicPromises.delete(topicKey)
        }).catch((err: IErrorInfo) => {
            this.handleUnsubscribed(topicKey)
            this.topicPromises.delete(topicKey)
            return Promise.reject(err)
        })
    }

    private updateChannelSubscription(topicKey: string): Promise<void> {
        const topicPromise = this.topicPromises.get(topicKey)
        if (topicPromise !== undefined) {
            return topicPromise.then(() => {
                return this.updateChannelSubscription(topicKey)
            })
        }
        const channelName = this.getChannelName(topicKey)
        if (channelName === undefined) {
            return Promise.reject({ code: ErrorCode.topic_error, message: "Unknown topic key" })
        }

        const currentState = this.getChannelState(channelName)
        const shouldSubscribe = this.topicsToSubscribe.has(topicKey)
        const topicKeys = this.subscribedTopicKeysByChannel.get(channelName)
        if (shouldSubscribe) {
            if (currentState === SubscriptionState.subscribed || currentState === SubscriptionState.subscribing) {
                return Promise.resolve()
            }
            return this.checkSubscribe(channelName, topicKeys, topicKey)
        } else {
            if (currentState === SubscriptionState.unsubscribed || currentState === SubscriptionState.unsubscribing) {
                return Promise.resolve()
            }
            return this.checkUnsubscribe(channelName, topicKeys, topicKey)
        }
    }

    protected abstract _subscribe(topicKey: string): Promise<void>

    protected abstract _unsubscribe(topicKey: string): Promise<void>

    public abstract enterPresence(topicKey: string, payload?: object): Promise<void>

    public abstract leavePresence(topicKey: string): Promise<void>

    protected checkForReauth(messageData: ArgJSONMap): void {
        if (messageData.getStringOrUndefined("_control") === "reauth") {
            const channelNames = messageData.getStringList("_channel_names")
            const reauthPromises: Promise<void>[] = []
            const presence = TopicManager.getPresenceKeys()
            channelNames.forEach(reauthChannel => {
                const reauthTopicKeys = this.getTopicKeys(reauthChannel)
                if (reauthTopicKeys !== undefined) {
                    reauthTopicKeys.forEach(reauthTopicKey => {
                        reauthPromises.push(this.unsubscribe(reauthTopicKey))
                    })
                } else {
                    reauthPromises.push(Promise.resolve())
                }
            })
            reauthPromises.push(...presence.map(p => this.unsubscribe(p)))

            Promise.all(reauthPromises).then(() => {
                channelNames.forEach(c => {
                    const topics = this.getTopicKeys(c)
                    this.context?.deleteChannel(c)
                    if (topics !== undefined) {
                        topics.forEach(t => {
                            this.context?.deleteTopic(t)
                        })
                    }
                })
                PushService.updateAuthorization()
            }).catch(err => {
                // add to err so newrelic adds to attributes
                err["topics"] = channelNames
                error("Error during re-auth detach", err, SubSystemType.PushService)
            })
        }
    }

    protected handleSubscribed(topicKey: string): void {
        this.failedTopics.delete(topicKey)
        this.activeSubscriptions.add(topicKey)
        this.callbacks.onSubscribe(this.clientName, topicKey, true)
    }

    protected handleUnsubscribed(topicKey: string): void {
        this.failedTopics.delete(topicKey)
        this.activeSubscriptions.delete(topicKey)
        this.callbacks.onSubscribe(this.clientName, topicKey, false)
    }

    protected handleTopicFailure(topicKey: string): void {
        const count = this.failedTopics.get(topicKey)
        this.failedTopics.set(topicKey, count === undefined ? 1 : count + 1)
    }

    public shouldRetrySubscribe(): boolean {
        const topics = this.auth.getTopicKeys()
        for(const [key, count] of this.failedTopics.entries()) {
            if (topics.includes(key) && count < this.criticalFailLimit) {
                return true
            }
        }
        return false
    }

    public getCriticallyFailedTopics(): string[] {
        return Array.from(this.failedTopics.keys()).filter(key => {
            const count = this.failedTopics.get(key)
            return count !== undefined && count > this.criticalFailLimit
        })
    }

    public getConnectionType(): string {
        return ""
    }

    public getConnectionId(): string {
        return ""
    }

    public getClientId(): string {
        return ""
    }

    public getConnectionHost(): string {
        return ""
    }

    public getConnectionServer(): string {
        return ""
    }

    public getConnectionSerial(): string {
        return ""
    }

    protected getChannelName(topicKey: string): string | undefined {
        return this.context?.getChannelName(topicKey)
    }

    protected getTopicKeys(channelName: string): string[] | undefined {
        return this.context?.getTopicKeys(channelName)
    }

    protected getChannelTopicMap(channelName: string): Map<string, string> | undefined {
        return this.context?.getChannelTopicMap(channelName)
    }

    protected abstract getChannelState(channelName: string): SubscriptionState

    // handle deduplication and reporting
    public addExternalMessage(topicKey: string, tid: string): boolean {
        const key = `${this.getChannelName(topicKey)}:${tid}`
        if (this.messagesInChat.get(key) === undefined) {
            // not seen before, set to empty array so client doesnt report error but still drops message
            this.messagesInChat.set(key, [])
            // check if the MessageTopic has been recieved already
            const seenTimestamps = this.seenMessages.get(key)
            if (seenTimestamps === undefined) {
                return false
            }
        }
        // no need to report or add to the list of messages, just drop message
        return true
    }

    public clearMessagesForHistory(): void {
        this.seenMessages = new Map<string, IDuplicateMessageMeta[]>()
        this.messagesInChat = new Map<string, IDuplicateMessageMeta[]>()
    }

    protected handleMessageDuplicate(channelName: string, messageData: ArgJSONMap, tid: string): boolean {
        const key = `${channelName}:${tid}`
        const seenTimestamps = this.seenMessages.get(key)
        if (seenTimestamps === undefined) {
            if (this.seenMessages.size > this.seenMessagesLimit) {
                const arr = Array.from(this.seenMessages.entries())
                this.seenMessages = new Map(arr.splice(Math.floor(arr.length * -0.5)))
            }
            this.seenMessages.set(key, [this.getDuplicateMessageMeta(messageData)])
            return false
        } else {
            seenTimestamps.push(this.getDuplicateMessageMeta(messageData))
            if (seenTimestamps.length !== 0) {
                // only report if seen message comes from service, not from history
                this.reportDuplicateMessage(channelName, messageData, tid, seenTimestamps)
            }
        }
        return true
    }

    private reportDuplicateMessage(channelName: string, messageData: ArgJSONMap, tid: string, previousMessages: IDuplicateMessageMeta[]): void {
        const atts = this.duplicateMessageAttributes(channelName, messageData, tid, previousMessages)
        debug("[PushService] Duplicate Message: ", atts)
        if (newRelicEnabled()) {
            addPageAction("PushDuplicateMessage", atts)
        }
    }

    protected duplicateMessageAttributes(channelName: string, messageData: ArgJSONMap, tid: string, previousMessages: IDuplicateMessageMeta[]): NewrelicAttributes {
        const firstItem = previousMessages[0]
        const newestId = messageData.getParsedSubMap("providerData").getStringOrUndefined("id") ?? "invalid_provider_id"
        const newestHash = this.hashMessage(messageData.stringMessage)
        const topic = messageData.getStringOrUndefined("_topic")
        let payload = {}
        if (Math.floor(Math.random() * 400) === 0) {
            payload = messageData
        }
        return {
            "tid": tid,
            "first_seen_time": firstItem.timestamp,
            "time_since_first_seen": new Date().getTime() - firstItem.timestamp,
            "num_duplicates": previousMessages.length,
            "client": this.clientName,
            "connection_host": this.getConnectionHost(),
            "connection_type": this.getConnectionType(),
            "connection_state": this.getConnectionState(),
            "num_reconnects": this.getReconnectCount(),
            "same_content": newestHash === firstItem.hash,
            "same_pid": newestId === firstItem.providerId,
            "same_server": firstItem.server === this.hashMessage(this.getConnectionServer()),
            "same_host": firstItem.host === this.hashMessage(this.getConnectionHost()),
            "orig_connection_serial": firstItem.connectionSerial,
            "channel_name": channelName,
            "pid": newestId,
            "topic": topic,
            "messageData": JSON.stringify(payload),
        }
    }

    private hashMessage(message: string): string {
        if (message === "") {
            return ""
        }
        return Array.from(message, ch => ch.charCodeAt(0)).reduce((prev, curr) => prev + curr).toString() + message.length.toString()
    }

    private getDuplicateMessageMeta(message: ArgJSONMap): IDuplicateMessageMeta {
        return {
            timestamp: new Date().getTime(),
            hash: this.hashMessage(message.stringMessage),
            providerId: message.getParsedSubMap("providerData").getStringOrUndefined("id") ?? "invalid_provider_id",
            server: this.hashMessage(this.getConnectionServer()),
            host: this.hashMessage(this.getConnectionHost()),
            connectionSerial: this.getConnectionSerial(),
        }
    }

    private checkSubscribe(channelName: string, topicKeys: string[] | undefined, topicKey: string): Promise<void>{
        if (topicKeys === undefined) {
            this.subscribedTopicKeysByChannel.set(channelName, [topicKey])
        } else if (!topicKeys.includes(topicKey)) {
            topicKeys.push(topicKey)
            this.subscribedTopicKeysByChannel.set(channelName, topicKeys)
            return Promise.resolve()
        }
        return this._subscribe(topicKey).then(() => {
            // handle subscribe for all topics associated with subscribed channel
            const topicKeys = this.getTopicKeys(channelName)
            if (topicKeys !== undefined) {
                topicKeys.forEach((topicKey) => {
                    this.handleSubscribed(topicKey)
                })
            }
        })
    }

    private checkUnsubscribe(channelName: string, topicKeys: string[] | undefined, topicKey: string): Promise<void> {
        if (topicKeys !== undefined && topicKeys.includes(topicKey)) {
            const index = topicKeys.indexOf(topicKey)
            topicKeys.splice(index, 1)
            this.subscribedTopicKeysByChannel.set(channelName, topicKeys)
            if (topicKeys.length === 0) {
                this.subscribedTopicKeysByChannel.delete(channelName)
            } else {
                return Promise.resolve()
            }
        }
        return this._unsubscribe(topicKey).then(() => {
            // handle unscubscribe for all topics associated with unsubscribed channel
            const topicKeys = this.getTopicKeys(channelName)
            if (topicKeys !== undefined) {
                topicKeys.forEach((topicKey) => {
                    this.handleUnsubscribed(topicKey)
                })
            }
        })
    }
}
