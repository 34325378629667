import { trackCustomEvent } from "@convivainc/conviva-js-appanalytics"
import { isPortrait } from "@multimediallc/web-utils"
import { isAndroid, isSamsungBrowser } from "@multimediallc/web-utils/modernizr"
import { getCookieOrEmptyString } from "@multimediallc/web-utils/storage"
import { addEventListenerPoly, removeEventListenerPoly } from "../../common/addEventListenerPolyfill"
import { recordInteractionIfAffiliatePage } from "../../common/affiliates"
import { normalizeResource } from "../../common/api"
import { roomLoaded } from "../../common/context"
import { type BoundListener, EventRouter } from "../../common/events"
import { fullscreenChange } from "../../common/fullscreen"
import { ModalComponent } from "../../common/modalComponent"
import { addPageAction } from "../../common/newrelic"
import { convivaEnabled } from "../../common/player/utils"
import { getCurrentPathWithSearchQuery, getRedirectInfo, getRelativePathWithRedirectQuery, LOGIN_BASE_PATH, SIGN_UP_BASE_PATH } from "../../common/redirectParamUtils"
import { i18n } from "../../common/translation"
import { dom } from "../../common/tsxrender/dom"
import { goToHomepage, isInIframe, mediumPopupLinkFeatures, safeWindowOpen } from "../../common/windowUtils"
import { pageContext } from "../interfaces/context"
import { currentSiteSettings } from "../siteSettings"
import type { ISocialAuth } from "../interfaces/socialAuth"

export const loginOverlayShown = new EventRouter<undefined>("loginOverlayShown")

export interface ILoginOverlayProps {
    fromFeature?: boolean,
    seeBackground?: boolean,
    returnToPage?: boolean,
    refreshOpener?: boolean,
    requiredForPage?: boolean,
}

const signupRedirectUrl = `${SIGN_UP_BASE_PATH}?src=login_gate`

const stopNonEscProp = (event: KeyboardEvent) => {
    if (event.key !== "Escape") {
        event.stopImmediatePropagation()
    }
}

let roomName = ""
let roomUid = ""
// eslint-disable-next-line @multimediallc/no-global-listener
roomLoaded.listen((context) => {
    roomName = context.chatConnection.room()
    roomUid = context.dossier.roomUid
})

let ssoClicks = 0

function ssoLink(auth: ISocialAuth): HTMLAnchorElement {
    const ssoLink = getRelativePathWithRedirectQuery(`/auth-sso/login/${auth.provider}/?popup=1`, getRedirectInfo({ ignoreExistingRoom: true }))
    return (
        <a className="auth_option" href="#"
            onClick={(event) => {
                event.preventDefault()
                ssoClicks += 1
                if (!(ssoClicks > 1)) {
                    addPageAction("SsoSignupClick", {
                        "provider": auth.provider,
                        "source": "loginOverlay",
                    })
                    safeWindowOpen(ssoLink, "_blank", mediumPopupLinkFeatures)
                }
                window.setTimeout(() => {
                    ssoClicks = 0
                }, 1000)
            }}>
            <div>
                <img src={`${STATIC_URL_ROOT}images/sso-${auth.provider}.svg`} alt={auth.provider}/>
                <p>{i18n.signInWithSocialAuth(auth.name)}</p>
            </div>
        </a>
    )
}

/**
 * @styles: scss/theme/shared/loginOverlay.scss
 */
export class LoginOverlay extends ModalComponent {
    protected loginContainer: HTMLFormElement
    private header: HTMLHeadingElement
    protected usernameInput: HTMLInputElement
    protected passwordInput: HTMLInputElement
    private loginButton: HTMLInputElement
    private closeButton: HTMLInputElement
    private rememberMeCheckBox: HTMLInputElement
    private nextField: HTMLInputElement
    private csrfField: HTMLInputElement
    protected bodyElement?: HTMLElement
    private signupLink: HTMLAnchorElement
    private forgotPasswordLink: HTMLAnchorElement
    public isVisible = false
    private autofillPollInterval = 0
    private fullscreenChangeListener: BoundListener<void> | undefined
    private requiredForPage = false

    constructor() {
        super({
            onShow: () => this.onShow(),
            onHide: (fromNavigation) => this.onHide(fromNavigation),
            easyExit: true,
        })

        const createChildDiv = (parent: HTMLElement): HTMLElement => {
            const group = document.createElement("div")
            group.style.display = "block"
            group.style.margin = "10px 0 0 0"
            parent.appendChild(group)
            return group
        }

        this.element.style.display = "none"
        this.element.style.position = "fixed"
        this.element.style.fontSize = "12px"
        this.element.style.width = "364px"
        this.element.style.height = "auto"
        this.element.style.color = "currentSiteSettings.defaultColor"
        this.element.style.margin = "auto"
        this.element.style.boxShadow = "0px 0px 10px rgba(0, 0, 0, 0.4)"
        this.element.style.background = "white"
        this.element.style.border = "3px solid #0E6C92"
        this.element.style.borderRadius = "10px"
        this.element.style.visibility = "visible"
        this.element.id = "LoginOverlay"
        this.element.dataset["testid"] = "login-form"

        this.overlay.classList.add("blurred-login-overlay")
        this.overlay.style.visibility = "visible"
        this.overlay.style.background = ""
        this.overlay.style["-webkit-overflow-scrolling"] = "touch"

        this.loginContainer = document.createElement("form")
        this.loginContainer.target = "_top"
        this.loginContainer.style.padding = "5px 30px 10px 30px"
        this.loginContainer.action = normalizeResource(getRelativePathWithRedirectQuery(LOGIN_BASE_PATH, getRedirectInfo({ ignoreExistingRoom: true })))
        this.loginContainer.method = "POST"
        this.loginContainer.onclick = (event: MouseEvent) => {
            event.stopPropagation()
        }
        this.element.appendChild(this.loginContainer)

        const closeDiv = createChildDiv(this.loginContainer)
        const headerDiv = createChildDiv(this.loginContainer)
        const usernameDiv = createChildDiv(this.loginContainer)
        const passwordDiv = createChildDiv(this.loginContainer)
        const rememberMeDiv = createChildDiv(this.loginContainer)
        const loginButtonDiv = createChildDiv(this.loginContainer)
        const ssoSignInDiv = createChildDiv(this.loginContainer)
        const signUpLinkDiv = createChildDiv(this.loginContainer)
        const hiddenInputContainer = createChildDiv(this.loginContainer)

        rememberMeDiv.style.display = "flex"
        rememberMeDiv.style.alignItems = "center"

        this.closeButton = document.createElement("input")
        this.closeButton.type = "button"
        this.closeButton.onclick = (e: Event) => {
            e.preventDefault()
            this.hide()
        }
        this.closeButton.value = ""
        this.closeButton.style.background = `transparent url(${STATIC_URL}close_icon2.svg) no-repeat center center`
        this.closeButton.style.backgroundSize = "10px"
        this.closeButton.style.padding = "10px 20px 8px 12px"
        this.closeButton.style.border = "none"
        this.closeButton.style.cursor = "pointer"
        this.closeButton.dataset.testid = "login-modal-close-button"
        closeDiv.appendChild(this.closeButton)
        closeDiv.style.position = "absolute"
        closeDiv.style.top = "0px"
        closeDiv.style.right = "0px"
        closeDiv.style.margin = "0px"

        this.header = document.createElement("h1")
        this.header.style.font = "18px ubuntumedium,Arial,Helvetica,sans-serif"
        this.header.style.color = "#f47422"
        headerDiv.appendChild(this.header)
        headerDiv.style.textAlign = "center"

        this.usernameInput = this.createFormInput(usernameDiv, `${i18n.usernameText}:`, "text", "username")
        this.usernameInput.required = true
        this.usernameInput.dataset["testid"] = "username-input"

        this.forgotPasswordLink = document.createElement("a")
        this.forgotPasswordLink.target = "_top"
        this.forgotPasswordLink.href = normalizeResource("/auth/password_reset/")
        this.forgotPasswordLink.tabIndex = 0
        this.forgotPasswordLink.textContent = i18n.forgotPassword
        this.forgotPasswordLink.style.cssFloat = "right"
        this.forgotPasswordLink.dataset["testid"] = "forgot-password-link"
        passwordDiv.appendChild(this.forgotPasswordLink)
        this.passwordInput = this.createFormInput(passwordDiv, `${i18n.passwordText}:`, "password", "password")
        this.passwordInput.required = true
        this.passwordInput.dataset["testid"] = "password-input"
        this.rememberMeCheckBox = this.createFormInput(rememberMeDiv, `${i18n.keepLoggedInText}:`, "checkbox", "rememberme")
        this.rememberMeCheckBox.style.marginLeft = "10px"
        this.rememberMeCheckBox.dataset["testid"] = "remember-me-check-box"

        this.loginButton = document.createElement("input")
        this.loginButton.dataset["testid"] = "login-button"
        this.loginButton.id = "id_login_btn"
        this.loginButton.type = "submit"
        this.loginButton.disabled = true
        this.loginButton.value = i18n.loginVerbText
        if (currentSiteSettings.isWhiteLabel) {
            this.loginButton.style.backgroundColor = currentSiteSettings.signupBgColor
        }
        if (convivaEnabled()) {
            this.loginButton.onclick = () => {
                if (!this.loginButton.disabled) {
                    trackCustomEvent({
                        name: "LoginRequest",
                        data: {
                            "roomName": roomName,
                            "broadcasterID": roomUid,
                        },
                    })
                }
            }
        }
        loginButtonDiv.appendChild(this.loginButton)

        const socialAuths = pageContext.current.socialAuths
        if (socialAuths !== undefined && socialAuths.length > 0) {
            const orSeparator = <div className="or_separator">
                <p>&nbsp;{ i18n.orSimple }&nbsp;</p>
            </div>
            ssoSignInDiv.appendChild(orSeparator)

            for (const auth of socialAuths) {
                ssoSignInDiv.appendChild(ssoLink(auth))
            }
            ssoSignInDiv.style.marginBottom = "16px"
        }

        const signupText = document.createElement("p")
        signupText.textContent = `${i18n.dontHaveAccountYet} `
        signupText.style.display = "inline"
        this.signupLink = document.createElement("a")
        this.signupLink.target = "_top"
        this.signupLink.classList.add("signupLogin")
        this.signupLink.href = signupRedirectUrl
        this.signupLink.tabIndex = 0
        this.signupLink.textContent = i18n.signUpText
        signUpLinkDiv.appendChild(signupText)
        signUpLinkDiv.appendChild(this.signupLink)
        signUpLinkDiv.style.textAlign = "center"

        const getUrlNext = (): string => {
            const redirectInfo = getRedirectInfo({ ignoreExistingRoom: true })

            // redirect to homepage on success if on autologin page or reg login page w/ no next param
            if (getCurrentPathWithSearchQuery().indexOf("/al/") !== -1 || redirectInfo.nextParam === undefined) {
                return "/"
            }

            return redirectInfo.nextParam
        }

        this.nextField = document.createElement("input")
        this.csrfField = document.createElement("input")
        this.nextField.type = this.csrfField.type = "hidden"
        this.nextField.name = "next"
        this.nextField.value = getUrlNext() // may get overridden on `roomLoaded` below
        this.csrfField.name = "csrfmiddlewaretoken"
        this.csrfField.value = getCookieOrEmptyString("csrftoken")
        hiddenInputContainer.appendChild(this.nextField)
        hiddenInputContainer.appendChild(this.csrfField)
        hiddenInputContainer.style.display = "none"

        // To avoid scrolling away from login form
        this.closeButton.onkeydown = this.tabListenerFactory(this.usernameInput, this.signupLink)
        this.usernameInput.onkeydown = this.tabListenerFactory(this.passwordInput, this.closeButton)
        this.passwordInput.onkeydown = this.tabListenerFactory(this.forgotPasswordLink, this.usernameInput)
        this.forgotPasswordLink.onkeydown = this.tabListenerFactory(this.rememberMeCheckBox, this.passwordInput)
        this.rememberMeCheckBox.onkeydown = this.tabListenerFactory(this.loginButton, this.forgotPasswordLink)
        this.loginButton.onkeydown = this.tabListenerFactory(this.signupLink, this.rememberMeCheckBox)
        this.signupLink.onkeydown = this.tabListenerFactory(this.closeButton, this.loginButton)

        this.usernameInput.oninput = this.detectInputFilled
        this.passwordInput.oninput = this.detectInputFilled

        roomLoaded.listen(() => {
            this.nextField.value = getCurrentPathWithSearchQuery()
        })
    }

    private tabListenerFactory = (tabFocus: HTMLElement, shiftTabFocus: HTMLElement): ((e: KeyboardEvent) => void) => {
        return (e: KeyboardEvent) => {
            if (e.key === "Tab") {
                e.preventDefault()
                if (!e.shiftKey) {
                    tabFocus.focus()
                } else {
                    shiftTabFocus.focus()
                }
            }
        }
    }

    public initAndShow(props: ILoginOverlayProps): void {  // eslint-disable-line complexity
        if (isInIframe() && roomName !== "") {
            safeWindowOpen(`/auth/login/?next=/${roomName}/`)
            return
        }
        if (pageContext.current.forceSocialAuth !== undefined && pageContext.current.forceSocialAuth.length > 0) {
            window.location.href = normalizeResource(getRelativePathWithRedirectQuery(LOGIN_BASE_PATH, getRedirectInfo({ ignoreExistingRoom: true })))
            return
        }

        // Deals with the edge case :
        // Login overlay can appear along with Join overlay and/or feedback form.
        // Both components should listen to when the login overlay is shown, and then hide that component
        loginOverlayShown.fire(undefined)

        this.header.innerText = props.fromFeature !== undefined && props.fromFeature ? i18n.loginToUseFeature : i18n.loginToContinue
        this.header.dataset.testid = "login-modal-header-text"
        this.csrfField.value = getCookieOrEmptyString("csrftoken")
        this.element.style.display = "block"
        super.show()
        if (props.seeBackground !== undefined && props.seeBackground) {
            this.unhideBodyElement()
            this.overlay.style.backgroundColor = "rgba(0, 0, 0, 0.5)"
        }
        this.signupLink.href = normalizeResource(props.returnToPage === false ? signupRedirectUrl : getRelativePathWithRedirectQuery(signupRedirectUrl, getRedirectInfo({})))
        if (props.refreshOpener === true) {
            const redirectInfo = getRedirectInfo({ ignoreExistingRoom: true })
            if (redirectInfo.nextParam !== undefined) {
                if (redirectInfo.nextParam.endsWith("/")) {
                    redirectInfo.nextParam += "?refresh_opener=1"
                } else {
                    redirectInfo.nextParam += "/refresh_opener=1"
                }
                this.nextField.value = redirectInfo.nextParam
            }
            this.loginContainer.action = normalizeResource(getRelativePathWithRedirectQuery(`${LOGIN_BASE_PATH}`, redirectInfo))
            this.signupLink.href = `${this.signupLink.href}?refresh_opener=1`
        }

        this.requiredForPage = props.requiredForPage ?? false

        this.createAutofillPollInterval()
        this.usernameInput.focus()
    }

    // Detect when submit is allowed
    private detectInputFilled = () => {
        if (this.passwordInput.value.trim() !== "" && this.usernameInput.value.trim() !== "") {
            this.loginButton.removeAttribute("disabled")
            this.rememberMeCheckBox.onkeydown = this.tabListenerFactory(this.loginButton, this.forgotPasswordLink)
            this.loginButton.onkeydown = this.tabListenerFactory(this.signupLink, this.rememberMeCheckBox)
            this.signupLink.onkeydown = this.tabListenerFactory(this.closeButton, this.loginButton)
            this.clearAutofillPollInterval()
        } else {
            this.loginButton.disabled = true
            this.rememberMeCheckBox.onkeydown = this.tabListenerFactory(this.signupLink, this.forgotPasswordLink)
            this.signupLink.onkeydown = this.tabListenerFactory(this.closeButton, this.rememberMeCheckBox)
            this.createAutofillPollInterval()
        }
    }

    private clearAutofillPollInterval = () => {
        if (this.autofillPollInterval) {
            window.clearInterval(this.autofillPollInterval)
            this.autofillPollInterval = 0
        }
    }

    // firefox doesn't trigger input/change events when autofilling and inconsistency between browsers is a recurring issue.
    // we can poll for the filled fields in addition to listening to field change events to catch all cases of autofill.
    private createAutofillPollInterval = () => {
        if (!this.autofillPollInterval) {
            this.autofillPollInterval = window.setInterval(() => {
                this.detectInputFilled()
            }, 200)
        }
    }

    protected createFormInput(parent: HTMLElement, labelText: string, inputType: string, inputID: string): HTMLInputElement {
        const label = document.createElement("label")
        const input = document.createElement("input")
        label.innerText = labelText
        label.htmlFor = inputID
        input.type = inputType
        input.id = inputID
        input.name = inputID
        input.style.height = inputType === "checkbox" ? "18px" : "26px"
        input.style.width = inputType === "checkbox" ? "18px" : "100%"
        input.style.font = "100% Arial,Helvetica,sans-serif"
        input.style.color = "#000"
        input.style.boxSizing = "border-box"
        input.style.border = "1px solid #d5d5d5"
        input.style.padding = "2px 4px"
        input.style.margin = "1px 0"
        input.style.lineHeight = "18px"
        input.style.borderRadius = "4px"
        addEventListenerPoly("keydown", input, (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                event.preventDefault()
                this.loginButton.click()
            }
        })
        parent.appendChild(label)
        parent.appendChild(input)
        return input
    }

    private unhideBodyElement(): void {
        if (this.bodyElement !== undefined) {
            this.bodyElement.style.visibility = ""
            this.bodyElement.style.backgroundColor = ""
            this.bodyElement.style.overflow = ""
            this.bodyElement.style.position = ""
            this.bodyElement.classList.remove("blurred-login-overlay-shown", "embed-blurred-login-overlay")
        }
    }

    private hideBodyElement(): void {
        this.unhideBodyElement()
        if (this.element.parentElement !== null) {
            this.bodyElement = this.element.parentElement
            this.bodyElement.style.overflow = "hidden"
            this.bodyElement.classList.add("blurred-login-overlay-shown")

            // Specifically for samsung browsers, the address bar tends to mess with the
            // no scroll behavior, except if we add a position="fixed"
            if (isSamsungBrowser()) {
                this.bodyElement.style.position = "fixed"
                this.bodyElement.classList.add("embed-blurred-login-overlay")
            }
        }
    }

    protected repositionChildren(): void {
        const availableWidth = Math.max(document.documentElement.clientWidth, window.innerWidth)
        const availableHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)
        this.element.style.left = `${(availableWidth - this.loginContainer.offsetWidth) / 2}px`
        this.element.style.top = `${(availableHeight - this.loginContainer.offsetHeight) / 2}px`
    }

    private onShow(): void {
        this.repositionChildren()
        this.isVisible = true
        this.usernameInput.focus()
        addEventListenerPoly("keydown", this.element, stopNonEscProp)
        this.fullscreenChangeListener = fullscreenChange.listen(() => this.hideBodyElement())
        this.hideBodyElement()
    }

    private onHide(fromNavigation: boolean): void {
        if (this.requiredForPage && !fromNavigation) {
            goToHomepage()
        }
        const wasVisible = this.isVisible
        this.element.style.display = "none"
        this.isVisible = false
        if (wasVisible) {
            removeEventListenerPoly("keydown", this.element, stopNonEscProp)
            this.fullscreenChangeListener?.removeListener()
            this.fullscreenChangeListener = undefined
        }
        this.unhideBodyElement()
        this.clearAutofillPollInterval()
    }
}

export class MobileLoginOverlay extends LoginOverlay {
    constructor() {
        super()
        this.element.style.width = "calc(96% - 6px)"
        this.element.style.maxWidth = "364px"
        this.element.style.margin = ""
        this.element.style.fontSize = "12px"
        this.element.style.position = "fixed"
        this.element.style.overflow = "scroll"
    }

    protected createFormInput(parent: HTMLElement, labelText: string, inputType: string, inputID: string): HTMLInputElement {
        const input = super.createFormInput(parent, labelText, inputType, inputID)
        input.style.fontFamily = "'UbuntuRegular', Arial, Helvetica, sans-serif"
        input.style.fontSize = "16px"

        return input
    }

    protected repositionChildren(): void {
        const availableWidth = Math.max(document.documentElement.clientWidth, window.innerWidth)
        const availableHeight = Math.max(document.documentElement.clientHeight, window.innerHeight)

        const left = (availableWidth - this.loginContainer.offsetWidth) / 2
        let top = Math.max(50, (availableHeight - this.loginContainer.offsetHeight) / 2)
        if (isAndroid()) {
            top = Math.max(50, (availableHeight - this.loginContainer.offsetHeight) / 5)
        }

        const isPortraitMode = isPortrait()
        const smallDeviceBreakpoint = 576
        const shouldFillHeight = !isPortraitMode && availableHeight < smallDeviceBreakpoint

        this.element.style.left = `${left}px`
        this.element.style.top = shouldFillHeight ? "0px" : `${top}px`
        this.element.style.bottom = shouldFillHeight ? "0px" : ""

        const loginInputHasFocus = document.activeElement === this.usernameInput || document.activeElement === this.passwordInput

        if (loginInputHasFocus && !isPortraitMode) {
            window.setTimeout(() => {
                document.activeElement?.scrollIntoView()
            }, 510) // scroll after 500ms timeout from `safariHeightFix` in `mobileRoot`
        }
    }
}

let loginOverlay: LoginOverlay | undefined
let mobileLoginOverlay: MobileLoginOverlay | undefined

export function showLoginOverlay(props: ILoginOverlayProps): void {
    if (pageContext.current.isMobile) {
        if (mobileLoginOverlay === undefined) {
            mobileLoginOverlay = new MobileLoginOverlay()
            addPageAction("LoginOverlayOpened")
        }
        mobileLoginOverlay.initAndShow(props)
    } else {
        if (loginOverlay === undefined) {
            loginOverlay = new LoginOverlay()
            addPageAction("LoginOverlayOpened")
        }
        loginOverlay.initAndShow(props)
    }
    recordInteractionIfAffiliatePage()
}

export function hideLoginOverlay(): void {
    if (loginOverlay !== undefined) {
        loginOverlay.hide()
    }
}

export function loginOverlayVisible(): boolean {
    if (loginOverlay !== undefined) {
        return loginOverlay.isVisible
    }
    return false
}
