export function checkDevicePermsGranted() {
    return navigator.mediaDevices.enumerateDevices().then((devices) => {
        let hasMicrophone = false;
        let hasWebcam = false;
        devices.forEach((device) => {
            if (device.label === "") {
                return;
            }
            if (device.kind === "audioinput") {
                hasMicrophone = true;
            }
            if (device.kind === "videoinput") {
                hasWebcam = true;
            }
        });
        return hasMicrophone && hasWebcam;
    });
}
