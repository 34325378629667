// The auth abstraction layer is currently made up of the following interfaces:
//
// - IAuthProvider: Encapsulates the current auth state for all subscribed topics.
//

import { ArgJSONMap } from "@multimediallc/web-utils"
import { postCb } from "../../common/api"
import { SubSystemType } from "../../common/debug"
import { pageContext } from "../interfaces/context"
import { PushService } from "./pushService"

export const AUTH_API_URL = "push_service/auth/"

export interface IAuthProvider {
    serialize(): string

    // Returns a list of keys for all the topics this provider grants access to.
    getTopicKeys(): string[]

    // Determine if the provider currently has access to a particular topic.
    canAccessTopic(topicKey: string): boolean

    invalidateAuth(): void

    updateAuthToken(): Promise<void>

    reportFailedTopics(authContext: ArgJSONMap): void
}

export function createAuthRequest(backend: string, extra = {}): Promise<ArgJSONMap> {
    let isPrimary = PushService.getClientName() === backend
    if (pageContext.current.isNoninteractiveUser) {
        isPrimary = false
    }
    const topicsToAuth = PushService.getTopicKeysToAuth()
    if (topicsToAuth.length > 100) {
        warn("PushService is attempting to request too many topics", {}, SubSystemType.PushService)
        topicsToAuth.length = 100
    }
    const tokenAuthData: Record<string, object> = {}
    topicsToAuth.forEach((topicKey) => {
        const topicData = PushService.getTopicAuthData(topicKey)
        if (topicData === undefined || topicData.subscribeOnlyOnPrimaryBackend && !isPrimary) {
            return
        }
        tokenAuthData[topicData.key] = topicData.payload
    })
    const prefix = isPrimary ? "+" : "-"
    const presenceId = `${prefix}${PushService.presenceId}`
    const postData = {
        "presence_id": presenceId,
        "topics": JSON.stringify(tokenAuthData),
        "backend": backend,
        ...extra,
    }
    return postCb(AUTH_API_URL, postData).then(xhr => {
        try {
            const result = new ArgJSONMap(xhr.responseText)
            return Promise.resolve(result)
        } catch (e) {
            return Promise.reject(e)
        }
    })
}
