import { Component } from "../../../common/defui/component"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { addColorClass } from "../../colorClasses"
import { TransparentCheckbox } from "../toggle"
import type { formProps } from "./appPage"
import type { IApp, IAppListApp, IPermissions, IPermissionsValues } from "../../interfaces/asp"

function isValidPermissionKey(key: string): key is keyof IPermissions {
    return key in AppPermissionsForm.APP_PERMISSIONS
}

export class AppPermissionsForm extends Component<HTMLDivElement, formProps> {
    static readonly EXCLUSIVE_PERMISSIONS = {
        videoPanel: "videoPanel",
        tipOptions: "tipOptions",
    }
    static readonly ALL_PERMISSIONS = {
        ...AppPermissionsForm.EXCLUSIVE_PERMISSIONS,
        rewriteMessages: "rewriteMessages",
    }
    static readonly APP_PERMISSIONS = {
        videoPanel: {
            label: i18n.videoPanelLabel,
            description: i18n.exclusiveAppPermissionText,
            exclusiveText: i18n.aspPermissionOverrideText,
        },
        rewriteMessages: {
            label: i18n.transformMessagesLabel,
            description: "",
            exclusiveText: "",
        },
        tipOptions: {
            label: i18n.tipOptionsLabel,
            description: i18n.exclusiveAppPermissionText,
            exclusiveText: "",
        },
    }

    app: IApp
    onChange: () => void
    submit: () => void
    exclusiveApps: IAppListApp[]
    cbappAppUsed: boolean

    showPermissions: boolean
    exclusivePermissions: Record<string, string>
    permissionsForm: HTMLFormElement
    permissionsStatus?: HTMLHeadingElement

    chevronUp: HTMLDivElement
    chevronDown: HTMLDivElement

    constructor(props: formProps) {
        super("div", props)
    }

    protected initData(props: formProps): void {
        this.app = props.app
        this.onChange = props.onChange
        this.submit = props.submit
        this.exclusiveApps = props.exclusiveApps
        this.cbappAppUsed = props.cbappsAppUsed

        this.showPermissions = false
        this.initExclusivePermissions()
    }

    protected initUI(props: formProps): void {
        this.permissionsForm = this.createPermissionsForm()
        this.permissionsForm.style.display = "none"

        this.element = 
            <div>
                {this.showPermissions && 
                    <div
                        onClick={() => {this.togglePermissionsForm()}}
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            margin: "10px 10px 0",
                        }}
                    >
                        <h3
                            style={{
                                fontSize: "12px",
                                fontFamily: "ubuntumedium",
                                fontWeight: 500,
                                minWidth: "200px",
                                color: "#494949",
                            }}
                        >
                            {i18n.permissions}
                        </h3>
                        <h3
                            ref={(el: HTMLDivElement) => {
                                this.permissionsStatus = el
                            }}
                            style={{ fontSize: "12px" }}
                            colorClass="subText"
                        >
                            {this.getStatusText()}
                        </h3>
                        <div></div>
                        <div></div>
                        <div>
                            <div
                                style={{
                                    margin: "10px 10px 0 0",
                                    width: "15px",
                                    height: "15px",
                                    backgroundSize: "cover",
                                }}
                                ref={(el: HTMLDivElement) => {
                                    this.chevronDown = el
                                }}
                                colorClass="chevronDown"
                            ></div>
                            <div
                                style={{
                                    margin: "10px 10px 0 0",
                                    width: "15px",
                                    height: "15px",
                                    backgroundSize: "cover",
                                    display: "none",
                                }}
                                ref={(el: HTMLDivElement) => {
                                    this.chevronUp = el
                                }}
                                colorClass="chevronUp"
                            ></div>
                        </div>
                    </div>
                }
                <div>{this.permissionsForm}</div>
            </div>
        
        addColorClass(this.element, "appForm")

        if (!this.app.isInstalled && this.showPermissions) {
            this.togglePermissionsForm()
        }
    }

    private togglePermissionsForm(): void {
        if (this.showPermissions) {
            this.permissionsForm.style.display = this.permissionsForm.style.display === "none" ? "block" : "none"
            this.chevronDown.style.display = this.chevronDown.style.display === "none" ? "block" : "none"
            this.chevronUp.style.display = this.chevronUp.style.display === "none" ? "block" : "none"
        }
    }

    private initialValue(name: string, value: boolean): boolean {
        if (!this.app.isInstalled) {
            return true
        }
        if (!isValidPermissionKey(name)) {
            warn(`Invalid permission key: ${name}`)
            return false
        }

        // Check if this is a new permission
        if (this.app.hasUpdate && this.app.installed?.permissions[name].settings !== "ask") {
            const isExclusive = name in AppPermissionsForm.EXCLUSIVE_PERMISSIONS
            if (!isExclusive || this.exclusivePermissions[name] === "") {
                return true
            }
        }
        return value
    }

    private createPermissionsForm(): HTMLFormElement {
        // @ts-ignore values populated
        let permissionsValues: IPermissionsValues = {}
        if (this.app.isInstalled && this.app.permissionsValues !== undefined) {
            permissionsValues = this.app.permissionsValues
        } else {
            for (const permission of Object.keys(AppPermissionsForm.APP_PERMISSIONS)) {
                permissionsValues[permission as keyof IPermissions] = false
            }
        }

        return (
            <form
                onKeyPress={(e) => {
                    if (e.key === "Enter") {
                        e.preventDefault()
                        e.stopPropagation()
                        this.submit()
                    }
                }}
            >
                <ul>
                    {Object.entries(permissionsValues).map(([name, permValue]) => {
                        const appPermissions = this.app.latest.permissions
                        if (!isValidPermissionKey(name)) {
                            return ""
                        }
                        if (appPermissions[name].settings === "ask") {
                            this.showPermissions = true
                            const value = this.initialValue(name, permValue)
                            return this.renderPermission(name, value)
                        }
                        return ""
                    })}
                </ul>
            </form>
        )
    }

    private initExclusivePermissions(): void {
        const exclusives = Object.keys(AppPermissionsForm.EXCLUSIVE_PERMISSIONS) as (keyof IPermissions)[]
        this.exclusivePermissions = {}
        for (const permission of exclusives) {
            this.exclusivePermissions[permission] = ""
        }

        for (const app of this.exclusiveApps) {
            if (this.app.id === app.id) {
                continue
            }
            for (const permission of exclusives) {
                if (Boolean(app.permissionsValues?.[permission])) {
                    this.exclusivePermissions[permission] = app.name
                }
            }
        }
    }

    private getStatusText(): string {
        let numChecked = 0
        const inputs = [...this.permissionsForm.elements]
        inputs.forEach((input: HTMLInputElement) => {
            if (input.checked) {
                numChecked += 1
            }
        })
        const fractionChecked = numChecked / this.permissionsForm.elements.length
        if (fractionChecked === 1) {
            return i18n.allPermissionsText
        } else if (fractionChecked === 0) {
            return i18n.noPermissionsText
        }
        return i18n.somePermissionsText
    }

    private exclusiveAppTextElement(permission: keyof IPermissions): HTMLParagraphElement {
        const exclusiveApp = this.exclusivePermissions?.[permission]
        let text = AppPermissionsForm.APP_PERMISSIONS[permission].exclusiveText
        if (this.cbappAppUsed && text !== "") {
            text = i18n.activeAppOverrideText
        } else if (permission in this.exclusivePermissions && this.exclusivePermissions[permission] !== "") {
            text = i18n.usedByText(exclusiveApp)
        }
        return (
            <p
                style={{
                    width: "450px",
                    padding: "0 0 0 25px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                }}>
                {text}
            </p>
        )
    }

    private checkboxElement(name: string, value: boolean): HTMLDivElement {
        const checkbox = new TransparentCheckbox(16, value, this.onChange)
        checkbox.setName(name)
        checkbox.element.style.minWidth = "16px"
        return checkbox.element
    }

    private renderPermission(name: string, value: boolean, hideExclusiveApps = false): Node | undefined {
        if (!isValidPermissionKey(name)) {
            return
        }

        const permission = AppPermissionsForm.APP_PERMISSIONS[name]
        const checkbox = this.checkboxElement(name, value)

        return (
            <li
                style={{
                    display: "flex",
                    justifyContent: "left",
                    cssFloat: "none",
                    alignItems: "center",
                    borderBottom: "1px solid #E5E5E5",
                    padding: "10px",
                    margin: "0",
                }}
            >
                <div
                    style={{
                        minWidth: "220px",
                        margin: "0 8px 0 0", 
                    }}
                >
                    <p
                        style={{
                            fontSize: "12px",
                            margin: 0,
                            padding: 0,
                            fontFamily: "ubuntumedium",
                            fontWeight: 500,
                        }}
                    >
                        {permission["label"]}
                    </p>
                    <p
                        style={{
                            fontSize: "10px",
                            margin: 0,
                            padding: 0,
                        }}
                        colorClass="subText"
                    >
                        {permission["description"]}
                    </p>
                </div>
                {checkbox}
                {!hideExclusiveApps ? this.exclusiveAppTextElement(name) : ""}
            </li>
        )
    }

    updateStatusText(): void {
        if (this.permissionsStatus === undefined) {
            return
        }
        this.permissionsStatus.innerText = this.getStatusText()
        const inputs = [...this.permissionsForm.elements]
        inputs.forEach((input: HTMLInputElement) => {
            if (!input.checked) {
                return
            }
            const text = input.nextSibling as HTMLParagraphElement
            if (text.innerText !== "") {
                text.innerText = ""
            }
        })
    }

    private formData(): Record<string, boolean> {
        const resultKey = AppPermissionsForm.ALL_PERMISSIONS
        const result: Record<string, boolean> = {}
        for (const permission of Object.values(resultKey)) {
            result[permission] = false
        }

        const formData = new FormData(this.permissionsForm)
        for (const key of formData.keys()) {
            if (key in resultKey) {
                // @ts-ignore using runtime check
                result[resultKey[key]] = true
            }
        }
        return result
    }

    // Collect inputs selected/unselected in modalForm. Ignore the rest of permissions.
    private modalFormData(modalForm: HTMLFormElement): Record<string, boolean> {
        const resultKey = AppPermissionsForm.ALL_PERMISSIONS
        const result = {}
        for (const element of modalForm.elements) {
            const input = element as HTMLInputElement
            if (input.name in resultKey) {
                // @ts-ignore using runtime check
                result[resultKey[input.name]] = input.checked
            }
        }
        return result
    }

    getFormData(modalForm?: HTMLFormElement): Record<string, boolean> {
        let modalFormData = {}
        if (modalForm !== undefined) {
            modalFormData = this.modalFormData(modalForm)
        }
        const formData = this.formData()
        return {
            ...formData,
            ...modalFormData,
        }
    }

    private getSelectedExclusiveConflicts(): (keyof IPermissions)[] {
        const selected = new FormData(this.permissionsForm).keys()
        const selectedExclusives: (keyof IPermissions)[] = []
        for (const name of selected) {
            if (name in this.exclusivePermissions && this.exclusivePermissions[name] !== "") {
                selectedExclusives.push(name as keyof IPermissions)
            }
        }
        return selectedExclusives
    }

    shouldConfirmModal(): boolean {
        return this.getSelectedExclusiveConflicts().length > 0
    }

    confirmModalForm(): HTMLFormElement {
        const exclusiveConflicts = this.getSelectedExclusiveConflicts()
        return (
            <form>
                <ul>
                    {exclusiveConflicts.map((name, index) => {
                        const permissionItem = this.renderPermission(name, true, true)
                        if (permissionItem !== undefined && exclusiveConflicts.length === index + 1) {
                            (permissionItem as HTMLLIElement).style.borderBottom = "none"
                        }
                        return permissionItem
                    })}
                </ul>
            </form>)
    }

    render(): HTMLDivElement {
        return this.element
    }
}
