import { debug, error, warn } from "@multimediallc/logging";
// ArgJSONMap will report if you forget to parse something if you call `logUnusedDebugging`.
// `logUnusedDebugging` doesn't output anything if compiled with PRODUCTION=true
export class ArgJSONMap {
    constructor(inputData) {
        this.parsed = {};
        this.usedFields = {};
        this.valid = true;
        if (typeof inputData === "string") {
            this.stringMessage = inputData;
            try {
                this.parsed = JSON.parse(inputData);
            }
            catch (e) {
                this.parsed = {};
                this.valid = false;
                error(`Cannot JSON parse`, { message: inputData });
            }
        }
        else if (typeof inputData === "object") {
            this.parsed = inputData;
            this.stringMessage = JSON.stringify(inputData);
        }
        else {
            // Not... actually sure this can be hit, assuming we type check, since input being not a string
            // or an object at compile time will fail.
            this.parsed = {};
            this.valid = false;
            this.stringMessage = JSON.stringify(this.parsed);
            error(`Invalid input type`, { message: inputData });
        }
    }
    keys() {
        return Object.keys(this.parsed);
    }
    unusedFields() {
        const fields = [];
        for (const key in this.parsed) {
            if (this.usedFields[key] !== true) {
                fields.push(key);
            }
        }
        return fields;
    }
    ignore(key) {
        this.usedFields[key] = true;
    }
    getParsed() {
        // spread to make copy and cant mutate original
        return Object.assign({}, this.parsed);
    }
    getAny(key) {
        this.ignore(key);
        if (this.parsed[key] === null) {
            return undefined;
        }
        return this.parsed[key];
    }
    // Note: This method is not type safe, but we can't know the type of the array
    getList(key, reportError = true) {
        this.ignore(key);
        if (!Array.isArray(this.parsed[key])) {
            return undefined;
        }
        // Note: This will error if the array contains anything other than a string or object
        return this.parsed[key].map((item) => {
            if (reportError &&
                typeof item !== "object" &&
                typeof item !== "string") {
                warn(`getList(${key}): called on Array with invalid items`, {
                    message: this.stringMessage,
                    item: item,
                });
            }
            return new ArgJSONMap(item);
        });
    }
    // Note: This method is not type safe, but we can't know the type of the array
    getStringList(key, reportError = true) {
        this.ignore(key);
        if (!Array.isArray(this.parsed[key])) {
            return [];
        }
        return this.parsed[key].map((item) => {
            if (reportError && typeof item !== "string") {
                warn(`getStringList(${key}): called on Array with invalid items`, {
                    message: this.stringMessage,
                    item: item,
                });
            }
            return item;
        });
    }
    getStringListOrUndefined(key, reportError = true) {
        this.ignore(key);
        if (!Array.isArray(this.parsed[key])) {
            return undefined;
        }
        return this.getStringList(key, reportError);
    }
    getAsString(key) {
        // eslint-disable-next-line
        return `${this.getAny(key)}`;
    }
    getStringOrUndefined(key, reportError = true) {
        const x = this.getAny(key);
        if (typeof x !== "string") {
            if (reportError && x !== undefined) {
                warn(`getStringOrUndefined(${key}): is wrong type`, {
                    message: this.stringMessage,
                    item: x,
                });
            }
            return undefined;
        }
        return x;
    }
    getString(key, reportError = true) {
        const x = this.getAny(key);
        if (typeof x !== "string") {
            if (reportError) {
                warn(`getString(${key}): is wrong type`, {
                    message: this.stringMessage,
                    item: x,
                });
            }
            return "";
        }
        return x;
    }
    getStringWithNumbers(key, reportError = true) {
        const x = this.getAny(key);
        if (typeof x !== "string" && typeof x !== "number") {
            if (reportError) {
                warn(`getStringWithNumbers(${key}): is wrong type`, {
                    message: this.stringMessage,
                    item: x,
                });
            }
            return "";
        }
        return `${x}`;
    }
    getStringWithNumbersOrBoolean(key, reportError = true) {
        const x = this.getAny(key);
        if (typeof x !== "string" &&
            typeof x !== "number" &&
            typeof x !== "boolean") {
            if (reportError) {
                warn(`getStringWithNumbersOrBoolean(${key}): is wrong type`, {
                    message: this.stringMessage,
                    item: x,
                });
            }
            return "";
        }
        if (typeof x === "boolean") {
            return x;
        }
        return `${x}`;
    }
    getStrings(keys) {
        const foundKeys = new Map();
        for (const key of keys) {
            if (this.getStringOrUndefined(key, false) !== undefined) {
                foundKeys.set(key, this.getStringOrUndefined(key, false));
            }
        }
        return foundKeys;
    }
    getBoolean(key, defaultValue = false, reportError = true) {
        const x = this.getAny(key);
        if (typeof x !== "boolean") {
            if (reportError) {
                warn(`getBoolean(${key}): is wrong type`, {
                    message: this.stringMessage,
                    item: x,
                });
            }
            return defaultValue;
        }
        return x;
    }
    getBooleanOrUndefined(key, reportError = true) {
        const x = this.getAny(key);
        if (typeof x !== "boolean") {
            if (reportError && x !== undefined) {
                warn(`getBoolean(${key}): is wrong type`, {
                    message: this.stringMessage,
                    item: x,
                });
            }
            return undefined;
        }
        return x;
    }
    getNumber(key, reportError = true) {
        const x = this.getAny(key);
        if (typeof x !== "number") {
            if (reportError) {
                warn(`getNumber(${key}): is wrong type`, {
                    message: this.stringMessage,
                    item: x,
                });
            }
            return NaN;
        }
        return x;
    }
    getNumberOrUndefined(key, reportError = true) {
        const x = this.getAny(key);
        if (typeof x !== "number") {
            if (reportError && x !== undefined) {
                warn(`getNumberOrUndefined(${key}): is wrong type`, {
                    message: this.stringMessage,
                    item: x,
                });
            }
            return undefined;
        }
        return x;
    }
    // Unlike getMap, don't serialize and re-parse the object,
    // instead create a new map with the parsed sub object
    getParsedSubMap(key) {
        return new ArgJSONMap(this.getObject(key));
    }
    // Same as getParsedSubMap, but for potentially undefined nested objects
    getParsedSubMapOrUndefined(key, reportError = true) {
        const nestedObject = this.getObjectOrUndefined(key, reportError);
        if (nestedObject !== undefined) {
            return new ArgJSONMap(nestedObject);
        }
        return undefined;
    }
    getMap(key, reportError = true) {
        const x = this.getObjectString(key, reportError);
        if (x === "") {
            return new ArgJSONMap("{}");
        }
        return new ArgJSONMap(x);
    }
    getObjectString(key, reportError = true) {
        const x = this.getAny(key);
        if (typeof x !== "object") {
            if (reportError) {
                warn(`getObjectString(${key}): is wrong type`, {
                    message: this.stringMessage,
                    item: x,
                });
            }
            return "";
        }
        return JSON.stringify(x);
    }
    getObjectStringOrUndefined(key, reportError = true) {
        const x = this.getAny(key);
        if (typeof x !== "object") {
            if (reportError && x !== undefined) {
                warn(`getObjectStringOrUndefined(${key}): is wrong type`, {
                    message: this.stringMessage,
                    item: x,
                });
            }
            return undefined;
        }
        return JSON.stringify(x);
    }
    getObject(key, reportError = true) {
        const x = this.getAny(key);
        if (typeof x !== "object") {
            if (reportError) {
                warn(`getObject(${key}): is wrong type`, {
                    message: this.stringMessage,
                    item: x,
                });
            }
            return {};
        }
        return x;
    }
    getObjectOrUndefined(key, reportError = true) {
        const x = this.getAny(key);
        if (typeof x !== "object") {
            if (reportError && x !== undefined) {
                warn(`getObjectOrUndefined(${key}): is wrong type`, {
                    message: this.stringMessage,
                    item: x,
                });
            }
            return undefined;
        }
        return x;
    }
    logUnusedDebugging(moduleName) {
        for (const key of this.unusedFields()) {
            const value = typeof this.parsed[key] === "object"
                ? JSON.stringify(this.parsed[key])
                : this.parsed[key];
            debug(
            // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-base-to-string
            `Unhandled message argument: . Key:${key} Value:${value} in ${moduleName}`);
        }
    }
}
