/**
 * Returns whether given feature flag is active
 */
export function featureFlagIsActive(name: string): boolean {
    const flagRegExp = new RegExp(`\\b${name}\\b`)
    return flagRegExp.test(window["active_feature_flags"])
}

/**
 * Returns whether given PerformToggle is active
 */
export function performToggleIsActive(name: string): boolean {
    const toggleRegExp = new RegExp(`(?:^|,)${name}(?:$|,)`)
    return toggleRegExp.test(window["active_perform_toggles"])
}
