import { ArgJSONMap } from "@multimediallc/web-utils"
import { isiOS } from "@multimediallc/web-utils/modernizr"
import { GameSelection } from "../../cb/components/games/gameSelection"
import { roomDossierContext } from "../../cb/interfaces/context"
import { PushService } from "../../cb/pushservicelib/pushService"
import { SubSystemType } from "../../common/debug"
import { addEventListenerPoly, removeEventListenerPoly } from "../addEventListenerPolyfill"
import { getCb } from "../api"
import { featureFlagIsActive } from "../featureFlag"
import { addPageAction } from "../newrelic"
import { ReconnectLimiter } from "../reconnectlimiter"
import { getRoomDossier } from "../roomDossier"
import { RoomStatus } from "../roomStatus"
import { i18n } from "../translation"
import {
    handleAppDebugError,
    handleAwayModeCancel,
    handleHiddenApprove,
    handleHiddenShowStatus,
    handleKick,
    handlePasswordChanged,
    handlePersonallyKicked,
    handlePrivateShowApprove,
    handlePrivateShowCancel,
    handlePrivateShowRequest,
    handlePromotion,
    handlePurchaseNotification,
    handleRevoke,
    handleRoomMessage,
    handleSettingsUpdate,
    handleSilence,
    handleTipAlert,
    handleTitleChange,
    openComplianceModal,
} from "./messagehandler"
import {
    parseSettingsUpdate,
    parseShortcodes,
    parseTipAlert,
    parseUserInfo,
    parseWowzaRoomMessage,
} from "./messageParsers"
import { stringPart } from "./roomnoticeparts"
import type { ChatConnection } from "./chatConnection"
import type {
    IPurchase,
    IRoomNotice,
    IRoomNoticePart,
} from "../messageInterfaces"
import type { IRoomDossier } from "../roomDossier"

interface IControlMessage {
    method: string
    callback: number
    args: string[]
    tid?: string,
}

export class WowzaHandler {
    private _websocket?: WebSocket = undefined
    private reconnectLimiter: ReconnectLimiter
    private handlePagehide?: () => void

    constructor(private roomDossier: IRoomDossier, public chatConn: ChatConnection) {
        this.reconnectLimiter = new ReconnectLimiter()
        this.remakeWebsocket()

        if (isiOS()) {
            // iOS back/forward cache does not close the websocket connection
            // when the user navigates away, so do it manually as to not prevent errors
            // when a user enters a room through the back button
            this.handlePagehide = () => {this.disconnect()}
            addEventListenerPoly("pagehide", window, this.handlePagehide)
        }
    }

    private remakeWebsocket(): void {
        return
    }

    public readyState(): string {
        if (this._websocket !== undefined) {
            switch (this._websocket.readyState) {
                case WebSocket.CLOSED:
                    return "closed"
                case WebSocket.OPEN:
                    return "open"
                case WebSocket.CLOSING:
                    return "closing"
                case WebSocket.CONNECTING:
                    return "connecting"
                default:
                    return "unknown"
            }
        } else {
            return "websocket undefined"
        }
    }

    // when push service gets switched off, make sure wowza is connected
    // properly in case user was disconnected while push was handling
    public ensureConnected(): void {
        if (this._websocket !== undefined && this.websocket().readyState === WebSocket.CONNECTING) {
            return
        }
        if (!this.isWebsocketReady()) {
            debug("restoring websocket")
            this._websocket?.close()
            this.remakeWebsocket()
        }
    }

    // websocket is a getter that prevents the need to cast the _websocket every time we use it.
    private websocket(): WebSocket {
        return this._websocket as WebSocket
    }

    private isWebsocketReady(): boolean {
        return this._websocket !== undefined && this._websocket.readyState === WebSocket.OPEN
    }

    public setConnectedAndAuthed(): void {
        this.reconnectLimiter.reset()
        if (shouldHandleMessage({ method: "onTitleChange", callback: 0, args: [] })) {
            handleTitleChange(this.chatConn, this.roomDossier.roomTitle)
        }
    }

    public disconnect(): void {
        if (this._websocket !== undefined) {
            this._websocket.onclose = () => {}
            this._websocket.onerror = () => {}
            this._websocket.close()
            this._websocket = undefined
        }

        if (this.handlePagehide !== undefined) {
            removeEventListenerPoly("pagehide", window, this.handlePagehide)
        }
    }

    private sendRawMessage(message: string): void {
        debug(["sending ", message])
        this.websocket().send(message)
    }

    public sendMessage(method: string, data: object = {}): void {
        return
    }

    private retryMessage(method: string, data: object, retryCount: number): void {
        // make sure isAuthed so we sent a joinRoom message first
        if (!this.isWebsocketReady()) {
            if (retryCount < 5) {
                window.setTimeout(() => {
                    this.retryMessage(method, data, retryCount + 1)
                }, 1000)
            } else {
                addPageAction("WowzaMsgRetryFailed", {
                    "method": method,
                    "push_active": PushService.isEnabledForUI(),
                })
            }
            return
        }
        this.sendRawMessage(JSON.stringify({
            "method": method,
            "data": data,
        }))
    }
}


export function parseIncomingMessage(message: string): IControlMessage {
    const jsonData = JSON.parse(message)
    return {
        method: jsonData["method"],
        callback: jsonData["callback"],
        args: jsonData["args"],
    }
}

export class WowzaMessageHandler {
    constructor(public conn: ChatConnection) {
    }

    receive(unparsedMessage: string): void {
        const message = parseIncomingMessage(unparsedMessage)
        if (Object.keys(methodMap).indexOf(message.method) >= 0) {
            if (shouldHandleMessage(message)) {
                methodMap[message.method as keyof typeof methodMap](this.conn, message)
            } else if (PushService.isEnabledForUI() && message.method === "onPersonallyKicked" && message.args[0] ==="rejoined") {
                // when personally kicked (rejoined), disconnect from wowza for multiple tab support
                this.conn.wowzaHandler.disconnect()
            }
        } else {
            error(`Unknown message method: ${message.method} args: ${unparsedMessage}`)
        }
    }
}

function shouldHandleMessage(message: IControlMessage): boolean {
    // wowzaMethods are overrides from activeMessageHandler - will always use wowza for these methods
    // onAuthResponse - needed to auth with wowza and join room
    const wowzaMethods = ["onAuthResponse", "onPrivateMsg"]
    if (wowzaMethods.indexOf(message.method) !== -1) {
        return true
    }
    // also will always handle push_backends message in case of failover
    if (message.method === "onNotify") {
        const p = new ArgJSONMap(message.args[0])
        const argType = p.getString("type")
        if (argType === "push_backends") {
            return true
        }
    }
    return !PushService.isEnabledForUI()
}

const methodMap = {
    "onAuthResponse": parseAndHandleAuthResponse,
    "onRoomCountUpdate": parseAndHandleRoomCountUpdate,
    "onRoomMsg": parseAndHandleRoomMessage,
    "onNotify": parseAndHandleNotify,
    "onTitleChange": parseAndHandleTitleChange,
    "onPrivateMsg": parseAndHandlePrivateMessage,
    "onPromotion": parseAndHandlePromotion,
    "onRevoke": parseAndHandleRevoke,
    "onPersonallyKicked": parseAndHandlePersonallyKicked,
    "onSilence": parseAndHandleSilence,
    "onKick": parseAndHandleKick,
    "onNotifyPrivateShowRequest": parseAndHandlePrivateShowRequest,
    "onNotifyPrivateShowApprove": parseAndHandlePrivateShowApprove,
    "onNotifyPrivateShowCancel": parseAndHandlePrivateShowCancel,
    "onNotifyLeavePrivateRoom": parseAndHandleLeavePrivateRoom,
    "onNotifyTokenBalanceUpdate": parseAndHandleTokenBalanceUpdate,
    "onNotifyAwayModeCancel": parseAndHandleAwayModeCancel,
}

function parseAndHandleRoomCountUpdate(conn: ChatConnection, message: IControlMessage): void {
    if (message.args.length < 1) {
        error(`Invalid onRoomCountUpdate args: ${message.args.toString()}`)
    } else if (message.args[0] !== "" && !PushService.isEnabledForUserList()) {
        conn.event.roomCountUpdate.fire(parseInt(message.args[0]))
    }
}

// eslint-disable-next-line complexity
function parseAndHandleAuthResponse(conn: ChatConnection, message: IControlMessage): void {
    if (1 <= message.args.length && "1" === message.args[0]) {
        let nextStatus: RoomStatus
        nextStatus = conn.statusAfterConnected
        if (conn.status !== RoomStatus.NotConnected) {
            nextStatus = conn.status
        }
        switch (nextStatus) {
            case RoomStatus.Offline:
            case RoomStatus.Away:
            case RoomStatus.PrivateRequesting:
            case RoomStatus.PrivateNotWatching:
            case RoomStatus.PrivateSpying:
            case RoomStatus.Hidden:
            case RoomStatus.HiddenWatching:
            case RoomStatus.Public:
                conn.joinRoom()
                conn.changeStatus(nextStatus)
                break
            case RoomStatus.PrivateWatching:
                if (conn.status === RoomStatus.NotConnected) {
                    conn.joinPrivateRoom()
                    conn.changeStatus(nextStatus)
                } else {
                    getRoomDossier(conn.room()).then((roomDossier) => {
                        // broadcaster always gets put into private room
                        if (roomDossier.roomStatus === RoomStatus.PrivateNotWatching && conn.isBroadcasting) {
                            roomDossier.roomStatus = RoomStatus.PrivateWatching
                        }
                        if (roomDossier.roomStatus === RoomStatus.PrivateWatching) {
                            conn.joinPrivateRoom()
                        } else {
                            conn.joinRoom()
                        }
                        conn.changeStatus(roomDossier.roomStatus)
                    }).catch((e) => {
                        error(`Error getting room dossier on reconnect: ${e}`, { "room": conn.room() })
                    })
                }
                break
            default:
                warn(`unexpected status: ${nextStatus}`)
        }
        conn.wowzaHandler.setConnectedAndAuthed()
    } else {
        if (message.args.length > 0 && message.args[0] === "0") {
            return
        }
        error("Error connecting!", { "message": message })
    }
}

function parseAndHandleTitleChange(conn: ChatConnection, message: IControlMessage, approved_tags?: string[]): void {
    if (message.args.length < 1) {
        error(`Invalid onTitleChange args: ${message.args.toString()}`)
        return
    }
    if (message.args.length > 1 && message.args[1] === "0") {
        return
    }
    handleTitleChange(conn, message.args[0], approved_tags)
}

function parseAndHandlePrivateMessage(conn: ChatConnection, message: IControlMessage): void {
    return
}

function parseAndHandlePromotion(conn: ChatConnection, message: IControlMessage): void {
    if (message.args.length !== 2) {
        error(`Invalid onPromotion args: ${message.args.toString()}`)
    } else {
        const event = {
            username: message.args[0],
            fromUser: message.args[1],
        }
        handlePromotion(conn, event)
    }
}

function parseAndHandleRevoke(conn: ChatConnection, message: IControlMessage): void {
    if (message.args.length !== 2) {
        error(`Invalid onRevoke args: ${message.args.toString()}`)
    } else {
        const event = {
            username: message.args[0],
            fromUser: message.args[1],
        }
        handleRevoke(conn, event)
    }
}

function parseAndHandlePersonallyKicked(conn: ChatConnection, message: IControlMessage): void {
    if (message.args.length !== 1) {
        error(`Invalid onPersonallyKicked args: ${message.args.toString()}`)
    } else {
        const reason = message.args[0]
        let kickMessage: string
        if (reason === "rejoined") {
            kickMessage = i18n.rejoinedRoomKickedMessage
            // remove pvt id so user rejoins public channels - fixes user counts in private
            roomDossierContext.setState({ privateShowId: "" })
        } else if (reason === "kicked") {
            kickMessage = i18n.kickedFromRoomMessage
        } else {
            warn(`createPersonallyKickedMessage called with unknown argument ${reason}`)
            kickMessage = i18n.kickedMessage
        }
        handlePersonallyKicked(conn, kickMessage)
    }
}

function parseAndHandleSilence(conn: ChatConnection, message: IControlMessage): void {
    if (message.args.length !== 2) {
        error(`Invalid onSilence args: ${message.args.toString()}`)
    } else {
        const event = {
            username: message.args[0],
            fromUser: message.args[1],
        }
        handleSilence(conn, event)
    }
}

function parseAndHandleKick(conn: ChatConnection, message: IControlMessage): void {
    if (message.args.length !== 1) {
        error(`Invalid onKick args: ${message.args.toString()}`)
    } else {
        const event = {
            username: message.args[0],
            fromUser: conn.room(),
        }
        handleKick(conn, event)
    }
}

export function parseAndHandleRoomMessage(conn: ChatConnection, message: IControlMessage): void {
    if (message.args[0] === "") {
        if (message.args.length > 1) {
            if (message.args[1] === "Chat disconnected. The broadcaster has set a new password on this room.") {
                handlePasswordChanged(conn)
            } else {
                conn.event.roomNotice.fire({ messages: [[stringPart(message.args[1])]], showInPrivateMessage: true })
            }
        } else {
            error(`roomMessage invalid args: ${message.args.toString()}`)
        }
        return
    }
    const p = new ArgJSONMap(message.args[1])
    if (p.getAny("X-Successful") !== true) {
        error("X-Successful not true?")
    }
    const parsedRoomMessage = {
        ...parseWowzaRoomMessage(message.args[0], p),
        isSpam: p.getAny("X-Spam") === true,
    }
    handleRoomMessage(conn, parsedRoomMessage)
}

function parseAndHandlePrivateShowRequest(conn: ChatConnection, message: IControlMessage): void {
    if (message.args.length < 2) {
        error(`PrivateShowRequest invalid args: ${message.args.toString()}`)
        return
    }
    const event = {
        userRequesting: message.args[0],
        tokensPerMinute: parseInt(message.args[1]),
        delayFiringEvent: false,
    }
    handlePrivateShowRequest(conn, event)
}

function parseAndHandlePrivateShowApprove(conn: ChatConnection, message: IControlMessage): void {
    if (message.args.length !== 1) {
        error(`Invalid privateShowApprove args: ${message.args.toString()}`)
        return
    }
    handlePrivateShowApprove(conn)
}

function parseAndHandlePrivateShowCancel(conn: ChatConnection, message: IControlMessage): void {
    if (message.args.length !== 0) {
        error(`Invalid privateShowCancel args: ${message.args.toString()}`)
    }
    handlePrivateShowCancel(conn)
}

function parseAndHandleLeavePrivateRoom(conn: ChatConnection, message: IControlMessage): void {
    // Pass. Was only used for group shows. Remove once onNotifyLeavePrivateRoom is removed from chaturbate_wowza
}

function parseAndHandleTokenBalanceUpdate(conn: ChatConnection, message: IControlMessage): void {
    if (message.args.length !== 2) {
        error(`Invalid tokenBalanceUpdate args: ${message.args.toString()}`)
    } else {
        conn.event.tokenBalanceUpdate.fire({ tokens: Number(message.args[1]) })
    }
}

function parseAndHandleAwayModeCancel(conn: ChatConnection, message: IControlMessage): void {
    if (message.args.length !== 0) {
        warn(`Invalid awayModeCancel args: ${message.args.toString()}`)
    }
    handleAwayModeCancel(conn)
}

interface IHandleNotifyArgs {
    conn: ChatConnection
    p: ArgJSONMap
    fromHistory?: boolean
}

function parseAndHandleNotify(conn: ChatConnection, message: IControlMessage): void {
    const p = new ArgJSONMap(message.args[0])
    const argType = p.getString("type")
    p.ignore("send_to")
    p.ignore("to_user")

    // we need to use casting here, but we control the handlermap so it's OK
    const uncastHandler = handlerMap[argType as keyof typeof handlerMap]
    if (typeof uncastHandler !== "function") {
        error(`Unhandled onNotify type ${argType}`, { "message": message })
        return
    } else {
        const handler = handlerMap[argType as keyof typeof handlerMap] as (args: IHandleNotifyArgs) => void
        handler({
            conn: conn,
            p: p,
            fromHistory: message.args.length > 1 && message.args[1] === "true",
        })
    }

    if (!PRODUCTION) {
        p.logUnusedDebugging(JSON.stringify(message))
    }
}

const handlerMap = {
    "tip_alert": parseAndHandleTipAlert,
    "log": parseAndHandleLog,
    "refresh_panel": parseAndHandleRefreshPanel,
    "app_tab_refresh": parseAndHandleAppTabRefresh,
    "appnotice": parseAndHandleAppNotice,
    "apperrorlog": parseAndHandleAppErrorLog,
    "clear_app": parseAndHandleClearApp,
    "room_entry": parseAndHandleRoomEntry,
    "room_leave": parseAndHandleRoomLeave,
    "spy_room_leave": parseAndHandleSpyRoomLeave,
    "purchase_notification": parseAndHandlePurchaseNotification,
    "settingsupdate": parseAndHandleSettingsUpdate,
    "hidden_show_status_change": parseAndHandleHiddenShowStatusChange,
    "hidden_show_approve": parseAndHandleHiddenShowApprove,
    "hidden_show_deny": parseAndHandleHiddenShowDeny,
    "is_restricted_hls_allowed": parseAndHandleIsRestrictedHlsAllowed,
    "compliance_image_required": parseAndHandleComplianceImageRequired,
    "game_selection": parseAndHandleGameSelection,
    "push_backends": parseAndHandleBackendsChange,
}

// When an anonymous tip is received, it's displayed in one of two ways:
//  - Public Chat View: "a user tipped # tokens anonymously"
//  - Broadcaster Chat View: "${username} tipped # tokens anonymously"
function parseAndHandleTipAlert(args: IHandleNotifyArgs): void {
    const parsed = parseTipAlert(args.p)
    handleTipAlert(args.conn, parsed, args.fromHistory)
    if (args.conn.isBroadcasting) {
        getCb("tipping/get_token_balance/").then((xhr) => {
            const p = new ArgJSONMap(xhr.responseText)
            if (p.getBoolean("success")) {
                args.conn.event.tokenBalanceUpdate.fire({ tokens: p.getNumber("token_balance") })
            }
        }).catch(() => {})
    }
}

function parseAndHandleLog(args: IHandleNotifyArgs): void {
    if (!args.conn.isBroadcasting) {
        return
    }
    const msg = args.p.getStringOrUndefined("msg")
    if (msg === undefined) {
        error("handleLog with an empty msg")
        return
    }
    const event = {
        debugMessage: msg,
        type: args.p.getString("type"),
        tid: args.p.getString("tid"),
    }
    handleAppDebugError(args.conn, event)
}

function parseAndHandleAppTabRefresh(args: IHandleNotifyArgs): void {
    args.conn.event.appTabRefresh.fire(undefined)
}

function parseAndHandleRefreshPanel(args: IHandleNotifyArgs): void {
    args.conn.event.refreshPanel.fire(undefined)
}

function parseAndHandleAppNotice(args: IHandleNotifyArgs): void {
    const msgUncast = args.p.getAny("msg")
    const messages: IRoomNoticePart[][] = []
    if (typeof msgUncast === "string") {
        messages.push([stringPart(`Notice: ${msgUncast}`)])
    } else if (msgUncast instanceof Array) {
        for (const msg of msgUncast as string[]) {
            messages.push([stringPart(`Notice: ${msg}`)])
        }
    } else {
        error("handleAppNotice error: Invalid message type", { data: msgUncast })
        return
    }

    function getNoticeColors() {
        const darkModeEnabled = document.body.classList.contains("darkmode")
        const useDarkModeOpts = featureFlagIsActive("SendNoticeDarkModeOpts") && darkModeEnabled
        let foreground = args.p.getStringOrUndefined("foreground")
        let background = args.p.getStringOrUndefined("background")
        if (useDarkModeOpts) {
            const darkmode_foreground = args.p.getStringOrUndefined("darkmode_foreground")
            const darkmode_background = args.p.getStringOrUndefined("darkmode_background")
            if (darkmode_foreground !== undefined && darkmode_foreground !== "") {
                foreground = darkmode_foreground
            }
            if (darkmode_background !== undefined && darkmode_background !== "") {
                background = darkmode_background
            }
        }
        return { foreground, background }
    }

    const { foreground, background } = getNoticeColors()

    const shortcodes = args.p.getList("shortcodes") ?? []
    const roomNotice: IRoomNotice = {
        messages: messages,
        toGroup: args.p.getStringOrUndefined("to_group"),
        toUser: args.p.getStringOrUndefined("to_user"),
        background,
        foreground,
        weight: args.p.getStringOrUndefined("weight", false),
        showInPrivateMessage: false,
        shortcodes: parseShortcodes(shortcodes),
        tid: args.p.getString("tid"),
    }
    args.conn.event.roomNotice.fire(roomNotice)
}

function parseAndHandleAppErrorLog(args: IHandleNotifyArgs): void {
    if (!args.conn.isBroadcasting) {
        return
    }
    const msgUncast = args.p.getAny("msg")
    const messages: IRoomNoticePart[][] = []
    messages.push([stringPart("App Error: ")])
    if (typeof msgUncast === "string") {
        messages.push([stringPart(msgUncast)])
    } else if (msgUncast instanceof Array) {
        for (const msg of msgUncast as string[]) {
            messages.push([stringPart(msg)])
        }
    } else {
        error("handleAppErrorLog error: Invalid message type", { data: msgUncast })
        return
    }
    const event = {
        errorMessages: messages,
        type: args.p.getString("type"),
        tid: args.p.getString("tid"),
    }
    handleAppDebugError(args.conn, event)
}

function parseAndHandleClearApp(args: IHandleNotifyArgs): void {
    args.conn.event.refreshPanel.fire(undefined)
}

function parseAndHandleRoomEntry(args: IHandleNotifyArgs): void {
    args.conn.roomEntry(parseUserInfo(args.p))
}

function parseAndHandleRoomLeave(args: IHandleNotifyArgs): void {
    args.conn.roomLeave(parseUserInfo(args.p))
}

function parseAndHandleSpyRoomLeave(args: IHandleNotifyArgs): void {
    if (args.conn.status === RoomStatus.PrivateSpying) {
        args.conn.changeStatus(RoomStatus.PrivateNotWatching)
    }
}

function parseAndHandlePurchaseNotification(args: IHandleNotifyArgs): void {
    const fromUser = parseUserInfo(args.p)
    if (args.p.getBoolean("history") !== true) {
        warn("purchase history is not true?")
    }
    const event: IPurchase = {
        fromUser: fromUser,
        message: args.p.getString("message"),
        tid: args.p.getString("tid"),
    }
    handlePurchaseNotification(args.conn, event)
    if (event.fromUser.username === args.conn.username()) {
        getCb("tipping/get_token_balance/").then((xhr) => {
            const p = new ArgJSONMap(xhr.responseText)
            if (p.getBoolean("success")) {
                args.conn.event.tokenBalanceUpdate.fire({ tokens: p.getNumber("token_balance") })
            }
        }).catch(() => {})
    }
}

function parseAndHandleSettingsUpdate(args: IHandleNotifyArgs): void {
    const newSettings = parseSettingsUpdate(args.p)
    handleSettingsUpdate(args.conn, newSettings)
}

function parseAndHandleHiddenShowStatusChange(args: IHandleNotifyArgs): void {
    if (args.conn.isBroadcasting) {
        return
    }
    const roomStatus = args.p.getBoolean("is_starting") ? RoomStatus.Hidden : RoomStatus.Public
    const hiddenMessage = args.p.getString("hidden_message")
    args.p.logUnusedDebugging("handleHiddenShowStatusChange")
    handleHiddenShowStatus(args.conn, roomStatus, hiddenMessage)
}

function parseAndHandleHiddenShowApprove(args: IHandleNotifyArgs): void {
    handleHiddenApprove(args.conn, args.p.getBoolean("initial_hide_cam"))
}

function parseAndHandleHiddenShowDeny(args: IHandleNotifyArgs): void {
    if (args.conn.status === RoomStatus.Public || args.conn.status === RoomStatus.HiddenWatching) {
        args.conn.changeStatus(RoomStatus.Hidden)
    }
}

function parseAndHandleIsRestrictedHlsAllowed(args: IHandleNotifyArgs): void {
    // TODO
}

export function argsSuccessful(message: IControlMessage): boolean {
    return message.args.length >= 1 && message.args[0] === "1"
}

function parseAndHandleComplianceImageRequired(args: IHandleNotifyArgs): void {
    let message = `Compliance Image Required!\nPlease visit ${window.location.host}/verify on your mobile
     device to verify your identity.\n\nThis is required for broadcasters not showing their face.`
    const time = args.p.getNumberOrUndefined("time")
    if (time !== undefined && time > 0) {
        message += `\n\nYou have ${time} minute${time === 1 ? "" : "s"} to complete.`
    } else {
        message += "\n\nYou must complete this to continue broadcasting, failure to do so may result in broadcasting restrictions."
    }
    openComplianceModal(message)
}

function parseAndHandleGameSelection(args: IHandleNotifyArgs): void {
    GameSelection.selectionChange.fire(GameSelection.parseSelection(args.p.getObjectStringOrUndefined("game")))
}

function parseAndHandleBackendsChange(args: IHandleNotifyArgs): void {
    const backends = args.p.getStringList("backends")
    if (backends === undefined) {
        warn("backend change sent with invalid args", { "keys": args.p.keys() }, SubSystemType.PushService)
        return
    }
    PushService.changeChatHandler(backends)
    args.conn.wowzaHandler.ensureConnected()
}
