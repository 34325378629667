import { EventRouter } from "../events"
import type { ConversationType } from "../../cb/api/pm"

export const enum PlayerSizeChangeType {
    Resize,
    Reposition,
}

export interface IPlayerChangeSize {changeType: PlayerSizeChangeType}

export interface IRepositionChatContents {
    isInputFocused: boolean
    playerTop?: number
}

export const mobileVideoControlsTap = new EventRouter<undefined>("MobileVideoControls:tap")
export const mobilePureChatTap = new EventRouter<boolean>("MobilePureChat:tap")
export const mobilePureChatDrag = new EventRouter<undefined>("MobilePureChat:drag")
export const mobilePureChatDragEnd = new EventRouter<undefined>("MobilePureChat:dragEnd")
export const mobileFullscreenSendChat = new EventRouter<undefined>("MobileFullscreenSendChat")
export const playerChangeSize = new EventRouter<IPlayerChangeSize>("MobilePlayer:changeSize")
export const mobileConversationLinkFullscreenToggle = new EventRouter<ConversationType>("mobileConversationLinkFullscreenToggle")
export const repositionChatContentsOnInputFocus = new EventRouter<IRepositionChatContents>("repositionChatContentsOnInputFocus")
