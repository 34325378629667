import { getBrowserAndPlatformInfo, iOSVersion, isEdgeMS, isiOS, isPuffin } from "@multimediallc/web-utils/modernizr"
import { getSmcSharedWith } from "../../cb/components/showMyCam/smcViewer"
import { getCb, postCb } from "../../common/api"
import { stopTracksWithTimeout } from "../../common/broadcastlib/mediaDevices"
import { addPageAction } from "../../common/newrelic"
import { uuidv4 } from "../../common/uuid"
import { modalAlert } from "../alerts"
import { EventRouter } from "../events"
import { featureFlagIsActive } from "../featureFlag"
import { tryFullScreen } from "../mobilebroadcastlib/orientation"
import { reportBroadcasterBitrate } from "../player/utils"
import { i18n } from "../translation"
import { showOBSOverlay } from "./OBSOverlay"
import { SDPLib } from "./sdpLib"
import { withSuspensionCheck } from "./suspension"
import type { IStreamData } from "./streamWatcher"
import type { IBroadcastDossier } from "../broadcastlib/dossier"
import type { IBrowserAndPlatformInfo } from "@multimediallc/web-utils/modernizr"

function constraintsPixels(pixels: object | number | undefined): number | undefined {
    let res: number | undefined
    res = undefined
    if (pixels === undefined || typeof pixels === "number") {
        res = pixels
    } else if ("exact" in pixels && typeof pixels["exact"] === "number") {
        res = pixels["exact"]
    } else if ("ideal" in pixels && typeof pixels["ideal"] === "number") {
        res = pixels["ideal"]
    }
    // ignore min and max
    return res
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function constraintsResolution(constraints: { video?: { width: any, height: any } }): IResolution {
    let width: number | undefined
    width = undefined
    let height: number | undefined
    height = undefined

    const video = constraints["video"]
    if (video !== undefined) {
        width = constraintsPixels(video["width"])
        height = constraintsPixels(video["height"])
    }

    return {
        width: width,
        height: height,
    }
}

function isValidConstraints(constraints: object): constraints is { video: { width: object, height: object } } {
    return "video" in constraints && typeof constraints["video"] !== undefined
}

function resolutionPageAction(streamWidth: number, streamHeight: number, constraints: object): void {
    if (!isValidConstraints(constraints)) {
        return
    }

    const constraintsRes = constraintsResolution(constraints)
    if (constraintsRes.width !== undefined && constraintsRes.width !== streamWidth ||
        constraintsRes.height !== undefined && constraintsRes.height !== streamHeight) {
        const attrs: Record<string, string|number> = { "WebRTCHandlerError": "Stream resolution different from constraint resolution" }
        // constraints["video"] has the complete resolution information
        if (constraintsRes.width !== undefined) {
            attrs["WebRTCHandlerConstraintWidth"] = JSON.stringify(constraints["video"]["width"])
            attrs["WebRTCHandlerStreamWidth"] = streamWidth
        }
        if (constraintsRes.height !== undefined) {
            attrs["WebRTCHandlerConstraintHeight"] = JSON.stringify(constraints["video"]["height"])
            attrs["WebRTCHandlerStreamHeight"] = streamHeight
        }
        attrs["raw_constraints"] = JSON.stringify(constraints)
        addPageAction("WebRTCHandlerSetup", attrs)
    }
}

interface IResolution {
    width: number | undefined
    height: number | undefined
}

class FatalError extends Error {
    readonly fatal = true

    constructor(message?: string) {
        super(message)
    }
}

export const enum WebRTCSource {
    Mobile = "mobile",
    Desktop = "desktop",
}

export class WebRTCHandler {
    readonly dossier: IBroadcastDossier
    readonly video: HTMLVideoElement
    readonly source: WebRTCSource
    readonly browserAndPlatformInfo: IBrowserAndPlatformInfo
    private updateStatusInterval: number | undefined

    private websocket?: WebSocket
    private peerConnection?: RTCPeerConnection
    private streamData: IStreamData
    private lastMessage = 0
    private lastStreamInfoMessage = 0
    private bitrateNRSent = false
    private closed: boolean
    private constraints?: object
    private webRTCInfoSent = false
    private bitratePolling: number | undefined

    private streamInfo = { "applicationName": "live-origin", "streamName": "stream", "sessionId": "[empty]" }

    uuid: string
    streamSettings: MediaTrackSettings
    stream?: MediaStream
    started: boolean

    constructor(dossier: IBroadcastDossier, video: HTMLVideoElement, source: WebRTCSource) {
        this.dossier = dossier
        this.video = video
        // need this otherwise the enum values will not show when compiled
        this.source = source
        this.uuid = uuidv4()
        this.lastStreamInfoMessage = Date.now()
        this.started = false
        this.browserAndPlatformInfo = getBrowserAndPlatformInfo()
    }

    public setup(constraints: object): Promise<MediaStream> {
        this.constraints = constraints
        return new Promise<MediaStream>((resolve, reject) => {
            if (typeof navigator !== "object" || typeof navigator.mediaDevices !== "object" || typeof navigator.mediaDevices.getUserMedia !== "function") {
                const msg = "Your browser doesn't support broadcasting. Recent versions of Safari, Firefox, or Chrome are recommended."
                reject(new Error(msg))
                return
            }

            // See mediaDevices.ts:enumerate_devices for explanation of timeout
            let rejectedPermsAction = "DevicePermsOnLoadDenied"
            window.setTimeout(() => {
                rejectedPermsAction = "DevicePermsRequestDenied"
            }, 500)

            navigator.mediaDevices.getUserMedia(constraints).then(stream => {
                this.stream = stream
                const videoTracks = stream.getVideoTracks()
                if (videoTracks.length === 0 || videoTracks[0] === undefined) {
                    const msg = "We can't find any cameras, please ensure you have given permission to use your camera."
                    reject(new Error(msg))
                    return
                }
                this.streamSettings = videoTracks[0].getSettings()
                if (this.streamSettings.width !== undefined && this.streamSettings.height !== undefined) {
                    resolutionPageAction(this.streamSettings.width, this.streamSettings.height, constraints)
                }
                debug(`Got stream with constraints: ${JSON.stringify(constraints)}`)
                debug(`Using video device: ${videoTracks[0].label}`)
                this.video.srcObject = stream
                resolve(stream)
            }).catch((e: Error) => {
                let message = e.message
                if (e.name === "ConstraintNotSatisfiedError") {
                    message = i18n.resolutionNotSupported
                } else if (e.name === "OverconstrainedError") {
                    message = i18n.minResolutionNotSupported
                } else if (e.name === "PermissionDeniedError" || e.name === "NotAllowedError") {
                    message = i18n.needCamAndMicPermission
                } else if (message === "" || message === null) {
                    message = i18n.unknownDeviceError
                }
                addPageAction(rejectedPermsAction)
                const err = new Error(message)
                err.name = e.name
                reject(err)
            })
        })
    }

    public start(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.started = true
            let done = false

            if (this.stream === undefined) {
                this.close()
                reject(new FatalError("Unable to get video from camera. Try refreshing the page and make sure you allow video/audio if any prompts come up."))
                return
            }

            try {
                if (this.updateStatusInterval === undefined) {
                    this.closed = false
                    this.updateStatusInterval = window.setInterval(() => {
                        this.updateStatus()
                    }, 1000)
                }

                this.createPeerConnection()

                if (this.peerConnection === undefined) {
                    reject(new Error("Unable to connect to peer"))
                    return
                }

                this.peerConnection.oniceconnectionstatechange = (ev) => {
                    if (this.peerConnection === undefined) {
                        debug("oniceconnectionstatechange for closed peer", ev)
                        return
                    }
                    debug(`oniceconnectionstatechange (${this.peerConnection.iceConnectionState})`, ev)

                    if (done) {
                        return
                    }

                    if (this.peerConnection.iceConnectionState === "connected" || this.peerConnection.iceConnectionState === "completed") {
                        done = true
                        addPageAction("WebRTCHandlerConnected",  { "WebRTCHandlerSource": this.source })
                        streamStart.fire(this.uuid)
                        clearInterval(this.bitratePolling)
                        this.bitratePolling = window.setInterval(() => {
                            if (this.streamData.bitrate !== undefined) {
                                reportBroadcasterBitrate("webrtc", this.streamData.bitrate)
                            }
                        }, 60000)
                        resolve()
                    }

                    if (this.peerConnection.iceConnectionState === "failed") {
                        done = true
                        reject(new Error("WebRTC failed"))
                    }
                }
                this.connectOrigin(this.dossier, reject)
            } catch (e) {
                reject(e)
            }
        })
    }

    public stop(reconnect?: boolean): Promise<string> {
        const restart = reconnect === true
        return new Promise<string>((resolve) => {
            const finish = () => {
                if (!restart) {
                    this.started = false
                    streamStop.fire(this.uuid)
                }
                resolve(this.uuid)
            }

            clearInterval(this.bitratePolling)

            // stop retries first, in case anything below fails
            if (!restart) {
                this.close()
            }

            if (this.websocket !== undefined) {
                if (this.websocket.readyState !== WebSocket.CLOSING && this.websocket.readyState !== WebSocket.CLOSED) {
                    try {
                        this.websocket.close()
                    } catch (e) {
                        error("could not close websocket connection", { "reason": e.toString() })
                    }
                }
                this.websocket = undefined
            }

            if (this.peerConnection !== undefined) {
                try {
                    this.peerConnection.close()
                } catch (e) {
                    error("could not close peer connection", { "reason": e.toString() })
                }
                this.peerConnection = undefined
            }

            this.lastMessage = Date.now()

            if (!restart && this.stream !== undefined) {
                stopTracksWithTimeout(this.stream.getTracks()).then(() => {
                    this.stream = undefined
                    finish()
                }).catch(() => {})
            } else {
                finish()
            }
        })
    }

    public setMute(mute: boolean): void {
        // TODO this should return boolean
        if (this.stream === undefined) {
            error("no stream")
            return
        }
        if (this.stream.getAudioTracks().length > 0) {
            this.stream.getAudioTracks()[0].enabled = !mute
        }
    }

    public reset(): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            const started = this.started
            this.stop().then(() => {
                if (this.constraints !== undefined) {
                    this.setup(this.constraints).then((stream) => {
                        if (started) {
                            this.start().then(() => {
                                resolve()
                            }).catch(e => {
                                reject(e)
                            })
                        } else {
                            resolve()
                        }
                    }).catch(e => {
                        reject(e)
                    })
                } else {
                    resolve()
                }
            }).catch(e => {
                reject(e)
            })
        })
    }

    private close(): void {
        this.closed = true
        if (this.updateStatusInterval !== undefined) {
            clearInterval(this.updateStatusInterval)
            this.updateStatusInterval = undefined
        }
    }

    private updateStatus(): void {
        if (this.closed === true) {
            this.stop()  // eslint-disable-line @typescript-eslint/no-floating-promises
            return
        }
        const now = Date.now()
        if (this.lastStreamInfoMessage < now - 10000) {
            this.lastStreamInfoMessage = now
            if (!this.shouldUpdateOrigin()) {
                this.stop()  // eslint-disable-line @typescript-eslint/no-floating-promises
                return
            }
            addPageAction("WebRTCHandlerRestart")
            this.stop(true).then(() => {
                const url = `api/switch_origin/?current_origin=${this.dossier.originHost}`
                getCb(url).then(origin => {
                    this.dossier.originHost = origin.responseText
                    this.start().catch(e => {
                        error("Could not restart stream", { "reason": e.message })
                    })
                }).catch(e => {
                    error("Could not get new origin", { "reason": e.message })
                })
            }).catch(() => {})
        }
        let data = this.streamData
        if (this.websocket === undefined) {
            data = { "method": "stream_info", "status": "connecting" }
        }
        if (data !== undefined) {
            streamStatusUpdate.fire({ uuid: this.uuid, data: data })
        }
    }

    private shouldUpdateOrigin(): boolean {
        return getSmcSharedWith() === undefined
    }

    private createPeerConnection(): void {
        if (this.stream === undefined) {
            error("no stream")
            return
        }

        if (this.peerConnection !== undefined) {
            error("peerConnection already open")
            this.peerConnection.close()
            this.peerConnection = undefined
        }

        this.peerConnection = new RTCPeerConnection({ "iceServers": [] })

        this.peerConnection.onicecandidate = (ev) => {
            debug("onicecandidate", ev)
        }
        // Only want one of each
        if (this.stream.getAudioTracks().length > 0) {
            this.peerConnection.addTrack(this.stream.getAudioTracks()[0], this.stream)
        }
        this.peerConnection.addTrack(this.stream.getVideoTracks()[0], this.stream)
        this.peerConnection.createOffer().then((description) => {
            this.updateSDP(description.sdp)
        }).catch(() => {})
    }

    private connectOrigin(dossier: IBroadcastDossier, reject: (reason?: any) => void): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (this.websocket !== undefined) {
            error("WebSocket already open")
            this.websocket.close()
            this.websocket = undefined
        }

        const websocket = new WebSocket(`wss://${dossier.originHost}${dossier.originWSEndpoint}`)
        websocket.binaryType = "arraybuffer"

        websocket.onopen = () => {
            this.websocket = websocket
            websocket.send(JSON.stringify({
                "method": "connect",
                "username": dossier.room,
                "password": dossier.originPassword,
            }))
        }
        websocket.onerror = (event: Event) => {
            error("webrtc websocket error", { "error": event })
            reject(new Error(`WebSocket error: ${JSON.stringify(event)}`))
        }
        websocket.onclose = () => {
            this.websocket = undefined
        }
        websocket.onmessage = (event) => {
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            let data: any
            try {
                data = JSON.parse(event.data)
            } catch (err) {
                return
            }
            switch (data["method"]) {
                case "auth_valid":
                    this.createOffer(reject)
                    break
                case "stream_info":
                    this.streamData = data
                    if (!this.webRTCInfoSent) {
                        this.webRTCInfoSent = true
                        this.sendInitialWebRTCInfo()
                    }
                    this.lastStreamInfoMessage = Date.now()
                    break
                case "already_publishing":
                    this.lastMessage = Date.now()
                    modalAlert("You have started publishing from a new location, shutting down stream", () => {
                        tryFullScreen.fire("")
                        showOBSOverlay.fire("")
                    })
                    this.stop()  // eslint-disable-line @typescript-eslint/no-floating-promises
                    break
                case "shutdown":
                    if (this.lastMessage < Date.now() - 1000) {
                        modalAlert("Error Publishing Stream", () => {
                            tryFullScreen.fire("")
                            withSuspensionCheck(() => {})
                        })
                    }
                    this.stop()  // eslint-disable-line @typescript-eslint/no-floating-promises
                    break
                case "origin_info":
                    break
                default:
                    switch (data["command"]) {
                        case "sendOffer":
                            this.processSdp(data, reject)
                            break
                        default:
                            warn("Unhandled", data)
                    }
            }
        }
    }

    /**
     * Returns width & height corrected for mobile browser issues
     *
     * Chrome on Android, if this handler is initialized in portrait, will send
     * swapped width & height for the offer, resulting in a bad aspect ratio.
     * This swaps them, since the mobile site can only broadcast in landscape.
     */
    private correctedWidthAndHeight(): Record<"width" | "height", number | undefined> {
        const { width, height } = this.streamSettings
        const isPortrait = Number(width) < Number(height)
        // iOS 16, for whatever reason on desktop, swaps the width and height so it needs to be swapped back
        const iOS = iOSVersion()
        if (this.source === WebRTCSource.Mobile && isPortrait || iOS !== undefined && iOS >= 16) {
            return { "width": height, "height": width } // swapped
        } else {
            return { "width": width, "height": height }
        }
    }

    private createOffer(reject: (reason: any) => void): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (this.websocket === undefined) {
            reject(new Error("ws undefined"))
            return
        }
        if (this.stream === undefined) {
            reject(new Error("stream undefined"))
            return
        }
        // Presend the res
        this.websocket.send(JSON.stringify({
            "method": "publish",
            "frameRate": this.streamSettings.frameRate,
            "source": this.source,
            "browserAndPlatform": JSON.stringify(this.browserAndPlatformInfo),
            ...this.correctedWidthAndHeight(),
        }))
        if (this.peerConnection === undefined) {
            error("peerConnection not open")
            return
        }
        this.peerConnection.createOffer().then(description => {
            if (this.peerConnection === undefined) {
                error("peerConnection not open")
                return
            }
            debug(`Got description ${description}`)
            description.sdp = this.updateSDP(description.sdp)

            debug(`gotDescription: ${JSON.stringify({ "sdp": description })} ${this.peerConnection}`)

            this.peerConnection.setLocalDescription(description).then(() => {
                if (this.websocket === undefined) {
                    reject(new Error("ws undefined"))
                    return
                }
                this.websocket.send(JSON.stringify({
                    "direction": "publish",
                    "command": "sendOffer",
                    "streamInfo": this.streamInfo,
                    "sdp": description,
                }))
            }).catch(e => {
                reject(e)
            })
        }).catch(e => {
            reject(e)
        })
    }

    private processSdp(data: any, reject: (reason: any) => void): void { // eslint-disable-line @typescript-eslint/no-explicit-any
        if (this.peerConnection === undefined) {
            error("peerConnection not open")
            return
        }

        const sdpData = data["sdp"]
        if (sdpData !== undefined && sdpData !== null) {
            debug("sdp: ", sdpData)

            this.peerConnection.setRemoteDescription(sdpData)
                .catch((e: Error) => {
                    reject(e)
                })
        }

        const iceCandidates = data["iceCandidates"]
        if (iceCandidates !== undefined) {
            for (const candidate of iceCandidates) {
                debug("iceCandidates: ", candidate)

                this.peerConnection.addIceCandidate(new RTCIceCandidate(candidate)).catch(e => {
                    reject(e)
                })
            }
        }
    }

    private updateSDP(sdpStr?: string): string { // eslint-disable-line complexity
        if (sdpStr === undefined) {
            error("sdpStr undefined")
            return ""
        }

        debug("Original SDP", sdpStr)
        const sdp = new SDPLib(sdpStr)
        if (isiOS()) {
            // Iphone does not handle vp8 well
            sdp.forceVideoCodecs(["H264"], ["42e01f"])
        } else {
            // Chrome needs baseline h264
            sdp.forceVideoCodecs(["H264", "VP8"], ["42e01f"])
        }

        if (!featureFlagIsActive("VDPFixedBitrate")) {
            // Autobitrate with Wowza on Chrome, iOS, and Safari is broken, so force bitrate
            const videoBitrate = sdp.forceVideoBitrate(this.streamSettings.height)
            if (videoBitrate !== undefined) {
                // This method gets called twice. Let's only send the metric once.
                if (!this.bitrateNRSent) {
                    addPageAction("WebRTCHandlerSDPUpdate", { "WebRTCHandlerVideoBitrate": videoBitrate })
                    this.bitrateNRSent = true
                }
            }
        }

        sdpStr = sdp.write()
        debug("Updated SDP", sdpStr)

        return sdpStr
    }

    public static isWebRTCNotYetSupported(): boolean {
        // These browsers support WebRTC but not well. In the future we might support these.
        return isPuffin()
    }

    public static isWebRTCNeverSupported(): boolean {
        // These browsers will never support WebRTC well.
        return isEdgeMS()
    }

    public static canUseWebRTC(): boolean {
        return !WebRTCHandler.isWebRTCNotYetSupported() && !WebRTCHandler.isWebRTCNeverSupported()
    }

    private sendInitialWebRTCInfo(): void {
        let region = ""
        if (this.streamData.region !== undefined) {
            region = this.streamData.region
        }
        postCb("api/ts/chat/webrtc-start/", {
            "room": this.dossier.room,
            "stream_type": "webrtc",
            "origin_host": this.dossier.originHost,
            "origin_region": region,
            "webrtc_browser_info": JSON.stringify(this.browserAndPlatformInfo),
        }) // eslint-disable-line @typescript-eslint/no-floating-promises
    }
}

export interface IStatusUpdate {
    uuid: string,
    data: IStreamData,
}
export const streamStatusUpdate = new EventRouter<IStatusUpdate>("streamStatusUpdate")
export const streamStart = new EventRouter<string>("streamStart")
export const streamStop = new EventRouter<string>("streamStop")
