import { getLocalStorageWithExpiration, setLocalStorageWithExpiration } from "@multimediallc/web-utils/storage"
import { addColorClass } from "../cb/colorClasses"
import { applyStyles } from "./DOMutils"
import { ModalComponent } from "./modalComponent"
import { i18n } from "./translation"
import { dom } from "./tsxrender/dom"

const BROADCAST_TERMS_KEY = "broadcast_terms"

export class BroadcastTermsModal extends ModalComponent {
    protected body: HTMLDivElement
    protected acceptLink: HTMLAnchorElement

    constructor(blockPage = true, acceptCallback = () => {}, rejectCallback = () => {}) {
        super({ easyExit: false })

        const broadcastTermsModalStyle: CSSX.Properties = {
            position: "fixed",
            overflow: "hidden",
            height: "auto",
            maxWidth: "1000px",
            minWidth: "450px",
            width: "90%",
            top: "50%",
            transform: "translateY(-50%)",
            left: "0px",
            right: "0px",
            margin: "auto",
            padding: "20px",
            borderWidth: "2px",
            borderStyle: "solid",
            borderRadius: "20px",
            // Display before other modal components
            zIndex: 10000,
        }
        applyStyles(this.element, broadcastTermsModalStyle)
        addColorClass(this.element, "broadcastTermsModal")

        this.overlay.style.background = ""
        if (blockPage) {
            addColorClass(this.overlay, "blackOverlay")
            this.overlayClickStayOpen = true
        } else {
            addColorClass(this.overlay, "transparentOverlay")
            this.overlayClick.listen(rejectCallback)
        }

        this.element.appendChild(this.createHeader())
        this.element.appendChild(this.createBody())
        this.element.appendChild(this.createAcceptButton(acceptCallback))
    }

    public static areTermsAccepted(): boolean {
        const stored_value = getLocalStorageWithExpiration(BROADCAST_TERMS_KEY)
        return stored_value === "1"
    }

    private createHeader(): HTMLDivElement {
        return <div colorClass="header">
            <h3 style={{ textAlign: "center" }}>{ i18n.broadcastTermsHeader }</h3>
        </div>
    }

    private createBody(): HTMLDivElement {
        const bodyStyle: CSSX.Properties = {
            height: "200px",
            overflow: "auto",
            padding: "0 15px 0 10px",
            borderWidth: "1px",
            borderStyle: "solid",
            margin: "0 auto",
            textAlign: "left",
        }
        this.body = <div style={bodyStyle} colorClass="body">
            <p>{ i18n.broadcastTerms1 }</p>
            <ol>
                <li>{ i18n.broadcastTerms2 }</li>
                <li>{ i18n.broadcastTerms3 }</li>
                <li>{ i18n.broadcastTerms4 }</li>
                <li>{ i18n.broadcastTerms5 }</li>
                <li dangerouslySetInnerHTML={{ __html: i18n.broadcastTerms6 }}></li>
                <li>{ i18n.broadcastTerms7 }</li>
                <li>{ i18n.broadcastTerms8 }</li>
                <li>{ i18n.broadcastTerms9 }</li>
            </ol>
            <p dangerouslySetInnerHTML={{ __html: i18n.broadcastTerms10 }}></p>
        </div>
        return this.body
    }

    protected createAcceptButton(acceptCallback: () => void): HTMLDivElement {
        const acceptButtonStyle: CSSX.Properties = {
            textAlign: "center",
            color: "#222",
        }
        const acceptLinkStyle: CSSX.Properties = {
            width: "200px",
            height: "30px",
            lineHeight: "30px",
            fontWeight: "bold",
            margin: "10px 5px 0px 5px",
            borderWidth: "1px",
            borderStyle: "solid",
            display: "inline-block",
            textAlign: "center",
            cursor: "pointer",
        }
        const onClick = (event: MouseEvent) => {
            event.stopPropagation()
            this.hide()
            setLocalStorageWithExpiration(BROADCAST_TERMS_KEY, "1", { days: 7 })
            acceptCallback()
        }
        return <div style={acceptButtonStyle}>
            <a style={acceptLinkStyle}
                data-testid="agree-broadcast-terms-button"
                colorClass="acceptLink"
                ref={(el: HTMLAnchorElement) => {this.acceptLink = el}}
                onClick={onClick}>{ i18n.termsAgree }</a>
        </div>
    }
}
