import { isOfflineFollowed, ShowType, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { MultipleCheckbox } from "../../ui/fields"
import { AdvancedOptionsCheckboxStyles } from "./AdvancedOptionsStyles"
import type { IChoices } from "../../ui/fields"


const showLocationChoices: IChoices[] = [
    { label: i18n.showLocations, value: true },
]

const previewRoomsChoices: IChoices[] = [
    { label: i18n.previewRooms, value: true },
]

interface ISettingsFormProps {
    showLocation: boolean,
    animateThumbnails: boolean,
    animateThumbnailsDisabled: boolean,
}

export class AdvancedSettingsForm extends HTMLComponent<HTMLDivElement, ISettingsFormProps> {
    showLocationCheckboxes: MultipleCheckbox
    animateThumbnailsCheckboxes: MultipleCheckbox

    protected initData(props: ISettingsFormProps): void {
        super.initData(props)
        UrlState.current.listen(["showType"], (state) => {
            this.setHidden(this.showLocationCheckboxes, state.showType === ShowType.FOLLOW_OFFLINE)
        }, this.element)
    }

    protected createElement(props: ISettingsFormProps): HTMLDivElement {
        this.showLocationCheckboxes = new MultipleCheckbox({
            choices: showLocationChoices,
            name: "show_location",
            defaultValue: props.showLocation,
            styles: new AdvancedOptionsCheckboxStyles(),
        })
        this.animateThumbnailsCheckboxes = new MultipleCheckbox({
            choices: previewRoomsChoices,
            name: "animate_thumbnails",
            defaultValue: props.animateThumbnails,
            disabled: props.animateThumbnailsDisabled,
            styles: new AdvancedOptionsCheckboxStyles(),
        })
        this.setDisabled(this.animateThumbnailsCheckboxes, props.animateThumbnailsDisabled)
        this.setHidden(this.showLocationCheckboxes, isOfflineFollowed())
        return <div id="advanced_options">
            <label>{i18n.settings}:</label>
            <form id="filter_options_form" action="/ax/update_option_filters/" method="post">
                {this.showLocationCheckboxes.getField()}
            </form>
            <form id="animate_thumbnails_form" action="/ax/update_animate_thumbnails_options/" method="post">
                {this.animateThumbnailsCheckboxes.getField()}
            </form>
        </div>
    }

    protected initUI(props?: ISettingsFormProps): void {
        super.initUI(props)
        this.setIdAndForAttribute(this.showLocationCheckboxes, "id_show_location")
        this.setIdAndForAttribute(this.animateThumbnailsCheckboxes, "id_animate_thumbnails")
    }

    private setDisabled(checkbox: MultipleCheckbox, isDisabled: boolean): void {
        // Disables the input checkbox
        const inputEl = checkbox.getField().querySelector("input")
        if (inputEl !== null) {
            inputEl.disabled = isDisabled
        }
    }

    private setHidden(checkbox: MultipleCheckbox, isHidden: boolean): void {
        // Hides the checkbox field entirely
        const fieldEl = checkbox.getField()
        fieldEl.style.display = isHidden ? "none" : "block"
        this.setDisabled(checkbox, isHidden)
    }

    private setIdAndForAttribute(checkbox: MultipleCheckbox, id: string): void {
        // Set the given checkbox input element id and the for attribute of it's label to the given id
        const checkboxInput = checkbox.getField().querySelector("input")
        if (checkboxInput !== null) {
            checkboxInput.id = id
            const label = checkboxInput.closest("td label") as HTMLLabelElement
            if (label !== null){
                label.htmlFor = checkboxInput.id
            }
        }
    }
}
