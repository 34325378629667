import { PageType, shouldShowHomepageFilters, UrlState } from "@multimediallc/cb-roomlist-prefetch"
import { addEventListenerPoly } from "../../../common/addEventListenerPolyfill"
import { Component } from "../../../common/defui/component"
import { applyStyles, getScrollbarWidth, underlineOnHover } from "../../../common/DOMutils"
import { Gender, getCurrentGender } from "../../../common/genders"
import { getViewportWidth } from "../../../common/mobilelib/viewportDimension"
import { addPageAction } from "../../../common/newrelic"
import { pageContext } from "../../interfaces/context"
import { currentSiteSettings } from "../../siteSettings"
import { CollapsibleComponent, ExpandableDropDownMenu, isOverflown } from "../../ui/expandableDropDownMenu"
import { makeResponsive, resizeDebounceEvent } from "../../ui/responsiveUtil"
import { GenderedLink } from "../genderedLink"
import { isRoomRoomlistSpaEligiblePage } from "../roomlist/spaHelpers"

export class Header extends Component {
    private _headerCollapseDropDownTab: ExpandableDropDownMenu
    private nav: Component
    private genderedLinks: GenderedLink[]
    private firstTopSectionAnchor: HTMLElement | null

    constructor(headerEl: HTMLElement) {
        super(headerEl)
        this.makeLogoResponsive()
        this.addHeaderAdPageAction()
        this.addMerchLinkPageAction()
        this.makeTopNavResponsive()
        this.makeUserInfoResponsive()
        this.bindLinkUpdateHandling()

        const isRmRmlstSpaRoomPage = isRoomRoomlistSpaEligiblePage() && UrlState.current.state.pageType === PageType.ROOM
        if (shouldShowHomepageFilters() || isRmRmlstSpaRoomPage) {
            this.makeHeaderLinksSPA(headerEl)
        }
    }

    private makeHeaderLinksSPA(headerEl: HTMLElement) {
        const spyTab = headerEl.querySelector("#spy_tab a") as HTMLAnchorElement
        if (spyTab !== null) {
            addEventListenerPoly("click", spyTab, (e) => {
                e.preventDefault()
                UrlState.current.navigateTo(spyTab.href)
            })
        }
        const homeTab = headerEl.querySelector("#ChatRoomLink") as HTMLAnchorElement
        if (homeTab !== null) {
            addEventListenerPoly("click", homeTab, (e) => {
                e.preventDefault()
                UrlState.current.navigateTo(homeTab.href)
            })
        }
        const logo = headerEl.querySelector("#logoLink") as HTMLAnchorElement
        if (logo !== null) {
            addEventListenerPoly("click", logo, (e) => {
                e.preventDefault()
                UrlState.current.navigateTo(logo.href)
            })
        }
    }

    private makeTopNavResponsive(): void {
        this.genderedLinks = []
        this.initFirstTopSectionAnchor()

        const navBarEl = document.querySelector<HTMLElement>(".nav-bar")
        if (navBarEl !== null) {
            makeResponsive(navBarEl, 500, 600, [{ name: "padding-left", min: 4, max: 15 }])
        }

        const navEl = document.getElementById("nav")
        if (navEl !== null) {
            this.nav = new Component(navEl)
            const gender = getCurrentGender()
            Array.from(navEl.children).forEach((childEl) => {
                if (childEl instanceof HTMLLIElement) {
                    const collapsibleComponent = new CollapsibleComponent(childEl)
                    this.nav.attachChild(collapsibleComponent)
                    collapsibleComponent.element.style.display = "block"
                    const anchors = collapsibleComponent.element.getElementsByTagName("a")
                    const a = anchors[0]
                    if (a !== null && a instanceof HTMLAnchorElement) {
                        const aStyle = getComputedStyle(a)
                        a.style.color = aStyle.color
                        a.style.font = aStyle.font
                        // for IE we need to set font related styles individually.
                        a.style.fontFamily = aStyle.fontFamily
                        a.style.fontWeight = aStyle.fontWeight
                        a.style.fontSize = aStyle.fontSize

                        if (a.hasAttribute("gendered-link")) {
                            this.genderedLinks.push(new GenderedLink({ anchorElement: a, gender: gender }))
                        }
                    }

                    a.onfocus = () => {
                        this.focusIntoDropdown(collapsibleComponent)
                    }

                    a.onblur = (event) => {
                        this.blurOutOfDropdown(event)
                    }

                    collapsibleComponent.onCollapseEvent.listen((collapsed) => {
                        collapsibleComponent.element.style.padding = collapsed ? "8px 4px" : ""
                    })
                }
            })
            const headerCollapseDropDownTab = new ExpandableDropDownMenu("li", true)
            this.nav.addChild(headerCollapseDropDownTab)
            underlineOnHover(headerCollapseDropDownTab.element)
            applyStyles(headerCollapseDropDownTab, {
                border: "1px solid transparent",
                borderRadius: "4px 4px 0 0",
                height: "30px",
                top: "6px",
                width: "39px",
                paddingTop: "3px",
                color: currentSiteSettings.navigationFontColor,
            })
            headerCollapseDropDownTab.dropDown.toggleEvent.listen((evt) => {
                if (evt.isShowing) {
                    headerCollapseDropDownTab.element.style.border = `1px solid ${currentSiteSettings.tabBorderColor}`
                } else {
                    headerCollapseDropDownTab.element.style.border = "1px solid transparent"
                }
            })
            applyStyles(headerCollapseDropDownTab.dropDown, {
                borderColor: `${currentSiteSettings.tabBorderColor}`,
                padding: "8px 20px 8px 8px",
            })
            headerCollapseDropDownTab.dropDown.element.classList.add("header-dropdown")

            this._headerCollapseDropDownTab = headerCollapseDropDownTab
            this.setCollapsibleDropdownSpacerWidth()
        }
    }

    private focusIntoDropdown(collapsibleComponent: CollapsibleComponent): void {
        if (isOverflown(collapsibleComponent)) {
            this._headerCollapseDropDownTab.dropDown.showElement()
            this.setTabIndexForDropdown(200)
            window.setTimeout(() => {
                // Focus is lost when showElement moves the tab into the dropdown
                collapsibleComponent.element.getElementsByTagName("a")[0].focus()
            }, 0)
        }
    }

    private blurOutOfDropdown(event: FocusEvent) {
        // check if the blurred element is in the collapsed tab.
        const blurredEl = event.target
        const focusedEl = event.relatedTarget
        event.preventDefault()
        if (blurredEl instanceof HTMLAnchorElement && this._headerCollapseDropDownTab.isTargetOverflownAnchor(blurredEl)) {
            // check if the focused element is not in the collapsed tab or doesn't exists.
            if (focusedEl instanceof HTMLElement
                && !this._headerCollapseDropDownTab.isTargetOverflownAnchor(focusedEl)
                || focusedEl === null) {
                this.setTabIndexForDropdown(0, true)
                this._headerCollapseDropDownTab.dropDown.hideElement()
            }
        }
    }

    private setTabIndexForDropdown(index=0, reset=false): void {
        const lastShownCollapsedTab = this._headerCollapseDropDownTab.lastShownSibling()
        if (lastShownCollapsedTab !== undefined) {
            lastShownCollapsedTab.element.getElementsByTagName("a")[0].tabIndex = reset ? 0 : index
        }
        for (const anchor of this._headerCollapseDropDownTab.dropDown.element.getElementsByTagName("a")) {
            anchor.tabIndex = reset ? 0 : index + 1
        }
        if (this.firstTopSectionAnchor !== null && this.firstTopSectionAnchor !== undefined) {
            this.firstTopSectionAnchor.tabIndex = reset ? 0 : index + 2
        }
    }

    private  initFirstTopSectionAnchor(): void {
        const topSection = document.querySelector(".top-section")
        if (topSection !== null) {
            this.firstTopSectionAnchor = topSection.getElementsByTagName("a")[0]
            if (this.firstTopSectionAnchor === undefined) {
                // Tag page does not have ASO
                this.firstTopSectionAnchor = topSection.getElementsByTagName("li")[0]
            }
        }
    }

    private setCollapsibleDropdownSpacerWidth(): void {
        if (this.nav !== undefined && this.nav.element.parentElement !== null) {
            let width = 0
            for (const child of this.nav.element.parentElement.children) {
                if (child !== this.nav.element && child instanceof HTMLElement) {
                    width += child.offsetWidth
                }
            }

            this._headerCollapseDropDownTab.setSpacerWidth(width)
        }
    }

    private addHeaderAdPageAction(): void {
        const headerAdAnchor: HTMLAnchorElement | null = document.querySelector("#header .ad a")
        if (headerAdAnchor !== null) {
            headerAdAnchor.dataset.testid = "header-ad-anchor"
            headerAdAnchor.onclick = () => {
                addPageAction("BannerAdClicked", {
                    "content": "real_ads",
                    "ad_title": headerAdAnchor.getAttribute("data-feature-show-title") ?? undefined,
                    "location": pageContext.current.pageLocation,
                })
            }
        }
    }

    private addMerchLinkPageAction(): void {
        const merchLink: HTMLAnchorElement | null = document.querySelector("#merch")
        if (merchLink !== null) {
            merchLink.onclick = () => { addPageAction("MerchLinkClicked") }
        }
    }

    private makeLogoResponsive(): void {
        const logoEl = document.querySelector(".logo-zone")
        if (logoEl !== null && logoEl instanceof HTMLElement) {
            logoEl.dataset.testid = "header-logo"
            const logoSVG = document.querySelector(".logo-zone svg")
            const logoText = document.querySelector(".logo-zone strong:last-child")
            makeResponsive(logoEl, 500, 600, [
                { name: "padding-left", min: 8, max: 15 },
                { name: "padding-top", min: 12, max: 6 },
            ])

            if (logoSVG !== null && logoSVG instanceof HTMLElement) {
                makeResponsive(logoSVG, 500, 600, [
                    { name: "width", min: 145, max: 198 },
                    { name: "height", min: 45, max: 61 },
                ])
            }
            if (logoText !== null && logoText instanceof HTMLElement) {
                logoText.dataset.testid = "header-slogan-text"
                makeResponsive(logoText, 500, 600, [
                    { name: "font-size", min: 8, max: 10 },
                    { name: "padding-left", min: 13, max: 17 },
                ])
            }
            const logoWhitelabel = document.querySelector(".logo-zone .logo")
            if (logoWhitelabel !== null && logoWhitelabel instanceof HTMLElement) {
                const logoWidth = logoWhitelabel.clientWidth
                if (logoWidth > 500 - getScrollbarWidth()) {
                    resizeDebounceEvent.listen(() => {
                        if (logoWidth + logoWhitelabel.offsetLeft > getViewportWidth()) {
                            logoWhitelabel.style.maxWidth = `${getViewportWidth() - logoWhitelabel.offsetLeft - 1}px`
                        } else {
                            logoWhitelabel.style.maxWidth = ""
                        }
                    })
                }
            }
        }
    }

    private makeUserInfoResponsive(): void {
        const userInfoEl = document.querySelector("#user_information")
        if (userInfoEl !== null && userInfoEl instanceof HTMLElement) {
            makeResponsive(userInfoEl, 500, 600, [{ name: "margin-right", min: 4, max: 15 }])
        }
    }

    private bindLinkUpdateHandling(): void {
        UrlState.current.listen(["genders"], (state) => {
            const gender = (state.genders ?? [])[0] ?? Gender.All
            this.genderedLinks.forEach((anchor) => {
                anchor.setState({ gender: gender })
            })
        }, this.element)
    }
}
