export interface IItem {
    slug: string,
    element: HTMLDivElement
}

/**
 * Caches the autocomplete information for some prefixes and can also filter the results itself to reduce api calls
 */
export class FilteringCache<T extends IItem> {
    protected cache: Map<string, T[]>

    constructor(private filterAfterSize: number | undefined = undefined, private cacheTTL = -1) {
        this.cache = new Map()
    }

    protected getShortenedPrefix(prefix: string): string {
        if (this.filterAfterSize === undefined || prefix.length > this.filterAfterSize) {
            return prefix.substring(0,this.filterAfterSize)
        }
        return prefix
    }

    get(prefix: string): T[] | undefined {
        const shortenedPrefix = this.getShortenedPrefix(prefix)
        const cacheResult = this.cache.get(shortenedPrefix)
        if (shortenedPrefix === prefix) {
            return cacheResult
        } else {
            return cacheResult?.filter((item) => item.slug.startsWith(prefix))
        }
    }

    set(prefix: string, items: T[]): void {
        if (this.filterAfterSize === undefined || prefix.length <= this.filterAfterSize) {
            this.cache.set(prefix, items)
            if (this.cacheTTL !== -1) {
                window.setTimeout(() => this.cache.delete(prefix), this.cacheTTL)
            }
        }
    }
}

export class CaseAgnosticFilteringCache extends FilteringCache<IItem> {

    get(prefix: string): IItem[] | undefined {
        const shortenedPrefix = this.getShortenedPrefix(prefix)
        const cacheResult = this.cache.get(shortenedPrefix)
        if (shortenedPrefix === prefix) {
            return cacheResult
        } else {
            return cacheResult?.filter((item) => item.slug.toLowerCase().startsWith(prefix))
        }
    }
}

export class FullSearchFilteringCache<T extends IItem> extends FilteringCache<T> {

    get(prefix: string): T[] | undefined {
        const shortenedPrefix = this.getShortenedPrefix(prefix)
        const cacheResult = this.cache.get(shortenedPrefix)
        if (shortenedPrefix === prefix) {
            return cacheResult
        } else {
            return cacheResult?.filter((item) => item.slug.includes(prefix))
        }
    }
}
