import { ArgJSONMap } from "@multimediallc/web-utils"
import { getCb, postCb } from "../../../common/api"
import type { IUserUpdateItem } from "../../../common/messageInterfaces"

export const enum NotificationType {
    TWITTER_FEED = "twitter_feed",
    OFFLINE_TIP = "offline_tip",
}

export class Api {
    static getUserUpdates(notificationTypes: NotificationType[], callback: (items: IUserUpdateItem[]) => void): void {
        // When on TFA page for SSO it throws 403 errors since the user is still not logged in so to avoid it
        if(window.location.pathname === "/security/tfa/auth/" || window.location.pathname === "/security/tfa/auth/choose_device/"){
            return
        }
        const notificationParams = notificationTypes.map(t => `notification_type=${t}`).join("&")
        getCb(`notifications/updates/?${notificationParams}`).then(xhr => {
            const items = new ArgJSONMap(xhr.responseText).getList("items")
            if (items !== undefined) {
                const updateItems = items.map(item => {
                    return {
                        text: item.getString("text"),
                        url: item.getString("url"),
                        timestamp: item.getNumber("timestamp"),
                        timeSince: item.getString("time_since"),
                        seen: item.getBoolean("seen"),
                        notificationType: item.getString("notification_type"),
                    } as IUserUpdateItem
                })
                callback(updateItems)
            }
        }).catch(() => {})
    }

    static recordLastSeen(recordLastSeenUrl: string, timestamp: number, callback: () => void): void {
        postCb(recordLastSeenUrl, { "timestamp": timestamp.toString() }).then(() => {
            callback()
        }).catch(() => {})
    }

    static hasUnseen(callback: (has_unseen: boolean) => void): void {
        getCb("notifications/has_unseen/").then(xhr => {
            const response = JSON.parse(xhr.responseText)
            callback(response.has_unseen)
        }).catch(() => {})
    }
}
