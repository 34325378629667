/* eslint-disable @typescript-eslint/no-explicit-any */

declare global {
    interface Window {ReactComponentRegistry: Record<string, IReactComponent>}
}

// Takes props for the given React Component - make sure to quote object keys to be ClosureCompiler safe
// Takes target to render React Component into
export type IReactComponent = new (props: Record<string, any>, target: HTMLElement) => ReactComponent

export abstract class ReactComponent {
    // Takes partial props (props to update) and re-renders the React Component - make sure to quote object keys to be ClosureCompiler safe
    update(props: Record<string, any>): void {}
    // Causes React to unmount the Component from the target element and stop tracking lifecycle.
    // Always call this when the target element will be discarded or replaced.
    dispose(): void {}
}

class ReactDummy extends ReactComponent {
    constructor(props: Record<string, any>, target: HTMLElement){
        super()
        const errorSpan = document.createElement("span")
        errorSpan.style.color = "red"
        errorSpan.textContent = "Error loading component"
        target.appendChild(errorSpan)
        warn("Created dummy React component")
    }
    update(props: Record<string, any>): void {
        warn("Update called on dummy React component")
    }
    dispose(): void {
        warn("Dispose called on dummy React component")
    }
}

export class ReactComponentRegistry {
    // Returns class with given name.  Will return dummy component if class is not added to registry in React app for given page.
    public static get(name: string): IReactComponent {
        if (window["ReactComponentRegistry"] === undefined) {
            error("ReactComponentRegistry is undefined - React code is likely not loaded", { "argument": name })
            // Return dummy class so TS will continue to run.
            return ReactDummy
        }
        if (window["ReactComponentRegistry"][name] === undefined) {
            error(`ReactComponentRegistry does not contain component ${name} - React code may not be up to date`, { "argument": name })
            // Return dummy class so TS will continue to run.
            return ReactDummy
        }
        return window["ReactComponentRegistry"][name]
    }
}
