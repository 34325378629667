import { addEventListenerMultiPoly } from "./addEventListenerPolyfill"
import { EventRouter } from "./events"
import { getViewportHeight } from "./mobilelib/viewportDimension"

export function isVirtualKeyboardLikelyShowing(): boolean {
    const isInputFocused = document.activeElement instanceof HTMLInputElement || (document.activeElement as HTMLElement).contentEditable === "true"
    const hasSmallerVisualViewport = Math.round(visualViewport?.height ?? getViewportHeight()) < getViewportHeight()
    return isInputFocused && hasSmallerVisualViewport
}

export function focusMayTriggerVirtualKeyboard(el: HTMLElement): boolean {
    const nonTypingInputTypes = [
        "checkbox",
        "radio",
        "button",
        "reset",
        "submit",
        "file",
    ]

    return (
        el.tagName.toLowerCase() === "textarea" ||
        el.isContentEditable ||
        el instanceof HTMLInputElement && !nonTypingInputTypes.includes(el.type.toLowerCase())
    )
}

export const anyTypingInputFocused = new EventRouter<Event>("anyTypingInputFocused")
export const anyTypingInputBlurred = new EventRouter<Event>("anyTypingInputBlurred")

function addFocusListeners(): void {
    addEventListenerMultiPoly(["focusin", "focusout"], document, (e) => {
        if (e.target instanceof HTMLElement && focusMayTriggerVirtualKeyboard(e.target)) {
            if (e.type === "focusin") {
                anyTypingInputFocused.fire(e)
            } else {
                anyTypingInputBlurred.fire(e)
            }
        }
    })
}

addFocusListeners()
