import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { EventRouter } from "../events"
import { getViewportWidth } from "./viewportDimension"
import type { Params } from "../events"

const TAP_DISTANCE_THRESHOLD = 10 // Maximum distance touch can travel before no longer being considered a tap
const VERTICAL_SWIPE_THRESHOLD = 110 // Minimum vertical distance travelled needed to cover to be considered a vertical swipe
const VERTICAL_SWIPE_PERCENTAGE_THRESHOLD = 0.7 // Percentage used to determine if a touch event is a vertical swipe

export type Point = { x: number, y: number }

export const enum TouchDirection {
    Horizontal,
    Vertical,
    None,
}

export const enum TouchState {
    Start = 0,
    InProgress,
    End,
    Tap,
}

export interface ITouchState {state: TouchState,}

export interface ITouchInfo extends ITouchState {
    direction: TouchDirection,
    distance: Point,
    elapsedTime: number,
    totalDistance: Point,
    startingPoint: Point,
    event: Event,
}

export interface IPinchInfo extends ITouchState {
    centerPoint: Point,
    previousCenterPoint: Point,
    zoomPercentage: number,
}

export const isVerticalSwipe = (totalDistance: number, distY: number): boolean => {
    // return true if swipe is mostly vertical or has traveled a lot in the vertical direction
    return distY >= totalDistance * VERTICAL_SWIPE_PERCENTAGE_THRESHOLD || distY > VERTICAL_SWIPE_THRESHOLD
}

export const isVerticalScroll = (startX: number, startY: number, currX: number, currY: number): boolean => {
    const xDiff = Math.abs(startX - currX)
    const yDiff = Math.abs(startY - currY)

    return yDiff > xDiff
}

export class TouchMoveEvent extends EventRouter<ITouchInfo> {
    constructor(protected element: HTMLElement | Window, options?: Partial<Params>) {
        super("TouchEvent", options)
        const lastPoint: Point = { x: 0, y: 0 }
        const currPoint: Point = { x: 0, y: 0 }
        let startTime: number
        let direction: TouchDirection
        const origPoint: Point = { x: 0, y: 0 }
        let totalDistanceMoved: number
        let isPinch: boolean
        let isTap: boolean
        let hasFiredStartState: boolean

        addEventListenerPoly("touchstart", this.element, (ev: TouchEvent) => {
            lastPoint.x = ev.touches[0].clientX
            lastPoint.y = ev.touches[0].clientY
            origPoint.x = ev.touches[0].clientX
            origPoint.y = ev.touches[0].clientY
            startTime = Date.now()
            totalDistanceMoved = 0
            isPinch = ev.touches.length === 1 ? false : true
            isTap = true
            hasFiredStartState = false
        })

        addEventListenerPoly("touchmove", this.element, (ev: TouchEvent) => {
            if (ev.touches.length === 1 && !isPinch) {
                currPoint.x = ev.touches[0].clientX
                currPoint.y = ev.touches[0].clientY

                const dx = lastPoint.x - currPoint.x
                const dy = currPoint.y - lastPoint.y
                let touchState = TouchState.InProgress
                totalDistanceMoved += Math.abs(dx) + Math.abs(dy)

                direction = isVerticalSwipe(totalDistanceMoved, Math.abs(currPoint.y - origPoint.y)) ? TouchDirection.Vertical : TouchDirection.Horizontal
                isTap = totalDistanceMoved <= TAP_DISTANCE_THRESHOLD
                if (!isTap) {
                    if (!hasFiredStartState) {
                        hasFiredStartState = true
                        touchState = TouchState.Start
                    }
                    this.fire({
                        direction: direction,
                        distance: { x: dx, y: dy },
                        state: touchState,
                        elapsedTime: Date.now() - startTime,
                        totalDistance: { x: origPoint.x - currPoint.x, y: currPoint.y - origPoint.y },
                        startingPoint: origPoint,
                        event: ev,
                    })
                }

                lastPoint.x = currPoint.x
                lastPoint.y = currPoint.y
            }
        })

        addEventListenerPoly("touchend", this.element, (ev: TouchEvent) => {
            if (ev.changedTouches.length === 1 && !isPinch) {
                currPoint.x = ev.changedTouches[0].clientX
                currPoint.y = ev.changedTouches[0].clientY
                const touchState = isTap ? TouchState.Tap : TouchState.End
                this.fire({
                    direction: direction,
                    distance: { x: lastPoint.x - currPoint.x, y: currPoint.y - lastPoint.y },
                    state: touchState,
                    elapsedTime: Date.now() - startTime,
                    totalDistance: { x: origPoint.x - currPoint.x, y: currPoint.y - origPoint.y },
                    startingPoint: origPoint,
                    event: ev,
                })
            }
        })
    }
}

export class PinchEvent extends EventRouter<IPinchInfo> {
    constructor(protected element: HTMLElement, options?: Partial<Params>) {
        super("PinchEvent", options)

        let prevPinchDist: number
        let zoomPercentage = 0
        let prevCenter: Point
        const centerPoint = {
            x: 0,
            y: 0,
        }

        const handlePinch = (x: number, y: number, x2: number, y2: number): number => {
            // Length of line between two points
            const currPinchDist = Math.sqrt((x - x2) * (x - x2) + (y - y2) * (y - y2))
            prevCenter = centerPoint
            centerPoint["x"] = (x + x2) / 2
            centerPoint["y"] = (y + y2) / 2
            let zoomRatio = 0
            if (prevPinchDist !== 0) {
                zoomRatio = (currPinchDist - prevPinchDist) / getViewportWidth()
            }
            prevPinchDist = currPinchDist
            return zoomRatio
        }

        addEventListenerPoly("touchstart", this.element, (ev: TouchEvent) => {
            prevPinchDist = 0
            zoomPercentage = 0
            if (ev.touches.length === 2) {
                centerPoint["x"] = (ev.touches[0].clientX + ev.touches[1].clientX) / 2
                centerPoint["y"] = (ev.touches[0].clientY + ev.touches[1].clientY) / 2

                this.fire({
                    state: TouchState.Start,
                    previousCenterPoint: centerPoint,
                    centerPoint: centerPoint,
                    zoomPercentage: zoomPercentage,
                })
            }
        })

        addEventListenerPoly("touchmove", this.element, (ev: TouchEvent) => {
            if (ev.touches.length === 2) {
                const x = ev.touches[0].clientX
                const y = ev.touches[0].clientY
                const x2 = ev.touches[1].clientX
                const y2 = ev.touches[1].clientY
                zoomPercentage = handlePinch(x, y, x2, y2)
                this.fire({
                    state: TouchState.InProgress,
                    previousCenterPoint: prevCenter,
                    centerPoint: centerPoint,
                    zoomPercentage: zoomPercentage,
                })
            }
        })

        addEventListenerPoly("touchend", this.element, (ev: TouchEvent) => {
            if (ev.changedTouches.length === 2) {
                const x = ev.changedTouches[0].clientX
                const y = ev.changedTouches[0].clientY
                const x2 = ev.changedTouches[1].clientX
                const y2 = ev.changedTouches[1].clientY
                zoomPercentage = handlePinch(x, y, x2, y2)
                this.fire({
                    state: TouchState.End,
                    previousCenterPoint: prevCenter,
                    centerPoint: centerPoint,
                    zoomPercentage: zoomPercentage,
                })
            }
        })
    }
}
