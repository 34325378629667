import { isPortrait } from "@multimediallc/web-utils"
import {
    addEventListenerPoly,
    removeEventListenerPoly,
} from "../common/addEventListenerPolyfill"
import { applyStyles, getCoords } from "../common/DOMutils"
import { screenOrientationChanged } from "../common/mobilelib/windowOrientation"
import { ModalComponent } from "../common/modalComponent"
import { tokenBalanceUpdate } from "./api/tipping"
import { ReactComponentRegistry } from "./components/ReactRegistry"
import { pageContext } from "./interfaces/context"
import { openPurchasePage } from "./ui/tipping"
import type { ReactComponent } from "./components/ReactRegistry"
import type { BoundListener } from "../common/events"

export const TOKEN_SOURCE_PURCHASE_MEDIA = "TokenSourcePurchaseMedia"

export class OneClickFlowPurchase extends ModalComponent {
    private content: HTMLDivElement
    private oneClickFlowInstance: ReactComponent | undefined
    private orientationChangeListener?: BoundListener<void>

    constructor(source: string, roomType?: string) {
        super()
        applyStyles(this.element, {
            width: "100%",
            position: "fixed",
            minHeight: "250px",
            maxHeight: "100%",
            height: "unset",
            overflowY: "auto",
            transition: "bottom 500ms",
            zIndex: 1006,
            bottom: "-400px",
            borderRadius: "8px 8px 0px 0px",
            backgroundColor: "#F8F9FA",
            touchAction: "none",
        })
        applyStyles(this.overlay, {
            zIndex: 1005,
            background: "rgba(0, 0, 0, 0.5)",
            touchAction: "none",
        })

        this.content = document.createElement("div")
        this.content.classList.add("oneclickflowMobile")
        this.element.appendChild(this.content)
        const closeButton = document.createElement("div")
        closeButton.onclick = () => this.hide()

        const closeButtonStyles =
            source === TOKEN_SOURCE_PURCHASE_MEDIA
                ? {
                    height: "14.6px",
                    width: "14.6px",
                    right: "25px",
                    top: "20px",
                }
                : {
                    height: "12.73px",
                    width: "12.73px",
                    right: "15px",
                    top: "10px",
                }
        applyStyles(closeButton, {
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            ...closeButtonStyles,
        })

        const createClosebuttonSlashes = (
            transformation: string,
        ): HTMLElement => {
            const slash = document.createElement("div")
            slash.classList.add("oneclickflow__closeButtonSlash")
            const slashStyles =
                source === TOKEN_SOURCE_PURCHASE_MEDIA
                    ? {
                        width: "4.5px",
                        height: "22px",
                        left: "10px",
                    }
                    : {
                        width: "3px",
                        height: "15px",
                        left: "5px",
                    }
            applyStyles(slash, {
                position: "absolute",
                transform: transformation,
                ...slashStyles,
            })
            return slash
        }
        closeButton.appendChild(createClosebuttonSlashes("rotate(-135deg)"))
        closeButton.appendChild(createClosebuttonSlashes("rotate(-45deg)"))
        this.element.appendChild(closeButton)
        this.show(source, roomType)
    }

    public show(source?: string, roomType?: string): void {
        this.orientationChangeListener = screenOrientationChanged.once(() => {
            this.hide()
        }, false)
        const OneClickFlow = ReactComponentRegistry.get("OneClickFlow")
        this.oneClickFlowInstance = new OneClickFlow(
            {
                source: source ?? "",
                roomType: roomType ?? "public",
                closeModal: () => this.hide(),
                openPurchasePage: () => {
                    openPurchasePage(source ?? "")
                    this.hide()
                },
            },
            this.content,
        )
        super.show()

        if (
            source !== undefined &&
            source === TOKEN_SOURCE_PURCHASE_MEDIA &&
            !isPortrait()
        ) {
            window.setTimeout(() => {
                this.element.style.bottom = "-60px"
            }, 1)
        } else {
            window.setTimeout(() => {
                this.element.style.bottom = "0"
            }, 1)
        }
    }

    public hide(): void {
        this.element.style.bottom = "-400px"
        this.orientationChangeListener?.removeListener()
        window.setTimeout(() => {
            super.hide()
            this.oneClickFlowInstance?.dispose()
        }, 1)
    }
}

const ONECLICK_WIDTH = 313
const ONE_CLICK_HEIGHT = 220
const ONE_CLICK_MARGIN = 10

export class OneClickFlowPurchaseDesktop extends ModalComponent {
    private content: HTMLDivElement
    private oneClickFlowInstance: ReactComponent | undefined
    private isOpen = false
    private tokenBalance: number =
        pageContext?.current?.loggedInUser?.tokenBalance ?? 0
    private outSideHandlerClick = (event: MouseEvent) => {
        if (
            event.target !== this.element ||
            !this.element.contains(event.target as Node)
        ) {
            this.hide()
        }
    }
    constructor(source: string, roomType?: string, target?: HTMLElement) {
        super()

        applyStyles(this.element, {
            width: `${ONECLICK_WIDTH}px`,
            position: "absolute",
            minHeight: "218px",
            height: "unset",
            overflowY: "auto",
            zIndex: 1006,
            borderRadius: "8px",
        })

        this.content = document.createElement("div")
        this.content.classList.add("oneclickflowDesktop")
        this.element.appendChild(this.content)
        const closeButton = this.createCloseButton()
        this.element.appendChild(closeButton)
        this.show(source, roomType, target)

        tokenBalanceUpdate.listen((balance) => {
            this.tokenBalance = balance?.tokens ?? 0
            if (this.oneClickFlowInstance !== undefined) {
                this.oneClickFlowInstance?.update({ tokenBalance: this.tokenBalance })
            }
        })
    }

    private createCloseButton = (): HTMLDivElement => {
        const closeButton = document.createElement("div")
        closeButton.onclick = () => this.hide()
        applyStyles(closeButton, {
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            height: "12.73px",
            width: "12.73px",
            right: "15px",
            top: "10px",
        })

        const createClosebuttonSlashes = (
            transformation: string,
        ): HTMLElement => {
            const slash = document.createElement("div")
            slash.classList.add("oneclickflow__closeButtonSlash")
            applyStyles(slash, {
                position: "absolute",
                transform: transformation,
                width: "3px",
                height: "15px",
                left: "5px",
            })
            return slash
        }

        closeButton.appendChild(createClosebuttonSlashes("rotate(-135deg)"))
        closeButton.appendChild(createClosebuttonSlashes("rotate(-45deg)"))
        return closeButton
    }

    private overrideBasedOnSource = (source?: string): void => {
        const ONECLICK_POSITION_PER_SOURCE_DESKTOP: Record<
            string,
            CSSX.Properties
        > = {
            TokenSourceUserInfoPanel: {
                top: "100px",
                right: "1%",
            },
        }
        const sourcePresent = source !== null && source !== undefined
        const overrideDefaultPositionStyles =
            sourcePresent &&
            ONECLICK_POSITION_PER_SOURCE_DESKTOP.hasOwnProperty(source) &&
            ONECLICK_POSITION_PER_SOURCE_DESKTOP[source]

        const topOffset = document?.body?.getBoundingClientRect()?.top ?? 0
        // we can pre-define the position of the one-click flow per each source
        if (overrideDefaultPositionStyles !== false) {
            applyStyles(this.element, {
                transform: "none",
                top: "unset",
                right: "unset",
                left: "unset",
                ...overrideDefaultPositionStyles,
            })
        } else {
            applyStyles(this.element, {
                left: "unset",
                top: `calc(50% - ${topOffset}px)`,
                right: "50%",
                transform: "translate(50%, -50%)",
            })
        }
    }

    private overrideBasedOnTarget(targetCoords: DOMRect) {
        const isNotEnoughWidth =
            window.innerWidth - (targetCoords.x + targetCoords.width) <
            ONECLICK_WIDTH + ONE_CLICK_MARGIN
        const isNotEnoughHeight =
            window.innerHeight -
                (targetCoords.y + targetCoords.height) +
                window.scrollY <
            ONE_CLICK_HEIGHT + ONE_CLICK_MARGIN
        applyStyles(this.element, {
            right: "unset",
            transform: "unset",
            top: isNotEnoughHeight
                ? `${
                    window.innerHeight -
                      ONE_CLICK_HEIGHT -
                      ONE_CLICK_MARGIN +
                      window.scrollY
                }px`
                : `${targetCoords.y}px`,
            left: isNotEnoughWidth
                ? `${window.innerWidth - ONECLICK_WIDTH - ONE_CLICK_MARGIN}px`
                : `${targetCoords.right}px`,
        })
    }

    private applyPositionOverrides(
        target: HTMLElement | undefined,
        source: string | undefined,
    ) {
        const targetCoords =
            target !== undefined ? getCoords(target) : undefined

        if (targetCoords) {
            this.overrideBasedOnTarget(targetCoords)
        } else {
            this.overrideBasedOnSource(source)
        }
    }
    private applyDarkModeStyles = (): void => {
        const isDarkMode = document.body.classList.contains("darkmode")
        applyStyles(this.element, {
            backgroundColor: isDarkMode ? "#202c39" : "#F8F9FA",
            boxShadow: isDarkMode
                ? "0 0 12px rgba(0,0,0,0.8)"
                : "0px 3px 10px 0px rgba(0, 0, 0, 0.10)",
            border: `1px solid  ${isDarkMode ? "#17202a" : "#ACACAC"}`,
        })
    }

    public show(
        source?: string,
        roomType?: string,
        target?: HTMLElement,
    ): void {
        if (this.isOpen) {
            this.hide()
            return
        }
        this.isOpen = true
        this.applyDarkModeStyles()
        this.applyPositionOverrides(target, source)

        const OneClickFlow = ReactComponentRegistry.get("OneClickFlow")
        this.oneClickFlowInstance = new OneClickFlow(
            {
                source: source ?? "",
                roomType: roomType ?? "public",
                desktop: true,
                tokenBalance: this.tokenBalance,
                openPurchasePage: () => {
                    openPurchasePage(source ?? "")
                    this.hide()
                },
            },
            this.content,
        )
        super.show()
        applyStyles(this.overlay, { display: "none" })
        // without setTimeout, the click event will be triggered immediately
        window.setTimeout(
            () =>
                addEventListenerPoly(
                    "click",
                    document,
                    this.outSideHandlerClick,
                ),
            10,
        )
    }

    public hide(): void {
        super.hide()
        this.oneClickFlowInstance?.dispose()
        this.oneClickFlowInstance = undefined
        removeEventListenerPoly("click", document, this.outSideHandlerClick)
        this.isOpen = false
    }
}

export class PurchasePageDesktopWrapper extends ModalComponent {
    private content: HTMLDivElement
    private purchasePageInstance: ReactComponent | undefined
    private isOpen = false

    constructor(source: string) {
        super()

        applyStyles(this.element, {
            position: "absolute",
            height: "unset",
            width: "unset",
            overflowY: "auto",
            zIndex: 1101,
            borderRadius: "4px",
            top: "13%",
            right: "16px",
            background: "#fff",
        })
        applyStyles(this.overlay, { background: "rgba(0, 0, 0, 0.5)" })
        this.element.classList.add("purchasePage-main")
        this.content = document.createElement("div")
        this.content.classList.add("purchasePageWrapper")
        this.element.appendChild(this.content)
        const closeButton = this.createCloseButton()
        this.element.appendChild(closeButton)
        this.show(source)
    }

    private createCloseButton = (): HTMLDivElement => {
        const closeButton = document.createElement("div")
        closeButton.onclick = () => this.hide()
        applyStyles(closeButton, {
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            height: "16px",
            width: "16px",
            right: "16px",
            top: "22px",
        })

        const createClosebuttonSlashes = (
            transformation: string,
        ): HTMLElement => {
            const slash = document.createElement("div")
            slash.classList.add("cb-purchase-page__closeButtonSlash")
            applyStyles(slash, {
                position: "absolute",
                transform: transformation,
                width: "2px",
                height: "16px",
                left: "5px",
            })
            return slash
        }

        closeButton.appendChild(createClosebuttonSlashes("rotate(-135deg)"))
        closeButton.appendChild(createClosebuttonSlashes("rotate(-45deg)"))
        return closeButton
    }

    public show(source?: string): void {
        if (this.isOpen) {
            this.hide()
            return
        }
        this.isOpen = true

        const PurchasePage = ReactComponentRegistry.get("PurchasePage")
        this.purchasePageInstance = new PurchasePage(
            {
                source: source ?? "",
                openPurchasePage: () => {
                    openPurchasePage(source ?? "")
                    this.hide()
                },
                closeModal: () => this.hide(),
                desktop: true,
            },
            this.content,
        )
        super.show()
    }

    public hide(): void {
        super.hide()
        this.purchasePageInstance?.dispose()
        this.purchasePageInstance = undefined
        this.isOpen = false
    }
}

export class MobilePurchasePageWrapper extends ModalComponent {
    private content: HTMLDivElement
    private purchasePageInstance: ReactComponent | undefined
    private orientationChangeListener?: BoundListener<void>

    constructor(source: string, roomType?: string) {
        super()
        applyStyles(this.element, {
            width: "100%",
            position: "fixed",
            minHeight: "250px",
            height: "unset",
            overflowY: "auto",
            transition: "bottom 500ms",
            zIndex: 1006,
            bottom: "-400px",
            borderRadius: "8px 8px 0px 0px",
            backgroundColor: "#fff",
            touchAction: "none",
        })
        applyStyles(this.overlay, {
            zIndex: 1005,
            background: "rgba(0, 0, 0, 0.5)",
            touchAction: "none",
        })

        this.content = document.createElement("div")
        this.content.classList.add("purchasePageWrapper-mobile")
        this.element.appendChild(this.content)
        const closeButton = document.createElement("div")
        closeButton.onclick = () => this.hide()

        const closeButtonStyles =
            source === TOKEN_SOURCE_PURCHASE_MEDIA
                ? {
                    height: "14.6px",
                    width: "14.6px",
                    right: "25px",
                    top: "20px",
                }
                : {
                    height: "12.73px",
                    width: "12.73px",
                    right: "16px",
                    top: "22px",
                }
        applyStyles(closeButton, {
            display: "inline-block",
            cursor: "pointer",
            position: "absolute",
            ...closeButtonStyles,
        })

        const createClosebuttonSlashes = (
            transformation: string,
        ): HTMLElement => {
            const slash = document.createElement("div")
            slash.classList.add("cb-purchase-page__closeButtonSlash")
            const slashStyles =
                source === TOKEN_SOURCE_PURCHASE_MEDIA
                    ? {
                        width: "4.5px",
                        height: "22px",
                        left: "10px",
                    }
                    : {
                        width: "2px",
                        height: "16px",
                        left: "5px",
                    }
            applyStyles(slash, {
                position: "absolute",
                transform: transformation,
                ...slashStyles,
            })
            return slash
        }
        closeButton.appendChild(createClosebuttonSlashes("rotate(-135deg)"))
        closeButton.appendChild(createClosebuttonSlashes("rotate(-45deg)"))
        this.element.appendChild(closeButton)
        this.show(source, roomType)
    }

    public show(source?: string, roomType?: string): void {
        this.orientationChangeListener = screenOrientationChanged.once(() => {
            this.hide()
        }, false)
        const PurchasePage = ReactComponentRegistry.get("PurchasePage")
        this.purchasePageInstance = new PurchasePage(
            {
                source: source ?? "",
                openPurchasePage: () => {
                    openPurchasePage(source ?? "")
                    this.hide()
                },
                closeModal: () => this.hide(),
                desktop: false,
            },
            this.content,
        )
        super.show()

        if (
            source !== undefined &&
            source === TOKEN_SOURCE_PURCHASE_MEDIA &&
            !isPortrait()
        ) {
            window.setTimeout(() => {
                this.element.style.bottom = "-60px"
            }, 1)
        } else {
            window.setTimeout(() => {
                this.element.style.bottom = "0"
            }, 1)
        }
    }

    public hide(): void {
        this.element.style.bottom = "-400px"
        this.orientationChangeListener?.removeListener()
        window.setTimeout(() => {
            super.hide()
            this.purchasePageInstance?.dispose()
        }, 1)
    }
}
