import { isAnonymous } from "../../../common/auth"
import { Component } from "../../../common/defui/component"
import { isReactDMActive } from "../../../common/featureFlagUtil"
import { addPageAction } from "../../../common/newrelic"
import { dom } from "../../../common/tsxrender/dom"
import { UserMessageTopic, UserTokenUpdateTopic } from "../../pushservicelib/topics/user"
import { ConversationListData } from "../pm/conversationListData"
import { DMUnreadData } from "../pm/dmUnreadData"
import { ReactComponentRegistry } from "../ReactRegistry"
import type { ReactComponent } from "../ReactRegistry"

export class MobileMessagingIcon extends Component<HTMLDivElement> {
    private messagingComponent?: ReactComponent
    private messagingElement?: HTMLDivElement
    private unreadBadgeComponent?: ReactComponent
    private unreadBadgeDiv: HTMLDivElement
    private hamburgerUnreadBadgeComponent?: ReactComponent
    private hamburgerUnreadBadgeDiv: HTMLDivElement

    constructor() {
        super("div")
    }

    protected initData(): void {
        if (isReactDMActive() && !isAnonymous()) {
            ConversationListData.getInstance()
        }
    }

    protected initUI(): void {
        const styles: Record<string, CSSX.Properties> = {
            container: {
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
                height: "100%",
                position: "relative",
                width: "100%",
                pointerEvents: "auto",
            },
            imgContainer: {
                position: "relative",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
            },
            img: {
                width: "20px",
                height: "20px",
            },
            hamburgerBadgeContainer: {
                position: "absolute",
                top: "11px",
                right: "2px",
            },
        }

        this.messagingElement = document.createElement("div")
        this.unreadBadgeDiv = document.createElement("div")
        this.hamburgerUnreadBadgeDiv = <div style={styles.hamburgerBadgeContainer} />

        this.element = 
            <div 
                onClick={() => this.handleClick()}
                style={styles.container}
                data-testid="mobile-messaging-icon"
            >
                <div style={styles.imgContainer}>
                    <img 
                        src="/static/mobileassets/messaging.svg"
                        style={styles.img}
                        alt="Messages"
                        data-testid="messaging-icon-img"
                    />
                    {this.unreadBadgeDiv}
                </div>
            </div>

        const hamburgerIcon = document.querySelector("#mmnav")
        if (hamburgerIcon !== null) {
            hamburgerIcon.appendChild(this.hamburgerUnreadBadgeDiv)
        }

        this.bindUnreadEvents()
    }

    private handleHamburgerUnreadCountUpdate(unread: number): void {
        const hamburgerIcon = document.querySelector("#mmnav")
        const userUpdatesBell = document.querySelector("#userUpdatesBellRoot")
        const hasUserUpdates = userUpdatesBell?.classList.contains("has_unseen") ?? false

        if (unread > 0) {
            this.updateHamburgerBadge(unread)
            hamburgerIcon?.classList.add("has_unseen")
        } else {
            this.updateHamburgerBadge(0)
            if (!hasUserUpdates) {
                hamburgerIcon?.classList.remove("has_unseen")
            }
        }
    }

    private updateHamburgerBadge(count: number): void {
        if (!this.hamburgerUnreadBadgeComponent) {
            const UnreadBadgeClass = ReactComponentRegistry.get("UnreadBadge")
            this.hamburgerUnreadBadgeComponent = new UnreadBadgeClass({ count }, this.hamburgerUnreadBadgeDiv)
        } else {
            this.hamburgerUnreadBadgeComponent.update({ count })
        }
    }

    private updateUnreadBadge(unread: number): void {
        if (!this.unreadBadgeComponent) {
            const UnreadBadgeClass = ReactComponentRegistry.get("UnreadBadge")
            this.unreadBadgeComponent = new UnreadBadgeClass({ count: unread }, this.unreadBadgeDiv)
        } else {
            this.unreadBadgeComponent.update({ count: unread })
        }

        this.handleHamburgerUnreadCountUpdate(unread)
    }

    private bindUnreadEvents(): void {
        if(!isReactDMActive() || isAnonymous()) {
            return
        }
        DMUnreadData.unreadDataUpdated.listen(({ unread }) => {
            this.updateUnreadBadge(unread)
        })
        this.updateUnreadBadge(DMUnreadData.getInstance().getUnreadCount())
    }

    private handleClick(): void {
        if(!isReactDMActive() || isAnonymous()) {
            return
        }
        this.handleAuthenticatedClick()
    }

    private handleAuthenticatedClick(): void {
        if (!isAnonymous() && !this.messagingComponent && this.messagingElement) {
            const MessagingComponent = ReactComponentRegistry.get("Messaging")
            const topics = { UserMessageTopic, UserTokenUpdateTopic }
            this.messagingComponent = new MessagingComponent({ 
                topics,
                interfaces: { dmUnreadData: DMUnreadData.getInstance() },
                onClose: this.handleClose,
            }, this.messagingElement)
            document.body.appendChild(this.messagingElement)
        } else {
            this.handleShow()
        }
    }

    protected handleShow = (): void => {
        if (this.messagingElement) {
            this.messagingElement.style.display = "block"
            addPageAction("DMListOpened", { "source": "mobile" })
        }
    }

    protected handleClose = (): void => {
        if (this.messagingElement) {
            this.messagingElement.style.display = "none"
        }
    }
} 