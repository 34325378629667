import { Component } from "../defui/component"
import { dom } from "../tsxrender/dom"
import type { TabName } from "./tabList"

export interface IBaseRoomTabProps {
    tabName: TabName
    tabLabel: string
    pageActionName: string
}

export abstract class BaseRoomTab extends Component<HTMLDivElement, IBaseRoomTabProps> {
    private tabName: TabName
    private tabLabel: string
    private pageActionName: string

    constructor(props: IBaseRoomTabProps) {
        super("div", props)
    }

    protected initData(props: IBaseRoomTabProps): void {
        this.tabName = props.tabName
        this.tabLabel = props.tabLabel
        this.pageActionName = props.pageActionName
    }

    protected initUI(props?: IBaseRoomTabProps): void {
        const containerStyle: CSSX.Properties = {
            width: "100%",
            height: "100%",
            flex: "none",
            backgroundColor: "#FFF",
            boxSizing: "border-box",
        }

        this.element = <div style={containerStyle} />
    }

    public getTabName(): TabName {
        return this.tabName
    }

    public getTabLabel(): string {
        return this.tabLabel
    }

    public getPageActionName(): string {
        return this.pageActionName
    }
}
