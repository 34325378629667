import { ArgJSONMap } from "@multimediallc/web-utils"
import { Debouncer, DebounceTypes } from "../../common/debouncer"
import { SubSystemType } from "../../common/debug"
import { EventRouter } from "../../common/events"
import { addPageAction } from "../../common/newrelic"
import { allSettledPromises } from "../../common/promiseUtils"
import { pageContext } from "../interfaces/context"
import { ABLY_CLIENT_NAME, AblyContext, AblyPushServiceClient } from "./ablyClient"
import { ReportedActions } from "./baseClient"
import { HERMOD_CLIENT_NAME, HermodClient } from "./cbpush/hermod"
import { HermodContext } from "./cbpush/hermodContext"
import { PUSHER_CLIENT_NAME, PusherClient, PusherContext } from "./pusherClient"
import { ConnectionState } from "./states"
import { TopicManager } from "./topics/topicManager"
import { PushClientVerifier } from "./verifier"
import type { IClientCallbacks, IPushContextSettings, IPushServiceClient } from "./baseClient"
import type { IConnectionStateChange, IErrorInfo } from "./states"
import type { ITopicAuthData } from "./topics/topicManager"
import type { ISettledPromise } from "../../common/promiseUtils"

const WOWZA_SERVICE_NAME = "w"

class ClientManager {
    private verifier: PushClientVerifier
    private contexts: IPushContextSettings[]
    private clients = new Map<string, IPushServiceClient>()
    private lastConnection: number | undefined
    private resetting = false
    private counter = 0
    constructor(originalContext: ArgJSONMap[]) {
        this.contexts = this.parseContext(originalContext)
        const initializeAllClients = () => {
            this.contexts.forEach(c => {
                const clientToClose = this.clients.get(c.backend)
                if (clientToClose !== undefined) {
                    clientToClose.close()
                }
                if (c.backend === WOWZA_SERVICE_NAME) {
                    return
                }
                if (c.backend === PushService.clientToVerify){
                    c.verifyEnabled = true
                }
                const client = this.initializeClient(c)
                if (client === undefined) {
                    warn("client not initialized", { "client": c.backend }, SubSystemType.PushService)
                    return
                }
                this.clients.set(c.backend, client)
                client.connectionChange.listen(event => {
                    this.verifier.recordDisconnect(client.clientName)
                    event.primary = this.isPrimaryClient(client)
                    debug("PushClient connection change", { client: client.clientName, event: event })
                    if ([ConnectionState.failed, ConnectionState.closed, ConnectionState.disconnected].includes(event.current) && !this.resetting) {
                        if (event.primary) {
                            this.invalidateClient(client.clientName)
                            this.counter += 1
                            this.resetting = true
                            window.setTimeout(() => {
                                initializeAllClients()
                                PushService.updateAuthorization()
                                this.resetting = false
                                this.informReconnect(client.clientName, this.getPrimaryClient(), this.counter)
                            }, 3000)
                        }
                    }
                    PushService.connectionChange.fire(event)
                    if (ConnectionState.connected === event.current) {
                        this.lastConnection = Date.now()
                        PushService.updateAuthorization()
                        this.verifier.validateClient(client.clientName)
                    }
                })
            })
        }
        initializeAllClients()
        this.verifier = new PushClientVerifier(this.contexts.map(c => c.backend))
    }

    public getFlag(flag: string): boolean {
        const primaryContext = this.contexts.find(c => c.backend !== WOWZA_SERVICE_NAME)
        if (primaryContext === undefined) {
            warn("getting flag without any valid context", {}, SubSystemType.PushService)
        }
        return primaryContext?.flags.get(flag) ?? false
    }

    public setVerified(clientToVerify: string | undefined): void {
        if (clientToVerify !== undefined){
            this.contexts.forEach(c => {
                if (c.backend === clientToVerify) {
                    c.flags.set("verify_enabled", true)
                }
            })
        }
    }

    public isWowzaPrimary(): boolean {
        if (this.contexts.length < 1) {
            return true
        }
        return this.contexts[0].backend === WOWZA_SERVICE_NAME
    }

    public isPrimaryClient(client: IPushServiceClient): boolean {
        const primaryContext = this.contexts.find(c => {
            return c.backend !== WOWZA_SERVICE_NAME &&  c.flags.get("is_live") === true
        })
        return primaryContext?.backend === client.clientName
    }

    public getPrimaryClient(): IPushServiceClient | undefined {
        const primaryContext = this.contexts.find(c => {
            return c.backend !== WOWZA_SERVICE_NAME
        })
        const errorInfo = {
            "contexts": Array.from(this.contexts.keys()),
            "clients_keys": Array.from(this.clients.keys()),
            "clients": Array.from(this.clients.values()).map(c => c.clientName),
        }
        if (primaryContext === undefined) {
            warn("no valid push service context", errorInfo, SubSystemType.PushService)
            return
        }
        // error is reported by callee
        return this.clients.get(primaryContext.backend)
    }

    public getActiveClients(): IPushServiceClient[] {
        return Array.from(this.clients.values()).filter(client => {
            const context = this.contexts.find(c => c.backend === client.clientName)
            return this.isPrimaryClient(client) ||
                    context?.verifyEnabled === true ||
                    context?.flags.get("is_live") === true
        })
    }

    public updateOrder(backends: string[]): void {
        if (backends.length !== this.contexts.length) {
            warn("invalid chat backends", { "backends": backends }, SubSystemType.PushService)
        }
        // remove any backends that might not exist in new order
        debug("Changing push service backends", backends)
        this.contexts = this.contexts.filter(s => {
            return backends.includes(s.backend)
        }).sort((a, b) => {
            return backends.indexOf(a.backend) - backends.indexOf(b.backend)
        })
    }

    private initializeClient(context: IPushContextSettings): IPushServiceClient | undefined {
        const isPrimary = (clientName: string) => this.contexts.find(c => c.backend !== WOWZA_SERVICE_NAME)?.backend === clientName
        const callbacks: IClientCallbacks = {
            onMessage: (clientName: string, topicKey: string, data: ArgJSONMap) => {
                this.verifier.verify(clientName, topicKey, data)
                if (isPrimary(clientName)) {
                    TopicManager.fireMessage(topicKey, data)
                }
            },
            onSubscribe: (clientName: string, topicKey: string, success: boolean) => {
                if (isPrimary(clientName)) {
                    TopicManager.fireSubscribeChange(topicKey, {
                        subscribed: success,
                        isCriticalFail: false,
                    })
                }
            },
        }
        try {
            switch (context.backend) {
                case ABLY_CLIENT_NAME:
                    return new AblyPushServiceClient(callbacks, context, PushService.isBroadcaster)
                case PUSHER_CLIENT_NAME:
                    return new PusherClient(callbacks, context)
                case HERMOD_CLIENT_NAME:
                    return new HermodClient(callbacks)
            }
        } catch (e) {
            if (PushService.getClientName() === context.backend){
                PushService.primaryClientFailure.fire("client failure")
            }
            error("An exception was thrown during client construction", e, SubSystemType.PushService)
        }
        return
    }

    private parseContext(context: ArgJSONMap[]): IPushContextSettings[] {
        const wowzaService = { backend: WOWZA_SERVICE_NAME, flags: new Map() , verifyEnabled: false }
        const services: IPushContextSettings[] = []
        for(const service of context) {
            const backendKey = service.getStringOrUndefined("backend")
            switch (backendKey) {
                case ABLY_CLIENT_NAME:
                    services.push(AblyContext.parseSettings(service))
                    break
                case HERMOD_CLIENT_NAME:
                    services.push(HermodContext.parseSettings(service))
                    break
                case PUSHER_CLIENT_NAME:
                    services.push(PusherContext.parseSettings(service))
                    break
                case WOWZA_SERVICE_NAME:
                    services.push(wowzaService)
                    break
                default:
                    warn("unrecognized backend during parseContext", { "failed_context": service }, SubSystemType.PushService)
            }
        }

        if (services.length === 1) {
            // if first context is push, make sure fallback_eligible is true
            if (services[0].flags.get("is_live") !== true) {
                warn("first context is not is_live", { "context": services[0] }, SubSystemType.PushService)
                services[0].flags.set("is_live", true)
            }
        }

        return services
    }

    private invalidateClient(clientName: string): void {
        for (const [_, clientToClose] of this.clients){
            clientToClose.close()
        }
        this.orderLast(clientName)
        let counter = 0
        while (this.contexts[0].flags.get("is_live") !== true && counter < 3) {
            this.orderLast(this.contexts[0].backend)
            counter += 1
        }
    }

    public orderLast(clientName: string): void {
        this.updateOrder(this.contexts.map(s => s.backend)
            .filter(s => s !== clientName)
            .concat([clientName]),
        )
    }

    public setVerifierRoomCount(roomCount: number): void {
        this.verifier.setRoomCount(roomCount)
    }

    private informReconnect(oldClient: string, newClient: IPushServiceClient | undefined, counter: number): void {
        window.setTimeout(() => {
            const nrEvent = {
                "failedClient": oldClient,
                "lastConnection": this.lastConnection,
                "newClient": newClient?.clientName,
                "success": newClient?.isConnected(),
                "connectionAttempt": counter,
            }
            addPageAction("PushServiceReconnect", nrEvent)
        }, 3000)
    }
}

export class PushService {
    private static clientManager?: ClientManager
    private static pushReconnectTimeout: number
    private static pushReconnectTimer = 5000
    public static isBroadcaster = false
    public static readonly connectionChange = new EventRouter<IConnectionStateChange>("PushServiceClientConnection")
    public static readonly primaryClientFailure = new EventRouter<string>("PushServicePrimaryClientFailure")
    private static servicesContext: ArgJSONMap[] | undefined
    private static updateAuthThrottler = new Debouncer(() => {
        PushService._updateAuthorizationAndSubscriptions()
    }, {
        bounceLimitMS: 800,
        debounceType: DebounceTypes.debounce,
    })
    public static presenceId = Math.random().toString(36).substring(2)
    public static clientToVerify: string | undefined

    public static initialize(pageContext: object): void {
        const contextObj = new ArgJSONMap(pageContext)
        PushService.servicesContext = contextObj.getList("push_services")
        PushService.presenceId = contextObj.getStringOrUndefined("presence_id") ?? PushService.presenceId
        PushService.clientToVerify = contextObj.getStringOrUndefined("push_service_verify")

        if (PushService.servicesContext === undefined) {
            error("PushService parseContext failed", { "context_keys": contextObj.keys() }, SubSystemType.PushService)
            return
        }
        PushService.isBroadcaster = contextObj.getAny("broadcastDossier") !== undefined
        PushService.clientManager = new ClientManager(PushService.servicesContext)
        PushService.clientManager.setVerified(PushService.clientToVerify)
    }

    public static isConnected(): boolean {
        const client = PushService.getClient()
        return client !== undefined && client.isConnected()
    }

    public static isEnabledForVerify(): boolean {
        // only anon users not in split test will ever disable verifiers
        return (
            PushService.isEnabledForUI() ||
            pageContext.current.loggedInUser !== undefined
        )
    }

    public static isEnabledForUI(): boolean {
        if (PushService.clientManager === undefined) {
            return false
        }
        return !PushService.clientManager.isWowzaPrimary()
    }

    public static isEnabledForUserList(): boolean {
        return PushService.isConnected()
    }

    public static changeChatHandler(backends: string[]): void {
        if (PushService.clientManager === undefined) {
            error("attempting to change backends on not ready push service", { "backends": backends }, SubSystemType.PushService)
            return
        }
        PushService.clientManager.updateOrder(backends)
    }

    public static updateAuthorization(): void {
        PushService.updateAuthThrottler.callFunc()
    }

    public static forceUpdateAuthorization(): void {
        const clients = PushService.clientManager?.getActiveClients() ?? []
        clients.forEach(client => {
            client.auth.invalidateAuth()
        })
        this.updateAuthorization()
    }

    private static authId = 0
    private static _updateAuthorizationAndSubscriptions(): void {
        const clients = PushService.clientManager?.getActiveClients() ?? []
        if (clients.length < 1) {
            TopicManager.getTopicKeys().forEach(t => {
                TopicManager.fireSubscribeChange(t, {
                    subscribed: false,
                    isCriticalFail: true,
                })
            })
            return
        }

        PushService.authId += 1
        const currentAuthId = PushService.authId
        window.clearTimeout(PushService.pushReconnectTimeout)

        const currentTopics = PushService.getTopicKeysToAuth()
        const authStart = new Date().getTime()

        clients.forEach(client => {
            const isPrimary = PushService.isPrimaryClient(client)
            client.getAuthPromise(currentTopics).then(() => {
                // when auth succeeds, reset to 5s
                PushService.pushReconnectTimer = 5000
                if (currentAuthId < PushService.authId) {
                    return
                }
                if (isPrimary) {
                    client.context?.getFailedTopics().forEach(t => TopicManager.fireAuthFail(t))
                }
                let wasClosedDuringSubscription = false
                const pushServiceCloseListener = client.connectionChange.listen(event => {
                    if (event.current === ConnectionState.closed || event.current === ConnectionState.disconnected) {
                        wasClosedDuringSubscription = true
                    }
                }, false)
                const start = new Date().getTime()
                PushService.subscribeToEligibleTopics(client).then((results) => {
                    const end = new Date().getTime()
                    pushServiceCloseListener.removeListener()
                    if (currentAuthId < PushService.authId) {
                        return
                    }

                    const successCount = results.filter((promise) => promise.status === "fulfilled").length
                    const failureList = client.auth.getTopicKeys().filter(t => !client.isSubscribedTo(t))
                    addPageAction("PushServiceTopicSetup", {
                        "client": client.clientName,
                        "all_topics_success": failureList.length === 0,
                        "topics_requested_count": results.length,
                        "successful_topic_count": successCount,
                        "failed_topic_list": failureList.toSorted().toString(),
                        "is_push_connected": PushService.isConnected(),
                        "elapsed_time": end - start,
                        "elapsed_time_auth": end - authStart,
                    })
                    if (client.shouldRetrySubscribe() && !wasClosedDuringSubscription) {
                        // subscribe fail retry is always 10s, only backoff on auth fails
                        PushService.pushReconnectTimeout = window.setTimeout(() => {
                            PushService.updateAuthorization()
                        }, 10000)
                    }
                    if (isPrimary) {
                        const criticalTopics = client.getCriticallyFailedTopics()
                        failureList.forEach((topicKey) => {
                            TopicManager.fireSubscribeChange(topicKey, {
                                subscribed: false,
                                isCriticalFail: wasClosedDuringSubscription || criticalTopics.includes(topicKey),
                                retryCallback: wasClosedDuringSubscription ? undefined : () => {PushService.updateAuthorization()},
                            })
                        })
                    }
                }).catch(() => {}) // subscribeToEligibleTopics cannot throw
            }).catch((err: IErrorInfo) => {
                if (currentAuthId < PushService.authId) {
                    return
                }
                // only do reporting for primary client
                if (!isPrimary) {
                    return
                }
                PushService.primaryClientFailure.fire("auth failure")
                PushService.pushReconnectTimeout = window.setTimeout(() => {
                    PushService.updateAuthorization()
                }, PushService.pushReconnectTimer)
                PushService.pushReconnectTimer *= 2

                const failedTopics = currentTopics.filter(topic => !PushService.isListeningFor(topic))
                failedTopics.forEach((topicKey) => {
                    TopicManager.fireAuthFail(topicKey)
                })
                // ignore throttle errors entirely
                if (!err.message.includes("access denied")) {
                    client.addPageAction(ReportedActions.auth_failure, {
                        "client_id": client.getClientId(),
                        "user_uid": pageContext.current.loggedInUser?.userUid ?? "anon",
                        "message": err.message,
                        "provider_status": err.providerCode,
                        "cause": err.cause,
                        "code": err.code,
                    })
                }
            })
        })
    }

    public static getTopicKeysToAuth(): string[] {
        return TopicManager.getTopicKeys().concat(TopicManager.getPendingTopics())
    }

    public static getTopicAuthData(topicKey: string): ITopicAuthData | undefined {
        const topicData = TopicManager.getTopicAuthData(topicKey)
        if (topicData === undefined) {
            warn("undefined topic data", { "topic": topicKey }, SubSystemType.PushService)
        }
        return topicData
    }

    private static getClient(): IPushServiceClient | undefined {
        const primaryClient = PushService.clientManager?.getPrimaryClient()
        if (primaryClient === undefined) {
            error("No valid push service client", {}, SubSystemType.PushService)
        }
        return primaryClient
    }

    private static listenFor(client: IPushServiceClient, topicKey: string): Promise<void> {
        if (client.isSubscribedTo(topicKey)) {
            return Promise.resolve()
        }
        return client.subscribe(topicKey).catch((err: IErrorInfo) => {
            client.addPageAction(ReportedActions.subscribe_failure, {
                "is_primary": PushService.isPrimaryClient(client),
                "topic": topicKey,
                "auth": client.auth.serialize(),
                "code": err.code,
                "message": err.message,
                "providerCode": err.providerCode,
            })
        })
    }

    public static stopListeningFor(topicKey: string): Promise<void> {
        const clients = PushService.clientManager?.getActiveClients() ?? []
        if (clients.length < 1) {
            return Promise.reject("PushService client is not initialized")
        }

        const primaryPromise = clients.map(client => {
            const isPrimary = PushService.isPrimaryClient(client)
            const promise = client.unsubscribe(topicKey).catch(err => {
                client.addPageAction(ReportedActions.unsubscribe_failure, {
                    "is_primary": isPrimary,
                    "topic": topicKey,
                    "auth": client.auth.serialize(),
                    "code": err.code,
                    "message": err.message,
                    "providerCode": err.providerCode,
                })
            })
            return isPrimary ? promise : undefined
        }).find(p => p !== undefined)

        return primaryPromise ?? Promise.reject("Primary client lost during unsubscribe")
    }

    public static isListeningFor(topicKey: string): boolean {
        const client = PushService.getClient()
        if (client === undefined) {
            return false
        }
        return client.isSubscribedTo(topicKey)
    }

    private static subscribeToEligibleTopics(client: IPushServiceClient): Promise<ISettledPromise[]> {
        const filterFunc = (topic: string): boolean => {
            return (TopicManager.hasPresence(topic) || !client.isSubscribedTo(topic))
                && client.auth.canAccessTopic(topic)
        }
        const topicsToSubscribe = TopicManager.getTopicKeys().filter(filterFunc)
        const promises = topicsToSubscribe.map(topic => {
            const presence = TopicManager.getPresence(topic)
            if (presence !== undefined) {
                return client.enterPresence(topic, presence)
            }
            return PushService.listenFor(client, topic)
        })
        return Promise.all(allSettledPromises(promises))
    }

    public static close(): void {
        const allClients = PushService.clientManager?.getActiveClients() ?? []
        allClients.forEach(c => c.close())
        if (PushService.servicesContext !== undefined) {
            PushService.clientManager = new ClientManager(PushService.servicesContext)
        }
    }

    // returns true if added message is a duplicate
    public static addExternalMessage(topicKey: string, tid: string): boolean {
        const allClients = PushService.clientManager?.getActiveClients() ?? []
        let primaryClientDuplicate = false

        // mark external message for all clients, but only return for primary one
        for(const c of allClients) {
            if (c.addExternalMessage(topicKey, tid) && PushService.isPrimaryClient(c)) {
                primaryClientDuplicate = true
            }
        }
        return primaryClientDuplicate
    }

    public static clearMessagesForHistory(): void {
        const allClients = PushService.clientManager?.getActiveClients() ?? []
        for(const c of allClients) {
            c.clearMessagesForHistory()
        }
    }

    public static getConnectionType(): string {
        const client = PushService.getClient()
        if (client === undefined) {
            return "not_connected"
        }
        return client.getConnectionType()
    }

    public static getConnectionId(): string {
        const client = PushService.getClient()
        if (client === undefined) {
            return ""
        }
        return client.getConnectionId()
    }

    private static isPrimaryClient(client: IPushServiceClient): boolean {
        if (PushService.clientManager === undefined) {
            return false
        }
        return PushService.clientManager.isPrimaryClient(client)
    }

    public static setVerifierRoomCount(count: number): void {
        PushService.clientManager?.setVerifierRoomCount(count)
    }

    public static getClientName(): string {
        return PushService.getClient()?.clientName ?? ""
    }
}
