export interface ILayoutConstraints {
    top: number,
    bottom: number,
    left: number,
    right: number,
    transitionTime: number,
}

export interface ILayoutConstraintsUpdate extends ILayoutConstraints {ID: string,}

export class LayoutConstraints {
    readonly ID: string
    // These are the layout's individual constraints that are set whenever setConstraints() is called
    private individualConstraints: ILayoutConstraints = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        transitionTime: 0,
    }
    // These constraints are the combined total of every other LayoutConstraint controlled under the same handler.
    private boundingConstraints: ILayoutConstraints = {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        transitionTime: 0,
    }
    private handler: LayoutConstraintsHandler | undefined
    private onUpdate: () => void = () => {}
    private onAttach: () => void = () => {}

    constructor(ID: string) {
        this.ID = ID
    }

    public top(): number {
        return this.boundingConstraints.top
    }

    public bottom(): number {
        return this.boundingConstraints.bottom
    }

    public left(): number {
        return this.boundingConstraints.left
    }

    public right(): number {
        return this.boundingConstraints.right
    }

    public transitionTime(): number {
        return this.boundingConstraints.transitionTime
    }

    public setConstraints(newConstraints: ILayoutConstraints): void {
        // If not already attached to a handler, update the constraints when this becomes attached
        if (this.handler === undefined) {
            this.onAttach = () => {
                this.updateConstraints(newConstraints)
            }
        } else {
            this.updateConstraints(newConstraints)
        }
        this.individualConstraints = newConstraints
    }

    private updateConstraints(newConstraints: ILayoutConstraints): void {
        if (this.handler !== undefined) {
            this.handler.updateConstraints({
                ID: this.ID,
                ...newConstraints,
            })
        }
    }

    public setOnUpdate(callback: () => void): void {
        this.onUpdate = callback
    }

    public getIndividualConstraints(): ILayoutConstraints {
        return this.individualConstraints
    }

    // Not called directly. Only called by LayoutConstraintHandler
    public attach(handler: LayoutConstraintsHandler): void {
        this.handler = handler
        this.onAttach()
    }

    // Not called directly. Only called by LayoutConstraintHandler
    public detach(): void {
        this.handler = undefined
    }

    // Not called directly. Only called by LayoutConstraintHandler
    public update(newConstraints: ILayoutConstraints): void {
        this.boundingConstraints = newConstraints
        this.onUpdate()
    }
}

export class LayoutConstraintsHandler {
    private constraintsMap = new Map<string, ILayoutConstraints>()
    private listeners: LayoutConstraints[] = []

    public addListener(layoutConstraints: LayoutConstraints): void {
        this.listeners.push(layoutConstraints)
        layoutConstraints.attach(this)
        this.updateConstraints({
            ID: layoutConstraints.ID,
            ...layoutConstraints.getIndividualConstraints(),
        })
    }

    public removeListener(layoutConstraints: LayoutConstraints): void {
        const index = this.listeners.indexOf(layoutConstraints)
        if (index >= 0) {
            this.listeners.splice(index, 1)
            this.constraintsMap.delete(layoutConstraints.ID)
            layoutConstraints.detach()
            this.notifyListeners()
        }
    }

    public updateConstraints(constraints: ILayoutConstraintsUpdate): void {
        this.constraintsMap.set(constraints.ID, constraints)
        this.notifyListeners()
    }

    private calculateTotalConstraints(): ILayoutConstraints {
        const totalConstraints: ILayoutConstraints = {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            transitionTime: 0,
        }
        for (const constraint of this.constraintsMap.values()) {
            for (const key of Object.keys(totalConstraints) as (keyof ILayoutConstraints)[]) {
                totalConstraints[key] += constraint[key]
            }
        }
        return totalConstraints
    }

    private notifyListeners(): void {
        const totalConstraints = this.calculateTotalConstraints()
        for (const listener of this.listeners) {
            const listenerConstraints = this.constraintsMap.get(listener.ID) // The individual listener's constraints
            if (listenerConstraints === undefined) {
                error("A LayoutConstraintHandler's listener does not exist in its constraints map")
                continue
            }
            // Subtract the listener's constraints from the totalConstraints to get the layoutConstraints the listener must follow.
            const layoutConstraints: ILayoutConstraints = { ...totalConstraints }
            for (const [key, val] of Object.entries(listenerConstraints)) {
                layoutConstraints[key as keyof ILayoutConstraints] -= val
            }

            listener.update(layoutConstraints)
        }
    }
}
