import { currentIUserInfo, getUserColorGroup, UserColorGroup } from "../common/chatconnection/users"
import { roomCleanup, roomLoaded } from "../common/context"
import { findSortedInsert } from "../common/dataUtils"
import { EventRouter, eventsPmSessionsCount, ListenerGroup } from "../common/events"
import { EnterLeaveAction } from "../common/messageInterfaces"
import { addPageAction } from "../common/newrelic"
import { ignoreCatch } from "../common/promiseUtils"
import { UserListSortOptions } from "../common/roomDossier"
import { RoomStatus } from "../common/roomStatus"
import { userChatSettingsUpdate } from "../common/theatermodelib/userActionEvents"
import { fetchRoomUsers } from "./api/userList"
import { pageContext, roomDossierContext } from "./interfaces/context"
import { RoomModeratorNoticeTopic } from "./pushservicelib/topics/noticeGroups"
import { PrivateRoomEnterLeaveTopic } from "./pushservicelib/topics/privateRoom"
import { RoomPrivilegedEnterTopic, RoomPrivilegedLeaveTopic } from "./pushservicelib/topics/room"
import type { IChatConnection } from "../common/context"
import type { IUserInfo } from "../common/messageInterfaces"

const randInt = (min: number, max: number) => min + Math.floor(Math.random() * max)
const LAG_WINDOW_MS = randInt(1, 10) * 30 * 1000 // random from .5 to 5 minutes
const SHORT_LAG_WINDOW_MS = 3 * 1000
const MIN_REFETCH_INTERVAL_MS = 2 * 1000
const REFETCH_INTERVAL = LAG_WINDOW_MS + randInt(2, 10) * 60 * 1000  // random from 2.5 to 25 minutes

// Currently enabled only for broadcasters if the realtime_userlist_enabled waffle switch is on
export const realtimeUserlistEnabled = (): boolean => pageContext.current.realtimeUserlistEnabled

// window["getRoomUsersInfo"] = (forcePublic: boolean) => {
//     if (!pageContext.current.isBroadcast) {
//         info("realtime userlist is currently only supported for broadcasters. Viewer support will come in a future PR")
//         return
//     }
//
//     const {totalCount, anonCount, roomUsers} = RoomUsers.getInstance().getRoomUsersInfo(forcePublic)
//     info("total count:", totalCount)
//     info("anon count:", anonCount)
//     info("room users:", roomUsers.map(user => user.username))
// }
// window["toggleRoomUsersRefetch"] = (shouldRefetch: boolean) => RoomUsers.getInstance().toggleRefetch(shouldRefetch)
// window["triggerBadRefetch"] = () => {
//     RoomUsers.getInstance().refetch(true, true).catch(ignoreCatch)
// }

export const roomUsersUpdate = new EventRouter<IRoomUsersInfo>("roomUsersUpdate", {
    maxHistorySize: 1,
    listenersWarningThreshold: () => 50 + 3 * eventsPmSessionsCount, // 2 per room pm session in room, 1 per room pm session in sitewide dropdown, and buffer for non room pms in the dropdown
})

export interface IRoomUsersInfo {
    totalCount: number,
    anonCount: number,
    roomUsers: IUserInfo[],
}

export const enum UserInRoomState {
    InRoom,  // Specifically they are in the room in the same private/public mode as the current user
    InRoomNotInPrivate,
    NotInRoom,
}

interface IEnterLeaveRecord {
    user: IUserInfo,
    action: EnterLeaveAction,
    timeout: number
}

const colorGroupsOrder = [
    UserColorGroup.Broadcaster,
    UserColorGroup.Moderator,
    UserColorGroup.Fanclub,
    UserColorGroup.TippedTonsRecently,
    UserColorGroup.TippedALotRecently,
    UserColorGroup.TippedRecently,
    UserColorGroup.HasTokens,
    UserColorGroup.Grey,
]

const alphaSortCompare = (user1: IUserInfo, user2: IUserInfo): number => {
    const user1ColorGroupIndex = colorGroupsOrder.indexOf(getUserColorGroup(user1))
    const user2ColorGroupIndex = colorGroupsOrder.indexOf(getUserColorGroup(user2))
    if (user1ColorGroupIndex !== user2ColorGroupIndex) {
        return user1ColorGroupIndex < user2ColorGroupIndex ? -1 : 1
    } else {
        return user1.username.localeCompare(user2.username)
    }
}

export class RoomUsers {
    private room: string | undefined
    private roomUid: string | undefined
    private publicUserList: UserList | undefined  // User list for the main room
    private privateUserList: UserList | undefined // User list for current private show, if any
    private recentPublicEnterLeaves = new Map<string, IEnterLeaveRecord>()
    private sortUsersBy: UserListSortOptions
    private refetchedRecentlyTimeout: number | undefined
    private chatConnection: IChatConnection | undefined
    private publicListeners = new ListenerGroup()
    private privateListeners = new ListenerGroup()
    private get activeUserList() { return this.privateUserList ?? this.publicUserList }

    private roomLoadTime: number
    private latestRefetchTime: number | undefined
    private refetchCounter: number
    private refetchInterval: number
    private refetchDisabled = false  // Allow disabling refetches for testing

    private static instance: RoomUsers | undefined

    private constructor() {
        roomLoaded.listen(context => {
            this.chatConnection = context.chatConnection
            this.room = context.dossier.room
            this.roomUid = context.dossier.roomUid
            this.sortUsersBy = context.dossier.userChatSettings.sortUsersKey
            this.roomLoadTime = performance.now()
            this.refetchCounter = 0
            this.initializePublicUserList()

            this.chatConnection.event.statusChange.listen((statusChange) => {
                if (statusChange.currentStatus === RoomStatus.PrivateWatching) {
                    this.initializePrivateUserList()
                } else if (statusChange.previousStatus === RoomStatus.PrivateWatching) {
                    this.cleanUpPrivateUserList()
                    roomUsersUpdate.fire(this.getRoomUsersInfo())
                }
            })
        })

        userChatSettingsUpdate.listen((settings) => {
            if (settings.sortUsersKey !== this.sortUsersBy) {
                this.sortUsersBy = settings.sortUsersKey
                this.refetch(true).catch(ignoreCatch)
            }
        })

        roomCleanup.listen(() => {
            this.cleanupPublicUserList()
            this.cleanUpPrivateUserList()
            roomUsersUpdate.fire(this.getRoomUsersInfo())
        })
    }

    public static getInstance(): RoomUsers {
        if (RoomUsers.instance === undefined) {
            RoomUsers.instance = new RoomUsers()
        }
        return RoomUsers.instance
    }

    public getRoomUsersInfo(forcePublic=false): IRoomUsersInfo {
        const userList = forcePublic ? this.publicUserList : this.activeUserList
        return {
            totalCount: userList?.getTotalUsers() ?? 0,
            anonCount: userList?.getAnonUsers() ?? 0,
            roomUsers: userList?.getUsers() ?? [],
        }
    }

    public userInRoomState(username: string): UserInRoomState {
        if (this.activeUserList?.hasUser(username) === true) {
            return UserInRoomState.InRoom
        } else if (this.publicUserList?.hasUser(username) === true) {
            return UserInRoomState.InRoomNotInPrivate
        } else {
            return UserInRoomState.NotInRoom
        }
    }

    public async refetch(force=false, forceFireMismatch=false): Promise<void> {
        // Refetch is for initially populating the public userlist, getting updated anon counts, and periodically
        // checking that we're still in sync with the backend userlist and haven't dropped any push events or something.
        // We don't use it for private shows because for private shows we already know the initial user list and the
        // volume of enter/leaves is not enough to worry about getting out of sync.
        if (!pageContext.current.isBroadcast) {
            // minimize extra api hits because we have a throttling issue on this endpoint and this feature isn't supported for viewers yet
            return
        }
        if (!force && (this.refetchedRecentlyTimeout !== undefined || this.refetchDisabled)) {
            return
        }
        window.clearTimeout(this.refetchedRecentlyTimeout)
        this.refetchedRecentlyTimeout = window.setTimeout(() => {
            this.refetchedRecentlyTimeout = undefined
        }, MIN_REFETCH_INTERVAL_MS)

        const roomUsersInfo = await this.fetchRoomUsers(true)
        const newCurrentUsers = new UserList(roomUsersInfo, this.sortUsersBy)
        newCurrentUsers.includeCurrentUser()
        this.recentPublicEnterLeaves.forEach(({ user, action }) => {
            if (action === EnterLeaveAction.Enter) {
                newCurrentUsers.addUser(user)
            } else {
                newCurrentUsers.deleteUser(user)
            }
        })

        const listsMismatched = this.publicUserList !== undefined && !newCurrentUsers.equals(this.publicUserList)
        if (forceFireMismatch || pageContext.current.isBroadcast && listsMismatched) {
            let timeSinceLastRefetch
            if (this.latestRefetchTime !== undefined) {
                timeSinceLastRefetch = performance.now() - this.latestRefetchTime
                timeSinceLastRefetch = (timeSinceLastRefetch - LAG_WINDOW_MS) / (60 * 1000)
            }
            addPageAction("RoomUserListsMismatched", {
                "current_size": this.publicUserList?.getUsers().length,
                "new_size": newCurrentUsers.getUsers().length,
                "time_elapsed_m": (performance.now() - this.roomLoadTime) / (60 * 1000),
                "time_since_last_refetch_m": timeSinceLastRefetch,
                "refetch_count": this.refetchCounter,
                "lag_window_m": LAG_WINDOW_MS / (60 * 1000),
            })
        }
        this.latestRefetchTime = performance.now()
        this.refetchCounter += 1
        this.publicUserList = newCurrentUsers
        roomUsersUpdate.fire(this.getRoomUsersInfo())
    }

    public toggleRefetch(shouldRefetch: boolean): void {
        this.refetchDisabled = !shouldRefetch
    }

    public fetchRoomUsers(ignorePrivate = false): Promise<IRoomUsersInfo> {
        // TODO can remove ignorePrivate when fetchRoomUsers is made private in a followup PR
        if (this.room === undefined) {
            error("roomUsers: calling fetchRoomUsers when not in a room")
            return Promise.reject("not in a room")
        }
        const getForPrivate = ignorePrivate ? false : this.chatConnection?.inPrivateRoom() ?? false
        return fetchRoomUsers(this.room, this.sortUsersBy, getForPrivate)
    }

    /** Methods maintaining this.publicUserList **/
    private initializePublicUserList(): void {
        this.listenForPublicEnterLeaves()

        // Load up userlist initially assuming 'short' wowza user list lag so we get it soon after room load, but check
        // again after a longer lag to be safe
        window.setTimeout(() => {
            this.refetch().catch(ignoreCatch)
        }, SHORT_LAG_WINDOW_MS)
        window.setTimeout(() => {
            this.refetch().catch(ignoreCatch)
        }, LAG_WINDOW_MS)
        this.refetchInterval = window.setInterval(() => {
            this.refetch().catch(ignoreCatch)
        }, REFETCH_INTERVAL)
    }

    private cleanupPublicUserList(): void {
        this.publicListeners.removeAll()
        this.recentPublicEnterLeaves.clear()
        window.clearTimeout(this.refetchedRecentlyTimeout)
        window.clearInterval(this.refetchInterval)
        this.refetchedRecentlyTimeout = undefined
        this.publicUserList = undefined
    }

    private listenForPublicEnterLeaves(): void {
        if (this.roomUid === undefined) {
            error("roomUsers: listening for public enter/leaves without room uid")
            return
        }
        const isPrivileged = pageContext.current.isBroadcast || roomDossierContext.getState().isModerator
        if (isPrivileged) {
            new RoomPrivilegedEnterTopic(this.roomUid).onMessage.listen(msg => {
                this.onPublicEnterLeave(msg.user, msg.action)
            }).addTo(this.publicListeners)
            new RoomPrivilegedLeaveTopic(this.roomUid).onMessage.listen(msg => {
                if (msg.connections !== 0) {
                    // Only count leaves if the user has no other tabs open to the room
                    return
                }
                this.onPublicEnterLeave(msg.user, msg.action)
            }).addTo(this.publicListeners)
            // RoomModeratorNoticeTopic is grouped with RoomPrivilegedEnterTopic and RoomPrivilegedLeaveTopic 
            // in the backend so must have a listener added at the same time, even if empty
            new RoomModeratorNoticeTopic(this.roomUid).onMessage.listen(() => {}).addTo(this.publicListeners)
        } else {
            // TODO implement in followup PR when we make this topic batched
            // new RoomEnterLeaveTopic(this.roomUid).onMessage.listen(msg => {
            //     this.onEnterLeave(msg.user, msg.action)
            // }).addTo(this.listeners)
        }
    }

    private onPublicEnterLeave(user: IUserInfo, action: EnterLeaveAction): void {
        if (action === EnterLeaveAction.Enter) {
            this.publicUserList?.addUser(user)
        } else {
            this.publicUserList?.deleteUser(user)
        }
        this.recordRecentPublicEnterLeave(user, action)
        roomUsersUpdate.fire(this.getRoomUsersInfo())
    }

    private recordRecentPublicEnterLeave(user: IUserInfo, action: EnterLeaveAction): void {
        // Remember recent enter/leaves in order to handle wowza and network lag when refetching the list from backend
        const enterLeaveRecord = this.recentPublicEnterLeaves.get(user.username)
        if (enterLeaveRecord !== undefined) {
            window.clearTimeout(enterLeaveRecord.timeout)
        }
        const timeout = window.setTimeout(() => {
            this.recentPublicEnterLeaves.delete(user.username)
        }, LAG_WINDOW_MS)
        this.recentPublicEnterLeaves.set(user.username, { user, action, timeout })
    }

    /** Methods maintaining this.privateUserList **/
    private initializePrivateUserList(): void {
        this.privateUserList = new UserList(undefined, this.sortUsersBy)

        // Manually init the user list to broadcaster + viewer since we know that's how it starts and so there's no
        // concern over any delay listening for private enter/leaves
        this.privateUserList.includeCurrentUser()
        if (pageContext.current.isBroadcast) {
            const privateShowUser = this.chatConnection?.getPrivateShowUser()
            if (privateShowUser === undefined) {
                error("roomUsers: no private show user when initializing private user list")
            } else {
                const otherUserInfo = this.publicUserList?.getLatestUserInfo(privateShowUser)
                if (otherUserInfo !== undefined) {
                    this.privateUserList.addUser(otherUserInfo)
                }
            }
        } else {
            // TODO when adding viewer support, add the broadcaster to the list here
        }

        roomUsersUpdate.fire(this.getRoomUsersInfo())

        this.listenForPrivateEnterLeaves()
    }

    private cleanUpPrivateUserList(): void {
        this.privateListeners.removeAll()
        this.privateUserList = undefined
    }

    private listenForPrivateEnterLeaves(): void {
        const startListening = (privateShowId: string) => {
            if (this.roomUid === undefined) {
                error("roomUsers: listening for private enter/leaves without room uid")
                return
            }
            new PrivateRoomEnterLeaveTopic(this.roomUid, privateShowId).onMessage.listen(msg => {
                if (msg.action === EnterLeaveAction.Leave && msg.connections !== 0) {
                    // Only count leaves if the user has no other tabs open to the room
                    return
                }
                this.onPrivateEnterLeave(msg.user, msg.action)
            }).addTo(this.privateListeners)
        }

        // Even though this is called when we enter RoomStatus.PrivateWatching, there can be a delay before
        // privateShowId is set
        const currentDossier = roomDossierContext.getState()
        if (currentDossier.privateShowId !== "") {
            startListening(currentDossier.privateShowId)
        } else {
            let dossierListenerTimeout = -1

            const dossierListener = roomDossierContext.onUpdate.listen((oldDossier) => {
                const privateShowId = roomDossierContext.getState().privateShowId
                if (privateShowId !== "") {
                    startListening(privateShowId)
                    dossierListener.removeListener()
                    clearTimeout(dossierListenerTimeout)
                }
            }, false)

            dossierListenerTimeout = window.setTimeout(() => {
                error("roomUsers: no private show ID after 10 seconds")
                dossierListener.removeListener()
            }, 10 * 1000)
        }
    }

    private onPrivateEnterLeave(user: IUserInfo, action: EnterLeaveAction): void {
        if (action === EnterLeaveAction.Enter) {
            this.privateUserList?.addUser(user)
        } else {
            this.privateUserList?.deleteUser(user)
        }
        roomUsersUpdate.fire(this.getRoomUsersInfo())
    }
}

// Data structure used by RoomUsers for tracking users in the room and keeping them sorted
class UserList {
    private currentUsers: IUserInfo[]
    private currentUsersSet = new Set<string>()  // Set mirroring currentUsers for fast membership checks
    private anonUsers: number
    private historicalUsers = new Map<string, IUserInfo>() // Remember the latest user info for anyone who's been in the room
    private sortUsersBy: UserListSortOptions

    constructor(roomUsersInfo: IRoomUsersInfo | undefined, sortUsersBy: UserListSortOptions) {
        this.currentUsers = roomUsersInfo?.roomUsers ?? []
        this.anonUsers = roomUsersInfo?.anonCount ?? 0
        this.sortUsersBy = sortUsersBy

        this.currentUsers.forEach(user => {
            this.currentUsersSet.add(user.username)
            this.historicalUsers.set(user.username, user)
        })
    }

    public getUsers(): IUserInfo[] {
        return this.currentUsers
    }

    public getTotalUsers(): number {
        return this.currentUsers.length + this.anonUsers
    }

    public getAnonUsers(): number {
        return this.anonUsers
    }

    public getLatestUserInfo(username: string): IUserInfo | undefined {
        return this.historicalUsers.get(username)
    }

    public hasUser(username: string): boolean {
        return this.currentUsersSet.has(username)
    }

    public addUser(user: IUserInfo): void {
        if (!this.hasUser(user.username)) {
            if (this.sortUsersBy === UserListSortOptions.Alphabetical) {
                this.addUserAlphaSorted(user)
            } else {
                this.addUserTokenSorted(user)
            }
        }
    }

    public deleteUser(user: IUserInfo): void {
        if (!this.hasUser(user.username)) {
            return
        }
        const userIndex = this.currentUsers.findIndex(u => u.username === user.username)
        this.currentUsers.splice(userIndex, 1)
        this.currentUsersSet.delete(user.username)
    }

    public includeCurrentUser(): void {
        // The userlist is often missing the current user because when they enter the room they won't be in the backend
        // userlist yet and they won't see their own enter push event. So allow manually including the current user
        const currentUser = currentIUserInfo()
        if (currentUser !== undefined) {
            this.addUser(currentUser)
        }
    }

    // Checks if two UserLists represent the same set of users. Does /not/ check if their order matches
    public equals(otherUserList: UserList): boolean {
        return otherUserList.getUsers().length === this.currentUsers.length
            && this.currentUsers.every((user) => otherUserList.hasUser(user.username))
    }

    private addUserAlphaSorted(user: IUserInfo): void {
        const insertIndex = findSortedInsert(this.currentUsers, user, alphaSortCompare)
        this.currentUsers.splice(insertIndex, 0, user)
        this.currentUsersSet.add(user.username)
        this.historicalUsers.set(user.username, user)
    }

    private addUserTokenSorted(user: IUserInfo): void {
        // TODO we will implement this in a followup project
        // Until then, we're ok with ignoring sorting by tokens for the new user and just inserting them at the end.
        // When the next refetch from backend happens, the fetched user list will have the user sorted in properly.
        this.currentUsers.push(user)
        this.currentUsersSet.add(user.username)
        this.historicalUsers.set(user.username, user)
        return
    }
}
