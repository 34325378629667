import { getCookieOrEmptyString } from "@multimediallc/web-utils/storage"
import { addEventListenerPoly, removeEventListenerPoly } from "../../../../common/addEventListenerPolyfill"
import { normalizeResource, postCb } from "../../../../common/api"
import { Component } from "../../../../common/defui/component"
import { applyStyles, tabListenerFactory } from "../../../../common/DOMutils"
import { ModalComponent } from "../../../../common/modalComponent"
import { ignoreCatch } from "../../../../common/promiseUtils"
import { BROADCASTER_SENTIMENT_SUBMIT_ENDPOINT } from "../../../../common/sentimentSurvey"
import { i18n } from "../../../../common/translation"
import { addColorClass, colorClass } from "../../../colorClasses"
import { pageContext } from "../../../interfaces/context"
import { currentSiteSettings } from "../../../siteSettings"
import { loginOverlayShown } from "../../../ui/loginOverlay"
import {
    Canvas, canvasClose, canvasOpen, CloseControl, Comments, createSimpleDiv, Header, ScreenshotControl,
    SentimentSelection, SubmitRow,
} from "./feedbackFormComponents"
import Key = JQuery.Key
import type { XhrError } from "../../../../common/api"
import type { SentimentSurveyOption } from "../../../../common/sentimentSurvey"

/**
 * @styles: scss/theme/shared/userFeedbackModal.scss
 */

export interface IFeedbackFormShowData {
    source: string,
    sentiment?: SentimentSurveyOption,
}

interface IFeedbackFormProps {
    modal: UserFeedbackModal,
    canvas: Canvas,
}

class UserFeedbackForm extends Component {
    form: HTMLFormElement
    globalError: HTMLElement
    errors: HTMLElement[]
    comments: Comments
    sentiment: SentimentSelection
    screenshot: ScreenshotControl
    notice: HTMLElement
    submitRow: SubmitRow
    csrf: HTMLInputElement
    url: HTMLInputElement
    source: HTMLInputElement
    private canvas: Canvas

    constructor(props: IFeedbackFormProps) {
        super("div", props)

        this.errors = []

        if (this.canvas.canScreenshot()) {
            this.updateInfoText()
            this.errors.push(this.screenshot.error)
        }
        this.errors.push(this.globalError)

        addEventListenerPoly("submit", this.form, (event: Event) => {
            event.preventDefault()
            this.submit()
        })
    }

    protected initUI(props: IFeedbackFormProps): void {
        this.element = createSimpleDiv()

        this.form = document.createElement("form")
        this.form.action = normalizeResource("/feedback/submit/")
        this.form.method = "POST"
        this.element.appendChild(this.form)

        this.globalError = document.createElement("p")
        this.globalError.style.color = "red"
        this.globalError.style.marginBottom = "8px"
        this.form.appendChild(this.globalError)

        this.sentiment = this.createSentimentSelection()
        this.form.appendChild(this.sentiment.element)

        this.comments = this.createComments()
        this.form.appendChild(this.comments.element)

        this.canvas = props.canvas
        if (this.canvas.canScreenshot()) {
            this.screenshot = new ScreenshotControl(props.modal, this.canvas)
            this.addChild(this.screenshot, this.form)
        }

        this.notice = document.createElement("p")
        addColorClass(this.notice, "privacyNotice")
        this.notice.dataset.testid = "privacy-notice"
        this.notice.style.fontSize = "10.5px"
        this.notice.style.lineHeight = "14px"
        this.notice.textContent = i18n.feedbackNotice.replace("%SITE_NAME%", currentSiteSettings.siteName)
        this.form.appendChild(this.notice)

        this.csrf = document.createElement("input")
        this.csrf.type = "hidden"
        this.csrf.name = "csrfmiddlewaretoken"
        this.csrf.value = getCookieOrEmptyString("csrftoken")
        this.form.appendChild(this.csrf)

        this.url = document.createElement("input")
        this.url.type = "hidden"
        this.url.name = "url"
        this.url.value = document.location.href.substr(0, 255)
        this.form.appendChild(this.url)

        this.source = document.createElement("input")
        this.source.type = "hidden"
        this.source.name = "source"
        this.source.value = ""
        this.form.appendChild(this.source)

        this.submitRow = new SubmitRow()
        this.addChild(this.submitRow, this.form)
    }

    protected createSentimentSelection(): SentimentSelection {
        return new SentimentSelection()
    }

    protected createComments(): Comments {
        const onChange = () => {
            // timeout to handle copy/paste
            window.setTimeout(() => {
                if (this.comments.anyCommentsNonempty()) {
                    this.submitRow.enable()
                } else {
                    this.submitRow.disable()
                }
            }, 200)
        }
        return new Comments({
            prompts: [i18n.feedbackCommentsLabel],
            onChange: onChange,
            required: true,
            textBoxHeight: 72,
        })
    }

    public canAddScreenshot(): boolean {
        return this.canvas.canScreenshot() && this.screenshot.button.style.display !== "none"
    }

    private updateInfoText(): void {
        if (this.canAddScreenshot()) {
            this.screenshot.info.textContent = this.source.value === "footer" ?
                i18n.feedbackAddScreenshotInfoScrollUp : i18n.feedbackAddScreenshotInfoScrollDown
        }
    }

    public updateCSRF(): void {
        this.csrf.value = getCookieOrEmptyString("csrftoken")
    }

    public updateURL(): void {
        this.url.value = document.location.href.substr(0, 255)
    }

    public updateSource(source: string): void {
        this.source.value = source
        this.updateInfoText()
    }

    public updateSentiment(sentimentValue: SentimentSurveyOption): void {
        this.sentiment.setValue(sentimentValue)
    }

    public clearErrors(): void {
        this.errors.forEach((errorEl) => {
            errorEl.textContent = ""
        })
        this.comments.clearErrors()
    }

    public resetForm(): void {
        this.forceForm()

        this.clearErrors()
        this.comments.clear()
        this.sentiment.clearSelection()
        this.submitRow.disable()
        this.form.reset()

        if (this.canvas.canScreenshot()) {
            this.screenshot.removeScreenshot()
        }
    }

    public forceForm(): void {
        this.removeAllDOMChildren()
        this.element.appendChild(this.form)

        this.comments.focus()
    }

    protected submit(): void {
        this.clearErrors()
        const data = new FormData()
        data.append("sentiment", this.sentiment.formInput.input.value)
        this.comments.prompts().forEach(value => {
            data.append("prompts", value)
        })
        this.comments.values().forEach(value => {
            data.append("responses", value)
        })
        data.append("url", document.location.href.substr(0, 255))
        data.append("source", this.source.value)
        data.append("csrfmiddlewaretoken", getCookieOrEmptyString("csrftoken"))

        if (this.canvas.screenshot !== undefined) {
            this.canvas.screenshot.toBlob((blob) => {
                if (blob !== null) {
                    data.append("screenshot", blob, "screenshot.png")
                }
                this.postFeedback(data)
            })
        } else {
            this.postFeedback(data)
        }
    }

    private postFeedback(data: Record<string, string> | FormData): void {
        this.submitRow.disable()

        postCb(this.form.action, data)
            .then((xhr: XMLHttpRequest) => {
                // we are expecting JSON, the banned redirect gives HTML
                const contentType = xhr.getResponseHeader("content-type")
                if (contentType !== null && contentType.includes("text/html")) {
                    this.globalError.textContent = i18n.feedbackBannedUser
                    this.submitRow.enable()
                } else {
                    this.resetForm()
                    this.element.removeChild(this.form)
                    const success = document.createElement("p")
                    success.textContent = i18n.feedbackSubmitted
                    this.element.appendChild(success)
                }
            })
            .catch((error: XhrError) => {
                if (error.xhr.status === 429) {
                    this.globalError.textContent = i18n.feedbackRateLimitError
                } else {
                    try {
                        const errors = JSON.parse(error.xhr.response)

                        const commentErrors: string[] | undefined = errors["responses"]
                        if (commentErrors !== undefined) {
                            commentErrors.forEach((error, idx) => this.comments.setError(error, idx))
                        }
                    } catch {
                        this.globalError.textContent = i18n.feedbackUnknownError
                    }
                }
                this.submitRow.enable()
            })
    }
}

export class UserFeedbackModal extends ModalComponent {
    protected form: UserFeedbackForm
    private isVisible = false
    protected canvas: Canvas
    private inner: HTMLDivElement
    private close: CloseControl
    private header: Header
    protected overlayClickStayOpen = true
    protected preventChatFocus = true

    protected static instance: UserFeedbackModal | undefined

    public static show(data: IFeedbackFormShowData): void {
        if (UserFeedbackModal.instance === undefined) {
            UserFeedbackModal.instance = new UserFeedbackModal()
        }
        UserFeedbackModal.instance.showFromSource(data)
    }

    constructor() {
        super({
            onShow: () => {
                this.onShow()
            },
            onHide: () => {
                this.onHide()
            },
            easyExit: true,
        })

        this.element.classList.add("block_CB1_chat_focus")
        this.element.dataset.testid = "user-feedback-modal"
        addColorClass(this.overlay, "overlayOpacity")
        addColorClass(this.element, "userFeedbackForm")
        addColorClass(this.element, colorClass.defaultColor) // only for lightmode, darkmode overrides it

        canvasOpen.listen(() => {
            this.element.style.display = "none"
        })

        canvasClose.listen(() => {
            this.element.style.display = "block"
        })

        loginOverlayShown.listen(() => {
            this.hide()
        })
        this.overlayClick.listen(() => {
            this.hide()
        })
    }

    protected initData(): void {
        this.canvas = new Canvas()
        if (this.canvas.canScreenshot()) {
            document.body.appendChild(this.canvas.element)
        }

        this.inner = createSimpleDiv(this.element)

        this.close = new CloseControl()
        addEventListenerPoly("click", this.close.control, () => {
            this.hide()
        })
        this.addChild(this.close, this.inner)

        this.header = new Header()
        this.addChild(this.header, this.inner)

        this.form = this.createFormContent()
        if (this.canvas.canScreenshot()) {
            this.canvas.controls.setScreenshot(this.form.screenshot.screenshot)
        }
        this.addChild(this.form, this.inner)


        const getEndOfForm = () => {
            return !this.form.submitRow.submit.disabled ? this.form.submitRow.submit : this.close.control
        }

        this.element.querySelectorAll<HTMLButtonElement>(".sentimentOption").forEach(option => {
            addEventListenerPoly("keydown", option, tabListenerFactory(this.form.comments.firstInput(), this.close.control))
        })
        addEventListenerPoly("keydown", this.form.comments.element, tabListenerFactory(() => {
            return this.form.canAddScreenshot() ? this.form.screenshot.button : getEndOfForm()
        }, this.close.control))
        if (this.form.canAddScreenshot()) {
            addEventListenerPoly("keydown", this.form.screenshot.button, tabListenerFactory(getEndOfForm, this.form.comments.lastInput()))
        }
        addEventListenerPoly("keydown", this.form.submitRow.submit, tabListenerFactory(this.close.control, () => {
            return this.form.canAddScreenshot() ?  this.form.screenshot.button : this.form.comments.lastInput()
        }))
        addEventListenerPoly("keydown", this.close.control, tabListenerFactory(this.form.comments.firstInput(), () => {
            if (!this.form.submitRow.submit.disabled) {
                return this.form.submitRow.submit
            } else if (this.form.canAddScreenshot()) {
                return this.form.screenshot.button
            } else {
                return this.form.comments.lastInput()
            }
        }))
    }

    protected initUI(): void {
        applyStyles(this.element, {
            position: "fixed",
            top: "150px",
            fontSize: "12px",
            left: "50%",
            marginLeft: "-157px",
            width: "310px",
            height: "auto",
            border: "4px",
            borderRadius: "4px",
            padding: "15px",
            fontFamily: "UbuntuRegular, Helvetica, Arial, sans-serif",
        })

        // screenshots can be weird and different sizes if the screen resizes
        // after it is taken; reset the form if it happens
        if (this.canvas.canScreenshot()) {
            addEventListenerPoly("resize", window, () => {
                if (!this.form.screenshot.isCapturing && this.canvas.screenshot !== undefined) {
                    this.form.screenshot.removeScreenshot()
                    this.form.screenshot.error.textContent = i18n.feedbackResize
                }
            })
        }
    }

    protected createFormContent(): UserFeedbackForm {
        return new UserFeedbackForm({ modal: this, canvas: this.canvas })
    }

    public showFromSource(data: IFeedbackFormShowData): void {
        this.form.updateSource(data.source)
        if (data.sentiment !== undefined) {
            this.form.updateSentiment(data.sentiment)
        }
        this.show()
    }

    public onShow(): void {
        // keep CSRF value on form updated in case of submitting without AJAX
        this.form.updateCSRF()
        this.form.updateURL()
        this.element.style.display = ""
        this.repositionChildren()
        this.isVisible = true
        addEventListenerPoly("keydown", this.element, stopPropagation)
        addEventListenerPoly("keydown", document.body, stopSpaceScroll)

        this.form.forceForm()
    }

    public onHide(): void {
        const wasVisible = this.isVisible
        this.element.style.display = "none"
        this.isVisible = false
        if (wasVisible) {
            removeEventListenerPoly("keydown", this.element, stopPropagation)
            removeEventListenerPoly("keydown", document.body, stopSpaceScroll)
        }

        try {
            window.dispatchEvent(new Event("resize"))
        } catch {
            // IE11 cannot fire resize events normally
            if (navigator.userAgent.indexOf("MSIE") !== -1 || navigator.appVersion.indexOf("Trident/") > 0) {
                const event = document.createEvent("UIEvents")
                event["initUIEvent"]("resize", true, false, window, 0)
                window.dispatchEvent(event)
            } else {
                error("Could not fire resize event")
            }
        }
        if (this.canvas.canScreenshot()) {
            this.form.screenshot.error.textContent = ""
        }
        this.canvas.hide()
    }
}

class BroadcasterFeedbackForm extends UserFeedbackForm {
    constructor(props: IFeedbackFormProps) {
        super(props)
        this.sentiment.inputChange.listen(() => {
            this.submitRow.enable()
        })
    }

    protected createSentimentSelection(): SentimentSelection {
        const sentimentSelection = new SentimentSelection()
        sentimentSelection.setPrompt(i18n.feedbackBroadcasterLabel(currentSiteSettings.siteName))
        return sentimentSelection
    }

    protected createComments(): Comments {
        const onChange = () => {
            // timeout to handle copy/paste
            window.setTimeout(() => {
                if (this.sentiment.getValue() !== "") {
                    return
                }
                if (this.comments.anyCommentsNonempty()) {
                    this.submitRow.enable()
                } else {
                    this.submitRow.disable()
                }
            }, 200)
        }

        return new Comments({
            prompts: [i18n.haveYouNoticedAnyBugs],
            onChange: onChange,
            required: false,
            textBoxHeight: 52,
        })
    }

    public forceForm(): void {
        super.forceForm()
        // Blur comments to try and encourage users to select a sentiment option and not only give comments
        this.comments.blur()
    }

    protected submit(): void {
        super.submit()

        // Also submit to the broadcaster sentiment endpoint so that we get the newrelic event
        const data = new FormData()
        data.append("rating", this.sentiment.formInput.input.value)
        data.append("url", document.location.href.substr(0, 255))
        data.append("room_user", pageContext.current.loggedInUser?.username ?? "")
        postCb(BROADCASTER_SENTIMENT_SUBMIT_ENDPOINT, data).catch(ignoreCatch)
    }
}

export class BroadcasterFeedbackModal extends UserFeedbackModal {
    protected form: BroadcasterFeedbackForm

    protected static instance: BroadcasterFeedbackModal | undefined

    public static show(): void {
        if (BroadcasterFeedbackModal.instance === undefined) {
            BroadcasterFeedbackModal.instance = new BroadcasterFeedbackModal()
        }
        BroadcasterFeedbackModal.instance.show()
    }

    constructor() {
        super()
        this.form.updateSource("broadcaster_sentiment_survey")
    }

    protected createFormContent(): BroadcasterFeedbackForm {
        return new BroadcasterFeedbackForm({ modal: this, canvas: this.canvas })
    }
}

const stopPropagation = (event: KeyboardEvent) => {
    if (event.keyCode !== Key.Escape) {
        event.stopImmediatePropagation()
    }
}

const stopSpaceScroll = (event: KeyboardEvent) => {
    if (event.keyCode === Key.Space && event.target === document.body) {
        event.preventDefault()
    }
}
