import { t } from "@lingui/macro"
import { applyStyles, createHoverText } from "../DOMutils"

export interface AdvancedOptions {
    optionsDiv: HTMLDivElement
    HLSToggleTooltip: HTMLParagraphElement
}

export function createAdvancedOptions(): AdvancedOptions {
    const optionsDiv = document.createElement("div")
    applyStyles(optionsDiv, {
        display: "flex",
        alignItems: "center",
        position: "absolute",
        opacity: "1",
        width: "95%",
        backgroundColor: "#FFF",
        marginTop: "8px",
        height: "56px",
        justifyContent: "space-between",
        borderRadius: "8px",
    })

    const HLSToggleTooltip = createHoverText()
    applyStyles(HLSToggleTooltip, {
        width: "222px",
        height: "auto",
        padding: "8px 16px",
        display: "none",
        opacity: "0",
    })

    HLSToggleTooltip.textContent = t`Enable to reduce the delay between you and the creator. If you are having video issues, try turning this setting on/off.`

    const label = document.createElement("label")
    label.textContent = t`Minimize Delay`

    const infoIcon = document.createElement("img")
    infoIcon.src = `${STATIC_URL_ROOT}images/info-icon-black.svg`
    infoIcon.style.width = "16px"
    infoIcon.style.height = "16px"

    infoIcon.onclick = (e) => {
        HLSToggleTooltip.style.display = HLSToggleTooltip.style.display === "none" ? "block" : "none"
        HLSToggleTooltip.style.opacity = HLSToggleTooltip.style.opacity === "0" ? "1" : "0"
        e.stopPropagation()
    }

    const infoDiv = document.createElement("div")
    infoDiv.appendChild(label)
    infoDiv.appendChild(infoIcon)
    infoDiv.style.display = "flex"
    infoDiv.style.gap = "4px"
    infoDiv.style.marginLeft = "16px"
    infoDiv.style.alignItems = "center"

    optionsDiv.appendChild(infoDiv)

    return { optionsDiv, HLSToggleTooltip }
}
