import { ArgJSONMap , isPortrait, usernameTitleCase } from "@multimediallc/web-utils"
import { iOSVersionFull, isHistorySupported, isiOS, isiOSPwa, isPuffin, isSafari } from "@multimediallc/web-utils/modernizr"
import { getRoomAccess, RoomAccessCode } from "../../cb/api/chat"
import { tokenBalanceUpdate } from "../../cb/api/tipping"
import { smcOnRoomPlaybackStart } from "../../cb/components/showMyCam/smcBroadcaster"
import { pageContext , roomDossierContext } from "../../cb/interfaces/context"
import { PushService } from "../../cb/pushservicelib/pushService"
import { RoomStatusTopic } from "../../cb/pushservicelib/topics/room"
import { currentSiteSettings } from "../../cb/siteSettings"
import { addEventListenerPoly, removeEventListenerPoly } from "../addEventListenerPolyfill"
import { modalAlert } from "../alerts"
import { normalizeResource, postCb } from "../api"
import { AudioHolder } from "../audioHolder"
import { isNotLoggedIn } from "../auth"
import { ChatConnection } from "../chatconnection/chatConnection"
import { dossierLoaded } from "../chatRoot"
import { getChatMode } from "../chatSettings"
import { roomCleanup, roomLoaded } from "../context"
import { Debouncer, DebounceTypes } from "../debouncer"
import { SubSystemType } from "../debug"
import { Component } from "../defui/component"
import { ListenerGroup } from "../events"
import { isPwaNotificationActive } from "../featureFlagUtil"
import { addPageAction, setCurrentMode, setCurrentRoom } from "../newrelic"
import { HlsPlayer } from "../player/hlsPlayer"
import { ignoreCatch } from "../promiseUtils"
import { getRoomDossier, parseRoomDossier } from "../roomDossier"
import { RoomStatus } from "../roomStatus"
import { styleUserSelect } from "../safeStyle"
import { userChatSettingsUpdate } from "../theatermodelib/userActionEvents"
import { i18n } from "../translation"
import { isVirtualKeyboardLikelyShowing } from "../virtualKeyboardUtil"
import { goToHomepage, isDocumentVisible } from "../windowUtils"
import { inputDivBorder, inputDivHeight } from "./chatContents"
import { HeaderRoomInfo } from "./headerRoomInfo"
import { repositionChatContentsOnInputFocus } from "./mobileControlsEvents"
import { MobileDismissibleMessages } from "./mobileDismissibleMessages"
import { MobilePlayer } from "./mobilePlayer"
import { MobilePureChat } from "./mobilePureChat"
import { PortraitContents } from "./portraitContents"
import { RoomTabs } from "./roomTabs"
import { isKeyboardOpen } from "./scrollFix"
import { SessionMetricsMobile } from "./sessionMetrics"
import { TabName } from "./tabList"
import { TipCallout } from "./tipCallout"
import { loadRoomRequest, openTipCalloutRequest, sendMessageInputBlur, sendMessageInputFocus, siteHeaderMenuOpened, userSwitchedTab } from "./userActionEvents"
import { screenOrientationChanged } from "./windowOrientation"
import type { AudioHolderSound } from "../audioHolder"
import type { IRoomHistory } from "../chatRoot"
import type { IRoomContext } from "../context"
import type { IRoomDossier } from "../roomDossier"
import type { ITipRequest } from "../specialoutgoingmessages"

function addHistory(roomHistory: IRoomHistory): void {
    if (!isHistorySupported()) {
        // TODO support IE8/9 and use History.js?
        return
    }

    const url = new URL(`/${roomHistory.room}/`, window.location.origin)
    url.search = window.location.search
    const roomPath = `${url.pathname}${url.search}`

    if (hasFirstRoomLoaded) {
        window.history.pushState(roomHistory.room, roomHistory.roomTitle, normalizeResource(roomPath))
    } else {
        window.history.replaceState(roomHistory.room, roomHistory.roomTitle, normalizeResource(roomPath))
    }
}

function setDocumentTitle(room: string, subject: string): void {
    document.title = i18n.mobileDocumentTitle(room, currentSiteSettings.siteName, subject)
}

export function needsSafariInputFix(): boolean {
    // iOS 15.0.* Safari covers the input when focused due to the bottom address bar
    // See: https://stackoverflow.com/questions/68974702/ios-15-safari-detect-floating-address-bar-when-keyboard-is-visible

    const iOSVersion = iOSVersionFull()
    return iOSVersion?.major === 15 && iOSVersion.minor === 0 && isSafari()
}

export function isPushmenuOpen(): boolean {
    const pushMenuOverlay = document.querySelector<HTMLElement>(".push-overlay")

    if (pushMenuOverlay === null) {
        return false
    }
    return pushMenuOverlay.style.display !== "none" && pushMenuOverlay.style.display !== ""
}

let hasFirstRoomLoaded = false

export class MobileRoot extends Component {
    private player: MobilePlayer
    private portraitContents: PortraitContents
    private audioHolder = new AudioHolder()
    private sessionMetrics = new SessionMetricsMobile()
    private listenerGroup = new ListenerGroup()
    private siteHeaderHeight = 0
    private isFullscreen = false
    private pushMenuOverlay: HTMLElement
    private pushMenu: HTMLElement
    private siteHeader: HTMLElement
    private mainContainer: HTMLElement
    private pushMenuContainer: HTMLDivElement
    private headerRoomInfo: HeaderRoomInfo
    private loading: number
    private currentRoom: string
    private currentChatConnection: ChatConnection
    private mobileDismissibleMessages: MobileDismissibleMessages
    private resizeObserver: ResizeObserver | undefined
    private chatTabPureChat: MobilePureChat
    private privateTabPureChat: MobilePureChat
    private tipCallout: TipCallout
    private isLoadFromBFCacheAfterSigningOut = false

    constructor(roomName: string, inlineAutoplaySupported: boolean) {
        super()
        this.loading = Date.now()
        this.currentRoom = roomName
        if (window["ResizeObserver"] !== undefined) {
            this.resizeObserver = new ResizeObserver(() => this.repositionChildrenRecursive())
        }
        document.body.style.overflow = "hidden" // prevent pull-to-refresh
        this.mainContainer = document.getElementById("main") as HTMLElement
        if (isPwaNotificationActive() && isiOSPwa()) {
            document.body.style.backgroundColor = "#FFF"
            this.mainContainer.style.backgroundColor = "#FFF"
        }
        this.initDismissibleMessages()
        this.mainContainer.appendChild(this.element)
        if (isiOS()) {
            this.hideAddressBarInLandscape(1)
            document.body.style.overflow = "auto"
        }

        setCurrentMode(this.sessionMetrics.getCurrentMode())

        this.siteHeader = document.querySelector("#static-header") as HTMLElement
        const siteHeaderRightNav = document.querySelector("#static-header .right-nav") as HTMLElement
        this.pushMenuOverlay = document.querySelector(".push-overlay") as HTMLElement
        this.pushMenu = document.querySelector(".pushmenu") as HTMLElement
        this.siteHeaderHeight = this.siteHeader.offsetHeight

        this.headerRoomInfo = new HeaderRoomInfo()
        siteHeaderRightNav.appendChild(this.headerRoomInfo.element)

        this.pushMenuContainer = document.querySelector("#pushmenu-container") as HTMLDivElement

        siteHeaderMenuOpened.listen((isOpen: boolean) => {
            if (isOpen) {
                addPageAction("SiteMenuOpened")
            }
        })

        tokenBalanceUpdate.listen((tokenBalanceInfo) => {
            const tokenDiv = document.getElementById("token-balance")
            if (tokenDiv != null) {
                tokenDiv.innerText = `${tokenBalanceInfo.tokens} Tokens`
            }
        })

        this.element.style.height = "100%"
        this.element.style.width = "100%"
        this.element.style.minHeight = "100%"
        this.element.style.minWidth = "100%"
        this.element.style.position = "fixed"
        this.element.style.zIndex = "1000"  // Should match the zIndex of mobile header for tipCallout overlay to overlap the header
        styleUserSelect(this.element, "none")
        setCurrentRoom(roomName)
        this.player = new MobilePlayer(inlineAutoplaySupported, this, this.mobileDismissibleMessages)
        if (this.player.playerComponent instanceof HlsPlayer){
            this.player.playerComponent.setPageLoaded(this.loading)
        }
        this.prependChild(this.player)
        this.sessionMetrics.bindPlayer(this.player)
        this.portraitContents = this.addChild(new PortraitContents({
            player: this.player,
            mobileDismissibleMessages: this.mobileDismissibleMessages,
        }))

        // Must be called after portraitContents is added to the DOM, else we get a chat rules bug if initially fullscreen
        this.player.videoControls.executeInitialFullscreenState()

        const chatTabSplitChat = this.portraitContents.getChatTabChatContents()
        // Create two separate purechat instances which will be reused for chatTab & privateTab in fullscreen mode
        this.chatTabPureChat = new MobilePureChat(chatTabSplitChat)
        this.privateTabPureChat = new MobilePureChat(this.portraitContents.getPrivateTabChatContents())

        this.player.toggleFullscreen.listen((isFullScreen) => {
            this.isFullscreen = isFullScreen
            this.repositionChildrenRecursive()
            this.scrollAllChatContentsToBottom()
            this.sessionMetrics.recordResize()
        })

        this.tipCallout = this.addChild(new TipCallout("mobile"))
        openTipCalloutRequest.listen((tipRequest: ITipRequest) => {
            if (pageContext.current.isNoninteractiveUser){
                modalAlert(i18n.internalStaffTip)
                return
            }
            if (this.player.isFullscreen || !isPortrait()) {
                this.player.videoControls.showTipForm(tipRequest)
                return
            }
            // delay repositionChildren calls to avoid resizing player to be too big
            window.setTimeout(() => {
                this.player.limitPortraitHeight(280)
                this.repositionChildrenRecursive() // UI changes should come before showing callout
                this.tipCallout.show(tipRequest)
            })
        })
        this.tipCallout.tipSent.listen((tipInfo) => {
            this.tipCallout.hide()

            if (tipInfo.success === true && RoomTabs.currentTab === TabName.Tokens) {
                userSwitchedTab.fire(TabName.Chat)
            }
        })
        this.tipCallout.closed.listen(() => {
            this.player.limitPortraitHeight(0)
        })

        this.sessionMetrics.bindTipCallout(this.tipCallout)
        this.loadRoom(roomName, true)
        this.repositionChildrenRecursive()
        this.afterDOMConstructedIncludingChildren()

        const repositionDebouncer = new Debouncer(() => {
            this.repositionChildrenRecursive()

            // TODO: Find a better fix without timeouts for scrolling issue
            // & also check if we really need this debouncer
            // https://multimediallc.leankit.com/card/30502080983333
            this.scrollAllChatContentsToBottom()
        }, { bounceLimitMS: 20, debounceType: DebounceTypes.debounce })

        screenOrientationChanged.listen(() => {
            if (isiOS()) {
                if (isPortrait()) {
                    this.hideAddressBarInLandscape(1.1)
                }
                window.scrollTo(0, this.mainContainer.scrollHeight)
            }
            repositionDebouncer.callFunc()
            this.player.centerPlayer()
            window.setTimeout(() => {
                // iOS Chrome sometimes resizes after an orientation change without calling resize (Fixed in iOS 13)
                // See: https://bugs.webkit.org/show_bug.cgi?id=170595
                repositionDebouncer.callFunc()
            }, 500)
        })

        let windowWidth = window.innerWidth
        window.onresize = () => {
            if (document.documentElement.scrollTop < 0) {
                // Pressing "Hide Toolbar" on iOS 13 causes the body to shift down, and it needs to be scrolled back up
                document.documentElement.scrollTop = 0
            }

            const orientationChanged = window.innerWidth !== windowWidth

            // resize gets fired on iOS when pull-to-refresh occurs from resizing the player,
            // so don't center the player in these cases
            const isIOSPullToRefresh = !orientationChanged && isiOS()

            if (!isIOSPullToRefresh || orientationChanged) {
                windowWidth = window.innerWidth
                this.player.centerPlayer()
            }
        }

        sendMessageInputFocus.listen(() => {
            this.player.setMessageInputFieldHasFocus(true)
        })
        sendMessageInputBlur.listen(() => {
            this.player.setMessageInputFieldHasFocus(false)
        })

        this.initPlayerMovementOnInputFocus()

        if (document.documentElement.clientHeight >= window.outerHeight) {
            // The viewport on Chrome on iOS will be larger than the actual window on initial Chrome launch.
            // Opening and closing the virtual keyboard will cause Chrome to correctly resize
            const forceReflow = () => {
                const input = document.createElement("input")
                input.style.fontSize = "16px" // prevent iOS zoom in on input
                document.body.appendChild(input)
                input.focus()
                input.blur()
                document.body.removeChild(input)
                removeEventListenerPoly("mousedown", this.element, forceReflow)
                removeEventListenerPoly("touchmove", this.element, forceReflow)
            }
            addEventListenerPoly("mousedown", this.element, forceReflow)
            addEventListenerPoly("touchmove", this.element, forceReflow)
        }

        this.preventCertainTouchActions()

        // Neither popstate nor pageshow reliably fire for history navigation when the history comes from a js
        // pushState or replaceState, but the popstate/pageshow listeners here each cover what the other misses
        addEventListenerPoly("popstate", window, (event: PopStateEvent) => {
            if (event.state !== undefined && event.state !== null) {
                this.loadRoom(event.state, false)
            } else if (hasFirstRoomLoaded && !isPuffin()) {
                // Needed for iOS. window.history.state == undefined on first back press after gone back through all rooms.
                // Needs the extra back to go back to previous page. Make sure only happens after room is loaded.
                // This does not happen in Android.
                history.back()
            }
        })

        addEventListenerPoly("pageshow", window, (event: PageTransitionEvent) => {
            if (event.persisted && this.currentChatConnection.room() !== this.currentRoom) {
                this.loadRoom(this.currentRoom, false)
            }
            const previousURL = document.referrer
            if (event.persisted && previousURL.includes("login")) {
                // We loaded the page from bfcache on mobile device,
                // we want to get fresh context
                this.isLoadFromBFCacheAfterSigningOut = true
                this.loadRoom(this.currentRoom, false)
            }
        })

        loadRoomRequest.listen((username) => {
            this.loadRoom(username, true)
        })

        roomLoaded.listen((context: IRoomContext) => {
            setDocumentTitle(context.dossier.room, context.dossier.roomTitle)
            this.repositionChildrenRecursive()
            if (!hasFirstRoomLoaded) {
                hasFirstRoomLoaded = true
            }

            this.headerRoomInfo.updateContext(context.dossier.room, context.dossier.following, context.dossier.roomStatus)
            this.headerRoomInfo.updateViewerCount(context.dossier.numViewers)

            if (context.dossier.roomStatus === RoomStatus.Offline) {
                userSwitchedTab.fire(TabName.Bio)
            } else {
                userSwitchedTab.fire(TabName.Chat)
            }

            this.sessionMetrics.playerDimensions()
        })

        this.player.playerComponent.showControls()

        getChatMode.fire("mobile")
    }

    private initDismissibleMessages(): void {
        this.mobileDismissibleMessages = new MobileDismissibleMessages()
        this.mainContainer.appendChild(this.mobileDismissibleMessages.element)
        this.resizeObserver?.observe(this.mobileDismissibleMessages.element)
    }

    private initPlayerMovementOnInputFocus(): void {
        // On non-fullscreen portrait keyboard showing, move player on top of the input to avoid the keyboard pushing part/all of it off screen.
        visualViewport?.addEventListener("resize", () => {
            if (isVirtualKeyboardLikelyShowing() && !this.player.isFullscreen && isPortrait() && !this.tipCallout.isShown()) {
                const inputHeight = inputDivHeight() - inputDivBorder
                const needsInputFix = needsSafariInputFix()
                const playerBottomVal = needsInputFix ? inputHeight + 50 : inputHeight
                const contentsTopVal = needsInputFix ? -50 : 0

                this.player.element.style.position = "fixed"
                this.player.element.style.top = ""
                this.player.element.style.bottom = `${playerBottomVal}px`
                this.player.element.style.zIndex = "1"
                this.player.videoControls.hideElement()
                this.portraitContents.element.style.top = `${contentsTopVal}px`
                this.portraitContents.element.style.height = "100%"
                repositionChatContentsOnInputFocus.fire({
                    isInputFocused: true,
                    playerTop: playerBottomVal + this.player.element.clientHeight,
                })
                this.player.roomStatusNotifier.setPlayerIsBottomPositioned(true)
            } else {
                this.player.element.style.position = "absolute"
                this.player.element.style.top = "0"
                this.player.element.style.bottom = ""
                this.player.element.style.zIndex = ""
                this.portraitContents.element.style.top = ""
                this.player.videoControls.showElement()
                repositionChatContentsOnInputFocus.fire({ isInputFocused: false })
                this.repositionChildrenRecursive()
                this.player.roomStatusNotifier.setPlayerIsBottomPositioned(false)
            }

            this.scrollAllChatContentsToBottom()
        })
    }

    public getMobilePlayer(): MobilePlayer {
        return this.player
    }

    private hideAddressBarInLandscape(factor: number): void {
        // When we move from landscape -> portrait -> landscape, we want to increase the main div height by a multiplying factor
        // so that it's greater than the viewport height.
        const viewportHeight = factor * (window.visualViewport?.height ?? window.innerHeight)
        this.mainContainer.style.height = `${viewportHeight}px`
    }

    private scrollAllChatContentsToBottom(): void {
        this.portraitContents.getAllChatContents().forEach((chatContents) => chatContents.scrollToBottom())
    }

    private passwordPrompt(room: string, setHistory: boolean): void {
        if (isNotLoggedIn(i18n.loginForPasswordPrompt(usernameTitleCase(room)), false, goToHomepage, { requiredForPage: true })) {
            return
        }
        const onCancelPrompt = () => {
            if (room === this.currentRoom) {
                window.location.reload()
            }
        }
        const password = prompt(i18n.passwordRequiredForRoom(room)) // eslint-disable-line no-alert
        if (password !== null) {
            postCb(`roomlogin/${room}/`, { password: password })
                .then((response) => {
                    const p = new ArgJSONMap(response.responseText)
                    const result = p.getStringOrUndefined("result")
                    if (result === "success") {
                        this.loadRoom(room, setHistory)
                    } else {
                        modalAlert(i18n.incorrectPassword, () => {
                            this.passwordPrompt(room, setHistory)
                        })
                    }
                }).catch((err) => {
                    error("Unable to login to room", { "room": room, "error": err })
                    onCancelPrompt()
                })
        } else {
            onCancelPrompt()
        }
    }

    private cleanupLastRoomCallback?: () => void

    private createChatConnection(roomDossier: IRoomDossier): ChatConnection {
        const chatConnection = new ChatConnection(roomDossier, true)

        const roomStatusTopic = new RoomStatusTopic(roomDossier.roomUid)
        const authFailListener = roomStatusTopic.onAuthFail.once(() => {
            getRoomAccess(roomDossier.room).then(resp => {
                const parsedResponse = new ArgJSONMap(resp.responseText)
                const code = parsedResponse.getString("code", false)
                const detail = parsedResponse.getString("detail", false)
                const accessCodeHandled = this.handleRoomAccessCode(roomDossier.room, code, detail)
                if (!accessCodeHandled) {
                    modalAlert(i18n.roomLoadError)
                    error("Error loading room", { "error": resp.responseText })
                }
            }).catch(ignoreCatch)
        })
        authFailListener.addTo(this.listenerGroup)
        roomStatusTopic.onSubscribeChange.once(() => {
            authFailListener.removeListener()
        }).addTo(this.listenerGroup)

        return chatConnection
    }

    private loadRoom(username: string, setHistory: boolean): void {
        let roomDossierPromise: Promise<IRoomDossier>
        if (!hasFirstRoomLoaded && pageContext.current.isLoadedFromCache !== true) {
            roomDossierPromise = parseRoomDossier(username, window["initialRoomDossier"]!)
        } else {
            roomDossierPromise = getRoomDossier(username)
        }
        roomDossierPromise.then((roomDossier) => {
            dossierLoaded.fire(roomDossier)
            if (this.cleanupLastRoomCallback !== undefined) {
                this.cleanupLastRoomCallback()
            }
            this.currentRoom = username
            setCurrentRoom(username)
            roomDossierContext.setState(roomDossier)
            this.currentChatConnection = this.createChatConnection(roomDossier)
            this.sessionMetrics.bindChatConnection(this.currentChatConnection)
            setDocumentTitle(roomDossier.room, roomDossier.roomTitle)
            this.currentChatConnection.event.titleChange.listen((title: string) => {
                setDocumentTitle(roomDossier.room, title)
            }).addTo(this.listenerGroup)
            userChatSettingsUpdate.listen((userChatSettings) => {
                this.currentChatConnection.updateEnterLeaveSettings(userChatSettings.roomEntryFor, userChatSettings.roomLeaveFor)
            }).addTo(this.listenerGroup)

            this.audioHolder.loadTipSounds()

            this.currentChatConnection.event.playSound.listen((sound: AudioHolderSound) => {
                if (!isDocumentVisible()) {
                    return
                }
                let volume = this.player.playerComponent.getVolume()
                if (this.player.playerComponent instanceof HlsPlayer) {
                    volume = this.player.playerComponent.getMuted() ? 0 : this.player.playerComponent.getVolume()
                }
                if (isNaN(volume)) {
                    error("playerComponent.getVolume() is NaN.", {}, SubSystemType.Video)
                } else {
                    this.audioHolder.playSound(sound, roomDossier.userChatSettings.tipVolume * volume / 100)
                }
            }).addTo(this.listenerGroup)
            this.player.setIsWidescreen(roomDossier.isWidescreen)

            let roomCountInterval: number
            let roomChangeTimeout: number
            this.cleanupLastRoomCallback = () => {
                clearInterval(roomCountInterval)
                window.clearTimeout(roomChangeTimeout)
                this.currentChatConnection.disconnect()
                this.player.playerComponent.stop()
                this.listenerGroup.removeAll()
                roomCleanup.fire(undefined)
                this.cleanupLastRoomCallback = undefined
            }
            const context = {
                dossier: roomDossier,
                chatConnection: this.currentChatConnection,
            }

            this.player.playerComponent.handleRoomLoaded(context)
            this.player.playerComponent.playbackStart.listen(() => {
                smcOnRoomPlaybackStart(roomDossier, this.player.playerComponent)
            }).addTo(this.listenerGroup)

            if (setHistory) {
                addHistory(roomDossier)
            }

            roomLoaded.fire(context)

            // eslint-disable-next-line complexity
            this.currentChatConnection.event.statusChange.listen(roomStatusChangeNotification => {
                this.headerRoomInfo.updateStatus(roomStatusChangeNotification.currentStatus)

                // Room count updates also ping and update users presence in public/private room
                const isOffline = roomStatusChangeNotification.currentStatus === RoomStatus.Offline

                const inPrivateStates = [RoomStatus.PrivateWatching, RoomStatus.PrivateSpying]
                const enteredPrivate = inPrivateStates.includes(roomStatusChangeNotification.currentStatus)
                const leftPrivate = inPrivateStates.includes(roomStatusChangeNotification.previousStatus)

                if (!isOffline && (roomStatusChangeNotification.previousStatus === RoomStatus.NotConnected || enteredPrivate || leftPrivate)) {
                    clearInterval(roomCountInterval)
                    roomCountInterval = window.setInterval(() => {
                        this.currentChatConnection.updateRoomCount(enteredPrivate)
                    }, (enteredPrivate ? 5 : 90) * 1000)

                    roomChangeTimeout = window.setTimeout(() => {
                        this.currentChatConnection.updateRoomCount(enteredPrivate || leftPrivate)
                    }, 2000) // Delay to ensure user is connected to room.
                }
                switch (roomStatusChangeNotification.currentStatus) {
                    case RoomStatus.PasswordProtected:
                        this.cleanupLastRoomCallback?.()
                        PushService.forceUpdateAuthorization()
                        this.passwordPrompt(this.currentChatConnection.room(), false)
                        break
                    case RoomStatus.Offline:
                        this.player.playerComponent.stop()
                        break
                }

                if (this.currentChatConnection.inPrivateRoom()) {
                    const privateShowChatContents = this.portraitContents.getPrivateShowChatContents()
                    if (privateShowChatContents === undefined) {
                        return
                    }
                    if (!this.privateTabPureChat.isMirroringComponent(privateShowChatContents)) {
                        this.privateTabPureChat?.dispose()
                        this.privateTabPureChat = new MobilePureChat(privateShowChatContents)
                    }
                    this.player.videoControls.setPureChat(this.privateTabPureChat)
                } else {
                    this.player.videoControls.setPureChat(this.chatTabPureChat)
                }
            }).addTo(this.listenerGroup)
        }).catch((e) => {  // eslint-disable-line complexity
            let code = ""
            let detail = ""
            let errorText = ""
            if (typeof e === "string") {
                // Error is from parseRoomDossier
                // match is effectively: split on [space, comma, ", :, {, }] but don't split on anything within quotes
                const splitErr = e.match(/(?:[^\s,":{}]+|"[^"]*")+/g)
                if (splitErr !== null) {
                    // Can't leave out all quotes using the match above, so strip them here
                    const splitErrMap = splitErr.map(str => str.replace(/"/g, ""))
                    let idx = splitErrMap.indexOf("code")
                    if (idx !== -1) {
                        code = splitErrMap[idx + 1]
                    }
                    idx = splitErrMap.indexOf("detail")
                    if (idx !== -1) {
                        detail = splitErrMap[idx + 1]
                    }
                }
                errorText = e
            } else if (e.xhr !== undefined && e.xhr.responseText !== undefined && e.xhr.responseText !== "") {
                // Error is from getRoomDossier
                if (e.xhr.getResponseHeader("Content-Type") !== "application/json") {
                    error("Error reading room dossier error", { "room": username, "error": e.xhr.responseText })
                    return
                }
                const p = new ArgJSONMap(e.xhr.responseText)
                code = p.getString("code", false)
                detail = p.getString("detail", false)
                errorText = e.xhr.responseText
            } else if (e instanceof TypeError) {
                code = "access-denied"
            }

            const accessCodeHandled = this.handleRoomAccessCode(username, code, detail)
            if (!accessCodeHandled) {
                error("Error parsing room dossier error", { "room": username, "error": errorText })
            }
        })
    }

    private handleRoomAccessCode(username: string, code: string, detail: string): boolean {
        switch (code) {
            case RoomAccessCode.AccessDenied:
                modalAlert(`Access Denied for room: ${username}\n\n${detail}`, () => {
                    if (!hasFirstRoomLoaded) {
                        goToHomepage()
                    }
                })
                return true
            case RoomAccessCode.Unauthorized:
                if (this.isLoadFromBFCacheAfterSigningOut) {
                    // Reset value
                    this.isLoadFromBFCacheAfterSigningOut = false
                }
                window.location.href =
                    normalizeResource(`/auth/login/?next=${window.location.pathname}${window.location.search}`)
                return true
            case RoomAccessCode.PasswordProtected:
                if (this.isLoadFromBFCacheAfterSigningOut) {
                    this.isLoadFromBFCacheAfterSigningOut = false

                    window.location.href = normalizeResource(`/auth/login/?next=${window.location.pathname}`)
                    return true
                }
                this.passwordPrompt(username, true)
                return true
            case RoomAccessCode.Ok:
                return true
        }
        return false
    }

    private preventCertainTouchActions(): void {
        const isEntranceTermsShown = () => {
            const entraceTerms = document.getElementById("entrance_terms")
            return entraceTerms !== null && entraceTerms.style.display !== "none"
        }

        let startTouch: TouchEvent | undefined
        let isTracking = false

        addEventListenerPoly("touchstart", window, (event: TouchEvent) => {
            if (isEntranceTermsShown()) {
                return
            }

            const isPinch = event.touches.length > 1

            if (isPinch && !isTracking) {
                addPageAction("PinchZoomStarted")
                isTracking = true
            }

            if (isPinch && isKeyboardOpen() && event.cancelable) {
                event.preventDefault()
            }

            startTouch = event
        })

        addEventListenerPoly("touchmove", window, (event: TouchEvent) => {
            if (isEntranceTermsShown() || isPushmenuOpen()) {
                return
            }

            const currY = event.touches[0].pageY
            const startY = startTouch?.touches[0].pageY ?? currY
            const isScrollingVertically = currY !== startY

            if (isScrollingVertically && isKeyboardOpen() && event.cancelable) {
                event.preventDefault()
            }
        })

        addEventListenerPoly("touchend", window, (event: TouchEvent) => {
            if (isEntranceTermsShown()) {
                return
            }

            startTouch = undefined
            isTracking = false
        })
    }

    private adjustDimensions(forceFullScreen = false): void {
        if (forceFullScreen || !isPortrait()) {
            this.element.style.top = "0px"
            this.element.style.height = "100%"
            this.element.style.minHeight = "100%"
            this.hidePushMenu()
            this.mobileDismissibleMessages.hideElement()
        } else {
            this.mobileDismissibleMessages.showElement()
            const dismissibleMessagesHeight = this.mobileDismissibleMessages.element.offsetHeight
            const offset = this.siteHeaderHeight + dismissibleMessagesHeight

            this.element.style.top = `${offset}px`
            this.element.style.height = `calc(100% - ${offset}px)`
            this.element.style.minHeight = `calc(100% - ${offset}px)`
        }
    }

    protected repositionChildren(): void {
        super.repositionChildren()
        this.adjustDimensions(this.isFullscreen)
    }

    private hidePushMenu(): void {
        siteHeaderMenuOpened.fire(false)
        this.pushMenuOverlay.style.display = "none"
        this.pushMenu.classList.remove("pushmenu-animate")
        this.siteHeader.classList.remove("push-page-content")
        this.pushMenuContainer.style.display = "none"
    }

    public toggleAdvancedOptions(open: boolean): void {
        // only handles portraitContents; mobileVideoControls handles landscape/fullscreen
        if (isPortrait() && !this.player.isFullscreen) {
            this.portraitContents.toggleAdvancedOptions(open)
        }
    }
}
