import { GenderedMoreRoomsList } from "../../cb/components/roomlist/moreRooms/genderedMoreRoomsList"
import { MoreRoomsList } from "../../cb/components/roomlist/moreRooms/moreRoomsList"
import { Debouncer } from "../debouncer"
import { followingEvent } from "../follow"
import { userCategoryToAbbrev } from "../genders"
import { getGenderIconUrl } from "../roomUtil"
import { dom } from "../tsxrender/dom"
import { MobileMoreRoomsCard, roomCardInfoHeight, roomImageHeightRatio, roomMargin, roomPadding } from "./moreRoomsCard"
import { getViewportWidth } from "./viewportDimension"
import type { IGenderedMoreRoomsListProps } from "../../cb/components/roomlist/moreRooms/genderedMoreRoomsList"
import type { IRoomCardProps } from "../../cb/components/roomlist/roomCard"

class MobileMoreRoomsList extends MoreRoomsList<MobileMoreRoomsCard> {
    protected createRoomCard(roomProps: IRoomCardProps): MobileMoreRoomsCard {
        return new MobileMoreRoomsCard(roomProps)
    }

    createElement(): HTMLUListElement {
        return <ul className="MobileMoreRoomsList" />
    }
}

export class MobileGenderedMoreRoomsList extends GenderedMoreRoomsList<MobileMoreRoomsCard, HTMLInputElement> {
    private streamVisibleRoomsDebouncer: Debouncer
    private hasInitialLoaded: boolean

    constructor(props: IGenderedMoreRoomsListProps) {
        super(props)

        this.streamVisibleRoomsDebouncer = new Debouncer(() => {
            this.streamVisibleRooms()
        }, { bounceLimitMS: 200 })

        this.hasInitialLoaded = false

        followingEvent.listen((event) => {
            const room = this.children().find(room => room.getRoomName() === event.roomName)

            if (room !== undefined) {
                room.updateFollowStar(event.following)
            }
        })
    }

    updateSelectedGendersFromCheckboxes(): void {
        this.selectedGenders = []
        for (const checkbox of this.genderCheckboxes) {
            if (checkbox.checked) {
                this.selectedGenders.push(userCategoryToAbbrev(checkbox.name))
            }
        }
    }

    protected initUI(): void {
        super.initUI()
        this.element.appendChild(this.moreRoomsList.element)
    }

    protected initData(props: IGenderedMoreRoomsListProps): void {
        super.initData(props)
        this.moreRoomsList = new MobileMoreRoomsList()
    }

    protected createElement(props: object): HTMLDivElement {
        const elStyle: CSSX.Properties = {
            height: "100%",
            overflow: "hidden",
        }
        return <div style={elStyle} />
    }

    getScrolledElement(): HTMLElement {
        return this.moreRoomsList.element
    }

    createLoadingDiv(): HTMLDivElement {
        return <LoadingDiv></LoadingDiv>
    }

    protected roomWidth(): number {
        const adjacentPadding = 12
        return (getViewportWidth() - roomPadding() * 2 - adjacentPadding) / 2
    }

    protected roomHeight(): number {
        const height = this.roomWidth() * roomImageHeightRatio()
        return height + roomCardInfoHeight()
    }

    repositionChildren(): void {
        super.repositionChildren()
        const roomWidth = this.roomWidth()
        for (const child of this.children()) {
            child.element.style.width = `${roomWidth}px`
            child.element.style.height = `${this.roomHeight()}px`
        }
        if (this.visible) {
            this.loadMoreRoomsIfNearBottom()
        }
    }

    handleScroll(): void {
        this.removeRoomsScrolledPastTop()
        this.loadMoreRoomsIfNearBottom()
        this.streamVisibleRoomsDebouncer.callFunc()
    }

    listVisible(): void {
        this.visible = true

        if (!this.hasInitialLoaded) {
            this.resetAndLoad()
            this.hasInitialLoaded = true
        }
    }

    listHidden(): void {
        this.visible = false
        for (const room of this.children()) {
            room.stopStreaming()
        }
    }

    private streamVisibleRooms(): void {
        if (!this.visible) {
            return
        }
        // make sure any modifications to room dimensions happen first
        // to calculate visiblity accurately
        this.repositionChildren()

        for (const room of this.children()) {
            const fromTop = room.element.offsetTop - this.moreRoomsList.element.scrollTop
            const hiddenTop = Math.min(0, fromTop)
            const fromBottom = this.moreRoomsList.element.scrollTop + this.moreRoomsList.element.offsetHeight - (room.element.offsetTop + room.element.offsetHeight)
            const hiddenBottom = Math.min(0, fromBottom)
            const visible = Math.max(0, room.element.offsetHeight + hiddenBottom + hiddenTop)
            const visiblePercent = visible / room.element.offsetHeight

            room.stopStreaming()

            if (visiblePercent >= 0.6) {
                room.startStreaming()
            }
        }
    }

    buildGenderCheckbox(gender: string): HTMLSpanElement {
        const blurCheckbox = () => {
            checkBox.blur()
        }
        const checkBox: HTMLInputElement = <input
            type="checkbox"
            style={{
                height: "34px",
                verticalAlign: "middle",
            }}
            name={gender.toLowerCase()}
            data-testid={`${gender.toLocaleLowerCase()}-checkbox`}
            // mouseup & touchend used to prevent bug where More Rooms tab can't scroll when there's focus on checkbox
            onMouseUp={blurCheckbox}
            onTouchEnd={blurCheckbox}
            onChange={() => {
                this.handleGenderChange()
            }}></input>

        checkBox.checked = this.selectedGenders.indexOf(userCategoryToAbbrev(gender.toLowerCase())) > -1

        const wrapper = <span
            style={{
                display: "inline-block",
                width: "44px",
                height: "37px",
                margin: "0 16px 0 8px",
                textAlign: "center",
            }}>{checkBox}
            <img src={getGenderIconUrl(gender)}
                style={{
                    verticalAlign: "middle",
                    cursor: "pointer",
                    width: "16px",
                    height: "16px",
                }}
                onClick={() => {
                    checkBox.checked = !checkBox.checked
                    this.handleGenderChange()
                }}></img>
        </span>

        this.genderCheckboxes.push(checkBox)
        return wrapper
    }

    protected calculateScrollRemovalThreshold(numOfRooms: number, includePadding = true): number {
        return numOfRooms * (this.roomHeight() + roomMargin() + 2) + (includePadding? roomPadding() : 0)
    }
}

const LoadingDiv = (): HTMLDivElement => {
    const containerStyle: CSSX.Properties = {
        display: "flex",
        flex: 1,
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
    }
    const spinnerStyle: CSSX.Properties = {
        background: `url("${STATIC_URL_ROOT}images/loading_spinner.svg")`,
        backgroundSize: "cover",
        width: "30px",
        height: "31px",
        animationName: "spin",
        animationDuration: "2s",
        animationTimingFunction: "linear",
        animationIterationCount: "infinite",
        animationDelay: "0.01s",
    }

    return (
        <div style={containerStyle}>
            <div style={spinnerStyle} />
        </div>
    )
}
