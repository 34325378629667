import { isLocalStorageSupported } from "@multimediallc/web-utils/modernizr"
import { pageContext } from "../../cb/interfaces/context"
import { modalAlert, modalConfirm } from "../alerts"
import { Component } from "../defui/component"
import { EventRouter } from "../events"
import { followingEvent, followRoom, unfollowRoom } from "../follow"
import { OptimisticUpdate } from "../optimisticUpdate"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"
import { NotificationsModal } from "./notificationsModal"

interface IFollowButtonState {
    isFollowing: boolean,
    isPending?: boolean,
}

export class FollowButton extends Component {
    protected roomName: string
    private isFollowing: boolean
    protected followButton: HTMLDivElement
    protected unfollowButton: HTMLDivElement
    private isFollowingUpdate: OptimisticUpdate<boolean>
    public followStateChanged: EventRouter<IFollowButtonState>

    constructor() {
        super()
    }

    protected initData(): void {
        this.followStateChanged = new EventRouter<IFollowButtonState>("followStateChanged")
        this.isFollowingUpdate = new OptimisticUpdate<boolean>(
            false,
            (isFollowing: boolean, isPending: boolean) => this.setIsFollowing(isFollowing, isPending),
            () => {
                const followError = this.isFollowing
                    ? i18n.errorUnfollowingUser(this.roomName)
                    : i18n.errorFollowingUser(this.roomName)
                modalAlert(followError)
            },
        )
    }

    protected initUI(): void {
        this.followButton = <FollowButtonElement onClick={() => {this.handleFollowAndUnfollow()}} />
        this.unfollowButton = <UnfollowButtonElement onClick={() => {this.onUnfollowClick()}} />

        this.element = 
            <div colorClass="FollowButton" style={{ cssFloat: "left" }}>
                {this.followButton}
                {this.unfollowButton}
            </div>
        

        followingEvent.listen((event) => {
            if (event.roomName === this.roomName) {
                this.updateContext(this.roomName, event.following)
            }
        })
    }

    protected onUnfollowClick(): void {
        modalConfirm(i18n.unfollowInfo, () => { this.handleFollowAndUnfollow() }, void 0, {
            title: `${i18n.unfollowText} ${this.roomName}?`,
            acceptText: `${i18n.yes}, ${i18n.unfollowLower}`,
            declineText: `${i18n.no}, ${i18n.cancelLower}`,
        })
    }

    protected updateFollowButtonDisplay(isPending?: boolean): void {
        this.followButton.style.display = this.isFollowing ? "none" : "flex"
        this.unfollowButton.style.display = !this.isFollowing ? "none" : "flex"
        this.followStateChanged.fire({
            isFollowing: this.isFollowing,
            isPending: isPending,
        })
    }

    public updateContext(roomName: string, isFollowing: boolean): void {
        this.roomName = roomName
        this.isFollowing = isFollowing
        this.updateFollowButtonDisplay()
    }

    public showBrowserNotification(): void {
        const isAnonymous = pageContext.current.loggedInUser === undefined

        if (!isAnonymous) {
            const isBrowserNotificationSupported = window["isBrowserNotificationSupported"]
            const isSubscribedToBrowserNotifications = window["isSubscribedToBrowserNotifications"]

            if (
                isBrowserNotificationSupported !== undefined &&
                isBrowserNotificationSupported() === true &&
                isSubscribedToBrowserNotifications !== undefined &&
                isLocalStorageSupported()
            ) {
                const notificationModal = new NotificationsModal()
                if (notificationModal.canRequestPermission()) {
                    isSubscribedToBrowserNotifications(false, () => {
                        this.addChild(notificationModal, document.body)
                        notificationModal.closed.once(() => {
                            this.removeChild(notificationModal)
                        })
                    })
                }
            }
        }
    }

    protected handleFollowAndUnfollow(): void {
        const isAnonymous = pageContext.current.loggedInUser === undefined
        if (!this.isFollowing){
            this.showBrowserNotification()
        }

        const followAction = this.isFollowing ? unfollowRoom : followRoom
        const response = followAction(this.roomName, isAnonymous, "FollowButton")
        if (response === undefined) {
            return // user is not logged in
        }
        this.isFollowingUpdate.dispatch(!this.isFollowing, response) // flip the current following state
    }

    private setIsFollowing(isFollowing: boolean, isPending: boolean): void {
        this.isFollowing = isFollowing
        this.updateFollowButtonDisplay(isPending)
    }
}

const FollowButtonElement = (props: { onClick: () => void }) => {
    const buttonStyle: CSSX.Properties = {
        display: "none",
        borderWidth: "1px",
        borderStyle: "solid",
        boxSizing: "border-box",
        borderRadius: "4px",
        marginRight: "4px",
        padding: "0 8px",
        justifyContent: "center",
        alignItems: "center",
    }
    const starStyle: CSSX.Properties = {
        position: "relative",
        padding: "2px 3px 1px 0px",
        marginLeft: "-6px",
        height: "29px",
        width: "29px",
        backgroundSize: "28px 28px",
        display: "inline-block",
        verticalAlign: "middle",
    }
    const followTextStyle: CSSX.Properties = {
        display: "inline-block",
        verticalAlign: "middle",
    }

    return (
        <div colorClass="follow" style={buttonStyle} onClick={props.onClick}>
            <span className="icon_not_following no_hover" style={starStyle} />
            <span style={followTextStyle}>{i18n.followText}</span>
        </div>
    )
}

const UnfollowButtonElement = (props: { onClick: () => void }) => {
    const buttonStyle: CSSX.Properties = {
        display: "none",
        borderWidth: "1px",
        borderStyle: "solid",
        boxSizing: "border-box",
        borderRadius: "4px",
        marginRight: "4px",
        justifyContent: "center",
        alignItems: "center",
    }
    const starStyle: CSSX.Properties = {
        position: "relative",
        padding: "2px 4px 1px 4px",
        height: "29px",
        width: "29px",
        backgroundSize: "28px 28px",
    }

    return (
        <div colorClass="unfollow" style={buttonStyle} onClick={props.onClick}>
            <span className="icon_following no_hover" style={starStyle} />
        </div>
    )
}
