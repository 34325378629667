import { addEventListenerPoly } from "../../common/addEventListenerPolyfill"
import { Debouncer, DebounceTypes, StartEndDebouncer } from "../../common/debouncer"
import { EventRouter } from "../../common/events"
import type { Component } from "../../common/defui/component"
import type { BoundListener, Params } from "../../common/events"

type ScrollListenerBindingObject = HTMLElement | Document

export function registerScrollStartEndDebouncer(element: ScrollListenerBindingObject, fn: (isStart: boolean) => void): StartEndDebouncer {
    const scrollStartEndDebouncer = new StartEndDebouncer(fn)
    addEventListenerPoly("scroll", element, () => {
        scrollStartEndDebouncer.callFunc()
    })
    return scrollStartEndDebouncer
}

export const enum ScrollOrientation {
    VERTICAL = 0,
    HORIZONTAL = 1,
}

export class ScrollStartEndDebounceEvent extends EventRouter<IScrollStartEndEvent> {
    private lastScroll: number

    constructor(
        private listenerObj: ScrollListenerBindingObject,
        private readonly scrollOrientation: ScrollOrientation = ScrollOrientation.VERTICAL,
        options?: Partial<Params>,
    ) {
        super("scrollEndDebounceEvent", options)
        this.lastScroll = this.getCurrentScroll()
    }

    private get element(): HTMLElement {
        return this.listenerObj instanceof Document ? this.listenerObj.documentElement : this.listenerObj
    }

    private getCurrentScroll(): number {
        return this.scrollOrientation === ScrollOrientation.VERTICAL ? this.element.scrollTop : this.element.scrollLeft
    }

    listen(listener: (event: IScrollStartEndEvent) => void, syncHistory = true): BoundListener<IScrollStartEndEvent> {
        const res = super.listen(listener, syncHistory)
        this.registerDebouncer()
        return res
    }

    addListener(listener: (event: IScrollStartEndEvent) => void, listeningSource: Component | HTMLElement): void {
        super.addListener(listener, listeningSource)
        this.registerDebouncer()
    }

    private registerDebouncer() {
        registerScrollStartEndDebouncer(this.listenerObj, (isStart) => {
            const currScroll = this.getCurrentScroll()
            this.fire({
                isStart: isStart,
                scrollDirection: currScroll > this.lastScroll ? ScrollDirection.FORWARD : ScrollDirection.BACKWARD,
            })
            this.lastScroll = currScroll
        })
    }
}

export interface IScrollStartEndEvent {
    isStart: boolean
    scrollDirection: ScrollDirection
}

export const enum ScrollDirection {
    BACKWARD = 0,
    FORWARD = 1,
}

export function smoothScrollPoly(scrollElement: HTMLElement, endOffset: number, scrollTime: number): void {
    const currentPos = scrollElement.scrollLeft
    let start: number | undefined
    const time = scrollTime
    const step = (currentTime: number) => {
        start = start === undefined ? currentTime : start
        const progress = currentTime - start
        if (currentPos < endOffset) {
            scrollElement.scrollLeft = currentPos + (endOffset - currentPos) * progress / time
        } else {
            scrollElement.scrollLeft = currentPos - (currentPos - endOffset) * progress / time
        }
        if (progress < time) {
            window.requestAnimationFrame(step)
        } else {
            scrollElement.scrollLeft = endOffset
        }
    }
    window.requestAnimationFrame(step)
}

export function registerScrollDebouncer(element: ScrollListenerBindingObject, fn: () => void, delay = 100): Debouncer {
    const scrollDebouncer = new Debouncer(fn, { bounceLimitMS: delay, debounceType: DebounceTypes.trailThrottle })
    addEventListenerPoly("scroll", element, () => {
        scrollDebouncer.callFunc()
    })
    return scrollDebouncer
}
