import { addEventListenerPoly, removeEventListenerPoly } from "../../common/addEventListenerPolyfill"
import { modalAlert } from "../../common/alerts"
import { EventRouter } from "../../common/events"
import { addPageAction } from "../../common/newrelic"
import { OverlayComponent } from "../../common/overlayComponent"
import { i18n } from "../../common/translation"
import { addColorClass } from "../colorClasses"
import { pageContext } from "../interfaces/context"

export function createTitleElement(): HTMLElement {
    const titleElement = document.createElement("div")
    addColorClass(titleElement, "title")
    titleElement.innerText = i18n.followerBrowserNotifications
    titleElement.style.fontFamily = "UbuntuBold, Helvetica, Arial, sans-serif"
    return titleElement
}

export function createDescriptionElement(): HTMLElement {
    const descriptionElement = document.createElement("div")
    descriptionElement.innerText = i18n.allowBrowserNotificationsMessage
    descriptionElement.style.margin = "5px 0 10px"
    descriptionElement.style.fontSize = "11px"
    return descriptionElement
}

export function createConfirmButton(clickListener: EventListener): HTMLElement {
    const confirmButton = document.createElement("div")
    addColorClass(confirmButton, "confirmButton")
    confirmButton.innerText = i18n.yesText
    confirmButton.style.display = "inline-block"
    confirmButton.style.padding = "5px 30px"
    confirmButton.style.marginLeft = "10px"
    confirmButton.style.borderRadius = "3px"
    confirmButton.style.cursor = "pointer"
    addEventListenerPoly("click", confirmButton, clickListener)
    return confirmButton
}

export function createDenyButton(clickListener: EventListener): HTMLElement {
    const denyButton = document.createElement("div")
    addColorClass(denyButton, "denyButton")
    denyButton.innerText = i18n.notNowText
    denyButton.style.display = "inline-block"
    denyButton.style.padding = "5px 18px"
    denyButton.style.cursor = "pointer"
    addEventListenerPoly("click", denyButton, clickListener)
    return denyButton
}

export interface INotificationTimeoutLocalStorageKeys {
    numTimeoutsKey: string
    timeoutDateKey: string
}

const NotificationModalLocalStorageKeys: INotificationTimeoutLocalStorageKeys = {
    numTimeoutsKey: "numPushNotificationTimeouts",
    timeoutDateKey: "pushNotificationTimeout",
}

export abstract class BaseNotificationsModal extends OverlayComponent {
    private timeoutDate: Date
    private numTimeouts: number
    private skipOverlay: boolean
    protected numTimeoutsLocalStorageKey: string
    protected timeoutDateLocalStorageKey: string

    public closed = new EventRouter<undefined>("closed")

    constructor(props = NotificationModalLocalStorageKeys, skipOverlay = false) {
        super(props)
        if (skipOverlay) {
            this.skipOverlay = true
            const outSideHandlerClick = (event: MouseEvent) => {
                if (event.target === this.element || !this.element.contains(event.target as Node)) {
                    this.close()
                }
            }
            addEventListenerPoly("click", document, outSideHandlerClick)
    
            this.closed.once(() => {
                try {
                    removeEventListenerPoly("click", document, outSideHandlerClick)
                } catch (e) {
                    debug("NotificationsModal listener was already removed")
                }
            })
            return
        }

        this.overlayClick.listen(() => {
            this.close()
        })
    }

    protected initData(props: INotificationTimeoutLocalStorageKeys): void {
        super.initData()

        this.numTimeoutsLocalStorageKey = props.numTimeoutsKey
        this.timeoutDateLocalStorageKey = props.timeoutDateKey
        const numTimeoutsString = window.localStorage.getItem(this.numTimeoutsLocalStorageKey)
        const timeoutDateString = window.localStorage.getItem(this.timeoutDateLocalStorageKey)
        if (numTimeoutsString !== null && timeoutDateString !== null) {
            this.timeoutDate = new Date(timeoutDateString)
            this.numTimeouts = parseInt(numTimeoutsString) + 1
        } else {
            this.timeoutDate = new Date()
            this.numTimeouts = 1
        }
    }

    afterDOMConstructedIncludingChildren(): void {
        super.afterDOMConstructedIncludingChildren()
        if (!this.skipOverlay) {
            this.showOverlay()
        }
    }

    public canRequestPermission(): boolean {
        return new Date() >= this.timeoutDate
    }

    public close(): void {
        this.element.style.display = "none"
        if (!this.skipOverlay){
            this.hideOverlay()
        }
        this.closed.fire(undefined)
    }

    protected subscribeToBrowserPush(successFunc: () => void, failFunc: () => void): void {
        const subscribeUserToPush = window["subscribeUserToPush"]
        if (pageContext.current.loggedInUser !== undefined) {
            const signedUsername = pageContext.current.loggedInUser.signedUsername
            subscribeUserToPush(signedUsername).then(() => {
                successFunc()
                addPageAction("PushNotificationSettingsUpdated", { "new_status": true, "old_status": false, "location": "notif-modal" })
            }).catch(failFunc)
        }
    }

    protected onConfirmClick(): void {
        this.subscribeToBrowserPush(
            () => {},
            (response?: XMLHttpRequest) => {
                if (response === undefined || response.status === 400) {
                    modalAlert(i18n.followSubscribeFailedMessage)
                } else {
                    window.localStorage.removeItem(this.numTimeoutsLocalStorageKey)
                    window.localStorage.removeItem(this.timeoutDateLocalStorageKey)
                }
            },
        )
    }

    protected onDenyClick(): void {
        const currDate = new Date()
        const numHours = Math.min(Math.pow(2, this.numTimeouts), 168)
        this.timeoutDate.setTime(currDate.getTime() + numHours * 60 * 60 * 1000)
        window.localStorage.setItem(this.numTimeoutsLocalStorageKey, this.numTimeouts.toString())
        window.localStorage.setItem(this.timeoutDateLocalStorageKey, this.timeoutDate.toString())
    }
}
