import { ArgJSONMap } from "@multimediallc/web-utils"
import { isGoodStatus, XhrError } from "../../../common/api"
import { addPageAction } from "../../../common/newrelic"
import { createAuthRequest } from "../auth"
import { ReportedActions } from "../baseClient"
import { HERMOD_CLIENT_NAME } from "./hermod"
import { HermodContext } from "./hermodContext"
import type { HermodClient } from "./hermod"
import type { IHermodTokenRequest } from "./hermodContext"
import type { IAuthProvider } from "../auth"

export class HermodAuthProvider implements IAuthProvider {
    public context: HermodContext | undefined
    private consecutiveAuthFails = 0

    constructor(private client: HermodClient, context?: HermodContext) {
        this.context = context
    }

    public serialize(): string {
        if (this.context?.isValid() !== true) {
            return ""
        }
        return this.context.serialize()
    }

    public fetchAccessToken(): Promise<string> {
        return this.getRequestToken().then(requestToken => {
            return this.getAccessToken(requestToken)
        })
    }

    private getRequestToken(): Promise<IHermodTokenRequest> {
        return new Promise<IHermodTokenRequest>((resolve, reject) => {
            createAuthRequest(HERMOD_CLIENT_NAME).then((authCtx) => {
                this.consecutiveAuthFails = 0
                const failures = authCtx.getObjectOrUndefined("failures")
                if (typeof failures === "object" && Object.keys(failures).length !== 0) {
                    addPageAction("PushServiceClient", {
                        "action": ReportedActions.token_request_failed_topics,
                        "topics": JSON.stringify(failures),
                        "client": HERMOD_CLIENT_NAME,
                    })
                }
                this.context = new HermodContext(authCtx)
                if (this.context.isValid()) {
                    this.client.context = this.context
                    const tokenRequest = this.context.getTokenRequest()
                    resolve(tokenRequest)
                } else {
                    reject("Invalid token request")
                }
            }).catch((error) => {
                this.consecutiveAuthFails += 1
                if (this.consecutiveAuthFails >= 3) {
                    // Prevent trying to reconnect every 30 seconds
                    debug("Should be closing connection and reverting to ably/wowza")
                    // this.realtime.connection.close()
                }
                reject(error)
            })
        })
    }

    private getAccessToken(requestToken: IHermodTokenRequest): Promise<string> {
        return new Promise((resolve, reject) => {
            if (this.context === undefined) {
                reject("no context")
                return
            }
            // eslint-disable-next-line @multimediallc/no-xhr
            const xhr = new XMLHttpRequest()
            const url = this.context.settings.url === undefined ? "" : `http://${this.context.settings.url}`
            xhr.open("POST", `${url}/auth/access_token`, true)
            xhr.setRequestHeader("Content-Type", "application/json;charset=UTF-8")
            xhr.onload = () => {
                if (isGoodStatus(xhr.status)) {
                    const args = new ArgJSONMap(xhr.responseText)
                    resolve(args.getString("access_token"))
                } else {
                    reject(new XhrError(xhr))
                }
            }
            xhr.send(JSON.stringify({ "request_token": requestToken }))
        })
    }

    public canAccessTopic(topicKey: string): boolean {
        return this.context?.getChannelName(topicKey) !== undefined
    }

    public getTopicKeys(): string[] {
        return []
    }

    public getFailedTopics(): string[] {
        // cb dev client doesnt have any failed topics
        return []
    }

    public updateAuthToken(): Promise<void> {
        return this.client.updateAuth()
    }

    public reportFailedTopics(authContext: ArgJSONMap): void {
        debug("Failed topics on dev service", authContext.stringMessage)
    }

    // HermodAuthProvider doesn't store its auth, so nothing to invalidate
    public invalidateAuth(): void {}
}
