import { ArgJSONMap } from "@multimediallc/web-utils"
import { HTMLComponent } from "../../../common/defui/htmlComponent"
import { i18n } from "../../../common/translation"
import { dom } from "../../../common/tsxrender/dom"
import { safeWindowOpenWithReturn, toolbarPopupLinkFeatures } from "../../../common/windowUtils"
import { removeApp, stopApp } from "../../api/asp"
import { addColorClass, colorClass } from "../../colorClasses"
import type { IApp, IAppError, IAppListApp, IBroadcasterApp } from "../../interfaces/asp"

type AppDirectoryPopUpMap = Record<string, Window>
export interface IAspPanelProps {
    authUrl: string,
    savedApps: IAppListApp[],
    appDirectoryPopUps: AppDirectoryPopUpMap
}

const MAX_APPS = 10

export function parseAppError(str: string): IAppError {
    const parser = new ArgJSONMap(str)
    const detail = parser.getStringOrUndefined("detail", false)
    if (detail !== undefined) {
        return {
            msg: detail,
            type: "legacy",
        }
    }
    const formError = parser.getMap("detail")
    return {
        msg: formError.getString("msg"),
        type: formError.getString("type"),
        app: formError.getStringOrUndefined("app", false),
    }
}

export function getAppPath(app: IAppListApp | IBroadcasterApp | IApp): string {
    return `${getAppIdPrefix(app)}-${prettyUrlEncode(app.name)}`
}

function getAppIdPrefix(app: IAppListApp | IBroadcasterApp | IApp): string {
    return app.id.split("-", 1)[0]
}

function prettyUrlEncode(text: string): string {
    return text.replace(/[^a-zA-Z0-9]/g, "-")
}

export const aspPopupFeatures = "status=0,toolbar=1,menubar=0,directories=0,resizable=1,scrollbars=1,height=768,width=860,top=50,left=50"

const AppListLink = (props: { text: string, slot: number, registerPopUp: (key: string, win: Window) => void }) => {
    const href = `/v2apps?slot=${props.slot}`
    const popUpKey = `appdir-slot-${props.slot}`
    return (
        <a
            style={actionLinkStyle}
            colorClass={colorClass.hrefColor}
            href={href}
            onClick={(e) => {
                e.preventDefault()
                safeWindowOpenWithReturn(
                    (e.target as HTMLAnchorElement).href,
                    popUpKey,
                    aspPopupFeatures,
                ).then((win) => {
                    if (win === undefined) {return}
                    props.registerPopUp(popUpKey, win)
                }).catch((e) => error(e))
            }}
            data-test={`choose-app-btn-${props.slot}`}
        >
            {props.text}
        </a>
    )
}

const AppLink = (props: { app: IAppListApp, text: string, hidden: boolean, registerPopUp: (key: string, win: Window) => void }) => {
    const href = `/v2apps?slot=${props.app.slot}&app_path=${getAppPath(props.app)}`
    return (
        <a
            style={{
                ...actionLinkStyle,
                visibility: props.hidden ? "hidden" : "visible",
            }}
            colorClass={colorClass.hrefColor}
            href={href}
            onClick={(e) => {
                const popUpKey = `appdir-slot-${props.app.slot}`
                e.preventDefault()
                safeWindowOpenWithReturn(
                    (e.target as HTMLAnchorElement).href,
                    popUpKey,
                    toolbarPopupLinkFeatures,
                ).then((win) => {
                    if (win === undefined) {return}
                    props.registerPopUp(popUpKey, win)
                }).catch((e) => error(e))
            }}
            data-test={`app-${props.text.toLowerCase()}-link-${props.app.slot}`}
        >
            {props.text}
        </a>
    )
}

const AppStatusText = (props: { app: IAppListApp }) => {
    const defaultStyle: CSSX.Properties = {
        width: "50px",
        display: "inline-block",
        textAlign: "left",
    }
    const isCrashed = props.app.isInstalled && !props.app.isActive && Boolean(props.app.reason) && props.app.reason !== "user requested"
    if (isCrashed) {
        return <span style={defaultStyle} colorClass="deactivate" data-test={`app-status-${props.app.slot}`}>{i18n.crashed}</span>
    }
    if (props.app.isActive) {
        return <span style={defaultStyle} colorClass="activeStatus" data-test={`app-status-${props.app.slot}`}>{i18n.running}</span>
    }
    return <span style={defaultStyle} colorClass="noneSelected" data-test={`app-status-${props.app.slot}`}>{i18n.stopped}</span>
}

const StopAppLink = (props: { app: IAppListApp }) => {
    return (
        <a
            style={{
                ...actionLinkStyle,
                width: "40px",
                textAlign: "left",
            }}
            colorClass="deactivate"
            href="#"
            onClick={(e) => {
                stopApp(props.app.id, () => {}).catch((e) => {error(e)})
            }}
            data-test={`app-stop-btn-${props.app.slot}`}
        >
            {i18n.stop}
        </a>
    )
}

const RemoveAppLink = (props: { app: IAppListApp, text: string }) => {
    return (
        <a
            style={actionLinkStyle}
            colorClass="deactivate"
            href="#"
            onClick={(e) => {
                removeApp(props.app.id, true, () => {}).catch((e) => {error(e)})
            }}
            data-test={`app-uninstall-btn-${props.app.slot}`}
        >
            {props.text}
        </a>
    )
}

const PlaceHolderAppRow = (props: { slot: number, registerPopUp: (key: string, win: Window) => void }) => {
    return (
        <tr colorClass="row">
            <td style={labelContainerStyle}>
                <legend
                    style={labelStyle}
                    colorClass="label"
                    data-test={`app-label-${props.slot}`}
                >
                    {`v2 App #${props.slot}:`}
                </legend>
            </td>
            <td style={{ fontStyle: "italic" }} colorClass="noneSelected" data-test={`app-name-${props.slot}`}>
                {i18n.noneSelected}
            </td>
            <td colSpan={4}>
                <span style={{ cssFloat: "right", paddingRight: "8px" }}>
                    <AppListLink text={i18n.chooseAnApp} slot={props.slot} registerPopUp={props.registerPopUp} />
                </span>
            </td>
        </tr>
    )
}

const SavedAppRow = (props: { app: IAppListApp, slot: number, registerPopUp: (key: string, win: Window) => void }) => {
    return (
        <tr colorClass="row">
            <td style={labelContainerStyle}>
                <legend
                    style={labelStyle}
                    colorClass="label"
                    data-test={`app-label-${props.slot}`}
                >
                    v2 App #{props.slot}:
                </legend>
            </td>
            <td
                style={{
                    fontStyle: props.app.isActive ? "normal" : "italic",
                    width: "430px",
                    textOverflow: "ellipsis",
                    overflow: "hidden",
                    whiteSpace: "nowrap",
                }}
                colorClass={props.app.isActive ? "activeStatus" : "noneSelected"}
                data-test={`app-name-${props.slot}`}
            >
                {props.app.name}
            </td>
            <td>
                <AppStatusText app={props.app} />
            </td>
            <td>
                <AppLink
                    app={props.app}
                    text={props.app.hasUpdate ? i18n.upgrade : i18n.settings}
                    hidden={!props.app.isActive}
                    registerPopUp={props.registerPopUp}
                />
            </td>
            <td>
                {!props.app.isActive && <AppLink app={props.app} text={i18n.restart} hidden={false} registerPopUp={props.registerPopUp} />}
                {props.app.isActive && <StopAppLink app={props.app} />}
            </td>
            <td style={{ width: 0, paddingRight: "8px" }}>
                <RemoveAppLink app={props.app} text={i18n.uninstall} />
            </td>
        </tr>
    )
}

export class AspPanel extends HTMLComponent<HTMLDivElement, IAspPanelProps> {
    authUrl: string
    savedApps: IAppListApp[]
    appRows: HTMLTableRowElement[]
    appDirectoryPopUps: AppDirectoryPopUpMap

    protected initData(props: IAspPanelProps): void {
        this.authUrl = props.authUrl
        this.savedApps = props.savedApps
        this.appDirectoryPopUps = props.appDirectoryPopUps
        this.populateAppsRows()
    }

    protected initUI(): void {
        super.initUI()
        this.element = 
            <div
                style={{
                    width: "850px",
                    borderStyle: "solid",
                    borderWidth: "1px",
                    borderRadius: "5px 5px 0 0",
                }}
            >
                {this.createAppsTable()}
                <div style={{ margin: "10px 8px" }}>
                    {this.createDevportalMessage()}
                </div>
            </div>
        
        addColorClass(this.element, "aspPanel")
    }

    private createAppsTable(): HTMLTableElement {
        return (
            <table style={tableStyle}>
                <tr>
                    <th
                        colSpan={6}
                        style={{
                            fontSize: "14px",
                            padding: "8px",
                            borderRadius: "5px 5px 0 0",
                        }}
                        colorClass="header"
                        data-test="v2-apps-header"
                    >
                        v2 {i18n.apps}
                        <span
                            colorClass="headerBadge"
                            style={{
                                padding: "2px 7px",
                                margin: "0 5px",
                                borderRadius: "2px",
                            }}
                        >{i18n.newCapitalized}</span>
                    </th>
                </tr>
                <tr>
                    <td colSpan={6}>
                        <div
                            style={{
                                fontSize: "12px",
                                margin: "8px 8px",
                                padding: "8px 10px",
                                borderRadius: "5px",
                            }}
                            colorClass="notice"
                            data-test="v2-apps-header-text"
                        >
                            {i18n.v2AppsDescription}
                        </div>
                    </td>
                </tr>
                {this.appRows.slice(0, MAX_APPS).map((row) => row)}
            </table>
        )
    }

    private registerPopUp(key: string, win: Window): void {
        this.appDirectoryPopUps[key] = win
    }

    public notifyDirectoryPopUpsRefresh(): void {
        for (const win of Object.values(this.appDirectoryPopUps)) {
            if (!win.closed) {
                win.postMessage("asp-tab-refresh", "*")
            }
        }
    }

    private populateAppsRows(): void {
        this.appRows = []
        for (let i = 0; i < MAX_APPS; ++i) {
            this.appRows.push(<PlaceHolderAppRow slot={i + 1} registerPopUp={this.registerPopUp.bind(this)} />)
        }
        this.savedApps.forEach((app) => {
            if (app.slot === undefined) {
                error(`${app.name} has no slot`)
                return
            }
            this.appRows[app.slot - 1] = <SavedAppRow app={app} slot={app.slot} registerPopUp={this.registerPopUp.bind(this)} />
        })
    }

    private createDevportalMessage(): HTMLParagraphElement {
        const html = i18n.devportalMessage(this.authUrl)
        const container = document.createElement("p")
        // eslint-disable-next-line @multimediallc/no-inner-html
        container.innerHTML = html.replace(/\s{2,}/gm, " ")
        container.style.margin = "0"
        container.style.fontSize = "12px"
        // eslint-disable-next-line @multimediallc/no-set-attribute
        container.setAttribute("data-test", "dev-portal-link-text")
        return container
    }

    render(): HTMLDivElement {
        return this.element
    }
}

const tableStyle: CSSX.Properties = {
    borderCollapse: "collapse",
    textAlign: "left",
    width: "100%",
}

const labelContainerStyle: CSSX.Properties = { width: "110px" }

const labelStyle: CSSX.Properties = { padding: "3px 8px" }

const actionLinkStyle: CSSX.Properties = {
    verticalAlign: "middle",
    display: "inline-block",
}
