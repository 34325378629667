const byteToHex: string[] = []
for (let i = 0; i < 256; ++i) {
    byteToHex[i] = (i + 0x100).toString(16).substr(1)
}

function bytesToUuid(buf: Uint8Array): string {
    let i =  0
    const bth = byteToHex
    // join used to fix memory issue caused by concatenation: https://bugs.chromium.org/p/v8/issues/detail?id=3175#c4
    /* eslint-disable no-plusplus */
    return [bth[buf[i++]], bth[buf[i++]],
        bth[buf[i++]], bth[buf[i++]], "-",
        bth[buf[i++]], bth[buf[i++]], "-",
        bth[buf[i++]], bth[buf[i++]], "-",
        bth[buf[i++]], bth[buf[i++]], "-",
        bth[buf[i++]], bth[buf[i++]],
        bth[buf[i++]], bth[buf[i++]],
        bth[buf[i++]], bth[buf[i++]]].join("")
    /* eslint-enable no-plusplus */
}

// Installing @types/uuid screws up all setInterval assignments with "Type 'Timer' is not assignable to type 'number | undefined'."
// So, this is borrowed from the uuid library
export function uuidv4(): string {
    const rnds = new Uint8Array(16)
    window.crypto.getRandomValues(rnds)

    // Per 4.4, set bits for version and `clock_seq_hi_and_reserved`
    rnds[6] = rnds[6] & 0x0f | 0x40
    rnds[8] = rnds[8] & 0x3f | 0x80

    return bytesToUuid(rnds)
}
