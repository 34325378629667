import { isPortrait } from "@multimediallc/web-utils"
import { getBanID } from "../../cb/api/chatBansAndSilences"
import { ConversationType, dmsEnabled, PrivateMessageSource, sendPrivateMessage } from "../../cb/api/pm"
import { MobileChatLink } from "../../cb/components/pm/chatLinks"
import { createDmWindowRequest } from "../../cb/components/pm/dmWindowsManager"
import { allPmsRead, directMessage } from "../../cb/components/pm/userActionEvents"
import { MentionUserList } from "../../cb/components/userMentions/MentionUserList"
import { pageContext, roomDossierContext } from "../../cb/interfaces/context"
import { AppPanelChat } from "../appPanelChat"
import { createUndoOptions } from "../chatUndoOptions"
import { roomCleanup, roomLoaded } from "../context"
import { ConversationNotifier } from "../conversationNotifier"
import { Component } from "../defui/component"
import { DmNotifier } from "../dmNotifier"
import { EventRouter, ListenerGroup } from "../events"
import { ChatMode, createAppsRunningMessage } from "../messageToDOM"
import { ignoreCatch } from "../promiseUtils"
import { MobileRoomNotice } from "../roomNotice"
import { RoomNoticeDeclutterer } from "../roomNoticeDeclutterer"
import { RoomStatus } from "../roomStatus"
import { isCurrentUserRoomOwner } from "../roomUtil"
import { i18n } from "../translation"
import { dom } from "../tsxrender/dom"
import { appDebuggingToggled, mentionUser, userInitiatedPm } from "../userActionEvents"
import { ChatContents, inputDivHeight } from "./chatContents"
import { createLogMessage, createPMChatLinkMessage, createRoomMessage, createShortcodeMessage } from "./messageToDOM"
import { otherUserInitiatedPm } from "./mobilePmWindow"
import { mobilePureChatChange } from "./mobilePureChat"
import { PrivateContainer } from "./privateContainer"
import { PrivateShowInfo } from "./privateShowInfo"
import { RoomTabs } from "./roomTabs"
import { TabName } from "./tabList"
import { openTipCalloutRequest, toggleDms, userSwitchedTab } from "./userActionEvents"
import type { IPMError, ISendPrivateMessage } from "../../cb/api/pm"
import type { IPMAnnouncement } from "../../cb/components/pm/chatLinks"
import type { IShortcodeForm } from "../../cb/interfaces/shortcode"
import type { IChatConnection, IRoomContext } from "../context"
import type { IBanSilenceInfo, IPushPrivateMessage, IRemoveMessagesNotification, IRoomNotice } from "../messageInterfaces"
import type { RoomNotice } from "../roomNotice"
import type { IOutgoingMessageHandlers } from "../specialoutgoingmessages"

export class ChatWindow extends Component {
    private chatConnection: IChatConnection
    public chatContents: ChatContents
    public numUnreadChanged: EventRouter<number>
    private numUnread: number
    private conversationNotifier?: ConversationNotifier
    private roomNoticeDeclutterer: RoomNoticeDeclutterer
    private privateShowInfo: PrivateShowInfo
    private privateContainer: HTMLDivElement

    constructor() {
        super()

        this.initNoticeDeclutterer()

        const listenerGroup = new ListenerGroup()

        roomLoaded.listen((context) => {
            this.chatConnection = context.chatConnection
            this.chatContents.appendMessageDiv(createLogMessage(i18n.viewerWelcomeWarning), false)
            this.chatContents.appendMessageDiv(createLogMessage(i18n.mobileWelcomeMessage), false)

            createAppsRunningMessage(
                context.dossier.room, context.dossier.roomUid, context.dossier.appsRunning, ChatMode.Mobile,
            ).then((appsMessage) => {
                this.chatContents.appendMessageDiv(appsMessage, false)
            }).catch(ignoreCatch)

            context.chatConnection.event.roomShortcode.listen((m) => {
                const shortcodeMsgDiv = createShortcodeMessage(m)
                this.chatContents.appendMessageDiv(shortcodeMsgDiv)
            }).addTo(listenerGroup)

            context.chatConnection.event.roomMessage.listen((m) => {
                MentionUserList.getInstance().addRecentUser(m.fromUser)
                this.chatContents.appendMessageDiv(createRoomMessage(m))
                this.incrementUnread()
            }).addTo(listenerGroup)

            context.chatConnection.event.roomNotice.listen((roomNoticeData) => {
                this.newRoomNotice(roomNoticeData)
            }).addTo(listenerGroup)

            context.chatConnection.event.removeMessages.listen((removeMessages: IRemoveMessagesNotification) => {
                this.chatContents.handleRemoveMessages(removeMessages.username)
            }).addTo(listenerGroup)

            context.chatConnection.event.appDebugLog.listen((m) => {
                if (context.chatConnection.isAppDebuggingEnabled()) {
                    this.chatContents.appendMessageDiv(createLogMessage(`DEBUG: ${m}`))
                }
            }).addTo(listenerGroup)

            this.togglePrivateOverlay()
            this.togglePrivateContainer()

            context.chatConnection.event.statusChange.listen(() => {
                this.togglePrivateOverlay()
                this.togglePrivateContainer()
            }).addTo(listenerGroup)

            context.chatConnection.event.onBanSilence.listen((m) => {
                this.handleBanSilence(m, context)  // eslint-disable-line @typescript-eslint/no-floating-promises
            }).addTo(listenerGroup)

            this.conversationNotifier = new ConversationNotifier(this.chatConnection)

            if (pageContext.current.mergePmDm) {
                directMessage.listen((m: IPushPrivateMessage) => {
                    if (this.conversationNotifier?.shouldNotify(m.fromUser.username) === true) {
                        const msgDiv = this.conversationNotifier.createChatLinkMessage(m.fromUser.username)
                        this.chatContents.appendMessageDiv(msgDiv)
                        this.conversationNotifier.preventNotifications(m.otherUsername)
                    }
                }).addTo(listenerGroup)
            }
        })

        this.privateShowInfo.didRepositionEvent.listen(() => {this.repositionChildren(true)})

        new AppPanelChat({
            appendMessageDiv: (message: HTMLDivElement) => this.chatContents.appendMessageDiv(message),
            removeMessageDiv: (message: HTMLDivElement) => {
                this.chatContents.removeMessageDiv(message)
            },
            getLastMessageId: () => this.chatContents.getLastMessageId(),
            messagesSinceId: (id: number) => this.chatContents.messagesSinceId(id),
            getMessagesScrollTop: () => this.chatContents.messageListWrapper.scrollTop,
            getMessagesOffsetHeight: () => this.chatContents.element.offsetHeight,
        })

        mentionUser.listen(username => {
            this.chatContents.customInputField.appendText(` @${username} `)

            if (RoomTabs.currentTab !== TabName.Chat) {
                userSwitchedTab.fire(TabName.Chat)
            }
        })

        appDebuggingToggled.listen((enabled) => {
            if (enabled) {
                this.chatContents.appendMessageDiv(
                    createLogMessage("Debug mode enabled. Type /debug again to disable."))
            } else {
                this.chatContents.appendMessageDiv(createLogMessage("Debug mode disabled."))
            }
        })

        if (!pageContext.current.mergePmDm) {
            const dmNotifier = new DmNotifier()
            if (dmsEnabled()) {
                directMessage.listen((m: IPushPrivateMessage) => {
                    if (dmNotifier.shouldNotify(m.fromUser.username)) {
                        const msg = i18n.newDirectMessageNotice(m.otherUsername)
                        const chatLink = new MobileChatLink({
                            onClick: () => {
                                if (isPortrait()) {
                                    toggleDms.fire(true)
                                }
                                createDmWindowRequest.fire(m.otherUsername)
                            },
                            conversationType: ConversationType.DM,
                        })
                        const msgDiv = createPMChatLinkMessage(msg, chatLink.openConversationElement)
                        this.chatContents.appendMessageDiv(msgDiv)
                        dmNotifier.preventNotifications(m.otherUsername)
                    }
                })
            }
        }

        if (!pageContext.current.mergePmDm) {
            otherUserInitiatedPm.listen((pmAnnouncement) => {
                if (this.conversationNotifier?.shouldNotify(pmAnnouncement.username) === true) {
                    this.newPmChatLink(pmAnnouncement)
                    this.conversationNotifier.preventNotifications(pmAnnouncement.username)
                }
            })
        }

        userSwitchedTab.listen((tabName) => {
            // Clear unread count only if user switches from chat tab to any other tab.
            // We want to preserve the count for chat window's unread messages.
            if (RoomTabs.currentTab === TabName.Chat && tabName !== TabName.Chat) {
                this.chatContents.clearScrollButtonUnread()
            }
            if (tabName === TabName.Chat) {
                this.resetNumUnread()
                this.maybeMarkPrivateShowPmsRead(tabName)
            }
        })

        mobilePureChatChange.listen((isVisible: boolean) => {
            if (isVisible) {
                this.resetNumUnread()
                this.maybeMarkPrivateShowPmsRead(undefined, isVisible)
            }
        })

        roomCleanup.listen(() => {
            this.chatContents.clear()
            this.resetNumUnread()
            listenerGroup.removeAll()
            this.conversationNotifier?.dispose()
            this.roomNoticeDeclutterer.onRoomCleanup()
        })
    }

    protected initData(): void {
        this.numUnreadChanged = new EventRouter<number>("numUnreadChanged")
        this.numUnread = 0
    }

    protected initUI(): void {
        this.privateShowInfo = new PrivateShowInfo()
        this.privateContainer = <PrivateContainer privateShowInfo={this.privateShowInfo} />
        this.element.appendChild(this.privateContainer)

        const outgoingHandlers: IOutgoingMessageHandlers = {
            onTipRequest: (tipRequest) => {
                openTipCalloutRequest.fire(tipRequest)
            },
            onToggleDebugMode: () => {
                this.chatConnection.toggleAppDebugging()
            },
            onChatMessage: (msg) => {
                if (this.chatConnection.inPrivateRoom()) {
                    const pm: ISendPrivateMessage = {
                        message: msg,
                        username: this.chatConnection.isBroadcasting
                            ? this.chatConnection.getPrivateShowUser()
                            : this.chatConnection.room(),
                        media: undefined,
                        source: PrivateMessageSource.RoomViewPM,
                        roomName: this.chatConnection.room(),
                    }

                    sendPrivateMessage(pm).catch((error: IPMError) => {
                        this.chatContents.appendMessageDiv(createLogMessage(error.errorMessage))
                    })
                } else {
                    this.chatConnection.sendRoomMessage(msg)
                }
            },
            onShortcode: (shortcode: IShortcodeForm) => {
                this.chatConnection.sendShortcode(shortcode)
            },
        }
        this.chatContents = this.addChild(new ChatContents(outgoingHandlers, () => this.isConversationShowing()))
    }

    private isConversationShowing(): boolean {
        return RoomTabs.currentTab === TabName.Chat || roomDossierContext.getState().privateShowId !== ""
    }

    private initNoticeDeclutterer(): void {
        this.roomNoticeDeclutterer = new RoomNoticeDeclutterer({
            appendNotice: (notice: RoomNotice, countsForUnread: boolean) => this.chatContents.appendNoticeMessage(notice, countsForUnread),
            appendSentinelDiv: (div: HTMLDivElement) => this.chatContents.element.appendChild(div),
        })
    }

    private newPmChatLink(pmAnnouncement: IPMAnnouncement): void {
        const msg = i18n.mobileNewPrivateMessageNotice(pmAnnouncement.username)
        const msgDiv = createPMChatLinkMessage(msg, pmAnnouncement.PMChatLink.openConversationElement)
        this.chatContents.appendMessageDiv(msgDiv)
    }

    private newRoomNotice(roomNoticeData: IRoomNotice): void {
        const roomNotice = new MobileRoomNotice({
            roomNoticeData,
            isChatScrolledToBottom: () => !this.chatContents.isScrolledUp(),
            scrollChatToBottom: () => this.chatContents.debouncedScrollToBottom(),
            getChatScrollTop: () => this.chatContents.getScrollTop(),
            setChatScrollTop: (top: number) => this.chatContents.setScrollTop(top),
        })

        this.roomNoticeDeclutterer.addRoomNotice(roomNotice)
    }

    public incrementUnread(): void {
        if (this.isConversationShowing()) {
            return
        }

        this.numUnread += 1
        this.numUnreadChanged.fire(this.numUnread)
    }

    private resetNumUnread(): void {
        this.numUnread = 0
        this.numUnreadChanged.fire(this.numUnread)
    }

    private maybeMarkPrivateShowPmsRead(currentTab = RoomTabs.currentTab, isPureChatVisible = false): void {
        if (
            (currentTab === TabName.Chat || isPureChatVisible)
            && this.chatConnection.inPrivateRoom()
        ) {
            allPmsRead.fire(isCurrentUserRoomOwner() ? this.chatConnection.getPrivateShowUser() : this.chatConnection.room())
        }
    }

    public togglePrivateOverlay(): void {
        if (this.chatConnection.inPrivateRoom()) {
            const initAndFocusPm = () => {
                userInitiatedPm.fire({
                    username: this.chatConnection.getPrivateShowUser(),
                    focus: true,
                    showSupporterAlert: false,
                })
            }

            if (this.chatConnection.isBroadcasting) {
                initAndFocusPm()
            }

            this.chatContents.showPrivateOverlay(
                this.chatConnection.getPrivateShowUser(),
                () => {
                    if (this.chatConnection.isBroadcasting) {
                        initAndFocusPm()
                    } else {
                        userSwitchedTab.fire(TabName.Private)
                    }
                },
            )
        } else {
            this.chatContents.hidePrivateOverlay()
        }
    }

    private togglePrivateContainer(): void {
        const togglableStatuses = [RoomStatus.PrivateNotWatching, RoomStatus.PrivateSpying]

        if (togglableStatuses.includes(this.chatConnection.status)) {
            this.privateContainer.style.display = "flex"
        } else {
            this.privateContainer.style.display = "none"
        }

        this.repositionChildren(true)
    }

    async handleBanSilence(m: IBanSilenceInfo, context: IRoomContext): Promise<void> {
        const isUserRoomOwner = context.dossier.userName === context.dossier.room
        if (isUserRoomOwner || m.silencer === context.dossier.userName) {
            const banId = await getBanID({
                bannedUser: m.silenced,
                createdBy: m.silencer,
                isSilence: !m.isBan,
                roomUser: context.dossier.room,
            })
            const logFunction = (msg: string) => { this.showLogMsg(msg) }
            const divOptions = createUndoOptions(this.chatContents, m, banId, context, logFunction)
            this.chatContents.appendMessageDiv(divOptions)
        }
    }

    public showLogMsg(msg: string): void {
        this.chatContents.appendMessageDiv(createLogMessage(msg))
    }

    protected repositionChildren(maintainScroll?: boolean): void {
        const messageListWrapperHeight =
            this.element.offsetHeight
            - inputDivHeight()
            - this.privateContainer.offsetHeight
        this.chatContents.setMessageListWrapperHeight(messageListWrapperHeight, maintainScroll)
    }

    public getPrivateContainerHeight(): number {
        return this.privateContainer.offsetHeight
    }
}
