import { setupI18n } from "@lingui/core"

declare global {
    interface Window {i18njs?: { messages: Record<string, string> }}
}

export const jsi18n = setupI18n({ productionCompileEnabled: true })


export function activateI18n(languageCode: string): void {
    if (window.i18njs === undefined) {
        throw new Error("window.linguijs is undefined")
    }
    jsi18n.load(languageCode, window.i18njs.messages)
    jsi18n.activate(languageCode)
}
