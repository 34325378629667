import { addColorClass } from "../cb/colorClasses"
import { addEventListenerPoly, removeEventListenerPoly } from "./addEventListenerPolyfill"
import { Debouncer, DebounceTypes } from "./debouncer"
import { Component } from "./defui/component"
import { EventRouter } from "./events"
import { mouseEventPolyfill } from "./mouseEventPolyfill"

export interface ISliderConfig {
    handleDiameter: number
    barWidth?: number
    barHeight?: number
    handleColor?: string
    emptyBarColor?: string
    filledBarColor?: string
    handleImage?: string
    handleImageSize?: number
    barImage?: string
    barImageSize?: string
}

export class Slider extends Component<HTMLDivElement> {
    private emptyBar: HTMLDivElement | HTMLImageElement
    private filledBar: HTMLDivElement
    public handle: HTMLDivElement | HTMLImageElement

    readonly barHeight: number
    private value = 0
    readonly valueChanged = new EventRouter<number>("valueChanged")
    readonly valueChangeStart = new EventRouter<number>("valueChangeStart")
    readonly valueChangeEnd = new EventRouter<number>("valueChangeEnd")
    private valueUpdater: Debouncer

    constructor(private config: ISliderConfig) { // eslint-disable-line complexity
        super()

        addColorClass(this.element, "slider")
        this.element.style.position = "relative"
        this.element.style.overflow = "visible"
        this.element.style.cursor = "pointer"
        if (config.barWidth !== undefined) {
            this.element.style.width = `${config.barWidth + config.handleDiameter}px`
        } else {
            this.element.style.width = "90%"
        }
        this.element.style.height = `${config.handleDiameter}px`

        this.barHeight = config.barHeight !== undefined ? config.barHeight : 2 * Math.round(config.handleDiameter * 0.25 / 2)

        // if slider is provided and image otherwise use slider bar/default
        if (config.barImage !== undefined) {
            this.emptyBar = document.createElement("img");
            (this.emptyBar as HTMLImageElement).src = config.barImage
            this.emptyBar.style.width = "100%"
            this.emptyBar.style.height = "100%"
            this.element.appendChild(this.emptyBar)
        } else {
            this.emptyBar = document.createElement("div")
            this.emptyBar.style.position = "absolute"
            this.emptyBar.style.boxSizing = "border-box"
            if (config.emptyBarColor !== undefined) {
                this.emptyBar.style.backgroundColor = config.emptyBarColor
            } else {
                this.emptyBar.style.backgroundColor = "#333333"
                this.emptyBar.style.backgroundColor = "rgba(255, 255, 255, 0.2)"
            }
            this.emptyBar.style.top = `${(config.handleDiameter - this.barHeight) * 0.5}px`
            this.emptyBar.style.height = `${this.barHeight}px`
            this.emptyBar.style.width = "100%"
            this.element.appendChild(this.emptyBar)
        }

        // if the slider is given an image hide the filled bar slider otherwise use slider bar/default
        if (config.barImage !== undefined) {
            this.filledBar = document.createElement("div")
            this.filledBar.style.visibility = "none"
        } else {
            this.filledBar = document.createElement("div")
            this.filledBar.style.position = "absolute"
            this.filledBar.style.boxSizing = "border-box"
            this.filledBar.style.backgroundColor = config.filledBarColor !== undefined ? config.filledBarColor : "#ffffff"
            this.filledBar.style.top = `${(config.handleDiameter - this.barHeight) * 0.5}px`
            this.filledBar.style.height = `${this.barHeight}px`
            this.element.appendChild(this.filledBar)
        }

        if (config.handleImage !== undefined) {
            this.handle = document.createElement("img")
            this.handle.style.position = "absolute"
            this.handle.style.top = "0";
            (this.handle as HTMLImageElement).src = config.handleImage
            this.handle.style.height = `${config.handleDiameter}px`
            this.handle.style.width = `${config.handleDiameter}px`
            this.handle.style.cursor = "grab"
            this.handle.style.left = "0px"
            this.element.appendChild(this.handle)
        } else {
            this.handle = document.createElement("div")
            this.handle.style.position = "absolute"
            this.handle.style.top = "0"
            this.handle.style.backgroundColor = config.handleColor !== undefined ? config.handleColor : "#ffffff"
            this.handle.style.borderRadius = "50%"
            this.handle.style.height = `${config.handleDiameter}px`
            this.handle.style.height = `${config.handleDiameter}px`
            this.handle.style.width = `${config.handleDiameter}px`
            this.handle.style.cursor = "grab"
            this.element.appendChild(this.handle)
        }

        this.valueUpdater = new Debouncer(() => {
            this.valueChanged.fire(this.value)
        }, {
            bounceLimitMS: 100,
            debounceType: DebounceTypes.trailThrottle,
        })

        const moveListener = (ev: MouseEvent) => {
            this.handleMoveEvent(ev, ev.clientX)
        }
        this.setupListeners(moveListener, "mousedown", "mousemove", "mouseup")

        const touchListener = (ev: TouchEvent) => {
            if (ev.touches.length === 1) {
                const touch = ev.touches.item(0)
                if (touch !== null) {
                    this.handleMoveEvent(ev, touch.clientX)
                }
            }
        }
        this.setupListeners(touchListener, "touchstart", "touchmove", "touchend")

        addEventListenerPoly("mousedown", this.element, (ev: MouseEvent) => {
            this.handleMoveEvent(ev, ev.clientX)
            this.handle.dispatchEvent(mouseEventPolyfill(ev.type, ev))
        })
    }

    private setupListeners(moveListener: EventListener, startEvent: string, moveEvent: string, endEvent: string): void {
        addEventListenerPoly(startEvent, this.handle, (ev: Event) => {
            this.valueChangeStart.fire(this.value)
            this.handle.style.cursor = ""
            document.body.style.cursor = "grabbing"
            ev.preventDefault()
            ev.stopPropagation()
            addEventListenerPoly(moveEvent, document, moveListener, true)
            const upHandler = (ev: Event) => {
                this.valueChangeEnd.fire(this.value)
                this.handle.style.cursor = "grab"
                document.body.style.cursor = ""
                ev.preventDefault()
                removeEventListenerPoly(moveEvent, document, moveListener, true)
                removeEventListenerPoly(endEvent, document, upHandler, true)
            }
            addEventListenerPoly(endEvent, document, upHandler, true)
        })
    }

    private handleMoveEvent(ev: Event, x: number): void {
        const x0 = this.element.getBoundingClientRect().left
        if (this.config.barWidth !== undefined) {
            const newX = Math.max(this.config.handleDiameter * 0.5, Math.min(this.config.barWidth + this.config.handleDiameter * 0.5, x - x0))
            this.setValue((newX - this.config.handleDiameter * 0.5) * (100 / this.config.barWidth))
        } else {
            const newX = Math.max(this.config.handleDiameter * 0.5, Math.min(this.element.offsetWidth + this.config.handleDiameter * 0.5, x - x0))
            this.setValue((newX - this.config.handleDiameter * 0.5) * (100 / this.element.offsetWidth))
        }
        this.valueUpdater.callFunc()
        ev.stopPropagation()
    }

    getValue(): number {
        return this.value
    }

    setValue(value: number): void {
        if (this.config.barWidth !== undefined) {
            this.handle.style.left = `${value * this.config.barWidth / 100}px`
            this.filledBar.style.width = `${value * this.config.barWidth / 100}px`
        } else {
            this.handle.style.left = `calc(${value}% - ${this.config.handleDiameter * value / 100}px)`
            this.filledBar.style.width = `${value}%`
        }
        this.value = value
    }

    public hideHandle(): void {
        this.handle.style.display = "none"
    }

    public showHandle(): void {
        this.handle.style.display = "block"
    }

    public handleResize(): void {
        if (this.config.barWidth === undefined) {
            this.handle.style.left = `calc(${this.value}% - ${this.config.handleDiameter * this.value / 100}px)`
            this.filledBar.style.width = `${this.value}%`
        }
    }
}
