import { getCb } from "../api"

export const withSuspensionCheck: (onSuccess: () => void) => void = (onSuccess = () => {}) => {
    // Checking if the user is suspended 
    const suspensionCheckUrl = "api/ts/accounts/broadcaster_suspension/"
    getCb(suspensionCheckUrl).then(resp => {
        const jsnData = JSON.parse(resp.responseText) as { is_suspended: boolean }
        if (jsnData["is_suspended"]) {
            // Forcing a page refresh prevents
            // the broadcaster to start broadcasting
            // after suspension
            window.location.reload()
        } else {
            onSuccess()
        }
    }).catch(e => {
        error(e.message)
    })
}