import { addColorClass, removeColorClass } from "../cb/colorClasses"
import { pageContext, roomDossierContext } from "../cb/interfaces/context"
import { addEventListenerPoly } from "./addEventListenerPolyfill"
import { getCb } from "./api"
import { roomCleanup, roomLoaded } from "./context"
import { Debouncer, DebounceTypes } from "./debouncer"
import { numberFromStyle } from "./DOMutils"
import { ListenerGroup } from "./events"
import { FilteringCache } from "./filteringCaches"
import { OverlayComponent } from "./overlayComponent"
import { userChatSettingsUpdate } from "./theatermodelib/userActionEvents"
import type { IXhrConfig } from "./api"
import type { IRoomContext } from "./context"
import type { ICustomInput } from "./customInput"
import type { IItem } from "./filteringCaches"

export interface IAutocompleteConfig {
    inputElement: ICustomInput
    leftOffset: number
    rightOffset: number
}

const NO_SELECTION = -1

const specialFunctionKeys = ["ArrowDown", "ArrowLeft", "ArrowRight", "ArrowUp", "Enter", "Escape", "Space", "Tab"]

export abstract class AutocompleteModal<T extends IItem> extends OverlayComponent {
    protected list: HTMLDivElement
    protected visible: boolean
    protected items: T[] = []
    protected selectedIndex = NO_SELECTION
    protected searchSlug: string
    private displayedResultsSearchSlug: string | undefined
    protected maxSearchSlugLength: number | undefined
    protected cache: FilteringCache<T>
    protected cacheTTL = -1
    protected filterAfterSize: number | undefined
    protected delay: number
    protected listenerGroup: ListenerGroup
    private regex: RegExp
    protected maxOptionLength: number
    protected disableCache: boolean
    protected xhrConfig: IXhrConfig | undefined
    protected isPrefixSearch: boolean // Whether the autocomplete only returns values beginning with the search term (eg emoticons) or returns any values containing the term (eg hashtags)
    private getDataDebouncer: Debouncer

    constructor(protected config: IAutocompleteConfig) {
        super(config)

        this.overlayClick.listen(() => {
            if (this.isValidSelectionIndex()) {
                this.finalizeSelection()
            }
            this.hide()
        })

        this.initEventListeners()
    }

    protected initEventListeners(): void {
        addEventListenerPoly("input", this.config.inputElement.element, () => {
            this.handleInputChange()
        })
        addEventListenerPoly("keydown", this.config.inputElement.element, (event: KeyboardEvent) => {
            this.handleKeydown(event)
        })
        roomCleanup.listen(() => {
            this.hide()
        }).addTo(this.listenerGroup)
    }

    protected handleKeydown(event: KeyboardEvent): void {
        if (this.visible) {
            this.handleArrowUpDown(event)
            if (["Tab", "ArrowRight", "Space", "Enter"].includes(event.code)) {
                event.preventDefault()
                event.stopPropagation()
                this.hide()

                // mobile always submits on enter (for now) so we don't want to have
                // a whitespace in input after submit
                if (!(pageContext.current.isMobile && event.code === "Enter")) {
                    this.finalizeSelection()
                }
            }
            if (event.code === "Escape" || event.code === "ArrowLeft") {
                event.preventDefault()
                this.hide()
                this.deleteHighlightedSuffix()
            }
        }
    }

    protected handleArrowUpDown(event: KeyboardEvent): void {
        if (event.code === "ArrowUp") {
            event.preventDefault()
            this.scrollList(true)
        }
        if (event.code === "ArrowDown") {
            event.preventDefault()
            this.scrollList(false)
        }
    }

    protected initData(): void {
        super.initData()
        this.list = document.createElement("div")
        this.visible = false
        this.disableCache = false
        this.isPrefixSearch = true
        this.listenerGroup = new ListenerGroup()
        this.regex = this.buildRegex()
        this.getDataDebouncer = new Debouncer(() => this.getData(), { bounceLimitMS: this.getDataDebounceMS(), debounceType: DebounceTypes.debounce })
        this.initDelay()
    }

    protected initUI(config?: IAutocompleteConfig): void {
        super.initUI()
        addColorClass(this.element, "autocompleteModal")
        this.element.style.visibility = "hidden"
        this.element.style.width = "auto"
        this.element.style.maxWidth = "260px"
        this.element.style.height = "auto"
        this.element.style.borderWidth = "1px"
        this.element.style.borderStyle = "solid"
        this.element.style.borderBottom = "none"
        this.element.style.fontFamily = "Helvetica, Arial, sans-serif"
        this.element.style.cursor = "pointer"

        this.list.style.width = "auto"
        this.list.style.maxHeight = "180px"
        this.list.style.overflowY = "scroll"
        this.element.appendChild(this.list)
    }

    // Regex for character(s) that signal the start of an autocomplete, eg ":" for emoticons, or "\\[cb" for shortcodes
    // Returns a string for ease of use in buildRegex(), so remember to escape backslashes ("\\[cb" not "\[cb")
    protected abstract promptRegex(): string

    // Regex for valid input following promptRegex, ie the string used for filtering search results.
    // Returns a string for ease of use in buildRegex(), so remember to escape backslashes ("[\\w-]+" not "[\w-]+")
    protected abstract searchSlugRegex(): string

    // Returns a regex with two named groups: "preslug" for the input content before the user's autocomplete search slug, and "slug" for the search slug
    private buildRegex(): RegExp {
        return new RegExp(`(?<preslug>(?:^|.*\\s+)${this.promptRegex()})(?<slug>${this.searchSlugRegex()})$`, "i")
    }

    public dispose(): void {
        this.listenerGroup.removeAll()
    }

    protected initDelay(): void {
        this.delay = roomDossierContext.getState().userChatSettings.emoticonAutocompleteDelay

        roomLoaded.listen((context: IRoomContext) => {
            this.delay = context.dossier.userChatSettings.emoticonAutocompleteDelay
        }).addTo(this.listenerGroup)

        userChatSettingsUpdate.listen((userChatSettings) => {
            this.delay = userChatSettings.emoticonAutocompleteDelay
        }).addTo(this.listenerGroup)
    }

    protected repositionChildren(): void {
        const properX = this.config.inputElement.caretXPos(true) + this.config.leftOffset
        const maxX = this.config.inputElement.element.clientWidth - this.config.rightOffset
        const minX = 4
        this.element.style.left = `${Math.max(minX, Math.min(properX, maxX))}px`
    }

    protected setItems(items: T[]): void {
        this.items = items
        this.displayedResultsSearchSlug = this.searchSlug
        this.updateListDOM()
    }

    protected getData(): void {
        const currentSearchSlug = this.searchSlug
        if (this.isGetDataCancelled()) {
            return
        }
        getCb(this.getDataEndpoint(), this.xhrConfig).then((xhr: XMLHttpRequest) => {
            if (this.searchSlug !== currentSearchSlug) {
                return
            }
            const items = this.parseResponse(xhr.responseText)
            this.setItems(items)
            if (!this.disableCache) {
                this.cache.set(this.searchSlug, items)
            }
        }).catch((err) => {
            error("Error autocomplete modal", err)
        })
    }

    private updateItems(): void {
        if (this.disableCache) {
            this.getDataDebouncer.callFunc()
        } else {
            const cache = this.getCache()
            const items = cache.get(this.normalizedSearchSlug())
            if (items !== undefined) {
                this.setItems(items)
            } else {
                this.getDataDebouncer.callFunc()
            }
        }
    }

    private handleInputChange(): void {
        if (this.delay >= 0 && this.config.inputElement.getAvailableLength() > this.maxOptionLength) {
            const match = this.inputMatch()
            if (match !== undefined) {
                this.searchSlug = match
                if (this.maxSearchSlugLength !== undefined && this.searchSlug.length > this.maxSearchSlugLength) {
                    warn(`Autocomplete search slug >${this.maxSearchSlugLength} chars. Not updating.`)
                    return
                }
                this.clearSelectionStyle()
                this.selectedIndex = NO_SELECTION
                this.updateItems()
            } else {
                this.hide()
            }
        }
    }

    protected getCache(): FilteringCache<T> {
        if (this.cache === undefined) {
            this.cache = new FilteringCache(this.filterAfterSize, this.cacheTTL)
        }
        return this.cache
    }

    protected normalizedSearchSlug(): string {
        return this.searchSlug
    }

    private inputMatch(): string | undefined {
        const preCaretMatch = this.config.inputElement.getCurrentNodePreCaretText().match(this.regex)
        if (preCaretMatch == null || preCaretMatch.groups === undefined) {
            return undefined
        }
        return preCaretMatch.groups["slug"]
    }

    protected isSpecialFunctionKey(event: KeyboardEvent): boolean {
        return specialFunctionKeys.includes(event.code)
    }

    protected updateListDOM(): void {
        this.clearListDOM()
        if (this.items.length === 0) {
            this.element.style.display = "none"
        } else {
            this.element.style.display = "block"
        }

        for (let i = 0; i < this.items.length; i += 1) {
            this.appendItem(this.items[i], i)
        }

        const offsetTop = this.list.children.length <= 0 ? 0
            : (this.list.children.item(this.list.children.length - 1) as HTMLElement).offsetTop
        this.list.scrollTop = offsetTop

        if (!this.visible) {
            this.show()
        }
    }

    protected clearListDOM(): void {
        while (this.list.firstChild !== null) {
            this.list.removeChild(this.list.firstChild)
        }
        this.selectedIndex = NO_SELECTION
    }

    protected scrollList(scrollUp: boolean): void {
        this.clearSelectionStyle()
        let index = this.isValidSelectionIndex() ? this.selectedIndex + (scrollUp ? -1 : 1) : scrollUp ? this.items.length - 1 : 0
        if (index < 0) {
            index = this.items.length - 1
        } else if (index >= this.items.length) {
            index = 0
        }
        this.pickItem(index, true)
    }

    protected highlightSelectedIndex(shouldScroll: boolean): void {
        if (this.isValidSelectionIndex() && this.items.length > 0) {
            this.clearSelectionStyle()
            addColorClass(this.items[this.selectedIndex].element, "selectedEmoticon")
            this.items[this.selectedIndex].element.dataset.testid = "selected-emoticon"
            if (shouldScroll) {
                this.scrollToIndex(this.selectedIndex)
            }
        }
    }

    protected replacePreCaratInputText(newSlug: string, highlight: boolean): void {
        const highlightLength = highlight ? newSlug.length - (this.isPrefixSearch ? this.searchSlug.length : 0) : 0
        // When the search doesn't have good results to give, it's possible for it to include results shorter than the search slug
        const nonNegHighlightLength = Math.max(highlightLength, 0)

        try {
            this.config.inputElement.replaceCurrentNodePreCaretText(this.regex, `$<preslug>${newSlug}`, nonNegHighlightLength)
        } catch {
            // In case of setCurrentNodeSelectionError in CustomInput
            this.hide()
        }
    }

    protected scrollToIndex(selectedIndex: number): void {
        const listHeight = this.list.clientHeight
        const itemHeight = (this.list.firstChild as HTMLElement).getBoundingClientRect().height
        this.list.scrollTop = selectedIndex * itemHeight - listHeight / 2
    }

    protected deleteHighlightedSuffix(): void {
        this.config.inputElement.deleteSelection()
    }

    protected finalizeSelection(): void {
        this.config.inputElement.setCaretToEndOfSelection()
        this.config.inputElement.insertText(" ")
    }

    protected show(): void {
        if (this.delay >= 0) {
            window.setTimeout(() => {
                if (this.inputMatch() !== undefined) {
                    this.element.style.visibility = "visible"
                    if (numberFromStyle(this.element.style.width) === 0) {
                        // This is to allow the div to stretch based on the available screen width
                        // so that the autocomplete window gets truncated in case of longer usernames/emoticons
                        this.element.style.width = "auto"
                        this.element.style.width = "-moz-available"
                        this.element.style.width = "-webkit-fill-available"
                        this.element.style.width = "fill-available"
                    }
                    this.element.style.height = "auto"
                    this.showOverlay()
                    this.repositionChildrenRecursive()
                    this.visible = true
                }
            }, this.delay)
        }
    }

    protected hide(): void {
        this.hideOverlay()
        this.element.style.visibility = "hidden"
        this.element.style.width = "0"
        this.element.style.height = "0"
        this.visible = false
        this.clearListDOM()
    }

    protected clearSelectionStyle(): void {
        for (const i of this.items) {
            removeColorClass(i.element, "selectedEmoticon")
            i.element.dataset.testid = "autocomplete-item"
        }
    }

    protected appendItem(item: T, index: number): HTMLDivElement {
        const div = document.createElement("div")
        addColorClass(div, "modalItem")
        div.style.padding = "2px 8px"
        if (this.shouldIgnoreItem(item)) {
            div.style.display = "none"
        }
        div.onclick = (event: Event) => {
            this.pickItem(index, false)
            event.preventDefault()
        }
        div.dataset.testid = "autocomplete-item"

        const text = document.createElement("div")
        text.dataset.testid = "emoticon-title"
        addColorClass(text, "tag-text")
        text.textContent = item.slug
        text.title = item.slug
        text.style.overflow = "hidden"
        text.style.textOverflow = "ellipsis"
        div.appendChild(text)

        this.list.appendChild(div)
        item.element = div
        return div
    }

    protected pickItem(index: number, fromArrowPress: boolean): void {
        if (this.displayedResultsSearchSlug === undefined) {
            error("displayedResultsSearchSlug unset while picking results")
            return
        }
        if (this.searchSlug !== this.displayedResultsSearchSlug) {
            this.searchSlug = this.displayedResultsSearchSlug
        }
        this.selectedIndex = index
        if (this.isValidSelectionIndex() && this.items.length > 0) {
            this.highlightSelectedIndex(fromArrowPress)
            this.replacePreCaratInputText(this.items[this.selectedIndex].slug, true)
        }
    }

    protected shouldIgnoreItem(item: T): boolean {
        return false
    }

    public isVisible(): boolean {
        return this.visible
    }

    private isValidSelectionIndex(): boolean {
        return this.selectedIndex >= 0 && this.selectedIndex < this.items.length
    }

    protected isGetDataCancelled(): boolean {
        return this.selectedIndex !== NO_SELECTION
    }

    protected getDataDebounceMS(): number {
        return 200
    }

    protected abstract parseResponse(response: string): T[]

    protected abstract getDataEndpoint(): string
}
