import { isOrientationChangeSupported, isPortrait } from "@multimediallc/web-utils"
import { isiOS } from "@multimediallc/web-utils/modernizr"
import { addEventListenerPoly } from "../addEventListenerPolyfill"
import { Component } from "../defui/component"
import { EventRouter } from "../events"
import { screenOrientationChanged } from "../mobilelib/windowOrientation"
import { i18n } from "../translation"

const Accelerometer = window["Accelerometer"]

export class Orientation extends Component {
    private text: HTMLDivElement
    private image: HTMLImageElement

    constructor() {
        super()

        this.element.style.textAlign = "center"
        this.element.style.paddingTop = "40px"
        this.element.style.backgroundColor = "rgba(255, 255, 255, 0.5)"
        this.element.style.width = "100%"
        this.element.style.height = "100%"

        const img = this.element.appendChild(document.createElement("img"))
        img.src = `${STATIC_URL_MOBILE}rotate.svg`
        img.style.width = "500px"
        img.style.objectFit = "contain"
        img.style.maxWidth = "40%"
        img.style.maxHeight = "40%"
        img.style.padding = "15px"
        img.style.animationName = "spin-default"
        img.style.animationDuration = "2s"
        img.style.animationIterationCount = "infinite"

        document.head.appendChild(document.createElement("style")).innerText = "@keyframes spin-default { 0% { transform: rotate(0deg); } 100% { transform: rotate(180deg); } }"

        this.text = this.element.appendChild(document.createElement("div"))
        this.text.style.fontWeight = "bold"
        this.text.style.fontSize = "1.5em"
        this.text.style.padding = "15px"
        addEventListenerPoly("fullscreenchange", document, (event) => {
            this.onFullScreen()
        })

        if (!isiOS() && window["Accelerometer"] !== undefined) {
            this.image = img
            const loadOrientation = screen.orientation.type
            const acl = new Accelerometer({ frequency: 2 })
            addEventListenerPoly("reading", acl, () => {
                this.updateAndroid(acl.x, acl.y, acl.z, loadOrientation)
            })
            acl.start()
        } else {
            this.update()

            screenOrientationChanged.listen(() => {
                this.update()
            })
        }
        tryFullScreen.listen(() => {
            if (isOrientationChangeSupported() && document.fullscreenElement === null) {
                try {
                    if (document.documentElement.requestFullscreen !== undefined) {
                        document.documentElement.requestFullscreen()  // eslint-disable-line @typescript-eslint/no-floating-promises
                    }
                } catch (e) {
                    warn(e)
                }
            }
        })
    }

    private onFullScreen(): void {
        debug("On Full Screen")
        if (isOrientationChangeSupported() && screen.orientation !== undefined) {
            debug("Trying to lock landscape")
            try {
                // @ts-expect-error - removed from dom types in 5.2
                screen.orientation.lock("landscape")  // eslint-disable-line @typescript-eslint/no-floating-promises
            } catch (e) {
                warn(e)
            }
        }
        this.update()
    }

    private update(): void {
        if (isOrientationChangeSupported() && !isPortrait() || new URLSearchParams(window.location.search).get("ignorerotation") === "true" || !isOrientationChangeSupported()) {
            this.element.style.display = "none"
        } else {
            this.element.style.display = ""
            this.text.innerText = i18n.incorrectPortrait
        }
    }

    private updateAndroid(x: number, y: number, z: number, orientation: string): void{
        const fullscreen = document.fullscreenElement
        if (fullscreen === null) {
            this.image.style.display = "none"
            this.element.style.display = ""
            this.text.innerText = i18n.missingFullscreen + i18n.androidBroadcast
        } else if ((orientation === "portrait-primary" || orientation === "portrait-secondary") &&
                  (x < 4.5 && (y > 2 || y < -2))) {
            this.image.style.display = ""
            this.element.style.display = ""
            this.text.innerText = i18n.incorrectPortrait
        } else if (Math.abs(y) > 6.5 && z < 8.5) {
            this.image.style.display = ""
            this.element.style.display = ""
            this.text.innerText = i18n.incorrectPortrait
        } else {
            this.element.style.display = "none"
        }
    }
}

export const tryFullScreen = new EventRouter<string | void>("tryFullScreen")
